import React from 'react'
import {Route, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {loggedIn} from '../../helpers/LocalStorageHelper'
import store from '../../Store'
import AdminRoutes from "./../AdminRoutes";
import NotFound from "../../pages/NotFound";
import LoginRoutes from "../LoginRoutes";
import {endOfURL,atHomePage, startOfURL, secondToLastItemInURL} from "../../helpers/UrlHelper";
import Sidebar from "../../components/layout/Sidebar";
import {setUser} from "./MainRoute-Actions";
import getLoggedInUser from "../../ajax/actions/user/user_loggedin_get";
import OrganizationAdminRoutes from "../OrganizationAdminRoutes";
import HeaderNavigation from '../../components/layout/HeaderNavigation'
import TwoFactorAuthCheck from '../../../src/components/shared/pages/2FA_Redirect'

class MainRoute extends React.Component {
    componentDidMount() {
        if (endOfURL() === 'analytics' || endOfURL() === 'requested') {
            document.body.classList.add('developing');
        } else {
            document.body.classList.remove('developing');
        }

        if( loggedIn() ) {
            store.dispatch( getLoggedInUser());
        }
    }

    removeClassDeveloping() {
        document.body.classList.remove('developing');
    }

    componentDidUpdate(prev) {

        if (endOfURL() !== 'requested' && endOfURL() !== 'analytics') {
            this.removeClassDeveloping();
        }

        if( this.props.getLoggedInUser !== prev.getLoggedInUser ) {
            let { success } = this.props.getLoggedInUser;
            if( success ) {
                store.dispatch( setUser( success.data.data ) )
            }
        }
    }


    render() {
        let { action, role, user  } = this.props;
        const initials = user && user.first_name;
        
        if (action === 'render-404') {
            return <NotFound/>
        } else {
            return (
                loggedIn() ? (
                    <>
                        {/*<TwoFactorAuthCheck/>*/}
                        <HeaderNavigation url={endOfURL()} startOfURL={startOfURL()} role={role}/>
                        <section className="Main-App">
                            <div className="container-fluid p-0">
                                <div className="row no-gutters">
                                    {
                                        startOfURL() !== "dashboard" &&
                                        <div className="col sidebar-wrapper">
                                            <Sidebar url={endOfURL()} startOfURL={startOfURL()}
                                            secondToLastItemInURL={secondToLastItemInURL()} home={atHomePage()}
                                            />
                                        </div>
                                    }
                                    <div className={atHomePage()?"col content-area-wrapper pb-0":"col content-area-wrapper"} id={endOfURL() === 'statistics' && "statistics-content-wrapper"}>
                                        <div className="content-area">
                                            { (role && role === 'organization-admin') &&
                                            <Route  path="/" component={ OrganizationAdminRoutes  }/>
                                            }{ (role && role === 'super-admin') &&
                                            <Route  path="/" component={ AdminRoutes  }/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="Download-App">
                            <div className="under-development col-md-8 col-sm-8 col-xs-8">
                                <h3>Hi <span>{initials}</span>,</h3>
                                <h3>We are currently developing</h3>
                                <h3 className="spacing">Our mobile HQ platform.</h3>
                                <p>For now our platform is only optimised for smaller MacBooks and devices. For iPad & Mobile please download our internal apps to administrate the Kepler Hosting infrastructure. Please note that some settings are only available from the desktop version.</p>
                            </div>
                        </section>

                    </>
                    )
                    :
                    <LoginRoutes/>
            )
        }
    }
}

function mapStateToProps(state) {
    let { route, loginForm, getLoggedInUser } = state;
    let {action, routes_url, user} = route.data;
    let role = route && route.data.user && route.data.user.role.slug;
    let {redirectTo} = loginForm.data;
    return {route, action, loginForm, redirectTo, routes_url, getLoggedInUser, role, user  };

}

export default withRouter(connect(mapStateToProps)(MainRoute));
