import React from 'react';
import store from '../../../Store';
import getsMigrationTracking from "../../../ajax/actions/migration-tracking/migration_tracking_gets"

class MigrationTrackingWorker extends React.Component {
    componentDidMount() {
        store.dispatch(getsMigrationTracking());
    }

    render() {
        return null
    }
}

export default MigrationTrackingWorker;