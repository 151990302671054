import React from "react";
import { Route, Switch } from "react-router-dom";
import Site from "../pages/site/list/Site";
import Organizations from "../pages/organization/list/Organization";
import Role from "../pages/role/list/Role";
import SuperAdminSites from "../pages/super-admin-sites/list/Site";
import NotFound from "../components/shared/pages/NotFound";
import RoleForm from "../pages/role/form/RoleForm";
import DataCenter from "../pages/data-center/list/DataCenter";
import DataCenterForm from "../pages/data-center/form/DataCenterForm";
import OrganizationForm from "../pages/organization/form/OrganizationForm";
import User from "../pages/user/list/User";
import LoadBalancer from "../pages/load-balancer/list/LoadBalancer";
import LoadBalancerForm from "../pages/load-balancer/form/LoadBalancerForm";
import ServerModule from "../pages/server-module/list/ServerModule";
import ServerModuleForm from "../pages/server-module/form/ServerModuleForm";
import SiteForm from "../pages/site/form/SiteForm";
import OrganizationDashboard from "../pages/organization/OrganizationDashboard";
import UserForm from "../pages/user/form/UserForm";
import ActivityLog from "../pages/activity-log/list/Activity";
import Dashboard from "../pages/dashboard/Dashboard";
import OrganizationUser from "../pages/organization-user/list/User";
import OrganizationUserForm from "../pages/organization-user/form/UserForm";
import Payment from "../pages/payment/list/Payment";
import Discount from "../pages/discount-slab/list/Discount";
import Coupon from "../pages/coupon/list/Coupon";
import DiscountForm from "../pages/discount-slab/form/DiscountForm";
import Price from "../pages/price/list/Price";
import PriceForm from "../pages/price/form/PriceForm";
import CouponForm from "../pages/coupon/form/CouponForm";
import NewSiteDashboard from "../pages/site/NewSiteDashboard";
import AllOrganizationUser from "../pages/all-organizations-user/list/User";
import GlobalActivity from "../pages/global-activity-log/list/GlobalActivity";
import Migration from "../pages/migration/list/Migrate";
import MyAccount from "../pages/myAccount/MyAccount";
import Hotfix from "../pages/hotfix/Hotfix";
import MigrationForm from "../pages/migration/form/MigrationForm";
import BackupStatus from "../pages/backup-status/list/BackupStatus";
import ExpiredBackup from "../pages/expired-backup/list/ExpiredBackup";
import Bug from "../pages/support/list/Bug";
import BugForm from "../pages/support/form/BugForm";
import BugView from "../pages/support/form/BugView";
import MigrationTracking from "../pages/internal-migration-tracking/list/MigrationTracking";
import MigrationTrackingForm from "../pages/internal-migration-tracking/form/MigrationTrackingForm";

class AdminRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/allusers" component={User} />
        <Route exact path="/users" component={AllOrganizationUser} />
        <Route exact path="/allusers/create" component={UserForm} />
        <Route exact path="/site-pending-hotfix" component={Hotfix} />
        <Route exact path="/internal-support" component={Bug} />
        <Route exact path="/internal-support/urgent" component={Bug} />
        <Route exact path="/internal-support/active" component={Bug} />
        <Route exact path="/internal-support/completed" component={Bug} />
        <Route exact path="/bugs/create" component={BugForm} />
        <Route exact path="/bugs/:id([0-9]{0,9})/update" component={BugForm} />
        <Route exact path="/bugs/:id([0-9]{0,9})/details" component={BugView} />

        <Route
          exact
          path="/internal-migration-tracking"
          component={MigrationTracking}
        />
        <Route
          exact
          path="/internal-migration-tracking/:id([0-9]{0,9})/update"
          component={MigrationTrackingForm}
        />

        <Route exact path="/myaccount" component={MyAccount} />
        <Route
          exact
          path="/allusers/:id([0-9]{0,9})/update"
          component={UserForm}
        />
        <Route exact path="/organizations" component={Organizations} />
        <Route
          exact
          path="/organizations/:id([0-9]{0,9})/update"
          component={OrganizationForm}
        />
        <Route
          exact
          path="/organizations/:id([0-9]{0,9})"
          component={OrganizationDashboard}
        />
        <Route
          exact
          path="/organizations/:id([0-9]{0,9})/sites"
          component={Site}
        />
        <Route
          exact
          path="/organizations/:id([0-9]{0,9})/sites/create"
          component={SiteForm}
        />

        <Route
          exact
          path="/organizations/:org_id([0-9]{0,9})/sites/:id([0-9]{0,9})/:site/:mode"
          component={NewSiteDashboard}
        />
        <Route
          exact
          path="/organizations/:org_id([0-9]{0,9})/sites/:id([0-9]{0,9})/:site"
          component={NewSiteDashboard}
        />
        <Route
          exact
          path="/organizations/:org_id([0-9]{0,9})/activitylog"
          component={ActivityLog}
        />
        <Route
          exact
          path="/organizations/:org_id([0-9]{0,9})/users"
          component={OrganizationUser}
        />
        <Route
          exact
          path="/organizations/:org_id([0-9]{0,9})/users/create"
          component={OrganizationUserForm}
        />
        <Route
          exact
          path="/organizations/:org_id([0-9]{0,9})/users/:id([0-9]{0,9})/update"
          component={OrganizationUserForm}
        />
        <Route exact path="/activitylog" component={GlobalActivity} />
        <Route exact path="/migration" component={Migration} />
        <Route
          exact
          path="/migration-details/:id([0-9]{0,9})"
          component={MigrationForm}
        />

        <Route exact path="/server" component={ServerModule} />
        <Route exact path="/sites" component={SuperAdminSites} />
        <Route exact path="/server/create" component={ServerModuleForm} />
        <Route
          exact
          path="/server/:id([0-9]{0,9})/update"
          component={ServerModuleForm}
        />
        <Route exact path="/load-balancer" component={LoadBalancer} />
        <Route
          exact
          path="/load-balancer/create"
          component={LoadBalancerForm}
        />
        <Route
          exact
          path="/load-balancer/:id([0-9]{0,9})/update"
          component={LoadBalancerForm}
        />
        <Route exact path="/data-center" component={DataCenter} />
        <Route exact path="/data-center/create" component={DataCenterForm} />
        <Route
          exact
          path="/data-center/:id([0-9]{0,9})/update"
          component={DataCenterForm}
        />

        <Route
          exact
          path="/activitylog/infrastructure"
          component={GlobalActivity}
        />
        <Route exact path="/roles" component={Role} />
        <Route exact path="/role/create" component={RoleForm} />
        <Route exact path="/role/:id([0-9]{0,9})/update" component={RoleForm} />
        <Route
          exact
          path="/organizations/create"
          component={OrganizationForm}
        />
        <Route
          exact
          path="/organizations/:id([0-9]{0,9})/payments"
          component={Payment}
        />
        <Route exact path="/discounts" component={Discount} />
        <Route exact path="/discounts/create" component={DiscountForm} />
        <Route
          exact
          path="/discounts/:id([0-9]{0,9})/update"
          component={DiscountForm}
        />
        <Route
          exact
          path="/price/:id([0-9]{0,9})/update"
          component={PriceForm}
        />
        <Route exact path="/price/create" component={PriceForm} />
        <Route exact path="/price" component={Price} />

        <Route exact path="/coupon" component={Coupon} />
        <Route exact path="/coupon/create" component={CouponForm} />
        <Route
          exact
          path="/coupon/:id([0-9]{0,9})/update"
          component={CouponForm}
        />
        <Route
          exact
          path="/coupon/:id([0-9]{0,9})/apply"
          component={CouponForm}
        />

        <Route exact path="/backup-status" component={BackupStatus} />
        <Route exact path="/expired-backup" component={ExpiredBackup} />

        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default AdminRoutes;
