const DefaultPlanFormState = {
    payload: {
        plan_id: ''
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: ``,
    message: ``,
    site_id: ``,
    organization_id: ``,
};

export const PlanFormState = {data: DefaultPlanFormState};