import React from 'react';
import RoleWorker from "./RoleWorker";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import TableHead from "../../../components/shared/table/TableHead";
import store from "../../../Store";
import {toggleRoleListFilter} from "./RoleList-Action";
import {ucFirst} from "../../../helpers/StringHelper";
import deleteRole from "../../../ajax/actions/role/role_delete";
import swal from "sweetalert";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import RoleListFilters from "./RoleListFilters";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";

class Role extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    toggleSort = (field) => {
        store.dispatch(toggleRoleListFilter({
            // target: {name: `sort_id`, value: field}
            target: {name: `sort_by`, value: field}
        }))
    };

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this role? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id) {
        store.dispatch(deleteRole(id));
    }

    render() {
        let {list, fields, params, pages, getRoles} = this.props;
        let {processing,error} = getRoles;
        if (error)
        {
            this.props.history.push('/');
        }
        return (
                <Page title="Roles" processing={processing}>

                    <RoleWorker/>

                    <RoleListFilters title="roles"/>

                    <div className="table-block table-responsive pr-60">

                        <table className="table table-striped  ">
                            <TableHead fields={fields}
                                       params={params}
                                       noActions={true}
                                       onClick={this.toggleSort}/>


                                <tbody>
                                    {processing ? <td colSpan={3} className="text-center"><Loading/></td> :
                                        list && list.length ? list.map((l, key) => {
                                        return (
                                            <tr key={key}>
                                                {/*<td>{l.id}</td>*/}
                                                <td>{ucFirst(l.name)}</td>
                                                <td>{l.slug}</td>
                                                <td>
                                                    <div className="dropdown dropdown-alt text-center">
                                                            <span className="dropdown-toggle"
                                                                  data-toggle="dropdown">
                                                                <span className="icon-more"/>
                                                            </span>
                                                        <div className="dropdown-menu">
                                                            <Link to={`/role/${l.id}/update`} className={`link-edit`}>
                                                                Edit
                                                            </Link>
                                                            <a href="/#" onClick={ev => {this.deleteConfirm( l.id );ev.preventDefault()}} className={`link-delete`}>
                                                                Delete
                                                            </a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }) : <tr>
                                            <td colSpan={4} className="text-center">No Roles Available</td>
                                        </tr>
                                    }
                            </tbody>
                        </table>
                    </div>
                    { list && list.length ? (
                        <div className="fixed-footer pr-60">
                            <PaginationButtons pages={pages}
                                processing={ processing }
                                fetchAction={toggleRoleListFilter} />
                        </div>
                    ) : null }
                </Page>
            )
        }
    }

    function mapStateToProps(state) {
        let { getRoles,  roleList} = state;

        let { params, fields } = roleList.data;

        let {success} = getRoles;

        let list =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data;

        let current_page = success &&  success.data && success.data.data.current_page;
        let last_page = success && success.data && success.data.data.last_page;
        let total = success && success.data && success.data.data.total;
        let pages = { current_page, last_page, total };

        return {getRoles, list, roleList, params, fields, pages };
    }

    export default connect(mapStateToProps)(Role);