import store from '../../../Store';
// import getLoadBalancerDataCenterServer from "../../../ajax/actions/load-balancer/load_balancer_data_center_server_get";
import getDataCenterServerModule from "../../../ajax/actions/server-module/datacenter_server_module_get";
import getLoadBalancers from "../../../ajax/actions/load-balancer/load_balancers_get";
import {toggleURLQuery} from "../../../helpers/UrlHelper";

export function toggleLoadBalancerListFilter(e,history) {
    return function (dispatch) {
        let {name, value} = e.target;
        toggleURLQuery( name, value , history)
        dispatch({type: 'TOGGLE-LOAD-BALANCER-LIST-FILTER', name, value});
        store.dispatch(getLoadBalancers());
    }
}

export function onDataCenterFormChange(data_center_ids) {
    return function (dispatch) {
        dispatch({type: 'ON-DATA-CENTER-SELECT-SUCCESS', data_center_ids});
        if (data_center_ids) {
            store.dispatch(getDataCenterServerModule(data_center_ids));
        }
    }
}

export function onDataCenterFormReset() {
    return function (dispatch) {
        dispatch({type: 'ON-DATA-CENTER-SELECT-RESET'});
        store.dispatch(getLoadBalancers())
    }
}

export function onServerFormReset() {
    return function (dispatch) {
        dispatch({type: 'ON-SERVER-SELECT-RESET'});
        store.dispatch(getLoadBalancers())
    }
}

// export function onServerFormChange(server_id,dat_center_id) {
//   return function (dispatch) {
//     dispatch({type:'ON-SERVER-SELECT-SUCCESS', server_id,dat_center_id});
//     if( server_id && dat_center_id ) {
//       store.dispatch(getLoadBalancerDataCenterServer());
//     }
//
//   }
// }
export function onServerFormChange(server_id) {
    return function (dispatch) {
        dispatch({type: 'ON-SERVER-SELECT-SUCCESS', server_id});
        if (server_id) {
            store.dispatch(getLoadBalancers());
        }

    }
}