export function loadBalancerFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'LOAD-BALANCER-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onLoadBalancerFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-LOAD-BALANCER-FORM-SUBMIT'})
    }
}

export function onLoadBalancerFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-LOAD-BALANCER-FORM-CHANGE', name, value});
    }
}

export function onLoadBalancerFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-LOAD-BALANCER-FORM-MODE-CHANGE', mode});
    }
}

export function cancelLoadBalancerFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-LOAD-BALANCER-FORM-DISPATCH'});
    }
}

export function setDefaultLoadBalancerForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-LOAD-BALANCER-FORM'});
    }
}

export function setLoadBalancerDataForUpdate() {
    return function (dispatch, getState) {
        let {getLoadBalancer} = getState();
        let loadBalancer = getLoadBalancer && getLoadBalancer.success;
        dispatch({type: 'SET-LOAD-BALANCER-DATA-FOR-UPDATE', loadBalancer});
    }
}