import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../../Store';
import SSLFormWorker from './SSLFormWorker'
import {
    onSslCertificateFormChange,
    onSslCertificateFormSubmit,
} from "./SSLForm-Action";
import TextField from "../../../../../components/shared/form/TextFieldCertificate";
import {withRouter} from "react-router";
import TextAreaField from "../../../../../components/shared/form/CustomSSLTextAreaField";
import Loading from "../../../../../components/shared/loaders/Loading";
import {secondToLastItemInURL} from "../../../../../helpers/UrlHelper";

class SSLForm extends React.Component {

    sslFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onSslCertificateFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onSslCertificateFormChange(e.target.name, e.target.value));
    };

    render() {
        let {
            errors,
            name,
            key_content,
            certificate,
            createSSLCertificates,
            updateSSLCertificates,
            mode,
            getSslCertificate,
            site_status
        } = this.props;
        let {processing} = createSSLCertificates;
        let updateSSLCertificatesProcessing = updateSSLCertificates && updateSSLCertificates.processing;
        let getSslCertificateProcessing = getSslCertificate && getSslCertificate.processing
        return (
            <>
                <SSLFormWorker/>
                <div className="ssl-form">
                    {getSslCertificateProcessing ? <><h3 className="ssl-header text-center mb-20">Update SSL Certificate<Loading/>
                        </h3></> :
                        <>
                            {mode === 'update' ? <h3 className="ssl-header text-center mb-20">Update SSL Certificate</h3> : null}
                            <form onSubmit={this.sslFormSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-50 mt-20">
                                            <TextField name="name"
                                                       className="form-control"
                                                       value={name}
                                                       label="Certificate Name"
                                                       errors={errors.name}
                                                       onChange={this.onChange}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-50">
                                            <TextAreaField name="key_content"
                                                           className="form-control"
                                                           value={key_content}
                                                           label="Add .key contents"
                                                           errors={errors.key_content}
                                                           onChange={this.onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-50">
                                            <TextAreaField name="certificate"
                                                           className="form-control"
                                                           value={certificate}
                                                           label="Add .cert contents"
                                                           errors={errors.certificate}
                                                           onChange={this.onChange}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="button-wrapper inline-buttons text-center d-flex justify-content-end">
                                    <button type="submit" className="btn-theme btn-outline"
                                            disabled={secondToLastItemInURL() === "demo" || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}>
                                        {updateSSLCertificatesProcessing || processing ? <>Save<Loading/></> : "Save"}
                                    </button>
                                </div>
                            </form>
                        </>
                    }
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
    SSLForm
,
    getSiteStatus
,
    siteDashboard
,
    createSSLCertificates
,
    updateSSLCertificates
,
    sslCertificate
,
    getSslCertificate
}

= state;

let {site_status} = siteDashboard.data;
let site_search_status_message =
    getSiteStatus &&
    getSiteStatus.success &&
    getSiteStatus.success.message
let {errors, payload, mode} = SSLForm.data;
let {name, key_content, certificate} = payload

return {
    SSLForm,
    errors,
    getSiteStatus,
    site_search_status_message,
    site_status,
    mode,
    name,
    key_content,
    certificate,
    payload,
    createSSLCertificates,
    updateSSLCertificates,
    sslCertificate,
    getSslCertificate
};
}

export default withRouter(connect(mapStateToProps)(SSLForm));