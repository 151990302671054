import React from 'react';
import PriceWorker from "./PriceWorker";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import TableHead from "../../../components/shared/table/TableHead";
import store from "../../../Store";
import {togglePriceListFilter} from "./Price-Action";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import PriceHeader from "./PriceHeader";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";

class Price extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }


    toggleSort = (field) => {
        store.dispatch(togglePriceListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    render() {
        let {fields, params, pages, list, getPrices} = this.props;
        let {processing, error} = getPrices;
        if (error) {
            this.props.history.push('/');
        }
        return (
            <Page title="Price" processing={processing}>

                <PriceWorker/>

                <PriceHeader title="price"/>

                <div className="table-block  pr-60">

                    <table className="table table-striped  ">

                        <TableHead fields={fields}
                                   params={params}
                                   noActions={true}
                                   onClick={this.toggleSort}/>

                        <tbody>
                        {processing ? <td colSpan="4" className="text-center"><Loading/></td> :
                            list && list.length ? list.map((l, key) => {
                                return (
                                    <tr key={key}>
                                        <td>
                                            {l.site_type}
                                        </td>
                                        <td>
                                            {l.currency}
                                        </td>
                                        <td>
                                            {parseFloat((l.price).toString())}
                                            {l.currency}
                                        </td>
                                        <td>
                                            <div className="dropdown dropdown-alt text-center">
                                                    <span className="dropdown-toggle"
                                                          data-toggle="dropdown">
                                                        <span className="icon-more"/>
                                                    </span>
                                                <div className="dropdown-menu">
                                                    <Link to={`/price/${l.id}/update`}>
                                                        <span className=" dropdown-item">Edit</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : <tr>
                                <td colSpan="7" className="text-center">No Price Available</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {list && list.length ? (
                    <div className="fixed-footer pr-60">
                        <PaginationButtons pages={pages}
                                           processing={processing}
                                           fetchAction={togglePriceListFilter}/>
                    </div>
                ) : null}

            </Page>
        )
    }
}

function mapStateToProps(state) {
    let {priceList, getPrices} = state;

    let {params, fields} = priceList.data;

    let {success} = getPrices;

    let list =
        success

    let current_page = success && success.current_page;
    let last_page = success && success.last_page;
    let total = success && success.total;
    let pages = {current_page, last_page, total};

    return {params, fields, pages, getPrices, list};
}

export default connect(mapStateToProps)(Price);