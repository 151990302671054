import React from 'react';
import store from '../../../Store';
import getDataCenters from "../../../ajax/actions/data-center/data_centers_get";
import getServerModules from "../../../ajax/actions/server-module/server_modules_get";
import withRouter from "react-router/withRouter";
import {connect} from "react-redux";

class ServerModuleWorker extends React.Component
{

  componentDidMount()
  {
    store.dispatch( getDataCenters() )
    store.dispatch( getServerModules() )
  }

  render()
  {
      return null
  }
}

// export default ServerModuleWorker;
function mapStateToProps(state) {
  let { getDataCenters } = state;

  return { getDataCenters };
}
export default withRouter(connect(mapStateToProps)(ServerModuleWorker));