import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    onLoadBalancerFormModeChange,
    setDefaultLoadBalancerForm,
    setLoadBalancerDataForUpdate
} from "./LoadBalancerForm-Action";
import {endOfURL, urlTrailWithoutLastItem} from "../../../helpers/UrlHelper";
import getLoadBalancer from "../../../ajax/actions/load-balancer/load_balancer_get";
import createLoadBalancer from "../../../ajax/actions/load-balancer/load_balancer_post";
import updateLoadBalancer from "../../../ajax/actions/load-balancer/load_balancer_put";
import getServerModules from "../../../ajax/actions/server-module/server_modules_get";

class LoadBalancerFormWorker extends React.Component {

    constructor(props) {
        super(props);
        if (props.match.params.id) {
            store.dispatch(getLoadBalancer(props.match.params.id));
            store.dispatch(onLoadBalancerFormModeChange('update'));
        }
        store.dispatch(getServerModules());
        if (endOfURL() === 'create') {
            store.dispatch(setDefaultLoadBalancerForm())
        }
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode,} = this.props;

        if (this.props.getLoadBalancer !== prev.getLoadBalancer) {
            let {success} = this.props.getLoadBalancer;
            if (success) {
                store.dispatch(setLoadBalancerDataForUpdate());
            }
        }

        if (this.props.createLoadBalancer !== prev.createLoadBalancer) {
            let {success} = this.props.createLoadBalancer;
            if (success) {
                this.props.history.push(urlTrailWithoutLastItem());
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                if (mode === 'create') {
                    store.dispatch(createLoadBalancer())
                } else {
                    store.dispatch(updateLoadBalancer(this.props.match.params.id))
                }
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {loadBalancerForm, getLoadBalancer, createLoadBalancer,} = state;
    let {dispatchAPI, mode, payload} = loadBalancerForm.data;
    return {loadBalancerForm, dispatchAPI, getLoadBalancer, mode, payload, createLoadBalancer};
}

export default withRouter(connect(mapStateToProps)(LoadBalancerFormWorker));