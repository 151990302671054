export function priceFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'PRICE-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onPriceFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-PRICE-FORM-SUBMIT'})
    }
}

export function onPriceFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-PRICE-FORM-CHANGE', name, value});
    }
}

export function onPriceFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-PRICE-FORM-MODE-CHANGE', mode});
    }
}

export function cancelPriceFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-PRICE-FORM-DISPATCH'});
    }
}

export function setDefaultPriceForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-PRICE-FORM'});
    }
}

export function setPriceDataForUpdate() {
    return function (dispatch, getState) {
        let {getPrice} = getState();
        let price = getPrice && getPrice.success;
        dispatch({type: 'SET-PRICE-DATA-FOR-UPDATE', price});
    }
}