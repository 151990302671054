export function passwordResetSuccessMessage() {
    return function (dispatch) {
        dispatch({type: 'ON-PASSWORD-RESET-CREATE'});
    }
}

export function setDefaultPasswordResetMessage() {
    return function (dispatch) {
        dispatch({type: 'DEFAULT-PASSWORD-RESET-MESSAGE'});
    }
}

export function toggleTwoFactorAuthenticationFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-TWO-FACTOR-AUTHENTICATION-FORM-MODAL'});
    }
}