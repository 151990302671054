export default function createMarkAsPaidReducer
    (state = { error:'', success:'', processing:false}, action)
{
    switch (action.type) {
        case 'CREATE_MARK_AS_PAID_SUCCESS':
            return {...state, ...{success: action.success}};

        case 'CREATE_MARK_AS_PAID_ERROR':
            return {...state, ...{error: action.error}};

        case 'CREATE_MARK_AS_PAID_PROCESSING':
            return {...state, ...{processing: action.processing}};

        default: return state;
    }
}