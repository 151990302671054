import { createStore, applyMiddleware, compose } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import rootReducer from './State'
// import createHistory from 'history/createBrowserHistory'
import history from "./history";

// export const history = createHistory()

const initialState = {}
const enhancers = []
const middleware = [ thunk, routerMiddleware( history )]

if ( process.env.NODE_ENV === 'development' ) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if ( typeof devToolsExtension === 'function' ) {
    enhancers.push( devToolsExtension( ))
  }
}

const composedEnhancers = compose( applyMiddleware( ...middleware ), ...enhancers )

export default createStore( connectRouter( history )( rootReducer ), initialState, composedEnhancers )
