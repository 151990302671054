
const DefaultServerModuleState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    sort_order:  'desc',
    // sort_id: `id`,
    sort_by: `data_center_name`,
    q: ``,
    data_center_id: '',
  },
  data_center_id:``,
  fields: [
    // { field: `id`, label: `ID`, sort: true },
    { field: `data_center_name`, label: `Data Center Name`, sort: true },
    { field: `name`, label: `Name`, sort: true },
    { field: `sites_count`, label: `Number of sites`, sort: true },
    { field: `public_ip_address`, label: `Public IP Address`, sort: true },
    { field: `status`, label: `Status`, sort: true },

  ]
}

export const ServerModuleState = {data:DefaultServerModuleState}