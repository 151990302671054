import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken, logOut} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from "../../../helpers/ErrorHelper";
import {setSiteStatus} from "../../../pages/site/SiteDashboard-Action";
import store from '../../../Store';
import Alert from 'react-s-alert';
import {ConfigErrorAlert} from "../../../Config";
import getSite from '../sites/site_get'
import {
    setFailedSearchResult,
    setSearchResult
} from "../../../pages/site/searchandreplace/form/SearchAndReplaceForm-Action";
import getSiteBackup from "../backup/site_backup_get";
import getManualSiteBackup from "../backup/site_manual_backup_get";
import getSiteDomain from "../domain/site_domain_get";
import getRedirects from "../redirects/redirects_get";
import getSiteDomainNoPagination from "../domain/site_domain_nopagination_get";
import getsCacheIgnore from "../kepler-tools/cache_ignore_gets";

function _success(success) {
    return {type: 'GET_SITE_STATUS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_SITE_STATUS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_SITE_STATUS_PROCESSING', processing};
}

function getSiteStatus(id, organization_id = null) {
    return (dispatch, getState) => {

        dispatch(_processing(true));

        let {organizationDashboard} = getState();

        let org_id = organization_id || organizationDashboard.data.organization_id;

        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${id}/status`),
            method: "get",
            dataType: 'json',
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                dispatch(_success(res.data.data));

                if (res.data.data) {

                    store.dispatch(setSiteStatus());
                    if (res && res.data && res.data.data && res.data.data.notifications.length) {
                        let notify = res.data.data.notifications

                        notify && notify.length && notify.map((l, key) => {
                            (l.status === 'successful' ?

                                handleSuccess(l.message) : l.status === 'failed' &&
                                Alert.error(`Error - ${l.message}`, ConfigErrorAlert))
                            if(l.status !== `failed` && l.event_name === "search_replace_term") {
                                store.dispatch(setSearchResult(l.extra.amount_of_terms, l.extra.search_term))
                            }
                            if (l.status === `failed` && l.event_name === "search_replace_term") {
                                store.dispatch(setFailedSearchResult(l.status, l.message))
                            }
                            // if (l.status === `failed` && l.event_name === "create_domain") {
                            //     store.dispatch(getSiteDomain(org_id));
                            // }
                            if (l.event_name === "create_domain") {
                                store.dispatch(getSiteDomain(org_id));
                            }
                            if (l.event_name === "update_auth_paths") {
                                store.dispatch(getSite(id,org_id));
                            }
                            if (l.event_name === "create_redirect_rule") {
                                store.dispatch(getRedirects(id,org_id));
                            }
                            if (l.event_name === "update_redirect_rule") {
                                store.dispatch(getRedirects(id,org_id));
                            }
                            if (l.event_name === "delete_redirect_rule") {
                                store.dispatch(getRedirects(id,org_id));
                            }
                            if (l.event_name === "delete_ignore_cache") {
                                store.dispatch(getsCacheIgnore(id,org_id));
                            }
                            if (l.event_name === "add_ignore_cache") {
                                store.dispatch(getsCacheIgnore(id,org_id));
                            }
                            if (l.event_name === "update_ignore_cache") {
                                store.dispatch(getsCacheIgnore(id,org_id));
                            }
                            if (l.event_name === "generate_ssl") {
                                store.dispatch(getSite(id,org_id));
                                store.dispatch(getSiteDomainNoPagination(id));
                            }
                            if (l.event_name === "delete_domain") {
                                store.dispatch(getSite(id,org_id));
                                store.dispatch(getSiteDomain(org_id));
                            }
                            if (l.event_name === "update_domain") {
                                store.dispatch(getSite(id,org_id));
                                store.dispatch(getSiteDomain(org_id));
                            }
                            if (l.event_name === "change_php_version") {
                                store.dispatch(getSite(id,org_id));
                            }
                            if(l.event_name === "disable_debug") {
                                store.dispatch(getSite(id, org_id));
                            }
                            if(l.event_name === "enable_debug") {
                                store.dispatch(getSite(id, org_id));
                            }
                            if(l.event_name === "force_ssl_enable") {
                                store.dispatch(getSite(id, org_id));
                            }
                            if(l.event_name === "force_ssl_disable") {
                                store.dispatch(getSite(id, org_id));
                            }
                            if(l.event_name === "change_react_config_flag") {
                                store.dispatch(getSite(id, org_id));
                            }
                            if (l.event_name === "update_domain") {
                                store.dispatch(getSite(id,org_id));
                            }
                            if (l.event_name === "enable_cache") {
                                store.dispatch(getSite(id,org_id));
                            }
                            if (l.event_name === "disable_cache") {
                                store.dispatch(getSite(id,org_id));
                            }
                            if (l.event_name === "change_site_ssl") {
                                store.dispatch(getSite(id,org_id));
                            }
                            if(l.event_name === "delete_backup") {
                                store.dispatch(getSiteBackup(org_id));
                                store.dispatch(getManualSiteBackup(org_id));
                            }

                        })
                    }
                }
                dispatch(_processing(false));
                // store.dispatch(getSite(id, org_id));

            }).catch(function (error) {
            if (error && error.response &&
                error.response.status === 401) {
                logOut()
            } else {
                handleError(error)
                dispatch(_error(error));
                dispatch(_processing(false));
            }
        });
    }
}

export default getSiteStatus;