import React from 'react';
import { pushToPage} from "../../../helpers/UrlHelper";
import {withRouter} from "react-router";
import {connect} from "react-redux";

class TwoFaRedirect extends React.Component {
    render() {

        const current_path = this.props.location.pathname;
        const account_paths = ['/organizations'];
        let { getLoggedInUser} = this.props;
        if (getLoggedInUser.success) {
            if (!getLoggedInUser.success.data.data.twoFa) {
                if (!account_paths.includes(current_path)) {
                        pushToPage(this.props, `/organizations`)
                    }
            }
        }

        return (

            null

        )
    }
}

function mapStateToProps(state) {
    let {route, getLoggedInUser} = state;

    let {action, routes_url} = route.data;

    let role = route && route.data.user && route.data.user.role.slug;

    return {route, action, routes_url, getLoggedInUser, role};
}

export default withRouter(connect(mapStateToProps)(TwoFaRedirect));


