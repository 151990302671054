import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'GET_ROLE_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_ROLE_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_ROLE_PROCESSING', processing};
}

function getRole(id) {
    return (dispatch, getState) => {
        dispatch(_processing(true));

        const request = axios({

            url: apiBaseUrl(`admin/roles/${id}`),
            method: "get",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error)

            });

        return {
            type: 'GET_ROLE_SUCCESS',
            payload: request
        }
    }
}

export default getRole;