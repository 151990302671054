export function passwordResetFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'PASSWORD-RESET-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onPasswordResetFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-PASSWORD-RESET-FORM-SUBMIT'})
    }
}

export function onPasswordResetFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-PASSWORD-RESET-FORM-CHANGE', name, value});
    }
}

export function setDefaultPasswordResetForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-PASSWORD-RESET-FORM'});
    }
}

export function cancelPasswordResetFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-PASSWORD-RESET-FORM-DISPATCH'});
    }
}

export function passwordResetError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-PASSWORD-RESET-ERROR', error});
    }
}