import store from '../../../Store';
import { toggleURLQuery } from '../../../helpers/UrlHelper'
import getServerModules from "../../../ajax/actions/server-module/server_modules_get";
import getDataCenterServerModule from "../../../ajax/actions/server-module/datacenter_server_module_get";

export function toggleServerModuleListFilter(e,history)
{
  return function (dispatch) {
    let { name, value } = e.target;
    toggleURLQuery( name, value , history)
    dispatch({type:'TOGGLE-SERVER-MODULE-LIST-FILTER', name, value, history});
    if(name === 'limit') {
      store.dispatch( getServerModules());
      toggleURLQuery( 'page', '',history)
    }
    else {
      store.dispatch(getServerModules());
    }
    // store.dispatch(getServerModules());
  }
}

export function setDefaultServerModuleFiltersToURL( history )
{
  return ( dispatch, getState ) => {
    let { data } = getState().serverModuleList;
    let { params } = data;
    Object.keys( params ).map( key => {
      if ( params[key] !== `` && params[key] !== null && params[key] !== undefined ) {
        toggleURLQuery( key, params[key], history)
      }
    })
  }
}

export function onServerModuleDataCenterChange(data_center_ids) {
  return function (dispatch) {
    dispatch({type:'ON-DATA-CENTER-SELECT-CHANGE', data_center_ids});
    data_center_ids && store.dispatch(getDataCenterServerModule(data_center_ids));
  }
}

export function onServerDataCenterChange(data_center_id) {
  return function (dispatch) {
    dispatch({type:'ON-SERVER-DATA-CENTER-CHANGE', data_center_id});
    store.dispatch( getServerModules( ));
  }
}

export function onServerReset() {
  return function (dispatch) {
    dispatch({type:'ON-SERVER-RESET'});
    store.dispatch( getServerModules( ) );
  }
}