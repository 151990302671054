export default function getCustomerRolesReducer
    (state = {error: '', success: '', processing: false}, action) {
    switch (action.type) {
        case 'GET_CUSTOMER_ROLES_SUCCESS':
            return {...state, ...{success: action.success}};

        case 'GET_CUSTOMER_ROLES_ERROR':
            return {...state, ...{error: action.error}};

        case 'GET_CUSTOMER_ROLES_PROCESSING':
            return {...state, ...{processing: action.processing}};

        default:
            return state;
    }
}