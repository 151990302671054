import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import PhpVersionFormWorker from './PhpVersionFormWorker'
import {
    onPhpVersionFormChange,
    onPhpVersionFormSubmit
} from "./PhpVersionForm-Action";
import Loading from "../../../../components/shared/loaders/Loading";
import {togglePhpVersionFormModal} from "../../feature-block/list/FeatureBlock-Action";
import SelectField from "../../../../components/shared/form/SelectField-phpVersion";


class phpVersionForm extends React.Component {
    phpVersionFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onPhpVersionFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onPhpVersionFormChange(e.target.name, e.target.value,));
    };


    render() {
        let {
            errors,
            getSite,
            php_version,
            phpVersionList,
            site_status,
        } = this.props;
        let phpVersions = [];
        phpVersionList && phpVersionList.map(d => {
            phpVersions.push({value: d, label: d})
        })

        let {processing} = getSite

        return (
            <>
                <PhpVersionFormWorker/>
                <div className="content-form-block light-gray-bg">

                    {/*<h3 className="search-header text-center mb-20">PHP Version</h3>*/}
                    {/*<div className="search-replace-line">*/}

                        {/*<p>  {site_search_status_event === "change_php_version" ? <>*/}
                        {/*    <p className="bg-danger"><Loading color="white"/> {site_search_status_message}</p>*/}

                        {/*</> : null}</p>*/}

                        <form className="mt-30 domain-form" onSubmit={this.phpVersionFormSubmit}>

                            <SelectField name="php_version"
                                         value={php_version}
                                         options={phpVersions}
                                         label="PHP Version"
                                         errors={errors && errors.php_version}
                                         message={errors && errors.errors}
                                         onChange={this.onChange}
                                         _value="value"
                                         _label="label"/>

                            {/*</div>*/}

                            <div className="button-wrapper inline-buttons text-center d-flex justify-content-center">
                                <button className="btn-theme btn-outline" type="reset"
                                        onClick={() => store.dispatch(togglePhpVersionFormModal())}>Cancel
                                </button>
                                <button type="submit" className="btn-theme radius-btn-blue"
                                        disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}>

                                    {/*{processing ? <Loading/> : `Submit`}*/}
                                    {processing ? <Loading/> : `Submit`}
                                </button>
                            </div>
                        </form>
                    </div>
                {/*</div>*/}
            </>
        )
    }
}

function mapStateToProps(state) {
    let {phpVersionForm, createPhpVersion, getSite, getSiteStatus, siteDashboard, getPhpVersions} = state;
    let {site_status} = siteDashboard.data;
    let phpVersionList = getPhpVersions && getPhpVersions.success

    let site_search_status_event =
        getSiteStatus &&
        getSiteStatus.success &&
        getSiteStatus.success.event
    let site_search_status_message =
        getSiteStatus &&
        getSiteStatus.success &&
        getSiteStatus.success.message

    let {errors, payload} = phpVersionForm.data;

    let {
        php_version
    } = payload;

    return {
        phpVersionForm,
        php_version,
        errors,
        getSite,
        getSiteStatus,
        site_search_status_event,
        site_search_status_message,
        site_status,
        getPhpVersions,
        phpVersionList,
        createPhpVersion
    };
}

export default connect(mapStateToProps)(phpVersionForm);