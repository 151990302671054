import { BreadCrumbState } from './BreadCrumb-State'

export default function BreadCrumbReducer ( state = BreadCrumbState, action )
{
  let { data } = { ...state };
  let { list } = action;

  switch( action.type )
  {
    case `SET-BREADCRUMBS`:
      data.list = list;
      return { ...state,  data };

    default: return state;
  }
}