import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
    return {type: 'GET_ORGANIZATIONS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_ORGANIZATIONS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_ORGANIZATIONS_PROCESSING', processing};
}

function getOrganizations() {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_error(null));

        let {params} = getState().organizationList.data;

        const request = axios({

            url: apiBaseUrl(`admin/organizations`),
            method: "get",
            dataType: 'json',
            params,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`)
            }
        }).then(function (res) {
            dispatch(_processing(false));
            dispatch(_success(res));

        }).catch(function (error) {
            handleError(error)
            dispatch(_error(error));
            dispatch(_processing(false));
        });

        return {
            type: 'GET_ORGANIZATIONS_SUCCESS',
            payload: request
        }
    }
}

export default getOrganizations;
