import React from 'react';
import ServerModuleWorker from "./ServerModuleWorker";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import TableHead from "../../../components/shared/table/TableHead";
import store from "../../../Store";
import {toggleServerModuleListFilter} from "./ServerModule-Action";
import swal from "sweetalert";
import deleteServerModule from "../../../ajax/actions/server-module/server_module_delete";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import ServerModuleListFilters from "./ServerModuleListFilters";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import Status from '../../../components/shared/status/Status';

class ServerModule extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(  setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    toggleSort = (field) => {
        store.dispatch(toggleServerModuleListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this server ? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id)
    {
        store.dispatch(deleteServerModule(id));
    }

    render() {
        let { getServerModules, fields, params , pages, serverModules } = this.props
        let { processing,error} = getServerModules
        if (error)
        {
            this.props.history.push('/');
        }
        return (
            <>
                <Page title="Server" processing={processing}>
                <ServerModuleWorker/>
                <ServerModuleListFilters title="server"/>
                <div className="table-block  pr-60">
                       <table className="table table-striped  ">
                            <TableHead fields={fields}
                                       params={params}
                                       onClick={this.toggleSort}/>
                           <tbody>
                           { processing ? <td colSpan={5} className="text-center"> <Loading /></td> :
                                   serverModules && serverModules.length ? serverModules.map((l, key) => {
                                       return (
                                           <tr key={key}>

                                               <td>
                                                   <Link to="/data-center">
                                                       {l.data_center_name}
                                                   </Link>
                                               </td>
                                               <td>
                                                   {l.name}
                                               </td>
                                               <td>{l.sites_count}</td>
                                               <td>{l.public_ip_address} </td>
                                               <td>
                                                   <Status status={l.status}/>
                                                
                                               {/* <Badge round type={l.status === 0 ? 'danger' : 'success'}>
                                                   {l.status === 0 ? 'Inactive' : 'Active'}
                                               </Badge> */}
                                               </td>
                                               <td>
                                                   <div className="dropdown dropdown-alt text-center">
                                                        <span className="dropdown-toggle"
                                                              data-toggle="dropdown">
                                                            <span className="icon-more"/>
                                                        </span>
                                                       <div className="dropdown-menu">
                                                           <Link to={`/server/${l.id}/update`} className={`link-edit`}>
                                                               Edit
                                                           </Link>
                                                           {/*<button type="button"
                                                                   className="btn btn-danger btn-sm ml-1 dropdown-item red-link"
                                                           onClick={() => this.deleteConfirm( l.id )}>
                                                               <span className="dropdown-item red-link" >Delete</span>
                                                           </button>*/}
                                                       </div>
                                                   </div>
                                               </td>
                                           </tr>
                                       )
                                   }) :<tr><td colSpan="8" className="text-center">Please Select the Data Center</td></tr>
                               }
                            </tbody>
                       </table>
                            { serverModules && serverModules.length ? (
                                <div className="fixed-footer pr-60">
                                    <PaginationButtons pages={pages}
                                                       processing={ processing }
                                                       fetchAction={toggleServerModuleListFilter} />
                                </div>
                            ) : null }
                </div>
                </Page>
            </>

        )
    }
}

function mapStateToProps(state) {
    let {   serverModuleList, getServerModules ,getDataCenters} = state;
// console.log(` <| getDataCenters |> `, getDataCenters)
    let { params, fields, mode } = serverModuleList.data;
    let { success } = getServerModules;
    let serverModules =
        getServerModules &&
        getServerModules.success &&
        getServerModules.success.data &&
        getServerModules.success.data.data &&
        getServerModules.success.data.data.data


    let current_page = success &&  success.data && success.data.data && success.data.data.current_page;
    let last_page = success &&  success.data && success.data.data && success.data.data.last_page;
    let total = success &&  success.data && success.data.data && success.data.data.total;
    let from = success &&  success.data && success.data.data && success.data.data.from;

    let pages = { current_page, last_page, total, from };

    return { serverModuleList, params, fields ,pages, serverModules, mode, getServerModules , getDataCenters};
}

export default connect(mapStateToProps)(ServerModule);