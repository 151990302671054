import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
  onServerModuleFormModeChange,
  setDefaultServerModuleForm,
  setServerModuleDataForUpdate
} from "./ServerModuleForm-Action";
import {endOfURL, urlTrailWithoutLastItem} from "../../../helpers/UrlHelper";
import getServerModule from "../../../ajax/actions/server-module/server_module_get";
import createServerModule from "../../../ajax/actions/server-module/server_module_post";
import updateServerModule from "../../../ajax/actions/server-module/server_module_put";
import getDataCenters from "../../../ajax/actions/data-center/data_centers_get";

class ServerModuleFormWorker extends React.Component
{

  constructor(props) {
    super(props);
    if(props.match.params.id) {
      store.dispatch(getServerModule(props.match.params.id));
      store.dispatch(onServerModuleFormModeChange('update'));
    }
      store.dispatch(getDataCenters());
     if(  endOfURL() === 'create') {
       store.dispatch(setDefaultServerModuleForm())
     }
  }

  componentDidUpdate(prev)
  {
    let {  dispatchAPI, mode, } = this.props;

    if(this.props.getServerModule !== prev.getServerModule) {
      let {success, error} = this.props.getServerModule;
      if(success) {
        store.dispatch(setServerModuleDataForUpdate());
      }

      if( error ) {
      }
    }

    if(this.props.createServerModule !== prev.createServerModule) {
      let { success} = this.props.createServerModule;
      if(success) {
        this.props.history.push(urlTrailWithoutLastItem());
      }
    }

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if( mode === 'create' ) {
          store.dispatch( createServerModule() )
        } else {
          store.dispatch(updateServerModule( this.props.match.params.id ))
        }
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { serverModuleForm, getServerModule, createServerModule,  } = state;
  let {dispatchAPI, mode, payload} = serverModuleForm.data;
  return { serverModuleForm, dispatchAPI, getServerModule, mode, payload, createServerModule };
}
export default withRouter(connect( mapStateToProps )( ServerModuleFormWorker ));