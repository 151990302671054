import axios from 'axios';
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {
    cancelPriceBlockFormDispatch,
    setDefaultPriceBlockForm
} from "../../../pages/site/price-block/form/PriceBlockForm-Action";
import getSite from "../sites/site_get"

function _success(success) {
    return {type: 'DELETE_SITE_PRICE_SUCCESS', success}
}

function _error(error) {
    return {type: 'DELETE_SITE_PRICE_ERROR', error}
}

function _processing(processing) {
    return {type: 'DELETE_SITE_PRICE_PROCESSING', processing}
}

function deleteSitePrice(id) {
    return (dispatch, getState) => {

        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        let {SftpList,organizationDashboard} = getState();
        let site_id =  SftpList.data.site_id
        let org_id = organizationDashboard.data.organization_id;
        let config = {
            headers: {'Authorization': 'Bearer ' + loginToken()},
        };

        axios.delete(apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/prices/${id}`), config)

            .then(res => {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
                store.dispatch(getSite(site_id));
                store.dispatch(cancelPriceBlockFormDispatch());
                store.dispatch(setDefaultPriceBlockForm())

            }).catch(error => {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error)

        });
    }
}

export default deleteSitePrice;