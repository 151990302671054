import React from 'react';
import store from "../../../../Store";
import {toggleSearchAndReplaceFormModal} from "./FeatureBlock-Action";
import findSvg from "../../../../assets/images/searchreplace.svg";
import {connect} from "react-redux";
import Loading from "../../../../components/shared/loaders/Loading";

class SearchAndReplacePrimaryView extends React.Component {
    render() {
        let {event} = this.props
        return (
            <div className="col-xl-12 col-sm-12 feature-col">
                <div className="single-feature">
                <div className="feature-information">
                    <img src={findSvg} alt="feature svg" className="feature-img"/>
                    <div className="feature-name-section">
                        <p className="site-tools-heading">Site Tools</p>
                        <h3>Search & Replace</h3>
                    </div>
                </div>
                    <p className="site-tools-description">The Kepler Hosting Search & Replace tool - After you migrate a website to Kepler or if you want to change your domain. The search and replace tool is used to search for your old domain and replace it with your new domain. Please remember to clear your cache if the cache is activated.</p>
                    <div className="btn-wrapper">
                        <button className="btn-theme btn-outline blue-outline" data-toggle="modal"
                                data-target="#public-path"
                                onClick={() => store.dispatch(toggleSearchAndReplaceFormModal())}>
                            {event === "search_replace_term" || event === "replace_searched_term" || event === "backup_for_replace_term" ? <>Change <Loading/></> : "Change"}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let {getSite, getSiteStatus, siteDashboard} = state;
    let {site_status} = siteDashboard.data;

    let event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event

    return {event, getSite, getSiteStatus, site_status, siteDashboard};

}

export default connect(mapStateToProps)(SearchAndReplacePrimaryView);