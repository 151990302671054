import React from "react";
import sslSvg from "../../../../assets/images/ssl.svg";
import { connect } from "react-redux";
import store from "../../../../Store";
import { withRouter } from "react-router";
import debug from "../../../../ajax/actions/kepler-tools/debug_post";
import deleteSslCertificates from "../../../../ajax/actions/ssl/ssl_delete";
import getSslCertificate from "../../../../ajax/actions/ssl/ssl_certificate_get";
import {
  toggleSSLFormModal,
  setSSLFilteredData,
  onChangeLetsEncrypt,
} from "./SSL-Action";
import createGenerateLetsEncrypt from "../../../../ajax/actions/ssl/generate_lets_encrypt_post";
import createCustomSslCertificate from "../../../../ajax/actions/ssl/custom_ssl_certificate_post";
import Loading from "../../../../components/shared/loaders/Loading";
import createGenerateSsl from "../../../../ajax/actions/ssl/generateSsl_post";
import CheckField from "../../../../components/shared/form/CheckField";
import customSSLImg from "../../../../assets/images/custom_ssl.svg";
import ResponsiveModalForm from "../../../../components/shared/modals/ResponsiveModalForm";
import SSLForm from "../sslCertificate/form/SSLForm";
import SSLWorker from "./SSLWorker";
import createGenerateCustomSsl from "../../../../ajax/actions/ssl/generateCustomSsl_post";
import getSiteDomainNoPagination from "../../../../ajax/actions/domain/site_domain_nopagination_get";
import {
  fullDateStringNoTime,
  justDate,
} from "../../../../helpers/DateTimeHelper";

class SSL extends React.Component {
  state = {
    custom: false,
    sslForm: false,
    data: [],
    dataCustom: [],
    isCheckedLetsEncryptID: [],
  };

  componentDidMount() {
    store.dispatch(getSiteDomainNoPagination(this.props.match.params.id));
  }

  componentDidUpdate(prev) {
    if (
      this.props.getSiteDomainNoPagination !== prev.getSiteDomainNoPagination
    ) {
      let { success } = this.props.getSiteDomainNoPagination;
      if (success) {
        store.dispatch(setSSLFilteredData());
      }
    }
  }

  onChangeLetsEncrypt = (index, value, e) => {
    store.dispatch(onChangeLetsEncrypt(e));
  };

  /*  onChangeCustom = (index, value, e) => {
        let newArray = [...this.state.dataCustom, e.target.id];
        if (this.state.dataCustom.includes(e.target.id)) {
            newArray = newArray.filter(item => item !== e.target.id);
        }
        this.setState({
            dataCustom: newArray
        });
    };*/

  onGenerateSSL = (e) => {
    store.dispatch(createGenerateSsl(this.props.match.params.id));
  };

  onGenerateCustomSSL = (e) => {
    store.dispatch(createGenerateCustomSsl(this.props.match.params.id));
  };

  enableDisableGenerateLetsEncrypt = (value) => {
    const payload = {
      ssl_type: value,
    };
    store.dispatch(
      createGenerateLetsEncrypt(this.props.match.params.id, payload)
    );
  };

  enableDisableCustomSslCertificate = (value) => {
    const payload = {
      ssl_type: value,
    };
    store.dispatch(
      createCustomSslCertificate(this.props.match.params.id, payload)
    );
  };

  debug = (value) => {
    const payload = {
      debug: value,
    };
    store.dispatch(debug(payload));
  };

  /*  customSSL = () => {
        if (this.state.custom === false) {
            this.setState({custom: true})
        } else {
            this.setState({custom: false})
        }
    }*/

  /*   onClickAddRule = () => {
        this.setState({sslForm: true});
    }*/

  onDeleteCertificate = (id) => {
    store.dispatch(deleteSslCertificates(this.props.match.params.id, id));
  };

  onEditCertificate = (id) => {
    this.setState({ sslForm: false });
    store.dispatch(getSslCertificate(this.props.match.params.id, id));
    store.dispatch(toggleSSLFormModal());
  };

  render() {
    let {
      ignore_cache,
      getSiteStatus,
      getSite,
      site_status,
      getSslCertificates,
      sslType,
      SSLList,
      ignore_ssl,
      SSLCertificate,
      createGenerateLetsEncrypt,
      createCustomSslCertificate,
      domainLists,
      createGenerateSsl,
      letsEncryptgeneratingDomainsId,
      event,
      createGenerateCustomSsl,
      getSiteDomainNoPagination,
    } = this.props;

    let domainListsExceptKeplerCloud =
      domainLists &&
      domainLists.filter((n) => !n.name.includes(".keplerhosting.cloud"));
    let { processing } = getSiteDomainNoPagination;
    let letsEncryptProcessing = createGenerateLetsEncrypt.processing;
    let customProcessing = createCustomSslCertificate.processing;
    let createGenerateSslProcessing = createGenerateSsl.processing;
    let createGenerateCustomSslProcessing = createGenerateCustomSsl.processing;
    let getSslCertificatesProcessing = getSslCertificates.processing;
    let getSiteProcessing = getSite.processing;

    const { data, isCheckedLetsEncryptID } = SSLList.data;

    return (
      <>
        <div className="features-block">
          <div className="col-md-12 m-auto px-0">
            <div
              className="d-flex align-items-center justify-content-between"
              id="cache-row"
            >
              <div className="feature-col" id="cache-col">
                <div className="single-feature" id="cache-feature">
                  <div className="feature-information">
                    <img
                      src={sslSvg}
                      alt="feature svg"
                      className="feature-img"
                    />
                    <div className="feature-name-section">
                      <p className="site-tools-heading">SSL Tools</p>
                      <h3>Generate Lets Encrypt</h3>
                    </div>
                  </div>
                  <div className="btn-wrapper">
                    <a>
                      {sslType === "none" ? (
                        <button
                          className="btn-theme btn-outline blue-outline"
                          disabled={
                            sslType === "custom" ||
                            site_status === "pending" ||
                            site_status === "updating" ||
                            site_status === "restoring"
                              ? "disabled"
                              : null
                          }
                          onClick={() =>
                            this.enableDisableGenerateLetsEncrypt("letsencrypt")
                          }
                        >
                          {letsEncryptProcessing ||
                          (getSiteStatus?.success?.event ===
                            "change_site_ssl" &&
                            getSiteStatus?.success?.status === "updating") ? (
                            <div>
                              Activate
                              <Loading />
                            </div>
                          ) : (
                            "Activate"
                          )}
                        </button>
                      ) : (
                        sslType === "letsencrypt" && (
                          <button
                            className="btn-theme btn-outline blue-outline red-btn "
                            disabled={
                              sslType === "custom" ||
                              site_status === "pending" ||
                              site_status === "updating" ||
                              site_status === "restoring"
                                ? "disabled"
                                : null
                            }
                            onClick={() =>
                              this.enableDisableGenerateLetsEncrypt("none")
                            }
                          >
                            {letsEncryptProcessing ||
                            (getSiteStatus?.success?.event ===
                              "change_site_ssl" &&
                              getSiteStatus?.success?.status === "updating") ? (
                              <div>
                                Disable
                                <Loading />
                              </div>
                            ) : (
                              "Disable"
                            )}
                          </button>
                        )
                      )}
                      {sslType === "custom" && (
                        <button
                          className="btn-theme btn-outline-disable"
                          disabled
                        >
                          Activate
                        </button>
                      )}
                      {!sslType && getSiteProcessing && (
                        <button
                          className="btn-theme btn-outline-disable"
                          disabled
                        >
                          <Loading />
                        </button>
                      )}
                    </a>
                  </div>
                </div>
              </div>

              <div className="feature-col" id="cache-col">
                <div className="single-feature" id="cache-feature">
                  <div className="feature-information">
                    <img
                      src={customSSLImg}
                      alt="feature svg"
                      className="feature-img"
                    />
                    <div className="feature-name-section">
                      <p className="site-tools-heading">SSL Tools</p>
                      <h3>Custom SSL Certificate</h3>
                    </div>
                  </div>

                  <div className="btn-wrapper">
                    <a>
                      {sslType === "none" ? (
                        <button
                          className="btn-theme btn-outline blue-outline"
                          disabled={
                            sslType === "letsencrypt" ||
                            site_status === "pending" ||
                            site_status === "updating" ||
                            site_status === "restoring"
                              ? "disabled"
                              : null
                          }
                          onClick={() =>
                            this.enableDisableCustomSslCertificate("custom")
                          }
                        >
                          {customProcessing ||
                          (getSiteStatus?.success?.event ===
                            "change_site_ssl" &&
                            getSiteStatus?.success?.status === "updating") ? (
                            <div>
                              Activate
                              <Loading />
                            </div>
                          ) : (
                            "Activate"
                          )}
                        </button>
                      ) : (
                        sslType === "custom" && (
                          <button
                            className="btn-theme btn-outline blue-outline red-btn "
                            disabled={
                              sslType === "letsencrypt" ||
                              site_status === "pending" ||
                              site_status === "updating" ||
                              site_status === "restoring"
                                ? "disabled"
                                : null
                            }
                            onClick={() =>
                              this.enableDisableCustomSslCertificate("none")
                            }
                          >
                            {customProcessing ||
                            (getSiteStatus?.success?.event ===
                              "change_site_ssl" &&
                              getSiteStatus?.success?.status === "updating") ? (
                              <div>
                                Disable
                                <Loading />
                              </div>
                            ) : (
                              "Disable"
                            )}
                          </button>
                        )
                      )}
                      {sslType === "letsencrypt" && (
                        <button
                          className="btn-theme btn-outline-disable"
                          disabled
                        >
                          Activate
                        </button>
                      )}
                      {!sslType && getSiteProcessing && (
                        <button
                          className="btn-theme btn-outline-disable"
                          disabled
                        >
                          <Loading />
                        </button>
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {sslType === "custom" && (
              <>
                <div className="feature-col" id="ignore-cache-feature">
                  <div className="single-feature mt-4">
                    <div className="ssl-information">
                      <div className="feature-name-section">
                        <h3>Add a Custom SSL Certificate </h3>
                        <p>
                          Welcome to the custom SSL section. Please start by
                          adding your certificates and then click on the Save
                          Button. <br />
                          Once you have added your certificate you will not be
                          able to generate SSL on your domains.
                          <br />
                          <br />
                          If you have any questions please contact our support
                          and we will be happy to help you.
                        </p>
                      </div>
                    </div>
                    {/*<div className="btn-wrapper">*/}
                    {/*    <button className="btn-theme btn-outline blue-outline"*/}
                    {/*            onClick={this.onClickAddRule}>*/}
                    {/*        Add Rule*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </>
            )}

            {/*{getSslCertificatesProcessing?*/}
            {/*    <p className="text-center"><Loading/></p>*/}
            {/*    :*/}
            {/*    <>*/}
            {/*{sslType === "custom"  ?*/}
            {/*    <SSLForm /> : null}*/}
            {getSslCertificatesProcessing ? (
              <p className="text-center">
                <Loading />
              </p>
            ) : (
              <>
                {sslType === "custom" && !SSLCertificate ? <SSLForm /> : null}

                {/*view the certificate*/}
                {/*    {sslType === "custom" && SSLCertificate &&
                                        <>

                                            <div className="ssl-form">
                                                <div className="row">
                                                <div className="col-md-6">
                                                    <div className="mb-50 mt-20">
                                                        <TextField name="name"
                                                                   className="form-control"
                                                                   value={SSLCertificate.name}
                                                                   label="Certificate Name"
                                                                   readonly/>
                                                    </div>
                                                </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="mb-50">
                                                            <TextAreaField name="key_content"
                                                                           className="form-control"
                                                                           value={SSLCertificate.key}
                                                                           label="Add .key contents"
                                                                           readonly
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="mb-50">
                                                            <TextAreaField name="certificate"
                                                                           className="form-control"
                                                                           value={SSLCertificate.certificate}
                                                                           label="Add .cert contents"
                                                                           readonly/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                   }*/}

                <ResponsiveModalForm
                  visible={ignore_ssl.mode}
                  closeModal={() => store.dispatch(toggleSSLFormModal())}
                >
                  <SSLForm />
                </ResponsiveModalForm>

                {SSLCertificate && sslType === "custom" ? (
                  <div className="ignore-urls">
                    <table className="url-table">
                      <tbody>
                        <tr className="url-table-col">
                          <th className="url-table-heading">
                            Certificate Name
                          </th>
                          <th className="url-table-heading" id="actions">
                            Action
                          </th>
                        </tr>
                        {processing ? (
                          <td colSpan={3} className="text-center">
                            <Loading />
                          </td>
                        ) : (
                          sslType === "custom" &&
                          SSLCertificate && (
                            <>
                              <tr className="url-table-col">
                                <td
                                  className="url-table-body onclick"
                                  onClick={() =>
                                    this.onEditCertificate(SSLCertificate.id)
                                  }
                                >
                                  {SSLCertificate.name}
                                </td>
                                <td className="url-table-body" id="actions">
                                  <div className="dropdown dropdown-alt text-center">
                                    <span
                                      className="icon-more"
                                      data-toggle="dropdown"
                                    ></span>
                                    <div
                                      className="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() =>
                                          this.onEditCertificate(
                                            SSLCertificate.id
                                          )
                                        }
                                      >
                                        {" "}
                                        Edit
                                      </a>
                                      <a
                                        href="javascript:void(0);"
                                        className="dropdown-item"
                                        type="button"
                                        onClick={() =>
                                          this.onDeleteCertificate(
                                            SSLCertificate.id
                                          )
                                        }
                                      >
                                        {" "}
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )
                        )}

                        {!SSLCertificate ? (
                          <td colSpan={3} className="text-center  pt-4">
                            No Certificate
                          </td>
                        ) : null}
                      </tbody>
                    </table>
                    {SSLCertificate && sslType === "custom" ? (
                      <div className="button-wrapper inline-buttons text-center d-flex justify-content-end">
                        <button
                          type="submit"
                          className="btn-theme btn-outline blue-outline "
                          onClick={this.onGenerateCustomSSL}
                          disabled={
                            createGenerateCustomSslProcessing ||
                            site_status === "pending" ||
                            site_status === "updating" ||
                            site_status === "restoring"
                              ? "disabled"
                              : null
                          }
                        >
                          {createGenerateCustomSslProcessing ||
                          (getSiteStatus?.success?.event ===
                            "generate_custom_ssl" &&
                            getSiteStatus?.success?.status === "updating") ? (
                            <>
                              Generating <Loading />
                            </>
                          ) : (
                            "Generate"
                          )}
                        </button>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </>
            )}

            {sslType === "letsencrypt" ? (
              <div className="ignore-urls">
                <table className="url-table">
                  <tbody>
                    <tr className="url-table-col">
                      <th className="url-table-heading">Select Domain</th>
                      {/*<th className="url-table-heading">Expiry Date</th>*/}
                      <th className="url-table-heading" id="actions">
                        Select
                      </th>
                    </tr>
                    {processing ? (
                      <tr className="url-table-col">
                        <td className="text-center pt-4">
                          <Loading />
                        </td>
                      </tr>
                    ) : (
                      sslType === "letsencrypt" &&
                      domainLists &&
                      domainLists.length &&
                      domainLists
                        .filter((n) => !n.name.includes(".keplerhosting.cloud"))
                        .map((domainName, index) => (
                          <>
                            <tr className="url-table-col">
                              <td className="url-table-body">
                                {domainName.name}{" "}
                                {"[Expiry Date:" +
                                  fullDateStringNoTime(domainName.expiry_date) +
                                  "]"}
                                {domainName.ssl_type === "letsencrypt" ? (
                                  <span className="badge badge-primary">
                                    {domainName.ssl_type}
                                  </span>
                                ) : null}
                              </td>
                              {/*<td className="url-table-body">
                                                          {fullDateStringNoTime(domainName.expiry_date)}
                                                        </td>*/}
                              {/*  <td className="url-table-body" id="actions">
                                                            <CheckField name="name"
                                                                        className="form-control"
                                                                        value={domainName.id}
                                                                        id={domainName.id}
                                                                        checked={[...this.state.data].includes((domainName.id).toString())}
                                                                        noLabel
                                                                        onChange={(e) => this.onChange(index, e.target.value, e)}/>
                                                        </td>*/}

                              <td className="url-table-body" id="actions">
                                {(site_status === "pending" ||
                                  site_status === "updating" ||
                                  site_status === "restoring") &&
                                event === "generate_ssl" &&
                                letsEncryptgeneratingDomainsId &&
                                letsEncryptgeneratingDomainsId.includes(
                                  domainName.id
                                ) ? (
                                  <>
                                    Generating <Loading />
                                  </>
                                ) : (
                                  <CheckField
                                    name="name"
                                    className="form-control"
                                    value={domainName.id}
                                    id={domainName.id}
                                    checked={
                                      [...data].includes(
                                        domainName.id.toString()
                                      ) ||
                                      isCheckedLetsEncryptID.includes(
                                        domainName.id.toString()
                                      )
                                    }
                                    noLabel
                                    onChange={(e) =>
                                      this.onChangeLetsEncrypt(
                                        index,
                                        e.target.value,
                                        e
                                      )
                                    }
                                    disabled={
                                      site_status === "pending" ||
                                      site_status === "updating" ||
                                      site_status === "restoring"
                                        ? "disabled"
                                        : null
                                    }
                                  />
                                )}
                              </td>
                            </tr>
                          </>
                        ))
                    )}

                    {domainListsExceptKeplerCloud &&
                    !domainListsExceptKeplerCloud.length &&
                    !processing ? (
                      <tr className="url-table-col">
                        <td colSpan={2} className="text-center  pt-4">
                          No Domains
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
                {domainListsExceptKeplerCloud &&
                domainListsExceptKeplerCloud.length &&
                sslType === "letsencrypt" ? (
                  <div className="button-wrapper inline-buttons text-center d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn-theme btn-outline blue-outline "
                      onClick={this.onGenerateSSL}
                      disabled={
                        site_status === "pending" ||
                        site_status === "updating" ||
                        site_status === "restoring"
                          ? "disabled"
                          : null
                      }
                    >
                      {createGenerateSslProcessing ||
                      (getSiteStatus?.success?.event === "generate_ssl" &&
                        getSiteStatus?.success?.status === "updating") ? (
                        <>
                          Generating <Loading />
                        </>
                      ) : (
                        "Generate"
                      )}
                    </button>
                  </div>
                ) : null}
              </div>
            ) : null}

            {/*      { SSLCertificate && sslType === 'custom' ?
                            <div className="ignore-urls">
                                <table className="url-table">
                                    <tbody>
                                    <tr className="url-table-col">
                                        <th className="url-table-heading">Select Domain</th>
                                        <th className="url-table-heading"
                                            id="actions">Select
                                        </th>
                                    </tr>
                                    {
                                        processing ?
                                            <td colSpan={3} className="text-center"><Loading/></td>
                                            :
                                             domainLists && domainLists.length && domainLists.filter(n => !n.name.includes('.keplerhosting.cloud')).map((domainName, index) => (
                                                <>
                                                    <tr className="url-table-col">
                                                        <td className="url-table-body">{domainName.name}
                                                            {domainName.ssl_type ==="custom"?
                                                            <span className="badge badge-primary">{domainName.ssl_type}</span>:null}
                                                        </td>
                                                        <td className="url-table-body" id="actions">
                                                            {(site_status === 'pending' || site_status === 'updating' || site_status === 'restoring') &&
                                                            event === 'generate_ssl' &&   letsEncryptgeneratingDomainsId && letsEncryptgeneratingDomainsId.includes((domainName.id)) ?
                                                                <>Generating <Loading/></> :
                                                                <CheckField name="name"
                                                                            className="form-control"
                                                                            value={domainName.id}
                                                                            id={domainName.id}
                                                                            checked={[...this.state.dataCustom].includes((domainName.id).toString())}
                                                                            noLabel
                                                                            onChange={(e) => this.onChangeCustom(index, e.target.value, e)}
                                                                            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                />
                                                            }

                                                        </td>
                                                    </tr>
                                                </>
                                            ))}

                                    {domainListsExceptKeplerCloud && !domainListsExceptKeplerCloud.length ?
                                        <td colSpan={3} className="text-center  pt-4">No Domains</td>
                                        : null}

                                    </tbody>
                                </table>
                                {(domainListsExceptKeplerCloud && domainListsExceptKeplerCloud.length) && sslType === 'custom' ?
                                    <div
                                        className="button-wrapper inline-buttons text-center d-flex justify-content-end">
                                        <button type="submit" className="btn-theme btn-outline blue-outline "
                                                onClick={this.onGenerateCustomSSL}
                                                disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                        >
                                            {createGenerateSslProcessing || (getSiteStatus?.success?.event === "generate_ssl" && getSiteStatus?.success?.status === "updating") ? <>
                                                Generating <Loading/></> : "Generate"}
                                        </button>
                                    </div>
                                    : null}
                            </div>
                            : null}*/}
          </div>
          {sslType === "custom" && <SSLWorker />}
          {/* </div> */}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    getSite,
    getSites,
    getSiteStatus,
    getSslCertificates,
    SSLList,
    siteDashboard,
    createGenerateLetsEncrypt,
    createCustomSslCertificate,
    createGenerateSsl,
    getSiteDomainNoPagination,

    createGenerateCustomSsl,
  } = state;

  let { success } = getSiteDomainNoPagination;
  let letsEncryptgeneratingDomainsId =
    getSiteStatus && getSiteStatus.success && getSiteStatus.success.domain_id;
  let event =
    getSiteStatus && getSiteStatus.success && getSiteStatus.success.event;

  let domainLists = success && success.data && success.data.data;

  let sslType = getSite && getSite.success && getSite.success.ssl_type;
  let { site_status } = siteDashboard.data;
  let SSLCertificate =
    getSslCertificates &&
    getSslCertificates.success &&
    getSslCertificates.success.data &&
    getSslCertificates.success.data.data;

  let { ignore_ssl } = SSLList.data;

  return {
    getSite,
    getSites,
    getSiteStatus,
    site_status,
    getSslCertificates,
    SSLList,
    ignore_ssl,
    SSLCertificate,
    sslType,
    createGenerateLetsEncrypt,
    createCustomSslCertificate,
    createGenerateSsl,
    domainLists,
    // getSiteDomain,
    letsEncryptgeneratingDomainsId,
    event,
    createGenerateCustomSsl,
    getSiteDomainNoPagination,
  };
}

export default withRouter(connect(mapStateToProps)(SSL));
