
export function onUserFormChange( e ) {
  let {name, value} = e.target;
  return function ( dispatch ) {
    dispatch({ type:'ON-USER-FORM-CHANGE', name, value});
  }
}

export function selectCountry( value ) {
  let name = "country_id"
  return function ( dispatch ) {
    dispatch({ type:'ON-USER-FORM-CHANGE', name,  value});
  }
}

export function onUserRoleFormChange( value ) {
  let name = "role";
  return function ( dispatch ) {
    dispatch({ type:'ON-USER-FORM-CHANGE', name, value});
  }
}

export function onUserOrganizationAssign( value) {
  return function ( dispatch ) {
    dispatch({ type:'ON-USER-ORGANIZATION-ASSIGN', value});
  }
}
export function setOrganizationID( org_id ) {
  return function (dispatch,){
    dispatch({type:'SET-ORGANIZATION-ID', org_id });
  }
}

export function onUserFormSubmit( e ) {
  e && e.preventDefault();
  return function ( dispatch ) {
    dispatch({ type:'ON-USER-FORM-SUBMIT' });
  }
}

export function userFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:'USER-FORM-SERVER-VALIDATION-ERRORS', validationErrors });
  }
}

export function setDefaultUserData() {
  return function (dispatch) {
    dispatch({type:'SET-DEFAULT-USER-DATA',});
  }
}

export function cancelUsersFormDispatch() {
  return function (dispatch) {
    dispatch({ type:'CANCEL-USER-FORM-DISPATCH' });
  }
}

export function setAdminDataForUpdate()
{
  return function (dispatch, getState) {
    let { getOrganizationUser } = getState();
    let user = getOrganizationUser && getOrganizationUser.success;
    dispatch({type:'SET-SPECIFIC-USER-DATA',  user});
  }
}