import axios from 'axios'
import {apiBaseUrl,webpageBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import {scrollToError} from '../../../helpers/ScrollHelper'
import {
    cancelOrganizationBlockFormDispatch,
    organizationBlockFormServerValidationErrors
} from "../../../pages/site/organization-block/form/OrganizationBlockForm-Action";

function _success(success) {
    return {type: 'CREATE_ORGANIZATION_BLOCK_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_ORGANIZATION_BLOCK_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_ORGANIZATION_BLOCK_PROCESSING', processing}
}

function createOrganizationBlock(site_id , orz_id = null) {
    return (dispatch, getState) => {

        let {organizationBlockForm, siteDashboard} = getState();
        let org_id = organizationBlockForm.data.payload.organization_id
        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))
        let organization_id = orz_id || siteDashboard && siteDashboard.data.org_id

        axios({
            url: apiBaseUrl(`admin/organizations/${organization_id}/sites/${site_id}/organization`),
            method: 'post',
            dataType: 'json',
            data: organizationBlockForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                // window.location.href ="http://localhost:3000/organizations/"+ org_id +"/sites/"+site_id+"/";
                window.location.href = webpageBaseUrl( "organizations/"+org_id +"/sites/"+site_id+"/launchpad");

                // this.props.history.push(urlTrailWithoutLastItem());
                // store.dispatch(getSiteStatus(site_id,orz_id))
                // store.dispatch(getOrganizationsBlock())
                // store.dispatch(cancelOrganizationBlockFormDispatch())

            }).catch(function (error) {
            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)

            store.dispatch(cancelOrganizationBlockFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(organizationBlockFormServerValidationErrors(error.response.data.errors))
                scrollToError()
            }
        })
    }
}

export default createOrganizationBlock
