import {PlanListState} from './PlanList-State'

export default function planListReducer(state = PlanListState, action) {

    let {data} = {...state};
    let {mode,plan_id} = action;

    switch (action.type) {

        case 'SET-PLAN-MODE':
            data.mode = mode;
            return {...state, data};

        case 'SET-PLAN-ID-FOR-UPDATE':
            data.payload.plan_id = plan_id;
            return {...state, data};

        case 'SET-DEFAULT-PLAN-UPDATE':
            data.payload.plan_id = ``;
            return {...state, data};

        default:
            return state
    }
}
