import store from '../../../Store';
import {toggleURLQuery} from "../../../helpers/UrlHelper";
import getCoupons from "../../../ajax/actions/coupon/coupon_gets"

export function toggleCouponListFilter(e, history) {
    return function (dispatch) {
        let {name, value} = e.target;
        toggleURLQuery(name, value, history)
        dispatch({type: 'TOGGLE-COUPON-LIST-FILTER', name, value, history});

        if (name === 'limit') {
            store.dispatch(getCoupons());
            toggleURLQuery('page', '', history)
        } else {
            store.dispatch(getCoupons());
        }
    }
}

export function setDefaultCouponFiltersToURL(history) {
    return (dispatch, getState) => {
        let {couponList} = getState()
        let {data} = couponList
        let {params} = data;
        Object.keys(params).map(key => {
            if (params[key] !== `` && params[key] !== null && params[key] !== undefined) {
                toggleURLQuery(key, params[key], history)
            }
        })
    }
}