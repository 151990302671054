import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import getPermissions from "../../../ajax/actions/permission/permissions_get";
import updateRole from "../../../ajax/actions/role/role_put";
import {
  listPermissionForAssign,
  onRoleFormModeChange,
  setDefaultRoleForm,
  setRoleDataForUpdate
} from "./RoleForm-Action";
import createRole from "../../../ajax/actions/role/role_post";
import getRole from "../../../ajax/actions/role/role_get";
import {endOfURL} from "../../../helpers/UrlHelper";

class RoleFormWorker extends React.Component
{

  constructor(props) {
    super(props);
    store.dispatch( getPermissions() );
    if(props.match.params.id) {
      store.dispatch(getRole(props.match.params.id));
      // store.dispatch(setRoleDataForUpdate());
      store.dispatch(onRoleFormModeChange(props.match.params.type));
    }
  }

  componentDidMount()
  {
    // // store.dispatch(getRole());
    if( endOfURL() ===`create`) {
      store.dispatch(setDefaultRoleForm());
    }

  }

  componentDidUpdate(prev)
  {
    let {  dispatchAPI, mode,  getPermissions } = this.props;

    if(this.props.getRole !== prev.getRole) {
      let {success, error} = this.props.getRole;
      if(success) {
        store.dispatch(setRoleDataForUpdate());
      }

      if( error ) {
      }

    }

    if(getPermissions !== prev.getPermissions){
      let { success } = getPermissions;
      if( success ) {
        store.dispatch(listPermissionForAssign());
      }
    }

    // if( this.props.createRole !== prev.createRole ) {
    //   let { success } = this.props.createRole;
    //   if( success ) {
    //     window.location.href = '/role';
    //   }
    // }

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if( mode === 'create' ) {
          store.dispatch( createRole() )
        } else {
          store.dispatch(updateRole( this.props.match.params.id ))
        }
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { roleForm, getRole, getPermissions, createRole } = state;
  let {dispatchAPI, mode, payload} = roleForm.data;
  return { roleForm, dispatchAPI, getRole, mode, payload, getPermissions, createRole };
}
export default withRouter(connect( mapStateToProps )( RoleFormWorker ));