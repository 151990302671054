import React from 'react';
import cacheSvg from "../../../../assets/images/cache-clear.svg";
import EnableDisable from "../../../../assets/images/enable-disable.svg";
import {connect} from "react-redux";
import store from "../../../../Store";
import monitorPost from "../../../../ajax/actions/kepler-tools/monitor_post";
import {withRouter} from 'react-router';
import FeatureBlockWorker from "./FeatureBlockWorker";
import ResponsiveModalForm from "../../../../components/shared/modals/ResponsiveModalForm";
import {
    toggleCacheIgnoreFormModal
} from "./Cache-Action";
import debug from "../../../../ajax/actions/kepler-tools/debug_post";
import restartPhp from "../../../../ajax/actions/kepler-tools/restart_php_post";
import getSite from "../../../../ajax/actions/sites/site_get";
import Loading from "../../../../components/shared/loaders/Loading";
import enableCache from "../../../../ajax/actions/kepler-tools/enable_cache_post";
import disableCache from "../../../../ajax/actions/kepler-tools/disable_cache_post";
import clearCache from "../../../../ajax/actions/kepler-tools/clear_cache_post";
import CacheIgnorePrimaryView from "./CacheIgnorePrimaryView";
import IgnoreCacheForm from "../../ignoreCache/form/IgnoreCacheForm"
import { onIgnoreCacheFormModeChange} from "../../ignoreCache/form/IgnoreCacheForm-Action"
import getCacheIgnore from "../../../../ajax/actions/kepler-tools/cache_ignore_get";
import deleteCacheIgnore from "../../../../ajax/actions/kepler-tools/cache_ignore_delete";


class Cache extends React.Component {
    enabledisablemonitor = (value) => {
        const payload = {
            "monitor": value
        }
        store.dispatch(monitorPost(payload));

    }

    debug = (value) => {
        const payload = {
            "debug": value
        }
        store.dispatch(debug(payload));
    }

    restartPhp = () => {
        store.dispatch(restartPhp(this.props.match.params.id));
    }

    onEnableCache = () => {
        store.dispatch(enableCache(this.props.match.params.id));
    }

    onDisableCache = () => {
        store.dispatch(disableCache(this.props.match.params.id));
    }

    clearCache = () => {
        store.dispatch(clearCache(this.props.match.params.id));
    }

    componentDidMount() {
        if(this.props.organizationDashboard.organization_id) {
            store.dispatch(getSite(this.props.match.params.id, this.props.organizationDashboard.organization_id));
        }
    }

    onEditCacheIgnore = (id) => {
        store.dispatch(getCacheIgnore(this.props.match.params.id, id));
        store.dispatch(onIgnoreCacheFormModeChange("update"));
        store.dispatch(toggleCacheIgnoreFormModal());
    }

    onDeleteCacheIgnore = (id) => {
        store.dispatch(deleteCacheIgnore(this.props.match.params.id,id))
    }

    render() {
        let {
            cache, ignore_cache, enableCache, disableCache,
            getSiteStatus, clearCache, cache_event,
            getSite, site_status, domainLists, cacheIgnoreList,ignoreCacheUpdatingId
        } = this.props

        let {processing} = getSite
        let enableCacheProcessing = enableCache.processing
        let disableCacheProcessing = disableCache.processing
        let clearCacheProcessing = clearCache.processing
        let ignoreCachePaths = getSite && getSite.success && getSite.success.ignoredCachePaths
        const primaryDomain = (domainLists || []).find(d => d.is_primary === 1) || '';

        return (
            <>
                <FeatureBlockWorker/>
                <div className="features-block">
                        <div className="col-md-12 m-auto px-0">
                                <div className="d-flex align-items-center justify-content-between" id="cache-row">
                                    <div className="feature-col" id="cache-col">
                                        <div className="single-feature" id="cache-feature">
                                            <div className="feature-information">
                                                <img src={cacheSvg} alt="feature svg" className="feature-img"/>
                                                <div className="feature-name-section">
                                                    <p className="site-tools-heading">Cache Tools</p>
                                                    <h3>Clear Cache</h3>
                                                </div>
                                            </div>
                                            <div className="btn-wrapper">
                                                    <button className={cache ? "btn-theme btn-outline blue-outline" : "btn-theme btn-outline-disable"}
                                                            disabled={!cache || site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                            onClick={() => this.clearCache()}>
                                                        {clearCacheProcessing || (getSiteStatus?.success?.event === "clear_cache" && getSiteStatus?.success?.status === "updating") ?
                                                            <div>Clear Cache <Loading/></div> :
                                                            "Clear Cache"}</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="feature-col" id="cache-col">
                                        <div className="single-feature" id="cache-feature">
                                            <div className="feature-information">
                                                <img src={EnableDisable} alt="feature svg" className="feature-img"/>
                                                <div className="feature-name-section">
                                                    <p className="site-tools-heading">Cache Tools</p>
                                                    <h3>Cache</h3>
                                                </div>
                                            </div>

                                            <div className="btn-wrapper">
                                                <a href="/#" onClick={ev => {ev.preventDefault();}}>
                                                    {cache && cache === 1 ?
                                                        <button className="btn-theme btn-outline blue-outline"
                                                                type="button"
                                                                disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                onClick={() => this.onDisableCache()}
                                                        >
                                                            {disableCacheProcessing || cache_event === "disable_cache" ? <>
                                                                Disable <Loading/></> : "Disable"}</button>
                                                        :
                                                        <button className="btn-theme btn-outline blue-outline"
                                                                type="button"
                                                                disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                                                                onClick={() => this.onEnableCache()}>
                                                            {enableCacheProcessing || cache_event === "enable_cache" ? <>
                                                                Enable <Loading/></> : "Enable"}</button>
                                                    }
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            {cache && cache_event !== "disable_cache" && cache_event !== "enable_cache" ?
                                <>

                                <div className="d-flex align-items-center justify-content-between mt-4" id="cache-row">
                                    <ResponsiveModalForm visible={ignore_cache.mode}
                                                        closeModal={() => store.dispatch(toggleCacheIgnoreFormModal())}>
                                        <IgnoreCacheForm/>
                                    </ResponsiveModalForm>
                                    <CacheIgnorePrimaryView/>
                                </div>


                                <div className="ignore-urls">
                                        <table className="url-table">
                                            <tbody>
                                            <tr className="url-table-col">
                                                <th className="url-table-heading">Ignore Url</th>
                                                <th className="url-table-heading"
                                                    id="actions">Actions</th>
                                            </tr>
                                            {
                                                processing ?
                                                <td colSpan={5} className="text-center"><Loading/></td>

                                                :
                                                <>
                                                {cacheIgnoreList.length  ?
                                                        cacheIgnoreList.map(item => {
                                                        return (
                                                            <>

                                                                <tr className="url-table-col">
                                                                    <td className="url-table-body">{primaryDomain.name}{item.path}</td>
                                                                    {(cache_event === "add_ignore_cache" || cache_event === "delete_ignore_cache" || cache_event === "update_ignore_cache") && ignoreCacheUpdatingId === item.id ?
                                                                        <td className="url-table-body"
                                                                            id="actions">
                                                                            {cache_event === "add_ignore_cache" && <>Adding<Loading/></>}
                                                                            {cache_event === "delete_ignore_cache" && <>Deleting<Loading/></>}
                                                                            {cache_event === "update_ignore_cache" && <>Updating<Loading/></>}
                                                                        </td>
                                                                        :
                                                                        <td className="url-table-body" id="actions">
                                                                            <div
                                                                                className="dropdown dropdown-alt text-center">
                                                                                    <span className="icon-more"
                                                                                          data-toggle="dropdown"></span>
                                                                                <div
                                                                                    className="dropdown-menu"
                                                                                    aria-labelledby="dropdownMenuButton">
                                                                                    <a href="/#"
                                                                                       className="dropdown-item"
                                                                                       type="button"
                                                                                       onClick={ev => {
                                                                                           this.onEditCacheIgnore(item.id);
                                                                                           ev.preventDefault()
                                                                                       }}>Edit
                                                                                    </a>
                                                                                    <a href="/#"
                                                                                       className="dropdown-item"
                                                                                       type="button"
                                                                                       onClick={ev => {
                                                                                           this.onDeleteCacheIgnore(item.id);
                                                                                           ev.preventDefault()
                                                                                       }}>Delete
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    }
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                :
                                                    <tr>
                                                        <td colSpan="7" className="text-center pt-4">No Ignore Rules</td>
                                                    </tr>
                                                }
                                                </>
                                            }

                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                :
                                null}
                        </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        getSite,
        getSites,
        featureBlocklist,
        getSiteStatus,
        getSiteDomain,
        siteDashboard,
        enableCache,
        disableCache,
        clearCache,
        organizationDashboard,
        getsCacheIgnore,
        getCacheIgnore,
    } = state;
    let {success} = getsCacheIgnore
    let cacheIgnoreList = success && success.data && success.data.data && success.data.data.data

    let debug_event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event
    let monitor = getSite && getSite.success && getSite.success.monitor
    let wp_flag = getSite && getSite.success && getSite.success.wp_flag
    let {site_status} = siteDashboard.data;

    let debugMode = getSite && getSite.success && getSite.success && getSite.success.debug_mode
    let cache_event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event
    let ignoreCacheUpdatingId = getSiteStatus && getSiteStatus.success && getSiteStatus.success.ignore_cache_id

    let cache = getSite && getSite.success && getSite.success.site_meta && getSite.success.site_meta.enable_cache

    let {modal, public_path_modal, ignore_cache, php_version, password_protection} = featureBlocklist.data;
    let domainLists =
        getSiteDomain &&
        getSiteDomain.success &&
        getSiteDomain.success.data &&
        getSiteDomain.success.data.data &&
        getSiteDomain.success.data.data.data;

    return {
        getSite,
        getsCacheIgnore,
        getSites,
        monitor,
        ignore_cache,
        modal,
        public_path_modal,
        featureBlocklist,
        debug_event,
        debugMode,
        wp_flag,
        getSiteStatus,
        siteDashboard,
        site_status,
        cache, cache_event,
        enableCache,
        disableCache,
        clearCache,
        php_version,
        password_protection,
        organizationDashboard,
        domainLists,
        cacheIgnoreList,
        getCacheIgnore,
        ignoreCacheUpdatingId
    };
}

export default withRouter(connect(mapStateToProps)(Cache));
