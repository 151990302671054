import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";

function _success(success) {
  return{ type: 'GETS_REDIRECTS_SUCCESS',success };
}
function _error(error) {
  return{ type: 'GETS_REDIRECTS_ERROR',error };
}
function _processing(processing) {
  return{ type: 'GETS_REDIRECTS_PROCESSING', processing };
}

function getRedirects(id,organization_id=null) {
  return ( dispatch , getState) => {
    dispatch(_processing(true));
    dispatch(_error(null));
    let {organizationDashboard} = getState();
    let org_id = organization_id || organizationDashboard.data.organization_id;

      axios({
        url: apiBaseUrl(`admin/organizations/${org_id}/sites/${id}/redirect_rule `),
        method: "get",
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + loginToken()
        }
      }).then(function (res) {
        dispatch(_processing(false));
        dispatch(_success(res));

      }).catch(function (error) {
        handleError(error);
        dispatch(_error(error));
        dispatch(_processing(false));
      });
    }
}
export default getRedirects;
