import React from 'react';
import store from "../../../../Store";
import {togglePublicPathFormModal} from "./FeatureBlock-Action";
import pathSvg from "../../../../assets/images/publicpath.svg";
import {connect} from "react-redux";
import Loading from "../../../../components/shared/loaders/Loading";

class PublicPathPrimaryView extends React.Component {
    render() {
        let {event, site_status} = this.props
        return (
            <div className="col-xl-12 col-sm-12 feature-col">
                <div className="single-feature">
                <div className="feature-information">
                    <img src={pathSvg} alt="feature svg" className="feature-img"/>
                    <div className="feature-name-section">
                        <p className="site-tools-heading">Site Tools</p>
                        <h3>Public Path</h3>
                    </div>
                </div>
                    <p className="site-tools-description">The Kepler Hosting Public Path tool - This tool is used to change the public path of your websites. If you have any questions contact our support team.</p>
                    <div className="btn-wrapper">
                        <button  className="btn-theme btn-outline blue-outline" data-toggle="modal"
                           data-target="#public-path"
                           onClick={() => store.dispatch(togglePublicPathFormModal())}
                                 disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating'? 'disabled' : null}>
                        {event === "update_site_public_path" ? <>Change <Loading/></> : "Change"}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let {getSite, getSiteStatus,siteDashboard} = state;
    let { site_status } = siteDashboard.data;

    let event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event

    return {event, getSite, getSiteStatus,siteDashboard,site_status};

}

export default connect(mapStateToProps)(PublicPathPrimaryView);
