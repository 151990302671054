import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import OrganizationBlockFormWorker from './OrganizationBlockFormWorker'
import {onOrganizationBlockFormChange, onOrganizationBlockFormSubmit} from "./OrganizationBlockForm-Action";
import Loading from "../../../../components/shared/loaders/Loading";
import Select from "react-select";
import swal from "sweetalert";
import ChangeOrg from '../../../../assets/images/change-org.svg'
import {withRouter} from 'react-router';


class organizationBlockForm extends React.Component {

    OrganizationBlockSubmit = (e) => {
        e.preventDefault();
        swal("Are you sure you want to change Organization? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Yes",
                    value: e
                }
            }
        }).then((e) => {
            if (e) store.dispatch( onOrganizationBlockFormSubmit()  )
        })
    };

    onChange = (e) => {
        if(e === null){
            store.dispatch(onOrganizationBlockFormChange("Organization", ""));
        }
        else {
            store.dispatch(onOrganizationBlockFormChange(e.name, e.value,));
        }
    };

    render() {
        let {
            errors, getSite, changeOrganizationsList,site_status
        } = this.props;
        let clearable = true;
        let organizationList = [];
        changeOrganizationsList && changeOrganizationsList.map(organization => {
            organizationList.push({value: organization.id, label: organization.name})
        });
        let {processing} = getSite.processing

        return (
            <>
                <OrganizationBlockFormWorker/>
                <form className="mt-10" onSubmit={this.OrganizationBlockSubmit}>
                    <div className="padded-block">
                        <div className="d-flex justify-content-between align-items-center col-md-12 px-0" id="form-block-section">
                            <div className="d-flex text-section">
                                <img alt="logo" src={ChangeOrg}/>
                                <div>
                                    <p id="site-tools">Organisation Tool</p>
                                    <h3>Change Organisations</h3>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between px-0 input-and-button-section">
                                <div className="px-0 input-section mr-5">
                                    <Select
                                        name="Organization"
                                        isClearable={clearable}
                                        placeholder="Select Organization"
                                        value={organizationList.id}
                                        options={organizationList}
                                        onChange={this.onChange}
                                        errors={errors && errors.organization_id}
                                    />

                                </div>
                                <div className="button-wrapper ml-2">
                                    <button type="submit" className="btn-theme btn-outline" id="admin-org-btn"
                                            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring'? 'disabled' : null}>
                                        {processing ? <Loading/> : `Submit`}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="description-section mt-5">
                            <p>Changing organisation is a easy way to move a site from one client to another client. If a client requests a transfer please use this tool and not the internal migration.</p>
                            <p id="bold-text">Once this process is done please make sure the site is moved to the correct organisation.</p>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {organizationBlockForm, createSiteOrganizationChange, getSite,getSiteStatus,getOrganizationForBlock,
        siteDashboard,getOrganizationsBlock
    } = state;
    
    let { site_status,org_id } = siteDashboard.data;

    let changeOrganizationsList =
        getOrganizationsBlock &&
        getOrganizationsBlock.success &&
        getOrganizationsBlock.success.data &&
        getOrganizationsBlock.success.data.data

    return {
        organizationBlockForm, createSiteOrganizationChange, getSite,getSiteStatus,getOrganizationForBlock,
        changeOrganizationsList,org_id,site_status
    };
}

export default withRouter(connect(mapStateToProps)(organizationBlockForm));