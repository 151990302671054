import React from 'react';
import Button from "../../../components/shared/buttons/Button";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Loading from "../../../components/shared/loaders/Loading";

class PaymentHeading extends React.Component {


    render() {
        let {organization_name, organization_id, dueAmount, getPayments} = this.props;
        let {processing} = getPayments
        return (

            <div className="heading-block pr-60">
                <div className="title-block">
                    <h2>Payments</h2>
                    <span><Link to="/organizations">Organizations</Link></span>
                    <span><Link to={`/organizations/${organization_id}/sites`}>{organization_name}</Link></span>
                    <span>Payments</span>
                </div>
                <div className="payment-due-title d-flex">
                    <b>Invoice =&nbsp;&nbsp;</b> <b className="due-amount"> {processing? <Loading/>:  dueAmount}</b>
                </div>

                <div className="button-wrapper">
                    <Link to={`/organizations/${organization_id}/sites/create`}>
                        <Button className="btn-theme btn-dark-blue"
                                title={`Add Site`}
                                text={`Add Site`}/>
                    </Link>

                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    let {organizationDashboard, getInvoice, siteDashboard, paymentList, getPayments} = state;
    let {organization_name, organization_id} = organizationDashboard.data;
    let {params} = paymentList.data;
    let dueAmount = getPayments && getPayments.success && getPayments.success.data && getPayments.success.data.due

    return {
        organizationDashboard, organization_name, getInvoice,
        siteDashboard, organization_id, params,getPayments, dueAmount
    };
}

export default connect(mapStateToProps)(PaymentHeading);
