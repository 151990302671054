import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultPriceFormState = {
    payload: {
        price: ``,
        site_type: ``,
        currency: ``
    },

    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: endOfURL(),
    message: ``
};

export const PriceFormState = {data: DefaultPriceFormState};