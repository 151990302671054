import React from 'react';
import UserWorker from "./UserWorker";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import TableHead from "../../../components/shared/table/TableHead";
import store from "../../../Store";
import {toggleAllOrganizationUserListFilter} from "./UserList-Actions";
// import deleteOrganizationUser from "../../../ajax/actions/organization-user/user_delete";
// import swal from "sweetalert";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import Page from "../../../components/shared/pages/Page";
import UserListFilters from "./UserListFilters";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";

class User extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch( setDashboardMenu(''));
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    toggleSort = (field) => {
        store.dispatch(toggleAllOrganizationUserListFilter({
            target: {name: `sort_by`, value: field }
        }))
    };

    // deleteConfirm = (id) => {
    //     swal("Are you sure you want to delete this user? ", {
    //         buttons: {
    //             cancel: "Cancel",
    //             catch: {
    //                 text: "Delete",
    //                 value: id
    //             }
    //         }
    //     }).then((value) => {
    //         if (value) this.delete(value)
    //     })
    // };
    //
    // delete(id) {
    //     store.dispatch(deleteOrganizationUser(id));
    // }


    render() {
        let {getAllOrganizationUsers, users, fields, params, pages, org_id} = this.props;
        let {processing,error} = getAllOrganizationUsers;
        if (error)
        {
            this.props.history.push('/');
        }
        return (
            <Page title="Users" processing={processing}>
                <UserWorker/>
                <UserListFilters title="users" organization_id = {  this.props.match.params.org_id }/>
                <div className="table-block table-responsive pr-60">

                    <table className="table table-striped  ">
                        <TableHead fields={fields}
                                   params={params}
                                   noActions={true}
                                   onClick={this.toggleSort}/>

                        <tbody>
                        {processing ? <td colSpan={4} className="text-center"><Loading/></td> :
                            users && users.length ? users.map((user, key) => {
                                    return (
                                        <tr key={key}>                                            
                                            <td >
                                                {(user.first_name)} {(user.middle_name)} {(user.last_name)}
                                                </td>
                                            <td>{user.email}</td>
                                            <td>{(user.role)}</td>
                                            <td>
                                                <div className="dropdown dropdown-alt text-center">
                                            <span className="dropdown-toggle"
                                                   data-toggle="dropdown">
                                                <span className="icon-more"/>
                                            </span>
                                                    <div className="dropdown-menu">
                                                        {user?.organization ?
                                                        <Link to={`/organizations/${user?.organization.id}/users/${user.id}/update`} className={`link-edit`}>
                                                            Edit
                                                        </Link>
                                                            : null}
                                                        {/*<a href="/#"*/}
                                                        {/*   onClick={ev => {this.deleteConfirm(user.id);ev.preventDefault()}}*/}
                                                        {/*   className={`link-delete`}>*/}
                                                        {/*    Delete*/}
                                                        {/*</a>*/}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) :
                                <tr>
                                    <td colSpan={4} className="text-center">No Users Available</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {users && users.length ? (
                    <div className="fixed-footer pr-60" id="inside-organization-pagination">
                        <PaginationButtons pages={pages}
                                           processing={processing}
                                           fetchAction={toggleAllOrganizationUserListFilter}/>
                    </div>
                ) : null}
            </Page>
        )
    }
}

function mapStateToProps(state) {
    let { allOrganizationUsersList, getAllOrganizationUsers} = state;
    let {params, fields} = allOrganizationUsersList.data;
    let {success} = getAllOrganizationUsers;
    let users =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data;
    let current_page = success && success.data && success.data.data.current_page;
    let last_page = success && success.data && success.data.data.last_page;
    let total = success && success.data && success.data.data.total;
    let pages = {current_page, last_page, total};

    return {getAllOrganizationUsers ,allOrganizationUsersList, params, fields, users, pages};
}

export default connect(mapStateToProps)(User);