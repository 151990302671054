import {MigrationFormState} from './MigrationForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../helpers/ErrorHelper'
import {isObject} from "../../../helpers/DataHelper";

export default function MigrationFormReducer(state = MigrationFormState, action) {

    let {data} = {...state};
    let {validationErrors, name, value, mode, error, site_id,migration} = action;

    switch (action.type) {

        case 'ON-MIGRATION-FORM-CHANGE':
                data.payload[name] = value;
            buildErrors();
            return {...state, data};

        case 'ON-MIGRATION-FORM-MODE-CHANGE':
            data.mode = mode;
            buildErrors();
            return {...state, data};

        case 'SET-MIGRATION-ERROR':
            data.message = error;
            buildErrors();
            return {...state, data};

        case 'SET-SITE-ID':
            data.site_id = site_id;
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-MIGRATION-FORM':
            data = {
                payload: {
                    status: '',
                },
                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: `update`,
                message: ``
            };
            buildErrors();
            return {...state, data};

        case 'SET-MIGRATION-DATA-FOR-UPDATE':

            data.payload = {
                status:migration && migration.status
            };
            buildErrors();
            return {...state, data};

        case 'SET-MIGRATION-DATA-FOR-VIEW':

            data.view = {
                id:migration.id,
                customerName:migration.customer_name,
                server_ip: migration.server_ip,
                server_port:migration.server_port,
                server_username:migration.server_username,
                server_password:migration.server_password,
                type: migration.type,
                db_login_url:migration.db_login_url,
                is_multi_site: migration.is_multi_site,
                is_ecommerce: migration.is_ecommerce,
                wordpress_username: migration.wordpress_username,
                wordpress_password: migration.wordpress_password,
                wordpress_admin_url: migration.wordpress_admin_url,
                create_new_site: migration.create_new_site,
                site_name: migration.site_name,
                status:migration.status
            };
            buildErrors();
            return {...state, data};

        case 'CANCEL-MIGRATION-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-MIGRATION-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'MIGRATION-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            );
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, validationErrors} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
                if (field === `status`) {
                    if (!_(field)) newError(field, 0, `is required`)
                }
        }

        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}
