import {DiscountFormState} from './DiscountForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../helpers/ErrorHelper'
import {endOfURL} from '../../../helpers/UrlHelper'
import {isObject} from "../../../helpers/DataHelper";

export default function discountFormReducer(state = DiscountFormState, action) {

    let {data} = {...state};
    let {validationErrors, name, value, discount, mode} = action;
    switch (action.type) {

        case 'ON-DISCOUNT-FORM-CHANGE':
            data.payload[name] = value;
            buildErrors();
            return {...state, data};

        case 'ON-DISCOUNT-FORM-MODE-CHANGE':
            data.mode = mode;
            buildErrors();
            return {...state, data};

        case 'SET-DISCOUNT-DATA-FOR-UPDATE':
            data.payload = {
                discount_percentage: discount.discount_percentage,
                minimum_amount: discount.minimum_amount,
                status: discount.status,
                currency: discount.currency
            };
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-DISCOUNT-FORM':
            data = {
                payload: {
                    discount_percentage: ``,
                    minimum_amount: ``,
                    status: ``,
                    currency: ``
                },

                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: endOfURL(),
                message: ``
            };
            buildErrors();
            return {...state, data};

        case 'CANCEL-DISCOUNT-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-DISCOUNT-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'DISCOUNT-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            );
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, validationErrors} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            if (field === `discount_percentage`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }

            if (field === `minimum_amount`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (field === `status`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (field === `currency`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }


        }

        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}