import React from 'react';
import store from '../../../Store';
import getDiscounts from "../../../ajax/actions/discount-slab/discount_gets";

class DiscountWorker extends React.Component {

    componentDidMount() {
        store.dispatch(getDiscounts());
    }

    render() {
        return null
    }
}

export default DiscountWorker;