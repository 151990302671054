import React from 'react';
import store from '../../../../Store';
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import getSslCertificates from "../../../../ajax/actions/ssl/ssl_get";

class SSLWorker extends React.Component {
    componentDidMount() {
                store.dispatch(getSslCertificates(this.props.match.params.id));

    }

    componentDidUpdate(prev) {
        if (this.props.getSslCertificates !== prev.getSslCertificates) {
            let {success} = this.props.getSslCertificates;
            if (success) {
                store.dispatch(getSslCertificates(this.props.match.params.id));
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getSite, getSites,organizationDashboard} = state;
    let { organization_id } = organizationDashboard.data;
    return {getSite, getSites,organizationDashboard,organization_id};
}

export default withRouter(connect(mapStateToProps)(SSLWorker));