import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultDiscountFormState = {
    payload: {
        discount_percentage: ``,
        minimum_amount: '',
        status: '',
        currency: ''
    },

    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: endOfURL(),
    message: ``
};

export const DiscountFormState = {data: DefaultDiscountFormState};