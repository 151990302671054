const DefaultCouponState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 25,
        page: 1,
        order: 'desc',
        q: ``,
    },
    fields: [
        {field: `name`, label: `Code`, sort: true},
        {field: `effective_from`, label: `Effective From`, sort: true},
        {field: `effective_to`, label: `Effective To`, sort: true},
        {field: `total_usage_amount`,label: `Total Usage Amount`, sort: true},
    ]
}

export const CouponState = {data: DefaultCouponState}