import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultInternalMigrationForm
} from "./InternalMigrationForm-Action";
import createInternalMigration from "../../../../ajax/actions/migrate/migrate_post";
import getServerModules from '../../../../ajax/actions/server-module/server_modules_get';


class InternalMigrationFormWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(setDefaultInternalMigrationForm())
        store.dispatch(getServerModules())
    }

    componentDidUpdate(prev) {
        let {dispatchAPI} = this.props;
        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(createInternalMigration(this.props.match.params.id, this.props.organizationDashboard.data.organization_id))
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {internalMigrationForm, createInternalMigration,organizationDashboard,getSite} = state;
    let {dispatchAPI, mode, payload} = internalMigrationForm.data;
    return {internalMigrationForm, dispatchAPI, mode, payload, createInternalMigration,organizationDashboard,getSite};
}

export default withRouter(connect(mapStateToProps)(InternalMigrationFormWorker));