import { MainState } from './Main-State'

export default function MainReducer (state = MainState, action)
{
  let { data } = { ...state };
  let { expanded } = data;

  switch ( action.type )
  {
    case `TOGGLE-MAIN-WIDTH`:
      data.expanded = !expanded;
      return { ...state,  data };

      case `EXPAND-MAIN-WIDTH`:
      data.expanded = true;
      return { ...state,  data };

    default: return state;
  }
}