import { combineReducers } from "redux";

import route from "./routes/main-route/MainRoute-Reducer";
import loginForm from "./pages/login/Login-Reducer";
import loginUser from "./ajax/reducers/user/user-login-post";
import logoutUser from "./ajax/reducers/user/user-logout-post";
import createUser from "./ajax/reducers/user/user-post";
import deleteUser from "./ajax/reducers/user/user-delete";
import updateUser from "./ajax/reducers/user/user-put";
import getUser from "./ajax/reducers/user/user-get";
import getAllUsersWithoutPagination from "./ajax/reducers/user/all-users-without-pagination-get";
import getLoggedInUser from "./ajax/reducers/user/user-loggedin-get";
import getUsers from "./ajax/reducers/user/users-get";
import userRoleRevoke from "./ajax/reducers/user/user-role-revoke-post";
import userRole from "./ajax/reducers/user/user-role-post";
import getCustomerRole from "./ajax/reducers/role/customer_roles-get";

import userForm from "./pages/user/form/UserForm-Reducer";

import userList from "./pages/user/list/UserList-Reducer";

import profileForm from "./pages/myAccount/myProfile/form/ProfileForm-Reducer";
import loggedInUserUpdate from "./ajax/reducers/user/loggedin-user-put";

/*Password Change*/
import passwordReset from "./ajax/reducers/user-admin-account/user-change-password-put";
import passwordResetForm from "./pages/myAccount/password-reset/form/PasswordResetForm-Reducer";
import myAccountList from "./pages/myAccount/MyAccount-Reducer";

import createOrganizationUser from "./ajax/reducers/organization-user/user-post";
import deleteOrganizationUser from "./ajax/reducers/organization-user/user-delete";
import updateOrganizationUser from "./ajax/reducers/organization-user/user-put";
import getOrganizationUser from "./ajax/reducers/organization-user/user-get";
import getOrganizationUsers from "./ajax/reducers/organization-user/users-get";
import getOrganizationsWithoutPagination from "./ajax/reducers/organization/organizations-without-pagination-get";

import organizationUserForm from "./pages/organization-user/form/UserForm-Reducer";

import organizationUserList from "./pages/organization-user/list/UserList-Reducer";

import getAllOrganizationUsers from "./ajax/reducers/all-organization-user/all-organizations-users-get";
import allOrganizationUsersList from "./pages/all-organizations-user/list/UserList-Reducer";

import deleteSite from "./ajax/reducers/sites/site-delete";
import getSite from "./ajax/reducers/sites/site-get";
import updateSite from "./ajax/reducers/sites/site-put";
import createSite from "./ajax/reducers/sites/site-post";
import getSites from "./ajax/reducers/sites/sites-get";
import getSitesWithoutPagination from "./ajax/reducers/sites/sites-without-pagination-get";
import getSuperAdminSites from "./ajax/reducers/sites/super-admin-sites-get";
import getSiteStatus from "./ajax/reducers/sites/site-status-get";
import getAdminSiteStatus from "./ajax/reducers/sites/superadminsite-status-get";

import getCredentials from "./ajax/reducers/sftp-database/credential-get";
import createCredentials from "./ajax/reducers/sftp-database/credential-post";
import SftpList from "./pages/site/sftp-database/list/Sftp-Reducer";
//get site using organization ID
import organizationSite from "./ajax/reducers/sites/organization-site-get";
import getdashboardSearchSites from "./ajax/reducers/sites/dashboard-search-sites-get";

import deleteOrganization from "./ajax/reducers/organization/organization-delete";
import getOrganization from "./ajax/reducers/organization/organization-get";
import updateOrganization from "./ajax/reducers/organization/organization-put";
import getOrganizations from "./ajax/reducers/organization/organizations-get";

// newClient
import billingTypeForm from "./pages/new-client/billing-type-form/BillingTypeForm-Reducer";

/*expiring Billing*/
import createMarkAsPaid from "./ajax/reducers/invoice/mark-as-paid-post";
import getResendEmail from "./ajax/reducers/invoice/payment-resend-email-get";

//get organization detail using user_id
import userOrganizationDetail from "./ajax/reducers/organization/user-organization-get";
import createOrganization from "./ajax/reducers/organization/organization-post";
import getOrganizationStatus from "./ajax/reducers/organization/organization-status-get";

import deleteRole from "./ajax/reducers/role/role-delete";
import createRole from "./ajax/reducers/role/role-post";
import updateRole from "./ajax/reducers/role/role-put";
import getRoles from "./ajax/reducers/role/roles-get";
import getRole from "./ajax/reducers/role/role-get";

import getPermissions from "./ajax/reducers/permission/permissions-get";

import getDataCenters from "./ajax/reducers/data-center/data-centers-get";
import getSiteCreateDataCenters from "./ajax/reducers/data-center/site-create-data-centers-get";
import getDataCenter from "./ajax/reducers/data-center/data-center-get";
import updateDataCenter from "./ajax/reducers/data-center/data-center-put";
import deleteDataCenter from "./ajax/reducers/data-center/data-center-delete";
import createDataCenter from "./ajax/reducers/data-center/data-center-post";

import getLoadBalancers from "./ajax/reducers/load-balancer/load-balancers-get";
import getLoadBalancer from "./ajax/reducers/load-balancer/load-balancer-get";
import getLoadBalancerDataCenterServer from "./ajax/reducers/load-balancer/load-balancer-data-center-server-get";
import updateLoadBalancer from "./ajax/reducers/load-balancer/load-balancer-put";
import deleteLoadBalancer from "./ajax/reducers/load-balancer/load-balancer-delete";
import createLoadBalancer from "./ajax/reducers/load-balancer/load-balancer-post";

import getServerModules from "./ajax/reducers/server-module/server-modules-get";
import getServerModule from "./ajax/reducers/server-module/server-module-get";
import updateServerModule from "./ajax/reducers/server-module/server-module-put";
import deleteServerModule from "./ajax/reducers/server-module/server-module-delete";
import createServerModule from "./ajax/reducers/server-module/server-module-post";
import getDataCenterServerModule from "./ajax/reducers/server-module/datacenter-server-module-get";

import getBackup from "./ajax/reducers/backup/backup-get";
import deleteBackup from "./ajax/reducers/backup/backup-delete";
import createBackup from "./ajax/reducers/backup/backup-post";
import restoreBackup from "./ajax/reducers/backup/restore-backup-post";
import getSiteBackup from "./ajax/reducers/backup/site-backup-get";
import getManualSiteBackup from "./ajax/reducers/backup/site-manual-backup-get";

import manualBackupForm from "./pages/site/backup/form/ManualBackupForm-Reducer";

//DOMAIN
import getDomain from "./ajax/reducers/domain/domain-get";
import getDomains from "./ajax/reducers/domain/domains-get";
import updateDomain from "./ajax/reducers/domain/domain-put";
import createDomain from "./ajax/reducers/domain/domain-post";
import deleteDomain from "./ajax/reducers/domain/domain-delete";
import getSiteDomain from "./ajax/reducers/domain/site-domain-get";
import createBulkDomain from "./ajax/reducers/domain/domain-bulk-post";
import getSiteDomainNoPagination from "./ajax/reducers/domain/site-domain-nopagination-get";

//kepler tools
import createMonitor from "./ajax/reducers/kepler-tools/monitor-post";
import createReactConfiguration from "./ajax/reducers/kepler-tools/react-configuration-post";
import createPublicPath from "./ajax/reducers/kepler-tools/public-path-post";
import featureBlocklist from "./pages/site/feature-block/list/FeatureBlock-Reducer";
import publicPathForm from "./pages/site/feature-block/form/PublicPathForm-Reducer";
import restartPhp from "./ajax/reducers/kepler-tools/restart-php-post";
import debug from "./ajax/reducers/kepler-tools/debug-post";
import search from "./ajax/reducers/kepler-tools/search-post";
import replace from "./ajax/reducers/kepler-tools/replace-post";
import searchAndReplaceForm from "./pages/site/searchandreplace/form/SearchAndReplaceForm-Reducer";
import createForceSsl from "./ajax/reducers/kepler-tools/force-ssl-post";

//migration-tracking
import getMigrationTracking from "./ajax/reducers/migration-tracking/migration-tracking-get";
import getsMigrationTracking from "./ajax/reducers/migration-tracking/migration-tracking-gets";
import updateMigrationTracking from "./ajax/reducers/migration-tracking/migration-tracking-put";
import deleteMigrationTracking from "./ajax/reducers/migration-tracking/migration-tracking-delete";
import migrationTrackingList from "./pages/internal-migration-tracking/list/MigrationTracking-Reducer";
import migrationTrackingForm from "./pages/internal-migration-tracking/form/MigrationTrackingForm-Reducer";

// Dashboard
import getDashboard from "./ajax/reducers/dashboard/dashboard-get";

//staging-site
import getStagingSite from "./ajax/reducers/staging-site/staging-site-get";

// Component Reducers
import main from "./components/main/Main-Reducers";
import siteList from "./pages/site/list/SiteList-Reducer";
import siteDashboard from "./pages/site/SiteDashboard-Reducer";
import siteForm from "./pages/site/form/SiteForm-Reducer";
import roleList from "./pages/role/list/RoleList-Reducer";
import roleForm from "./pages/role/form/RoleForm-Reducer";

import dataCenterList from "./pages/data-center/list/DataCenter-Reducer";
import dataCenterForm from "./pages/data-center/form/DataCenterForm-Reducer";

import loadBalancerList from "./pages/load-balancer/list/LoadBalancer-Reducer";
import loadBalancerForm from "./pages/load-balancer/form/LoadBalancerForm-Reducer";

import serverModuleList from "./pages/server-module/list/ServerModule-Reducer";
import serverModuleForm from "./pages/server-module/form/ServerModuleForm-Reducer";

import organizationList from "./pages/organization/list/OrganizationList-Reducer";
import organizationForm from "./pages/organization/form/OrganizationForm-Reducer";
import organizationDashboard from "./pages/organization/OrganizationDashboard-Reducer";

import domainList from "./pages/site/domain/list/Domain-Reducer";
import domainForm from "./pages/site/domain/form/DomainForm-Reducer";

import backupList from "./pages/site/backup/list/Backup-Reducer";

import getActivity from "./ajax/reducers/activity-log/activity-logs-get";
import getActivityEvents from "./ajax/reducers/activity-log/activity-events-name-get";
import activityList from "./pages/activity-log/list/ActivityList-Reducer";
import getGlobalActivity from "./ajax/reducers/gloal-activity-log/global-activity-logs-get";
import globalActivityList from "./pages/global-activity-log/list/GlobalActivityList-Reducer";

import superAdminSiteList from "./pages/super-admin-sites/list/SiteList-Reducer";

import dashboardList from "./pages/dashboard/Dashboard-Reducer";
import BreadCrumb from "./components/layout/breadcrumb/BreadCrumb-Reducer";
import getSuperAdminSitesReducer from "./ajax/reducers/sites/super-admin-sites-get";

//internal migration
import createInternalMigration from "./ajax/reducers/migrate/migration-post";
import internalMigrationForm from "./pages/site/internal-migration-block/form/InternalMigrationForm-Reducer";
import getWordPressLangugae from "./ajax/reducers/word-press-language/wp-languages-get";

//organization Block
import createSiteOrganizationChange from "./ajax/reducers/organization/organization-block-post";
import organizationBlockForm from "./pages/site/organization-block/form/OrganizationBlockForm-Reducer";
import getOrganizationsBlock from "./ajax/reducers/organization/organizations-block-get";

import createSiteDisplayName from "./ajax/reducers/sites/site-displayname-post";
import displayNameForm from "./pages/site/change-display-name/form/DisplayNameForm-Reducer";

//invoice
import downloadInvoice from "./ajax/reducers/invoice/invoice-download-get";
import getPayments from "./ajax/reducers/invoice/payment-get";
import paymentList from "./pages/payment/list/PaymentList-Reducer";
import paymentDelete from "./ajax/reducers/invoice/payment-delete";
import invoiceDelete from "./ajax/reducers/invoice/invoice-delete";
/*all invoices*/
import getAllInvoice from "./ajax/reducers/invoice/all-invoices-get";

//discount
import getDiscount from "./ajax/reducers/discount-slab/discount-get";
import createDiscount from "./ajax/reducers/discount-slab/discount-post";
import updateDiscount from "./ajax/reducers/discount-slab/discount-put";
import adminApplyDiscount from "./ajax/reducers/coupon/coupon-apply-put";
import getDiscounts from "./ajax/reducers/discount-slab/discount-gets";
import deleteDiscount from "./ajax/reducers/discount-slab/discount-delete";
import discountList from "./pages/discount-slab/list/Discount-Reducer";
import discountForm from "./pages/discount-slab/form/DiscountForm-Reducer";

import getCountries from "./ajax/reducers/country/countries-get";

import enableCache from "./ajax/reducers/kepler-tools/enable-cache-post";
import disableCache from "./ajax/reducers/kepler-tools/disable-cache-post";
import clearCache from "./ajax/reducers/kepler-tools/clear-cache-post";
import cacheIgnore from "./ajax/reducers/kepler-tools/cache-ignore-post";
import getCacheIgnore from "./ajax/reducers/kepler-tools/cache-ignore-get";
import getsCacheIgnore from "./ajax/reducers/kepler-tools/cache-ignore-gets";
import deleteCacheIgnore from "./ajax/reducers/kepler-tools/cache-ignore-delete";
import updateCacheIgnore from "./ajax/reducers/kepler-tools/cache-ignore-put";
import ignoreCacheForm from "./pages/site/ignoreCache/form/IgnoreCacheForm-Reducer";
import getPhpVersions from "./ajax/reducers/kepler-tools/php-versions-get";
import phpVersionForm from "./pages/site/phpVersion/form/PhpVersionForm-Reducer";
import createPhpVersion from "./ajax/reducers/kepler-tools/php-version-post";
import createPasswordProtection from "./ajax/reducers/kepler-tools/password-protection-post";
import passwordProtectionForm from "./pages/site/passwordProtection/form/PasswordProtectionForm-Reducer";
import disablePasswordProtection from "./ajax/reducers/kepler-tools/disable-password-protection-post";

//site price
import createSitePrice from "./ajax/reducers/kepler-tools/site-price-post";
import updateSitePrice from "./ajax/reducers/kepler-tools/site-price-put";
import deleteSitePrice from "./ajax/reducers/kepler-tools/site-price-delete";
import priceBlockForm from "./pages/site/price-block/form/PriceBlockForm-Reducer";

import getLogs from "./ajax/reducers/logs/Logs-get";
import logsList from "./pages/site/logs/list/Logs-Reducer";

//price
import priceForm from "./pages/price/form/PriceForm-Reducer";
import priceList from "./pages/price/list/Price-Reducer";
import getPrices from "./ajax/reducers/price/price-gets";
import getPrice from "./ajax/reducers/price/price-get";
import updatePrice from "./ajax/reducers/price/price-put";
import createPrice from "./ajax/reducers/price/price-post";

//billing
import billingBlockForm from "./pages/site/billing-block/BillingBlockForm-Reducer";
import updateSiteBilling from "./ajax/reducers/kepler-tools/site-billing-post";

//coupon
import getCoupon from "./ajax/reducers/coupon/coupon-get";
import getCoupons from "./ajax/reducers/coupon/coupon-gets";
import deleteCoupon from "./ajax/reducers/coupon/coupon-delete";
import updateCoupon from "./ajax/reducers/coupon/coupon-put";
import createCoupon from "./ajax/reducers/coupon/coupon-post";

import couponList from "./pages/coupon/list/Coupon-Reducer";
import couponForm from "./pages/coupon/form/CouponForm-Reducer";
import getCurrency from "./ajax/reducers/coupon/currency-gets";

// Upcloud
import upcloudList from "./pages/upcloud/list/Upcloud-Reducer";
import getResource from "./ajax/reducers/upcloud-resources/resource-get";

//ssl
import getSslCertificates from "./ajax/reducers/ssl/ssl-get";
import getSslCertificate from "./ajax/reducers/ssl/ssl-certificate-get";
import updateSSLCertificates from "./ajax/reducers/ssl/ssl-put";
import createSSLCertificates from "./ajax/reducers/ssl/ssl-post";
import deleteSSLCertificates from "./ajax/reducers/ssl/ssl-delete";
import SSLForm from "./pages/site/ssl/sslCertificate/form/SSLForm-Reducer";
import SSLList from "./pages/site/ssl/list/SSL-Reducer";
import createCustomSslCertificate from "./ajax/reducers/ssl/custom-ssl-certificate-post";
import createGenerateLetsEncrypt from "./ajax/reducers/ssl/generate-lets-encrypt-post";
import createGenerateSsl from "./ajax/reducers/ssl/generateSsl-post";
import createGenerateCustomSsl from "./ajax/reducers/ssl/generateCustomSsl-post";

//twoFactorAuthentication
import enable2fa from "./ajax/reducers/2fa-authentication/enable-2fa-post";
import enable2faForm from "./pages/two-factor-authentication/Enable2Fa-Reducer";
import Verify2fa from "./ajax/reducers/2fa-authentication/verify-2fa-authy-post";
import getAuthyQr from "./ajax/reducers/2fa-authentication/authy-qr-code-get";

//redirects
import getRedirects from "./ajax/reducers/redirects/redirects-get";
import redirectList from "./pages/site/redirects/list/Redirects-Reducer";
import redirectForm from "./pages/site/redirects/form/RedirectsForm-Reducer";
import createRedirect from "./ajax/reducers/redirects/redirect-post";
import updateRedirect from "./ajax/reducers/redirects/redirect-put";
import getRedirect from "./ajax/reducers/redirects/redirect-get";
import deleteRedirect from "./ajax/reducers/redirects/redirect-delete";

//migration
import updateMigrationStatus from "./ajax/reducers/migration/manual-migrate-status-put";
import getsMigration from "./ajax/reducers/migration/migration-gets";
import getMigration from "./ajax/reducers/migration/migration-get";
import deleteMigration from "./ajax/reducers/migration/migration-delete";
import migrationList from "./pages/migration/list/Migrate-Reducer";
import migrationForm from "./pages/migration/form/MigrationForm-Reducer";

//hotfix
import getHotfixs from "./ajax/reducers/hotfix/hotfix-get";
import createHotfix from "./ajax/reducers/hotfix/hotfix-post";
import hotfixList from "./pages/hotfix/HotfixList-Reducer";

//backup infrastructure
import getBackupStatus from "./ajax/reducers/backup-infrastructure/backup-status-get";
import getExpiredBackup from "./ajax/reducers/backup-infrastructure/expired-backup-get";
import backupStatusList from "./pages/backup-status/list/Backup-Status-Reducer";
import expiredBackupList from "./pages/expired-backup/list/Expired-Backup-Reducer";

//bug - support
import getsBug from "./ajax/reducers/support/bugs-get";
import getBug from "./ajax/reducers/support/bug-get";
import deleteBugImage from "./ajax/reducers/support/bug-image-delete";
import createBug from "./ajax/reducers/support/bug-post";
import deleteBug from "./ajax/reducers/support/bug-delete";
import updateBug from "./ajax/reducers/support/bug-put";
import updateBugStatus from "./ajax/reducers/support/bug-status-put";

import bugList from "./pages/support/list/Bug-Reducer";
import bugForm from "./pages/support/form/BugForm-Reducer";

// plans
import getPlans from "./ajax/reducers/hosting-plan/plan-get";
import createPlan from "./ajax/reducers/hosting-plan/plan-post";
import updatePlan from "./ajax/reducers/hosting-plan/plan-put";
import deletePlan from "./ajax/reducers/hosting-plan/plan-delete";
import planForm from "./pages/site/plan/form/PlanForm-Reducer";
import planList from "./pages/site/plan/list/PlanList-Reducer";

// import backupForm from "./pages/site/backup/form/BackupForm-Reducer";
/**
 * COMBINE ALL REDUCERS
 * Place related reducers in a single line. No pairs
 */
export default combineReducers({
  /* - - - COMPONENT REDUCERS - - -*/

  route,
  BreadCrumb,
  main,
  loginForm,
  loginUser,
  logoutUser,
  roleList,
  roleForm,
  organizationList,
  organizationDashboard,

  getDashboard,

  dataCenterList,
  dataCenterForm,
  loadBalancerList,
  loadBalancerForm,
  serverModuleList,
  serverModuleForm,

  userList,
  userForm,
  siteDashboard,

  domainList,
  domainForm,
  // ajax reducers list
  createUser,
  deleteUser,
  updateUser,
  getUsers,
  userRoleRevoke,
  userRole,
  getUser,
  getAllUsersWithoutPagination,
  getLoggedInUser,

  getPermissions,

  deleteRole,
  getRoles,
  createRole,
  updateRole,
  getRole,

  deleteSite,
  getSite,
  getSites,
  getSitesWithoutPagination,
  updateSite,
  createSite,
  siteList,
  siteForm,
  getSiteStatus,

  getSuperAdminSites,

  getCredentials,
  createCredentials,
  SftpList,

  deleteOrganization,
  getOrganization,
  getOrganizations,
  updateOrganization,
  userOrganizationDetail,
  organizationSite,
  createOrganization,
  getOrganizationStatus,

  getLoadBalancerDataCenterServer,
  getLoadBalancer,
  updateLoadBalancer,
  deleteLoadBalancer,
  createLoadBalancer,
  organizationForm,
  getLoadBalancers,

  getDataCenters,
  getDataCenter,
  updateDataCenter,
  deleteDataCenter,
  createDataCenter,
  getSiteCreateDataCenters,

  getServerModules,
  getServerModule,
  updateServerModule,
  deleteServerModule,
  createServerModule,
  getDataCenterServerModule,

  getDomain,
  getDomains,
  updateDomain,
  createDomain,
  deleteDomain,
  getSiteDomain,
  createBulkDomain,
  getSiteDomainNoPagination,

  getBackup,

  // updateBackup,
  createBackup,
  getSiteBackup,
  getManualSiteBackup,

  getBackupStatus,
  getExpiredBackup,
  backupStatusList,
  expiredBackupList,

  restoreBackup,
  deleteBackup,
  backupList,
  // backupForm
  getActivity,
  activityList,
  getActivityEvents,

  getGlobalActivity,
  globalActivityList,

  getSuperAdminSitesReducer,
  superAdminSiteList,
  getAdminSiteStatus,

  createOrganizationUser,
  deleteOrganizationUser,
  updateOrganizationUser,
  getOrganizationUser,
  organizationUserList,
  getOrganizationUsers,
  organizationUserForm,

  createMonitor,
  createReactConfiguration,
  createPublicPath,
  featureBlocklist,
  publicPathForm,
  getCustomerRole,
  restartPhp,
  debug,
  search,
  searchAndReplaceForm,
  replace,

  createInternalMigration,
  internalMigrationForm,
  getWordPressLangugae,

  createSiteOrganizationChange,
  organizationBlockForm,
  getOrganizationsBlock,
  createSiteDisplayName,
  displayNameForm,
  dashboardList,
  getdashboardSearchSites,
  downloadInvoice,
  paymentDelete,
  invoiceDelete,
  getAllInvoice,
  getResendEmail,
  createMarkAsPaid,
  getPayments,
  paymentList,

  createSiteOrganizationChange,
  organizationBlockForm,
  getOrganizationsBlock,
  createSiteDisplayName,
  displayNameForm,
  dashboardList,
  getdashboardSearchSites,
  downloadInvoice,
  paymentDelete,
  invoiceDelete,
  getAllInvoice,
  getResendEmail,
  createMarkAsPaid,
  getPayments,
  paymentList,

  getDiscount,
  getDiscounts,
  deleteDiscount,
  createDiscount,
  updateDiscount,
  discountList,
  discountForm,
  getCountries,
  enableCache,
  disableCache,
  clearCache,
  cacheIgnore,
  getCacheIgnore,
  updateCacheIgnore,
  getsCacheIgnore,
  deleteCacheIgnore,
  ignoreCacheForm,
  getLogs,
  logsList,
  getPhpVersions,
  phpVersionForm,
  createPhpVersion,
  passwordProtectionForm,
  createPasswordProtection,
  disablePasswordProtection,

  createSitePrice,
  updateSitePrice,
  deleteSitePrice,
  priceBlockForm,

  priceForm,
  priceList,
  getPrice,
  getPrices,
  updatePrice,
  createPrice,
  getOrganizationsWithoutPagination,
  updateSiteBilling,
  billingBlockForm,

  getCoupon,
  getCoupons,
  updateCoupon,
  deleteCoupon,
  createCoupon,
  couponForm,
  couponList,
  adminApplyDiscount,
  getCurrency,

  upcloudList,
  getResource,

  getSslCertificates,
  SSLForm,
  createSSLCertificates,
  updateSSLCertificates,
  getSslCertificate,
  deleteSSLCertificates,
  SSLList,
  createGenerateLetsEncrypt,
  createCustomSslCertificate,
  createGenerateSsl,
  createGenerateCustomSsl,

  manualBackupForm,

  getAllOrganizationUsers,
  allOrganizationUsersList,
  createForceSsl,
  enable2fa,
  enable2faForm,
  Verify2fa,
  getAuthyQr,

  getRedirects,
  redirectList,
  redirectForm,
  createRedirect,
  updateRedirect,
  getRedirect,
  deleteRedirect,

  updateMigrationStatus,
  getsMigration,
  getMigration,
  deleteMigration,
  migrationList,
  migrationForm,

  passwordReset,
  passwordResetForm,
  myAccountList,

  loggedInUserUpdate,
  profileForm,

  billingTypeForm,

  getHotfixs,
  createHotfix,
  hotfixList,

  getsBug,
  getBug,
  deleteBugImage,
  updateBug,
  updateBugStatus,
  createBug,
  deleteBug,
  bugList,
  bugForm,

  getMigrationTracking,
  getsMigrationTracking,
  updateMigrationTracking,
  deleteMigrationTracking,
  migrationTrackingList,
  migrationTrackingForm,

  getStagingSite,

  getPlans,
  createPlan,
  deletePlan,
  updatePlan,
  planList,
  planForm,
});
