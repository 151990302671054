import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import {scrollToError} from '../../../helpers/ScrollHelper'
import {
    cancelBugFormDispatch,
    bugFormServerValidationErrors
} from "../../../pages/support/form/BugForm-Action";
import getBug from "./bug_get";

function _success(success) {
    return {type: 'UPDATE_BUG_SUCCESS', success}
}

function _error(error) {
    return {type: 'UPDATE_BUG_ERROR', error}
}

function _processing(processing) {
    return {type: 'UPDATE_BUG_PROCESSING', processing}
}

function updateBug(id) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let { bugForm} = getState();
        let {payload} = bugForm.data
        /*if(!(payload.images).length){
            delete payload.images
        }*/
        let data = payload;

        const formData = new FormData();
        Object.keys(data).map(item => {
            if(item !== `images` && item !== 'tags') {
                formData.append(item, data[item])
            }
            else if (item === "images"){
                (data.images).map(i =>{
                    formData.append(`images[]`,i)
                })
            }
            else if (item === "tags"){
                (data.tags).map(i =>{
                    formData.append(`tags[]`,i)
                })
            }
        })
        axios({
            url: apiBaseUrl(`admin/internal-bug/${id}`),
            method: "post",
            data: formData,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res)
                store.dispatch(getBug(id))
                store.dispatch(cancelBugFormDispatch())

            }).catch(function (error) {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error)
            store.dispatch(cancelBugFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(bugFormServerValidationErrors(error.response.data.errors))
                scrollToError();
            }
        });
    }
}
export default updateBug;
