import store from '../../../Store';
import { toggleURLQuery } from '../../../helpers/UrlHelper'
import getSites from '../../../ajax/actions/sites/sites_get'
import getOrganizationStatus from "../../../ajax/actions/organization/organization_status_get";
import getSite from "../../../ajax/actions/sites/site_get"

export function toggleSiteListFilter(e, id)
{
  return function (dispatch ) {
    let { name, value  } = e.target;
   dispatch({type:'TOGGLE-SITE-LIST-FILTER', name, value});
    store.dispatch(getSites(id));
  }
}

export function setDefaultSiteFiltersToURL( history )
{
  return ( dispatch, getState ) => {
    let { data } = getState().siteList;
    let { params } = data;
    Object.keys( params ).map( key => {
      if ( params[key] !== `` && params[key] !== null && params[key] !== undefined ) {
        toggleURLQuery( key, params[key], history)
      }
    })
  }
}

export function toggleSiteFormModal( )
{
  return function (dispatch) {
    dispatch({type:'TOGGLE-SITE-FORM-MODAL', });
  }
}

export function setOrganizationIDSite( id )
{
  return function (dispatch) {
    dispatch({type:'SET-ORGANIZATIONID-SITE', id});
  }
}

export function setDefaulttoggleDangerZoneFormModal() {
  return function (dispatch) {
    dispatch({type: 'DEFAULT-TOGGLE-DANGER-ZONE-FORM-MODAL'});
  }
}