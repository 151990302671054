import { SiteListState } from './SiteList-State'

export default function siteListReducer(state = SiteListState, action) {

  let { data } = { ...state };
  let {name, value, server_id} = action;

  switch (action.type) {

    case 'TOGGLE-SITE-LIST-FILTER':
      data.params[ name ] = value;
      if (name === `sort_by`) {
        if (data.params.sort_by === value) {
          data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
        }
      }
      let toggle = true;
      if (toggle) {
        if (name === 'limit') {
          data.params.page = 1;
        }
        data.params[name] = value;
      }
      return { ...state, data };

    case 'ON-SITE-SERVER-CHANGE':
      data.params.server_id = server_id;
      // if (server_id) {
        data.params.page = '';
      // }
      return { ...state, data };

    case 'ON-SITE-RESET':
      data.params.server_id = ``;
      return { ...state, data };

    case 'SET-DEFAULT-SEARCH':
      data.params.q = ``;
      return {...state, data}

    default: return state
  }
}
