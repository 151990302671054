import React from 'react';
import {Link} from "react-router-dom";
import {connect} from 'react-redux';
import {endOfURL, secondToLastItemInURL} from "../../helpers/UrlHelper";

class SiteDashboardLinks extends React.Component {

    render() {
        let {org_id, site_id, processing, lbVersion, role} = this.props;
        return (
            <>
                <ul className="site-dashboard-links">
                    <li className="nav-item" id={processing && "disabled-link"}>
                        <Link
                            className={`nav-link ${endOfURL() === "launchpad" || (secondToLastItemInURL() === "launchpad" && endOfURL() === "admin") ? "active" : ""}`}
                            to={`/organizations/${org_id}/sites/${site_id}/launchpad`}
                            id={processing && "disabled-link"}
                        >Launch Pad</Link>
                    </li>
                    <li className="nav-item" id={processing && "disabled-link"}>
                        <Link className={`nav-link ${endOfURL() === "domain" ? "active" : ""}`}
                              to={`/organizations/${org_id}/sites/${site_id}/domain`} id={processing && "disabled-link"}
                        >Domain</Link>
                    </li>
                    <li className="nav-item" id={processing && "disabled-link"}>
                        <Link className={`nav-link ${endOfURL() === "redirects" ? "active" : ""}`}
                              to={`/organizations/${org_id}/sites/${site_id}/redirects`} id={processing && "disabled-link"}
                        >Redirects</Link>
                    </li>
                    {lbVersion === 2 &&
                    <li className="nav-item" id={processing && "disabled-link"}>
                        <Link className={`nav-link ${endOfURL() === "ssl" ? "active" : ""}`}
                              to={`/organizations/${org_id}/sites/${site_id}/ssl`} id={processing && "disabled-link"}
                        >SSL</Link>
                    </li>
                    }
                    <li className="nav-item" id={processing && "disabled-link"}>
                        <Link className={`nav-link ${endOfURL() === "backup" ? "active" : ""}`}
                              to={`/organizations/${org_id}/sites/${site_id}/backup`} id={processing && "disabled-link"}
                        >Backup</Link>
                    </li>
                    <li className="nav-item" id={processing && "disabled-link"}>
                        <Link className={`nav-link ${endOfURL() === "featureblock" ? "active" : ""}`}
                              to={`/organizations/${org_id}/sites/${site_id}/featureblock`}
                              id={processing && "disabled-link"}
                        >Tools</Link>
                    </li>

                    <li className="nav-item" id={processing && "disabled-link"}>
                        <Link className={`nav-link ${endOfURL() === "cache" ? "active" : ""}`}
                              to={`/organizations/${org_id}/sites/${site_id}/cache`} id={processing && "disabled-link"}
                        >Cache</Link>
                    </li>
                    <li className="nav-item" id={processing && "disabled-link"}>
                        <Link className={`nav-link ${endOfURL() === "logs" ? "active" : ""}`}
                              to={`/organizations/${org_id}/sites/${site_id}/logs`} id={processing && "disabled-link"}
                        >Logs</Link>
                    </li>
                    {role !== 'organization-admin' ?
                        <li className="nav-item" id={processing && "disabled-link"}>
                            <Link className={`nav-link ${endOfURL() === "adminorg" ? "active" : ""}`}
                                  to={`/organizations/${org_id}/sites/${site_id}/adminorg`}
                                  id={processing && "disabled-link"}
                            >Admin</Link>
                        </li> : null}

                    <li className="nav-item" id={processing && "disabled-link"}>
                        <Link className={`nav-link ${endOfURL() === "plan" ? "active" : ""}`}
                              to={`/organizations/${org_id}/sites/${site_id}/plan`} id={processing && "disabled-link"}
                        >Plan</Link>
                    </li>
                </ul>
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
        route
        ,
        getSite
    }

        = state;
    let org_id = getSite.success?.organization_id;
    let site_id = getSite.success?.id;
    let lbVersion = getSite.success?.lb_version
    let role = route && route.data.user && route.data.user.role.slug;

    return {route, org_id, site_id, getSite, lbVersion, role}
}

export default connect(mapStateToProps)(SiteDashboardLinks);