import {ExpiredBackupState} from './Expired-Backup-State'

export default function expiredBackupListReducer(state = ExpiredBackupState, action) {

    let {data} = {...state};
    let {name, value} = action;

    switch (action.type) {

        case 'TOGGLE-EXPIRED-BACKUP-LIST-FILTER':
            let toggle = true;
            if (toggle) data.params[name] = value;
            return {...state, data};

        default:
            return state
    }
}

