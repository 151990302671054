import React from 'react';
import DataCenterWorker from "./DataCenterWorker";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import TableHead from "../../../components/shared/table/TableHead";
import store from "../../../Store";
import {toggleDataCenterListFilter} from "./DataCenter-Action";
import swal from "sweetalert";
import deleteDataCenter from "../../../ajax/actions/data-center/data_center_delete";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import DataCenterListFilters from "./DatacenterListFilters";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import {ucFirst} from "../../../helpers/StringHelper";
import { Availability, Status } from '../../../components/shared/status/Status';

class DataCenter extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    toggleSort = (field) => {
        store.dispatch(toggleDataCenterListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this data center? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id) {
        store.dispatch(deleteDataCenter(id));
    }

    render() {
        let {list, fields, params, pages, getDataCenters} = this.props;
        let {processing, error} = getDataCenters;
        if (error) {
            this.props.history.push('/');
        }
        return (
            <>
                <Page title="Data Center" processing={processing}>

                    <DataCenterWorker/>
                    <DataCenterListFilters title="data-center"/>
                    <div className="table-block table-responsive pr-60">

                        <table className="table table-striped  ">
                            <TableHead fields={fields}
                                       params={params}
                                       noActions={true}
                                       onClick={this.toggleSort}
                                       processing={processing}
                            />
                            <tbody>
                            {processing ? <td colSpan={5} className="text-center"><Loading/></td> :
                                list && list.length ? list.map((l, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                <Link to={`/data-center/${l.id}/update`}>{ucFirst(l.name)}</Link>
                                            </td>
                                            <td>
                                                <Availability availability={l.availability}/>
                                            </td>
                                            <td>
                                                <Status status={l.status}/>
                                            </td>
                                            <td>
                                                <div className="dropdown dropdown-alt text-center">
                                                <span className="dropdown-toggle"
                                                      data-toggle="dropdown">
                                                    <span className="icon-more"/>
                                                </span>
                                                    <div className="dropdown-menu">
                                                        <Link to={`/data-center/${l.id}/update`}
                                                              className={`link-edit`}>
                                                            Edit
                                                        </Link>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan="5" className="text-center">No Data Center Available</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                    {list && list.length ? (
                        <div className="fixed-footer pr-60">
                            <PaginationButtons
                                className="pagination"
                                pages={pages}
                                processing={processing}
                                fetchAction={toggleDataCenterListFilter}/>
                        </div>
                    ) : null}
                </Page>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {getDataCenters, dataCenterList} = state;

    let {params, fields} = dataCenterList.data;

    let {success} = getDataCenters;

    let list =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data;

    let current_page = success && success.data && success.data.data.current_page;
    let last_page = success && success.data && success.data.data.last_page;
    let total = success && success.data && success.data.data.total;
    let pages = {current_page, last_page, total};

    return {getDataCenters, list, dataCenterList, params, fields, pages};
}

export default connect(mapStateToProps)(DataCenter);