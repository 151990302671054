import React from "react";
import { connect } from "react-redux";
import store from "../../../Store";
import DataCenterFormWorker from "./DataCenterFormWorker";
import {
  onDataCenterFormChange,
  onDataCenterFormSubmit,
} from "./DataCenterForm-Action";
import TextField from "../../../components/shared/form/TextField";
import Loading from "../../../components/shared/loaders/Loading";
import SelectField from "../../../components/shared/form/SelectField";
import {
  activeStatusFilter,
  availabilityStatus,
} from "../../../static-data/UserData";
import { setDashboardMenu } from "../../../routes/main-route/MainRoute-Actions";
import Link from "react-router-dom/Link";
import { endOfURL } from "../../../helpers/UrlHelper";
import { ucFirst } from "../../../helpers/StringHelper";
import Page from "../../../components/shared/pages/Page";

class DataCenterForm extends React.Component {
  componentDidMount() {
    document.querySelector("body").classList.add("organization-dashboard");
    store.dispatch(setDashboardMenu(""));
  }

  componentWillUnmount() {
    document.querySelector("body").classList.remove("organization-dashboard");
  }

  dataCenterFormSubmit = (e) => {
    e.preventDefault();
    store.dispatch(onDataCenterFormSubmit());
  };

  onChange = (e) => {
    e.preventDefault();
    store.dispatch(onDataCenterFormChange(e.target.name, e.target.value));
  };

  render() {
    let {
      name,
      display_name,
      status,
      availability,
      updateDataCenter,
      createDataCenter,
      dataCenterForm,
      latitude,
      longitude,
    } = this.props;
    let { errors } = dataCenterForm.data;
    let processing = updateDataCenter.processing || createDataCenter.processing;

    return (
      <>
        <Page
          title={
            endOfURL() === "create" ? "Add DataCenter" : "Update DataCenter"
          }
          processing={processing}
        >
          <div className="heading-block pr-60">
            <div className="title-block">
              <h2>
                {" "}
                {endOfURL() === "create"
                  ? "Add new Data Center"
                  : "Update Data Center"}
              </h2>
              <span>
                <Link to="/data-center">Data Center</Link>
              </span>
              {endOfURL() === "update" ? <span>{ucFirst(name)}</span> : null}
              <span>
                {endOfURL() === "create" ? `Add new Data Center` : `Update`}
              </span>
            </div>
          </div>
          <DataCenterFormWorker />
          <div className="box-block">
            <div className="tab-content">
              <div className="padded-block">
                <form onSubmit={this.dataCenterFormSubmit}>
                  <div className="form-row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <TextField
                          name="name"
                          value={name}
                          label="Name"
                          errors={errors && errors.name}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <TextField
                          name="display_name"
                          value={display_name}
                          label="Display Name"
                          errors={errors.display_name}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <SelectField
                          name="status"
                          value={status}
                          options={activeStatusFilter}
                          errors={errors.status}
                          onChange={this.onChange}
                          _value="value"
                          _label="name"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <SelectField
                          name="availability"
                          value={availability}
                          options={availabilityStatus}
                          errors={errors.availability}
                          onChange={this.onChange}
                          _value="value"
                          _label="name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <TextField
                          name="latitude"
                          value={latitude}
                          errors={errors.latitude}
                          label="Latitude"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <TextField
                          name="longitude"
                          value={longitude}
                          errors={errors.longitude}
                          label="Longitude"
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="button-wrapper inline-buttons text-center d-flex justify-content-end">
                    <button type="submit" className="btn-theme btn-dark-blue">
                      {processing ? <Loading /> : `Submit`}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Page>
      </>
    );
  }
}

function mapStateToProps(state) {
  let { dataCenterForm, updateDataCenter, createDataCenter } = state;
  let { errors, payload } = dataCenterForm.data;

  let { name, display_name, status, availability, latitude, longitude } =
    payload;

  return {
    dataCenterForm,
    updateDataCenter,
    createDataCenter,
    errors,
    name,
    display_name,
    status,
    availability,
    latitude,
    longitude,
  };
}

export default connect(mapStateToProps)(DataCenterForm);
