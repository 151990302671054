export default function createServerModuleReducer
    (state = { error:'', success:'', processing:false}, action)
{
    switch (action.type) {
        case 'CREATE_SERVER_MODULE_SUCCESS':
            return {...state, ...{success: action.success}};

        case 'CREATE_SERVER_MODULE_ERROR':
            return {...state, ...{error: action.error}};

        case 'CREATE_SERVER_MODULE_PROCESSING':
            return {...state, ...{processing: action.processing}};

        default: return state;
    }
}