import React from 'react';
import store from '../../../Store';
import withRouter from "react-router/withRouter";
import {connect} from "react-redux";
import getPayments from "../../../ajax/actions/invoice/payment_get";
import {setOrganizationDashboardData, setOrganizationId} from "../../organization/OrganizationDashboard-Action";
import getOrganization from "../../../ajax/actions/organization/organization_get";

class PaymentWorker extends React.Component {
    componentDidMount() {
        let id = this.props.match.params.id
        store.dispatch(getPayments(id));
        store.dispatch(setOrganizationId(id));
        store.dispatch(getOrganization(id))
    }

    componentDidUpdate(prevProps) {
        if (this.props.getOrganization !== prevProps.getOrganization) {
            let {success} = this.props.getOrganization;
            if (success) {
                store.dispatch(setOrganizationDashboardData());
                // store.dispatch(getOrganizations());
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getPayments,organizationDashboard,getOrganization} = state;

    return {getPayments,organizationDashboard,getOrganization};
}

export default withRouter(connect(mapStateToProps)(PaymentWorker));