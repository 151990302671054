import React from 'react';
import Button from "../../../components/shared/buttons/Button";
import store from '../../../Store';
import {ucFirst, unslufigy,} from "../../../helpers/StringHelper";
import {Link} from "react-router-dom";
import {
    onGlobalActivityLogSiteChange,
    onGlobalActivityLogSiteReset,
    toggleGlobalActivityListFilter,
    onGlobalActivityLogEventChange,
    onGlobalActivityLogEventReset
} from './GlobalActivityList-Actions'
import {connect} from "react-redux";
import Select from "react-select";
import {withRouter} from "react-router";

class GlobalActivityHeading extends React.Component {

    toggleFilter = (e) => {
        store.dispatch(toggleGlobalActivityListFilter(e, this.props.history));
    }

    // handleSiteChange = (selectedOption) => {
    //     if (selectedOption) {
    //         store.dispatch(onActivityLogSiteChange(selectedOption.value));
    //     } else {
    //         store.dispatch(onActivityLogSiteReset());
    //     }
    // }
    handleEventChange = (selectedOption) => {
        if (selectedOption) {
            store.dispatch(onGlobalActivityLogEventChange(selectedOption.value, this.props.history));
        } else {
            store.dispatch(onGlobalActivityLogEventReset(this.props.history));
        }
    }

    render() {
        let {activityEventLists, params} = this.props;
        let clearable = true;
        // let siteList = [];
        // siteLists && siteLists.map(site => {
        //     siteList.push({value: site.id, label: ucFirst(site.name)})
        // });
        let eventList=[];
        activityEventLists && activityEventLists.map(e => {
            eventList.push({value: e, label: e})
        });

        return (

            <div className="heading-block pr-60">
                <div className="title-block">
                    <h2>Activity Logs</h2>
                </div>
                {/*<div className="search-bar">*/}
                {/*    <input type="text"*/}
                {/*           className="form-control"*/}
                {/*           name="q"*/}
                {/*           placeholder={`Search Activity Logs`}*/}
                {/*           value={params.q}*/}
                {/*           onChange={this.toggleFilter}*/}
                {/*    />*/}
                {/*    <span className="icon-search"/>*/}
                {/*</div>*/}
                {/*<div className="select-block">*/}
                {/*    <Select*/}
                {/*        name="site_id"*/}
                {/*        isClearable={clearable}*/}
                {/*        placeholder="Select Site ..."*/}
                {/*        value={siteList.id}*/}
                {/*        options={siteList}*/}
                {/*        onChange={this.handleSiteChange}*/}
                {/*    />*/}

                {/*</div>*/}
                {/*<div className="select-block" id="filter-datacenter">*/}
                <div className="select-block" >
                    <Select
                        name="event"
                        isClearable={clearable}
                        placeholder="Select Event ..."
                        value={eventList.value}
                        options={eventList}
                        onChange={this.handleEventChange}
                    />

                </div>
                <div className="button-wrapper">
                    <Link to={`/organizations`}>
                        <Button className="btn-theme btn-dark-blue"
                                title={`Add Site`}
                                text={`Add Site`}/>
                    </Link>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let {globalActivityList,getActivityEvents} = state;
    let {params} = globalActivityList.data;
    let activityEventLists = getActivityEvents?.success?.data.data
    return {
          globalActivityList,
         params,getActivityEvents,activityEventLists
    };
}

export default withRouter(connect(mapStateToProps)(GlobalActivityHeading));
