export function setSiteDashboardData() {
  return function (dispatch, getState) {
    let { getSite } = getState();
    let { success } = getSite;
    if( success ) {
      dispatch({type:'SET-SITE-DASHBOARD-DATA',  site:success });
    }
  }
}

export function setSiteStatus() {
  return function (dispatch, getState) {
    let { getSiteStatus } = getState();
    let { success } = getSiteStatus;
    if( success ) {
      dispatch({type:'SET-SITE-STATUS',  status:success });
    }
  }
}

export function changeLink(value) {
  return function (dispatch,) {
      dispatch({type: 'CHANGE-SITE-LINK', value});
  }
}

export function setSiteDashboardOrganizationID( org_id ) {

  return function (dispatch) {
    dispatch({type:'SET-ORAGANIZATION-ID', org_id });
  }
}