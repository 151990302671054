import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handle422error, handleError, handleSuccess, handle422errorWithOutStatusCode} from '../../../helpers/ErrorHelper'
import { scrollToError } from '../../../helpers/ScrollHelper'
import store from "../../../Store";
import {
  cancelSslCertificateFormDispatch,
  setDefaultSslCertificateForm,
  SSLCertificateFormServerValidationErrors
} from '../../../pages/site/ssl/sslCertificate/form/SSLForm-Action'
import getSslCertificates from "./ssl_get";


function _success(success)
{
  return { type: 'POST_SSL_CERTIFICATES_SUCCESS', success };
}
function _error(error)
{
  return { type: 'POST_SSL_CERTIFICATES_ERROR', error };
}
function _processing(processing)
{
  return { type: 'POST_SSL_CERTIFICATES_PROCESSING', processing };
}

function createSsl(id)
{
  return ( dispatch, getState ) =>
  {
    dispatch(_processing(true));
    dispatch(_success(null));
    dispatch(_error(null));
    let {SSLForm,organizationDashboard} = getState();
    let {payload} = SSLForm.data
    payload.key = SSLForm &&  SSLForm.data.payload.key_content;
    // delete payload.key_content;
    let org_id = organizationDashboard.data.organization_id;

    axios({
      url: apiBaseUrl(`admin/organizations/${org_id}/sites/${id}/certificates`),
      method: "post",
      dataType: 'json',
      data: SSLForm.data.payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    })
      .then(function(res){
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess( res )

        store.dispatch(getSslCertificates(id));
        store.dispatch(setDefaultSslCertificateForm());
        // store.dispatch(setSiteId(this.props.match.params.id));
      })
      .catch(function(error){
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError( error )
        store.dispatch(cancelSslCertificateFormDispatch())

        if(error.response && error.response.status === 422 ) {
          if(error.response.data.errors[0][0] === "SSL Certificate is not Valid."){
            handle422errorWithOutStatusCode(error.response.data.errors)
          }
          else {
            handle422error(error.response.data.errors)
            // validationError(error.response.data.message,error.response.status)
            store.dispatch(SSLCertificateFormServerValidationErrors(error.response.data.errors))
          }
          scrollToError();
        }
      });

  }
}


export default createSsl;