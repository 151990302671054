import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import InternalMigrationBlock from "../internal-migration-block/form/InternalMigrationForm";
import OrganizationBlock from "../organization-block/form/OrganizationBlockForm";
import Loading from "../../../components/shared/loaders/Loading";
import PriceBlock from "../price-block/form/PriceBlockForm";
import BillingBlock from "../billing-block/BillingBlockForm"
import AdminOrganizationWorker from "./AdminOrganizationWorker";


class AdminOrganization extends React.Component{

    render() {
        let { getSite, role} = this.props;
        let {success} =  getSite
        return (
            <>
             {/*<SiteDashboardWorker/>*/}
            <AdminOrganizationWorker/>
            {role === "super-admin" ?
                <>
                    <div className="single-setting">
                        <div className="setting-content" id="Price">
                            <div className="setting-content-wrapper">
                                {success ?
                                    <>
                                        <BillingBlock/>
                                        <PriceBlock />
                                    </>
                                    :
                                    <Loading />
                                }
                            </div>
                        </div>
                    </div>
                </>
                    : null
                }

                <div className="single-setting">
                    <div className="setting-content" id="InternalMigration">
                        <div className="setting-content-wrapper">
                            <InternalMigrationBlock/>
                        </div>
                    </div>
                </div>
                
                <div className="single-setting mb-90">
                    <div className="setting-content" id="Payment">
                        <div className="setting-content-wrapper">
                            <OrganizationBlock/>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    let { route, userDetail, organizationDashboard ,getOrganization , getCredentials , getSite, siteDashboard} = state;
    let role = route && route.data.user && route.data.user.role.slug;
    let {link} = siteDashboard.data;
    return { userDetail, organizationDashboard ,getOrganization , getCredentials, getSite, role, link,siteDashboard};
}

export default withRouter(connect(mapStateToProps)(AdminOrganization));