import React from 'react';
import store from '../../../Store';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {toggleOrganizationListFilter,onBillableChange,onBillableReset} from './OrganizationList-Actions'
import {unslufigy} from "../../../helpers/StringHelper";
import {Link} from "react-router-dom";
import Select from "react-select";
import {activeStatusFilter} from "../../../static-data/UserData";

class OrganizationListFilters extends React.Component {
    componentDidMount() {
    }

    toggleFilter = (e) => {
        store.dispatch(toggleOrganizationListFilter(e, this.props.history));
    }

    handleBillable = (selectedOption) => {
        if( selectedOption ) {
            store.dispatch(onBillableChange(selectedOption.value , this.props.history));
        }else {
            let value="all";
            store.dispatch(onBillableReset(value, this.props.history));
        }
    }

    render() {
        let {title, params, siteClick, noSites, role} = this.props;
        let clearable = true;
        let billableList = [];
        activeStatusFilter && activeStatusFilter.map(status => {
            billableList.push({value:status.value, label:status.name})
        });
        return (
            <div className="heading-block pr-60">
                <div className="title-block">
                    <h2>{"Organizations"}</h2>
                </div>
                <div className="search-bar">
                    <input type="text"
                           className="form-control"
                           name="q"
                           placeholder={`Search ${unslufigy(title)}`}
                           value={params.q}
                           onChange={this.toggleFilter}
                    />
                    <span className="icon-search"/>
                </div>

                <div className="select-block" id="filter-billable">
                    <Select name="billable"
                            isClearable={clearable}
                        value={billableList.name}
                        options={billableList}
                        onChange={this.handleBillable}
                            placeholder="Billing.."
                    />

                </div>

                <div className="button-wrapper d-flex">
                    {  
                        role === "super-admin" &&
                        <Link to={`organizations/create`} className="btn-theme btn-dark-blue">
                            {`Add Org`}
                        </Link>
                    }
                    <button onClick={siteClick} className="btn-theme btn-dark-blue ml-3">
                        {noSites ? "All Organizations" :"Zero Org"}
                    </button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let {getOrganizations, organizationList} = state;

    let organizations =
        getOrganizations &&
        getOrganizations.success &&
        getOrganizations.success.data &&
        getOrganizations.success.data.data &&
        getOrganizations.success.data.data.data;

    let {params} = organizationList.data;

    return {getOrganizations, organizationList, organizations, params};
}

export default withRouter(connect(mapStateToProps)(OrganizationListFilters));