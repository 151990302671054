import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultRoleFormState = {
  payload:{
    name:``,
    slug:``,
    permissions:[],

  },
  admin_role:[],
  admin_permission:[],
  admin:[],
  backup:[],
  domain:[],
  server:[],
  site:[],
  data_center:[],
  load_balancer:[],
  customer:[],
  organization:[],
  dashboard:[],
  customer_role:[],
  customer_permission:[],
  // permissionList:[],
  // permissionTitles:[],
  // permissionTitles:[{
  //   AdminRole,
  //   AdminPermission,
  //   Admin,
  //   Backup,
  //   Domain,
  //   Server,
  //   Site,
  //   DataCenter,
  //   LoadBalancer
  // }],

  //
  // originalPermissionList:[],
  // originalPermissionTitles:[],

  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ``
};

export const RoleFormState = { data: DefaultRoleFormState };