import {toggleURLQuery} from "../../../helpers/UrlHelper";
import getPayments from "../../../ajax/actions/invoice/payment_get";
import store from "../../../Store"


export function togglePaymentListFilter(e, history,)
{
  return function (dispatch ) {
    let { name, value  } = e.target;
    toggleURLQuery(name, value, history)
    dispatch({type:'TOGGLE-PAYMENT-LIST-FILTER', name, value});
    store.dispatch(getPayments())
  }
}