import {OrganizationBlockFormState} from './OrganizationBlockForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../../helpers/ErrorHelper'
import {endOfURL} from '../../../../helpers/UrlHelper'
import {isObject} from "../../../../helpers/DataHelper";

export default function organizationFormReducer(state = OrganizationBlockFormState, action) {

    let {data} = {...state};
    let {validationErrors, value, site_id, organization_id} = action;
    switch (action.type) {

        case 'ON-ORGANIZATION-BLOCK-FORM-CHANGE':
            data.payload["organization_id"] = value;
            buildErrors();
            return {...state, data};

        case 'SET-SITE-ID':
            data.site_id = site_id;
            buildErrors();
            return {...state, data};

        case 'SET-ORGANIZATION-ID':
            data.organization_id = organization_id;
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-ORGANIZATION-BLOCK-FORM':
            data = {
                payload: {
                    organization_id: '',
                },
                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: endOfURL(),
                message: ``
            };
            buildErrors();
            return {...state, data};

        case 'CANCEL-ORGANIZATION-BLOCK-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-ORGANIZATION-BLOCK-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'ORGANIZATION-BLOCK-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            );
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, validationErrors} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            if (field === `organization_id`) {
                if (!_(field)) newError(field, 0, `is required`)
            }
        }

        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}