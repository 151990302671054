import store from '../../../Store';
import {toggleURLQuery} from "../../../helpers/UrlHelper";
import getBugs from "../../../ajax/actions/support/bugs_get";

export function toggleBugFilter(e, history,) {
    return function (dispatch) {
        let {name, value} = e.target;
        toggleURLQuery(name, value, history)
        dispatch({type: 'TOGGLE-BUG-FILTER', name, value});
        store.dispatch(getBugs());
    }
}

export function onClickTag(name, value, history) {
    return function (dispatch) {
        toggleURLQuery(name, value, history)
        dispatch({type: 'TOGGLE-BUG-FILTER', name, value});
        store.dispatch(getBugs());
    }
}

export function filter(name, value) {
    return function (dispatch) {
        toggleURLQuery(name, value)
        dispatch({type: 'TOGGLE-BUG-FILTER', name, value});
        store.dispatch(getBugs());
    }
}
export function filterUrgency(value) {
    return function (dispatch) {
        dispatch({type: 'URGENCY-FILTER', value});
    }
}
export function filterAll(value) {
    return function (dispatch) {
        dispatch({type: 'All-FILTER', value});
    }
}

export function toggleBugFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-BUG-FORM-MODAL'});
    }
}

export function tagStoreFromUrlParam(tag) {
    return function (dispatch) {
        dispatch({type: 'PATH-FROM-BUG-URL', tag});
    }
}