import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError, handleSuccess} from "../../../helpers/ErrorHelper";
import store from "../../../Store";
import getSslCertificates from "./ssl_get";
import {loginToken} from "../../../helpers/LocalStorageHelper";

function _success(success) {
  return{ type: 'GET_SSL_CERTIFICATES_DELETE_SUCCESS',success };
}
function _error(error) {
  return{ type: 'GET_SSL_CERTIFICATES_DELETE_ERROR',error };
}
function _processing(processing) {
  return{ type: 'GET_SSL_CERTIFICATES_DELETE_PROCESSING', processing };
}

function deleteSslCertificates(site_id,id) {
  return ( dispatch ,getState) => {
    dispatch(_processing(true));
    dispatch(_error(null));
    let {organizationDashboard} = getState();
    let org_id = organizationDashboard.data.organization_id;

      axios({
        url: apiBaseUrl(`/admin/organizations/${org_id}/sites/${site_id}/certificates/${id}`),
        method: "delete",
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + loginToken()
        }
      }).then(function (res) {
        dispatch(_processing(false));
        dispatch(_success(res));
        handleSuccess( res )
        store.dispatch(getSslCertificates(site_id));

      }).catch(function (error) {
        handleError(error);
        dispatch(_error(error));
        dispatch(_processing(false));
      });
    }
}
export default deleteSslCertificates;
