export function MigrationFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'MIGRATION-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onMigrationFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-MIGRATION-FORM-SUBMIT'})
    }
}

export function onMigrationFormChange(name,value) {
    return function (dispatch) {
        dispatch({type: 'ON-MIGRATION-FORM-CHANGE', name, value});
    }
}

export function onDeleteMigrationForm(value) {
    return function (dispatch) {
        dispatch({type: 'ON-DELETE-MIGRATION-FORM', value});
    }
}

export function onMigrationFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-MIGRATION-FORM-MODE-CHANGE', mode});
    }
}

export function cancelMigrationFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-MIGRATION-FORM-DISPATCH'});
    }
}

export function setDefaultMigrationForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-MIGRATION-FORM'});
    }
}
export function setMigrationDataForUpdate() {
    return function (dispatch, getState) {
        let {getMigration} = getState();
        let migration = getMigration && getMigration.success && getMigration.success.data && getMigration.success.data.data;
        dispatch({type: 'SET-MIGRATION-DATA-FOR-UPDATE', migration});
    }
}

export function setMigrationDataForView() {
    return function (dispatch, getState) {
        let {getMigration} = getState();
        let migration = getMigration && getMigration.success && getMigration.success.data && getMigration.success.data.data;
        dispatch({type: 'SET-MIGRATION-DATA-FOR-VIEW', migration});
    }
}

export function setSiteId(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITE-ID', site_id});
    }
}

