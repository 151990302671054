import React from 'react';
import TextFieldDeleteSite from "../../../components/shared/form/TextFieldDeleteSite";
import store from "../../../Store";
import {toggleDangerZoneFormModal} from "../list/SiteList-Actions";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import deleteSite from "../../../ajax/actions/sites/site_delete";
import Loading from "../../../components/shared/loaders/Loading";

class DeleteSiteForm extends React.Component {

    constructor() {
        super();
        this.state = {};
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    deleteConfirm = (e) => {
        e.preventDefault();
        store.dispatch(deleteSite(this.props.site_id, this.props.org_id));
        this.props.history.push(`/organizations/${this.props.org_id}/sites`);
    }

    render() {
        let {siteName, getSite, setCloseModel, deleteSite} = this.props;
        let deleteSiteProcessing = deleteSite.processing
        let {processing} = getSite
        return (
            <>
            <div className="delete-site">

                <h3 className="delete-site-header text-left mb-30">Destroy Site</h3>
                <div className="delete-site-line">
                    <p>Are you sure you want to permanently delete {processing ? <Loading/> : siteName}.</p>
                    <p>Confirm by entering the Droplet text below</p>
                    <p className="delete-site-name">
                        <h3>{processing ? <Loading/> : siteName}</h3>
                    </p>

                        <form className="mt-30 domain-form" onSubmit={this.deleteConfirm}>
                            {/*<div className="delete-site-form mb-20">*/}
                            <div className="form-group">
                                <TextFieldDeleteSite name="site_name"
                                                     value={this.state.site_name}
                                                     placeholder="Enter here"
                                                     label="Enter the name of this Droplet"
                                                     siteName={siteName}
                                                     onChange={this.onChange}/>
                            </div>
                            {/*</div>*/}

                    <div className="button-wrapper inline-buttons text-center d-flex justify-content-center">
                        <button className="btn-theme btn-outline" type="reset"
                                onClick={ setCloseModel}
                        >
                            Cancel
                        </button>

                        <button type="submit"
                            className={siteName !== this.state.site_name ? "btn-theme btn-theme-disabled blue-outline btn-lg" : "btn-theme btn-outline red-btn"}
                            disabled={siteName !== this.state.site_name}
                        >
                            {deleteSiteProcessing?<><Loading color="white"/></>:"Delete Site"}
                        </button>
                    </div>
                        </form>
            </div>
            </div>
    </>

    )
    }
}

function mapStateToProps(state) {
    let {getSite, deleteSite} = state;
    let siteId = getSite && getSite.success && getSite.success.id
    let siteName = getSite && getSite.success && getSite.success.name
    return {
        getSite, siteId, siteName, deleteSite
    };
}

export default withRouter(connect(mapStateToProps)(DeleteSiteForm));