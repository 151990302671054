import React from 'react';
import store from '../../../Store';
import {connect} from "react-redux";
import getsMigration from "../../../ajax/actions/migration/migration_gets";
import {withRouter} from "react-router";


class MigrateWorker extends React.Component
{
  componentDidMount() {
    store.dispatch(getsMigration())
  }

  render()
  {
      return null
  }
}

function mapStateToProps( state ) {
  let {getsMigration } = state;
  return { getsMigration}
}
export default withRouter(connect(mapStateToProps)(MigrateWorker));
