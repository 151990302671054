import { MainRouteState } from './MainRoute-State'

export default function MainRouteReducer (state = MainRouteState, action) {
  let { data } = { ...state };
  let { role, users, menu, url } = action;

  switch (action.type) {

    case 'SET-USER-ROLE':
      data.user_role = role;
      data.action = 'render';
      return {...state, data}

    case 'SET-USER':
      data.user = users;
      data.action = 'render';
      return {...state, data}

    case 'SET-DASHBOARD-MENU':
      data.dashboard_menu = menu;
      return {...state, data}

    case 'SET-ORAGANIZATION-SITE-URL':
      data.site_url = url;
      return {...state, data}

    case 'LOG-OUT-USER':
      data.user_role = ``;
      return {...state, data}

    default: return state;
  }
}