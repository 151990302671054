import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import IgnoreCacheFormWorker from './IgnoreCacheFormWorker'
import {
    onIgnoreCacheFormChange,
    onIgnoreCacheFormSubmit
} from "./IgnoreCacheForm-Action";
import TextField from "../../../../components/shared/form/TextFieldIgnoreCacheForm";
import Loading from "../../../../components/shared/loaders/Loading";
import {toggleCacheIgnoreFormModal} from "../../feature-block/list/FeatureBlock-Action";

class IgnoreCacheForm extends React.Component {

    ignoreCacheFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onIgnoreCacheFormSubmit());
    };

    onChange = (e) => {
        let value = e.target.value.replace(/[!@$%^*()\[\]{};':"\\|,.<>]+/,"")
            store.dispatch(onIgnoreCacheFormChange(e.target.name, value));
    };

    render() {
        let {
            errors,
            site_status,
            domainLists,
            cacheIgnore,
            path,
            mode,
            getCacheIgnore
        } = this.props;

        const primaryDomain = (domainLists || []).find(d => d.is_primary === 1) || '';

        let {processing} = cacheIgnore
        let getCacheIgnoreProcessing = getCacheIgnore.processing

        return (
            <>
                <IgnoreCacheFormWorker/>
                {getCacheIgnoreProcessing ?
                    <div className="d-flex align-items-center flex-column" id="ignore-cache-pop-up">
                        <h2>Update Ignore Cache <Loading/></h2>
                    </div>
                    :
                    <div className="d-flex align-items-center flex-column" id="ignore-cache-pop-up">
                        {mode === 'create' ? <h2>Ignore Cache</h2> : <h2>Update Ignore Cache</h2>}
                        <form className="mt-30" onSubmit={this.ignoreCacheFormSubmit}>
                            <div className="ignore-cache-primarydomain d-flex">
                                <TextField name="path"
                                           className="form-control"
                                           type="text"
                                    // value={path.replace('/','')}
                                           value={path}
                                           primaryDomainName={primaryDomain.name}
                                           placeholder="Ignore Cache"
                                           noLabel
                                           errors={errors.path}
                                           onChange={(e) => this.onChange(e)}/>
                            </div>

                            <div
                                className="button-wrapper inline-buttons text-center d-flex justify-content-center mt-4">
                                <button className="btn-theme btn-outline" type="reset"
                                        onClick={() => store.dispatch(toggleCacheIgnoreFormModal())}>Cancel
                                </button>
                                <button type="submit" className="btn-theme radius-btn-blue"
                                        disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}>

                                    {processing ? <Loading/> : `Submit`}
                                </button>
                            </div>
                        </form>
                    </div>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        ignoreCacheForm
        ,
        getSite
        ,
        getSiteStatus
        ,
        siteDashboard
        ,
        getSiteDomain
        ,
        cacheIgnore
        ,
        getCacheIgnore
    }

        = state;

    let {site_status} = siteDashboard.data;
    let domainLists =
        getSiteDomain &&
        getSiteDomain.success &&
        getSiteDomain.success.data &&
        getSiteDomain.success.data.data &&
        getSiteDomain.success.data.data.data;
    let ignore_cache_event =
        getSiteStatus &&
        getSiteStatus.success &&
        getSiteStatus.success.event
    let site_search_status_message =
        getSiteStatus &&
        getSiteStatus.success &&
        getSiteStatus.success.message

    let {errors, payload, mode} = ignoreCacheForm.data;

    let {
        path
    } = payload;

    return {
        ignoreCacheForm,
        path,
        mode,
        errors,
        ignore_cache_event,
        getSite,
        getSiteStatus,
        site_search_status_message,
        site_status,
        getSiteDomain,
        domainLists,
        cacheIgnore,
        getCacheIgnore
    };
}

export default connect(mapStateToProps)(IgnoreCacheForm);