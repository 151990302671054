import React from 'react';
import store from '../../../Store';
import getPrices from "../../../ajax/actions/price/price_gets";

class PriceWorker extends React.Component {

    componentDidMount() {
        store.dispatch(getPrices());
    }

    render() {
        return null
    }
}

export default PriceWorker;