import React from 'react';
import store from "../../../../Store";
import {connect} from "react-redux";
import {toggleBackupFormModal} from "./Backup-Action";

class BackupPrimaryView extends React.Component {

    render() {
        let { site_status,getSite } = this.props
        return (
            // <div className="d-flex align-items-center justify-content-between mt-4" id="cache-row">
                <div className="tab-backup-button">
                    <button className="btn-theme btn-dark-blue" id="backup-btn" data-toggle="modal"
                            data-target="#manual-backup" onClick={()=> store.dispatch(toggleBackupFormModal(getSite && getSite.success && getSite.success.id))}
                            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring' ? 'disabled' : null}
                    >Create</button>
                </div>
            // </div>

        )
    }
}

function mapStateToProps(state) {
    let {getSite, getSiteStatus, siteDashboard} = state;
    let {site_status} = siteDashboard.data;

    return { getSite, getSiteStatus, site_status, siteDashboard};

}

export default connect(mapStateToProps)(BackupPrimaryView);