import store from '../../../Store';
import {toggleURLQuery} from "../../../helpers/UrlHelper";
import getMigrationList from "../../../ajax/actions/migration/migration_gets";

export function toggleMigrateFilter(e, history,)
{
  return function (dispatch ) {
    let { name, value  } = e.target;
    toggleURLQuery(name, value, history)
    dispatch({type:'TOGGLE-MIGRATE-FILTER', name, value});
    store.dispatch(getMigrationList());
  }
}

export function toggleMigrateFormModal() {
  return function (dispatch) {
    dispatch({type: 'TOGGLE-MIGRATE-FORM-MODAL'});
  }
}
