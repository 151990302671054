export function organizationFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:'ORGANIZATION-FORM-SERVER-VALIDATION-ERRORS', validationErrors });
  }
}

export function onOrganizationFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:'ON-ORGANIZATION-FORM-SUBMIT' })
  }
}

export function onOrganizationFormChange( name, value )
{
  return function ( dispatch )
  {
    dispatch({ type:'ON-ORGANIZATION-FORM-CHANGE', name, value} );
  }
}

export function selectCountry( value ) {
  let name = "country_id"
  return function ( dispatch ) {
    dispatch({ type:'ON-ORGANIZATION-FORM-CHANGE', name,  value});
  }
}

export function onOrganizationFormTypeChange( data_type )
{
  return function ( dispatch )
  {
    dispatch({ type:'ON-ORGANIZATION-FORM-TYPE-CHANGE', data_type } );
  }
}

export function onOrganizationFormModeChange( mode )
{
  return function ( dispatch )
  {
    dispatch({ type:'ON-ORGANIZATION-FORM-MODE-CHANGE', mode} );
  }
}

export function cancelOrganizationFormDispatch() {
  return function (dispatch) {
    dispatch({ type:'CANCEL-ORGANIZATION-FORM-DISPATCH' });
  }
}

export function setDefaultOrganizationForm() {
  return function (dispatch){
    dispatch({type:'SET-DEFAULT-ORGANIZATION-FORM'});
  }
}

export function setOrganizationDataForUpdate()
{
  return function (dispatch, getState) {
    let { getOrganization } = getState();
    let organization = getOrganization && getOrganization.success;
    dispatch({type:'SET-ORGANIZATION-DATA-FOR-UPDATE',  organizations: JSON.parse(JSON.stringify( organization )) });
  }
}

export function listPermissionForAssign() {
  return function (dispatch, getState) {
    let { getPermissions } = getState();
    let permissions = getPermissions.success &&
        getPermissions.success.data && getPermissions.success.data.data;
    if( permissions ) {
      dispatch({type:'PERMISSIONS-LIST-TO-ASSIGN',  permissions });
    }
  }
}

export function onOrganizationPermissionsFormChange(name, value) {
  return function (dispatch){
    dispatch({type:'ON-ORGANIZATION-PERMISSIONS-FORM-CHANGE', name, value});
  }
}