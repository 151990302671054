import React from 'react';
import {connect} from 'react-redux';
import Loading from "../../../../components/shared/loaders/Loading";
import PlanForm from "../form/PlanForm";
import swal from "sweetalert";
import store from "../../../../Store";
import deletePlan from "../../../../ajax/actions/hosting-plan/plan_delete";
import SelectField from "../../../../components/shared/form/SelectFieldPlan";
import {setDefaultPlanForUpdate, setPlanId, setPlanMode} from "./PlanList-Action";
import updatePlan from "../../../../ajax/actions/hosting-plan/plan_put";
import getPlans from "../../../../ajax/actions/hosting-plan/plan_get";

class PlanList extends React.Component {

    componentDidMount() {
        store.dispatch(getPlans());
        store.dispatch(setPlanMode(" "));
        store.dispatch(setDefaultPlanForUpdate());
    }

    deleteConfirm = (site_id) => {
        swal("Are you sure you want to delete this plan? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: site_id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id) {
        store.dispatch(deletePlan(id));
    }

    onChange = (e) => {
        e.preventDefault();
        let id = e.target.value;
        store.dispatch(setPlanId(id));
        let payload = {
            plan_id: id
        }
        store.dispatch(updatePlan(payload));
    }

    onClickUpdate = (e,plan_id) => {
        e.preventDefault();
        store.dispatch(setPlanMode("update"));
        store.dispatch(setPlanId(plan_id));
    }

    render() {
        let {plan, getSite ,site_id, deletePlan,updatePlan, mode, plan_id, plans, wp_flag } = this.props;
        let {processing} =  deletePlan
        let {success, processing: updatePlanProcessing,error} = updatePlan
        let { processing: getSiteProcessing} = getSite
        let plansList = [];
        let err = error && error?.response.data.errors
        /*
        * we are choosing plans according to the wordpress flag in this case where as in siteform
        * we are choosing according to wp, ec  and blankinstall
        * api should be rewritten for the proper fix of this issue
        * */
        plans && plans.map(plan => {
            if (wp_flag === 1) {
                if (plan.site_type === "Wordpress")
                    plansList.push({value: plan.id, name: plan.description})
            }
            if (wp_flag === 0) {
                if (plan.site_type === "Blank")
                    plansList.push({value: plan.id, name: plan.description})
            }
        })

        return (
            <>
                {!success && (getSiteProcessing || processing)? <Loading/> :
                plan ?
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="pills-daily" role="tabpanel"
                         aria-labelledby="pills-daily-tab">
                        <div className="table-block table-responsive pr-60 table-list-block">
                            <table className="table table-striped">

                                <thead id="backup-table-head">
                                <tr>
                                    <th>
                                        Title
                                    </th>
                                    <th>
                                        Interval
                                    </th>
                                    <th>
                                        Price
                                    </th>
                                    <th>
                                        Site Type
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Currency
                                    </th>
                                    <th>
                                        Action
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                        <tr>
                                            <td>
                                                {plan && plan.title}
                                            </td>
                                            <td>
                                                {plan && plan.interval}
                                            </td>
                                            <td>
                                                {plan && plan.price}
                                            </td>
                                            <td>
                                                {plan && plan.site_type}
                                            </td>
                                            <td>
                                                {updatePlanProcessing || getSiteProcessing ? <Loading/>:
                                                    mode === "update" ?
                                                    <SelectField name="plan_id"
                                                                 value={plan_id}
                                                                 options={plansList}
                                                                 errors={err && err.plan_id}
                                                                 onChange={this.onChange}
                                                                 className="px-0 mb-0"
                                                                 _value="value"
                                                                 _label="name"
                                                                 label="Select Plan"
                                                    />
                                                    :
                                                    plan && plan.description
                                                }

                                            </td>
                                            <td className="text-center">
                                                {plan && plan.currency}
                                            </td>
                                            <td>
                                                <div className="dropdown dropdown-alt text-center">
                                                <span className="dropdown-toggle"
                                                      data-toggle="dropdown">
                                                    <span className="icon-more"/>
                                                </span>
                                                    <div className="dropdown-menu">
                                                        <a href="/#" type="button"
                                                           onClick={ev => {this.onClickUpdate(ev,plan.id)}}
                                                           className={`link-edit`}
                                                        >
                                                            Update
                                                        </a>
                                                        <a href="/#"
                                                           onClick={ev => {this.deleteConfirm(site_id);ev.preventDefault()}}
                                                           className={`link-delete`}>
                                                            Delete
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>

                                        </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                    :
                    <div className="settings-section" id="settings-sections-bulk">
                    <div className="single-setting">
                        <div className="setting-content" id="Price">
                            {/*<div className="setting-content-wrapper">*/}
                                <PlanForm/>
                            {/*</div>*/}
                        </div>
                    </div>
                    </div>
                }
            </>
        )
    }
}

function mapStateToProps(state) {
    let {getSite, deletePlan,updatePlan, planList, getPlans} = state;
    let organization_id =getSite && getSite.success && getSite.success.organization_id
    let site_id =getSite && getSite.success && getSite.success.id
    let plan = getSite && getSite.success && getSite.success.plan
    let wp_flag = getSite && getSite.success && getSite.success.wp_flag
    let {payload,mode } =  planList?.data
    let {plan_id} = payload
    let plans = getPlans && getPlans.success && getPlans.success.plans
    return {getSite, plan,organization_id,site_id,deletePlan,updatePlan, mode, plan_id, plans, wp_flag};
}

export default connect(mapStateToProps)(PlanList);