import React from 'react';
import store from '../../../../Store';
import {setSiteIDLogs} from "./Logs-Action";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import getSiteStatus from "../../../../ajax/actions/sites/site_status_get";
import getLogs from "../../../../ajax/actions/logs/Logs_get";

class LogsWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(setSiteIDLogs(props.match.params.id))
        // if(props.organizationDashboard.organization_id) {
        //     store.dispatch(getLogs(props.match.params.id, props.organizationDashboard.data.organization_id));
        //     store.dispatch(getSiteStatus(props.match.params.id, props.organizationDashboard.data.organization_id));
        // }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {organizationDashboard, getSiteStatus, getErrorLogs, getAccessLogs} = state;
    let {organization_id} = organizationDashboard.data;
    return {organizationDashboard, organization_id, getSiteStatus, getErrorLogs, getAccessLogs};
}

export default withRouter(connect(mapStateToProps)(LogsWorker));