import store from '../../../Store';
import { toggleURLQuery } from '../../../helpers/UrlHelper'
import getRoles from "../../../ajax/actions/role/roles_get";

export function toggleRoleListFilter(e, history)
{
  return function (dispatch) {
    let { name, value } = e.target;
    toggleURLQuery( name, value , history)
    dispatch({type:'TOGGLE-ROLE-LIST-FILTER', name, value, history});
    // store.dispatch(getRoles());
    if(name === 'limit') {
      store.dispatch( getRoles());
      toggleURLQuery( 'page', '',history)
    }
    else {
      store.dispatch(getRoles());
    }
  }
}

export function setDefaultRoleFiltersToURL( history )
{
  return ( dispatch, getState ) => {
    let { data } = getState().siteList;
    let { params } = data;
    Object.keys( params ).map( key => {
      if ( params[key] !== `` && params[key] !== null && params[key] !== undefined ) {
        toggleURLQuery( key, params[key], history)
      }
    })
  }
}