export function serverModuleFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:'SERVER-MODULE-FORM-SERVER-VALIDATION-ERRORS', validationErrors });
  }
}

export function onServerModuleFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:'ON-SERVER-MODULE-FORM-SUBMIT' })
  }
}

export function onServerModuleFormChange( name, value )
{
  return function ( dispatch )
  {
    dispatch({ type:'ON-SERVER-MODULE-FORM-CHANGE', name, value} );
  }
}

export function onServerModuleFormModeChange( mode )
{
  return function ( dispatch )
  {
    dispatch({ type:'ON-SERVER-MODULE-FORM-MODE-CHANGE', mode} );
  }
}

export function cancelServerModuleFormDispatch() {
  return function (dispatch) {
    dispatch({ type:'CANCEL-SERVER-MODULE-FORM-DISPATCH' });
  }
}

export function setDefaultServerModuleForm() {
  return function (dispatch){
    dispatch({type:'SET-DEFAULT-SERVER-MODULE-FORM'});
  }
}

export function setServerModuleDataForUpdate()
{
  return function (dispatch, getState) {
    let { getServerModule } = getState();
    let serverModule = getServerModule && getServerModule.success;
    dispatch({type:'SET-SERVER-MODULE-DATA-FOR-UPDATE',  serverModule });
  }
}