import store from '../../Store';
import {toggleURLQuery} from '../../helpers/UrlHelper'
import getSuperAdminSites from "../../ajax/actions/sites/super_admin_sites_get";
import getDashboard from "../../ajax/actions/dashboard/dashboard_get";

export function toggleDashboardListFilter(e, history) {
    return function (dispatch) {
        let {name, value} = e.target;
        toggleURLQuery(name, value, history)
        dispatch({type: 'TOGGLE-SITE-LIST-FILTER', name, value, history});
        store.dispatch(getSuperAdminSites());
    }
}
export function setDashboardSearch(search_status){
    return function (dispatch) {
        dispatch({type: `SET-DASHBOARD-SEARCH`, search_status})
    }
}

export function setDate(name,date){
    return function (dispatch) {
        dispatch({type: `SET-DATE`, name,date})
        store.dispatch(getDashboard())
    }
}

export function setDefaultSearch(){
    return function (dispatch) {
        dispatch({type: `SET-DEFAULT-SEARCH`})
    }
}

export function setDefaultDashboardFiltersToURL(history) {
    return (dispatch, getState) => {
        let {data} = getState().organizationList;
        let {params} = data;
        Object.keys(params).map(key => {
            if (params[key] !== `` && params[key] !== null && params[key] !== undefined) {
                toggleURLQuery(key, params[key], history)
            }
        })
    }
}