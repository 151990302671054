import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import RoleFormWorker from './RoleFormWorker'
import {
    onPartialFullItemChange,
    onRoleFormChange,
    onRoleFormSubmit,
    onRolePermissionsFormChange,
    onRolePermissionsFormSwitch
} from "./RoleForm-Action";
import TextField from "../../../components/shared/form/TextField";
import Loading from "../../../components/shared/loaders/Loading";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import {endOfURL} from "../../../helpers/UrlHelper";
import Link from "react-router-dom/Link";
import Page from "../../../components/shared/pages/Page";
import {ucFirst, unslufigy} from "../../../helpers/StringHelper";
import SwitchField from "../../../components/shared/form/SwitchField";


class RoleForm extends React.Component {
    state={
        first:false
    }

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    roleFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onRoleFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onRoleFormChange(e.target.name, e.target.value,));

    };

    onChangeSwitch = (checked, e, id) => {
        store.dispatch(onRolePermissionsFormSwitch(id));
    }

    onPermissionChange = (e) => {
        let {name, value} = e.target;
        store.dispatch(onRolePermissionsFormChange(name, value));
    }

    click =(e) => {
        this.setState({ first: !this.state.first });
        let value = e.target.value
        let mode = ``;
        if( e.target.className.includes('status-partial') ) {
            mode = `status-partial`
        } else if( e.target.className.includes('status-full') ) {
            mode = `status-full`
        }
        let nameArr = value.split(',');
        nameArr && store.dispatch(onPartialFullItemChange(nameArr, mode ));
        // nameArr && nameArr.map((item, index) => {
        //                 // store.dispatch(onRolePermissionsFormSwitch(item))
        //
        // })
    }

    render() {
        let {name, updateRole, createRole,title, getPermissions, roleForm, permissions} = this.props;
        const titles = title?.data && Object.keys(title.data)
        let {errors} = roleForm.data;
        let processing = updateRole.processing || createRole.processing;
        let permission_processing = getPermissions.processing;

        return (
            <>
                <Page title={endOfURL() === "create" ?
                    "Add Role" :
                    "Update Role"
                } processing={processing}>
                    <div className="heading-block pr-60">
                        <div className="title-block" id="update-title-block">
                            <h2>{ endOfURL() === 'create' ? `Add new Role` : `Update Role` } </h2>
                            <span><Link to="/roles">Roles</Link></span>
                            { endOfURL() === 'update' ? <span>{ucFirst(name)}</span> : null}
                            <span>{ endOfURL() === 'create' ? `Add new Role` : `Update` }</span>
                        </div>
                    </div>
                    <RoleFormWorker/>
                    <div className="box-block" id="role-form-content">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="pills-create" role="tabpanel"
                                 aria-labelledby="pills-create-tab">
                                <div className="padded-block">
                                    <form onSubmit={this.roleFormSubmit}>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group" id="role-form-inputs">
                                                    { endOfURL() === 'create' ?  
                                                        <TextField name="name"
                                                                value={ucFirst(name)}
                                                                label="Name"
                                                                errors={errors.name}
                                                                onChange={this.onChange}/>
                                                    
                                                    :  
                                                        <>
                                                            <TextField name="name"
                                                                        value={ucFirst(name)}
                                                                        label="Name"
                                                                        errors={errors.name}
                                                                        onChange={this.onChange}/>

                                                            <TextField name="name"
                                                                        value={ucFirst(name)}
                                                                        label="Role to Edit"
                                                                        errors={errors.name}
                                                                        onChange={this.onChange}/>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>

                                        <div className="role-check-block">
                                            <h4>Assign Permissions: { permission_processing ? <Loading /> : null } </h4>
                                            <div className="card-columns">
                                                {
                                                    titles?.map((title,index)=>
                                                        <div key={index} className="card">
                                                            <div className="single-role">
                                                                <div className="role-heading">
                                                                    <p>{unslufigy(ucFirst(title))}</p>
                                                                        <button key={index}
                                                                            className= {`badge ${roleForm.data[title]?.map((item, index) => {return(item.slug)}).every(target_data => 
                                                                                            roleForm?.data?.payload?.permissions.includes(target_data)) 
                                                                                                 ? "status-full":"status-partial"}`}
                                                                            type="reset"
                                                                            name={title}
                                                                            value={roleForm.data[title]?.map((item, index) => {return(item.slug)})}
                                                                            onClick={this.click}>
                                                                            {roleForm.data[title]?.map((item, index) => {return(item.slug)}).every(target_data =>
                                                                                roleForm?.data?.payload?.permissions.includes(target_data))
                                                                                    ? "FULL":"PARTIAL"}
                                                                        </button>
                                                                </div>
                                                                <ul>
                                                                    {roleForm.data[title]?.map((item, index) => {
                                                                        return (
                                                                            <li key={index}>
                                                                                <SwitchField
                                                                                    key={item.id}
                                                                                    id={item.slug}
                                                                                    name="admin_role"
                                                                                    value={item.slug}
                                                                                    label={unslufigy(ucFirst((item.slug)))}
                                                                                    checked={permissions.includes(item.slug)}
                                                                                    onChange={this.onChangeSwitch}/>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                        {endOfURL() === 'create' ?
                                        <div className="button-wrapper text-right mt-20 d-flex justify-content-end">
                                            <button type="submit" className="btn-theme btn-dark-blue">
                                                {processing ? <Loading/> : `Submit`}
                                            </button>
                                        </div>
                                        :
                                        <div className="button-wrapper text-right mt-20 d-flex justify-content-end">
                                            <button type="submit" className="btn-theme btn-dark-blue">
                                                {processing ? <Loading/> : `Save Changes`}
                                            </button>
                                        </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {roleForm, updateRole, createRole, getPermissions} = state;
    let title = getPermissions?.success?.data
    let {errors, payload} = roleForm.data;
    let {name, slug, permissions} = payload;
    return {
        getPermissions,title,roleForm, updateRole, createRole, errors,name, slug, permissions
    };
}

export default connect(mapStateToProps)(RoleForm);
