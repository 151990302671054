const DefaultMigrationFormState = {
    payload: {
        status: '',
    },
    view:{
        id:``,
        customerName:``,
        server_ip: ``,
        server_port:``,
        server_username:``,
        server_password:``,
        type: ``,
        db_login_url:``,
        is_multi_site: ``,
        is_ecommerce: ``,
        wordpress_username: ``,
        wordpress_password: ``,
        wordpress_admin_url: ``,
        create_new_site: ``,
        site_name: ``,
        status:``
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: `viewMigration`,
    message: ``,
    site_id: ``,
};

export const MigrationFormState = {data: DefaultMigrationFormState};