import store from '../../../Store';
import { toggleURLQuery } from '../../../helpers/UrlHelper'
import getUsers from "../../../ajax/actions/user/users_get";

export function toggleUserListFilter(e, history)
{
  return function (dispatch) {
    let { name, value } = e.target;
    toggleURLQuery( name, value , history)
    dispatch({type:'TOGGLE-USER-LIST-FILTER', name, value, history});
    // store.dispatch( getUsers());
    if(name === 'limit') {
      store.dispatch( getUsers());
      toggleURLQuery( 'page', '',history)
    }
    else {
      store.dispatch(getUsers());
    }
  }
}

export function setDefaultUserFiltersToURL( history )
{
  return ( dispatch, getState ) => {
    let { data } = getState().siteList;
    let { params } = data;
    Object.keys( params ).map( key => {
      if ( params[key] !== `` && params[key] !== null && params[key] !== undefined ) {
        toggleURLQuery( key, params[key], history)
      }
    })
  }
}