export function publicPathFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'PUBLIC-PATH-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onPublicPathFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-PUBLIC-PATH-FORM-SUBMIT'})
    }
}

export function onPublicPathFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-PUBLIC-PATH-FORM-CHANGE', name, value});
    }
}

export function onPublicPathFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-PUBLIC-PATH-FORM-MODE-CHANGE', mode});
    }
}

export function cancelPublicPathFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-PUBLIC-PATH-FORM-DISPATCH'});
    }
}

// export function setDefaultPublicPathForm() {
//     return function (dispatch) {
//         dispatch({type: 'SET-DEFAULT-PUBLIC-PATH-FORM'});
//     }
// }
export function setDefaultPublicPathForm() {
    return function (dispatch, getState) {
        let {getSite} = getState();
        let path = getSite && getSite.success && getSite.success.path;
        dispatch({type: 'SET-DEFAULT-PUBLIC-PATH-FORM',path});
    }
}

export function setSiteId(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITE-ID',site_id});
    }
}


export function publicPathError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-PUBLIC-PATH-ERROR', error});
    }
}