
export function toggleSSLFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-SSL-FORM-MODAL'});
    }
}

export function setSSLFilteredData() {
    return function (dispatch, getState) {
        let {getSiteDomainNoPagination} = getState();
        let {success} = getSiteDomainNoPagination;

        if (success) {
            let domainLists = success &&
                success.data &&
                success.data.data

            let newletsEncryptDomainList = domainLists && domainLists.filter(item => item.ssl_type === "letsencrypt");
            let newletsEncryptDomainListId = newletsEncryptDomainList && newletsEncryptDomainList.map(function (a) {
                return (a.id).toString();
            });
            let newCustomDomainList = domainLists && domainLists.filter(domain => domain.ssl_type === "custom");
            let newCustomDomainListId = newCustomDomainList && newCustomDomainList.map(function (a) {
                return (a.id).toString();
            });

            /*this is used for check mark of the lets_encrypt */
            let letsEncryptDomainList = domainLists && domainLists.filter(data => data.ssl_type === "letsencrypt");

            let isCheckedLetsEncryptDomainsList = letsEncryptDomainList && letsEncryptDomainList.map(function (a) {
                return (a.id).toString();
            });

            /*this is used for check mark of the custom */
            let customDomainList = domainLists && domainLists.filter(data => data.ssl_type === "custom");

            let isCheckedCustomDomainsList = customDomainList && customDomainList.map(function (a) {
                return (a.id).toString();
            });

            dispatch({type: 'SET-LETSENCRYPT-DATA',newletsEncryptDomainListId,newCustomDomainListId,isCheckedLetsEncryptDomainsList,isCheckedCustomDomainsList});
        }
    }
}

export function onChangeLetsEncrypt(e) {
    return function (dispatch, getState) {
        let {SSLList} = getState();
        let {data} = SSLList.data
        let changedDomainId = [...data, e.target.id];
        if (data.includes(e.target.id)) {
            changedDomainId = changedDomainId.filter(item => item !== e.target.id);
        }
            dispatch({type: 'ON-CHANGE-SSL-LETS-ENCRYPT',changedDomainId});

    }
}

