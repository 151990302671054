import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";
import {loginToken} from "../../../helpers/LocalStorageHelper";
import store from "../../../Store";
import {setRedirectDataForUpdate} from "../../../pages/site/redirects/form/RedirectsForm-Action";

function _success(success) {
  return{ type: 'GET_REDIRECT_SUCCESS',success };
}
function _error(error) {
  return{ type: 'GET_REDIRECT_ERROR',error };
}
function _processing(processing) {
  return{ type: 'GET_REDIRECT_PROCESSING', processing };
}

function getRedirect(site_id,id) {
  return ( dispatch,getState ) => {
    dispatch(_processing(true));
    dispatch(_error(null));
    let {organizationDashboard} = getState();
    let org_id = organizationDashboard.data.organization_id;
    // admin/organizations/380/sites/571/redirect_rule
      axios({
        url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/redirect_rule/${id} `),
        method: "get",
        dataType: 'json',
        headers: {
          'Authorization': 'Bearer ' + loginToken()
        }
      }).then(function (res) {
        dispatch(_processing(false));
        dispatch(_success(res));
        store.dispatch(setRedirectDataForUpdate());

      }).catch(function (error) {
        handleError(error);
        dispatch(_error(error));
        dispatch(_processing(false));
      });
    }
}
export default getRedirect;
