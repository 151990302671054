import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import {
    cancelMigrationFormDispatch,
    MigrationFormServerValidationErrors
} from "../../../pages/migration/form/MigrationForm-Action";
import {toggleMigrateFormModal} from "../../../pages/migration/list/Migrate-Actions";
import getsMigration from "./migration_gets";

function _success(success) {
    return {type: 'UPDATE_MIGRATION_STATUS_SUCCESS', success}
}

function _error(error) {
    return {type: 'UPDATE_MIGRATION_STATUS_ERROR', error}
}

function _processing(processing) {
    return {type: 'UPDATE_MIGRATION_STATUS_PROCESSING', processing}
}

function updateMigrationStatus(id) {
    return (dispatch,getState) => {
        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))
        let {migrationForm}=getState();

        axios({
            url: apiBaseUrl(`admin/manual-migration/update-status/${id}`),
            method: 'post',
            dataType: 'json',
            data: migrationForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                store.dispatch(toggleMigrateFormModal());
                store.dispatch(getsMigration())
                store.dispatch(cancelMigrationFormDispatch())


            }).catch(function (error) {
            dispatch(_error(error.response.data.data))
            dispatch(_processing(false))
            handleError(error)
            store.dispatch(cancelMigrationFormDispatch());
            if (error.response && error.response.status === 422) {
                store.dispatch(MigrationFormServerValidationErrors(error.response.data.errors))
            }
        })
    }
}

export default updateMigrationStatus
