import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import { handleSuccess} from '../../../helpers/ErrorHelper'
import {clone} from '../../../helpers/DataHelper'
import {cancelDomainFormDispatch, domainBulkError, domainError, domainFormServerValidationErrors, setDefaultDomainForm}
    from '../../../pages/site/domain/form/DomainForm-Action'
import store from '../../../Store';
import {scrollToError} from '../../../helpers/ScrollHelper'
import {toggleDomainFormModal} from "../../../pages/site/domain/list/Domain-Action";
import getSiteStatus from "../sites/site_status_get";

function _success(success) {
    return {type: 'POST_BULK_DOMAIN_SUCCESS', success};
}

function _error(error) {
    return {type: 'POST_BULK_DOMAIN_ERROR', error};
}

function _processing(processing) {
    return {type: 'POST_BULK_DOMAIN_PROCESSING', processing};
}

function createBulkDomain() {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {domainForm, organizationDashboard, siteDashboard} = getState();
        let data = clone(domainForm.data.payload);
        let organization_id = organizationDashboard.data.organization_id;
        let {site_id} = siteDashboard.data;

        axios({
            url: apiBaseUrl(`admin/organizations/${organization_id}/sites/${site_id}/domains/bulk`),
            method: "post",
            dataType: 'json',
            data,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res)
                store.dispatch(cancelDomainFormDispatch())
                store.dispatch(setDefaultDomainForm())
                store.dispatch(toggleDomainFormModal());
                store.dispatch(getSiteStatus(site_id));
            })
            .catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                // handleError( error )
                // store.dispatch(domainBulkError("The given data was invalid."))
                store.dispatch(domainBulkError("invalid domain name."))
                store.dispatch(cancelDomainFormDispatch())

                if (error.response && error.response.status === 422) {
                    store.dispatch(domainFormServerValidationErrors(error.response.data.errors))
                    scrollToError();
                }
            });
    }
}


export default createBulkDomain;