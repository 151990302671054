import getLoggedInUser from "../../ajax/actions/user/user_loggedin_get";

export function enable2FaServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'ENABLE-2FA-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onEnable2FaSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-ENABLE-2FA-FORM-SUBMIT'})
    }
}

export function selectPhoneCode(value) {
    let name = "country_code"
    return function (dispatch) {
        dispatch({type: 'ON-ENABLE-2FA-FORM-CHANGE', name, value});
    }
}

export function onEnable2FaChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-ENABLE-2FA-FORM-CHANGE', name, value});
    }
}

export function cancelEnable2FaDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-ENABLE-2FA-FORM-DISPATCH'});
    }
}

export function setDefaultEnable2Fa() {
    return function (dispatch,getState) {
        let {getLoggedInUser} = getState()
        let {countryCode, contactNumber} = getLoggedInUser?.success?.data?.data
        dispatch({type: 'SET-DEFAULT-ENABLE-2FA-FORM',countryCode, contactNumber});
    }
}