import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import CouponFormWorker from './CouponFormWorker'
import {
    onCouponFormChange, onCouponFormSubmit, onCouponCurrencyChange,
    onInitialUpdateCurrencyChange, onCouponFormDateChange, onCouponApplyFormChange
} from "./CouponForm-Action";
import TextField from "../../../components/shared/form/TextField";
import Loading from "../../../components/shared/loaders/Loading";
import SelectField from "../../../components/shared/form/SelectField";
import {activeStatusStringFilter} from "../../../static-data/UserData";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Link from "react-router-dom/Link";
import {endOfURL} from "../../../helpers/UrlHelper";
import Page from "../../../components/shared/pages/Page";
import DatePicker from 'react-datepicker'
import {fullDateStringNoTime} from "../../../helpers/DateTimeHelper";
import {withRouter} from "react-router";
import Select from "react-select";
import NewSearchableSelect from "../../../components/shared/form/NewSearchableSelect";

class CouponForm extends React.Component {
    constructor() {
        super();
        this.state = {};
        this.onInputchange = this.onInputchange.bind(this);
        this.CouponFormSubmit = this.CouponFormSubmit.bind(this);
    }

    onInputchange(event) {
        store.dispatch(onInitialUpdateCurrencyChange(event.target.name, event.target.value))
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    CouponFormSubmit = (e) => {
        e.preventDefault();
        if (Object.keys(this.state).length !== 0) {
            store.dispatch(onCouponCurrencyChange(this.state));
        }
        store.dispatch(onCouponFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onCouponFormChange(e.target.name, e.target.value,));
    };

    onChangeOrganization = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.value;
        }
        store.dispatch(onCouponApplyFormChange("organization_id", value));
    };

    onChangeCustomer = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.value;
        }
        store.dispatch(onCouponApplyFormChange("customer_id", value));
    };

    onChangeDate = (name, date) => {
        store.dispatch(onCouponFormDateChange(name, fullDateStringNoTime(date)));
    };

    render() {
        let {
            couponForm,
            updateCoupon,
            createCoupon,
            name,
            effective_from,
            effective_to,
            total_usage_amount,
            status,
            value,
            getCurrency,
            currencyList,
            currencyError,
            changeOrganizationsList,
            organization_id, customer_id,
            organizationUser,
            mode
        } = this.props;

        let {errors, submittedStatus} = couponForm.data;
        let processing = updateCoupon.processing || createCoupon.processing || getCurrency.processing;
        const startDate = new Date();
        const endDate = new Date();
        let fromDate = effective_from && new Date(effective_from)
        let toDate = effective_to && new Date(effective_to)
        let clearable = true;
        let organizationList = [];
        changeOrganizationsList && changeOrganizationsList.map(organization => {
            organizationList.push({value: organization.id, label: organization.name})
        });

        let organizationUserList = [];
        organizationUser && organizationUser.map(user => {
            organizationUserList.push({value: user.id, label: user.first_name})
        });

        return (

            <>
                <Page title={endOfURL() === "create" ?
                    "Add Coupon" :
                    endOfURL() === 'update' ?
                        "Update Coupon" :
                        'Apply Coupon'
                } processing={processing}>
                    <div className="heading-block pr-60">
                        <div className="title-block">
                            <h2> {endOfURL() === 'create' ? 'Add new Coupon' :
                                endOfURL() === 'update' ? 'Update Coupon' : 'Apply Coupon'}</h2>
                            <span><Link to="/coupon">Coupon</Link></span>
                            <span>{endOfURL() === 'create' ? `Add new Coupon` :
                                endOfURL() === 'update' ? `Update` : `Apply Coupon`}</span>
                        </div>
                    </div>
                    <CouponFormWorker/>
                    <div className="box-block">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="pills-add-user" role="tabpanel"
                                 aria-labelledby="pills-add-user-tab">
                                <div className="padded-block">
                                    <form onSubmit={this.CouponFormSubmit}>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField name="name"
                                                               value={name}
                                                               label="Coupon Name"
                                                               errors={errors.name}
                                                               onChange={this.onChange}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 d-flex">
                                                <div className="form-group mr-3">
                                                    <label>Effective From</label><br/>
                                                    <DatePicker className="form-control" dateFormat="yyyy-MM-dd"
                                                                selected={fromDate ? fromDate : startDate}
                                                                onChange={date => this.onChangeDate("effective_from", date)}/>
                                                </div>

                                                <div className="form-group">
                                                    <label>Effective To</label><br/>
                                                    <DatePicker className="form-control" dateFormat="yyyy-MM-dd"
                                                                selected={toDate ? toDate : endDate}
                                                                onChange={date => this.onChangeDate("effective_to", date)}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField name="total_usage_amount"
                                                               value={total_usage_amount}
                                                               label="Total Usage Amount"
                                                               errors={errors.total_usage_amount}
                                                               response_error={currencyError && currencyError.errors && currencyError.errors.total_usage_amount}
                                                               onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <SelectField name="status"
                                                                 value={status}
                                                                 options={activeStatusStringFilter}
                                                                 errors={errors.status}
                                                                 onChange={this.onChange}
                                                                 _value="value"
                                                                 _label="name"/>
                                                </div>
                                            </div>

                                            {currencyList && currencyList.map(l => {
                                                return (
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <TextField name={l}
                                                                       type="number"
                                                                       value={value[l]}
                                                                       label={l}
                                                                       errors={currencyError && currencyError.errors &&
                                                                       currencyError.errors["value." + l] ? currencyError.errors["value." + l] : submittedStatus && value === '' && "The given data was invalid."}
                                                                       onChange={this.onInputchange}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                            }
                                            {mode === "apply"?<>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    {/*<label>Organization</label>*/}
                                                    <NewSearchableSelect
                                                        name="organization_id"
                                                        isClearable={clearable}
                                                        placeholder="Select Organization"
                                                        value={organizationList.id}
                                                        options={organizationList}
                                                        onChange={this.onChangeOrganization}
                                                        errors={errors.organization_id}
                                                        label="Organization"
                                                    />

                                                </div>
                                            </div>

                                            <div className="col-md-6">

                                                <div className="form-group">
                                                    <NewSearchableSelect
                                                        name="customer_id"
                                                        isClearable={clearable}
                                                        placeholder="Select Customer"
                                                        value={organizationUserList.id}
                                                        options={organizationUserList}
                                                        onChange={this.onChangeCustomer}
                                                        errors={errors && errors.customer_id}
                                                        label="Organization Customer"
                                                    />

                                                </div>
                                            </div>
                                            </> :null}

                                        </div>
                                        <div
                                            className="button-wrapper inline-buttons text-center d-flex justify-content-end"
                                            id="form-submit-btn">
                                            <button type="submit"
                                                    className="btn-theme btn-dark-blue">
                                                {processing ? <Loading/> : `Submit`}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>
            </>

        )
    }
}

function mapStateToProps(state) {
    let {
        couponForm, updateCoupon, createCoupon,
        getCurrency, getCoupon, adminApplyDiscount,
        getOrganizationsBlock,
        getOrganizationUsers
    } = state;

    let {errors, payload, currencyError,mode} = couponForm.data;

    let {
        name, effective_from, effective_to, total_usage_amount, status, value, organization_id, customer_id
    } = payload;
    let currencyList = getCurrency.success
    let coupon = getCoupon.success
    let changeOrganizationsList =
        getOrganizationsBlock &&
        getOrganizationsBlock.success &&
        getOrganizationsBlock.success.data &&
        getOrganizationsBlock.success.data.data
    let organizationUser =
        getOrganizationUsers &&
        getOrganizationUsers.success &&
        getOrganizationUsers.success.data &&
        getOrganizationUsers.success.data.data &&
        getOrganizationUsers.success.data.data.data

    return {
        couponForm,
        updateCoupon,
        createCoupon,
        errors,
        name,
        effective_from,
        effective_to,
        total_usage_amount,
        value,
        status,
        getCurrency,
        currencyList,
        getCoupon,
        coupon,
        currencyError,
        adminApplyDiscount,
        getOrganizationsBlock,
        changeOrganizationsList,
        organization_id, customer_id,
        getOrganizationUsers,
        organizationUser,mode
    };
}

export default withRouter(connect(mapStateToProps)(CouponForm));