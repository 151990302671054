import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import {scrollToError} from '../../../helpers/ScrollHelper'
import {
    cancelLoadBalancerFormDispatch,
    loadBalancerFormServerValidationErrors
} from "../../../pages/load-balancer/form/LoadBalancerForm-Action";

function _success(success) {
    return {type: 'UPDATE_LOAD_BALANCER_SUCCESS', success}
}

function _error(error) {
    return {type: 'UPDATE_LOAD_BALANCER_ERROR', error}
}

function _processing(processing) {
    return {type: 'UPDATE_LOAD_BALANCER_PROCESSING', processing}
}

function updateLoadBalancer(id) {
    return (dispatch, getState) => {

        let {loadBalancerForm} = getState()
        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))

        axios({
            url: apiBaseUrl(`admin/loadbalancers/${id}`),
            method: 'put',
            dataType: 'json',
            data: loadBalancerForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                store.dispatch(cancelLoadBalancerFormDispatch())

            }).catch(function (error) {
            dispatch(_error(error.response.data.message))
            dispatch(_processing(false))
            handleError(error)

            store.dispatch(cancelLoadBalancerFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(loadBalancerFormServerValidationErrors(error.response.data.errors))
                scrollToError()
            }
        })
    }
}

export default updateLoadBalancer
