import axios from 'axios';
import {apiBaseUrl, endOfURL} from "../../../helpers/UrlHelper";
import {loginToken, logOut} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {setPlanMode} from "../../../pages/site/plan/list/PlanList-Action";

function _success(success) {
    return {type: 'GET_SITE_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_SITE_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_SITE_PROCESSING', processing};
}

function siteDetails(id, organization_id = null) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_error(null));

        let { organizationDashboard} = getState();
        let org_id = organization_id || organizationDashboard.data.organization_id;

        const request = axios({

            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${id}`),
            method: "get",
            dataType: 'json',
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                // let role = res.data.data.roles[0].name;
                // if(role === 'customer') {
                //   logOut()
                // }else{
                dispatch(_success(res.data.data));
                dispatch(_processing(false));
                if(endOfURL() === "plan") {
                    store.dispatch(setPlanMode(" "));
                }
                // }
            }).catch(function (error) {

                if (error && error.response &&
                    error.response.status === 401) {
                    logOut()
                } else {
                    handleError("error")
                    dispatch(_error(error));
                    dispatch(_processing(false));
                }
            });

        return {
            type: 'GET_SITE_SUCCESS',
            payload: request
        }
    }
}


export default siteDetails;