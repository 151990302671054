import store from '../../../Store';
import { toggleURLQuery } from '../../../helpers/UrlHelper'
import getBackupStatus from "../../../ajax/actions/backup-infrastructure/backup_status_get";

export function toggleBackupStatusListFilter(e, history)
{
  return function (dispatch) {
    let { name, value } = e.target;
    let toggle = true;
    if ( toggle ) toggleURLQuery( name, value , history)
    dispatch({type:'TOGGLE-BACKUP-STATUS-LIST-FILTER', name, value});
    store.dispatch(getBackupStatus());
  }
}

export function onDateFilter(name, value, history)
{
  return function (dispatch) {
    let toggle = true;
    if ( toggle ) toggleURLQuery( name, value , history)
    dispatch({type:'TOGGLE-BACKUP-STATUS-LIST-FILTER', name, value});
    store.dispatch(getBackupStatus());
  }
}

export function backupDateStoreFromUrlParam(date){
  return function (dispatch){
    dispatch({type:'PATH-FROM-URL', date});
  }

}