import axios from 'axios';
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import { handleError } from '../../../helpers/ErrorHelper'

function _success(success)
{
  return { type: 'GETS_CURRENCY_SUCCESS', success };
}
function _error(error)
{
  return { type: 'GETS_CURRENCY_ERROR', error };
}
function _processing(processing)
{
  return { type: 'GETS_CURRENCY_PROCESSING', processing };
}

function getCurrency()
{
  return ( dispatch ) =>
  {
    dispatch(_processing(true));
    dispatch(_success(null));
    dispatch(_error(null));

    const request = axios({

      url: apiBaseUrl(`admin/misc/currencies`),
      method: "get",
      dataType: 'json',
      headers: { 'Authorization': 'Bearer ' +loginToken() }
    })
        .then(function (res) {
          dispatch(_success(res.data.data));
          dispatch(_processing(false));
        }).catch(function (error) {
          handleError(error);
          dispatch(_error(error));
          dispatch(_processing(false));
        });
  }
}


export default getCurrency;