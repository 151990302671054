import React from 'react';
import store from '../../../Store';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import getExpiredBackup from "../../../ajax/actions/backup-infrastructure/expired_backup_get";

class ExpiredBackupWorker extends React.Component {

  constructor(props) {
    super(props);
    store.dispatch(getExpiredBackup())
  }

  render() {
    return null
  }
}
  function mapStateToProps(state) {
  let { getExpiredBackup } = state;
  return { getExpiredBackup };
}

export default withRouter(connect(mapStateToProps)(ExpiredBackupWorker));