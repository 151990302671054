import React from "react";
import TextField from "../../../components/shared/form/TextField";
import PasswordField from "../../../components/shared/form/PasswordField";
import store from "../../../Store";
import { connect } from "react-redux";
import {
  onUserFormSubmit,
  onUserFormChange,
  onUserRoleFormChange,
  selectCountry,
  onUserOrganizationAssign,
  selectUserPhoneCode,
} from "./UserForm-Actions";
import UserFormWorker from "./UserFormWorker";
import Select from "react-select";
import { endOfURL } from "../../../helpers/UrlHelper";
import Page from "../../../components/shared/pages/Page";
import Loading from "../../../components/shared/loaders/Loading";
import { setDashboardMenu } from "../../../routes/main-route/MainRoute-Actions";
import { Link } from "react-router-dom";
import { ucFirst, unslufigy } from "../../../helpers/StringHelper";
import { countryListAllIsoData } from "../../../static-data/CountryList";
import getOrganizatonsWithoutPagination from "../../../ajax/actions/organization/organizations_without_pagination_get";
import CustomReactSelect from "../../../components/shared/form/CustomReactSelectWithErrorHandle";

class UserForm extends React.Component {
  componentDidMount() {
    document.querySelector("body").classList.add("organization-dashboard");
    store.dispatch(setDashboardMenu(""));
  }

  componentWillUnmount() {
    document.querySelector("body").classList.remove("organization-dashboard");
  }

  onChange = (e) => {
    store.dispatch(onUserFormChange(e));
  };

  handleRole = (selectedOption) => {
    let value = "";
    if (selectedOption) {
      value = selectedOption.slug;

      if (selectedOption.slug === "organization-admin") {
        // store.dispatch(getOrganizations());
        store.dispatch(getOrganizatonsWithoutPagination());
      }
      if (value) {
        store.dispatch(onUserRoleFormChange(value));
      }
    }
    store.dispatch(onUserRoleFormChange(value));
  };

  handleCountry = (selectedOption) => {
    let value = "";
    if (selectedOption) {
      value = selectedOption.value;
    }
    store.dispatch(selectCountry(value));
  };

  handleCountryCode = (selectedOption) => {
    let value = "";
    if (selectedOption) {
      value = selectedOption.value;
    }
    store.dispatch(selectUserPhoneCode(value));
  };

  handleOrganizationChange = (selectedOption) => {
    let value = [];
    if (selectedOption) {
      selectedOption.map((s) => {
        let data = { id: s.value, name: s.label };
        if (s.value) {
          value.push(data);
        }
      });
    }
    store.dispatch(onUserOrganizationAssign(value));
  };

  render() {
    let {
      first_name,
      middle_name,
      last_name,
      email,
      password,
      createUser,
      country_code,
      countries,
      contact_number,
      address,
      extra_address_line,
      login_user_role,
      post_code,
      city,
      country,
      errors,
      allRoles,
      role,
      allOrganizations,
      allowed_organizations,
      submitted,
      userForm,
      mode,
      updateUser,
    } = this.props;

    let clearable = true;
    let roleList = [];
    allRoles &&
      allRoles.map((role) => {
        roleList.push({
          value: role.id,
          label: ucFirst(role.name),
          slug: role.slug,
        });
      });

    let countryList = [];
    countryListAllIsoData.map((c) => {
      countryList.push({ value: c.name, label: c.name });
    });

    let orgList = [];
    allOrganizations &&
      allOrganizations.map((c) => {
        orgList.push({ value: c.id, label: c.name });
      });

    let allowed_org_list = [];
    allowed_organizations &&
      allowed_organizations.map((c) => {
        allowed_org_list.push({ value: c.id, label: c.name });
      });
    let countryCodeList = [];
    countries &&
      countries.map((c) => {
        countryCodeList.push({
          value: "+" + c.phone_code,
          label: c.name + " " + "(+" + c.phone_code + ")",
          id: c.id,
        });
      });

    let { processing } = createUser;
    let updateUserProcessing = updateUser.processing;

    return (
      <>
        <Page
          title={
            endOfURL() === "create"
              ? "Add User"
              : endOfURL() === "update"
              ? "Update User"
              : endOfURL() === "myaccount"
              ? "My Account"
              : null
          }
          processing={processing}
        >
          <div className="heading-block pr-60">
            <div className="title-block">
              <h2>
                {endOfURL() === "create"
                  ? `Add new User`
                  : endOfURL() === "update"
                  ? `Update User`
                  : endOfURL() === "myaccount"
                  ? "My Account"
                  : null}{" "}
              </h2>
              {endOfURL() !== `myaccount` ? (
                <span>
                  <Link to="/allusers">Users</Link>
                </span>
              ) : null}
              {endOfURL() === "update" ? (
                <span>
                  {ucFirst(first_name)} {ucFirst(middle_name)}{" "}
                  {ucFirst(last_name)}
                </span>
              ) : null}
              <span>
                {endOfURL() === "create"
                  ? `Add new User`
                  : endOfURL() === "update"
                  ? `Update`
                  : null}
              </span>
            </div>
          </div>

          <UserFormWorker />
          <div className="box-block">
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="pills-add-user"
                role="tabpanel"
                aria-labelledby="pills-add-user-tab"
              >
                <div className="padded-block">
                  <form onSubmit={(e) => store.dispatch(onUserFormSubmit(e))}>
                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <TextField
                            required
                            value={first_name}
                            name="first_name"
                            icon="user"
                            onChange={this.onChange}
                            errors={errors && errors.first_name}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <TextField
                            required
                            value={last_name}
                            name="last_name"
                            icon="user"
                            onChange={this.onChange}
                            errors={errors && errors.last_name}
                          />
                        </div>
                      </div>
                    </div>

                    <hr />

                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Country</label>
                          <CustomReactSelect
                            name="country"
                            isClearable={clearable}
                            onChange={this.handleCountry}
                            value={
                              countryList.filter(
                                (option) => option.value === country
                              ) || countryList.value
                            }
                            placeholder="Select Country..."
                            options={countryList}
                            isValid={errors && errors.country}
                            submitted={submitted}
                            Searchable
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Country Code</label>
                          <CustomReactSelect
                            name="country_code"
                            isClearable={clearable}
                            value={
                              countryCodeList.filter(
                                (option) => option.value === country_code
                              ) || countryCodeList.value
                            }
                            placeholder="Select Country Code..."
                            options={countryCodeList}
                            onChange={this.handleCountryCode}
                            isValid={errors && errors.country_code}
                            submitted={submitted}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <TextField
                            required
                            value={city}
                            name="city"
                            icon="user"
                            onChange={this.onChange}
                            errors={errors && errors.city}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <TextField
                            required
                            value={contact_number}
                            name="contact_number"
                            onChange={this.onChange}
                            errors={errors && errors.contact_number}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <TextField
                            required
                            value={email}
                            name="email"
                            type="email"
                            icon="mail"
                            autoComplete="nope"
                            errors={errors && errors.email}
                            onChange={this.onChange}
                          />
                        </div>
                      </div>

                      {mode === "myaccount" && (
                        <div className="col-md-6">
                          <div className="form-group">
                            <TextField
                              required
                              value={ucFirst(unslufigy(role))}
                              name="role"
                              disabled
                            />
                          </div>
                        </div>
                      )}

                      {login_user_role === "super-admin" &&
                      mode !== "myaccount" ? (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="mt-1">Select Role</label>
                            <CustomReactSelect
                              name="role"
                              placeholder={"Select Role..."}
                              value={
                                roleList.filter(
                                  (option) => option.slug === role
                                ) || roleList.value
                              }
                              isClearable={clearable}
                              onChange={this.handleRole}
                              options={roleList}
                              label="Role"
                              autoComplete="nope"
                              isValid={errors && errors.role}
                              submitted={submitted}
                              Searchable
                            />
                          </div>
                        </div>
                      ) : null}

                      {login_user_role === "super-admin" &&
                      role !== "super-admin" &&
                      mode !== "myaccount" ? (
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Select Organization</label>
                            <Select
                              name="allowed_organizations"
                              value={allowed_org_list}
                              isMulti
                              isClearable={clearable}
                              onChange={this.handleOrganizationChange}
                              options={orgList}
                              // errors={errors && errors.role}
                              Searchable
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>

                    <div className="form-row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <PasswordField
                            required
                            value={password}
                            name="password"
                            icon="lock"
                            defaultValue={null}
                            autoComplete="new-password"
                            onChange={this.onChange}
                            errors={errors && errors.password}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <PasswordField
                            required
                            name="password_confirmation"
                            icon="lock"
                            defaultValue={null}
                            onChange={this.onChange}
                            autoComplete="new-password"
                            errors={errors && errors.password}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="button-wrapper text-right mt-20 d-flex justify-content-end">
                      <button type="submit" className="btn-theme btn-dark-blue">
                        {updateUserProcessing || processing ? (
                          <>
                            Submit
                            <Loading />
                          </>
                        ) : (
                          `Submit`
                        )}
                      </button>
                    </div>
                  </form>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-edit-user"
                  role="tabpanel"
                  aria-labelledby="pills-edit-user-tab"
                ></div>
              </div>
            </div>
          </div>
        </Page>
      </>
    );
  }
}

function mapStateToProps(state) {
  let {
    userForm,
    getUser,
    createUser,
    updateUser,
    getRoles,
    getOrganizations,
    getLoggedInUser,
    getOrganizationsWithoutPagination,
    getCountries,
  } = state;

  let { payload, mode, submitted } = userForm.data;

  let login_user_role = getLoggedInUser?.success?.data?.data?.role?.slug;

  let countries =
    getCountries && getCountries.success && getCountries.success.data.data;

  let { success } = getRoles;

  let allRoles =
    success && success.data && success.data.data && success.data.data.data;
  console.log(getOrganizationsWithoutPagination);
  let allOrganizations =
    getOrganizationsWithoutPagination &&
    getOrganizationsWithoutPagination.success &&
    getOrganizationsWithoutPagination.success.data &&
    getOrganizationsWithoutPagination.success.data.data;

  let { errors, errorsPresent } = userForm.data;

  let {
    first_name,
    middle_name,
    last_name,
    email,
    password,
    address,
    extra_address_line,
    post_code,
    city,
    country,
    role,
    allowed_organizations,
    country_code,
    contact_number,
  } = payload;

  return {
    errors,
    errorsPresent,
    allRoles,
    first_name,
    countries,
    submitted,
    getLoggedInUser,
    middle_name,
    last_name,
    email,
    password,
    createUser,
    updateUser,
    getUser,
    mode,
    contact_number,
    userForm,
    address,
    extra_address_line,
    post_code,
    city,
    country,
    role,
    getRoles,
    country_code,
    getOrganizations,
    allOrganizations,
    allowed_organizations,
    login_user_role,
    getOrganizatonsWithoutPagination,
  };
}

export default connect(mapStateToProps)(UserForm);
