import React from 'react';
import store from '../../../Store';
import withRouter from "react-router/withRouter";
import {connect} from "react-redux";
import getActivityEvents from "../../../ajax/actions/activity-log/activity_events_name_get";
import {onActivityLogSiteReset,onActivityLogEventReset} from "./GlobalActivityList-Actions";
import getGlobalActivityLogs from "../../../ajax/actions/global-activity-log/global_activity_logs_get";

class ActivityWorker extends React.Component {
    componentDidMount() {
        store.dispatch(getGlobalActivityLogs());
        store.dispatch(getActivityEvents())
        // store.dispatch(onActivityLogSiteReset());
        // store.dispatch(onActivityLogEventReset());
    }

    // componentDidUpdate(prevProps) {
    //     if (this.props.getOrganization !== prevProps.getOrganization) {
    //         let {success} = this.props.getOrganization;
    //         if (success) {
    //             store.dispatch(setOrganizationDashboardData());
    //         }
    //     }
    // }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getGlobalActivityLogs, getActivityEvents} = state;

    return {getGlobalActivityLogs, getActivityEvents};
}

export default withRouter(connect(mapStateToProps)(ActivityWorker));