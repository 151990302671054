import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {setSSLFilteredData} from "../../../pages/site/ssl/list/SSL-Action";

function _success(success)
{
  return { type: 'GET_SITE_DOMAIN_NO_PAGINATION_SUCCESS', success };
}
function _error(error)
{
  return { type: 'GET_SITE_DOMAIN_NO_PAGINATION_ERROR', error };
}
function _processing(processing)
{
  return { type: 'GET_SITE_DOMAIN_NO_PAGINATION_PROCESSING', processing };
}

function getSiteDomainAll( id)
{
  return ( dispatch, getState  ) =>
  {
    dispatch(_processing(true));
    dispatch(_error(null));
      let { siteDashboard} = getState();
      let org_id = siteDashboard.data.org_id

   axios({
      url: apiBaseUrl(`admin/organizations/${org_id}/sites/${id}/domains`),
      method: "get",
      dataType: 'json',
      headers: { 'Authorization': 'Bearer ' +loginToken() }
    })
      .then(function(res){
          dispatch(_success(res));
          dispatch(_processing( false ));
          store.dispatch(setSSLFilteredData());


      }).catch(function( error ){
       handleError(error);
          dispatch(_error(error));
          dispatch(_processing(false));
      });
  }
}
export default getSiteDomainAll;