import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import getSiteStatus from "../sites/site_status_get";
import getsCacheIgnore from "./cache_ignore_gets";

function _success(success) {
    return {type: 'CACHE_IGNORE_DELETE_SUCCESS', success}
}

function _error(error) {
    return {type: 'CACHE_IGNORE_DELETE_ERROR', error}
}

function _processing(processing) {
    return {type: 'CACHE_IGNORE_DELETE_PROCESSING', processing}
}

function ignoreCacheDelete(site_id,id) {
    return ( dispatch, getState ) => {
        dispatch(_processing(true));
        dispatch(_error(null));
        let {organizationDashboard} = getState();
        let org_id = organizationDashboard.data.organization_id;

        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/tools/cache/ignore/${id}`),
            method: "delete",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        }).then(function (res) {
            dispatch(_processing(false));
            dispatch(_success(res));
            handleSuccess( res )
            store.dispatch(getSiteStatus(site_id));
            store.dispatch(getsCacheIgnore(site_id,org_id))

        }).catch(function (error) {
            handleError(error);
            dispatch(_error(error));
            dispatch(_processing(false));
        });
    }
}

export default ignoreCacheDelete;