import {endOfURL} from "../../../../helpers/UrlHelper";

const DefaultDomainFormState = {
  payload:{
    name:"",
    has_www:0,
  },
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ``
};

export const DomainFormState = { data: DefaultDomainFormState };