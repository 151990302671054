import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import Loading from "../../../components/shared/loaders/Loading";
import {toggleBugFormModal} from "../list/Bug-Actions";
import {
    bugStatusType,
    priorityTypeFilter,
    urgencyTypeFilter
} from "../../../static-data/UserData"
import Select from "react-select";
import {
    onAddImage,
    onAddTag,
    onBugFormChange,
    onBugFormSubmit,
    onDeleteAddedImage,
    onRemoveBugTag, setDefaultBugForm
} from "./BugForm-Action";
import BugFormWorker from "./BugFormWorker";
import {ucFirst} from "../../../helpers/StringHelper";
import TextField from "../../../components/shared/form/TextField";
import Page from "../../../components/shared/pages/Page";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import {endOfURL} from "../../../helpers/UrlHelper";
import withRouter from "react-router/withRouter";
import CustomReactSelect from "../../../components/shared/form/CustomReactSelectWithOnlyButtomBorder";
import {FileUploader} from "react-drag-drop-files";
import SelectField from "../../../components/shared/form/SelectField";
import Inactive from "../../../assets/images/inactive.svg";
import deleteBugImage from "../../../ajax/actions/support/bug_image_delete";
import Link from "react-router-dom/Link";
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

class BugForm extends React.Component {

    state = {
        data: [],
        items: [],
        value: "",
        error: null,
        files: [],
        image: '',
        imageError: null,
    };

    handleImageChange = (file) => {
        this.setState({
            image: file,
            imageError: null,
        });
        store.dispatch(onAddImage(file))
    };

    onTypeError = (err) => this.setState({imageError: err});

    componentDidMount() {
        if (this.props.match.params.id || endOfURL() === "create") {
            document.querySelector('body').classList.add('organization-dashboard');
            store.dispatch(setDashboardMenu(''));
        }
    }

    componentWillUnmount() {
        if (this.props.match.params.id || endOfURL() === "create") {
            document.querySelector('body').classList.remove('organization-dashboard');
        }
    }

    handleKeyDown = evt => {
        if (["Enter", "Tab", ","].includes(evt.key)) {
            evt.preventDefault();

            var value = this.state.value.trim();

            if (value && this.isValid(value)) {
                this.setState({
                    items: [...this.state.items, this.state.value],
                    value: ""
                });
                store.dispatch(onAddTag([ this.state.value]))
            }
        }
    };

    handleDelete = item => {
        if (item) {
            store.dispatch(onRemoveBugTag(item))
        }
        let data = this.state.items.filter(i => i !== item)
        this.setState({
            items: data
        });
    };

    handleChange = (e) => {
        // store.dispatch(onRemoveErrorMessage());
        this.setState({
            value: e.target.value,
            error: null,
        });
    };

    isValid(tag) {
        let error = null;

        if (this.isInList(tag)) {
            error = `${tag} has already been added.`;
        }

        if (error) {
            this.setState({error});

            return false;
        }

        return true;
    }

    isInList(tags) {
        return this.state.items.includes(tags);
    }

    onChangeStatus = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.value;
        }
        store.dispatch(onBugFormChange("status", value));
    }

    bugFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onBugFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onBugFormChange(e.target.name, e.target.value));
    };

    onChangeDescription = (value) => {
        store.dispatch(onBugFormChange("description", value));
    };

    handleUserChange = (selectedOption) => {
        if (selectedOption) {
            store.dispatch(onBugFormChange("reporter_id", selectedOption.value));
        } else {
            store.dispatch(onBugFormChange("reporter_id", ""));
        }
    }

    handleOrganizationChange = (selectedOption) => {
        if (selectedOption) {
            store.dispatch(onBugFormChange("organization_id", selectedOption.value));
        } else {
            store.dispatch(onBugFormChange("organization_id", ""));
        }
    }

    handleSiteChange = (selectedOption) => {
        if (selectedOption) {
            store.dispatch(onBugFormChange("site_id", selectedOption.value));
        } else {
            store.dispatch(onBugFormChange("site_id", ""));
        }
    }

    onDeleteBugImage = (id) => {
        store.dispatch(deleteBugImage(this.props.match.params.id, id))
    }

    onDeleteAddedImage = (data) => {
        store.dispatch(onDeleteAddedImage(data))
    }

    onClickViewImage = (data) => {
        var image = new Image();
        image.src = URL.createObjectURL(data)
        var w = window.open("");
        w.document.write(image.outerHTML);
    }

    render() {
        let {
            getBug, updateBugStatus, payload, mode, bugForm, organizations, sites, submitted, id, first_name, allUsers
        } = this.props;

        let {errors} = bugForm.data
        let {processing} = getBug
        let updateBugStatusProcessing = updateBugStatus && updateBugStatus.processing

        let clearable = true;
        let userList = [];

        allUsers && allUsers.map(user => {
            userList.push({value: user.id, label: (user.first_name + " " + user.last_name)})
        });

        let organizationList = [];
        organizations && organizations.map(organization => {
            organizationList.push({value: organization.id, label: organization.name})
        });

        let siteList = [];
        sites && sites.map(site => {
            siteList.push({value: site.id, label: site.name})
        });
        const fileTypes = ["JPG", "PNG", "JPEG"];

        let {tags, imageLinks, images} = payload
        let serverError = ( errors && errors.tags && errors.tags.length)

        return (
            <>
                {mode === "updateStatus" ?
                    <>
                        {processing ?
                            <h2 className="text-center mb-30">Update Status<Loading/></h2> :
                            <>
                                <h2 className="text-center mb-30">Update Status</h2>
                                <form className="mt-30 domain-form" onSubmit={this.bugFormSubmit}>

                                    <p style={{color: 'red'}}> {errors && errors.status && errors.status.length ? "This is required Field" : null}</p>

                                    <label>Status</label>
                                    <Select
                                        name="status"
                                        value={bugStatusType.value}
                                        placeholder={payload.status ? payload.status === "pending" ? "Pending" : ucFirst(payload.status) : "Select Status..."}
                                        options={bugStatusType}
                                        onChange={this.onChangeStatus}
                                        // errors={errors && errors.status}
                                    />

                                    <div
                                        className="button-wrapper inline-buttons text-center d-flex justify-content-center mt-3">
                                        <button className="btn-theme btn-outline" type="reset"
                                                onClick={() => store.dispatch(toggleBugFormModal())}>Cancel
                                        </button>
                                        <div className="tab-domain-button">
                                            <button type="submit" className="btn-theme btn-dark-blue">
                                                {updateBugStatusProcessing ? <Loading/> : `Update Status`}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <BugFormWorker/>
                            </>
                        }
                    </> :
                    (endOfURL() === "update" || endOfURL() === "create") &&
                    <>
                        <BugFormWorker/>
                        <Page title={endOfURL() === 'create' ? "New Bug" : "Update Bug"} processing={processing}>
                            <>
                                <div className="heading-block pr-60">
                                    <div className="title-block">
                                        <h2>{endOfURL() === 'create' ? "New Bug" : "Update Bug"}</h2>
                                        <span><Link to="/bugs">Bugs</Link></span>
                                        <span>{endOfURL() === 'create' ? `Add new Bug` :
                                            endOfURL() === 'update' ? `Update` : `View Bug`}</span>
                                    </div>
                                </div>
                                <div className="box-block-alt pl-4 pr-3">
                                    <form className="bug-form" onSubmit={this.bugFormSubmit}>

                                        <p style={{color: 'red'}}> {errors && errors.status && errors.status.length ? "This is required Field" : null}</p>

                                        <div className="row">
                                            <div className="col-xl-7 col-md-7">

                                                <div className="form-group">
                                                    <TextField
                                                        name="summary"
                                                        label="Summary"
                                                        value={payload.summary}
                                                        errors={errors.summary}
                                                        onChange={this.onChange}
                                                    />
                                                </div>

                                                {/* {endOfURL() === "update" ?
                                                            <div className="form-group">
                                                                <label>Description</label>
                                                                <div
                                                                    dangerouslySetInnerHTML={{__html: payload.description}}/>
                                                            </div>:*/}
                                                <div className="form-group">
                                                    <label>Description</label>
                                                    <div className={errors && errors.description && errors.description.length ? "description-error" : ""}>
                                                        <ReactQuill
                                                            theme='snow'
                                                            value={payload.description || ""}
                                                            onChange={this.onChangeDescription}
                                                            style={{minHeight: '100px'}}
                                                        />
                                                    </div>
                                                    {/*<p className="error"> { errors && errors.description && errors.description.length ? "This is required field." : null}</p>*/}


                                                    {/*<TextField
                                                                name="description"
                                                                label="Description"
                                                                value={ucFirst(payload.description)}
                                                                errors={errors.description}
                                                                onChange={this.onChange}
                                                                />*/}
                                                </div>
                                                {/*}*/}

                                                <div className="form-group">
                                                    <TextField
                                                        name="other_links"
                                                        label="Other Links"
                                                        value={payload.other_links}
                                                        errors={errors.other_links}
                                                        onChange={this.onChange}
                                                    />
                                                </div>


                                                <label>Tags</label>
                                                <div className="form-group">
                                                    {tags && tags.map(item => (

                                                        <div className="tag-item" key={item}>
                                                            {item}
                                                            <button
                                                                type="button"
                                                                className="button"
                                                                onClick={() => this.handleDelete(item)}
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>
                                                    ))}

                                                    <input
                                                        className={"input " + (this.state.error && " has-error" || serverError && " has-error")}
                                                        value={this.state.value}
                                                        placeholder="Enter Tags; separate them with COMMA or ENTER or TAB."
                                                        onKeyDown={this.handleKeyDown}
                                                        onChange={(e) => this.handleChange(e)}
                                                    />
                                                    {this.state.error &&
                                                    <p className="error">{this.state.error}</p>}
                                                    {!this.state.error &&
                                                    <>
                                                        <p className="error"> { errors && errors.tags && errors.tags.length ? "This is required field." : null}</p>
                                                    </>
                                                    }
                                                </div>

                                                <div className="form-group mt-5">
                                                    <div
                                                        className={this.state.imageError ? "error drag-drop" : "drag-drop"}>
                                                        <FileUploader handleChange={this.handleImageChange}
                                                                      name="images"
                                                                      types={fileTypes}
                                                                      children={
                                                                          <div className="drag-drop-icon">
                                                                              <i className="fa fa-upload"
                                                                                 aria-hidden="true">Drop files to
                                                                                  attach, or browse.</i>
                                                                          </div>
                                                                      }
                                                                      onTypeError={this.onTypeError}
                                                        />
                                                        <p>{this.state.imageError ? this.state.imageError : ""}</p>
                                                        {/*<p>{this.state.file ? `File name: ${file.name}` : "no files uploaded yet"}</p>*/}
                                                    </div>
                                                </div>
                                                {images && images.map((i) => (
                                                    <div className="bug-image" key={i}>
                                                        <img alt="status" src={Inactive}
                                                             onClick={() => this.onDeleteAddedImage(i)}/> {i.name} &nbsp;&nbsp;
                                                        <a className="onclick" onClick={() => this.onClickViewImage(i)}
                                                           target="_blank"><u>Open in new tab</u></a>
                                                    </div>
                                                ))
                                                }
                                                {imageLinks && imageLinks.map((i, index) => (
                                                    <div className="bug-image" key={i}>
                                                        <img alt="status" src={Inactive}
                                                             onClick={() => this.onDeleteBugImage(i.id)}/> Uploaded_Image_{index} &nbsp;&nbsp;
                                                        <a href={i.path} target="_blank"><u>Open in new tab</u></a>
                                                    </div>
                                                ))
                                                }
                                            </div>

                                            <div className="col-xl-4 offset-xl-1 col-md-5">
                                                {endOfURL() === "update" &&
                                                <>
                                                    <div className="form-group">
                                                        <label>Status</label>
                                                        <Select
                                                            name="status"
                                                            value={bugStatusType.value}
                                                            placeholder={payload.status ? payload.status === "pending" ? "Pending" : ucFirst(payload.status) : "Select Status..."}
                                                            options={bugStatusType}
                                                            onChange={this.onChangeStatus}
                                                            errors={errors && errors.status}
                                                        />
                                                    </div>
                                                </>
                                                }
                                                {/* <div className="form-group">
                                                            <a className="bugs-status blue-theme">{ucFirst(payload.status) || "-"}</a>
                                                        </div>*/}
                                                <div className="form-group">
                                                    <label>Reporter</label>
                                                    <CustomReactSelect
                                                        name="reporter_id"
                                                        isClearable={clearable}
                                                        value={userList.filter(option => option.value === (payload.reporter_id)) || userList.value}
                                                        placeholder="Select Reporter"
                                                        options={userList}
                                                        onChange={this.handleUserChange}
                                                        isValid={errors && errors.reporter_id}
                                                        submitted={submitted}
                                                    />
                                                </div>


                                                <div className="form-group">
                                                    <SelectField name="urgency"
                                                                 value={payload.urgency}
                                                                 options={urgencyTypeFilter}
                                                                 errors={errors.urgency}
                                                                 defaultValue={null}
                                                                 onChange={this.onChange}
                                                                 _value="value"
                                                                 _label="name"/>
                                                </div>

                                                <div className="form-group">
                                                    <SelectField name="priority"
                                                                 value={payload.priority}
                                                                 options={priorityTypeFilter}
                                                                 errors={errors.priority}
                                                                 defaultValue={null}
                                                                 onChange={this.onChange}
                                                                 _value="value"
                                                                 _label="name"/>
                                                </div>

                                                <div className="form-group">
                                                    <TextField
                                                        name="assigned_to"
                                                        label="Assigned To"
                                                        value={payload.assigned_to}
                                                        errors={errors.assigned_to}
                                                        onChange={this.onChange}
                                                    />
                                                </div>

                                                <div className="form-group">
                                                    <label>Organization</label>
                                                    <CustomReactSelect
                                                        name="organization_id"
                                                        isClearable={clearable}
                                                        value={organizationList.filter(option => option.value === (payload.organization_id)) || organizationList.value}
                                                        placeholder="Select Organization"
                                                        options={organizationList}
                                                        onChange={this.handleOrganizationChange}
                                                        isValid={errors && errors.organization_id}
                                                        submitted={submitted}
                                                    />
                                                </div>

                                                {payload.organization_id &&
                                                <div className="form-group">
                                                    <label>Site</label>
                                                    <Select
                                                        name="site_id"
                                                        isClearable={clearable}
                                                        placeholder="Select Site"
                                                        value={siteList.filter(option => option.value === (payload.site_id)) || siteList.value}
                                                        options={siteList}
                                                        onChange={this.handleSiteChange}
                                                        errors={errors && errors.site_id}
                                                    />
                                                </div>
                                                }

                                                <div className="form-group">
                                                    <TextField
                                                        name="remarks"
                                                        label="Remarks"
                                                        value={payload.remarks}
                                                        errors={errors.remarks}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="button-wrapper text-left pt-2">
                                            <button type="submit"
                                                    className="btn-theme btn-dark-blue">
                                                {
                                                    processing ? <>Submit <Loading/></> : `Submit`
                                                }

                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </>
                            {/*}*/}

                        </Page>
                    </>
                }

            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        getBug,
        bugForm,
        updateBugStatus,
        createBug,
        updateBug,
        getOrganizationsWithoutPagination,
        getSitesWithoutPagination,
        getLoggedInUser,
        getAllUsersWithoutPagination
    } = state;

    let {id, first_name} =
        getLoggedInUser?.success?.data?.data

    let {payload, mode, view, submitted} = bugForm.data

    let allUsers = getAllUsersWithoutPagination
        && getAllUsersWithoutPagination.success
        && getAllUsersWithoutPagination.success.data
        && getAllUsersWithoutPagination.success.data.data

    let organizations = getOrganizationsWithoutPagination
        && getOrganizationsWithoutPagination.success
        && getOrganizationsWithoutPagination.success.data
        && getOrganizationsWithoutPagination.success.data.data

    let sites = getSitesWithoutPagination
        && getSitesWithoutPagination.success
        && getSitesWithoutPagination.success.data
        && getSitesWithoutPagination.success.data.data

    let bugId = getBug && getBug.success && getBug.success.internal_bug && getBug.success.internal_bug.id

    return {
        getBug,
        bugId,
        payload,
        updateBugStatus,
        mode,
        view,
        createBug,
        updateBug,
        bugForm,
        organizations,
        sites,
        submitted,
        id, first_name,
        allUsers
    };
}

export default withRouter(connect(mapStateToProps)(BugForm));