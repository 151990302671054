import axios from 'axios';
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import { handleError } from '../../../helpers/ErrorHelper'

function _success(success)
{
  return { type: 'GETS_COUPON_SUCCESS', success };
}
function _error(error)
{
  return { type: 'GETS_COUPON_ERROR', error };
}
function _processing(processing)
{
  return { type: 'GETS_COUPON_PROCESSING', processing };
}

function getCoupons()
{
  return ( dispatch, getState ) =>
  {
    dispatch(_processing(true));
    dispatch(_success(null));
    dispatch(_error(null));
    let {params} = getState().couponList.data;

    const request = axios({

      url: apiBaseUrl(`admin/coupons`),
      method: "get",
      dataType: 'json',
      params,
      headers: { 'Authorization': 'Bearer ' +loginToken() }
    })
        .then(function (res) {
          dispatch(_success(res.data.data));
          dispatch(_processing(false));
        }).catch(function (error) {
          handleError(error);
          dispatch(_error(error));
          dispatch(_processing(false));
        });
  }
}


export default getCoupons;