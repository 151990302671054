import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import {scrollToError} from '../../../helpers/ScrollHelper'
import store from '../../../Store';
import {
    cancelOrganizationFormDispatch,
    organizationFormServerValidationErrors,
    setDefaultOrganizationForm
} from "../../../pages/organization/form/OrganizationForm-Action";

function _success(success) {
    return {type: 'CREATE_ORGANIZATION_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_ORGANIZATION_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_ORGANIZATION_PROCESSING', processing}
}

function createOrganization() {
    return (dispatch, getState) => {

        let {organizationForm} = getState();

        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))

        axios({
            url: apiBaseUrl(`admin/organizations`),
            method: 'post',
            dataType: 'json',
            data: organizationForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess("Organization created successfully")
                store.dispatch(cancelOrganizationFormDispatch())
                store.dispatch(setDefaultOrganizationForm())

            }).catch(function (error) {
            dispatch(_error(error.response.data.message))
            dispatch(_processing(false))
            handleError(error)
            store.dispatch(cancelOrganizationFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(organizationFormServerValidationErrors(error.response.data.errors))
                scrollToError()
            }
        })
    }
}

export default createOrganization;
