import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import TextField from "../../../components/shared/form/TextField";
import Loading from "../../../components/shared/loaders/Loading";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import {ucFirst} from "../../../helpers/StringHelper";
import Link from "react-router-dom/Link";
import {endOfURL} from "../../../helpers/UrlHelper";
import Page from "../../../components/shared/pages/Page";
import {onMigrationTrackingFormChange, onMigrationTrackingFormSubmit} from "./MigrationTrackingForm-Action";
import MigrationTrackingFormWorker from "./MigrationTrackingFormWorker";
import Select from "react-select";
import {migrationTrackingStatusType, yesNoStatusMigration} from "../../../static-data/UserData";
import {onBugFormChange} from "../../support/form/BugForm-Action";

class MigrationTrackingForm extends React.Component {
    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    migrationTrackingFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onMigrationTrackingFormSubmit());
    };

    onChange = (e) => {
        if (e.target.name === "server_id") {
            e.preventDefault();
        }
        store.dispatch(onMigrationTrackingFormChange(e.target.name, e.target.value,));
    };

    onChangeStatus = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.value;
        }
        store.dispatch(onMigrationTrackingFormChange("status", value));
    }

    onChangeClientVerifyStatus = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.value;
        }
        store.dispatch(onMigrationTrackingFormChange("client_verified", value));
    }

    onChangeBackupDeleteStatus = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.value;
        }
        store.dispatch(onMigrationTrackingFormChange("backup_deleted", value));
    }

    onChangeOldContainerDeleteStatus = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.value;
        }
        store.dispatch(onMigrationTrackingFormChange("old_container_deleted", value));
    }

    render() {
        let {
            site_name, client_verified,
            backup_deleted,
            old_container_deleted,
            status,
            source_server_ip,
            source_lb_ip,
            destination_server_ip,
            destination_lb_ip, updateMigrationTracking, migrationTrackingForm
        } = this.props;
        let {errors, mode} = migrationTrackingForm.data;
        let processing = updateMigrationTracking.processing;

        return (

            <>
                <Page title={endOfURL() === "create" ?
                    "Add MigrationTracking" :
                    "Update MigrationTracking"
                } processing={processing}>
                    <div className="heading-block pr-60">
                        <div className="title-block">
                            <h2> {endOfURL() === 'create' ? 'Add new Migration' : 'Update Internal Migration'}</h2>
                            <span><Link to="/internal-migration-tracking">Internal Migration Tracking</Link></span>
                            {endOfURL() === 'update' ? <span>{ucFirst(site_name)}</span> : null}
                            <span>{endOfURL() === 'create' ? `Add new Migration` : `Update`}</span>
                        </div>
                    </div>
                    <MigrationTrackingFormWorker/>
                    <div className="box-block">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="pills-add-user" role="tabpanel"
                                 aria-labelledby="pills-add-user-tab">
                                <div className="padded-block">
                                    <form onSubmit={this.migrationTrackingFormSubmit}>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                    <TextField name="site_name"
                                                               value={site_name}
                                                               label="Site Name"
                                                               errors={errors.site_name}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Client Verified</label>
                                                    <Select
                                                        name="client_verified"
                                                        value={yesNoStatusMigration.value}
                                                        placeholder={ client_verified === 0 ? "No" :  client_verified === 1 ? "Yes" : "Select Client Verified Status..."}
                                                        options={yesNoStatusMigration}
                                                        onChange={this.onChangeClientVerifyStatus}
                                                        errors={errors && errors.client_verified}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                  {/*  <TextField name="backup_deleted"
                                                               value={backup_deleted}
                                                               label="Backup Deleted"
                                                               errors={errors.backup_deleted}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}
                                                    /></div>*/}
                                                <label>Backup Deleted</label>
                                                <Select
                                                    name="backup_deleted"
                                                    value={yesNoStatusMigration.value}
                                                    placeholder={ backup_deleted === 0 ? "No" :  backup_deleted === 1 ? "Yes" : "Select Backup Deleted Status..."}
                                                    options={yesNoStatusMigration}
                                                    onChange={this.onChangeBackupDeleteStatus}
                                                    errors={errors && errors.backup_deleted}
                                                />
                                            </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                  {/*  <TextField name="old_container_deleted"
                                                               value={old_container_deleted}
                                                               label="Old Container Deleted"
                                                               errors={errors.old_container_deleted}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}
                                                    />*/}
                                                    <label>Old Container Deleted</label>
                                                    <Select
                                                        name="old_container_deleted"
                                                        value={yesNoStatusMigration.value}
                                                        placeholder={ old_container_deleted === 0 ? "No" :  old_container_deleted === 1 ? "Yes" : "Select Old ContainerStatus..."}
                                                        options={yesNoStatusMigration}
                                                        onChange={this.onChangeOldContainerDeleteStatus}
                                                        errors={errors && errors.old_container_deleted}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                    <TextField name="source_server_ip"
                                                               value={source_server_ip}
                                                               label="Source Server IP"
                                                               errors={errors.source_server_ip}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                    <TextField name="source_lb_ip"
                                                               value={source_lb_ip}
                                                               label="Source LB IP"
                                                               errors={errors.source_lb_ip}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                    <TextField name="destination_server_ip"
                                                               value={destination_server_ip}
                                                               label="Destination Server IP"
                                                               errors={errors.destination_server_ip}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                    <TextField name="destination_lb_ip"
                                                               value={destination_lb_ip}
                                                               label="Destination LB IP"
                                                               errors={errors.destination_lb_ip}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                 {/*   <TextField name="status"
                                                               value={status}
                                                               label="Status"
                                                               errors={errors.status}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}
                                                    />*/}
                                                    <label>Status</label>
                                                    <Select
                                                        name="status"
                                                        value={migrationTrackingStatusType.value}
                                                        placeholder={status ? status === "pending" ? "Pending" : ucFirst(status) : "Select Status..."}
                                                        options={migrationTrackingStatusType}
                                                        onChange={this.onChangeStatus}
                                                        errors={errors && errors.status}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="button-wrapper text-right mt-20 d-flex justify-content-end">
                                                <button type="submit"
                                                        className="btn-theme btn-dark-blue">
                                                    {processing ? <Loading/> : `Submit`}
                                                </button>
                                                {/*</div>*/}
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>
            </>

        )
    }
}

function mapStateToProps(state) {
    let {migrationTrackingForm, updateMigrationTracking} = state;

    let {errors, payload, mode} = migrationTrackingForm.data;

    let {
        site_name, client_verified,
        backup_deleted,
        old_container_deleted,
        status,
        source_server_ip,
        source_lb_ip,
        destination_server_ip,
        destination_lb_ip,
    } = payload;

    return {
        site_name, client_verified,
        backup_deleted,
        old_container_deleted,
        status,
        source_server_ip,
        source_lb_ip,
        destination_server_ip,
        destination_lb_ip, errors, mode, updateMigrationTracking, migrationTrackingForm
    };
}

export default connect(mapStateToProps)(MigrationTrackingForm);