import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handle422error, handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import getSiteDomain from "../domain/site_domain_get";
import getSiteStatus from "../sites/site_status_get";
import {siteFormServerValidationErrors} from "../../../pages/site/form/SiteForm-Action";
import {scrollToError} from "../../../helpers/ScrollHelper";

function _success(success)
{
  return { type: 'POST_GENERATE_SSL_SUCCESS', success };
}
function _error(error)
{
  return { type: 'POST_GENERATE_SSL_ERROR', error };
}
function _processing(processing)
{
  return { type: 'POST_GENERATE_SSL_PROCESSING', processing };
}

function createGenerateSsl(id)
{
  return ( dispatch, getState ) =>
  {
    dispatch(_processing(true));
    dispatch(_error(null));
    let {organizationDashboard,SSLList} = getState();
    let org_id = organizationDashboard.data.organization_id;
    let {data} = SSLList.data
    const payload = {
                "domains": data
            }

    axios({
      url: apiBaseUrl(`admin/organizations/${org_id}/sites/${id}/generateSsl`),
      method: "post",
      dataType: 'json',
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    })
      .then(function(res){
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess( res )
        store.dispatch(getSiteStatus(id))
        store.dispatch(getSiteDomain(id));
      })
      .catch(function(error){
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError( error )
        if (error.response && error.response.status === 422) {
          // store.dispatch(siteFormServerValidationErrors(error.response.data.errors))
          handle422error( error.response.data.errors )
          // scrollToError()
        }
      });

  }
}

export default createGenerateSsl;