import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import PriceBlockFormWorker from './PriceBlockFormWorker'
import {onPriceBlockFormChange, onPriceBlockFormSubmit} from "./PriceBlockForm-Action";
import Loading from "../../../../components/shared/loaders/Loading";
import swal from "sweetalert";
import TextField from "../../../../components/shared/form/TextField";
import deleteSitePrice from "../../../../ajax/actions/kepler-tools/site_price_delete";
import Delete from '../../../../assets/images/billing-delete.svg'
import {withRouter} from "react-router";


class priceBlockForm extends React.Component {

    PriceBlockSubmit = (e) => {
        e.preventDefault();
        swal("Are you sure you want to add Price?", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Yes",
                    value: e
                }
            }
        }).then((e) => {
            if (e) store.dispatch( onPriceBlockFormSubmit()  )
        })
    };

    onChange = (e) => {
        e.preventDefault();
        store.dispatch(onPriceBlockFormChange(e.target.name, e.target.value,));
    };

    deletePrice = (id) => {
        swal("Are you sure you want to Delete Price ? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Yes",
                    value: id
                }
            }
        }).then((id) => {
            if (id) store.dispatch(deleteSitePrice(id))
        })
    };

    render() {
        let {
            errors, site_status,payload,sitePrice,createSitePrice,updateSitePrice
        } = this.props;

        let {processing} = createSitePrice || updateSitePrice

        return (
            <>
                <PriceBlockFormWorker/>
                <form className="mt-30 mb-10" onSubmit={this.PriceBlockSubmit}>
                    <div className="padded-block">
                        <div className="d-flex justify-content-between align-items-center col-md-12 px-0" id="form-block-section">
                            <div className="d-flex px-0 text-section">
                                <p>The billing module controls payment of a site. You can disable billing and set a fixed monthly fee. Remember before updating here that the client needs a signed contract. </p>
                            </div>
                            <div className="d-flex px-0 input-and-button-section justify-content-between">
                                <div className="px-0 input-section" id="price-input">
                                    <TextField
                                        name="price"
                                        type="number"
                                        value={payload.price}
                                        placeholder="Site Price"
                                        onChange={this.onChange}
                                        errors={errors && errors.price}
                                        className="col-md-12 px-0 mb-0"
                                        label="Site"
                                    />

                                </div>
                                <div className="d-flex px-0 button-section">
                                    <div className="button-wrapper">
                                        <button type="submit" className="btn-theme btn-outline" id="admin-org-btn"
                                                disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring'? 'disabled' : null}
                                                onClick={this.PriceBlockSubmit}
                                        >

                                            { sitePrice ?
                                                <> { processing ?<Loading/> : `Update Price` }</>:
                                                <> { processing ?<Loading/> : `Add Price` }</>

                                            }
                                        </button>

                                    </div>
                                        {/* <a href="javascript:void(0);"
                                        onClick={() => this.deletePrice(sitePrice.id)} className="mt-1 ml-2">
                                            <img src={Delete} alt="delete"/>
                                        </a> */}
                                        {/* <a href="javascript:void(0);"
                                        onClick={() => this.deletePrice(sitePrice.id)} className="mt-1 ml-2"> */}
                                        {/* </a> */}
                                    { sitePrice  ?
                                            <img src={Delete} alt="delete" onClick={() => this.deletePrice(sitePrice.id)} className="ml-2"/>
                                        :null}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {priceBlockForm, createSitePrice,updateSitePrice, getSite,getSiteStatus,
        siteDashboard
    } = state;
    let {errors, payload} = priceBlockForm.data;
    let { site_status,org_id } = siteDashboard.data;
    let sitePrice = getSite && getSite.success && getSite.success.sitePrice

    return {
        priceBlockForm, createSitePrice,updateSitePrice, getSite, getSiteStatus, org_id, site_status, payload, sitePrice,errors
    };
}

export default withRouter(connect(mapStateToProps)(priceBlockForm));
