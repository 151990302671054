import React from 'react';
import store from '../../../Store';
import withRouter from "react-router/withRouter";
import {connect} from "react-redux";
import getAllOrganizationUsers from '../../../ajax/actions/all-organization-user/all_organizations_users_get'

class UserWorker extends React.Component {

componentDidMount()
{
  store.dispatch(getAllOrganizationUsers());
}

render() {
    return null
  }
}
function mapStateToProps( state ) {
  let { getAllOrganizationUsers } = state;
  return { getAllOrganizationUsers}
}
export default withRouter(connect(mapStateToProps)(UserWorker));


