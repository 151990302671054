import { ServerModuleFormState } from './ServerModuleForm-State'
import { errorsList, errorsPresent, mergeServerValidationErrors } from '../../../helpers/ErrorHelper'
import { endOfURL } from '../../../helpers/UrlHelper'
import {isObject} from "../../../helpers/DataHelper";

export default function serverModuleFormReducer(state = ServerModuleFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value, serverModule, mode } = action;
  switch (action.type) {

    case 'ON-SERVER-MODULE-FORM-CHANGE':
      data.payload[name] = value;
      buildErrors();
      return { ...state, data };

    case 'ON-SERVER-MODULE-FORM-MODE-CHANGE':
      data.mode = mode;
      buildErrors();
      return { ...state, data };

    case 'SET-SERVER-MODULE-DATA-FOR-UPDATE':
      data.payload = {
        name:serverModule.name,
        data_center_id:serverModule?.data_center.id,
        description:serverModule.description,
        public_ip_address:serverModule.public_ip_address,
        status:serverModule.status,
        network_interface: serverModule.network_interface,
        c_core: serverModule.c_core,
        storage_type: serverModule.storage_type,
        max_sites: serverModule.max_sites
      };
      buildErrors();
      return { ...state, data };

    case 'SET-DEFAULT-SERVER-MODULE-FORM':
      data = {
        payload:{
          name:``,
          data_center_id:'',
          description:``,
          public_ip_address:``,
          status:``,
          network_interface: '',
          c_core:'',
          storage_type:``,
          max_sites:``,
        },

        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode:endOfURL(),
        message: ``
      };
      buildErrors();
      return { ...state, data };

    case 'CANCEL-SERVER-MODULE-FORM-DISPATCH':
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case 'ON-SERVER-MODULE-FORM-SUBMIT':
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case 'SERVER-MODULE-FORM-SERVER-VALIDATION-ERRORS':
      validationErrors && isObject( validationErrors ) &&
      Object.keys(validationErrors).map(key =>
        data.errors[key] = [{ message: ` - ${validationErrors[key][0]}`.slice(0, -1) }]
      );
      data.dispatchAPI = false;
      return { ...state, data };

    default:
      return state
  }

  function buildErrors()
  {
    data.errors = errorsList(errors)
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field)
  {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
      if (field === `name`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }

      if (field === `data_center_id`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }

        if ( payload.status !== 0 && field === `status`) {
          if (!_(field)) newError(field, 0, `This is required field`)
        }

      if (field === `public_ip_address`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }
      if (field === `network_interface`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }

      if (field === `c_core`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }
      if (field === `storage_type`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }

      if (field === `max_sites`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }
    }

    errors = mergeServerValidationErrors(errors, validationErrors)

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}