import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    onMigrationTrackingFormModeChange,
    setDefaultMigrationTrackingForm,
    setMigrationTrackingDataForUpdate
} from "./MigrationTrackingForm-Action";
import updateMigrationTracking from "../../../ajax/actions/migration-tracking/migration_tracking_put";
import getMigrationTracking from "../../../ajax/actions/migration-tracking/migration_tracking_get";

class MigrationTrackingFormWorker extends React.Component {

    constructor(props) {
        super(props);
        if (props.match.params.id) {
            store.dispatch(getMigrationTracking(props.match.params.id));
            store.dispatch(onMigrationTrackingFormModeChange('update'));
        }
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode,} = this.props;

        if (this.props.getMigrationTracking !== prev.getMigrationTracking) {
            let {success} = this.props.getMigrationTracking;
            if (success) {
                store.dispatch(setMigrationTrackingDataForUpdate());
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                if (mode === 'create') {
                } else if (mode === `update`) {
                    store.dispatch(updateMigrationTracking(this.props.match.params.id))
                }
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {migrationTrackingForm, getMigrationTracking,} = state;
    let {dispatchAPI, mode, payload} = migrationTrackingForm.data;
    return {migrationTrackingForm, dispatchAPI, getMigrationTracking, mode, payload};
}

export default withRouter(connect(mapStateToProps)(MigrationTrackingFormWorker));