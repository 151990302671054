import React from 'react';
import store from '../../../Store';
import getOrganizations from '../../../ajax/actions/organization/organizations_get'
import {setDefaultSearch} from "./OrganizationList-Actions";

class OrganizationWorker extends React.Component {


    componentDidMount() {
        store.dispatch(getOrganizations());
        store.dispatch(setDefaultSearch());
    }

    render() {
        return null
    }
}


export default OrganizationWorker;