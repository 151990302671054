import store from '../../../../Store';
import { toggleURLQuery } from '../../../../helpers/UrlHelper'
import getSiteBackup from "../../../../ajax/actions/backup/site_backup_get";
import getSiteStatus from "../../../../ajax/actions/sites/site_status_get";
import getManualSiteBackup from "../../../../ajax/actions/backup/site_manual_backup_get"

export function toggleBackupListFilter(e, history)
{
  return function (dispatch) {
    let { name, value } = e.target;
    let toggle = true;
    if ( toggle ) toggleURLQuery( name, value , history)
    dispatch({type:'TOGGLE-BACKUP-LIST-FILTER', name, value});
    store.dispatch(getSiteBackup());
  }
}

export function toggleManualBackupListFilter(e, history)
{
  return function (dispatch) {
    let { name, value } = e.target;
    let toggle = true;
    if ( toggle ) toggleURLQuery( name, value , history)
    dispatch({type:'TOGGLE-BACKUP-LIST-FILTER', name, value});
    store.dispatch(getManualSiteBackup());
  }
}

export function setSiteIDBackup( site_id )
{
  return function (dispatch) {
    dispatch({type:'SET-SITEID-BACKUP', site_id});
  }
}
export function setDefaultPagination(  )
{
  return function (dispatch) {
    dispatch({type:'SET-DEFAULT-PAGINATION'});
  }
}

export function toggleBackupFormModal( id)
{
  return function (dispatch,getState) {
    let {getSite} = getState()
    let site_id = getSite && getSite.success && getSite.success.id;
    dispatch({type:'TOGGLE-BACKUP-FORM-MODAL'});
    store.dispatch(getSiteStatus(site_id));
  }
}