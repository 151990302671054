import React from 'react'
import { Route } from 'react-router-dom'
import  "../../assets/scss/main.scss";
import "../../assets/css/main.css"
import 'animate.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import MainRoute from '../../../src/routes/main-route/MainRoute';
import Alert from 'react-s-alert';
import {connect} from "react-redux";
import Intercom from 'react-intercom';
import {ConfigIntercom} from '../../Config'

const App = ({getLoggedInUser})=>
{
    const user = getLoggedInUser?.success?.data?.data
    const {id,email, first_name, intercom_hash} = user || {};
// ReactIntercom will automatically handle shutdown/boot when user_id or email changes
const intercomData = user
    ? { user_id: id, email: email , name:first_name, user_hash: intercom_hash}
    : { last_step: 'checkout' };
    return (
        <>
            <div className="App">
                <Intercom
                //    appID="gobsaoo3"
                   appID={ConfigIntercom.AppID}
                   { ...intercomData }
                />
                <Route path="/" component={ MainRoute } />
                <Alert stack={{limit: 1}} />
            </div>
        </>
    )
}
function mapStateToProps(state) {
    let { getLoggedInUser } = state;
    return {getLoggedInUser};
}

export default connect(mapStateToProps)(App);

