import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setCacheIgnoreDataForUpdate,
    setDefaultIgnoreCacheForm, setSiteId
} from "./IgnoreCacheForm-Action";
import cacheIgnore from "../../../../ajax/actions/kepler-tools/cache_ignore_post";
import updateCacheIgnore from "../../../../ajax/actions/kepler-tools/cache_ignore_put"

class IgnoreCacheFormWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(setSiteId(this.props.match.params.id))
        if (this.props.mode === 'create') {
            store.dispatch(setDefaultIgnoreCacheForm())
        }
    }

    componentDidUpdate(prev) {
        let {dispatchAPI,mode} = this.props;
        if (this.props.getCacheIgnore !== prev.getCacheIgnore) {
            let {success} = this.props.getCacheIgnore;

            if (success) {
                store.dispatch(setCacheIgnoreDataForUpdate());
            }
        }
        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                if (mode === 'create') {
                    store.dispatch(cacheIgnore(this.props.match.params.id))
                } else {
                    let {id} = this.props.getCacheIgnore
                    && this.props.getCacheIgnore.success
                    && this.props.getCacheIgnore.success.data.data
                    // && this.props.getCacheIgnore.success.data.data.data
                    store.dispatch(updateCacheIgnore(this.props.match.params.id, id))
                }
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {ignoreCacheForm,cacheIgnore,getCacheIgnore} = state;
    let {dispatchAPI, mode, payload} = ignoreCacheForm.data;
    return {ignoreCacheForm, dispatchAPI, mode, payload,cacheIgnore,getCacheIgnore};
}

export default withRouter(connect(mapStateToProps)(IgnoreCacheFormWorker));