export const filteredUserRoles = [
  { name: `customer` },
  { name: `restaurant-admin` },
  { name: `driver` },
  { name: `branch-admin` },
  { name: `branch-operator` },
  { name: `driver-captain` },
  { name: `restaurant-customer` },
];

export const allowedRole = user_role =>
{
  return (
    user_role === 'branch-admin' ||
    user_role === 'restaurant-admin' ||
    user_role === 'admin' ||
    user_role === 'super-admin'
  )
};

export const parentRole = [{name:"Restaurant Admin", value:"restaurant-admin"},{name:"Branch Admin", value:"branch-admin"}]

export const genderList = [{ id:'female', name:'Female' },{ id:'male', name:'Male' }];

export const activeStatus = [{ id:1, name:`Active` }, { id:0, name:`Inactive` }];

export const yesNoStatus = [{ name: "Yes", value:1 }, { name:"No", value:0 }];

export const yesNoStatusMigration = [{ label: "Yes", value:1 }, { label:"No", value:0 }];

export const activeStatusFilter = [{name:"Active", value:1},{name:"Inactive", value:0}];

export const billingTypeFilter = [{name:"Fortnox", value:'fortnox'},{name:"Stripe", value:"stripe"}];

export const contractTypeFilter = [{name:"Monthly", value:'monthly'}
  ,{name:"Yearly", value:"yearly"},{name:"Quarterly", value:"quarterly"}
];

export const versionFilter = [{name:"Version 1", value:1},{name:"Version 2", value:2}];

export const activeStatusStringFilter = [{name:"Active", value:"active"},{name:"Inactive", value:"inactive"}];

export const currencyFilter = [{name:"USD", value:"USD"},{name:"SEK", value:"SEK"}];

export const urgencyTypeFilter = [
  {name:"Urgent", value:"urgent"},
  {name:"Minor", value:"minor"},
];
export const priorityTypeFilter = [
  {name:"Highest", value:"highest"},
  {name:"Medium", value:"medium"},
  {name:"Low", value:"low"},
];

export const vatTypeFilter = [
  {name:"SE", value:"SEVAT"},
  {name:"SE reverse charge", value:"SEREVERSEDVAT"},
  {name:"EU reverse charge", value:"EUREVERSEDVAT"},
  {name:"Subject to EU VAT", value:"EUVAT"},
  {name:"Export", value:"EXPORT"},
];

export const lbType = [{name:"External", value:"external"},{name:"Internal", value:"internal"}];

export const selectType = [{name:"Normal", value:"normal"},{name:"Ecommerce", value:"ecommerce"}];

export const migrationStatusType = [
    {label:"Pending", value:"pending"},
  {label:"Migrating", value:"migrating"},
    {label:"Completed", value:"completed"},
  {label:"Failed", value:"failed"},
  {label:"Cancelled", value:"cancelled"},
  {label:"Rejected", value:"rejected"},
];

export const bugStatusType = [
  {label:"Pending", value:"pending"},
  {label:"Completed", value:"completed"},
  {label:"Active", value:"active"},
  {label:"Inactive", value:"inactive"},
  {label:"Reassigned", value:"reassigned"},
  {label:"Cancelled", value:"cancelled"},
];
export const migrationTrackingStatusType = [
  {label:"Pending", value:"pending"},
  {label:"Completed", value:"completed"},
  {label:"Started", value:"started"},
  {label:"Failed", value:"failed"},
];


export const storageType = [{name:"zfs", value:"zfs"},{name:"normal", value:"normal"}];

export const activeTypeFilter = [{name:"Personal", value:"personal"},{name:"Company", value:"company"}];

export const availabilityStatus = [{name:"Available", value:1},{name:"Unavailable", value:0}];

export const customer_type = [{name:"General", value:30},{name:"VIP", value:10}];

export const xpTypeList = [{name:"Normal", value:"normal"},{name:"Deduction", value:"deduction"}];

export const targetUsers = [{name:"All", value:"all"},{name:"Logged In", value:"logged-in-users"}];

export const operatorType = [
  {value:'normal', name:'Normal'},
  {value:'in_house', name:'In House'}

]

export const sslType = [
  {value:'certbot', name:'Certbot'},
  {value:'certificate', name:'Certificate'}

]



