import React from 'react';
import Button from "../../../components/shared/buttons/Button";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {withRouter} from "react-router";

class MigrateHeading extends React.Component {

    render() {

        let{params}=this.props
        return (
            <div className="heading-block pr-60">
                <div className="title-block">
                    <h2>Migration</h2>
                </div>

                <div className="button-wrapper">
                    <Link to={`/organizations`}>
                        <Button className="btn-theme btn-dark-blue"
                                title={`Add Site`}
                                text={`Add Site`}/>
                    </Link>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let {migrationList} = state;
    let {params} = migrationList.data;
    return {
        migrationList, params
    };
}

export default withRouter(connect(mapStateToProps)(MigrateHeading));
