import React from 'react'
import store from "../../Store";
import logoutUser from "../../ajax/actions/user/user_logout_post";
import {Link} from "react-router-dom";
import {logOut} from "../../helpers/LocalStorageHelper";
import {connect} from "react-redux";
import SidebarMainMenu from "./SidebarMainMenu";
import {withRouter} from 'react-router';
import Gravatar from "react-gravatar"
import Logo from '../../assets/images/kepler-logo-top.svg'


class Sidebar extends React.Component
{
    state = {
        width: window.innerWidth,
        logoutClicked: false,
        activeLabel:``,
    };

    logOut = () =>
    {
        if ( !this.state.logoutClicked ) {
            store.dispatch( logoutUser( ));
            this.setState({ logoutClicked: true }, () =>
                logOut()
            )
        }
    };

    render() {
        let {  startOfURL, user, site_url ,role, url, secondToLastItemInURL, home } = this.props;

        return (

                <div className="sidebar" id="sidebar">
                    <div className="row no-gutters">
                        <div className="col right-sidebar">
                            <div className="sidebar-content">
                                <div className="title-block">
                                    {/* <Link to={role === "organization-admin" ?"/organizations": "/"}><span className="icon-hdl"/></Link>
                                    <p>Kepler Control</p> */}
                                    <Link to={role === "organization-admin" ?"/organizations": "/"}><img alt="logo" src={Logo}/></Link>
                                </div>

                                    {/*<div className="row no-gutters">*/}
                                    {/* {
                                        dashboard_menu === 'organization' ?
                                            <OrganizationMenu url={ site_url, url } startOfURL={startOfURL} />
                                            : */}
                                <div className="menu-wrapper">
                                            <SidebarMainMenu  url={ url } startOfURL={startOfURL} secondToLastItemInURL={secondToLastItemInURL} home={home}/>
                                    {/* } */}
                                </div>
                            </div>
                            <ul className="lower-menu">
                                {/*<Link to={`/user/${user.id}/profile`}>*/}
                                <Link to={`/myaccount`}>
                                    <li className="avatar"><Gravatar email={user.email}/></li>
                                </Link>

                                {/*<li><Link id="username" to={`/user/${user.id}/profile`}>{user.first_name}</Link></li>*/}
                                <li><Link id="username" to={`/myaccount`}>{user.first_name}</Link></li>

                                <li id="logout" onClick={this.logOut}><Link to=""> <i className={`icon-logout`}/></Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
        )
    }
}

function mapStateToProps(state) {
    let { route, getUser, getLoggedInUser } = state;
    let { success } = getUser;

    let role = route && route.data.user && route.data.user.role.slug;

    let getFirstName=
           success &&
            success.first_name;

    let avatar =
        getLoggedInUser?.success?.data?.data?.avatar

    let { displayRightSidebar, site_url, user, dashboard_menu } = route.data;
    return { route, displayRightSidebar, site_url, user, dashboard_menu,getUser ,getFirstName , role, avatar}
}
export default withRouter(connect(mapStateToProps)(Sidebar));