import React from 'react';
import {connect} from "react-redux";
import store from "../../../../Store";
import {withRouter} from 'react-router';
import ReditectsWorker from "./RedirectsWorker";
import {toggleRedirectsFormModal} from "./Redirects-Action";
import ResponsiveModalForm from "../../../../components/shared/modals/ResponsiveModalForm";
import RedirectsForm from "../form/RedirectsForm";
import getRedirect from "../../../../ajax/actions/redirects/redirect_get"
import deleteRedirect from "../../../../ajax/actions/redirects/redirect_delete";
import {onRedirectsFormModeChange} from "../form/RedirectsForm-Action";
import Loading from "../../../../components/shared/loaders/Loading";

class Redirects extends React.Component {

    onDeleteRedirect = (id) => {
        store.dispatch(deleteRedirect(this.props.match.params.id, id));
    }

    onEditRedirect = (id) => {
        store.dispatch(getRedirect(this.props.match.params.id, id));
        store.dispatch(onRedirectsFormModeChange("update"));
        store.dispatch(toggleRedirectsFormModal());

    }
    onAddRedirect = () => {
        store.dispatch(onRedirectsFormModeChange("create"));
        store.dispatch(toggleRedirectsFormModal());

    }

    render() {
        let {
            getSite, redirects, list, pages, site_status, event, redirect_rule_id
        } = this.props
        let {processing} = getSite
        return (
            <>
                <ReditectsWorker/>
                <div className="features-block mt-0">
                    <div className="col-md-12 m-auto px-0">
                        <div className="d-flex align-items-center justify-content-between" id="cache-row">
                            <ResponsiveModalForm visible={redirects.mode}
                                                 redirectPopup={true}
                                                 closeModal={() => store.dispatch(toggleRedirectsFormModal())}>
                                <RedirectsForm/>
                            </ResponsiveModalForm>

                            <div className="feature-col" id="redirects-feature">
                                <div className="single-feature">
                                    <div className="feature-information">
                                        <div className="feature-name-section">
                                            <h3>Redirects</h3>
                                            <p>Welcome to our redirect tool. Here you will be able to create
                                                server-level redirects for your websites.<br/>
                                                Please contact our support if you have any questions and we will be
                                                happy to help you get started.</p>
                                        </div>
                                    </div>
                                    <div className="btn-wrapper">
                                        <button className="btn-theme btn-outline blue-outline"
                                                disabled={event && event}
                                                onClick={() => {
                                                    this.onAddRedirect()
                                                }}>
                                            {event === "create_redirect_rule" ? <>Adding
                                                Redirect Rule<Loading/></> : "Add Redirect Rule"}
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="ignore-urls pt-3">
                            <table className="url-table">
                                <tbody>
                                <tr className="url-table-col">
                                    <th className="url-table-heading">Domain</th>
                                    <th className="url-table-heading">Redirect From</th>
                                    <th className="url-table-heading">Redirect To</th>
                                    <th className="url-table-heading">Redirect Code</th>
                                    <th className="url-table-heading"
                                        id="actions">Actions
                                    </th>
                                </tr>
                                {
                                    list && list.map(items => {
                                        return (
                                            <>
                                                <tr className="url-table-col">
                                                    <td className="url-table-body onclick"
                                                        onClick={() => this.onEditRedirect(items.id)}>{items.domain ? items.domain.name : "All Domains"}</td>
                                                    <td className="url-table-body">{items.regex}</td>
                                                    <td className="url-table-body">{items.destination}</td>
                                                    <td className="url-table-body">{items.redirect_code}</td>
                                                    {(event === "create_redirect_rule" || event === "delete_redirect_rule" || event === "update_redirect_rule") && redirect_rule_id === items.id ?
                                                        <td className="url-table-body" id="actions">
                                                            {event === "create_redirect_rule" && <>Adding<Loading/></>}
                                                            {event === "delete_redirect_rule" && <>Deleting<Loading/></>}
                                                            { event === "update_redirect_rule" && <>Updating<Loading/></>}
                                                        </td>
                                                        :
                                                        <td className="url-table-body" id="actions">
                                                            {site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' ||
                                                            site_status === 'restoring' ?
                                                                <span className="icon-more"/>
                                                                :
                                                                <div
                                                                    className="dropdown dropdown-alt text-center">
                                                                                    <span className="icon-more"
                                                                                          data-toggle="dropdown"></span>
                                                                    <div
                                                                        className="dropdown-menu"
                                                                        aria-labelledby="dropdownMenuButton">
                                                                        <a href="javascript:void(0);"
                                                                           className="dropdown-item"
                                                                           type="button"
                                                                           onClick={() => this.onEditRedirect(items.id)}> Edit
                                                                        </a>
                                                                        <a href="javascript:void(0);"
                                                                           className="dropdown-item"
                                                                           type="button"
                                                                           onClick={() => this.onDeleteRedirect(items.id)}> Delete
                                                                        </a>

                                                                    </div>
                                                                </div>}
                                                        </td>
                                                    }
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                                {list && !list.length ?
                                    <td colSpan={5} className="text-center  pt-4">No
                                        Redirects</td>
                                    : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        getSite,
        getSites,
        getSiteStatus,
        siteDashboard,
        getRedirects,
        redirectList
    } = state;

    let {redirects} = redirectList.data

    let {success} = getRedirects;
    let list =
        success &&
        success.data &&
        success.data.data.data;
    let event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event
    let redirect_rule_id = getSiteStatus && getSiteStatus.success && getSiteStatus.success.redirect_rule_id

    let current_page = success && success.data && success.data.data.current_page;
    let last_page = success && success.data && success.data.data.last_page;
    let total = success && success.data && success.data.data.total;
    let pages = {current_page, last_page, total};

    let {site_status} = siteDashboard.data;

    return {
        getSite,
        getSites,
        getSiteStatus,
        site_status,
        siteDashboard,
        redirects,
        list, pages,
        event, redirect_rule_id
    };
}

export default withRouter(connect(mapStateToProps)(Redirects));