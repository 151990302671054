export function organizationBlockFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'ORGANIZATION-BLOCK-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onOrganizationBlockFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-ORGANIZATION-BLOCK-FORM-SUBMIT'})
    }
}

export function onOrganizationBlockFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-ORGANIZATION-BLOCK-FORM-CHANGE', name, value});
    }
}

export function onOrganizationBlockFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-ORGANIZATION-BLOCK-FORM-MODE-CHANGE', mode});
    }
}

export function cancelOrganizationBlockFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-ORGANIZATION-BLOCK-FORM-DISPATCH'});
    }
}

export function setDefaultOrganizationBlockForm() {
    return function (dispatch, getState) {
        let {getSite} = getState();
        let path = getSite && getSite.success && getSite.success.path;
        dispatch({type: 'SET-DEFAULT-ORGANIZATION-BLOCK-FORM', path});
    }
}

export function setSiteId(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITE-ID', site_id});
    }
}
export function setOrganizationId(organization_id) {
    return function (dispatch) {
        dispatch({type: 'SET-ORGANIZATION-ID', organization_id});
    }
}

export function organizationBlockError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-ORGANIZATION-BLOCK-ERROR', error});
    }
}