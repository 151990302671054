import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultLoadBalancerFormState = {
    payload: {
        name: ``,
        server_id: '',
        description: ``,
        public_ip_address: ``,
        public_ip_class: ``,
        public_gateway: ``,
        public_hardware_address: ``,
        private_ip_address: ``,
        private_ip_class: ``,
        private_gateway: ``,
        status: ``,
        version: 2,
        // lb_type: `external`
        type: `external`
    },

    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: endOfURL(),
    message: ``
};

export const LoadBalancerFormState = {data: DefaultLoadBalancerFormState};