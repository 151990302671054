const DefaultMyAccountState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        per_page: 25,
        page: 1,
        order: 'desc',
        sort_id: `id`,
        q: ``,
    },
    passwordResetSuccessMessage:``,
    twoFactorAuthentication:{
        mode:``
    }
}

export const MyAccountState = {data: DefaultMyAccountState}