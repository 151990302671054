export function setOrganizationDashboardData() {
    return function (dispatch, getState) {
        let {getOrganization} = getState();
        let {success} = getOrganization;
        if (success) {
            dispatch({type: 'SET-ORGANIZATION-DASHBOARD-DATA', organization: success});
        }

    }
}

export function setOrganizationStatus() {
    return function (dispatch, getState) {
        let {getOrganizationStatus} = getState();
        let {success} = getOrganizationStatus;
        if (success) {
            dispatch({type: 'SET-ORGANIZATION-STATUS', org_status: success});
        }
    }
}

export function setOrganizationId(org_id) {
    return function (dispatch) {
        dispatch({type: 'SET-ORGANIZATION-ID', org_id});
    }
}
