import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loggedInUser, loginToken, logOut, removeAccessToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {scrollToError} from "../../../helpers/ScrollHelper";
import getLoggedInUser from "../user/user_loggedin_get";

function _success(success) {
    return {type: 'VERIFY_2FA_SUCCESS', success}
}

function _error(error) {
    return {type: 'VERIFY_2FA_ERROR', error}
}

function _processing(processing) {
    return {type: 'VERIFY_2FA_PROCESSING', processing}
}

function verifyAuthy(payload) {
    return (dispatch) => {
        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))
        axios({
            url: apiBaseUrl(`admin/verify-2fa-token`),
            method: 'post',
            dataType: 'json',
            data: payload,
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                if (res) {
                    loggedInUser(res.data.token);
                    store.dispatch(getLoggedInUser())
                    window.location.href = '/';
                }

            }).catch(function (error) {
            dispatch(_error(error.response.data))
            dispatch(_processing(false))
        })
    }
}

export default verifyAuthy
