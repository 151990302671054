import {MigrationTrackingFormState} from './MigrationTrackingForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../helpers/ErrorHelper'
import {endOfURL} from '../../../helpers/UrlHelper'
import {isObject} from "../../../helpers/DataHelper";

export default function migrationTrackingFormReducer(state = MigrationTrackingFormState, action) {

    let {data} = {...state};
    let {validationErrors, name, value, migrationDetail, mode} = action;
    switch (action.type) {

        case 'ON-MIGRATION-TRACKING-FORM-CHANGE':
            data.payload[name] = value;
            buildErrors();
            return {...state, data};

        case 'ON-MIGRATION-TRACKING-FORM-MODE-CHANGE':
            data.mode = mode;
            buildErrors();
            return {...state, data};

        case 'SET-MIGRATION-TRACKING-DATA-FOR-UPDATE':
            data.payload = {
                site_name: migrationDetail.site_name,
                client_verified: migrationDetail.client_verified,
                backup_deleted: migrationDetail.backup_deleted,
                old_container_deleted: migrationDetail.old_container_deleted,
                status: migrationDetail.status,
                source_server_ip: migrationDetail.source_server_ip,
                source_lb_ip: migrationDetail.source_lb_ip,
                destination_server_ip: migrationDetail.destination_server_ip,
                destination_lb_ip: migrationDetail.destination_lb_ip,
            };
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-MIGRATION-TRACKING-FORM':
            data = {
                payload: {
                    site_name: ``,
                    client_verified: '',
                    backup_deleted: ``,
                    old_container_deleted: ``,
                    status: ``,
                    source_server_ip: ``,
                    source_lb_ip: ``,
                    destination_server_ip: ``,
                    destination_lb_ip: ``,
                },

                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: endOfURL(),
                message: ``
            };
            buildErrors();
            return {...state, data};

        case 'CANCEL-MIGRATION-TRACKING-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-MIGRATION-TRACKING-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'MIGRATION-TRACKING-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            );
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, validationErrors} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            if (field === `site_name`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if ( payload.status !== 0 && field === `status`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (payload.client_verified !== 0 &&field === `client_verified`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (payload.backup_deleted !== 0 &&field === `backup_deleted`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (payload.old_container_deleted !== 0 &&field === `old_container_deleted`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
        }

        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}