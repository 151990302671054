const DefaultUpcloudState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 25,
        page: 1,
        order: 'desc',
        sort_by: `date`,
        q: ``,
    },
    fields: [
        {field: `date`, label: `Date`, sort: true},
        {field: `resource`, label: `Resource`, sort: true},
        {field: `charge`, label: `Charge`, sort: true},
        {field: `description`, label: `Description`, sort: true},
        {field: `action`, label: `Action`, sort: false},
    ]
}

export const UpcloudState = {data: DefaultUpcloudState}