import React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import verifyCodeAuthy from "../../../ajax/actions/2fa-authentication/verify_2fa_authy_post"
import store from "../../../Store"
import Loading from "../loaders/Loading";
import TextField from "../form/TextField2FA";

class VerifyAuthyCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payload: '',
            token: '',
            errorFlag: false
        };
        this.onChange = this.onChange.bind(this);
    }

    sendCode = (e) =>{
        e.preventDefault();
        const payload = {
            "authy_token": this.state.payload,
            "access_token": this.props.access_token
        }
        this.setState({errorFlag: true})
        store.dispatch(verifyCodeAuthy(payload))
    }

    onChange(e, token) {
        this.setState({
            payload: e.target.value,
            token: token
        });
        this.setState({errorFlag: false})
    }

    goBack(){
        window.location.href=('/')
    }

    render() {


        let {loginUser, Verify2fa,access_token
            // qrCode
        } = this.props;
        let {processing, error} = Verify2fa
        if (loginUser.success) {
            if (loginUser.success.data.data.twoFa) {
                return (
                    /*old*/
/*
                    <div className="content-container light-gray-bg">
                        {/!*{qrCode ? <img className="qr-code" src={qrCode} alt="qrCode"/> : <Loading/>}*!/}
                        <div className="content-message-block">
                            <h2 className="activate-header">Activate 2FA</h2>
                            <p>Kepler Hosting uses time-based one time passwords (TOTP) <br/>
                                as the underlying technology for our two factor authentication. Please
                                type in the code or scan the QR code.Please note that we only support
                                Authy for Android & iphone</p>
                            <br/>

                            <p className="text-success">{Verify2fa.success ? Verify2fa.success.data.message : null}</p>
                            <div className="col-md-6 m-auto">
                                <div className="form-group">
                                    <TextField
                                        className="form-control text-center"
                                        name="code"
                                        type="number"
                                        label="Enter Time based code"
                                        errors={this.state.errorFlag ? error : ''}
                                        onChange={(e) => this.onChange(e, access_token)}/>
                                </div>
                            </div>
                            <div className="button-wrapper inline-buttons mt-30">
                                <div className="button-wrapper ">
                                    <button className="btn-theme btn-blue m-auto"
                                            onClick={() => this.sendCode(access_token)}
                                    >
                                        {processing ? <>Send<Loading/></> : "Send"}
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div>
*/


                    /*for new in the login form*/
                    <div className="col-lg-5">
                        <div className="form-block content-center">
                            <h2 id="activate">Two-Factor Authentication</h2>
                            <p id="activate-detail">Kepler Hosting uses time-based one time passwords (TOTP) <br/>
                                as the underlying technology for our two factor authentication. Please
                                type in the code
                                {/*or scan the QR code*/}
                                .Please note that we only support
                                Authy for Android & iphone</p>
                            <br/>

                            <p className="text-success">{Verify2fa.success ? Verify2fa.success.data.message : null}</p>
                           <form onSubmit={this.sendCode}>
                            {/*<div className="col-md-6 m-auto">*/}
                                <div className="form-group" id="login-input">
                                    <TextField
                                        className="form-control"
                                        name="code"
                                        type="number"
                                        label="Authentication code"
                                        placeholder="OTP"
                                        errors={this.state.errorFlag ? error : ''}
                                        onChange={(e) => this.onChange(e, access_token)}/>
                                </div>
                            {/*</div>*/}
                            <div className="button-wrapper inline-buttons text-center d-flex justify-content-center">
                                <button type="reset" className="btn-theme btn-dark-blue btn-login"
                                onClick={this.goBack}>
                                    Back
                                </button>
                                    <button type="submit" className="btn-theme btn-dark-blue btn-login">
                                        {processing ? <>Submit<Loading/></> : "Submit"}
                                    </button>
                            </div>
                           </form>
                        </div>
                    </div>

                )
            }
        }

        return (

            null

        )
    }
}

function mapStateToProps(state) {
    let {route, loginForm, Verify2fa, loginUser,
        // getAuthyQr
    } = state;

    // let {success} = getAuthyQr
    // let qrCode = success && success.data && success.data.data && success.data.data.qr_code
    let access_token = loginUser && loginUser.success && loginUser.success.data.data.access_token
    let {action, routes_url} = route.data;

    let role = route && route.data.user && route.data.user.role.slug;

    let {redirectTo} = loginForm.data;
    let {first_name} = route && route.data.user

    return {
        route,
        action,
        loginForm,
        redirectTo,
        routes_url,
        role,
        first_name,
        Verify2fa,
        loginUser,
        access_token
        // getAuthyQr,
        // qrCode
    };
}

export default withRouter(connect(mapStateToProps)(VerifyAuthyCode));


