import React from "react";
import store from "../../Store";
import { connect } from "react-redux";
import Loading from "../../components/shared/loaders/Loading";
import Page from "../../components/shared/pages/Page";
import { setDashboardMenu } from "../../routes/main-route/MainRoute-Actions";
import DashboardWorker from "./DashboardWorker";
import { ucFirst, unslufigy } from "../../helpers/StringHelper";
import GoogleMap from "./googlemap";
import TableHead from "../../components/shared/table/TableHead";
import SiteTableCell from "../super-admin-sites/list/SiteTableCell";
import "react-datepicker/dist/react-datepicker.css";

class Dashboard extends React.Component {
  state = {
    initialLoading: false,
    startDate: new Date(),
    endDate: new Date(),
  };

  componentDidMount() {
    document.querySelector("body").classList.add("organization-dashboard");
    store.dispatch(setDashboardMenu(""));
  }

  componentWillUnmount() {
    document.querySelector("body").classList.remove("organization-dashboard");
  }

  render() {
    let { getDashboard, params, fields, dashboard_search } = this.props;
    const titles = getDashboard?.success && Object.keys(getDashboard.success);
    let { processing } = getDashboard.processing;
    return (
      <>
        <Page title="Dashboard" processing={processing}>
          <DashboardWorker />
          {processing ? (
            <div className="text-center">
              <Loading />
            </div>
          ) : (
            <>
              {/* <DashboardFilters/> */}
              {dashboard_search === "true" ? (
                <div className="table-block table-responsive pr-60">
                  <table className="table table-striped  ">
                    <TableHead
                      fields={fields}
                      params={params}
                      noActions={true}
                    />
                    <tbody>
                      {this.state.initialLoading ? (
                        <td
                          colSpan={3}
                          className="text-center"
                          id="loader-spinner"
                        >
                          <Loading />
                        </td>
                      ) : (
                        <SiteTableCell />
                      )}
                    </tbody>
                  </table>
                </div>
              ) : getDashboard && getDashboard?.success?.map_information ? (
                <>
                  <div className="dashboard-content">
                    <div className="site-data-block">
                      <div className="title-block">
                        <h2>Dashboard</h2>
                      </div>
                      <div className="rowing">
                        {titles?.map(
                          (title, index) =>
                            title !== "map_information" &&
                            title !== "sites_this_month_vs_last_month" &&
                            title !== "sites_added_vs_sites_removed" && (
                              <>
                                <div id="dashboard-row" key={index}>
                                  <div className="single-site-data" key={index}>
                                    <div className="data-title" key={index}>
                                      <h2>{unslufigy(ucFirst(title))}</h2>
                                    </div>

                                    <div className="site-output">
                                      <div className="data-number">
                                        <p>
                                          {getDashboard.success[title].count}
                                        </p>
                                      </div>
                                      <div className="output-result ">
                                        <p
                                          className={
                                            getDashboard.success[title]
                                              .percentage > 0 ||
                                            getDashboard.success[title]
                                              .percentage === "N/A" ||
                                            getDashboard.success[title]
                                              .percentage === 0
                                              ? "increase"
                                              : "decrease"
                                          }
                                        >
                                          {getDashboard.success[title]
                                            .percentage === "N/A"
                                            ? (getDashboard.success[
                                                title
                                              ].percentage = 0)
                                            : Math.round(
                                                getDashboard.success[title]
                                                  .percentage
                                              )}
                                          {getDashboard.success[title]
                                            .percentage !== "N/A"
                                            ? "%"
                                            : null}

                                          <span
                                            className={
                                              getDashboard.success[title]
                                                .percentage < 0
                                                ? "icon-down"
                                                : getDashboard.success[title]
                                                    .percentage === 0 ||
                                                  getDashboard.success[title]
                                                    .percentage === "N/A"
                                                ? "icon-add"
                                                : "icon-up"
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                        )}
                      </div>
                    </div>

                    <GoogleMap
                      data={
                        getDashboard && getDashboard?.success?.map_information
                      }
                    />
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <Loading />
                </div>
              )}
            </>
          )}
        </Page>
      </>
    );
  }
}

function mapStateToProps(state) {
  let { getDashboard, dashboardList, getSuperAdminSites, getAdminSiteStatus } =
    state;
  let { params, fields, modal, dashboard_search } = dashboardList.data;
  let { success } = getSuperAdminSites;
  let list =
    success && success.data && success.data.data && success.data.data.data;
  return {
    getDashboard,
    dashboardList,
    getSuperAdminSites,
    getAdminSiteStatus,
    params,
    fields,
    modal,
    list,
    dashboard_search,
  };
}

export default connect(mapStateToProps)(Dashboard);
