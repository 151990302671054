import {BillingBlockFormState} from './BillingBlockForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../helpers/ErrorHelper'
import {isObject} from "../../../helpers/DataHelper";

export default function billingFormReducer(state = BillingBlockFormState, action) {

    let {data} = {...state};
    let {validationErrors, name, value,site_id,organization_id, billable} = action;
    switch (action.type) {

        case 'ON-BILLING-BLOCK-FORM-CHANGE':
            data.payload[name] = value;
            buildErrors();
            return {...state, data};

        case 'SET-SITE-ID':
            data.site_id = site_id;
            buildErrors();
            return {...state, data};

        case 'SET-BILLING-ID':
            data.organization_id = organization_id;
            buildErrors();
            return {...state, data};
        case 'SET-DEFAULT-BILLING-BLOCK-FORM':
            data = {
                payload: {
                    billable: ``
                },
                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: ``,
                message: ``
            };
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-BILLING-BLOCK-FORM-FOR-UPDATE':
            data = {
                payload: {
                    billable: billable
                },
                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: ``,
                message: ``
            };
            buildErrors();
            return {...state, data};

        case 'CANCEL-BILLING-BLOCK-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-BILLING-BLOCK-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'BILLING-BLOCK-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            );
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, validationErrors} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            // if (field === `billable`) {
            //     if (!_(field)) newError(field, 0, `is required`)
            // }
        }

        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        // function newError(field, step, message) {
        //     errors && errors[field].push({step, message})
        // }

        // function _(field) {
        //     return data.payload[field]
        // }
    }
}