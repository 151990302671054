import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultBillingBlockForm, setSiteId, setDefaultBillingBlockFormUpdate
} from "./BillingBlockForm-Action";
import createSiteBilling from "../../../ajax/actions/kepler-tools/site_billing_post";


class BillingBlockFormWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(setSiteId(this.props.match.params.id))
        store.dispatch(setDefaultBillingBlockFormUpdate())
    }

    componentDidUpdate(prev) {
        let {dispatchAPI} = this.props;
        if(this.props.getSite !== prev.getSite) {
            let {success} = this.props.getSite;
            if(success) {
                store.dispatch(setDefaultBillingBlockFormUpdate());
            }
            else{
                store.dispatch(setDefaultBillingBlockForm());

            }
        }
        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(createSiteBilling(this.props.match.params.id, this.props.match.params.org_id))
            }

        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {billingBlockForm, createSiteBilling,updateSiteBilling, getSite} = state;
    let {dispatchAPI, mode, payload} = billingBlockForm.data;

    return {billingBlockForm, createSiteBilling,updateSiteBilling, dispatchAPI, mode, payload, getSite};
}

export default withRouter(connect(mapStateToProps)(BillingBlockFormWorker));