const DefaultPriceState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 25,
        page: 1,
        order: 'desc',
        sort_by: `site_type`,
        q: ``,
    },
    fields: [
        {field: `site_type`, label: `Type`, sort: true},
        {field: `currency`, label: `Currency`, sort: true},
        {field: `price`, label: `Price`, sort: true},
        {field: `action`, label: `Action`, sort: false},
    ]
}

export const PriceState = {data: DefaultPriceState}