import store from '../../../Store';
import { toggleURLQuery } from '../../../helpers/UrlHelper'
import getOrganizationUsers from "../../../ajax/actions/organization-user/users_get";

export function toggleOrganizationUserListFilter(e, history)
{
  return function (dispatch) {
    let { name, value, id } = e.target;
    toggleURLQuery( name, value , history)
    dispatch({type:'TOGGLE-ORGANIZATION-USER-LIST-FILTER', name, value, history});
    // store.dispatch( getUsers());
    if(name === 'limit') {
      store.dispatch( getOrganizationUsers());
      toggleURLQuery( 'page', '',history)
    }
    else {
      store.dispatch(getOrganizationUsers(id));
    }
  }
}

export function setDefaultOrganizationUserFiltersToURL( history )
{
  return ( dispatch, getState ) => {
    let { data } = getState().siteList;
    let { params } = data;
    Object.keys( params ).map( key => {
      if ( params[key] !== `` && params[key] !== null && params[key] !== undefined ) {
        toggleURLQuery( key, params[key], history)
      }
    })
  }
}