import React from 'react';
import store from '../../../Store';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    onServerDataCenterChange,
    onServerReset,
    toggleServerModuleListFilter
} from './ServerModule-Action'
import {unslufigy} from "../../../helpers/StringHelper";
import {Link} from "react-router-dom";
import Button from "../../../components/shared/buttons/Button";

class ServerModuleListFilters extends React.Component
{
  componentDidMount()
  {

  }

  toggleFilter = (e) =>
  {
    store.dispatch(toggleServerModuleListFilter( e, this.props.history));
  }

    handleDataCenter = (selectedOption) => {
        if( selectedOption ) {
            store.dispatch(onServerDataCenterChange(selectedOption.value));
        }else {
            store.dispatch(onServerReset());
        }
    }

  render()
  {
    let { title, params} = this.props;

    return (
        <div className="heading-block pr-60">
          <div className="title-block">
            <h2>{ unslufigy(title) }</h2>
          </div>
          <div className="search-bar">
            <input type="text"
                   className="form-control"
                       name="q"
                   placeholder= {`Search ${unslufigy(title)}`}
                       value={params.q}
                       onChange={this.toggleFilter}
            />
            <span className="icon-search"/>
          </div>
         
          <div className="button-wrapper">
            <Link to={`/server/create`}>
              <Button className="btn-theme btn-dark-blue"
                      title={`Add ${unslufigy(title)}`}
                      text={`Add ${unslufigy(title)}`} />
            </Link>
          </div>
        </div>

    )
  }
}

function mapStateToProps(state)
{
  let {  getDataCenters , serverModuleList ,getServerModules} = state;
    let dataCenters =
        getDataCenters &&
        getDataCenters.success &&
        getDataCenters.success.data &&
        getDataCenters.success.data.data &&
        getDataCenters.success.data.data.data;

  let {params, data_center_id} = serverModuleList.data;


  return {  serverModuleList, params ,dataCenters, getDataCenters, data_center_id,getServerModules};
}
export default withRouter( connect( mapStateToProps )( ServerModuleListFilters ));