import React from 'react';
import Button from "../../../components/shared/buttons/Button";
import {Link} from 'react-router-dom';
import {unslufigy} from "../../../helpers/StringHelper";
import {connect} from "react-redux";
import store from "../../../Store";
import {
    toggleCouponListFilter,
} from "./Coupon-Action";

class CouponHeader extends React.Component {
    toggleFilter = (e) => {
        store.dispatch(toggleCouponListFilter(e, this.props.history));
    }

    render() {
        let {title} = this.props;
        return (
            <div className="heading-block pr-60">
                <div className="title-block Page animated fadeIn faster">
                    <h2>{unslufigy(title)}</h2>
                </div>

                <div className="button-wrapper">
                    <Link to={`/coupon/create`}>
                        <Button className="btn-theme btn-dark-blue"
                                title={`Add ${unslufigy(title)}`}
                                text={`Add ${unslufigy(title)}`}/>
                    </Link>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    let {discountList, getCoupons} = state;
    let {params} = discountList.data;

    return {getCoupons, params};
}

export default connect(mapStateToProps)(CouponHeader);