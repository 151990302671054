export function togglePublicPathFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-PUBLIC-PATH-FORM-MODAL'});
    }
}

export function toggleSearchAndReplaceFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-SEARCH-AND-REPLACE-FORM-MODAL'});
    }
}

export function toggleCacheIgnoreFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-CACHE-IGNORE-FORM-MODAL'});
    }
}

export function togglePhpVersionFormModal() {
    return function (dispatch, getState) {
        dispatch({type: 'TOGGLE-PHP-VERSION-FORM-MODAL'});
    }
}

export function togglePasswordProtectionFormModal() {
    return function (dispatch) {
        dispatch({type: 'TOGGLE-PASSWORD-PROTECTION-FORM-MODAL'});
    }
}