import React from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import enableTwoFactorAuthentication from "../../ajax/actions/2fa-authentication/enable_2fa_post"
import store from "../../Store"
import Loading from "../../components/shared/loaders/Loading";
import TextField from "../../components/shared/form/TextField";
import {onEnable2FaChange, onEnable2FaSubmit, selectPhoneCode} from "./Enable2Fa-Action";
import getCountries from "../../ajax/actions/country/countries_get";
import CustomReactSelect from "../../components/shared/form/CustomReactSelect"
import Enable2FaWorker from "./Enable2FaWorker";

class EnableTwoFactorAuthentication extends React.Component {
    componentDidMount() {
        store.dispatch(getCountries());
    }

    enable2FA=(e)=>{
        e.preventDefault();
        store.dispatch(onEnable2FaSubmit())
        store.dispatch(enableTwoFactorAuthentication())
    }

    onChange = (e) => {
        store.dispatch(onEnable2FaChange(e.target.name, e.target.value,));
    };

    handleCountry = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.value;
        }
        store.dispatch(selectPhoneCode(value));
    }

    render() {
        let {
            getLoggedInUser,
            first_name,
            submitted,
            enable2fa,
            country_code,
            contact_number,
            errors,
            countries
        } = this.props;
        let countryList = [];
        let clearable = true;
        countries && countries.map(c => {
            countryList.push({value: "+" + c.phone_code, label: c.name + " " + "(+" + c.phone_code+")", id: c.id})
        })
        let {processing} = enable2fa

        return (
            <>
                {/*{getLoggedInUser.success && !getLoggedInUser.success.data.data.twoFa &&*/}
                <Enable2FaWorker/>
                <div className="content-container light-gray-bg">
                    <div className="content-message-block">
                        <h2 className="enable2fa">Two-Factor Authentication</h2>
                        <div className="enable2fa-form">
                            <form onSubmit={this.enable2FA}>
                            <div className="form-row">
                                <div className="col-md-12">

                                    <div className="form-group">
                                        <label>Country Code</label>

                                        {/*<SelectField name="country"*/}
                                        {/*             options={countryList}*/}
                                        {/*             value={country_code}*/}
                                        {/*             errors={errors && errors.country_code}*/}
                                        {/*             onChange={this.handleCountry}*/}
                                        {/*             label="Country Code"*/}
                                        {/*             _value="value"*/}
                                        {/*             _label="label"/>*/}

                                        {/*<Select*/}
                                        {/*    name="country_code"*/}
                                        {/*    isClearable={clearable}*/}
                                        {/*    value={countryList.value}*/}
                                        {/*    placeholder="Select Country Code..."*/}
                                        {/*    options={countryList}*/}
                                        {/*    onChange={this.handleCountry}*/}
                                        {/*    errors={country_code}*/}
                                        {/*    styles={ customStyles }*/}
                                        {/*/>*/}
                                        <CustomReactSelect
                                            name="country_code"
                                            isClearable={clearable}
                                            value={countryList.filter(option => option.value === country_code)  || countryList.value}
                                            placeholder="Select Country Code..."
                                            options={countryList}
                                            onChange={this.handleCountry}
                                            isValid={errors && errors.country_code}
                                            submitted={submitted}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <TextField name="contact_number"
                                                   type="number"
                                                   value={contact_number}
                                                   label="Contact Number"
                                                   errors={errors.contact_number}
                                                   onChange={this.onChange}/>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="button-wrapper inline-buttons mt-30">*/}
                                <div className="inline-buttons text-center d-flex justify-content-center mt-10">
                                    <button className="btn-theme btn-outline blue-outline text-right"
                                            // onClick={() => this.enable2FA()}
                                        type="submit"
                                    >
                                        {processing ? <>Activate<Loading/></> : "Activate"}
                                    </button>

                                {/*</div>*/}
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/*}*/}
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
    route
,
    loginForm
,
    getLoggedInUser
,
    enable2fa
,
    enable2faForm
,
    getCountries
}

= state;

let {errors, payload, submitted} = enable2faForm.data
let {country_code, contact_number} = payload
let {action, routes_url} = route.data;

let countries = getCountries && getCountries.success && getCountries.success.data.data

let role = route && route.data.user && route.data.user.role.slug;

let {redirectTo} = loginForm.data;
let {first_name} = route && route.data.user

return {
    route,
    action,
    loginForm,
    redirectTo,
    routes_url,
    getLoggedInUser,
    role,
    first_name,
    enable2fa,
    enable2faForm,
    country_code,
    contact_number,
    errors,
    countries,
    submitted
};
}

export default withRouter(connect(mapStateToProps)(EnableTwoFactorAuthentication));


