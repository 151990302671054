import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultPriceBlockForm, setSiteId, setDefaultPriceBlockFormUpdate
} from "./PriceBlockForm-Action";
import getSite from "../../../../ajax/actions/sites/site_get"
import createSitePrice from "../../../../ajax/actions/kepler-tools/site_price_post";
import updateSitePrice from "../../../../ajax/actions/kepler-tools/site_price_put";


class PriceBlockFormWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(getSite(this.props.match.params.id,this.props.match.params.org_id))
        store.dispatch(setSiteId(this.props.match.params.id))
        store.dispatch(setDefaultPriceBlockFormUpdate())
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, sitePrice} = this.props;
        if(this.props.getSite !== prev.getSite) {
            let {success} = this.props.getSite;
            if(success) {
                store.dispatch(setDefaultPriceBlockFormUpdate());
            }
            else{
                store.dispatch(setDefaultPriceBlockForm());

            }
        }
        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                if(sitePrice !== null)
                {
                    store.dispatch(updateSitePrice(sitePrice.id))

                }else{
                store.dispatch(createSitePrice(this.props.match.params.id, this.props.match.params.org_id))
            }
            }

        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {priceBlockForm, createSitePrice,updateSitePrice, getSite} = state;
    let {dispatchAPI, mode, payload} = priceBlockForm.data;
    let sitePrice = getSite && getSite.success && getSite.success.sitePrice && getSite.success.sitePrice

    return {priceBlockForm, createSitePrice,updateSitePrice, dispatchAPI, mode, payload, getSite, sitePrice};
}

export default withRouter(connect(mapStateToProps)(PriceBlockFormWorker));