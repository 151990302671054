import {UserFormState} from './UserForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../helpers/ErrorHelper'
import {isValidEmail} from '../../../helpers/StringHelper'
import {endOfURL} from "../../../helpers/UrlHelper";

export default function userFormReducer(state = UserFormState, action) {

    let {data} = {...state};
    let {name, value, user, validationErrors, slug, login_user_role, mode} = action;

    switch (action.type) {

        case 'ON-USER-FORM-CHANGE':
            data.payload[name] = value;
            buildErrors()
            return {...state, data};

        case 'ON-MODE-CHANGE':
            data.mode = mode;
            buildErrors()
            return {...state, data};

        case 'SET-PROFILE-LOGIN-USER-ROLE':
            data.login_user_role = login_user_role;
            buildErrors()
            return {...state, data};

        case 'ON-USER-ROLE-FORM-CHANGE':
            data.payload[name] = slug;
            buildErrors()
            return {...state, data};

        case 'ON-USER-ORGANIZATION-ASSIGN':

            data.payload['allowed_organizations'] = value
            buildErrors()
            return {...state, data};

        case 'ON-USER-FORM-SUBMIT':
            data.submitted = true;
            buildErrors()
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'SET-SPECIFIC-USER-DATA':
            data.payload = {
                first_name: user.first_name,
                // middle_name: user.middle_name,
                last_name: user.last_name,
                email: user.email,
                password: user.password,
                role: user.role.slug,
                country_code: user.countryCode,
                contact_number: user.contactNumber,
                // extra_address_line: user.extra_address_line,
                // address: user.address,
                allowed_organizations: user.allowed_organizations,
                city: user.city,
                country: user.country,
                // post_code: user.post_code,

            };
            buildErrors()
            return {...state, data};


        case 'SET-SPECIFIC-USER-PROFILE':
            data.payload = {
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                password: user.password,
                role: user.role.slug,
                country_code: user.countryCode,
                contact_number: user.contactNumber,
                allowed_organizations: user.allowed_organizations,
                city: user.city,
                country: user.country,
            };
            buildErrors()
            return {...state, data};

        case 'USER-FORM-SERVER-VALIDATION-ERRORS':
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            )
            data.errorsPresent = true;
            data.dispatchAPI = false;
            return {...state, data};

        case 'CANCEL-USER-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors()
            return {...state, data};

        case 'SET-DEFAULT-USER-DATA':
            data = {
                payload: {
                    first_name: '',
                    last_name: '',
                    // middle_name: '',
                    email: '',
                    password: '',
                    allowed_organizations: [],
                    role: '',
                    country_code: '',
                    contact_number: '',
                    // extra_address_line: ``,
                    // address: ``,
                    city: ``,
                    country: ``,
                    // post_code: ``,
                    password_confirmation: '',
                },

                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,

                user_mode: ``,
                action: 'loader',
                message: ``,
                mode: endOfURL(),

            };
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)

        data.errorsPresent = errorsPresent(errors);
    }

    function errors(field) {
        let {payload, submitted, serverValidationErrors, mode} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            if (field === `first_name`) {
                if (!_(field)) newError(field, 0, `is required field`);
            }
            if (field === `last_name`) {
                if (!_(field)) newError(field, 0, ` is required field`);
            }
            // if (field === `address`) {
            //     if (!_(field)) newError(field, 0, ` is required field`);
            // }

            if (field === `country`) {
                if (!_(field)) newError(field, 0, ` is required field`);
            }

            if (field === `country_code`) {
                if (!_(field)) newError(field, 0, ` is required field`);
            }

            if (field === `contact_number`) {
                if (!_(field)) newError(field, 0, ` is required field`);
            }
            // if (field === `post_code`) {
            //     if (!_(field)) newError(field, 0, ` is required field`);
            // }
            if (field === `city`) {
                if (!_(field)) newError(field, 0, ` is required field`);
            }
            // if (field === `extra_address_line`) {
            //     if (!_(field)) newError(field, 0, ` is required field`);
            // }

            if (field === `email`) {
                if (!_(field).length) newError(field, 0, ` is required field`);
                else if (_(field).length > 42) newError(field, 0, `is too Long`);
                else if (!isValidEmail(_(field))) newError(field, 0, `is invalid`);
            }
            if (field === `password`) {
                if (endOfURL() === 'create') {
                    if (!_(field)) newError(field, 0, ` is required field`);
                }
            }
            if (mode !== 'myaccount') {
                if (field === `role`) {
                    if (!_(field)) newError(field, 0, ` is required field`);
                }
            }
        }

        errors = mergeServerValidationErrors(errors, serverValidationErrors);

        return field ? errors[field] : errors;

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}