const DefaultDiscountState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 25,
        page: 1,
        order: 'desc',
        // sort_order: `desc`,
        sort_by: `discount_percentage`,
        q: ``,
    },
    fields: [
        {field: `discount_percentage`, label: `Discount Percentage`, sort: true},
        {field: `minimum_amount`, label: `Minimum Amount`, sort: true},
        {field: `status`, label: `Status`, sort: true},
        {field: `action`, label: `Action`, sort: false},
    ]
}

export const DiscountState = {data: DefaultDiscountState}