import React from 'react';
import store from '../../../Store';
import getCoupons from "../../../ajax/actions/coupon/coupon_gets";
import getCurrency from "../../../ajax/actions/coupon/currency_gets";

class CouponWorker extends React.Component {
    componentDidMount() {
        store.dispatch(getCoupons());
        store.dispatch(getCurrency());
    }

    render() {
        return null
    }
}

export default CouponWorker;