import {SearchAndReplaceFormState} from './SearchAndReplaceForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../../helpers/ErrorHelper'
import {endOfURL} from '../../../../helpers/UrlHelper'
import {isObject} from "../../../../helpers/DataHelper";

export default function searchAndReplaceFormReducer(state = SearchAndReplaceFormState, action) {

    let {data} = {...state};
    let {validationErrors, name, value, mode, error ,
        site_id, search_term, amount_of_terms, replace_flag,failedStatus,errorMessage} = action;
    switch (action.type) {

        case 'ON-SEARCH-AND-REPLACE-FORM-CHANGE':
            data.payload[name] = value;
            data.amount_of_terms = ``;
            data.search_item_name = ``;
            data.failedStatus = ``;
            data.errorMessage = ``;
            if(name === "searchTerm" && value === "")
            {
                data.message="";
                data.payload[name] = "";
            }

            buildErrors();
            return {...state, data};

        case 'ON-SEARCH-AND-REPLACE-FORM-MODE-CHANGE':
            data.mode = mode;
            buildErrors();
            return {...state, data};

        case 'ON-REPLACE-FLAG-CHANGE':
            if(name === "replace_flag" && replace_flag === 0)
            {
                data.replace_flag =1;
            }
            if(name === "replace_flag" && replace_flag === 1)
            {
                data.replace_flag =0;
            }
            buildErrors();
            return {...state, data};

        case 'SET-SEARCH-AND-REPLACE-ERROR':
            data.message = error;
            buildErrors();
            return {...state, data};

        case 'SET-SITE-ID':
            data.site_id = site_id;
            buildErrors();
            return {...state, data};

            case 'ON_SEARCH_NOTIFICATION':
            data.amount_of_terms = amount_of_terms;
            data.search_item_name = search_term;
            buildErrors();
            return {...state, data};

        case 'ON_FAILED_SEARCH_NOTIFICATION':
            data.failedStatus = failedStatus;
            data.errorMessage = errorMessage;
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-SEARCH-AND-REPLACE-FORM':
            data = {
                payload: {
                    searchTerm: ``,
                    replaceTerm: ``
                },
                replace_flag: 0,
                amount_of_terms:``,
                search_item_name:``,
                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: endOfURL(),
                message: ``
            };
            buildErrors();
            return {...state, data};

        // case 'SET-DEFAULT-SEARCH-FORM':
        //     data = {
        //         dispatchAPI: false,
        //     };
        //     buildErrors();
        //     return {...state, data};
        //
        // case 'SET-DEFAULT-REPLACE-FORM':
        //     data = {
        //         dispatchAPI: false,
        //     };
        //     buildErrors();
        //     return {...state, data};

        case 'CANCEL-SEARCH-AND-REPLACE-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-SEARCH-AND-REPLACE-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'SEARCH-AND-REPLACE-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            );
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, validationErrors,replace_flag} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            if (field === `searchTerm`) {
                if (!_(field)) newError(field, 0, `is required`)
            }
            if( replace_flag === 1 ) {
                if (field === `searchTerm`) {
                    if (!_(field)) newError(field, 0, `is required`)
                }
                if (field === `replaceTerm`) {
                    if (!_(field)) newError(field, 0, `is required`)
                }
            }
        }

        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}