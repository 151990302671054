import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getSite from "../../../ajax/actions/sites/site_get";
import store from "../../../Store";
import {scrollToError} from "../../../helpers/ScrollHelper";
import {
    cancelBillingBlockFormDispatch,
    billingBlockFormServerValidationErrors
} from "../../../pages/site/billing-block/BillingBlockForm-Action";

function _success(success) {
    return {type: 'CREATE_SITE_BILLING_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_SITE_BILLING_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_SITE_BILLING_PROCESSING', processing}
}

function createSiteBilling(value) {
    console.log(` <|value  |> `,value )
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_error(null));

        let {SftpList,billingBlockForm,organizationDashboard} = getState();
        let site_id =  SftpList.data.site_id
        let payload = billingBlockForm.data.payload
        let org_id = organizationDashboard.data.organization_id;


        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/billings`),
            method: 'post',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res.data.message)
                store.dispatch(getSite(site_id))
                store.dispatch(cancelBillingBlockFormDispatch())

            }).catch(function (error) {

            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
            store.dispatch(cancelBillingBlockFormDispatch())

            if (error.response && error.response.status === 422) {
                store.dispatch(billingBlockFormServerValidationErrors(error.response.data.errors))
                scrollToError()
            }
        });
    }
}

export default createSiteBilling;