import React from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import {fullDate} from "../../helpers/DateTimeHelper";

class OrganizationDetail extends React.Component {
    render() {
        let {data} = this.props;
        return (
            <div className="row ">
                <div className="col-md-12">
                    <div className="panel panel-default panel-table">
                        <div className="panel-heading p-1">
                            <h3>Organization DashBoard</h3>
                        </div>
                        <div className="panel-body">
                            <table className="table">
                                <tbody>
                                <tr>
                                    <th>Organization ID</th>
                                    <td>{data && data.id}</td>
                                </tr>
                                <tr>
                                    <th>Name</th>
                                    <td>{data && data.name}</td>
                                </tr>
                                <tr>
                                    <th>Billing Name</th>
                                    <td>{data && data.billing_name}</td>
                                </tr>
                                <tr>
                                    <th>Country</th>
                                    <td>{data && data.country}</td>
                                </tr>
                                <tr>
                                    <th>State</th>
                                    <td>{data && data.state}</td>
                                </tr>
                                <tr>
                                    <th>City</th>
                                    <td>{data && data.city}</td>
                                </tr>
                                <tr>
                                    <th>Address 1</th>
                                    <td>{data && data.address_line_1}</td>
                                </tr>
                                <tr>
                                    <th>Address 2</th>
                                    <td>{data && data.address_line_2}</td>
                                </tr>
                                <tr>
                                    <th>Zip</th>
                                    <td>{data && data.zip}</td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>{data && data.type}</td>
                                </tr>
                                <tr>
                                    <th>Vat ID</th>
                                    <td>{data && data.vat_id}</td>
                                </tr>
                                <tr>
                                    <th>Created Date</th>
                                    <td>{data && fullDate(data.created_at)}</td>
                                </tr>

                                <tr>
                                    <td colSpan={2} className="text-center">
                                        <Link to={`/organizations/${data && data.id}/update`}>
                                            <button className="btn btn-primary">
                                                Update Organization
                                            </button>
                                        </Link>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let {getOrganization} = state;
    let {success} = getOrganization;
    let data = success;
    return {getOrganization, data};
}

export default connect(mapStateToProps)(OrganizationDetail);