import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import updateOrganization from "../../../ajax/actions/organization/organization_put";
import {
  onOrganizationFormModeChange,
  setOrganizationDataForUpdate,
  setDefaultOrganizationForm
} from "./OrganizationForm-Action";
import getOrganization from "../../../ajax/actions/organization/organization_get";
import createOrganization from "../../../ajax/actions/organization/organization_post";
import {endOfURL, urlTrailWithoutLastItem} from "../../../helpers/UrlHelper";
import getCountries from "../../../ajax/actions/country/countries_get";

class OrganizationFormWorker extends React.Component
{
  constructor(props) {
    super(props);
    if(props.match.params.id) {
      store.dispatch(getOrganization(props.match.params.id));
      // store.dispatch(setOrganizationDataForUpdate());
      store.dispatch(onOrganizationFormModeChange(props.match.params.type));
    }
    if(  endOfURL() === 'create') {
      store.dispatch(setDefaultOrganizationForm())
    }
    store.dispatch(getCountries());
  }

  componentDidUpdate(prev)
  {
    let {  dispatchAPI , mode } = this.props;

    if(this.props.getOrganization !== prev.getOrganization) {
      let {success} = this.props.getOrganization;
      if(success) {
        store.dispatch(setOrganizationDataForUpdate());
      }
    }

    if( this.props.createOrganization !== prev.createOrganization ) {
      let { success } = this.props.createOrganization;
      if( success ) {
        this.props.history.push(urlTrailWithoutLastItem());
      }
    }

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if( !this.props.match.params.id &&mode === 'create' ) {
          store.dispatch( createOrganization() )
        } else {
          store.dispatch(updateOrganization( this.props.match.params.id ))
        }
      }
    }
  }

  render()
  {
    return null
  }
}

function mapStateToProps(state) {
  let { organizationForm, getOrganization , getOrganizations ,createOrganization} = state;

  let {dispatchAPI, mode, payload} = organizationForm.data;
  return { organizationForm, dispatchAPI, getOrganization, mode, payload , getOrganizations ,createOrganization};
}
export default withRouter(connect( mapStateToProps )( OrganizationFormWorker ));