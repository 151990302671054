import React from 'react';
import Button from "../../../components/shared/buttons/Button";
import {Link} from 'react-router-dom';
import {unslufigy} from "../../../helpers/StringHelper";
import {connect} from "react-redux";
import store from "../../../Store";
import {
    toggleDiscountListFilter,
} from "./Discount-Action";

class DiscountHeader extends React.Component {
    toggleFilter = (e) => {
        store.dispatch(toggleDiscountListFilter(e, this.props.history));
    }

    render() {
        let {title, params} = this.props;
        return (
            <div className="heading-block pr-60">
                <div className="title-block Page animated fadeIn faster">
                    <h2>{unslufigy(title)}</h2>
                </div>

                <div className="search-bar">
                    <input type="text"
                           className="form-control"
                           name="q"
                           placeholder={`Search ${unslufigy(title)}`}
                           value={params.q}
                           onChange={this.toggleFilter}
                    />
                    <span className="icon-search"/>
                </div>

                <div className="button-wrapper">
                    <Link to={`/discounts/create`}>
                        <Button className="btn-theme btn-dark-blue"
                                title={`Add ${unslufigy(title)}`}
                                text={`Add ${unslufigy(title)}`}/>
                    </Link>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    let {discountList, getDiscounts} = state;
    let {params} = discountList.data;

    return {getDiscounts, params};
}

export default connect(mapStateToProps)(DiscountHeader);