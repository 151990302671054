import store from "../../../Store";
import getOrganizationUsers from "../../../ajax/actions/organization-user/users_get";

export function couponFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'COUPON-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onCouponFormSubmit() {
    return function (dispatch,getState) {
        let {getCurrency} = getState();
        let currencyList = getCurrency.success
        dispatch({type: 'ON-COUPON-FORM-SUBMIT',currencyList})
    }
}

export function onCouponFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-COUPON-FORM-CHANGE', name, value});
    }
}

export function onCouponApplyFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-COUPON-APPLY-FORM-CHANGE', name, value});
        if (value) {
            store.dispatch(getOrganizationUsers(value));
        }
    }

}

export function onCouponFormDateChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-COUPON-FORM-DATE-CHANGE', name, value});
    }
}

export function currencyError(currencyServerError) {
    return function (dispatch) {
        dispatch({type: 'ON-COUPON-CURRENCY-ERROR', currencyServerError});
    }
}

export function onCouponCurrencyChange(value) {
    return function (dispatch) {
        dispatch({type: 'ON-COUPON-CURRENCY-CHANGE',value});
    }
}

export function onInitialUpdateCurrencyChange(name,value) {
    return function (dispatch) {
        dispatch({type: 'ON-COUPON-CURRENCY-CHANGE',name,value});
    }
}

export function onCouponFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-COUPON-FORM-MODE-CHANGE', mode});
    }
}

export function cancelCouponFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-COUPON-FORM-DISPATCH'});
    }
}

export function setDefaultCouponForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-COUPON-FORM'});
    }
}

export function setDefaultApplyCouponForm() {
    return function (dispatch, getState) {
        let {getCoupon} = getState();
        let couponDetails = getCoupon && getCoupon.success;
        let price = getCoupon && getCoupon.success && getCoupon.success.value
        dispatch({type: 'SET-COUPON-DATA-FOR-APPLY',couponDetails,price});
    }
}

export function setCouponDataForUpdate() {
    return function (dispatch, getState) {
        let {getCoupon} = getState();
        let coupon = getCoupon && getCoupon.success;
        let priceValue = getCoupon && getCoupon.success && getCoupon.success.value
        dispatch({type: 'SET-COUPON-DATA-FOR-UPDATE', coupon,priceValue});
    }
}
