import React from 'react';
import monitorSvg from "../../../../assets/images/monitoring.svg";
import restartSvg from "../../../../assets/images/restart-php.svg";
import sslSvg from "../../../../assets/images/ssl.svg";
import debugSvg from "../../../../assets/images/wp-debug.svg";
import {connect} from "react-redux";
import store from "../../../../Store";
import monitorPost from "../../../../ajax/actions/kepler-tools/monitor_post";
import {withRouter} from 'react-router';
import FeatureBlockWorker from "./FeatureBlockWorker";
import ResponsiveModalForm from "../../../../components/shared/modals/ResponsiveModalForm";
import {
    togglePublicPathFormModal, toggleSearchAndReplaceFormModal,
    togglePhpVersionFormModal,
    togglePasswordProtectionFormModal
} from "./FeatureBlock-Action";
import PublicPathForm from "../form/PublicPathForm";
import PublicPathPrimaryView from "./publicPathPrimaryView";
import debug from "../../../../ajax/actions/kepler-tools/debug_post";
import restartPhp from "../../../../ajax/actions/kepler-tools/restart_php_post";
import getSite from "../../../../ajax/actions/sites/site_get";
import SearchAndReplacePrimaryView from "./searchAndReplacePrimaryView";
import SearchAndReplaceForm from "../../searchandreplace/form/SearchAndReplaceForm";
import Loading from "../../../../components/shared/loaders/Loading";
import enableCache from "../../../../ajax/actions/kepler-tools/enable_cache_post";
import disableCache from "../../../../ajax/actions/kepler-tools/disable_cache_post";
import clearCache from "../../../../ajax/actions/kepler-tools/clear_cache_post";
import PhpVersionPrimaryView from "./PhpVersionPrimaryView";
import PhpVersionForm from "../../phpVersion/form/PhpVersionForm"
import PasswordProtectionPrimaryView from "./PasswordProtectionPrimaryView";
import PasswordProtectionForm from "../../passwordProtection/form/PasswordProtectionForm"
import ForceSslPost from "../../../../ajax/actions/kepler-tools/force_ssl_post"
import createReactConfiguration from "../../../../ajax/actions/kepler-tools/react_configuration_post"


class FeatureBlock extends React.Component {
    enabledisablemonitor = (value) => {
        const payload = {
            "monitor": value
        }
        store.dispatch(monitorPost(payload));

    }

    enabledisableReactConfiguration = (value) => {
        const payload = {
            "react_flag": value
        }
        store.dispatch(createReactConfiguration(payload));

    }

    enableDisableForceSsl = (value) => {
        const payload = {
            "force_ssl": value
        }
        store.dispatch(ForceSslPost(payload, this.props.match.params.id));
    }

    debug = (value) => {
        const payload = {
            "debug": value
        }
        store.dispatch(debug(payload));
    }

    restartPhp = () => {
        store.dispatch(restartPhp(this.props.match.params.id));
    }

    onEnableCache = () => {
        store.dispatch(enableCache(this.props.match.params.id));
    }

    onDisableCache = () => {
        store.dispatch(disableCache(this.props.match.params.id));
    }

    clearCache = () => {
        store.dispatch(clearCache(this.props.match.params.id));
    }

    componentDidMount() {
        if(this.props.organizationDashboard.organization_id) {
            store.dispatch(getSite(this.props.match.params.id, this.props.match.params.org_id));
        }
    }

    render() {
        let {
            monitor,reactConfiguration, modal,createReactConfiguration, password_protection, debugMode, debug_event, public_path_modal,createForceSsl, wp_flag, getSite, site_status, php_version, force_ssl, event,
        } = this.props
        let {processing} = getSite.processing
        let forceSSLProcessing = createForceSsl.processing
        let reactConfigurationProcessing = createReactConfiguration.processing

        return (
            <>
                <FeatureBlockWorker/>
                <div className="features-block">
                        <div className="col-md-12 m-auto">
                            <div className="row">
                                <div className="col-xl-12 col-sm-12 feature-col">
                                    <div className="single-feature" id="first-feature">
                                    <div className="feature-information">
                                        <img src={monitorSvg} alt="feature svg" className="feature-img"/>
                                        <div className="feature-name-section">
                                            <p className="site-tools-heading">Site Tools</p>
                                            <h3>Monitoring</h3>
                                        </div>
                                    </div>
                                        <p className="site-tools-description">Activate monitoring if you would like the Kepler team to keep track of your site. We will be launching our Slack integration shortly. Stay tuned!</p>
                                        <div className="btn-wrapper">
                                            <a href="/#" onClick={ev => {ev.preventDefault();}}>
                                                {monitor && monitor === 1 ?
                                                    <button className="btn-theme btn-outline blue-outline "
                                                            onClick={() => this.enabledisablemonitor(0)}
                                                            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' ? 'disabled' : null}>
                                                        Disable</button> :
                                                    <button className="btn-theme btn-outline blue-outline"
                                                            onClick={() => this.enabledisablemonitor(1)}
                                                            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' ? 'disabled' : null}>
                                                        Enable</button>
                                                }

                                            </a>
                                        </div>
                                    </div>
                                </div>
                                </div>

                            <div className="row">
                                <div className="col-xl-12 col-sm-12 feature-col">
                                    <div className="single-feature">
                                        <div className="feature-information">
                                            <img src={sslSvg} alt="feature svg" className="feature-img"/>
                                            <div className="feature-name-section">
                                                <p className="site-tools-heading">Site Tools</p>
                                                <h3>Force SSL</h3>
                                            </div>
                                        </div>
                                        {/*<p className="site-tools-description">This tool helps you redirect insecure HTTP traffic to secure HTTPS one without touching any code.</p>*/}
                                        <p className="site-tools-description">The Kepler Hosting force SSL tool. Please use this tool to force SSL on non HTTPS links (HTTP) - The Force SSL tool will make sure that all your URLs on your website will be forced over SSL.</p>
                                        <div className="btn-wrapper">
                                            <a href="/#" onClick={ev => {ev.preventDefault();}}>
                                                {force_ssl && force_ssl === 1 ?
                                                    <button className="btn-theme btn-outline blue-outline "
                                                            onClick={() => this.enableDisableForceSsl(0)}
                                                            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' ? 'disabled' : null}>
                                                        {forceSSLProcessing || event === "force_ssl_disable"?<>Disable<Loading/></>:"Disable"}</button> :
                                                    <button className="btn-theme btn-outline blue-outline"
                                                            onClick={() => this.enableDisableForceSsl(1)}
                                                            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' ? 'disabled' : null}>
                                                        {forceSSLProcessing || event === "force_ssl_enable"?<>Enable<Loading/></>:"Enable"}</button>
                                                }

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                <div className="row">
                                    <ResponsiveModalForm visible={password_protection.mode}
                                                        closeModal={() => store.dispatch(togglePasswordProtectionFormModal())}>
                                        <PasswordProtectionForm/>
                                    </ResponsiveModalForm>
                                    <PasswordProtectionPrimaryView/>
                                </div>

                                <div className="row"> 
                                    <ResponsiveModalForm visible={php_version.mode}
                                                        closeModal={() => store.dispatch(togglePhpVersionFormModal())}>
                                        <PhpVersionForm/>
                                    </ResponsiveModalForm>
                                    <PhpVersionPrimaryView/>
                                </div>
                                
                                <div className="row">
                                    <ResponsiveModalForm visible={public_path_modal.mode}
                                                        closeModal={() => store.dispatch(togglePublicPathFormModal())}>
                                        <PublicPathForm/>
                                    </ResponsiveModalForm>
                                    <PublicPathPrimaryView/>
                                </div>

                                <div className="row">
                                    <div className="col-xl-12 col-sm-12 feature-col">
                                        <div className="single-feature">
                                        <div className="feature-information">
                                            <img src={restartSvg} alt="feature svg" className="feature-img"/>
                                            <div className="feature-name-section">
                                                <p className="site-tools-heading">Site Tools</p>
                                                <h3>Restart PHP</h3>
                                            </div>
                                        </div>
                                            <p className="site-tools-description">The Kepler Hosting Restart PHP tool - This tool is used to restart your PHP engine. This may help clear some issue that may lead to site connection problems. It is also good to restart your PHP once you migrate your site to make sure everything goes smooth. If you notice any server related issues. Please restart the PHP. If the issue is still there. Contact our support.</p>
                                            <div className="btn-wrapper">
                                                <a href="/#" onClick={ev => {ev.preventDefault();}}>
                                                    <button className="btn-theme btn-outline blue-outline " type="button"
                                                            onClick={() => this.restartPhp()}
                                                            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' ? 'disabled' : null}>
                                                        {debug_event === "restart_php_fpm" ? <>Restart PHP <Loading/></> : "Restart PHP"}</button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <ResponsiveModalForm visible={modal.mode}
                                                        closeModal={() => store.dispatch(toggleSearchAndReplaceFormModal())}>
                                        <SearchAndReplaceForm/>
                                    </ResponsiveModalForm>
                                    <SearchAndReplacePrimaryView/>
                                </div>

                                {!processing && wp_flag === 1 ?
                                    <div className="row">
                                    <div className="col-xl-12 col-sm-12 feature-col">
                                        <div className="single-feature">
                                            <div className="feature-information">
                                                <img src={debugSvg} alt="feature svg" className="feature-img"/>
                                                <div className="feature-name-section">
                                                    <p className="site-tools-heading">Site Tools</p>
                                                    <h3>Enable WPDebug</h3>
                                                </div>
                                            </div>
                                            <p className="site-tools-description">Use this tool to replace any value in your database. Moving to a new
                                                domain? No more pain.</p>
                                            <div className="btn-wrapper">
                                                <a href="/#" onClick={ev => {ev.preventDefault();}}>
                                                    {debugMode && debugMode === 1 ?
                                                        <button className="btn-theme btn-outline blue-outline"
                                                                type="button"
                                                                onClick={() => this.debug(0)}
                                                                disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' ? 'disabled' : null}>
                                                            {debug_event === "disable_debug" ? <>
                                                                Disable <Loading/> </> : "Disable"}</button> :
                                                        <button className="btn-theme btn-outline blue-outline"
                                                                type="button"
                                                                onClick={() => this.debug(1)}
                                                                disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' ? 'disabled' : null}>
                                                            {debug_event === "enable_debug" ? <>
                                                                Enable <Loading/></> : "Enable"}</button>
                                                    }

                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> : null}

                            <div className="row">
                                <div className="col-xl-12 col-sm-12 feature-col">
                                    <div className="single-feature">
                                        <div className="feature-information">
                                            <img src={monitorSvg} alt="feature svg" className="feature-img"/>
                                            <div className="feature-name-section">
                                                <p className="site-tools-heading">Site Tools</p>
                                                <h3>Enable React Configuration</h3>
                                            </div>
                                        </div>
                                        <p className="site-tools-description">
                                            Enable React Configuration</p>
                                        <div className="btn-wrapper">
                                            <a href="/#" onClick={ev => {ev.preventDefault();}}>
                                                {reactConfiguration && reactConfiguration === 1 ?
                                                    <button className="btn-theme btn-outline blue-outline "
                                                            onClick={() => this.enabledisableReactConfiguration(0)}
                                                            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' ? 'disabled' : null}>
                                                        {reactConfigurationProcessing || event === "change_react_config_flag"?<>Disable<Loading/></>:"Disable"}</button> :
                                                    <button className="btn-theme btn-outline blue-outline"
                                                            onClick={() => this.enabledisableReactConfiguration(1)}
                                                            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' ? 'disabled' : null}>
                                                        {reactConfigurationProcessing || event === "change_react_config_flag"?<>Enable<Loading/></>:"Enable"}</button>
                                                }

                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        getSite,
        getSites,
        featureBlocklist,
        getSiteStatus,
        siteDashboard,
        enableCache,
        disableCache,
        clearCache,
        organizationDashboard,
        createForceSsl,
        createReactConfiguration
    } = state;
    let debug_event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event
    let monitor = getSite && getSite.success && getSite.success.monitor
    let reactConfiguration = getSite && getSite.success && getSite.success.react_flag
    let wp_flag = getSite && getSite.success && getSite.success.wp_flag
    let force_ssl = getSite && getSite.success && getSite.success.force_ssl
    let {site_status} = siteDashboard.data;

    let debugMode = getSite && getSite.success && getSite.success && getSite.success.debug_mode
    let cache_event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event
    let event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event

    let cache = getSite && getSite.success && getSite.success.site_meta && getSite.success.site_meta.enable_cache

    let {modal, public_path_modal, ignore_cache, php_version, password_protection} = featureBlocklist.data;

    return {
        getSite,
        getSites,
        monitor,
        reactConfiguration,
        ignore_cache,
        modal,
        public_path_modal,
        featureBlocklist,
        debug_event,
        debugMode,
        wp_flag,
        getSiteStatus,
        siteDashboard,
        site_status,
        cache, cache_event,
        enableCache,
        disableCache,
        clearCache,
        force_ssl,
        php_version,
        password_protection,
        organizationDashboard,
        createForceSsl,
        event,
        createReactConfiguration
    };
}

export default withRouter(connect(mapStateToProps)(FeatureBlock));
