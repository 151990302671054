const DefaultSitesListState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 25,
        page: 1,
        sort_order: 'desc',
        // sort_id: `id`,
        sort_by: ``,
        q: ``,
    },
    modal: {
        mode: ``
    },
    organization_id: ``,
    fields: [
        // { field: `id`, label: `ID`, sort: true },
        {field: `display_name`, label: `Name`, sort: true},
        {field: `data_center_name`, label: `Data Center`, sort: true},
        {field: `plan`, label: `Plan`, sort: false},
        {field: `action`, label: `Action`, sort: false},
        // { field: `ssh_user`, label: `Client`, sort: true },
        // { field: `ssh_port`, label: `Port`, sort: true },
    ]
}

export const SiteListState = {data: DefaultSitesListState}