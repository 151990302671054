const DefaultSftpState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    per_page: 25,
    page:   1,
    order:  'desc',
    sort_id: `id`,
    q: ``,
  },
  site_id:``,
  fields: [
    // { field: `site_id`, label: `Site ID`, sort: true },
    { field: `site_id`, label: `Site ID`, sort: true },
    { field: `sftp_host`, label: `SFTP Host`, sort: true },
    { field: `sftp_username`, label: `SFTP Username`, sort: true },
    { field: `sftp_password`, label: `SFTP Password`, sort: true },
    { field: `sftp_port`, label: `SFTP Port`, sort: true },
    { field: `db_name`, label: `DataBase Name`, sort: true },
    { field: `db_username`, label: `DataBase Username`, sort: true },
    { field: `db_password`, label: `DataBase Password`, sort: true },
    { field: `php_myadmin_url`, label: `PHPmyAdmin URL`, sort: true },
  ]
}

export const SftpState = {data:DefaultSftpState}