import { PaymentListState } from './PaymentList-State'

export default function paymentListReducer(state = PaymentListState, action) {

  let { data } = { ...state };
  let {name, value} = action;

  switch (action.type) {

    case 'TOGGLE-PAYMENT-LIST-FILTER':
      let toggle = true;
      if ( toggle ) data.params[ name ] = value;
      if (name === `sort_by`) {
        if (data.params.sort_by === value) {
          data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
        }
      }
      return { ...state, data };

    default: return state
  }
}
