import React from 'react';
import Button from "../../../components/shared/buttons/Button";
import store from '../../../Store';
import {ucFirst,} from "../../../helpers/StringHelper";
import {Link} from "react-router-dom";
import {
    onActivityLogSiteChange,
    onActivityLogSiteReset,
    toggleActivityListFilter,
    onActivityLogEventChange,
    onActivityLogEventReset
} from './ActivityList-Actions'
import {connect} from "react-redux";
import Select from "react-select";

class ActivityHeading extends React.Component {

    toggleFilter = (e) => {

        store.dispatch(toggleActivityListFilter(e, this.props.history));
    }

    handleSiteChange = (selectedOption) => {
        if (selectedOption) {
            store.dispatch(onActivityLogSiteChange(selectedOption.value));
        } else {
            store.dispatch(onActivityLogSiteReset());
        }
    }
    handleEventChange = (selectedOption) => {
        if (selectedOption) {
            store.dispatch(onActivityLogEventChange(selectedOption.value));
        } else {
            store.dispatch(onActivityLogEventReset());
        }
    }

    render() {
        let {organization_name, organization_id, siteLists, activityEventLists} = this.props;
        let clearable = true;
        let siteList = [];
        siteLists && siteLists.map(site => {
            siteList.push({value: site.id, label: ucFirst(site.name)})
        });
        let eventList=[];
        activityEventLists && activityEventLists.map(e => {
            eventList.push({value: e, label: e})
        });

        return (

            <div className="heading-block pr-60">
                <div className="title-block">
                    <h2>Activity Logs</h2>
                    <span><Link to="/organizations">Organizations</Link></span>
                    <span><Link to={`/organizations/${organization_id}/sites`}>{organization_name}</Link></span>
                    <span>Activity Logs</span>
                </div>

                <div className="select-block">
                    <Select
                        name="site_id"
                        isClearable={clearable}
                        placeholder="Select Site ..."
                        value={siteList.id}
                        options={siteList}
                        onChange={this.handleSiteChange}
                    />

                </div>
                <div className="select-block">
                    <Select
                        name="event"
                        isClearable={clearable}
                        placeholder="Select Event ..."
                        value={eventList.value}
                        options={eventList}
                        onChange={this.handleEventChange}
                    />

                </div>
                <div className="button-wrapper">
                    <Link to={`/organizations/${organization_id}/sites/create`}>
                        <Button className="btn-theme btn-dark-blue"
                                title={`Add Site`}
                                text={`Add Site`}/>
                    </Link>

                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    let {organizationDashboard, getOrganization, siteDashboard, getSites, activityList,getActivityEvents} = state;
    let {organization_name, organization_id} = organizationDashboard.data;

    let {params} = activityList.data;

    let siteLists = getSites && getSites.success &&
        getSites.success.data &&
        getSites.success.data.data.data;

    let activityEventLists = getActivityEvents?.success?.data.data
    return {
        organizationDashboard, organization_name, activityList,
        getOrganization, siteDashboard, organization_id, getSites, siteLists, params,getActivityEvents,activityEventLists
    };
}

export default connect(mapStateToProps)(ActivityHeading);
