import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import {scrollToError} from '../../../helpers/ScrollHelper'
import {
    cancelInternalMigrationFormDispatch,
    internalMigrationFormServerValidationErrors
} from "../../../pages/site/internal-migration-block/form/InternalMigrationForm-Action";
import getSiteStatus from "../sites/site_status_get";

function _success(success) {
    return {type: 'CREATE_MIGRATION_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_MIGRATION_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_MIGRATION_PROCESSING', processing}
}

function createMigration(site_id , orz_id) {
    return (dispatch, getState) => {

        let {internalMigrationForm} = getState();

        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))

        axios({
            url: apiBaseUrl(`admin/organizations/${orz_id}/sites/${site_id}/migrate`),
            method: 'post',
            dataType: 'json',
            data: internalMigrationForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess("Site migration request is successfully sent.")
                store.dispatch(getSiteStatus(site_id,orz_id))
                store.dispatch(cancelInternalMigrationFormDispatch())

            }).catch(function (error) {
            dispatch(_error(error.response.data.data))
            dispatch(_processing(false))
            handleError(error)

            store.dispatch(cancelInternalMigrationFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(internalMigrationFormServerValidationErrors(error.response.data.errors))
                scrollToError()
            }
        })
    }
}

export default createMigration
