import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken, logOut} from '../../../helpers/LocalStorageHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
    return {type: 'GET_EXPIRED_BACKUP_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_EXPIRED_BACKUP_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_EXPIRED_BACKUP_PROCESSING', processing};
}

function getExpiredBackup() {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        let {expiredBackupList} = getState();
        let {params} = expiredBackupList.data;

        axios({
            url: apiBaseUrl(`admin/expired-backups`),
            method: "get",
            dataType: 'json',
            params,
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
            }).catch(function (error) {
            handleError(error);
            dispatch(_error(error));
            dispatch(_processing(false));
        });


    }
}


export default getExpiredBackup;