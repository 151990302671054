import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'GET_INVOICE_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_INVOICE_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_INVOICE_PROCESSING', processing};
}
/*organization invoice*/

function getInvoice(id=null) {
    return (dispatch,getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        let { organizationDashboard,paymentList} = getState();
           let org_id = id || organizationDashboard.data.organization_id;
        let {params} = paymentList.data
        const request = axios({

            url: apiBaseUrl(`admin/organizations/${org_id}/payments`),
            method: "get",
            dataType: 'json',
            params,
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
            }).catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error)
            });

        return {
            type: 'GET_INVOICE_SUCCESS',
            payload: request
        }
    }
}


export default getInvoice;