import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    onDiscountFormModeChange,
    setDefaultDiscountForm,
    setDiscountDataForUpdate
} from "./DiscountForm-Action";
import {endOfURL, urlTrailWithoutLastItem} from "../../../helpers/UrlHelper";
import getDiscount from "../../../ajax/actions/discount-slab/discount_get";
import createDiscount from "../../../ajax/actions/discount-slab/discount_post";
import updateDiscount from "../../../ajax/actions/discount-slab/discount_put";

class DiscountFormWorker extends React.Component {
    constructor(props) {
        super(props);
        if (props.match.params.id) {
            store.dispatch(getDiscount(props.match.params.id));
            store.dispatch(onDiscountFormModeChange('update'));
        }
        if (endOfURL() === 'create') {
            store.dispatch(setDefaultDiscountForm())
        }
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode,} = this.props;

        if (this.props.getDiscount !== prev.getDiscount) {
            let {success} = this.props.getDiscount;
            if (success) {
                store.dispatch(setDiscountDataForUpdate());
            }
        }

        if (this.props.createDiscount !== prev.createDiscount) {
            let {success} = this.props.createDiscount;
            if (success) {
                this.props.history.push(urlTrailWithoutLastItem());
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                if (mode === 'create') {
                    store.dispatch(createDiscount())
                } else {
                    store.dispatch(updateDiscount(this.props.match.params.id))
                }
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {discountForm, getDiscount, createDiscount,} = state;
    let {dispatchAPI, mode, payload} = discountForm.data;
    return {discountForm, dispatchAPI, getDiscount, mode, payload, createDiscount};
}

export default withRouter(connect(mapStateToProps)(DiscountFormWorker));