import React from 'react';
import MyIcon from "../icons/MyIcon";
import {Link} from "react-router-dom";
import store from "../../../Store";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";

class NotFound extends React.Component
{

  componentDidMount() {
    document.querySelector('body').classList.add('organization-dashboard');
    store.dispatch(  setDashboardMenu(''));
  }

  componentWillUnmount() {
    document.querySelector('body').classList.remove('organization-dashboard');
  }

  render()
  {
    return (

        <div className="row">
          <div className="col-lg-4 offset-lg-4 col-md-8 offset-md-2 col-12 text-center pd-3 pt-5 ">

            <MyIcon type="tools" width={80} />

            <h4 className="mt-5">The page you are looking for can not be found.</h4>

            <div className="mt-20">

              <Link to={ '/' } className="btn btn-secondary btn-in" >
                Back to Home Page
              </Link>

            </div>

          </div>
        </div>

    )
  }
}

export default  NotFound ;