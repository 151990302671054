import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import Loading from "../../../components/shared/loaders/Loading";
import {toggleMigrateFormModal} from "../list/Migrate-Actions";
import {withRouter} from "react-router";
import {migrationStatusType} from "../../../static-data/UserData"
import Select from "react-select";
import {onMigrationFormChange, onMigrationFormSubmit} from "./MigrationForm-Action";
import MigrationFormWorker from "./MigrationFormWorker";
import {ucFirst} from "../../../helpers/StringHelper";
import TextField from "../../../components/shared/form/TextField";
import Page from "../../../components/shared/pages/Page";
import getMigration from "../../../ajax/actions/migration/migration_get";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import {endOfURL} from "../../../helpers/UrlHelper";

class MigrateForm extends React.Component {

    componentDidMount() {
        // store.dispatch(getMigration(this.props.match.params.id));

        if (this.props.match.params.id) {
            document.querySelector('body').classList.add('organization-dashboard');
            store.dispatch(setDashboardMenu(''));
        }
    }

    componentWillUnmount() {
        if (this.props.match.params.id) {
            document.querySelector('body').classList.remove('organization-dashboard');
        }
    }


    onChangeStatus = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.value;
        }
        store.dispatch(onMigrationFormChange("status", value));
    }

    migrationFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onMigrationFormSubmit());
    };

    render() {
        let {
            getMigration, migrationDetails, updateMigrationStatus, payload, mode, view
        } = this.props;
        let {processing, errors} = getMigration
        let updateMigrationStatusProcessing = updateMigrationStatus.processing

        return (
            <>
                {mode === "update" ?
                    <>
                        {processing ?
                            <h2 className="text-center mb-30">Update Status<Loading/></h2> :
                            <>
                                <h2 className="text-center mb-30">Update Status</h2>
                                <form className="mt-30 domain-form" onSubmit={this.migrationFormSubmit}>

                                    <p style={{color: 'red'}}> {errors && errors.status && errors.status.length ? "This is required Field" : null}</p>

                                    <label>Status</label>
                                    <Select
                                        name="status"
                                        value={migrationStatusType.value}
                                        placeholder={payload.status ? payload.status === "pending" ? "Pending" : ucFirst(payload.status) : "Select Status..."}
                                        options={migrationStatusType}
                                        onChange={this.onChangeStatus}
                                        // errors={errors && errors.status}
                                    />

                                    <div
                                        className="button-wrapper inline-buttons text-center d-flex justify-content-center mt-3">
                                        <button className="btn-theme btn-outline" type="reset"
                                                onClick={() => store.dispatch(toggleMigrateFormModal())}>Cancel
                                        </button>
                                        <div className="tab-domain-button">
                                            <button type="submit" className="btn-theme btn-dark-blue">
                                                {updateMigrationStatusProcessing ? <Loading/> : `Update Status`}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                                <MigrationFormWorker/>
                            </>
                        }
                    </> :
                    mode === "viewMigration" &&
                    <>
                        <Page title="Migration Details">

                            {processing ?
                                <div className="heading-block-form pr-60">
                                    <div className="title-block">
                                        <h2 className="mt-5">Migration Details<Loading/></h2>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="heading-block-form pr-60">
                                        <div className="title-block">
                                            <h2 className="mt-5">Migration Details</h2>
                                        </div>
                                    </div>
                                    <div className="box-block">
                                        <div className="tab-content">
                                            <div className="padded-block">
                                                <form onSubmit={this.migrationFormSubmit}>

                                                    <p style={{color: 'red'}}> {errors && errors.status && errors.status.length ? "This is required Field" : null}</p>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField
                                                                    name="site_name"
                                                                    label="Site Name"
                                                                    value={view.site_name}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField
                                                                    name="status"
                                                                    label="Status"
                                                                    value={ucFirst(view.status)}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField
                                                                    name="type"
                                                                    label="Type"
                                                                    value={view.type === "ftp" ? "FTP" : "SFTP"}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField
                                                                    name="server_ip"
                                                                    label="Server Ip"
                                                                    value={view.server_ip}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField
                                                                    name="server_port"
                                                                    label="Server Port"
                                                                    value={view.server_port}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField
                                                                    name="server_username"
                                                                    label="Server Username"
                                                                    value={view.server_username}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField
                                                                    name="server_password"
                                                                    label="Server Password"
                                                                    value={view.server_password}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField
                                                                    name="create_new_site"
                                                                    label="Create a New Site?"
                                                                    value={view.create_new_site === 1 ? "yes" : "No"}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField
                                                                    name="Customer Name"
                                                                    label="Customer Name"
                                                                    value={view.customerName}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField
                                                                    name="db_login_url"
                                                                    label="Db Login Url"
                                                                    value={view.db_login_url}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField
                                                                    name="Is it an Ecommerce, Community or Member site?"
                                                                    label="Is it an Ecommerce, Community or Member site?"
                                                                    value={view.is_ecommerce === 1 ? "yes" : "No"}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField name="wordpress_username"
                                                                           label="Wordpress Username"
                                                                           value={view.wordpress_username}
                                                                           disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField name="wordpress_username"
                                                                           label="Wordpress UserName"
                                                                           value={view.wordpress_username}
                                                                           disabled
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField name="WordPress Password"
                                                                           label="Wordpress Password"
                                                                           value={view.wordpress_password}
                                                                           disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField name="WordPress Admin Url"
                                                                           label="Wordpress Admin Url"
                                                                           value={view.wordpress_admin_url}
                                                                           disabled
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <TextField name="is_multi_site"
                                                                           label="Is it a Multisite Installation?"
                                                                           value={view.is_multi_site === 1 ? "yes" : "No"}
                                                                           disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </form>
                                                <MigrationFormWorker/>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }

                        </Page>
                    </>
                }

            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        getMigration
        ,
        migrationForm
        ,
        updateMigrationStatus
    }

        = state;

    let {payload, mode, view} = migrationForm.data

    let migrationDetails = getMigration && getMigration.success && getMigration.success.data

    return {getMigration, migrationDetails, payload, updateMigrationStatus, mode, view};
}

export default withRouter(connect(mapStateToProps)(MigrateForm));