import React from 'react';
import { connect } from 'react-redux'
import PrimaryDomainView from "./PrimaryDomainView";
import DomainWorker from "./DomainWorker";
import Loading from "../../../../components/shared/loaders/Loading";
import swal from "sweetalert";
import store from "../../../../Store";
import deleteDomain from "../../../../ajax/actions/domain/domain_delete";
import ResponsiveModalForm from "../../../../components/shared/modals/ResponsiveModalForm";
import DomainForm from "../form/DomainForm";
import {toggleDomainFormModal } from "./Domain-Action";
import updateDomain from "../../../../ajax/actions/domain/domain_put";
import getSiteDomain from "../../../../ajax/actions/domain/site_domain_get";
import { scrollToError } from '../../../../helpers/ScrollHelper'


class Domain extends React.Component
{


    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this domain ? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    primaryConfirm = (id) => {
        swal("Are you sure you want to make this domain primary ? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Yes",
                    value: id
                }
            }
        }).then((value) => {
            if (value) store.dispatch( updateDomain( id ) )
        })
    };

    delete(id)
    {
        store.dispatch(deleteDomain(id));
    }

    componentDidUpdate(prev) {
        if (this.props.getSiteStatus !== prev.getSiteStatus) {
            let {getSiteStatus} = this.props;
            let update_status = getSiteStatus && getSiteStatus.success && getSiteStatus.success.notifications
                    update_status && update_status.length && update_status.map((l, key) => {
                        if(l.event_name === "update_domain"){
                            store.dispatch(getSiteDomain());
                        }
                    });
                if (getSiteStatus?.success?.notification?.event_name === "delete_domain" ||
                    getSiteStatus?.success?.notification?.event_name === "update_domain"
                ) {
                    store.dispatch(getSiteDomain());
                }
            if( getSiteStatus?.success?.status === "updating" || getSiteStatus?.success?.status === "deleting" ) {
                return null;
            }
        }
    }

    render()
    {
        let { domainLists, getSiteDomain, modal, site_status, getSiteStatus, getSite,creatingDomainList, event_name } = this.props;
        let { processing } = getSiteDomain.processing;

        return(
            <>
                <DomainWorker />
                <PrimaryDomainView />
                <div className="setting-table table-responsive mt-30" id="domain-table">
                    <table className="table table-striped">
                    { processing ? <div className="text-center"> <Loading /></div> :
                        <thead>
                        </thead>
                    }   
                        <tbody>
                        { processing ? <div className="text-center"> <Loading /></div> :
                                domainLists && domainLists.length ?
                                    domainLists.map(( d, key ) => {
                                        return <tr key={key}>
                                              <td className="domain-name-td">
                                                <div className="primary-name">
                                                    <p className="site-name">
                                                        {
                                                            d.is_primary === 1 ?
                                                            "Primary Domain Name" 
                                                                :
                                                            "Domain Name" 

                                                        }
                                                    </p>
                                                    <p>{d.name}</p>
                                                </div>
                                              </td>
                                              <td className="ip-block">
                                                <div className="primary-ip">
                                                    <p className="site-ip">Pointed to IP</p>
                                                    {/*<p>IP: {getSite?.success?.load_balancer_public_ip}</p>*/}
                                                    <p className={d.domain_ip_address ?"big-span-blue" : ""}>IP: {d.domain_ip_address?d.domain_ip_address :"Not pointed to any DNS"}</p>
                                                </div>
                                              </td>

                                            {event_name === "create_domain" && creatingDomainList && creatingDomainList.length && creatingDomainList.includes(d.id) ?
                                                <>
                                                    <td className="loading-domain">
                                                        <Loading/>
                                                        Adding
                                                    </td>
                                                    <td>
                                                    </td>
                                                </>
                                                :
                                                event_name === 'delete_domain' && creatingDomainList && creatingDomainList === d.id  ?
                                                <>
                                                <td className="loading-domain">
                                                <Loading/>
                                                Deleting
                                                </td>
                                                <td>
                                                </td>
                                                </>
                                                :
                                                    <>
                                              <td className="buttons-block col-lg-12" id={d.is_primary === 1 ? "primary-ip" : "domain-ip"}>
                                                
                                                  {d.is_primary !== 1 && (
                                                      <button id="make-primary-btn" onClick={() => {scrollToError();this.primaryConfirm( d.id )}}
                                                              disabled={ site_status === `pending` || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring'}
                                                              className="btn-theme btn-outline">{getSiteStatus?.success?.event === "update_domain" && getSiteStatus?.success?.domain_id === d.id ? <div>Make Primary <Loading/></div> :"Make Primary"}</button>
                                                  )}
                                                  { getSiteStatus?.success?.domain_id !== d.id ?
                                                        site_status !== `pending` && d.is_primary !== 1 ?
                                                          <span onClick={() => site_status!=='updating' ? this.deleteConfirm( d.id ) : null} >
                                                              <span className="icon-minus" aria-disabled={site_status==='updating' }/>
                                                          </span> :null:null

                                                      }
                                              </td>
                                                <td>
                                                </td>
                                                    </>
                                            }
                                            </tr>
                                    }) : null
                        }
                        </tbody>
                    </table>
                    <ResponsiveModalForm visible={modal.mode}
                                     closeModal={() => store.dispatch(toggleDomainFormModal())}>
                        <DomainForm/>
                    </ResponsiveModalForm>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    let { getSite, getSiteDomain, domainList, siteDashboard ,getSiteStatus } = state;
    let creatingDomainList = getSiteStatus && getSiteStatus.success && getSiteStatus.success.domain_id
    let event_name = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event
    let { site_status } = siteDashboard.data;
    let { success } = getSiteDomain;
    let {  modal } = domainList.data;
    let domainLists = success &&
        success.data &&
        success.data.data &&
        success.data.data.data;
    return { getSite, getSiteDomain,creatingDomainList,event_name, domainLists, domainList, modal, siteDashboard, site_status, getSiteStatus };
}

export default connect(mapStateToProps)(Domain);