import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Loading from "../../../components/shared/loaders/Loading";
import {ucFirst} from "../../../helpers/StringHelper";
import swal from "sweetalert";
import deleteSite from "../../../ajax/actions/sites/site_delete";
import {fromRecord, inRecord} from "../../../helpers/DataHelper";
import ResponsiveModalForm from "../../../components/shared/modals/ResponsiveModalForm";
import DeleteSiteForm from "../delete-site/DeleteSiteForm";
import getSite from '../../../ajax/actions/sites/site_get'

class SiteActionCell extends React.Component {

    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
        this.setCloseModel = this.setCloseModel.bind(this)

    }

    openModal (id) {
        this.setState({
            isOpen: {
                [id]: true
            }
        });
        if(id) {
            store.dispatch(getSite(id))
        }
    }

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this site? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id) {
        store.dispatch(deleteSite(id));
    }

    setCloseModel(){
        this.setState({isOpen:false});
    }

    render() {
        let {org_id, site_id, org_status} = this.props;
        let o_status = '';
        if (inRecord(org_status, 'id', '==', site_id)) {
            o_status = fromRecord(org_status, 'id', '==', site_id).status;
        }

        return (
            <>
                {
                    o_status === `updating` || o_status === `idle` ?
                        <div className="dropdown dropdown-alt text-center">
                    <span className="dropdown-toggle"
                          data-toggle="dropdown">
                        <span className="icon-more"/>
                    </span>
                            <div className="dropdown-menu">
                                <Link
                                    to={`/organizations/${org_id}/sites/${site_id}/launchpad`} className={`link-edit`}>
                                    Edit
                                </Link>


                                <ResponsiveModalForm visible={this.state.isOpen[site_id]}
                                                     closeModal={() => this.setCloseModel()}>
                                    <DeleteSiteForm org_id={org_id} site_id={site_id} setCloseModel={this.setCloseModel}/>
                                </ResponsiveModalForm>


                                <a href="javascript:void(0);" onClick= {
                                    this.openModal.bind(this, site_id)
                                } className={`link-delete`}>
                                    Delete
                                </a>
                            </div>
                        </div>
                        :
                        <div className="text-center position-absolute">{ucFirst(o_status)} <Loading className="ml-2"/>
                        </div>
                }
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
    getSites, getOrganizationStatus, siteList
}

= state;
let {delete_site_modal} = siteList.data;

let {success} = getSites;

let org_status =
    getOrganizationStatus?.success;

let list =
    success &&
    success.data &&
    success.data.data.data;
return {getSites, list, getOrganizationStatus, org_status, delete_site_modal};
}
export default connect(mapStateToProps)(SiteActionCell);
