import axios from 'axios';
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'

function _success(success)
{
  return { type: 'GET_PLAN_SUCCESS', success };
}
function _error(error)
{
  return { type: 'GET_PLAN_ERROR', error };
}
function _processing(processing)
{
  return { type: 'GET_PLAN_PROCESSING', processing };
}

function getPlans( )
{
  return ( dispatch ) =>
  {
    dispatch(_processing(true));
    dispatch(_success(null));
    dispatch(_error(null));
    const lang = localStorage.getItem('lang') || 'en'


    const request = axios({

      url: apiBaseUrl(`admin/plans`),
      method: "get",
      dataType: 'json',
      headers: { 'Authorization': 'Bearer ' +loginToken(),
        'lang': lang}
    })
      .then(function(res){
          dispatch(_success(res.data.data));
          dispatch(_processing( false ));
          // handleSuccess)
      }).catch(function( error ){
          dispatch(_error(error));
          dispatch(_processing(false));
          handleError( error )
      });

    return {
      type: 'GET_PLAN_SUCCESS',
      payload: request
    }
  }
}


export default getPlans;