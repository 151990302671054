import {PlanFormState} from './PlanForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../../helpers/ErrorHelper'
import {isObject} from "../../../../helpers/DataHelper";

export default function planFormReducer(state = PlanFormState, action) {

    let {data} = {...state};
    let {validationErrors, name, value, site_id} = action;
    switch (action.type) {

        case 'ON-PLAN-FORM-CHANGE':
            data.payload[name] = value;
            buildErrors();
            return {...state, data};

        case 'SET-SITE-ID':
            data.site_id = site_id;
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-PLAN-FORM':
            data = {
                payload: {
                    plan_id: ``
                },
                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: ``,
                message: ``
            };
            buildErrors();
            return {...state, data};

        case 'CANCEL-PLAN-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-PLAN-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'PLAN-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            );
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, validationErrors} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            if (field === `plan_id`) {
                if (!_(field)) newError(field, 0, `is required`)
            }
        }

        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}