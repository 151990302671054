import React from 'react';
import store from '../../../Store';
import getDataCenters from "../../../ajax/actions/data-center/data_centers_get";

class DataCenterWorker extends React.Component {
    componentDidMount() {
        store.dispatch(getDataCenters());
    }

    render() {
        return null
    }
}

export default DataCenterWorker;