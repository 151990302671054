import { OrganizationFormState } from './OrganizationForm-State'
import { errorsList, errorsPresent, mergeServerValidationErrors } from '../../../helpers/ErrorHelper'
import { endOfURL } from '../../../helpers/UrlHelper'
import {isObject} from "../../../helpers/DataHelper";
import { slugify } from "../../../helpers/StringHelper";

export default function organizationFormReducer(state = OrganizationFormState, action) {

  let { data } = { ...state };

  let { validationErrors, name, value, organizations, mode, data_type } = action;

  switch (action.type) {

    case 'ON-ORGANIZATION-FORM-CHANGE':
      data.payload[name] = value;
      if( name === 'name' ) {
        data.payload.slug = slugify( value );
      }
      buildErrors();
      return { ...state, data };

    case 'ON-ORGANIZATION-FORM-TYPE-CHANGE':
      data.payload.type = data_type;

      buildErrors();
      return { ...state, data };

    case 'ON-ORGANIZATION-PERMISSIONS-FORM-CHANGE':
      if (name === `search`) {
        data.permissionList =  data.originalPermissionList.filter(
            item => item.display.toLowerCase().includes( value ) || item.display_name.toLowerCase().includes( value )
        )
        data.payload[name] = value;

      } else if (name === `permissions`) {
        if (data.payload[name].includes(value)) {
          data.payload[name] = data.payload[name].filter(item => item !== value);
        } else {
          data.payload[name].push(value)
        }
      }
      return { ...state, data }

    case 'ON-ORGANIZATION-FORM-MODE-CHANGE':
      data.mode = mode;
      buildErrors();
      return { ...state, data };

    case 'SET-ORGANIZATION-DATA-FOR-UPDATE':
      data.payload ={
          name: organizations.name,
          billing_name: organizations.billing_name,
          country_id: organizations.country_id,
          state: organizations.state,
          city: organizations.city,
          address_line_1: organizations.address_line_1,
          address_line_2: organizations.address_line_2,
          zip: organizations.zip,
          type: organizations.type,
          vat_id: organizations.vat_id,
          billable: organizations.billable,
      };
      buildErrors();
      return { ...state, data };

    case 'SET-DEFAULT-ORGANIZATION-FORM':
      data = {
        payload:{
          name: '',
          billing_name: '',
          country_id: '',
          state: '',
          city: '',
          address_line_1: '',
          address_line_2: '',
          zip: '',
          type: 'company',
          vat_id: '',
          billable: `1`,
        },
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode:endOfURL(),
        message: ``
      };
      buildErrors();
      return { ...state, data };

    case 'CANCEL-ORGANIZATION-FORM-DISPATCH':
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case 'ON-ORGANIZATION-FORM-SUBMIT':
      console.log(data, "hej")
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };


    case 'ORGANIZATION-FORM-SERVER-VALIDATION-ERRORS':
      validationErrors && isObject( validationErrors ) &&
      Object.keys(validationErrors).map(key =>
        data.errors[key] = [{ message: ` - ${validationErrors[key][0]}`.slice(0, -1) }]
      );
      data.errorsPresent = true;
      data.dispatchAPI = false;
      return { ...state, data };

    default:
      return state
  }

  function buildErrors()
  {
    data.errors = errorsList(errors)
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field)
  {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
      if (field === `name`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }
      // if (field === `billing_name`) {
      //   if (!_(field)) newError(field, 0, `This is required field`)
      // }
      if (field === `country_id`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }
      if (field === `state`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }
      if (field === `city`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }
      if (field === `address_line_1`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }
      if (field === `zip`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }
      if (field === `type`) {
        if (!_(field)) newError(field, 0, `Choose either company or individual`)
      }
      if(payload.type === `company`) {
        if (field === `vat_id`) {
          if (!_(field)) newError(field, 0, `This is required field`)
        }
      }
    }

    errors = mergeServerValidationErrors(errors, validationErrors)

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

