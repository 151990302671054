import React from 'react';
import store from '../../../Store';
import getUsers from '../../../ajax/actions/user/users_get'

class UserWorker extends React.Component {


componentDidMount()
{
  store.dispatch(getUsers());
}

  render() {
    return null
  }
}


export default  UserWorker;