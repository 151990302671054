import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultOrganizationFormState = {
  payload:{
    name: '',
    billing_name: '',
    country_id: '',
    state: '',
    city: '',
    address_line_1: '',
    address_line_2: '',
    zip: '',
    type: 'company',
    vat_id: '',
    billable: `1`,

  },
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ``
};

export const OrganizationFormState = { data: DefaultOrganizationFormState };