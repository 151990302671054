import axios from 'axios'
import { apiBaseUrl } from '../../../helpers/UrlHelper'
import {loginToken, logOut} from '../../../helpers/LocalStorageHelper'
import { handleError, handleSuccess } from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {scrollToError} from "../../../helpers/ScrollHelper";
import {
  cancelEnable2FaDispatch,
  enable2FaServerValidationErrors
} from "../../../pages/two-factor-authentication/Enable2Fa-Action";
import logoutUser from "../user/user_logout_post";

function _success(success) {
  return { type: 'ENABLE_2FA_SUCCESS', success }
}

function _error(error) {
  return { type: 'ENABLE_2FA_ERROR', error }
}

function _processing(processing) {
  return { type: 'ENABLE_2FA_PROCESSING', processing }
}

function enable2FA() {
  return (dispatch,getState) => {

    let {enable2faForm} = getState();
    dispatch(_success(null))
    dispatch(_error(null))
    dispatch(_processing(true))
    let {payload} = enable2faForm.data

    axios({
      url: apiBaseUrl(`admin/enable-2fa`),
      method: 'post',
      dataType: 'json',
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    })
        .then(function(res) {
          dispatch(_success(res))
          dispatch(_processing(false))
          handleSuccess(res)
          store.dispatch(cancelEnable2FaDispatch())
          store.dispatch( logoutUser( ));
              logOut();

        }).catch(function(error) {
      dispatch(_error(error))
      dispatch(_processing(false))
      handleError(error)
      // store.dispatch(cancelEnable2FaDispatch())
      if (error.response && error.response.status === 422) {
        if(error.response.data.message === "Two Factor Authentication Already Enabled."){
          handleError(error.response.data)
        }
        store.dispatch(enable2FaServerValidationErrors(error.response.data.errors))
        scrollToError()
      }

    })
  }
}

export default enable2FA
