import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
    return {type: 'GET_ORGANIZATIONS_NOPAGINATION_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_ORGANIZATIONS_NOPAGINATION_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_ORGANIZATIONS_NOPAGINATION_PROCESSING', processing};
}

function getOrganizations() {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_error(null));


        const request = axios({

            url: apiBaseUrl(`admin/organizations?paginate=0&&sort_by=name&&sort_order=asc`),
            method: "get",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`)
            }
        }).then(function (res) {
            dispatch(_processing(false));
            dispatch(_success(res));

        }).catch(function (error) {
            handleError(error)
            dispatch(_error(error));
            dispatch(_processing(false));
        });

        return {
            type: 'GET_ORGANIZATIONS_NOPAGINATION_SUCCESS',
            payload: request
        }
    }
}

export default getOrganizations;
