const DefaultLogsState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    // type:`error`,
    per_page: 25,
    page:   1,
    order:  'desc',
    sort_id: `id`,
    q: ``,
    // mode:`error`
    type:`error`
  },
  site_id:``,
  fields: [
    // { field: `id`, label: `ID`, sort: true },
    // { field: `site_id`, label: `Site ID`, sort: true },
    // { field: `type`, label: `Backup Type`, sort: true },
    // { field: `created_at`, label: `Created At`, sort: true },
    // { field: `expiry_date`, label: `Expiry Date`, sort: true },
    // { field: `remarks`, label: `Description`, sort: true },
    // {field: `action`, label: `Action`, sort: true},
  ]
}

export const LogsState = {data:DefaultLogsState}