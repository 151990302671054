import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import DiscountFormWorker from './DiscountFormWorker'
import {onDiscountFormChange, onDiscountFormSubmit} from "./DiscountForm-Action";
import TextField from "../../../components/shared/form/TextField";
import Loading from "../../../components/shared/loaders/Loading";
import SelectField from "../../../components/shared/form/SelectField";
import {activeStatusStringFilter,currencyFilter} from "../../../static-data/UserData";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Link from "react-router-dom/Link";
import {endOfURL} from "../../../helpers/UrlHelper";
import Page from "../../../components/shared/pages/Page";

class DiscountForm extends React.Component {
    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    DiscountFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onDiscountFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onDiscountFormChange(e.target.name, e.target.value,));
    };

    render() {
        let {
            discountForm, updateDiscount, createDiscount,
            discount_percentage, minimum_amount, status, currency
        } = this.props;
        let {errors} = discountForm.data;
        let processing = updateDiscount.processing || createDiscount.processing;

        return (

            <>
                <Page title={endOfURL() === "create" ?
                    "Add Discount" :
                    "Update Discount"
                } processing={processing}>
                    <div className="heading-block pr-60">
                        <div className="title-block">
                            <h2> {endOfURL() === 'create' ? 'Add new Discount' : 'Update Discount'}</h2>
                            <span><Link to="/discounts">Discounts</Link></span>
                            <span>{endOfURL() === 'create' ? `Add new Discount` : `Update`}</span>
                        </div>
                    </div>
                    <DiscountFormWorker/>
                    <div className="box-block">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="pills-add-user" role="tabpanel"
                                 aria-labelledby="pills-add-user-tab">
                                <div className="padded-block">
                                    <form onSubmit={this.DiscountFormSubmit}>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField name="discount_percentage"
                                                               value={discount_percentage}
                                                               label="Discount Percentage"
                                                               errors={errors.discount_percentage}
                                                               onChange={this.onChange}/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField name="minimum_amount"
                                                               value={minimum_amount}
                                                               label="Minimum Amount"
                                                               errors={errors.minimum_amount}
                                                               onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <SelectField name="status"
                                                                 value={status}
                                                                 options={activeStatusStringFilter}
                                                                 errors={errors.status}
                                                                 onChange={this.onChange}
                                                                 _value="value"
                                                                 _label="name"/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <SelectField name="currency"
                                                                 value={currency}
                                                                 options={currencyFilter}
                                                                 errors={errors.currency}
                                                                 onChange={this.onChange}
                                                                 _value="value"
                                                                 _label="name"/>
                                                </div>
                                            </div>
                                        </div>
                                                <div className="button-wrapper inline-buttons text-center d-flex justify-content-end" id="form-submit-btn">
                                                    <button type="submit"
                                                            className="btn-theme btn-dark-blue">
                                                        {processing ? <Loading/> : `Submit`}
                                                    </button>

                                            </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>
            </>

        )
    }
}

function mapStateToProps(state) {
    let {discountForm, updateDiscount, createDiscount} = state;

    let {errors, payload, mode} = discountForm.data;

    let {
        discount_percentage, minimum_amount, status, currency
    } = payload;

    return {
        discountForm, updateDiscount, createDiscount, errors,currency,
        discount_percentage, minimum_amount, mode, status
    };
}

export default connect(mapStateToProps)(DiscountForm);