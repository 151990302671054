const DefaultInternalMigrationFormState = {
    payload: {
        server_id: '',
        change_loadbalancer: 1,
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: ``,
    message: ``,
    site_id: ``,
};

export const InternalMigrationFormState = {data: DefaultInternalMigrationFormState};