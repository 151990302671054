import React from 'react';
import store from '../../../Store';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {  toggleAllOrganizationUserListFilter } from './UserList-Actions'
import {unslufigy} from "../../../helpers/StringHelper";
import {Link} from "react-router-dom";
import Button from "../../../components/shared/buttons/Button";

class OrganizationUserListFilters extends React.Component
{

  toggleFilter = (e) =>
  {
    store.dispatch(toggleAllOrganizationUserListFilter( e, this.props.history));
  }

  render()
  {
    let { title, params , organization_id } = this.props;

    return (
        <div className="heading-block pr-60">
          <div className="title-block">
              <h2>{"Users"}</h2>
          </div>
          <div className="search-bar">
              <input type="text"
                     className="form-control"
                     name="q"
                     placeholder= {`Search ${unslufigy(title)}`}
                     value={ params.q }
                     onChange={this.toggleFilter}
              />
            <span className="icon-search"/>
          </div>

          {/*<div className="button-wrapper">*/}
          {/*  <Link to={`/organizations/${organization_id}/users/create`}>*/}
          {/*    <Button className="btn-theme btn-dark-blue"*/}
          {/*            title={`Add User`}*/}
          {/*            text={`Add User`} />*/}
          {/*  </Link>*/}
          {/*</div>*/}
        </div>

    )
  }
}

function mapStateToProps(state)
{
  let { allOrganizationUsersList, getAllOrganizationUsers} = state;
  let {params} = allOrganizationUsersList.data;

  return {   allOrganizationUsersList, getAllOrganizationUsers , params};
}
export default withRouter( connect( mapStateToProps )( OrganizationUserListFilters ));