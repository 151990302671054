import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken, logOut} from '../../../helpers/LocalStorageHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
    return {type: 'GET_SUPER_ADMIN_SITE_STATUS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_SUPER_ADMIN_SITE_STATUS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_SUPER_ADMIN_SITE_STATUS_PROCESSING', processing};
}

function getSuperAdminSiteStatus() {
    return (dispatch) => {

        dispatch(_processing(true));

        axios({
            url: apiBaseUrl(`admin/sites/status`),
            method: "get",
            dataType: 'json',
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                dispatch(_success(res.data.data));
                dispatch(_processing(false));

            }).catch(function (error) {
            if (error && error.response &&
                error.response.status === 401) {
                logOut()
            } else {
                handleError(error)
                dispatch(_error(error));
                dispatch(_processing(false));
            }
        });
    }
}

export default getSuperAdminSiteStatus;