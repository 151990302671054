export default function createOrganizationReducer
    (state = { error:'', success:'', processing:false}, action)
{
    switch (action.type) {
        case 'CREATE_ORGANIZATION_SUCCESS':
            return {...state, ...{success: action.success}};

        case 'CREATE_ORGANIZATION_ERROR':
            return {...state, ...{error: action.error}};

        case 'CREATE_ORGANIZATION_PROCESSING':
            return {...state, ...{processing: action.processing}};

        default: return state;
    }
}