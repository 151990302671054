import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Link} from "react-router-dom";
import SiteActionCell from "./SiteActionCell";

class SiteTableCell extends React.Component {
    onclick(organization_id, site_id) {
        this.props.history.push(`/organizations/${organization_id}/sites/${site_id}/launchpad`)
    }

    render() {
        let {list, org_status} = this.props;

        return (
            list?.length ? list.map((item, key) => {

                    const {id, created, display_name, organization_id, data_center_name, status, plan} = item
                    const organization_status = org_status?.map(d => d.id === id && d.status)
                    const isUpdating = organization_status?.includes("updating");
                    const isIdle = organization_status?.includes("idle");
                    const isRestoring = organization_status?.includes("restoring");
                    const isMigrating = organization_status?.includes("migrating");
                    const deactivatedStatus = ["deleting", "creating"]
                    const isSiteDeactivated = deactivatedStatus.includes(status)
                    const buttonClass = isSiteDeactivated ? "deactive" : "onclick"

                const siteIds = org_status?.map(d => d.id === id && d.id)


                    if(siteIds?.includes(id)) {
                        //if notification comes of delete than you dont have to check id to remove deleted sites
                        return (
                            <tr key={key}>
                                <td className={buttonClass}
                                    onClick={() =>
                                        (created && isUpdating)
                                        || isIdle || isMigrating || isRestoring
                                            ? this.onclick(organization_id, id) : "#"}>
                                    {(created && isUpdating) || isIdle || isMigrating || isRestoring
                                        ?
                                        <Link to={`/organizations/${organization_id}/sites/${id}/launchpad`}>
                                            {display_name}
                                        </Link> :
                                        display_name}
                                </td>
                                <td className={buttonClass}
                                    onClick={() => (created && isUpdating) || isIdle || isMigrating || isRestoring
                                        ? this.onclick(organization_id, id) : "#"}>
                                    {data_center_name}
                                </td>

                                <td className={buttonClass}
                                    onClick={() => (created && isUpdating) || isIdle || isMigrating || isRestoring
                                        ? this.onclick(organization_id, id) : "#"}>
                                    {plan ? plan.description : "-"}
                                </td>

                                <td className="text-center">
                                    <SiteActionCell
                                        org_id={organization_id}
                                        site_id={id}
                                        status={status}
                                    />
                                </td>
                            </tr>
                        )
                    }

                })
                : <tr>
                    <td colSpan={4} className="text-center">No Sites Available</td>
                </tr>
        )
    }
}

function mapStateToProps(state) {
    let {getSites, getOrganizationStatus} = state;
    let {success} = getSites;

    let org_status =
        getOrganizationStatus?.success;

    let list =
        success?.data.data.data;


    return {getSites, list, getOrganizationStatus, org_status};
}

export default withRouter(connect(mapStateToProps)(SiteTableCell));
