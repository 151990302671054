const DefaultMigrationTrackingState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 25,
        page: 1,
        order: 'desc',
        // sort_by: `id`,
        q: ``,
    },
    fields: [
        {field: `site_name`, label: `Site Name`, sort: false},
        {field: `source_server_ip`, label: `Source Server IP`, sort: false},
        {field: `source_lb_ip`, label: `Source LB IP`, sort: false},
        {field: `destination_server_ip`, label: `Destination Server IP`, sort: false},
        {field: `destination_lb_ip`, label: `Destination LB IP`, sort: false},
        {field: `old_container_deleted`, label: `Old Container Deleted`, sort: false},
        {field: `backup_deleted`, label: `Backup Deleted`, sort: false},
        {field: `client_verified`, label: `Client Verified`, sort: false},
        {field: `status`, label: `Status`, sort: false},
        {field: `action`, label: `Action`, sort: false},
    ]
}

export const MigrationTrackingState = {data: DefaultMigrationTrackingState}