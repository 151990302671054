import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import BugFormWorker from "./BugFormWorker";
import {ucFirst} from "../../../helpers/StringHelper";
import TextField from "../../../components/shared/form/TextField";
import Page from "../../../components/shared/pages/Page";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import {endOfURL} from "../../../helpers/UrlHelper";
import withRouter from "react-router/withRouter";
import active from "../../../assets/images/active.svg";
import Link from "react-router-dom/Link";
import {onBugFormChange, onBugFormModeChange} from "./BugForm-Action";
import updateBugStatus from "../../../ajax/actions/support/bug_status_put";
import swal from "sweetalert";
import deleteBug from "../../../ajax/actions/support/bug_delete";
import {onClickTag} from "../list/Bug-Actions";

class BugView extends React.Component {

    componentDidMount() {
        if (this.props.match.params.id || endOfURL() === "view") {
            document.querySelector('body').classList.add('organization-dashboard');
            store.dispatch(setDashboardMenu(''));
        }
    }

    componentWillUnmount() {
        if (this.props.match.params.id || endOfURL() === "view") {
            document.querySelector('body').classList.remove('organization-dashboard');
        }
    }

    onClickViewImage = (data) => {
        var image = new Image();
        image.src = URL.createObjectURL(data)
        var w = window.open("");
        w.document.write(image.outerHTML);
    }

    onChangeStatus = (status,id) => {
        store.dispatch(onBugFormChange("status", status));
        store.dispatch(updateBugStatus(id));
    }

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this bug ? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id)
    {
        store.dispatch(deleteBug(id));
        this.props.history.push('/bugs');
    }

    updateBug=(id)=>{
        store.dispatch(onBugFormModeChange('update'))
        this.props.history.push(`/bugs/${id}/update`)
    }

    onClickTags=(item)=>{
        this.props.history.push(`/internal-support`)
        store.dispatch(onClickTag("tag",item))
    }

    render() {
        let {
            getBug, payload, organizationName, siteName, reporter,bug_id
        } = this.props;
        let {processing} = getBug

        let {tags, imageLinks} = payload

        return (
            <>
                <BugFormWorker/>
                <Page title="Bug Details" processing={processing}>
                    <>
                        <div className="heading-block pr-60">
                            <div className="title-block">
                                <h2>Bug Details</h2>
                                <span><Link to="/bugs">Bugs</Link></span>
                                <span> Bug Details</span>
                            </div>
                        </div>
                        <div className="box-block-alt pl-4 pr-3">
                            <form className="bug-form">
                                <div className="row">
                                    <div className="col-xl-7 col-md-7">

                                        <div className="form-group">
                                            <TextField
                                                name="summary"
                                                label="Summary"
                                                value={payload.summary}
                                                disabled
                                            />
                                        </div>

                                        <div className="form-group pb-4">
                                            <div className="form-group">
                                                <label>Description</label>
                                                <div
                                                    dangerouslySetInnerHTML={{__html: payload.description}}/>
                                            </div>
                                        </div>


                                        <div className="form-group">
                                            <TextField
                                                name="other_links"
                                                label="Other Links"
                                                value={payload.other_links}
                                                disabled
                                            />
                                        </div>

                                        {imageLinks && imageLinks.map((i, index) => (
                                            <div className="bug-image pt-5" key={i}>
                                                <img alt="status" src={active}/> Uploaded_Image_{index} &nbsp;&nbsp;
                                                <a href={i.path} target="_blank"><u>Open in new tab</u></a>
                                            </div>
                                        ))
                                        }

                                        <div className="button-wrapper d-flex flex-wrap mt-5">
                                            <button onClick={()=>this.updateBug(bug_id)} type="reset" className="btn-theme btn-dark-blue ml-3 mb-4">
                                                Update
                                            </button>
                                            <button onClick={()=>this.onChangeStatus("reassigned",bug_id)} type="reset" className="btn-theme btn-dark-blue ml-3">
                                                Reassign
                                            </button>
                                            {payload.status !== "completed" &&
                                            <button onClick={() => this.onChangeStatus("completed", bug_id)}
                                                    type="reset" className="btn-theme btn-success ml-3">
                                                Complete
                                            </button>
                                            }
                                            <button onClick={()=>this.deleteConfirm(bug_id)}  type="reset"  className="btn-theme btn-danger ml-3">
                                                Delete
                                            </button>
                                        </div>

                                    </div>

                                    <div className="col-xl-4 offset-xl-1 col-md-5">

                                        <div className="form-group">
                                            <a className={payload.status === "completed"? "bugs-completed blue-theme":"bugs-status blue-theme"}>{ucFirst(payload.status) || "-"}</a>
                                        </div>

                                        <label>Tags</label>
                                        <div className="form-group td-with-array">
                                            <ul className="tags">
                                                {tags && tags.map(item => (
                                                    <li><a className="tag onclick" onClick={()=>this.onClickTags(item)}>{item}</a></li>
                                                ))}
                                            </ul>
                                        </div>



                                        <div className="form-group">
                                            <TextField
                                                name="reporter_id"
                                                label="Reporter"
                                                value={reporter}
                                                disabled
                                            />
                                        </div>


                                        <div className="form-group">
                                            <TextField
                                                name="urgency"
                                                label="Urgency"
                                                value={ucFirst(payload.urgency)}
                                                disabled
                                            />
                                        </div>

                                        <div className="form-group">
                                            <TextField
                                                name="priority"
                                                label="Priority"
                                                value={ucFirst(payload.priority)}
                                                disabled
                                            />
                                        </div>

                                        <div className="form-group">
                                            <TextField
                                                name="assigned_to"
                                                label="Assigned To"
                                                value={payload.assigned_to}
                                                disabled
                                            />
                                        </div>

                                        <div className="form-group">
                                            <TextField
                                                name="organization_id"
                                                label="Organization"
                                                value={organizationName}
                                                disabled
                                            />
                                        </div>

                                        {payload.site_id &&
                                        <div className="form-group">
                                            <TextField
                                                name="site_id"
                                                label="Site"
                                                value={siteName}
                                                disabled
                                            />
                                        </div>
                                        }

                                        <div className="form-group">
                                            <TextField
                                                name="remarks"
                                                label="Remarks"
                                                value={payload.remarks}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </>
                </Page>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        getBug,
        bugForm,
        updateBugStatus,
        createBug,
        updateBug,
        getOrganizationsWithoutPagination,
        getSitesWithoutPagination,
        getLoggedInUser,
        getAllUsersWithoutPagination
    } = state;

    let {id, first_name} =
        getLoggedInUser?.success?.data?.data

    let {payload, mode, view, submitted} = bugForm.data

    let allUsers = getAllUsersWithoutPagination
        && getAllUsersWithoutPagination.success
        && getAllUsersWithoutPagination.success.data
        && getAllUsersWithoutPagination.success.data.data

    let organizations = getOrganizationsWithoutPagination
        && getOrganizationsWithoutPagination.success
        && getOrganizationsWithoutPagination.success.data
        && getOrganizationsWithoutPagination.success.data.data

    let sites = getSitesWithoutPagination
        && getSitesWithoutPagination.success
        && getSitesWithoutPagination.success.data
        && getSitesWithoutPagination.success.data.data

    let organizationName = getBug && getBug.success && getBug.success.internal_bug && getBug.success.internal_bug.organization && getBug.success.internal_bug.organization.name
    let siteName = getBug && getBug.success && getBug.success.internal_bug && getBug.success.internal_bug.site && getBug.success.internal_bug.site.name
    let reporter = getBug && getBug.success && getBug.success.internal_bug && getBug.success.internal_bug.reporter
    let bug_id = getBug && getBug.success && getBug.success.internal_bug && getBug.success.internal_bug.id

    return {
        getBug,
        bug_id,
        organizationName,
        siteName,
        reporter,
        payload,
        updateBugStatus,
        mode,
        view,
        createBug,
        updateBug,
        bugForm,
        organizations,
        sites,
        submitted,
        id, first_name,
        allUsers
    };
}

export default withRouter(connect(mapStateToProps)(BugView));