import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
    return {type: 'GET_LOAD_BALANCERS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_LOAD_BALANCERS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_LOAD_BALANCERS_PROCESSING', processing};
}

function getLoadBalancers() {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_error(null));

        let {loadBalancerList} = getState();
        let {params} = loadBalancerList.data;

        axios({
            url: apiBaseUrl(`admin/loadbalancers`),
            method: "get",
            dataType: 'json',
            params,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`)
            }
        }).then(function (res) {
            dispatch(_processing(false));
            dispatch(_success(res));

        }).catch(function (error) {
            handleError(error)
            dispatch(_error(error));
            dispatch(_processing(false));
        });
    }
}

export default getLoadBalancers;
