import {DashboardSitesListState} from './DashboardList-State'

export default function dashboardReducer(state = DashboardSitesListState, action) {

    let {data} = {...state}
    let {name, value,search_status,date} = action;
    switch (action.type) {

        case 'TOGGLE-DASHBOARD-LIST-FILTER':

            data.params[name] = value;
            if (name === `sort_by`) {
                if (data.params.sort_by === value) {
                    data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
                }
            }
            let toggle = true;
            if (toggle) {
                if (name === 'limit') {
                    data.params.page = 1;
                }
                data.params[name] = value;
            }
            return {...state, data}

        case 'SET-DASHBOARD-SEARCH':
            data.dashboard_search = search_status;
            return {...state, data}

        case 'SET-DATE':
                data.params[name] = date;
            return {...state, data}

        case 'SET-DEFAULT-SEARCH':
            data.params.q = value;
            return {...state, data}
        default:
            return state
    }
}
