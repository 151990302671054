
const DefaultAllOrganizationUserListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    sort_order:  'desc',
    // sort_id: `id`,
    sort_by: ``,
    q: ``,
  },
  organization_id:``,
  fields: [
    // { field: `id`, label: `ID`, sort: true },
    { field: `first_name`, label: `Name`, sort: true },
    { field: `email`, label: `Email`, sort: true },
    { field: `role`, label: `Role`, sort: true },
    { field: `action`, label: `Action`, sort: false },
  ]
}

export const AllOrganizationUserListState = {data:DefaultAllOrganizationUserListState}