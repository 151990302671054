// export const loginToken = () => localStorage.getItem('Bearer');
//
// /* Find out if anyone is logged in | @return boolean */
// export const loggedIn   = () => !! localStorage.getItem('Bearer');
//
// export const loggedInUser = (data) =>
// {
//   localStorage.setItem('Bearer',          data.access_token);
//   localStorage.setItem('refresh_token',   data.refresh_token);
//   localStorage.setItem('expires_in',      data.expires_in);
//   localStorage.setItem('token_type',      data.token_type);
// };
//
// export const logOut = ( history ) =>
// {
//   localStorage.removeItem('Bearer');
//   if ( history ) history.push(`/login`);
//   else window.location.href = ('/login');
// };

export const loginToken = () => localStorage.getItem('Bearer');

/* Find out if anyone is logged in | @return boolean */
export const loggedIn   = () => !! localStorage.getItem('Bearer');

export const loggedInUser = (data) =>
{
  localStorage.setItem('Bearer',          data);
  // localStorage.setItem('refresh_token',   data.refresh_token);
  // localStorage.setItem('expires_in',      data.expires_in);
  // localStorage.setItem('token_type',      data.token_type);
};

/*export const accessToken = (data) =>
{
  localStorage.setItem('access_token',data);
};

export const removeAccessToken = () =>
{
  localStorage.removeItem('access_token');
};*/


export const logOut = ( history ) =>
{
  localStorage.removeItem('Bearer');
  if ( history ) history.push(`/login`)
  else window.location.href = ('/login');
};

