import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken, logOut} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'FETCH_ALL_ORGANIZATION_USERS_WITHOUT_PAGINATION_SUCCESS', success};
}

function _error(error) {
    return {type: 'FETCH_ALL_ORGANIZATION_USERS_WITHOUT_PAGINATION_ERROR', error};
}

function _processing(processing) {
    return {type: 'FETCH_ALL_ORGANIZATION_USERS_WITHOUT_PAGINATION_PROCESSING', processing};
}

function getAllOrganizationUsersWithoutPagination() {
    return (dispatch) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        const request = axios({

            url: apiBaseUrl(`admin`),
            method: "get",
            dataType: 'json',
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
            }).catch(function (error) {

                if (error && error.response &&
                    error.response.status === 401) {
                    logOut()
                } else {
                    handleError(error)
                    dispatch(_error(error));
                    dispatch(_processing(false));
                }
            });

        return {
            type: 'FETCH_ALL_ORGANIZATION_USERS_WITHOUT_PAGINATION_SUCCESS',
            payload: request
        }
    }
}


export default getAllOrganizationUsersWithoutPagination;