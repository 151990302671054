import React from 'react';
import DiscountWorker from "./DiscountWorker";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import TableHead from "../../../components/shared/table/TableHead";
import store from "../../../Store";
import {toggleDiscountListFilter} from "./Discount-Action";
import swal from "sweetalert";
import deleteDiscount from "../../../ajax/actions/discount-slab/discount_delete";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import DiscountHeader from "./DiscountHeader";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import Status from '../../../components/shared/status/Status';

class Discount extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }


    toggleSort = (field) => {
        store.dispatch(toggleDiscountListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this discount? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id) {
        store.dispatch(deleteDiscount(id));
    }

    render() {
        let {fields, params, pages, list, getDiscounts} = this.props;
        let {processing, error} = getDiscounts;
        if (error) {
            this.props.history.push('/');
        }
        return (
            <Page title="Discounts" processing={processing}>

                <DiscountWorker/>

                <DiscountHeader title="discounts"/>

                <div className="table-block  pr-60">

                    <table className="table table-striped  ">

                        <TableHead fields={fields}
                                   params={params}
                                   noActions={true}
                                   onClick={this.toggleSort}/>

                        <tbody>
                        {processing ? <td colSpan="4" className="text-center"><Loading/></td> :
                            list && list.length ? list.map((l, key) => {
                                return (
                                    <tr key={key}>
                                        <td>
                                            {l.discount_percentage}%
                                        </td>
                                        <td>
                                            {/*{currencyFormat(l.minimum_amount)} {l.currency}*/}
                                            {parseFloat((l.minimum_amount).toString())} {l.currency}
                                        </td>
                                        <td>
                                            {/* <Badge round type={l.status === 0 ? 'danger' : 'success'}>
                                                {l.status === 0 ? 'Inactive' : 'Active'}
                                            </Badge> */}
                                            <Status status={l.status}/>
                                        </td>
                                        <td>
                                            <div className="dropdown dropdown-alt text-center">
                                                    <span className="dropdown-toggle"
                                                          data-toggle="dropdown">
                                                        <span className="icon-more"/>
                                                    </span>
                                                <div className="dropdown-menu">
                                                    <Link to={`/discounts/${l.id}/update`}>
                                                        <span className=" dropdown-item">Edit</span>
                                                    </Link>
                                                    <a href="/#"
                                                       onClick={ev => {this.deleteConfirm(l.id);ev.preventDefault()}}
                                                       className={`link-delete`}>
                                                        Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : <tr>
                                <td colSpan="7" className="text-center">No Discount Available</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {list && list.length ? (
                    <div className="fixed-footer pr-60">
                        <PaginationButtons pages={pages}
                                           processing={processing}
                                           fetchAction={toggleDiscountListFilter}/>
                    </div>
                ) : null}

            </Page>
        )
    }
}

function mapStateToProps(state) {
    let {discountList, getDiscounts} = state;

    let {params, fields} = discountList.data;

    let {success} = getDiscounts;

    let list =
        success &&
        success.data

    let current_page = success && success.current_page;
    let last_page = success && success.last_page;
    let total = success && success.total;
    let pages = {current_page, last_page, total};

    return {params, fields, pages, getDiscounts, list};
}

export default connect(mapStateToProps)(Discount);