import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'GET_PERMISSIONS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_PERMISSIONS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_PERMISSIONS_PROCESSING', processing};
}

function getPermissions() {
    return (dispatch) => {
        // let params = clone( getState().permissionList.data.params );
        // if ( limit ) params.limit = limit;

        dispatch(_error(null));
        dispatch(_processing(true));

        const request = axios({

            url: apiBaseUrl(`admin/permissions`),
            method: "get",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error)

            });

        return {
            type: 'GET_PERMISSIONS_SUCCESS',
            payload: request
        }
    }
}

export default getPermissions;