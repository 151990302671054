import React from 'react';
import store from '../../../Store';
import withRouter from "react-router/withRouter";
import getSuperAdminSites from "../../../ajax/actions/sites/super_admin_sites_get";
import {connect} from "react-redux";
import getServerModules from "../../../ajax/actions/server-module/server_modules_get";
import getPermissions from "../../../ajax/actions/permission/permissions_get";
import getLoggedInUser from "../../../ajax/actions/user/user_loggedin_get";
import getAdminSiteStatus from "../../../ajax/actions/sites/superadmin_site_status_get";
import getOrganizations from "../../../ajax/actions/organization/organizations_block_get";
import {setDashboardSearch} from "../../dashboard/Dashboard-Actions";
import { setDefaultSearch } from "./SiteList-Actions";


class SiteWorker extends React.Component
{
  componentDidMount() {

    store.dispatch(getServerModules());
    store.dispatch(getSuperAdminSites());
    store.dispatch(getPermissions());
    store.dispatch(getLoggedInUser());
    store.dispatch(getOrganizations());
    store.dispatch(getAdminSiteStatus());
    store.dispatch(setDashboardSearch("false"));
    store.dispatch(setDefaultSearch());
    this.periodicFetch();
  }
  componentDidUpdate(prevProps) {
    if(this.props.getOrganizationStatus !== prevProps.getOrganizationStatus)
    {
      store.dispatch(getAdminSiteStatus());
    }
  }

  componentWillUnmount()
  {
    clearInterval( this.timer );
  }

  periodicFetch()
  {
    this.timer = setInterval(() => !this.props.getAdminSiteStatus.processing && store.dispatch( getAdminSiteStatus( )) , 10000)
  }

  render()
  {
      return null
  }
}

function mapStateToProps( state ) {
  let { getServerModules , getAdminSiteStatus , getSuperAdminSites, getOrganizationStatus } = state;
  return { getServerModules, getAdminSiteStatus , getSuperAdminSites, getOrganizationStatus }
}
export default withRouter(connect(mapStateToProps)(SiteWorker));
