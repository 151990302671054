import React from 'react';
import store from '../../../Store';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    onServerChange,
    onServerReset,
    toggleSiteListFilter
} from './SiteList-Actions'
import {unslufigy} from "../../../helpers/StringHelper";
import {Link} from "react-router-dom";
import Button from "../../../components/shared/buttons/Button";
import Select from "react-select";

class SuperAdminListFilters extends React.Component
{
  componentDidMount()
  {

    // store.dispatch(setDefaultServerModuleFiltersToURL(this.props.history));
  }

  toggleFilter = (e) =>
  {
    store.dispatch(toggleSiteListFilter( e, this.props.history));
  }

    handleServer = (selectedOption) => {
        if( selectedOption ) {
            store.dispatch(onServerChange(selectedOption.value));
        }else {
            store.dispatch(onServerReset());
        }
    }

  render()
  {
    let { title ,servers, params } = this.props;
      let clearable = true;

      let serverList = [];
      servers && servers.map(server => {
          serverList.push({value:server.id, label:server.name})
      });

    return (
        <div className="heading-block pr-60">
          <div className="title-block">
            <h2>{ unslufigy(title) }</h2>
          </div>
          <div className="search-bar">
            <input type="text"
                   className="form-control"
                       name="q"
                   placeholder= {`Search ${unslufigy(title)}`}
                       value={params.q}
                       onChange={this.toggleFilter}
            />
            <span className="icon-search"/>
          </div>
          {/*<div className="select-block">*/}
          {/*  <SelectField name="limit"*/}
          {/*               _value="total"*/}
          {/*               _label="label"*/}
          {/*               allowClear*/}
          {/*               labelIcon="list"*/}
          {/*               options={ limitOptions }*/}
          {/*               onChange={this.toggleFilter}*/}
          {/*               value={urlParamValue(`limit`)}*/}
          {/*  />*/}
          {/*</div>*/}

            <div className="select-block" id="filter-datacenter">
                {/*<label>Select Data center</label>*/}
                <Select
                    name="server_id"
                    isClearable={clearable}
                    value={serverList.id}
                    options = {serverList}
                    onChange={this.handleServer}
                    placeholder="Filter Datacenter"
                />

            </div>

            <div className="button-wrapper">
                    <Link to={`/organizations`}>
                        <Button className="btn-theme btn-dark-blue"
                                title={`Deploy ${unslufigy(title)}`}
                                text={`Deploy ${unslufigy(title)}`}/>
                    </Link>
                </div>
                
        </div>

    )
  }
}

function mapStateToProps(state)
{
  let {   superAdminSiteList ,getServerModules} = state;
    let servers =
        getServerModules &&
        getServerModules.success &&
        getServerModules.success.data &&
        getServerModules.success.data.data &&
        getServerModules.success.data.data.data;

  let {params, server_id} = superAdminSiteList.data;

  return {  superAdminSiteList, params ,servers, server_id,getServerModules};
}
export default withRouter( connect( mapStateToProps )( SuperAdminListFilters ));