export function dataCenterFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'DATA-CENTER-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onDataCenterFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-DATA-CENTER-FORM-SUBMIT'})
    }
}

export function onDataCenterFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-DATA-CENTER-FORM-CHANGE', name, value});
    }
}

export function onDataCenterFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-DATA-CENTER-FORM-MODE-CHANGE', mode});
    }
}

export function cancelDataCenterFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-DATA-CENTER-FORM-DISPATCH'});
    }
}

export function setDefaultDataCenterForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-DATA-CENTER-FORM'});
    }
}

export function setDataCenterDataForUpdate() {
    return function (dispatch, getState) {
        let {getDataCenter} = getState();
        let dataCenter = getDataCenter && getDataCenter.success;
        dispatch({type: 'SET-DATA-CENTER-DATA-FOR-UPDATE', dataCenters: JSON.parse(JSON.stringify(dataCenter))});
    }
}