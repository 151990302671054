import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultMigrationForm,
    setSiteId, setMigrationDataForUpdate, setMigrationDataForView
} from "./MigrationForm-Action";
import updateMigrationStatus from "../../../ajax/actions/migration/manual_migrate_status_put";

class RedirectsFormWorker extends React.Component {

    constructor(props) {
        super(props);
        // store.dispatch(setDefaultMigrationForm())
        store.dispatch(setSiteId(this.props.match.params.id))
        if (this.props.mode === 'create') {
            store.dispatch(setDefaultMigrationForm())
        }
        if (this.props.mode === 'viewMigration') {
            store.dispatch(setMigrationDataForView())
        }
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode} = this.props;

        if (this.props.getMigration !== prev.getMigration) {
            let {success, error} = this.props.getMigration;
            if (success && mode === "update") {
                store.dispatch(setMigrationDataForUpdate());
            } else {
                store.dispatch(setMigrationDataForView());
            }

            if (error) {
                //
            }
        }
        if (dispatchAPI) {
            // if (mode === 'update') {
                let {id} = this.props.getMigration
                && this.props.getMigration.success
                && this.props.getMigration.success.data
                && this.props.getMigration.success.data.data
            if(id) {
                store.dispatch(updateMigrationStatus(id))
            }
            // }
        }
    }


    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {migrationForm, getMigration} = state;
    let {dispatchAPI, mode, payload,} = migrationForm.data;
    return {migrationForm, dispatchAPI, mode, payload, getMigration};
}

export default withRouter(connect(mapStateToProps)(RedirectsFormWorker));