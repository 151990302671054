import React from 'react'
import {connect} from 'react-redux';
import SiteFormWorker from './SiteFormWorker';
import {
    onSiteFormChange,
    onWpLanguageChange,
    onSiteFormCheckedChange,
    onSiteFormPasswordChange,
    onSiteFormSubmit,
    onSiteFormCommerceCheckedChange
} from './SiteForm-Action'
import store from '../../../Store';
import SelectField from "../../../components/shared/form/SelectField";
import Loading from "../../../components/shared/loaders/Loading";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import {Link} from "react-router-dom";
import {urlTrailWithoutLastItem} from "../../../helpers/UrlHelper";
import Page from "../../../components/shared/pages/Page";
import {names} from "../../../helpers/StringHelper";
import generatePassword from 'generate-password';
import NewSearchableSelect from "../../../components/shared/form/NewSearchableSelect";
import TextField from "../../../components/shared/form/TextField";
import {selectType} from "../../../static-data/UserData"
import WordPressPasswordField from "../../../components/shared/form/WordPressPasswordField";
import OrganizationSideMenu from '../../organization/OrganizationSideMenu';


class SiteForm extends React.Component {

    state = {
        copied: false,
    }

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu('organization'));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }


    siteFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onSiteFormSubmit());
    };

    onChange = (e) => {
        e.preventDefault();
        this.state.copied = false;
        store.dispatch(onSiteFormChange(e.target.name, e.target.value));
    }

    onWpLanguageChange = (e) => {
        store.dispatch(onWpLanguageChange("wp_locale", e.value,))
    }

    onCheckedChange = (data) => {
        store.dispatch(onSiteFormCheckedChange(data));
    }

    onCommerceCheckedChange = (data) => {
        store.dispatch(onSiteFormCommerceCheckedChange(data));
    }

    onPasswordChange = (e) => {
        e.preventDefault();
        store.dispatch(onSiteFormPasswordChange(e.target.value));
    }

    beforeMaskedValueChange = (newState, userInput) => {
        var {value} = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = {start: cursorPosition, end: cursorPosition};
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection
        };
    }

    generatePwd() {
        this.state.copied = false;
        let password = generatePassword.generate({
            length: 20,
            numbers: true,
            symbols: true,
            lowercase: true,
            uppercase: true,
            exclude: "$",
        });
        store.dispatch(onSiteFormPasswordChange(password));
    }

    render() {
        let {
            dataCenters,
            payload,
            mode,
            createSite,
            errors,
            match,
            errorsPresent,
            wordPressLanguages,
            plans
        } = this.props;
        let {processing} = createSite;
        let {
            display_name,
            wp_admin_username,
            site_type,
            wp_admin_password,
            wp_admin_email,
            wp_title,
        } = payload;

        // wp_flag =1
        let dataCentersList = [];
        dataCenters && dataCenters.map(d => {
            if (d.status === 1) {
                dataCentersList.push(d);
            }
        })

        let plansList = [];
        plans && plans.map(plan => {
            if (mode === "wordpress") {
                if (plan.site_type === "Wordpress")
                    // plansList.push({value: plan.id, label:plan.title})
                    plansList.push({value: plan.id, name: plan.description})
            }
            if (mode === "ecommerce") {
                if (plan.site_type === "Woocommerce")
                    plansList.push({value: plan.id, name: plan.description})
            }
            if (mode === "blankinstall") {
                if (plan.site_type === "Blank")
                    plansList.push({value: plan.id, name: plan.description})
            }
        })


        let clearable = true;
        let wordPressLanguagelist = [];

        wordPressLanguages && Object.entries(wordPressLanguages).map(([key, value]) => {
            wordPressLanguagelist.push({value: value.wp_locale, label: value.name})
        })

        return (
            <>
                <Page title="Add Site"
                      processing={processing}>

                <div className="page-content">
                    
                    <div className="organizations-sidebar">
                        <OrganizationSideMenu />
                    </div>
                    <div className="content-section">
                    <div className="heading-block pr-60">
                        <div className="title-block">
                            <h2>Add new Site</h2>
                            <span><Link to={urlTrailWithoutLastItem()}>Sites</Link></span>
                            <span>Add new Site</span>
                        </div>
                    </div>
                    <SiteFormWorker organization_id={match.params.id}/>
                    <div className="box-block">
                        <ul className="nav nav-pills nav-justified">
                            <li className="nav-item">
                                <a className={`nav-link ${mode === "wordpress" ? 'active' : null}`}
                                    href="/#"
                                   onClick={ev => {this.onCheckedChange("wordpress");ev.preventDefault()}}
                                >WordPress</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${mode === "ecommerce" ? 'active' : null}`}
                                    href="/#"
                                   onClick={ev => {this.onCommerceCheckedChange("ecommerce");ev.preventDefault()}}
                                >E-commerce</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${mode === "blankinstall" ? 'active' : null}`}
                                    href="/#"
                                   onClick={ev => {this.onCheckedChange("blankinstall");ev.preventDefault()}}
                                >Blank Install</a>
                            </li>
                            <div
                                className={names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`)}>
                                {errorsPresent ? errors && errors.type && errors.type[0] && errors.type[0].message + '.' : null}
                            </div>

                        </ul>

                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="pills-install" role="tabpanel"
                                 aria-labelledby="pills-install-tab">
                                <div className="padded-block">
                                    <form onSubmit={this.siteFormSubmit}>
                                        {mode === "wordpress" &&
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <SelectField name="plan_id"
                                                                 options={plansList}
                                                                 errors={errors.plan_id}
                                                                 onChange={this.onChange}
                                                                 _value="value"
                                                                 label="Plan"/>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        {mode === "ecommerce" &&
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <SelectField name="plan_id"
                                                                 options={plansList}
                                                                 errors={errors.plan_id}
                                                                 onChange={this.onChange}
                                                                 _value="value"
                                                                 label="Plan"/>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        {mode === "blankinstall" &&
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <SelectField name="plan_id"
                                                                 options={plansList}
                                                                 errors={errors.plan_id}
                                                                 onChange={this.onChange}
                                                                 _value="value"
                                                                 label="Plan"/>
                                                </div>
                                            </div>
                                        </div>
                                        }

                                        <hr/>
                                        <div className="form-row">
                                            {mode === "blankinstall" ?
                                                <>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <SelectField name="site_type"
                                                                         value={site_type}
                                                                         options={selectType}
                                                                         errors={errors.status}
                                                                         onChange={this.onChange}
                                                                         _value="value"
                                                                         _label="name"/>

                                                        </div>
                                                    </div>
                                                </>
                                                : null}

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField name="display_name"
                                                               value={display_name}
                                                               label="Display Name"
                                                               errors={errors.display_name}
                                                               onChange={this.onChange}/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <SelectField name="data_center_id"
                                                                 options={dataCentersList}
                                                                 errors={errors.data_center_id}
                                                                 onChange={this.onChange}
                                                                 _value="id"
                                                                 label="Data Center"/>
                                                </div>
                                            </div>
                                            {
                                                mode === "wordpress" || mode === "ecommerce" ? (
                                                    <>
                                                        <div className="form-row">
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <TextField name="wp_title"
                                                                               value={wp_title}
                                                                               errors={errors.wp_title}
                                                                               onChange={this.onChange}
                                                                               label="WordPress Site Title"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <NewSearchableSelect
                                                                        name="wp_locale"
                                                                        isClearable={clearable}
                                                                        placeholder="English"
                                                                        options={wordPressLanguagelist}
                                                                        onChange={this.onWpLanguageChange}
                                                                        label="WordPress Site Language"
                                                                        _value="value"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <TextField name="wp_admin_username"
                                                                               value={wp_admin_username}
                                                                               errors={errors.wp_admin_username}
                                                                               onChange={this.onChange}
                                                                               label="WordPress Admin Username"/>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <WordPressPasswordField name="wp_admin_password"
                                                                                   value={wp_admin_password}
                                                                                   errors={errors.wp_admin_password}
                                                                                   onChange={this.onChange}
                                                                                   label="WordPress Admin Password">
                                                                </WordPressPasswordField>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <TextField name="wp_admin_email"
                                                                               value={wp_admin_email}
                                                                               errors={errors.wp_admin_email}
                                                                               onChange={this.onChange}
                                                                               label="WordPress Admin Email"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <hr/>
                                                    </>
                                                ) : null
                                            }
                                        </div>
                                        <div className="button-wrapper text-right mt-20">
                                            <button type="submit"
                                                    className="btn-theme btn-dark-blue">
                                                {processing ? <Loading/> : `Submit`}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>
                </Page>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        getDataCenterServerModule,
        getOrganizations,
        getSiteCreateDataCenters,
        getLoadBalancers,
        userDetail,
        loginForm,
        siteForm,
        createSite,
        getWordPressLangugae,
        getPlans
    } = state;
    let {payload, errors, errorsPresent, mode} = siteForm.data;

    let plans = getPlans && getPlans.success && getPlans.success.plans


    let wordPressLanguages =
        getWordPressLangugae &&
        getWordPressLangugae.success &&
        getWordPressLangugae.success.data &&
        getWordPressLangugae.success.data.data

    let dataCenterServer =
        getDataCenterServerModule &&
        getDataCenterServerModule.success &&
        getDataCenterServerModule.success.data &&
        getDataCenterServerModule.success.data.data &&
        getDataCenterServerModule.success.data.data.data;

    let dataCenters =
        getSiteCreateDataCenters &&
        getSiteCreateDataCenters.success &&
        getSiteCreateDataCenters.success.data &&
        getSiteCreateDataCenters.success.data.data

    return {
        getDataCenterServerModule,
        dataCenterServer,
        getOrganizations,
        getLoadBalancers,
        dataCenters,
        userDetail,
        loginForm,
        payload,
        createSite,
        errors,
        errorsPresent,
        getSiteCreateDataCenters,
        wordPressLanguages,
        getWordPressLangugae,
        mode,
        plans
    };
}

export default connect(mapStateToProps)(SiteForm);