import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handle422error, handleError, handleSuccess, validationError} from '../../../helpers/ErrorHelper'
import { scrollToError } from '../../../helpers/ScrollHelper'
import store from "../../../Store";
import {
  cancelRedirectsFormDispatch,
  RedirectsFormServerValidationErrors,
  setDefaultRedirectsForm
} from "../../../pages/site/redirects/form/RedirectsForm-Action";
import getRedirects from "./redirects_get";
import {toggleRedirectsFormModal} from "../../../pages/site/redirects/list/Redirects-Action";
import getSiteStatus from "../sites/site_status_get";

function _success(success)
{
  return { type: 'POST_REDIRECT_SUCCESS', success };
}
function _error(error)
{
  return { type: 'POST_REDIRECT_ERROR', error };
}
function _processing(processing)
{
  return { type: 'POST_REDIRECT_PROCESSING', processing };
}

function createRedirect(id )
{
  return ( dispatch, getState ) =>
  {
    dispatch(_processing(true));
    dispatch(_success(null));
    dispatch(_error(null));
    let {redirectForm} = getState()
    let {payload} = redirectForm.data
    delete payload.redirect_code_303
    delete payload.redirect_code_302
    if(payload.domain_id === null){
      delete payload.domain_id
    }
    let {organizationDashboard} = getState();
    let org_id = organizationDashboard.data.organization_id;
    axios({
      url: apiBaseUrl(`admin/organizations/${org_id}/sites/${id}/redirect_rule`),
      method: "post",
      dataType: 'json',
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    })
      .then(function(res){
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess( res )
        store.dispatch(getSiteStatus(id))
        store.dispatch(setDefaultRedirectsForm());
        store.dispatch(getRedirects(id));
        store.dispatch(toggleRedirectsFormModal());

      })
      .catch(function(error){
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError( error )
        store.dispatch(cancelRedirectsFormDispatch())
        if(error.response && error.response.status === 422 ) {
          // validationError(error.response.data.message,error.response.status)
          if(error && error.response.data.errors.redirect_rule){
            handle422error(error.response.data.errors)
          }
          else {
            store.dispatch(RedirectsFormServerValidationErrors(error.response.data.errors))
            scrollToError();
          }
        }
      });

  }
}


export default createRedirect;