import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultOrganizationBlockForm
} from "./OrganizationBlockForm-Action";
import createOrganizaitonForBlock from "../../../../ajax/actions/organization/organization_block_post";
import getOrganizationsBlock from "../../../../ajax/actions/organization/organizations_block_get";

class OrganizationBlockFormWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(setDefaultOrganizationBlockForm());
        store.dispatch(getOrganizationsBlock());
    }


    componentDidUpdate(prev) {
        let {dispatchAPI} = this.props;
        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(createOrganizaitonForBlock(this.props.match.params.id, this.props.match.params.org_id))

            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {organizationBlockForm, createSiteOrganizationChange,getOrganizationsBlock,organizationDashboard,getSite} = state;
    let {dispatchAPI, mode, payload} = organizationBlockForm.data;
    return {organizationBlockForm, organizationDashboard, dispatchAPI, mode, payload, createSiteOrganizationChange,getOrganizationsBlock,getSite};
}

export default withRouter(connect(mapStateToProps)(OrganizationBlockFormWorker));