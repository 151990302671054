import { BillingTypeFormState } from './BillingTypeForm-State'
import { errorsList, errorsPresent, mergeServerValidationErrors } from '../../../helpers/ErrorHelper'
import { endOfURL } from '../../../helpers/UrlHelper'
import {isObject} from "../../../helpers/DataHelper";

export default function billingTypeFormReducer(state = BillingTypeFormState, action) {

  let { data } = { ...state };

  let { validationErrors, name, value, organizations, mode, data_type } = action;

  switch (action.type) {

    case 'ON-BILLING-TYPE-FORM-CHANGE':
      data.payload[name] = value;
      buildErrors();
      return { ...state, data };

    case 'ON-BILLING-TYPE-FORM-TYPE-CHANGE':
      data.payload.type = data_type;

      buildErrors();
      return { ...state, data };

    case 'ON-BILLING-TYPE-FORM-MODE-CHANGE':
      data.mode = mode;
      buildErrors();
      return { ...state, data };

    case 'SET-BILLING-TYPE-DATA-FOR-UPDATE':
      data.payload ={
          billing_type: organizations.billing_type,
      };
      buildErrors();
      return { ...state, data };

    case 'SET-DEFAULT-BILLING-TYPE-FORM':
      data = {
        payload:{
          billing_type:`stripe`
        },
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode:`create`,
        message: ``
      };
      buildErrors();
      return { ...state, data };

    case 'CANCEL-BILLING-TYPE-FORM-DISPATCH':
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case 'ON-BILLING-TYPE-FORM-SUBMIT':
      data.submitted = true;
      buildErrors();
      return { ...state, data };


    case 'BILLING-TYPE-FORM-SERVER-VALIDATION-ERRORS':
      validationErrors && isObject( validationErrors ) &&
      Object.keys(validationErrors).map(key =>
        data.errors[key] = [{ message: ` - ${validationErrors[key][0]}`.slice(0, -1) }]
      );
      data.errorsPresent = true;
      data.dispatchAPI = false;
      return { ...state, data };

    default:
      return state
  }

  function buildErrors()
  {
    data.errors = errorsList(errors)
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field)
  {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
      if (field === `billing_type`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }
    }

    // errors = mergeServerValidationErrors(errors, validationErrors)

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

