import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getSite from "../../../ajax/actions/sites/site_get";
import store from "../../../Store";
import {cancelPriceBlockFormDispatch, priceBlockFormServerValidationErrors} from "../../../pages/site/price-block/form/PriceBlockForm-Action";
import {scrollToError} from "../../../helpers/ScrollHelper";

function _success(success) {
    return {type: 'UPDATE_SITE_PRICE_SUCCESS', success}
}

function _error(error) {
    return {type: 'UPDATE_SITE_PRICE_ERROR', error}
}

function _processing(processing) {
    return {type: 'UPDATE_SITE_PRICE_PROCESSING', processing}
}

function updateSitePrice(id) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {SftpList,priceBlockForm,organizationDashboard} = getState();

        let site_id =  SftpList.data.site_id
        let payload = priceBlockForm.data.payload
        let org_id = organizationDashboard.data.organization_id;


        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/prices/${id}`),
            method: 'put',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res.data.message)
                store.dispatch(getSite(site_id))
                store.dispatch(cancelPriceBlockFormDispatch())

            }).catch(function (error) {

            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
            if (error.response && error.response.status === 422) {
                store.dispatch(priceBlockFormServerValidationErrors(error.response.data.errors))
                scrollToError();
            }
        });
    }
}

export default updateSitePrice;