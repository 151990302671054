import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getRoles from './roles_get'
import store from '../../../Store';

function _success(success) {
    return {type: 'ROLE_DELETE_SUCCESS', success};
}

function _error(error) {
    return {type: 'ROLE_DELETE_ERROR', error};
}

function _processing(processing) {
    return {type: 'ROLE_DELETE_PROCESSING', processing};
}

function deleteRole(id) {
    return (dispatch) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        axios({
            url: apiBaseUrl(`admin/roles/${id}`),
            method: "delete",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                store.dispatch(getRoles())
                handleSuccess('Role deleted Successfully')
            })
            .catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error)
            });
    }
}
export default deleteRole;