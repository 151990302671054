import store from '../../../Store';
import { toggleURLQuery } from '../../../helpers/UrlHelper'
import getSites from '../../../ajax/actions/sites/super_admin_sites_get'

export function toggleSiteListFilter(e, history)
{
  return function (dispatch) {
    let { name, value } = e.target;
    toggleURLQuery( name, value , history)
    dispatch({type:'TOGGLE-SITE-LIST-FILTER', name, value, history});
    if(name === 'limit' ) {
      store.dispatch( getSites());
      toggleURLQuery( 'page', '',history)
    }
    else {
      store.dispatch(getSites());
    }
    // store.dispatch(getServerModules());
  }
}
export function setDefaultSearch(){
  return function (dispatch) {
    dispatch({type: `SET-DEFAULT-SEARCH`})
  }
}

export function setDefaultSiteFiltersToURL( history )
{
  return ( dispatch, getState ) => {
    let { data } = getState().siteList;
    let { params } = data;
    Object.keys( params ).map( key => {
      if ( params[key] !== `` && params[key] !== null && params[key] !== undefined ) {
        toggleURLQuery( key, params[key], history)
      }
    })
  }
}

export function onServerChange(server_id) {
  return function (dispatch) {
    dispatch({type:'ON-SITE-SERVER-CHANGE', server_id});
    store.dispatch( getSites());
  }
}

export function onServerReset() {
  return function (dispatch) {
    dispatch({type:'ON-SITE-RESET'});
    store.dispatch( getSites() );
  }
}