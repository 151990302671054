import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'LOGOUT_USER_SUCCESS', success};
}

function _error(error) {
    return {type: 'LOGOUT_USER_ERROR', error};
}

function _processing(processing) {
    return {type: 'LOGOUT_USER_PROCESSING', processing};
}

function logoutUser() {

    return (dispatch) => {
        dispatch(_processing(true));

        const request = axios({

            url: apiBaseUrl(`admin/logout`),
            method: "post",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));

            }).catch(function (error) {
                handleError(error)
                dispatch(_error(error));
                dispatch(_processing(false));
            });

        return {
            type: 'LOGOUT_USER_SUCCESS',
            payload: request
        }
    }
}

export default logoutUser;