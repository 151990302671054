import {OrganizationDashboardState} from './OrganizationDashboard-State'

export default function organizationDashboardReducer(state = OrganizationDashboardState, action) {

    let {data} = {...state};
    let {organization, org_status, org_id} = action;

    switch (action.type) {
        case 'SET-ORGANIZATION-DASHBOARD-DATA':
            data.organization_id = organization.id;
            data.organization_name = organization.name;
            return {...state, data};

        case 'SET-ORGANIZATION-ID':
            data.organization_id = org_id
            return {...state, data};

        case 'SET-ORGANIZATION-STATUS':
            data.organization_status = org_status.status;
            data.organization_message = org_status.message;
            data.organization_notification = org_status.notification;
            return {...state, data};

        default:
            return state
    }
}
