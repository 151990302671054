import store from '../../../Store';
import {toggleURLQuery} from "../../../helpers/UrlHelper";
import getPrices from "../../../ajax/actions/price/price_gets"

export function togglePriceListFilter(e, history) {
    return function (dispatch) {
        let {name, value} = e.target;
        toggleURLQuery(name, value, history)
        dispatch({type: 'TOGGLE-PRICE-LIST-FILTER', name, value, history});

        if (name === 'limit') {
            store.dispatch(getPrices());
            toggleURLQuery('page', '', history)
        } else {
            store.dispatch(getPrices());
        }
    }
}

export function setDefaultPriceFiltersToURL(history) {
    return (dispatch, getState) => {
        let {priceList} = getState()
        let {data} = priceList
        let {params} = data;
        Object.keys(params).map(key => {
            if (params[key] !== `` && params[key] !== null && params[key] !== undefined) {
                toggleURLQuery(key, params[key], history)
            }
        })
    }
}