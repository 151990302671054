import store from '../../../Store';
import getSitesWithoutPagination from "../../../ajax/actions/sites/sites_without_pagination_get";


export function bugFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'BUG-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onBugFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-BUG-FORM-SUBMIT'})
    }
}

export function onBugFormChange(name,value) {
    return function (dispatch) {
        dispatch({type: 'ON-BUG-FORM-CHANGE', name, value});
        if(name === "organization_id" && value)
        {
            store.dispatch(getSitesWithoutPagination(value))
        }
    }
}

export function onRemoveBugTag(item) {
    return function (dispatch, getState) {
        let {bugForm} = getState();
        let {tags} = bugForm.data.payload
        let tagArray_afterDelete =  tags.filter(i => i !== item)
        dispatch({type: 'ON-REMOVE-BUG-TAG', tagArray_afterDelete});
    }
}

export function onAddTag(items) {
    return function (dispatch, getState) {
        let {bugForm} = getState();
        let tags = bugForm.data.payload.tags
        let tagAdd =  [...tags ,items]
        dispatch({type: 'ON-ADD-BUG-TAG', tagAdd});
    }
}

export function onAddImage(items) {
    return function (dispatch, getState) {
        let {bugForm} = getState();
        let images = bugForm.data.payload.images
        let imageAdd = ``;
        if(images.length) {
             imageAdd = [...images, items]
        }else{
            imageAdd=[items]
        }
        dispatch({type: 'ON-ADD-IMAGE', imageAdd});
    }
}

export function onDeleteAddedImage(data) {
    return function (dispatch, getState) {
        let {bugForm} = getState();
        let images = bugForm.data.payload.images
        let filteredImage = images.filter(image => image.name !== data.name)
        dispatch({type: 'ON-DELETE-IMAGE', filteredImage});
    }
}

export function onBugFormUpdateChange(name,value) {
    return function (dispatch) {
        dispatch({type: 'ON-BUG-FORM-UPDATE-CHANGE', name, value});
    }
}

export function onDeleteBugForm(value) {
    return function (dispatch) {
        dispatch({type: 'ON-DELETE-BUG-FORM', value});
    }
}

export function onBugFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-BUG-FORM-MODE-CHANGE', mode});
    }
}

export function cancelBugFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-BUG-FORM-DISPATCH'});
    }
}

export function setDefaultBugForm() {
    return function (dispatch, getState) {
        let { getLoggedInUser} = getState();
        let user_id =
            getLoggedInUser?.success?.data?.data.id
        dispatch({type: 'SET-DEFAULT-BUG-FORM',user_id});
    }
}

export function setBugDataForStatusUpdate() {
    return function (dispatch, getState) {
        let {getBug} = getState();
        let bug = getBug && getBug.success && getBug.success.internal_bug;
        dispatch({type: 'SET-BUG-DATA-FOR-STATUS-UPDATE',bug: JSON.parse(JSON.stringify( bug ))});
    }
}

export function setBugDataForUpdate() {
    return function (dispatch, getState) {
        let {getBug, getLoggedInUser} = getState();
        let bug = getBug && getBug.success && getBug.success.internal_bug;
        let user_id =
            getLoggedInUser?.success?.data?.data.id
        if(bug && bug.organization_id){
            store.dispatch(getSitesWithoutPagination(bug.organization_id))
        }
        dispatch({type: 'SET-BUG-DATA-FOR-UPDATE', bug: JSON.parse(JSON.stringify( bug )),user_id});
    }
}

/*export function setBugDataForView() {
    return function (dispatch, getState) {
        let {getBug} = getState();
        let bug = getBug && getBug.success && getBug.success.internal_bug;
        console.log(` <| getBug |> `, getBug)
        dispatch({type: 'SET-BUG-DATA-FOR-VIEW', bug});
    }
}*/


