import React from 'react'
import {connect} from 'react-redux';
import PaymentWorker from './PaymentWorker';
import PaymentHeading from "./PaymentHeading";
import store from '../../../Store';
import TableHead from "../../../components/shared/table/TableHead";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import {justDate,justDates} from "../../../helpers/DateTimeHelper";
import Loading from "../../../components/shared/loaders/Loading";
import {togglePaymentListFilter} from "./PaymentList-Actions";
import {ucFirst, unslufigy} from "../../../helpers/StringHelper";
import Page from "../../../components/shared/pages/Page";
import getDownloadInvoice from "../../../ajax/actions/invoice/invoice_download_get";
import OrganizationSideMenu from '../../organization/OrganizationSideMenu';
import { Paid } from '../../../components/shared/status/Status';
import deletePayment from "../../../ajax/actions/invoice/payment_delete";
import deleteInvoice from "../../../ajax/actions/invoice/invoice_delete";
import getResendEmail from "../../../ajax/actions/invoice/payment_resend_email_get";


class Payment extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu('organization'));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    toggleSort = (field) => {
        store.dispatch(togglePaymentListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    download(organization_id,invoice_id) {
        store.dispatch(getDownloadInvoice(organization_id,invoice_id));
    }

    deletePayment(id){
        store.dispatch(deletePayment(id))
    }

    resendInvoiceEmail(organization_id,invoice_id){
        store.dispatch(getResendEmail(organization_id,invoice_id))
    }

    deleteInvoice(organization_id,id){
        store.dispatch(deleteInvoice(organization_id,id))
    }

    render() {
        let {pages, getPayments, list, params, fields,organization_id} = this.props;
        let {processing, error} = getPayments;
        if (error) {
            this.props.history.push('/');
        }

        return (
            <>
            <Page title="Payments" processing={processing}>

                <div className="page-content">

                <div className="organizations-sidebar">
                    <OrganizationSideMenu processing={processing}/>
                </div>
                <div className="content-section">
                <PaymentHeading organization_id={this.props.match.params.id}/>
                <div className="table-block table-responsive pr-60">
                    <table className="table table-striped payment-table ">
                        <TableHead fields={fields}
                                   params={params}
                                   noActions={true}
                                   onClick={this.toggleSort}/>
                        <tbody>
                        {processing ? <td colSpan={4} className="text-center"><Loading/></td> :

                            list && list.length ? list.map((l, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                <span><strong>{justDate(l.created_at)}</strong></span>
                                            </td>
                                            <td>{l.reference_number ?
                                                <>Invoice
                                                    for {justDates(l.invoice_for)}</>
                                                : l.type === `coupon_redemption` ?
                                                unslufigy(l.type)
                                                    :
                                                    l.payment_method === "fortnox" &&   l.type === `auto_invoice_payment` ?
                                                        unslufigy(l.type) + "   (Fortnox)"
                                                        :
                                                        l.payment_method === "stripe" &&   l.type === `auto_invoice_payment` ?
                                                            unslufigy(l.type) + "   (Stripe)"
                                                        :
                                                <>Payment ({ucFirst(l.card_type)} {l.card_last_four})</>}</td>
                                            <td>{l.reference_number ? <>{l.grand_total} {l.currency}</> : <>- {l.amount} {l.currency}</>}</td>
                                            {(l.reference_number) ?
                                                <td>
                                                    <div className='row align-items-center justify-content-center'>
                                                        <Paid status={l.status}/>   
                                                        <div className="dropdown dropdown-alt text-center" id="more-options">
                                                            <span className="dropdown-toggle"
                                                                    data-toggle="dropdown">
                                                            <span className="icon-more"/>
                                                            </span>
                                                            <div
                                                                className="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <a href="/#"
                                                                   className="dropdown-item"
                                                                   type="button"
                                                                   onClick={ev => {this.download(l.organization_id,l.id);ev.preventDefault()}}>
                                                                    Download
                                                                </a>
                                                                <a href="/#"
                                                                   className="dropdown-item"
                                                                   type="button"
                                                                   onClick={ev => {this.deleteInvoice(l.organization_id,l.id);ev.preventDefault()}}>
                                                                    Delete
                                                                </a>
                                                                <a href="/#"
                                                                   className="dropdown-item"
                                                                   type="button"
                                                                   onClick={ev => {this.resendInvoiceEmail(l.organization_id,l.id);ev.preventDefault()}}>
                                                                    Resend Invoice Email
                                                                </a>
                                                            </div>
                                                        </div>
                                                        </div>

                                            </td>
                                                :
                                                <td>
                                                    <div className='row align-items-center justify-content-center'>
                                                        <div className="dropdown dropdown-alt text-center" id="more-options">
                                                            <span className="dropdown-toggle"
                                                                  data-toggle="dropdown">
                                                            <span className="icon-more"/>
                                                            </span>
                                                            <div
                                                                className="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <a href="/#"
                                                                   className="dropdown-item"
                                                                   type="button"
                                                                   onClick={ev => {this.deletePayment(l.id);ev.preventDefault()}}>
                                                                    Delete
                                                                </a>
                                                                {/*<a href="/#"
                                                                   className="dropdown-item"
                                                                   type="button"
                                                                   onClick={ev => {this.resendInvoiceEmail(l.organization_id,l.id);ev.preventDefault()}}>
                                                                    Resend Invoice Email
                                                                </a>*/}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </td>
                                            }
                                        </tr>
                                    )
                                }) :
                                <tr>
                                    <td colSpan="4" className="text-center">No Payments Available</td>
                                </tr>
                        }
                        </tbody>
                    </table>

                {list && list.length ? (
                    <div className="fixed-footer pr-60" id="inside-organization-pagination">
                        <PaginationButtons pages={pages}
                                           processing={processing}
                                           fetchAction={togglePaymentListFilter}/>
                    </div>
                ) : null}

                    </div>
                </div>
                </div>
                <PaymentWorker organization_id={organization_id}/>
                </Page>
            </>
        )
    }
}

function mapStateToProps(state) {
    let { paymentList, getPayments} = state;
    let {params, fields, modal} = paymentList.data;

    let {success} = getPayments;

    let list =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data
    let current_page = success &&  success.data && success.data.data.current_page;
    let last_page = success && success.data && success.data.data.last_page;
    let total = success && success.data && success.data.data.total;
    let from = success && success.data && success.data.data.from;
    let pages = {current_page, last_page, total, from};

    return {getPayments, list, paymentList, params, fields, modal,pages};
}

export default connect(mapStateToProps)(Payment);