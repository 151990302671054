import { RoleFormState } from './RoleForm-State'
import { errorsList, errorsPresent, mergeServerValidationErrors } from '../../../helpers/ErrorHelper'
import { endOfURL } from '../../../helpers/UrlHelper'
import {isObject} from "../../../helpers/DataHelper";
import {slugify} from "../../../helpers/StringHelper";

export default function roleFormReducer(state = RoleFormState, action) {

  let { data } = { ...state };
  let { validationErrors, name, value, roles, mode, permissions, id  } = action;
  switch (action.type) {

    case 'ON-ROLE-FORM-CHANGE':
      data.payload[name] = value;
      if (name === 'name') {
        data.payload.slug = slugify(value);
      }
      buildErrors();
      return {...state, data};

    case 'ON-ROLE-PERMISSIONS-FORM-CHANGE':
      if (name === `search`) {
        data.permissionList = data.originalPermissionList.filter(
            item => item.display.toLowerCase().includes(value) || item.display_name.toLowerCase().includes(value)
        )
        data.payload[name] = value;

      } else if (name === `permissions`) {
        if (data.payload[name].includes(value)) {
          data.payload[name] = data.payload[name].filter(item => item !== value);
        } else {
          data.payload[name].push(value)
        }
      }
      return {...state, data}

    case 'ON-ROLE-PERMISSIONS-FORM-SWITCH':

      if (data.payload.permissions.includes(id)) {
        data.payload.permissions = data.payload.permissions.filter(item => item !== id);
      } else {
        data.payload.permissions.push(id)
      }
      return {...state, data}

    case 'ON-PARTIAL-FULL-ITEM-CHANGE':

      permissions && permissions.map( p => {
        if( mode === 'status-full' ) {
          data.payload.permissions = data.payload.permissions.filter(item => item !== p);
        } else {
          if (!data.payload.permissions.includes(p)) {
            data.payload.permissions.push(p)
          }
        }

      });
      return {...state, data}


    case 'ON-ROLE-FORM-MODE-CHANGE':
      data.mode = mode;
      buildErrors();
      return {...state, data};

    case 'SET-ROLE-DATA-FOR-UPDATE':
      let new_permission = [];
      roles.permissions && roles.permissions.map(p => {
        new_permission.push(p.slug);
      })
      data.payload = {
        name: roles.name,
        slug: roles.slug,

        permissions: new_permission
      };
      buildErrors();
      return {...state, data};

    case 'SET-DEFAULT-ROLE-FORM':
      data = {
        payload: {
          name: ``,
          slug: ``,
          permissions: []
        },
        admin_role: [],
        admin_permission: [],
        admin: [],
        backup: [],
        domain: [],
        server: [],
        site: [],
        data_center: [],
        load_balancer: [],
        customer:[],
        organization:[],
        dashboard:[],
        customer_role:[],
        customer_permission:[],
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode: endOfURL(),
        message: ``
      };
      buildErrors();
      return {...state, data};

    case 'CANCEL-ROLE-FORM-DISPATCH':
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return {...state, data};

    case 'ON-ROLE-FORM-SUBMIT':
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return {...state, data};

    case 'PERMISSIONS-LIST-TO-ASSIGN':
      data.admin_role = permissions && permissions.admin_role;
      data.admin_permission = permissions && permissions.admin_permission;
      data.admin = permissions && permissions.admin;
      data.backup = permissions && permissions.backup;
      data.domain = permissions && permissions.domain;
      data.server = permissions && permissions.server;
      data.site = permissions && permissions.site;
      data.data_center = permissions && permissions.data_center;
      data.load_balancer = permissions && permissions.load_balancer;
      data.customer = permissions && permissions.customer;
      data.organization = permissions?.organization;
      data.dashboard = permissions?.dashboard;
      data.customer_role = permissions?.customer_role;
      data.customer_permission = permissions?.customer_permission;

      return { ...state, data };

    case 'ROLE-FORM-SERVER-VALIDATION-ERRORS':
      validationErrors && isObject( validationErrors ) &&
      Object.keys(validationErrors).map(key =>
        data.errors[key] = [{ message: ` - ${validationErrors[key][0]}`.slice(0, -1) }]
      );
      data.dispatchAPI = false;
      return { ...state, data };

    default:
      return state
  }

  // function isAnActionItem(item)
  // {
  //   return (
  //       item === 'view'  || item === 'delete' || item === 'create' || item === 'update'   || item === `add`     || item === `trigger` ||
  //       item === 'clone' || item === 'edit'   || item === 'attach' || item === 'detach'   || item === `restore` || item === `show`    ||
  //       item === 'make'  || item === `assign` || item === `change` || item === 'download' || item === 'fetch'   || item === 'fetch-single'
  //   )
  // }

  function buildErrors()
  {
    data.errors = errorsList(errors)
    data.errorsPresent = errorsPresent(errors)
  }

  function errors(field)
  {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted) {
      if (field === `name`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }

      if (field === `slug`) {
        if (!_(field)) newError(field, 0, `This is required field`)
      }

    }

    errors = mergeServerValidationErrors(errors, validationErrors)

    return field ? errors[field] : errors

    function newError(field, step, message) {
      errors && errors[field].push({ step, message })
    }

    function _(field) {
      return data.payload[field]
    }
  }
}

