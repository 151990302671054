import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import getSiteStatus from "../sites/site_status_get";
import getManualSiteBackup from "./site_manual_backup_get";
import getSiteBackup from "./site_backup_get";
import {toggleBackupFormModal} from "../../../pages/site/backup/list/Backup-Action";
import {
    cancelManualBackupFormDispatch,
    setManualBackupError
} from "../../../pages/site/backup/form/ManualBackupForm-Action";

function _success(success) {
    return {type: 'CREATE_BACKUP_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_BACKUP_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_BACKUP_PROCESSING', processing}
}

function createBackup(organization_id = null) {
    return (dispatch, getState) => {
        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))
        let {backupList, organizationDashboard, manualBackupForm} = getState();

        let {site_id} = backupList.data;
        let {payload} = manualBackupForm.data
        if (payload.name === "") {
            delete payload.name
        }

        let org_id = organization_id || organizationDashboard.data.organization_id;


        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/backups`),
            method: 'post',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                store.dispatch(toggleBackupFormModal())
                store.dispatch(getSiteStatus(site_id));
                store.dispatch(getSiteBackup(site_id, org_id))
                store.dispatch(getManualSiteBackup(site_id, org_id))
            }).catch(function (error) {
            dispatch(_error(error.response.data.message))
            dispatch(_processing(false))
            store.dispatch(cancelManualBackupFormDispatch())
            handleError(error)
            store.dispatch(setManualBackupError(error.response.data))
        })
    }
}

export default createBackup
