export function priceBlockFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'PRICE-BLOCK-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onPriceBlockFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-PRICE-BLOCK-FORM-SUBMIT'})
    }
}

export function onPriceBlockFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-PRICE-BLOCK-FORM-CHANGE', name, value});
    }
}

export function onPriceBlockFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-PRICE-BLOCK-FORM-MODE-CHANGE', mode});
    }
}

export function cancelPriceBlockFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-PRICE-BLOCK-FORM-DISPATCH'});
    }
}

export function setDefaultPriceBlockForm() {
    return function (dispatch) {
        // let { getSite } = getState();
        // let site_price = getSite && getSite.success && getSite.success.sitePrice && getSite.success.sitePrice.price
        dispatch({type: 'SET-DEFAULT-PRICE-BLOCK-FORM'});
    }
}

export function setDefaultPriceBlockFormUpdate() {
    return function (dispatch, getState) {
        let { getSite } = getState();
        let site_price = getSite && getSite.success && getSite.success.sitePrice && getSite.success.sitePrice.price
        let billable = getSite && getSite.success && getSite.success.billable
        dispatch({type: 'SET-DEFAULT-PRICE-BLOCK-FORM-FOR-UPDATE', site_price,billable});
    }
}

export function setSiteId(site_id) {
    return function (dispatch) {
         dispatch({type: 'SET-SITE-ID', site_id});
    }
}
// export function setOrganizationId(organization_id) {
//     return function (dispatch) {
//         dispatch({type: 'SET-PRICE-ID', organization_id});
//     }
// }

export function priceBlockError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-PRICE-BLOCK-ERROR', error});
    }
}