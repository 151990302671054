import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import getSiteStatus from "../sites/site_status_get";

function _success(success) {
    return {type: 'CREATE_SFTP_CREDENTIAL_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_SFTP_CREDENTIAL_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_SFTP_CREDENTIAL_PROCESSING', processing}
}

function createSftpPassport(payload) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {SftpList, organizationDashboard} = getState();
        let {site_id} = SftpList.data;
        let org_id = organizationDashboard.data.organization_id;

        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/credentials`),
            method: 'post',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess("Password change request sent successfully")
                store.dispatch(getSiteStatus(site_id));

            }).catch(function (error) {

            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
        })
    }
}

export default createSftpPassport;
