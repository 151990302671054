import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import TableHead from "../../../components/shared/table/TableHead";
import store from "../../../Store";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import {ucFirst, unslufigy} from "../../../helpers/StringHelper";
import {Availability, Status} from '../../../components/shared/status/Status';
import ExpiredBackupListFilters from "./ExpiredBackupListFilters";
import {toggleExpiredBackupListFilter} from "./Expired-Backup-Action";
import ExpiredBackupWorker from "./ExpiredBackupWorker";
import {withRouter} from "react-router";
import Moment from "moment";

class ExpiredBackup extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    toggleSort = (field) => {
        store.dispatch(toggleExpiredBackupListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    render() {
        let {list, fields, params, pages, getExpiredBackup} = this.props;
        let {processing, error} = getExpiredBackup;
        if (error) {
            this.props.history.push('/');
        }
        return (
            <>
                <Page title="Expired Backup" processing={processing}>

                    <ExpiredBackupWorker/>
                    <ExpiredBackupListFilters title="expired-backup"/>
                    <div className="table-block table-responsive pr-60">

                        <table className="table table-striped">
                            <TableHead fields={fields}
                                       params={params}
                                       noActions={true}
                                       onClick={this.toggleSort}
                                       processing={processing}
                            />
                            <tbody>
                            {processing ? <td colSpan={9} className="text-center"><Loading/></td> :
                                list && list.length ? list.map((l, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                {l.id}
                                            </td>
                                            <td>
                                                {l.site_name}
                                            </td>
                                            <td>
                                                {l.name}
                                            </td>
                                            <td>
                                                {l.storage_type}
                                            </td>
                                            <td>
                                                {unslufigy(ucFirst(l.type))}
                                            </td>
                                            <td>{l.snapshot_name}</td>
                                            <td>{l.delete_attempt? l.delete_attempt : "N/A"}</td>
                                            <td>{l.backup_count}</td>
                                            <td>{Moment(l.created_at).format('YYYY-MM-DD h:mm A')}</td>
                                            <td>{Moment(l.expiry_date).format('YYYY-MM-DD h:mm A')}</td>
                                            {/*<td>{l.remarks !== " " ? l.remarks : "N/A"}</td>*/}
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan="9" className="text-center">No Expired Backup Available</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                    {list && list.length ? (
                        <div className="fixed-footer pr-60">
                            <PaginationButtons
                                className="pagination"
                                pages={pages}
                                processing={processing}
                                fetchAction={toggleExpiredBackupListFilter}/>
                        </div>
                    ) : null}
                </Page>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {getExpiredBackup, expiredBackupList} = state;

    let {params, fields} = expiredBackupList.data;
    let {success} = getExpiredBackup;
    let list =
        success &&
        success.data
        &&
        success.data.data &&
        success.data.data.data;


    let current_page = success && success.data && success.data.data.current_page;
    let last_page = success && success.data && success.data.data.last_page;
    let total = success && success.data && success.data.data.total;
    let pages = {current_page, last_page, total};

    return {getExpiredBackup, list, expiredBackupList, params, fields, pages};
}

export default withRouter(connect(mapStateToProps)(ExpiredBackup));