import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
// import {letsEncryptDomain} from "../../../pages/site/ssl/list/SSL-Action";

function _success(success) {
    return {type: 'GET_SITE_DOMAIN_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_SITE_DOMAIN_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_SITE_DOMAIN_PROCESSING', processing};
}

function getSiteDomain(organization_id = null) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_error(null));
        let {domainList, siteDashboard} = getState();

        let {params, site_id} = domainList.data;

        let org_id = siteDashboard.data.org_id

        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/domains`),
            method: "get",
            dataType: 'json',
            params,
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                // handleSuccess( res )
                // store.dispatch(letsEncryptDomain());


            }).catch(function (error) {
            handleError(error);
            dispatch(_error(error));
            dispatch(_processing(false));
        });
    }
}

export default getSiteDomain;