import React from 'react';
import store from '../../../Store'
import {
  setAdminDataForUpdate,
  setDefaultUserData,onModeChange
} from './UserForm-Actions'
import { endOfURL, urlTrailWithoutLastItem } from '../../../helpers/UrlHelper'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import createUser from '../../../ajax/actions/user/user_post'
import getUser from "../../../ajax/actions/user/user_get";
import updateUser from "../../../ajax/actions/user/user_put";
import getRoles from "../../../ajax/actions/role/roles_get";
import getOrganizatonsWithoutPagination from "../../../ajax/actions/organization/organizations_without_pagination_get";
import getCountries from "../../../ajax/actions/country/countries_get";

class UserFormWorker extends React.Component {
  constructor(props) {
    super(props);
    if (props.match.params.id) {
      store.dispatch(getUser(props.match.params.id));
      store.dispatch(setDefaultUserData())
      store.dispatch(onModeChange(endOfURL()));
    }
    store.dispatch(getCountries());
    store.dispatch(getRoles());
    store.dispatch(getOrganizatonsWithoutPagination());
    if (endOfURL() === 'create') {
      store.dispatch(setDefaultUserData())
    }
  }


  componentDidUpdate(prev) {
    let {dispatchAPI, mode} = this.props;
      if (this.props.getUser !== prev.getUser) {
        let {success} = this.props.getUser;
        if (success) {
          store.dispatch(setAdminDataForUpdate(mode))
        }
      }

      if (this.props.createUser !== prev.createUser) {
        let {success} = this.props.createUser;
        if (success) {
          this.props.history.push(urlTrailWithoutLastItem());
        }
      }
    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        if(endOfURL() === `create`) {
          store.dispatch( createUser());
        }
        else if(mode === 'update'){
          store.dispatch(  updateUser(this.props.match.params.id));
        }
      }
    }
  }

    render()
    {
      return null
    }
  }

function mapStateToProps(state) {
  let { getUser, createUser, updateUser, userForm , getRoles, getLoggedInUser } = state;

  let { dispatchAPI,mode } = userForm.data

  let {id:loggedInUserId} = getLoggedInUser?.success?.data?.data;

  return { getUser, createUser, updateUser, userForm, dispatchAPI ,getRoles, getLoggedInUser, loggedInUserId, mode};
}
export default withRouter(connect( mapStateToProps )( UserFormWorker ));