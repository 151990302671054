import {endOfURL} from "../../helpers/UrlHelper";

const DefaultEnable2FaFormState = {
    payload: {
        country_code: ``,
        contact_number: ``,
    },

    errors: [],
    twoFaError: '',
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: endOfURL(),
    message: ``
};

export const Enable2FaFormState = {data: DefaultEnable2FaFormState};