import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultBugForm,
    setBugDataForUpdate, onBugFormModeChange, setBugDataForStatusUpdate
} from "./BugForm-Action";
import updateBugStatus from "../../../ajax/actions/support/bug_status_put";
import getBug from "../../../ajax/actions/support/bug_get";
import createBug from "../../../ajax/actions/support/bug_post";
import updateBug from "../../../ajax/actions/support/bug_put";
import {endOfURL} from "../../../helpers/UrlHelper";
import getOrganizationsWithoutPagination from "../../../ajax/actions/organization/organizations_without_pagination_get"
import getAllUsersWithoutPagination from "../../../ajax/actions/user/all_users_without_pagination_get"

class BugFormWorker extends React.Component {

    constructor(props) {
        super(props);
        if(props.match.params.id){
            store.dispatch(getBug(props.match.params.id));
            store.dispatch(onBugFormModeChange('update'))
        }
        if (endOfURL() === 'create') {
            store.dispatch(setDefaultBugForm())
        }
        if(props.mode === `updateStatus`) {
            store.dispatch(setBugDataForStatusUpdate());
        }
        store.dispatch(getOrganizationsWithoutPagination())
        store.dispatch(getAllUsersWithoutPagination())
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode} = this.props;

        if (this.props.getBug !== prev.getBug) {
            let {success, error} = this.props.getBug;
            if (success && mode === 'update') {
                store.dispatch(setBugDataForUpdate());
            }

            if (error) {
                //
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                let {id} = this.props.getBug
                && this.props.getBug.success
                && this.props.getBug.success.internal_bug

                if (!this.props.match.params.id && mode === 'create') {
                    store.dispatch(createBug())
                }
                if (this.props.match.params.id && mode === 'update') {
                    store.dispatch(updateBug(this.props.match.params.id))
                }
                if (mode === 'updateStatus') {
                    store.dispatch(updateBugStatus(id))
                }
            }
        }
    }


    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {bugForm, getBug, createBug,updateBug, getOrganizationsWithoutPagination, getLoggedInUser,getAllUsersWithoutPagination} = state;
    let {dispatchAPI, mode, payload,view} = bugForm.data;
    return {bugForm, dispatchAPI, mode, payload, getBug, createBug,updateBug, view, getOrganizationsWithoutPagination, getLoggedInUser,getAllUsersWithoutPagination};
}

export default withRouter(connect(mapStateToProps)(BugFormWorker));