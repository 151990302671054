const DefaultDashboardSitesListState = {
    payload:{
        initialLoading: true,
        flashMessage: null,
        error:"",
        success:"",
    },
    params: {
        limit: 25,
        page:   1,
        sort_order:  'desc',
        sort_by:``,
        q: ``,
        start_date:``,
        end_date:``
    },
    modal:{
        mode:`dashboard`
    },
    dashboard_search:`false`,
    server_id:``,
    fields: [
        { field: `display_name`, label: `Name`, sort: true },
        { field: `data_center_name`, label: `Data Center`, sort: true },
        { field: `action`, label: `Action`, sort: false },
    ]
}

export const DashboardSitesListState = {data: DefaultDashboardSitesListState}