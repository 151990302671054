import { UserFormState } from './UserForm-State'
import { errorsList, errorsPresent, mergeServerValidationErrors } from '../../../helpers/ErrorHelper'
import {  isValidEmail } from '../../../helpers/StringHelper'
import {endOfURL} from "../../../helpers/UrlHelper";

export default function userFormReducer(state = UserFormState, action) {

  let { data } = { ...state };
  let {  name, value, user, validationErrors ,slug ,org_id} = action;

  switch (action.type) {

    case 'ON-USER-FORM-CHANGE':
        data.payload[name] = value;
      buildErrors()
      return {...state, data};

    case 'ON-USER-ROLE-FORM-CHANGE':
      data.payload[name] = slug;
      buildErrors()
      return {...state, data};

    case 'ON-USER-ORGANIZATION-ASSIGN':

      data.payload['allowed_organizations'] = value
      buildErrors()
      return {...state, data};

    case 'SET-ORGANIZATION-ID':
      data.payload.organization_id = +org_id;
      buildErrors();
      return {...state, data};

    case 'ON-USER-FORM-SUBMIT':
      data.submitted = true;
      buildErrors()
      if ( !data.errorsPresent ) data.dispatchAPI = true;
      return { ...state, data };

    case 'SET-SPECIFIC-USER-DATA':
      data.payload = {
        first_name:user.first_name,
        middle_name:user.middle_name,
        last_name:user.last_name,
        email:user.email,
        password:user.password,
        role:user.role.slug,
        extra_address_line:user.extra_address_line,
        address:user.address,
        city:user.city,
        country_id:user.country_id,
        post_code:user.post_code,
      };
      buildErrors()
      return {...state, data};

    case 'USER-FORM-SERVER-VALIDATION-ERRORS':
      Object.keys( validationErrors ).map( key =>
        data.errors[key] = [{ message: ` - ${ validationErrors[key][0] }`.slice(0, -1) }]
      )
      data.errorsPresent = true;
      data.dispatchAPI = false;
      return {...state, data};

    case 'CANCEL-USER-FORM-DISPATCH':
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors()
      return {...state, data};

    case 'SET-DEFAULT-USER-DATA':
      data = {
        payload:{
            first_name: '',
            last_name: '',
            middle_name: '',
            email: '',
            password: '',
          role:'',
          extra_address_line:``,
          address:``,
          city:``,
          country_id:``,
          post_code:``,
          password_confirmation:'',
          on_sales:true
        },
        organization_id: ``,
        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,

        user_mode:``,
        action:'loader',
        message: ``

      };
      return {...state, data};

    default: return state
  }

  function buildErrors()
  {
    data.errors = errorsList(errors)

    data.errorsPresent = errorsPresent(errors);
  }

  function errors(field)
  {
    let { payload, submitted, serverValidationErrors } = data;
    let errors = {};
    Object.keys(payload).map(key => errors[key] = []);

    if (submitted)
    {
      if (field === `first_name`)
      {
        if (!_(field)) newError(field,0, `is required field`);
      }
      if (field === `last_name`)
      {
        if (!_(field)) newError(field,0, ` is required field`);
      }
      if (field === `address`)
      {
        if (!_(field)) newError(field,0, ` is required field`);
      }

      if (field === `country_id`)
      {
        if (!_(field)) newError(field,0, ` is required field`);
      }

      if (field === `post_code`)
      {
        if (!_(field)) newError(field,0, ` is required field`);
      }
      if (field === `city`)
      {
        if (!_(field)) newError(field,0, ` is required field`);
      }

      if (field === `email`)
      {
        if (!_(field).length) newError(field,0, ` is required field`);
        else if (_(field).length > 42) newError(field, 0, `is too Long`);
        else if (!isValidEmail(_(field))) newError(field, 0, `is invalid`);
      }
      if (field === `password`)
      {
        if(endOfURL() === 'create') {
          if (!_(field)) newError(field,0, ` is required field`);
        }
      }
      if (field === `role`)
      {
        if (!_(field)) newError(field,0, ` is required field`);
      }
    }

    errors = mergeServerValidationErrors(errors, serverValidationErrors);

    return field ? errors[field] : errors;

    function newError(field, step, message)
    {
      errors && errors[field].push({ step, message })
    }
    function _(field)
    {
      return data.payload[field]
    }
  }
}