import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getsMigrationTracking from "./migration_tracking_gets";
import store from "../../../Store"

function _success(success) {
    return {type: 'DELETE_MIGRATION_TRACKING_SUCCESS', success}
}

function _error(error) {
    return {type: 'DELETE_MIGRATION_TRACKING_ERROR', error}
}

function _processing(processing) {
    return {type: 'DELETE_MIGRATION_TRACKING_PROCESSING', processing}
}

function deleteMigrationTracking(id) {
    return (dispatch) => {
        dispatch(_error(null))
        dispatch(_processing(true))

        let config = {
            headers: {'Authorization': 'Bearer ' + loginToken()},
        };

        axios.delete(apiBaseUrl(`admin/internal-migration-tracking/${id}`), config)

            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                store.dispatch(getsMigrationTracking())
            }).catch(function (error) {
            dispatch(_error(error.response.data.data))
            dispatch(_processing(false))
            handleError(error)
        })
    }
}

export default deleteMigrationTracking
