import React from 'react';
import store from '../../../Store';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import { toggleRoleListFilter } from './RoleList-Action'
import {unslufigy} from "../../../helpers/StringHelper";
import {Link} from "react-router-dom";
import Button from "../../../components/shared/buttons/Button";

class RoleListFilters extends React.Component
{
  componentDidMount()
  {

    // store.dispatch(setDefaultRoleFiltersToURL(this.props.history));
  }

  toggleFilter = (e) =>
  {
    store.dispatch(toggleRoleListFilter( e, this.props.history));
  }

  render()
  {
    let { title, params } = this.props;

    return (
        <div className="heading-block pr-60">
          <div className="title-block">
            <h2>{ unslufigy(title) }</h2>
          </div>
          <div className="search-bar">
              <input type="text"
                     className="form-control"
                     name="q"
                     placeholder= {`Search ${unslufigy(title)}`}
                     value={params.q}
                     onChange={this.toggleFilter}
              />
            <span className="icon-search"/>
          </div>
          <div className="button-wrapper">
            <Link to={`/role/create`}>
              <Button className="btn-theme btn-dark-blue"
                      title={`Add Role`}
                      text={`Add Role`} />
            </Link>
          </div>
        </div>

    )
  }
}

function mapStateToProps(state)
{
  let {  getRoles , roleList } = state;

  let {params} = roleList.data;


  return {  roleList , getRoles , params};
}
export default withRouter( connect( mapStateToProps )( RoleListFilters ));