import React from 'react';
import store from '../../../Store';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {unslufigy} from "../../../helpers/StringHelper";
import {toggleExpiredBackupListFilter} from "./Expired-Backup-Action";

class ExpiredBackupListFilters extends React.Component {

    toggleFilter = (e) => {
        store.dispatch(toggleExpiredBackupListFilter(e, this.props.history));
    }

    render() {
        let {title, params} = this.props;

        return (
            <div className="heading-block pr-60">
                <div className="title-block">
                    <h2>{unslufigy(title)}</h2>
                </div>
              {/*  <div className="search-bar">
                    <input type="text"
                           className="form-control"
                           name="q"
                           placeholder={`Search ${unslufigy(title)}`}
                           value={params.q}
                           onChange={this.toggleFilter}
                    />
                    <span className="icon-search"/>
                </div>*/}
               {/* <div className="button-wrapper">
                    <Link to={`/${title}/create`}>
                        <Button className="btn-theme btn-dark-blue"
                                title={`Add ${unslufigy(title)}`}
                                text={`Add ${unslufigy(title)}`}/>
                    </Link>
                </div>*/}
            </div>

        )
    }
}

function mapStateToProps(state) {
    let {getExpiredBackup, expiredBackupList} = state;
    let {success} = getExpiredBackup;

    let list =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data;


    let {params} = expiredBackupList.data;


    return {list, getExpiredBackup, params};
}

export default withRouter(connect(mapStateToProps)(ExpiredBackupListFilters));