
const DefaultSiteDashboardState ={
 site_id:``,
 site_name:``,
  org_flag:false,
 site_status:``,
 site_notification:``,
 site_message:``,
 link: `sftp`
}

export const SiteDashboardState = {data:DefaultSiteDashboardState}