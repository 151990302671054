import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import {clone} from '../../../helpers/DataHelper'
import {cancelPlanFormDispatch, planError, planFormServerValidationErrors, setDefaultPlanForm}
    from '../../../pages/site/plan/form/PlanForm-Action'
import store from '../../../Store';
import {scrollToError} from '../../../helpers/ScrollHelper'
import getSiteStatus from "../sites/site_status_get";
import getSite from "../sites/site_get"
import {setDefaultPlanForUpdate} from "../../../pages/site/plan/list/PlanList-Action";

function _success(success) {
    return {type: 'PUT_PLAN_SUCCESS', success};
}

function _error(error) {
    return {type: 'PUT_PLAN_ERROR', error};
}

function _processing(processing) {
    return {type: 'PUT_PLAN_PROCESSING', processing};
}

function updatePlan() {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_error(null));

        let {planList, organizationDashboard, siteDashboard} = getState();
        let data = clone(planList.data.payload);
        let organization_id = organizationDashboard.data.organization_id;
        let {site_id} = siteDashboard.data;

        axios({
            url: apiBaseUrl(`admin/organizations/${organization_id}/sites/${site_id}/plans`),
            method: "put",
            dataType: 'json',
            data,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res)
                store.dispatch(setDefaultPlanForUpdate())
                store.dispatch(getSite(site_id))
                store.dispatch(getSiteStatus(site_id));
            })
            .catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error )
            });
    }
}


export default updatePlan;