import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleErrorMessageWithOutStatusCode, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import {scrollToError} from '../../../helpers/ScrollHelper'
import {
  cancelCouponFormDispatch, currencyError
} from "../../../pages/coupon/form/CouponForm-Action";

function _success(success) {
  return { type: 'UPDATE_COUPON_ADMIN_SUCCESS', success }
}

function _error(error) {
  return { type: 'UPDATE_COUPON_ADMIN_ERROR', error }
}

function _processing(processing) {
  return { type: 'UPDATE_COUPON_ADMIN_PROCESSING', processing }
}

function createCouponAdmin(id) {
  return (dispatch, getState) => {

    let {couponForm} = getState()
    dispatch(_success(null))
    dispatch(_error(null))
    dispatch(_processing(true))

    axios({
      url: apiBaseUrl(`admin/coupons/${id}/apply`),
      method: 'post',
      dataType: 'json',
      data: couponForm.data.payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    })
        .then(function (res) {
          dispatch(_success(res))
          dispatch(_processing(false))
          // handleSuccess(res)
          handleSuccess("Coupon Applied Successfully")
          store.dispatch(cancelCouponFormDispatch())

        }).catch(function (error) {
      dispatch(_error(error.response.data.message))
      dispatch(_processing(false))
      handleError(error)

      store.dispatch(cancelCouponFormDispatch())
      if (error.response && error.response.status === 422) {
        if (error.response.data.errors[0][0] === "coupon is already used by the organization") {
          let invalidCouponError = {"message": "coupon is already used by the organization."}
          handleErrorMessageWithOutStatusCode(invalidCouponError)
        }
        else{
        // store.dispatch(couponFormServerValidationErrors(error.response.data.errors))
        store.dispatch(currencyError(error.response.data))
          }
        scrollToError()
      }
    })
  }
}

export default createCouponAdmin
