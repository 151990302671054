import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    onPriceFormModeChange,
    setDefaultPriceForm,
    setPriceDataForUpdate
} from "./PriceForm-Action";
import {endOfURL} from "../../../helpers/UrlHelper";
import getPrice from "../../../ajax/actions/price/price_get";
import updatePrice from "../../../ajax/actions/price/price_put";
import createPrice from "../../../ajax/actions/price/price_post";

class PriceFormWorker extends React.Component {
    constructor(props) {
        super(props);
        if (props.match.params.id) {
            store.dispatch(getPrice(props.match.params.id));
            store.dispatch(onPriceFormModeChange('update'));
        }
        if (endOfURL() === 'create') {
            store.dispatch(setDefaultPriceForm())
        }
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode,} = this.props;

        if (this.props.getPrice !== prev.getPrice) {
            let {success} = this.props.getPrice;
            if (success) {
                store.dispatch(setPriceDataForUpdate());
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                if (mode === 'create') {
                    store.dispatch(createPrice())
                } else {
                    store.dispatch(updatePrice(this.props.match.params.id))
                }
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {priceForm, updatePrice,createPrice, getPrice} = state;
    let {dispatchAPI, mode, payload} = priceForm.data;
    return {priceForm, dispatchAPI, mode, payload,updatePrice,getPrice, createPrice};
}

export default withRouter(connect(mapStateToProps)(PriceFormWorker));