export function setPlanMode( mode )
{
  return function (dispatch) {
    dispatch({type:'SET-PLAN-MODE', mode});
  }
}

export function setPlanId( plan_id )
{
  return function (dispatch) {
    dispatch({type:'SET-PLAN-ID-FOR-UPDATE', plan_id});
  }
}

export function setDefaultPlanForUpdate(  )
{
  return function (dispatch) {
    dispatch({type:'SET-DEFAULT-PLAN-UPDATE'});
  }
}
