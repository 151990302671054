import React from 'react'
import {connect} from 'react-redux';
import store from '../../../Store';
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Page from "../../../components/shared/pages/Page";
import MigrateWorker from "./MigrateWorker";
import MigrateHeading from "./MigrateHeading";
import TableHead from "../../../components/shared/table/TableHead";
import Loading from "../../../components/shared/loaders/Loading";
import {ActivityLogStatus} from "../../../components/shared/status/Status";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import {withRouter} from "react-router";
import swal from "sweetalert";
import deleteMigration from "../../../ajax/actions/migration/migration_delete";
import getMigration from "../../../ajax/actions/migration/migration_get";
import {toggleMigrateFilter, toggleMigrateFormModal} from "./Migrate-Actions";
import MigrateForm from "../form/MigrationForm";
import ResponsiveModalForm from "../../../components/shared/modals/ResponsiveModalForm";
import {onMigrationFormModeChange} from "../form/MigrationForm-Action";

class Migrate extends React.Component {

    state = {
        initialLoading: false
    }

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
        window.scrollTo(0, 0);
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    toggleSort = (field) => {
        store.dispatch(toggleMigrateFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this migration ? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id)
    {
        store.dispatch(deleteMigration(id));
    }

    editMigration=(id)=>{
        store.dispatch(getMigration(id));
        store.dispatch(onMigrationFormModeChange('update'))
        store.dispatch(toggleMigrateFormModal());
    }

    updateMigration=(id)=>{
        store.dispatch(getMigration(id));
        store.dispatch(onMigrationFormModeChange('viewMigration'))
        this.props.history.push(`/migration-details/${id}`)
    }


    render() {
        let {list, params, fields, getsMigration, pages, migrate} = this.props
        let {processing} = getsMigration
        return (
            <>
                <Page title="Migration">
                    <div className="page-content">
                        <div className="content-section">
                            <MigrateHeading/>
                            <ResponsiveModalForm visible={migrate.mode}
                                                 closeModal={() => store.dispatch(toggleMigrateFormModal())}>
                                <MigrateForm/>
                            </ResponsiveModalForm>
                            <div className="table-block table-responsive pr-60 table-list-block">
                                <table className="table table-striped ">
                                    <TableHead fields={fields}
                                               params={params}
                                               noActions={true}
                                               onClick={this.toggleSort}/>
                                    <tbody>
                                    {processing ? <td colSpan={5} className="text-center"><Loading/></td> :

                                        list && list.length ? list.map((l, key) => {
                                                return (
                                                    <tr key={key}>
                                                        {/*<td>{fullDate(l.created_at)}</td>*/}
                                                        <td className="onclick" onClick={()=> this.updateMigration(l.id)}>{l.customer.first_name} {l.customer.last_name}</td>
                                                        <td>{l.site_name || "-"}</td>
                                                        <td className="onclick" onClick={()=> this.editMigration(l.id)}>
                                                            <ActivityLogStatus status={l.status}/>
                                                            <i className={`icon-${l.status === "failed" || l.status === "cancelled" ||  l.status === "rejected" ? "close-circle" : 'checkmark'} text-${l.status === "failed" ? "danger" : l.status === "pending" ? "primary" : "success"}`}/>
                                                        </td>
                                                        <td>
                                                            <div className="dropdown dropdown-alt text-center">
                                                                    <span className="dropdown-toggle"
                                                                          data-toggle="dropdown">
                                                                        <span className="icon-more"/>
                                                                    </span>
                                                                <div className="dropdown-menu">
                                                                    <a href="javascript:void(0);" onClick={()=>this.updateMigration(l.id)} >
                                                                        View
                                                                    </a>
                                                                    <a href="javascript:void(0);" onClick={()=>this.editMigration(l.id)} >
                                                                        Update Status
                                                                    </a>
                                                                    <a href="javascript:void(0);" onClick={()=>this.deleteConfirm(l.id)} >
                                                                        Delete
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                )
                                            }) :
                                            <tr>
                                                <td colSpan="5" className="text-center">No Migrations
                                                </td>
                                            </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                            {list && list?.length ?
                            <div className="fixed-footer pr-lg-0" id="inside-organization-pagination">
                                    <PaginationButtons pages={pages}
                                                       processing={processing}
                                                       fetchAction={toggleMigrateFilter}/>
                                </div>:null
                           }
                        </div>
                    </div>
                    <MigrateWorker/>
                </Page>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {route, getsMigration, migrationList} = state;
    let {payload, params, fields, migrate} = migrationList.data

    let {success} = getsMigration
    /*for original list with pagination*/
    let list = success && success.data
    /*test list without pagination*/
    // let list =  success

    let current_page = success && success.current_page;
    let last_page = success && success.last_page;
    let total = success && success.total;
    let from = success && success.from;
    let pages = {current_page, last_page, total, from};

    return {route, getsMigration, list, params, fields, pages, migrate};
}

export default withRouter(connect(mapStateToProps)(Migrate));