import { DataCenterFormState } from "./DataCenterForm-State";
import {
  errorsList,
  errorsPresent,
  mergeServerValidationErrors,
} from "../../../helpers/ErrorHelper";
import { endOfURL } from "../../../helpers/UrlHelper";
import { isObject } from "../../../helpers/DataHelper";

export default function dataCenterFormReducer(
  state = DataCenterFormState,
  action
) {
  let { data } = { ...state };
  let { validationErrors, name, value, dataCenters, mode } = action;
  switch (action.type) {
    case "ON-DATA-CENTER-FORM-CHANGE":
      data.payload[name] = value;
      buildErrors();
      return { ...state, data };

    case "ON-DATA-CENTER-FORM-MODE-CHANGE":
      data.mode = mode;
      buildErrors();
      return { ...state, data };

    case "SET-DATA-CENTER-DATA-FOR-UPDATE":
      data.payload = {
        name: dataCenters.name,
        display_name: dataCenters.display_name,
        status: dataCenters.status,
        availability: dataCenters.availability,
        latitude: dataCenters.latitude !== null ? dataCenters.latitude : ``,
        longitude: dataCenters.longitude !== null ? dataCenters.longitude : ``,
      };
      buildErrors();
      return { ...state, data };

    case "SET-DEFAULT-DATA-CENTER-FORM":
      data = {
        payload: {
          name: ``,
          display_name: ``,
          status: ``,
          availability: ``,
          latitude: ``,
          longitude: ``,
        },

        errors: [],
        errorsPresent: false,
        submitted: false,
        dispatchAPI: false,
        mode: endOfURL(),
        message: ``,
      };
      buildErrors();
      return { ...state, data };

    case "CANCEL-DATA-CENTER-FORM-DISPATCH":
      data.dispatchAPI = false;
      data.submitted = false;
      buildErrors();
      return { ...state, data };

    case "ON-DATA-CENTER-FORM-SUBMIT":
      data.submitted = true;
      buildErrors();
      if (!data.errorsPresent) data.dispatchAPI = true;
      return { ...state, data };

    case "DATA-CENTER-FORM-SERVER-VALIDATION-ERRORS":
      validationErrors &&
        isObject(validationErrors) &&
        Object.keys(validationErrors).map(
          (key) =>
            (data.errors[key] = [
              { message: ` - ${validationErrors[key][0]}`.slice(0, -1) },
            ])
        );
      data.dispatchAPI = false;
      return { ...state, data };

    default:
      return state;
  }

  function buildErrors() {
    data.errors = errorsList(errors);
    data.errorsPresent = errorsPresent(errors);
  }

  function errors(field) {
    let { payload, submitted, validationErrors } = data;
    let errors = {};
    Object.keys(payload).map((key) => (errors[key] = []));

    if (submitted) {
      if (field === `name`) {
        if (!_(field)) newError(field, 0, `This is required field`);
      }

      if (field === `display_name`) {
        if (!_(field)) newError(field, 0, `This is required field`);
      }

      if (field === `status`) {
        if (!String(_(field))) newError(field, 1, `This is required field`);
      }

      if (field === `availability`) {
        if (!String(_(field))) newError(field, 1, `This is required field`);
      }
      if (payload.latitude !== "" && payload.latitude) {
        if (field === `longitude`) {
          if (!_(field)) newError(field, 0, `This is required field`);
        }
      }
      if (payload.longitude !== "" && payload.longitude) {
        if (field === `latitude`) {
          if (!_(field)) newError(field, 0, `This is required field`);
        }
      }
    }

    errors = mergeServerValidationErrors(errors, validationErrors);

    return field ? errors[field] : errors;

    function newError(field, step, message) {
      errors && errors[field].push({ step, message });
    }

    function _(field) {
      return data.payload[field];
    }
  }
}
