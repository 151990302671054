import React from 'react';
import store from '../../../Store';
import withRouter from "react-router/withRouter";
import getOrganizationUsers from '../../../ajax/actions/organization-user/users_get'
import {connect} from "react-redux";
import {setOrganizationDashboardData} from "../../organization/OrganizationDashboard-Action";
import getOrganizations from "../../../ajax/actions/organization/organizations_get";
import {setOrganizationIDSite} from "../../site/list/SiteList-Actions";
import getSites from "../../../ajax/actions/sites/sites_get";
import getOrganization from "../../../ajax/actions/organization/organization_get";

class UserWorker extends React.Component {

componentDidMount()
{
  store.dispatch(getOrganizationUsers(this.props.match.params.org_id));
  let id = this.props.match.params.org_id
  store.dispatch(setOrganizationIDSite( id ));
  store.dispatch(getSites(id));
  store.dispatch(getOrganization(id));
}

  componentDidUpdate(prevProps) {
    if (this.props.getOrganization !== prevProps.getOrganization) {
      let {success} = this.props.getOrganization;
      if (success) {
        store.dispatch(setOrganizationDashboardData());
        store.dispatch(getOrganizations());
      }
    }
  }

  render() {
    return null
  }
}
function mapStateToProps( state ) {
  let { getOrganizationUsers , organizationDashboard ,getOrganization} = state;
  return { getOrganizationUsers ,organizationDashboard, getOrganization}
}
export default withRouter(connect(mapStateToProps)(UserWorker));


