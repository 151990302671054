import store from '../../../Store';
import {toggleURLQuery} from "../../../helpers/UrlHelper";
import getGlobalActivityLogs from "../../../ajax/actions/global-activity-log/global_activity_logs_get";

export function toggleGlobalActivityListFilter(e, history,)
{
  return function (dispatch ) {
    let { name, value  } = e.target;
    toggleURLQuery(name, value, history)
    dispatch({type:'TOGGLE-GLOBAL-ACTIVITY-LIST-FILTER', name, value});
    store.dispatch(getGlobalActivityLogs());
  }
}

/*export function onGlobalActivityLogSiteChange(site_id) {
  return function (dispatch) {
    dispatch({type:'ON-GLOBAL-ACTIVITY-LOG-SITE-CHANGE', site_id});
    store.dispatch( getGlobalActivityLogs( ) );
  }
}

export function onGlobalActivityLogSiteReset() {
  return function (dispatch) {
    dispatch({type:'ON-GLOBAL-ACTIVITY-LOG-SITE-RESET'});
    store.dispatch( getGlobalActivityLogs( ) );
  }
}*/

export function onGlobalActivityLogEventChange(event,history) {
  return function (dispatch) {
    toggleURLQuery('page', 1 , history)
    dispatch({type:'ON-GLOBAL-ACTIVITY-LOG-EVENT-CHANGE', event,history});
    store.dispatch( getGlobalActivityLogs( ) );
  }
}

export function onGlobalActivityLogEventReset(history) {
  return function (dispatch) {
    toggleURLQuery('page', '' , history)
    dispatch({type:'ON-GLOBAL-ACTIVITY-LOG-EVENT-RESET'});
    store.dispatch( getGlobalActivityLogs( ) );
  }
}