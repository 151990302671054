import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
    return {type: 'GET_LOGS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_LOGS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_LOGS_PROCESSING', processing};
}

function getLogs(id , orgId ) {
    return (dispatch, getState) => {
        let {
            logsList, organizationDashboard
        } = getState();
        let org_id = orgId || organizationDashboard.data.organization_id;
        let site_id = id || logsList.data.site_id
        let {params} = logsList.data;
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/tools/logs`),
            method: "get",
            dataType: 'json',
            params,
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                dispatch(_success(res.data.data));
                dispatch(_processing(false));
            }).catch(function (error) {
            handleError(error);
            dispatch(_error(error));
            dispatch(_processing(false));
        });


    }
}


export default getLogs;