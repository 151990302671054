import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import {onPlanFormChange, onPlanFormSubmit} from "./PlanForm-Action";
import SelectField from "../../../../components/shared/form/SelectField";
import PlanFormWorker from "./PlanFormWorker";
import {withRouter} from "react-router";
import Loading from "../../../../components/shared/loaders/Loading";
import Plan from "../../../../assets/images/plan.svg";


class PlanForm extends React.Component {

    PlanSubmit = (e) => {
        e.preventDefault();
                store.dispatch( onPlanFormSubmit()  )
    };

    onChange = (e) => {
        e.preventDefault();
        store.dispatch(onPlanFormChange(e.target.name, e.target.value,));
    };

    render() {
        let {
            errors, site_status, payload,list, createPlan, wp_flag
        } = this.props;
        let {processing} = createPlan
        let planList = [];

        list && list.map(plan => {
            if (wp_flag === 1) {
                if (plan.site_type === "Wordpress")
                    planList.push({value: plan.id, label: plan.description})
            }
            if (wp_flag === 0) {
                if (plan.site_type === "Blank")
                    planList.push({value: plan.id, label: plan.description})
            }
        })
        return (
            <>
                <PlanFormWorker/>
                <form  onSubmit={this.PlanSubmit}>
                    <div className="padded-block">
                        <div className="d-flex justify-content-between align-items-center col-md-12 px-0" id="form-block-section">
                            <div className="d-flex text-section">
                                <img alt="logo" src={Plan}/>
                                <div>
                                    <p id="site-tools">
                                        Select Plan
                                    </p>
                                    <h3>Plan</h3>
                                </div>
                            </div>
                                <div className="d-flex px-0 input-and-button-section justify-content-between">
                                    <div className="px-0 input-section mr-5">
                                        <SelectField name="plan_id"
                                                     value={payload.plan_id}
                                                     options={planList}
                                                     errors={errors.plan_id}
                                                     onChange={this.onChange}
                                                     className="px-0 mb-0"
                                                     _value="value"
                                                     _label="label"
                                                     label="Select Plan"
                                                     />
                                    </div>
                                    <div className="button-wrapper">
                                            <button type="submit" className="btn-theme btn-outline" id="admin-org-btn"
                                                    disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring'? 'disabled' : null}

                                                    onClick={this.PlanSubmit}
                                            >
                                                {
                                                    processing ? <>Create Plan <Loading/> </> : `Create Plan`
                                                }
                                            </button>
                                    </div>
                                </div>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {planForm, getSite,getSiteStatus,
        siteDashboard, getPlans,createPlan
    } = state;
    let list = getPlans?.success?.plans
    let {errors, payload} = planForm.data;
    let { site_status,org_id } = siteDashboard.data;
    let wp_flag = getSite && getSite.success && getSite.success.wp_flag


    return {
        planForm, getSite, getSiteStatus, org_id, site_status, payload,errors, list,createPlan, wp_flag
    };
}

export default withRouter(connect(mapStateToProps)(PlanForm));
