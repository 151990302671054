
const DefaultRoleListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    sort_order:  'desc',
    // sort_id: `id`,
    sort_by: `name`,
    q: ``,
  },
  fields: [
    // { field: `id`, label: `ID`, sort: true },
    { field: `name`, label: `Name`, sort: true },
    { field: `slug`, label: `Slug`, sort: true },
    { field: `action`, label: `Action`, sort: false },
    // { field: `shared`, label: `Shared`, sort: true },
  ]
}

export const RoleListState = {data:DefaultRoleListState}