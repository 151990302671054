import React from 'react';
import store from '../../../Store'
import {
  setAdminDataForUpdate,
  setDefaultUserData,
} from './UserForm-Actions'
import { endOfURL, urlTrailWithoutLastItem } from '../../../helpers/UrlHelper'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import createOrganizationUser from '../../../ajax/actions/organization-user/user_post'
import getOrganizationUser from "../../../ajax/actions/organization-user/user_get";
import updateOrganizationUser from "../../../ajax/actions/organization-user/user_put";
import getCustomerRoles from "../../../ajax/actions/role/customer_roles_get";
import getOrganizations from "../../../ajax/actions/organization/organizations_get";
import {setOrganizationID} from "./UserForm-Actions";
import getCountries from "../../../ajax/actions/country/countries_get";

class OrganizationUserFormWorker extends React.Component
{
  constructor(props) {
    super(props);
    if(props.match.params.id) {
      store.dispatch(getOrganizationUser( this.props.organization_id  ,props.match.params.id));
    }
    store.dispatch(getCountries())
  }
  componentDidMount() {

    if(endOfURL() === 'create') {
      store.dispatch(setDefaultUserData())
    }
    store.dispatch(getCustomerRoles())
    store.dispatch( setOrganizationID( this.props.organization_id  ) );
    store.dispatch(getOrganizations())
    // store.dispatch(getOrganizationUser( this.props.organization_id ,this.props.match.params.id));

  }

  componentDidUpdate(lastProps)
  {
    let { dispatchAPI } = this.props;
    if ( dispatchAPI ) {

      if ( !lastProps.dispatchAPI ) {
        if(endOfURL() === `create`) {
          store.dispatch( createOrganizationUser(this.props.organization_id  ));
        }
        else {
          store.dispatch(  updateOrganizationUser(this.props.organization_id  ,this.props.match.params.id));
        }

      }
    }

    if( this.props.getOrganizationUser !== lastProps.getOrganizationUser ) {
      let { success } = this.props.getOrganizationUser;
      if( success ) {
        store.dispatch( setAdminDataForUpdate() )
      }
    }

    if( this.props.createOrganizationUser !== lastProps.createOrganizationUser ) {
      let { success } = this.props.createOrganizationUser;
      if( success ) {
        this.props.history.push(urlTrailWithoutLastItem());
      }
    }
    if( this.props.updateOrganizationUser !== lastProps.updateOrganizationUser ) {
      let { success } = this.props.updateOrganizationUser;
      if( success ) {
        this.props.history.push(`/organizations/${this.props.organization_id}/users`);
      }
    }
  }

  render()
  {
    return null
  }
}

function mapStateToProps(state) {
  let { getOrganizationUser, createOrganizationUser, updateOrganizationUser, organizationUserForm , getRoles } = state;
// console.log(` <| organizationUserForm |> `, organizationUserForm)
  let { dispatchAPI } = organizationUserForm.data

  return { getOrganizationUser, createOrganizationUser, updateOrganizationUser, organizationUserForm, dispatchAPI ,getRoles};
}
export default withRouter(connect( mapStateToProps )( OrganizationUserFormWorker ));