import {endOfURL} from "../../../helpers/UrlHelper";
import {fullDateStringNoTime} from "../../../helpers/DateTimeHelper";

const DefaultCouponFormState = {
    payload: {
        name: ``,
        type: 'flat',
        effective_from: fullDateStringNoTime(new Date()),
        effective_to: fullDateStringNoTime(new Date()),
        total_usage_amount:'',
        status:'',
        value: ``,
        customer_id: ``,
        organization_id: ``,
    },

    errors: [],
    currencyError: '',
    errorsPresent: false,
    submitted: false,
    submittedStatus:false,
    dispatchAPI: false,
    mode: endOfURL(),
    message: ``
};

export const CouponFormState = {data: DefaultCouponFormState};