const DefaultMigrateState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    order:  'desc',
    sort_by: ``,
    q: ``,
  },
  migrate: {
    mode:''
  },

  fields: [
    // { field: `created_at`, label: `Date`, sort: true },
    { field: `customer_name`, label: `User`, sort: false },
    { field: `site_name`, label: `Site`, sort: false },//display_name
    { field: `status`, label: `Status`, sort: true },
    { field: `event`, label: `Action`, sort: false },

  ]
}

export const MigrateState = {data:DefaultMigrateState}