import axios from 'axios';
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import { handleError } from '../../../helpers/ErrorHelper'

function _success(success)
{
  return { type: 'GET_COUPON_SUCCESS', success };
}
function _error(error)
{
  return { type: 'GET_COUPON_ERROR', error };
}
function _processing(processing)
{
  return { type: 'GET_COUPON_PROCESSING', processing };
}

function getCoupon( id )
{
  return ( dispatch ) =>
  {
    dispatch(_processing(true));
     axios({

      url: apiBaseUrl(`admin/coupons/${id}`),
      method: "get",
      dataType: 'json',
      headers: { 'Authorization': 'Bearer ' +loginToken() }
    })
        .then(function (res) {
          dispatch(_success(res.data.data));
          dispatch(_processing(false));
        }).catch(function (error) {
          handleError(error);
          dispatch(_error(error));
          dispatch(_processing(false));
        });
  }
}


export default getCoupon;