// import errorSound from "./assets/sounds/error.wav"
import base64 from "base-64";

export const ConfigApp = {
  Name: `Kepler Hosting`,
  Production: true, // comment this out for Staging Server, uncomment for Live Server
};

export const ConfigIntercom = {
  AppID:
    // "lxjolfzh" // comment this out when on Live Server
    "gobsaoo3", // comment this out when on Staging Server
};

export const TheApp = {
  Engine: {
    Production: true,
    Maintenance: true,
  },
  Title: "kepler-hosting",

  Pages: {
    Tree: [{ page: `login`, permission: "all" }],
  },

  /* fa - FontAwesome | ii - IonIcon */
  icons: [
    { id: `login`, icon: `clipboard-list`, type: `fa` },

    // { id: `history`, icon: `map-marker-alt`, type: `fa` },
    // { id: `customerComments`, icon: `comment-alt`, type: `fa` },
  ],
};

export const token = {
  auth: base64.encode("oskar:J0nsson13"),
};

export const ConfigErrorAlert = {
  position: "top-right",
  effect: "jelly",
  timeout: 4000,
  beep: false,
  stack: 1,
};
export const ConfigSuccessAlert = {
  position: "top-right",
  effect: "jelly",
  timeout: 4000,
  beep: false,
  stack: 1,
};

export const ConfigNotificationSuccessAlert = {
  position: "top-right",
  effect: "jelly",
  timeout: 6000,
  beep: false,
  stack: 1,
};

export const ConfigModal = {
  width: 650,
  height: 500,
  effect: `fadeInUp`,
};

export const ConfigSider = {
  MenuItems: {
    admin: [
      {
        link: `/site/3`,
        label: `Sites`,
        icon: "icon-browser",
        class: "files-link",
      },
      {
        link: `/users`,
        label: `Users`,
        icon: "icon-user",
        class: "users-link",
      },
      { link: `/role`, label: `Role`, icon: "icon-user", class: "users-link" },
      // { link: `/permission`, label: `Permission`, icon:'icon-user', class:'users-link' },
      {
        link: `/activity-log`,
        label: `Activity Log`,
        icon: "icon-bar_diagram",
        class: "activity-link",
      },
      {
        link: `/analytics`,
        label: `Analytics`,
        icon: "icon-pie_chart",
        class: "analytics-link",
      },
      {
        link: `/billing`,
        label: `Billing`,
        icon: "icon-pie_chart",
        class: "billing-link",
      },
    ],
  },
};
