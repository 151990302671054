import { RoleListState } from './RoleList-State'

export default function roleListReducer(state = RoleListState, action) {

  let { data } = { ...state }
  let {name, value } = action;

  switch (action.type) {

    case 'TOGGLE-ROLE-LIST-FILTER':
       data.params[ name ] = value;
      // if ( name === `sort_id` ) {
      //   if ( data.params.sort_id === value ) {
      //     data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
      //   }
      // }
      if ( name === `sort_by` ) {
        if ( data.params.sort_by === value ) {
          data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
        }
      }
      let toggle = true;
      if (toggle) {
        if (name === 'limit') {
          data.params.page = 1;
        }
        data.params[name] = value;
      }
      return { ...state, data }

    default: return state
  }
}
