import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'GET_MANUAL_SITE_BACKUP_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_MANUAL_SITE_BACKUP_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_MANUAL_SITE_BACKUP_PROCESSING', processing};
}

function getSiteBackup(id=null ,organization_id = null) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {backupList, organizationDashboard} = getState();

        let {params, site_id} = backupList.data;
        let siteId = id || site_id

        let org_id = organization_id || organizationDashboard.data.organization_id;

        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${siteId}/backups?type=manual`),
            method: "get",
            dataType: 'json',
            params,
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
            }).catch(function (error) {
            handleError(error);
            dispatch(_error(error));
            dispatch(_processing(false));
        });
    }
}

export default getSiteBackup;