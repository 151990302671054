import {emptyObject, isString} from './DataHelper';
import Alert from 'react-s-alert';
import {ConfigErrorAlert, ConfigSuccessAlert} from "../Config";


export const handleError = error => {
    if (error) {
        if (!error.response) {
            if (error.message) {
                Alert.error(`Error ${500} - ${error.message}`, ConfigErrorAlert);
            }
        } else { /* if response */
            let {response} = error;
            let {status, data} = response;
            if (status && data) {

                if (data[0] === `<`) /* HTML error */ {
                    Alert.error(`Error ${500} - Server Error`, ConfigErrorAlert);
                } else if (status === 400) {
                    if (isString(data.message)) {
                        Alert.error(`Error ${status} - ${data.message}`, ConfigErrorAlert);
                    } else if (data.payload.message) {
                        Alert.error(`Error ${status} - ${data.payload.message}`, ConfigErrorAlert);
                    }
                } else if (status === 401) {
                    if (data.message && isString(data.message)) {
                        Alert.error(`Error ${status} - ${data.message}`, ConfigErrorAlert);
                    } else if (data.payload.message && isString(data.payload.message)) {
                        Alert.error(`Error ${status} - ${data.payload.message}`, ConfigErrorAlert);

                    }
                } else if (status === 403) {
                    if (isString(data.message)) {
                        Alert.error(`Error ${status} - ${data.message}`, ConfigErrorAlert);
                    } else {
                        Alert.error(`Error ${status} - Forbidden action.`, ConfigErrorAlert);
                    }
                } else if (status === 404) {
                    if (isString(data.message)) {
                        Alert.error(`Error ${status} - ${data.message}`, ConfigErrorAlert);
                    }
                } else if (status === 409) {
                    if (isString(data.message)) {
                        Alert.error(`Error ${status} - ${data.message}`, ConfigErrorAlert);
                    }
                } else if (status === 500) {
                    if (data.error) {
                        if (isString(data.error)) {
                            Alert.error(`Error ${status} - ${data.error}`, ConfigErrorAlert);
                        }
                    }
                }
            }
        }
    }
};

export const handle422error = errors => {
    if (errors) {
        Object.keys(errors).map(key =>
            Alert.error(`Error ${422} - ${errors[key][0]}`, ConfigErrorAlert));
    }
};

export const handle422errorWithOutStatusCode = errors => {
    if (errors) {
        Object.keys(errors).map(key =>
            Alert.error(`Error - ${errors[key][0]}`, ConfigErrorAlert));
    }
};

export const handleErrorMessageWithOutStatusCode = errors => {
    if (errors) {
        Alert.error(`Error - ${errors.message}`, ConfigErrorAlert );
    }
};

export const handleSuccessError = success => {
    if (success.data.status === 401) {
        Alert.error(`Error ${success.data.status} - ${success.data.message}`, ConfigErrorAlert );
}
};

export const handleSuccess = success =>
{

  if(success) {
    if ( isString( success )) {
      Alert.success(`
        Success - ${ success }`, ConfigSuccessAlert );
    } else {
      if ( success.data ) {
        Alert.success(`
        Success - ${ success.data.message }`, ConfigSuccessAlert );
      }  else {
        Alert.success(`
        Success - ${ success.message }`, ConfigSuccessAlert );
      }
    }

  }
};


export const errorsList = (errors, field = null) =>
{
  let _errors = {};
  if ( errors ) {
    Object.keys(errors()).map(item => _errors[item] = errors(item));
    return field ? _errors[field] : _errors;
  }
};

export const errorsPresent = (errors) =>
{
  let verified = false;
  for (let key in errorsList(errors)) {
    if (errorsList(errors, key) &&
        errorsList(errors, key).length) {
      verified = true;
      break;
    }
  }
  return verified;
};

export const mergeServerValidationErrors = (errors, serverValidationErrors) =>
{
  if ( errors && serverValidationErrors )
    if (!emptyObject(serverValidationErrors))
      Object.keys(serverValidationErrors).map(field => {
        if (serverValidationErrors[field].length)
          serverValidationErrors[field].map(err =>
              errors[field] && errors[field].push({ step: 0, message: err })
          );
      });
  return errors;
};

export const stepHasError = ( step, errors ) =>
{
  let hasError = false;
  if ( String( step ) && errors ) {
    Object.keys( errors ).map( key => {
      if ( errors[key].length ) {
        errors[key].map( err => {
          if ( err.step === step ) hasError = true;
        })
      }
    })
  }
  return hasError;
};

