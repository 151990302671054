import { SiteDashboardState } from './SiteDashboard-State'

export default function siteDashboardReducer(state = SiteDashboardState, action) {

  let { data } = { ...state };
  let {site, status, org_id, value } = action;

  switch (action.type) {
    case 'SET-SITE-DASHBOARD-DATA':
      data.site_id = site.id;
      data.site_data_center_name = site.data_center_name;
      return { ...state, data };

    case 'CHANGE-SITE-LINK':
      data.link = value
      return {...state, data};

    case 'SET-SITE-STATUS':
      data.site_status = status.status;
      // data.site_notification = status.notification;
      data.site_message = status.message;
      return { ...state, data };

    case 'SET-ORAGANIZATION-ID':
      data.org_id = org_id;
     return { ...state, data };
    default: return state
  }

}
