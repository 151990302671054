import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultMigrationTrackingFormState = {
    payload: {
        site_name: ``,
        client_verified: '',
        backup_deleted: ``,
        old_container_deleted: ``,
        status: ``,
        source_server_ip: ``,
        source_lb_ip: ``,
        destination_server_ip: ``,
        destination_lb_ip: ``,
    },

    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: endOfURL(),
    message: ``
};

export const MigrationTrackingFormState = {data: DefaultMigrationTrackingFormState};