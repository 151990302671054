import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'GET_DOWNLOAD_INVOICE_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_DOWNLOAD_INVOICE_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_DOWNLOAD_INVOICE_PROCESSING', processing};
}

function getDownloadInvoice(organization_id = null , invoice_id) {
    return ( dispatch, getState ) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        let { organizationDashboard} = getState();
        let org_id =  organization_id || organizationDashboard.data.organization_id;

        const request = axios({

            url: apiBaseUrl(`admin/organizations/${org_id}/invoices/${invoice_id}/download`),
            method: "get",
            responseType: 'blob',
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'file.pdf');
                document.body.appendChild(link);
                link.click();
                // dispatch(_success(res.data.data));
                // dispatch(_processing( false ));
            }).catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error)
            });

        return {
            type: 'GET_DOWNLOAD_INVOICE_SUCCESS',
            payload: request
        }
    }
}


export default getDownloadInvoice;