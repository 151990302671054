import { SftpState } from './Sftp-State'

export default function sftpListReducer(state = SftpState, action) {

  let { data } = { ...state };
  let {name, value ,site_id} = action;

  switch (action.type) {

    case 'TOGGLE-SFTP-LIST-FILTER':
      let toggle = true;
      if ( toggle ) data.params[ name ] = value;
      return { ...state, data };

    case 'SET-SFTPID-SFTP':
      data.site_id = +site_id;
      return { ...state, data };

    case 'ON-SFTP-SUBMIT':
      data.submitted = true;
      if (!data.errorsPresent) data.dispatchAPI = true;
      return {...state, data};

    case 'TOGGLE-SFTP-FORM-MODAL':
      if ( data.modal.mode ) { // if open, close
        data.modal = { mode: ``,  }
      } else { // if close, open
        data.modal.mode = true;
      }
      return { ...state, data };

    default: return state
  }
}

