import React from 'react';
import store from '../../../../Store'
import {setAdminDataForProfile, setLoggedinUserRole} from './ProfileForm-Actions'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import getRoles from "../../../../ajax/actions/role/roles_get";
import getCountries from "../../../../ajax/actions/country/countries_get";
import getLoggedInUser from "../../../../ajax/actions/user/user_loggedin_get";
import loggedInUserUpdate from "../../../../ajax/actions/user/loggedin_user_put"
import getOrganizationsWithoutPagination
  from "../../../../ajax/actions/organization/organizations_without_pagination_get";

class ProfileFormWorker extends React.Component
{
  constructor(props) {
    super(props);
    store.dispatch(getLoggedInUser())
    store.dispatch(getCountries());
    store.dispatch(setLoggedinUserRole());
    store.dispatch(setAdminDataForProfile());
    store.dispatch(getOrganizationsWithoutPagination());

  }

  componentDidMount() {
    store.dispatch(getLoggedInUser())
  }

  componentDidUpdate(lastProps)
  {
    let { dispatchAPI,loggedInUserId } = this.props;
    if ( dispatchAPI ) {
      if ( !lastProps.dispatchAPI ) {
          store.dispatch(  loggedInUserUpdate(loggedInUserId));
        }
      }
  }

  render()
  {
    return null
  }
}

function mapStateToProps(state) {
  let { updateUser, userForm , getRoles, getLoggedInUser } = state;

  let { dispatchAPI,mode } = userForm.data

  let {id:loggedInUserId} = getLoggedInUser?.success?.data?.data;

  return { updateUser, userForm, dispatchAPI ,getRoles, getLoggedInUser, loggedInUserId, mode};
}
export default withRouter(connect( mapStateToProps )( ProfileFormWorker ));