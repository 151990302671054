export function discountFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'DISCOUNT-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onDiscountFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-DISCOUNT-FORM-SUBMIT'})
    }
}

export function onDiscountFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-DISCOUNT-FORM-CHANGE', name, value});
    }
}

export function onDiscountFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-DISCOUNT-FORM-MODE-CHANGE', mode});
    }
}

export function cancelDiscountFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-DISCOUNT-FORM-DISPATCH'});
    }
}

export function setDefaultDiscountForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-DISCOUNT-FORM'});
    }
}

export function setDiscountDataForUpdate() {
    return function (dispatch, getState) {
        let {getDiscount} = getState();
        let discount = getDiscount && getDiscount.success;
        dispatch({type: 'SET-DISCOUNT-DATA-FOR-UPDATE', discount});
    }
}