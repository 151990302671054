const DefaultBillingTypeFormState = {
  payload:{
  billing_type:`stripe`
  },
  step:0,
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:'create',
  message: ``
};

export const BillingTypeFormState = { data: DefaultBillingTypeFormState };