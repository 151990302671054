import React from 'react';
import store from '../../../Store';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import getOrganization from "../../../ajax/actions/organization/organization_get";
import { setSiteId} from "../organization-block/form/OrganizationBlockForm-Action";
import getSite from "../../../ajax/actions/sites/site_get"

class AdminOrganizationWorker extends React.Component {
    constructor(props) {
        super(props);
        store.dispatch(setSiteId(this.props.match.params.id))
        // store.dispatch(setSiteId(this.props.match.params.id))
        if(props.organizationDashboard.organization_id) {
            // store.dispatch(setOrganizationId(this.props.organizationDashboard.organization_id))

            // store.dispatch(getSite(this.props.match.params.id, props.organizationDashboard.organization_id))
            store.dispatch(getOrganization(props.organizationDashboard.organization_id))
        }
        // store.dispatch(getSite(this.props.match.params.id, this.props.organization_id))
        // store.dispatch(getOrganization(this.props.organization_id))
    }
    componentDidUpdate(prev) {
        if(this.props.getSite !== prev.getSite){
            if(this.props.organizationDashboard.organization_id) {
                store.dispatch(getSite(this.props.match.params.id, this.props.organizationDashboard.organization_id))
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {organizationDashboard, getSiteStatus,getOrganization, getSite} = state;
    let {organization_id} = organizationDashboard.data;
    return {organizationDashboard, organization_id, getSiteStatus,getOrganization,getSite};
}

export default withRouter(connect(mapStateToProps)(AdminOrganizationWorker));