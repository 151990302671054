export function internalMigrationFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'INTERNAL-MIGRATION-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onInternalMigrationFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-INTERNAL-MIGRATION-FORM-SUBMIT'})
    }
}

export function onInternalMigrationFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-INTERNAL-MIGRATION-FORM-CHANGE', name, value});
    }
}

export function onInternalMigrationFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-INTERNAL-MIGRATION-FORM-MODE-CHANGE', mode});
    }
}

export function cancelInternalMigrationFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-INTERNAL-MIGRATION-FORM-DISPATCH'});
    }
}

export function setDefaultInternalMigrationForm() {
    return function (dispatch, getState) {
        let {getSite} = getState();
        let path = getSite && getSite.success && getSite.success.path;
        dispatch({type: 'SET-DEFAULT-INTERNAL-MIGRATION-FORM', path});
    }
}

export function setSitesId(site_id) {
    return function (dispatch) {
        dispatch({type: 'SET-SITE-ID', site_id});
    }
}


export function internalMigrationError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-INTERNAL-MIGRATION-ERROR', error});
    }
}