import store from '../../Store';
import {toggleURLQuery} from '../../helpers/UrlHelper'
import getHotfixs from "../../ajax/actions/hotfix/hotfix_get";

export function toggleHotfixListFilter(e, history) {
    return function (dispatch) {
        let {name, value} = e.target;
        toggleURLQuery(name, value, history)
        dispatch({type: 'TOGGLE-HOTFIX-LIST-FILTER', name, value, history});
        if (name === 'limit') {
            store.dispatch(getHotfixs());
            toggleURLQuery('page', '', history)
        } else {
            store.dispatch(getHotfixs());
        }
    }
}
