import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import InternalMigrationFormWorker from './InternalMigrationFormWorker'
import {onInternalMigrationFormChange, onInternalMigrationFormSubmit} from "./InternalMigrationForm-Action";
import Loading from "../../../../components/shared/loaders/Loading";
import Select from "react-select";
import swal from "sweetalert";
import InternalMigration from '../../../../assets/images/internal-migration.svg'
import {withRouter} from "react-router";

class internalMigrationForm extends React.Component {

    InternalMigrationSubmit = (e) => {
        e.preventDefault();
        swal("Are you sure you want to perform Internal Migration?", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Yes",
                    value: e
                }
            }
        }).then((e) => {
            if (e) store.dispatch( onInternalMigrationFormSubmit()  )
        })
    };

    onChange = (e) => {
        if(e === null){
            store.dispatch(onInternalMigrationFormChange("Server", ""));
        }
        else {
            store.dispatch(onInternalMigrationFormChange(e.name, e.value,));
        }
    };

    render() {
        let {
            errors, getSite, servers, site_status
        } = this.props;
        let clearable = true;

        let serversList = [];
        servers && servers.map(server => {
            serversList.push({value: server.id, label: server.name})
        });
        let {processing} = getSite.processing

        return (
            <>
                <InternalMigrationFormWorker/>
                <form className="mt-10" onSubmit={this.InternalMigrationSubmit}>
                    <div className="padded-block">
                        <div className="d-flex justify-content-between align-items-center col-md-12 px-0" id="form-block-section">
                            <div className="d-flex text-section">
                                <img alt="logo" src={InternalMigration}/>
                                <div>
                                    <p id="site-tools">Migration Tool</p>
                                    <h3>Internal Migrations</h3>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between px-0 input-and-button-section">
                                <div className="px-0 input-section mr-5">
                                    <Select
                                        name="Server"
                                        isClearable={clearable}
                                        placeholder="Select Server"
                                        value={serversList.id}
                                        options={serversList}
                                        onChange={this.onChange}
                                        errors={errors && errors.server_id}
                                    />
                                    {/*<SelectField id="server_id"*/}
                                    {/*             name="Server"*/}
                                    {/*             errors={errors.server_id}*/}
                                    {/*             options={serversList}*/}
                                    {/*             onChange={this.onChange}*/}
                                    {/*             _value="value"*/}
                                    {/*             _name="label"*/}
                                    {/*/>*/}

                                </div>
                                <div className="button-wrapper ml-2">
                                    <button type="submit" className="btn-theme btn-outline" id="admin-org-btn"
                                            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring'? 'disabled' : null}>
                                        {processing ? <Loading/> : `Submit`}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="description-section mt-5">
                            <p>Use the internal migration tool if you want to migrate a site from one Kepler DataCenter to another. Start by selecting the Server you would like to migrate to and then click submit. The Kepler system will first tack a backup, then performed a migration and restore the site to the new server.</p>
                            <p id="bold-text">Once this process is done you will need to change the DNS settings of the site. </p>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {internalMigrationForm, createInternalMigration, getSite,getSiteStatus,siteDashboard,
        getServerModules,
    } = state;
    let {errors, payload} = internalMigrationForm.data;
    let { site_status } = siteDashboard.data;

    let servers =
        getServerModules &&
        getServerModules.success &&
        getServerModules.success.data &&
        getServerModules.success.data.data &&
        getServerModules.success.data.data.data
    let {
        server_id
    } = payload;

    return {
        internalMigrationForm, server_id, errors, createInternalMigration, getSite,getSiteStatus,siteDashboard,
        servers,site_status
    };
}

export default withRouter(connect(mapStateToProps)(internalMigrationForm));