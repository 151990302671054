import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import OrganizationFormWorker from './OrganizationFormWorker'
import {
    onOrganizationFormChange,
    onOrganizationFormSubmit, onOrganizationFormTypeChange,
    selectCountry
} from "./OrganizationForm-Action";
import TextField from "../../../components/shared/form/TextField";
import Loading from "../../../components/shared/loaders/Loading";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Link from "react-router-dom/Link";
import {endOfURL} from "../../../helpers/UrlHelper";
import {ucFirst} from "../../../helpers/StringHelper";
import Page from "../../../components/shared/pages/Page";
import SelectField from "../../../components/shared/form/SelectField";
import {activeStatusFilter} from "../../../static-data/UserData";


class OrganizationForm extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(  setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    organizationFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onOrganizationFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onOrganizationFormChange(e.target.name, e.target.value,));
    };

    onChangeType = (data) => {
        store.dispatch(onOrganizationFormTypeChange(data));
    };

    handleCountry = (selectedOption) => {
        let value = "";
        if( selectedOption ) {
            value = selectedOption.target.value;
        }
        store.dispatch( selectCountry( value ) );
    }

    render() {
        let { payload,getOrganization, updateOrganization, createOrganization,  organizationForm, errorsPresent,countriesList, role } = this.props;
        let {errors} = organizationForm.data;
        let processing = updateOrganization.processing || createOrganization.processing || getOrganization.processing
        let countryList = [];
        let org_id = getOrganization.success?.id
        countriesList && countriesList.map(c => {
            countryList.push({value: c.name, label: c.name, id: c.id})
        })

        return (
            <>
                <Page title={endOfURL()=== "create" ?
                    "Add Organization" :
                    "Update Organization"
                } processing={processing}>
                <div className="heading-block pr-60">
                    <div className="title-block">
                        <h2>{ endOfURL() === 'create' ? `Add new Organization` : `Update Organization` } </h2>
                        <span><Link to="/organizations">Organizations</Link></span>
                        { endOfURL() === 'update' ? <span><Link to={`/organizations/${org_id}/sites`}>{ucFirst(payload.name)}</Link></span> : null}
                        <span>{ endOfURL() === 'create' ? `Add new Organization` : `Update` }</span>
                </div>
                </div>
                <OrganizationFormWorker/>


                <div className="box-block">
                    <ul className="nav nav-pills nav-justified">
                        <li className="nav-item" >

                            <a className={`nav-link ${ payload.type === 'company' ? 'active' : null }`}  href="/#" onClick={ev => {this.onChangeType('company');ev.preventDefault()}}
                                id={errorsPresent ? errors && errors.type && errors.type[0] && 'has-error-box' : null}>Company</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${ payload.type === 'personal' ? 'active' : null }`} href="/#" onClick={ev => {this.onChangeType('personal');ev.preventDefault()}}
                               id={errorsPresent ? errors && errors.type && errors.type[0] && 'has-error-box' : null}>Individual</a>
                        </li>
                        {/* <div className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`, `company-or-individual`)}>
                            { errorsPresent ? errors && errors.type && errors.type[0] && errors.type[0].message + '.' : null }
                        </div> */}
                    </ul>

                <div className="tab-content">
                    <div className="tab-pane fade show active" id="pills-company" role="tabpanel"
                         aria-labelledby="pills-company-tab">
                        <div className="padded-block">
                    <form onSubmit={this.organizationFormSubmit}>

                        <div className="form-row">
                            <div className="col-md-6">
                                <div className="form-group">
                            <TextField name="name"
                                       value={payload.name}
                                       label="Name"
                                       errors={errors.name}
                                       onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                        <TextField name="address_line_1"
                                   value={payload.address_line_1}
                                   label="Address line 1"
                                   errors={errors.address_line_1}
                                   onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <SelectField name="country_id"
                                                 options={countryList}
                                                 value={payload.country_id}
                                                 errors={errors && errors.country_id}
                                                 onChange={this.handleCountry}
                                                 _value="id"
                                                 _label="value"/>
                                </div>
                            </div>


                                <div className="col-md-6">
                                    <div className="form-group">
                                        <TextField name="address_line_2"
                                                   value={payload.address_line_2}
                                                   label="Address line 2"
                                                   errors={errors.address_line_2}
                                                   onChange={this.onChange}/>
                                    </div>
                                </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <TextField name="city"
                                               value={payload.city}
                                               label="City"
                                               errors={errors.city}
                                               onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <TextField name="state"
                                               value={payload.state}
                                               label="State"
                                               errors={errors.state}
                                               onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <TextField name="zip"
                                               value={payload.zip}
                                               label="zip"
                                               errors={errors.zip}
                                               onChange={this.onChange}/>
                                </div>
                            </div>
                            {role === "super-admin"?
                            <div className="col-md-6">
                                <div className="form-group">
                                    <SelectField name="billable"
                                                 value={payload.billable}
                                                 options={activeStatusFilter}
                                                 errors={errors.billable}
                                                 label="Billing"
                                                 onChange={this.onChange}
                                                 _value="value"
                                                 _label="name"/>
                                </div>
                            </div>:null}

                            {payload.type === `company` ?
                                <div className="col-md-6">
                                    <div className="form-group">
                            <TextField name="vat_id"
                                       value={payload.vat_id}
                                       label="Vat Id"
                                       errors={errors.vat_id}
                                       onChange={this.onChange}/>
                                    </div>
                                </div>
                                : null}
                            </div>

                        <div className="button-wrapper text-right d-flex justify-content-end">
                            <button type="submit"
                                    className="btn-theme btn-dark-blue">
                                {
                                processing ? <Loading/> : `Submit`
                                }

                            </button>
                        </div>
                    </form>
                </div>
                    </div>
                </div>
                </div>
                </Page>
            </>

        );
    }
}

function mapStateToProps( state ) {
    let {route, organizationForm, updateOrganization, getOrganization, createOrganization,getCountries} = state;
    let countriesList = getCountries?.success?.data.data

    let {errors, payload, mode, errorsPresent } = organizationForm.data;
    let role = route && route.data.user && route.data.user.role.slug;

    return {
        organizationForm, updateOrganization, errors,mode,
        payload, getOrganization, createOrganization, errorsPresent,getCountries,countriesList,role
    };
}

export default connect(mapStateToProps)(OrganizationForm);
