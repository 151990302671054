import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    onCouponFormModeChange,
    setDefaultCouponForm,
    setCouponDataForUpdate,
    setDefaultApplyCouponForm
} from "./CouponForm-Action";
import {endOfURL, urlTrailWithoutLastItem} from "../../../helpers/UrlHelper";
import getCoupon from "../../../ajax/actions/coupon/coupon_get";
import createCoupon from "../../../ajax/actions/coupon/coupon_post";
import updateCoupon from "../../../ajax/actions/coupon/coupon_put";
import getCurrency from "../../../ajax/actions/coupon/currency_gets";
import adminApplyDiscount from "../../../ajax/actions/coupon/coupon_apply_put";
import getOrganizationsBlock from "../../../ajax/actions/organization/organizations_block_get"

class CouponFormWorker extends React.Component {
    constructor(props) {
        super(props);
        if (props.match.params.id) {
            store.dispatch(getCoupon(props.match.params.id));
        }
        if(endOfURL() === "update"){
            store.dispatch(onCouponFormModeChange('update'));
        }
        else if (endOfURL() === 'create') {
            store.dispatch(setDefaultCouponForm())
        }
        else if (endOfURL() === 'apply') {
            store.dispatch(onCouponFormModeChange('apply'));
            store.dispatch(getOrganizationsBlock());

        }
        store.dispatch(getCurrency())
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode} = this.props;
        if (this.props.getCoupon !== prev.getCoupon) {
            let {success} = this.props.getCoupon;
            if (success) {
                if(endOfURL() === "update") {
                    store.dispatch(setCouponDataForUpdate());
                }
                else if(mode === "apply"){
                    store.dispatch(setCouponDataForUpdate())
                }
            }
        }

        if (this.props.createCoupon !== prev.createCoupon) {
            let {success} = this.props.createCoupon;
            if (success) {
                this.props.history.push(urlTrailWithoutLastItem());
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                if (mode === 'create') {
                    store.dispatch(createCoupon())
                } else if(mode === 'update') {
                    store.dispatch(updateCoupon(this.props.match.params.id))
                }
                else if(mode === 'apply') {
                    store.dispatch(adminApplyDiscount(this.props.match.params.id))
                }
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {couponForm, getCoupon, createCoupon,adminApplyDiscount,getOrganizationsBlock} = state;
    let {dispatchAPI, mode, payload} = couponForm.data;
    return {couponForm, dispatchAPI, getCoupon, mode, payload, createCoupon,adminApplyDiscount,getOrganizationsBlock};
}

export default withRouter(connect(mapStateToProps)(CouponFormWorker));