import React from 'react';
import store from '../../Store';
import getDashboard from "../../ajax/actions/dashboard/dashboard_get";
import getSuperAdminSites from "../../ajax/actions/sites/super_admin_sites_get";
import getAdminSiteStatus from "../../ajax/actions/sites/superadmin_site_status_get";
import withRouter from "react-router/withRouter";
import {connect} from "react-redux";
import {setDashboardSearch} from "./Dashboard-Actions";
import {setDefaultSearch} from "../super-admin-sites/list/SiteList-Actions";

class DashboardWorker extends React.Component {
  componentDidMount() {
    store.dispatch(getDashboard())
    store.dispatch(getSuperAdminSites());
    store.dispatch(getAdminSiteStatus());
    store.dispatch(setDashboardSearch("false"));
    store.dispatch(setDefaultSearch());
    this.periodicFetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.getOrganizationStatus !== prevProps.getOrganizationStatus) {
      store.dispatch(getAdminSiteStatus());
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  periodicFetch() {
    this.timer = setInterval(() => !this.props.getAdminSiteStatus.processing && store.dispatch(getAdminSiteStatus()), 10000)
  }

  render() {
    return null
  }
}
  function mapStateToProps( state ) {
  let {  getAdminSiteStatus , getSuperAdminSites } = state;
  return {  getAdminSiteStatus , getSuperAdminSites }
}
  export default withRouter(connect(mapStateToProps)(DashboardWorker));