import React from 'react';
import {connect} from "react-redux";
const google = window.google;
const bounds = new google.maps.LatLngBounds();
var mapStyle = [
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#e9e9e9"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f5f5f5"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#dedede"
            },
            {
                "lightness": 21
            }
        ]
    },
    {
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#ffffff"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#333333"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#f2f2f2"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#fefefe"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    }
];

class GoogleMap extends React.Component {

    initialize=()=> {
        const obj = this.props.data;
        var map = new google.maps.Map(document.getElementById('kepler-map'), {
            zoom: 8,
            panControl: false,
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            overviewMapControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: mapStyle,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        });

        var infowindow = new google.maps.InfoWindow();
        // var j;

        var icon = [
            '<?xml version="1.0"?>' +
            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="113" height="113" viewBox="0 0 113 113">\n' +
            '  <defs>\n' +
            '    <filter id="Ellipse_265" x="0" y="0" width="113" height="113" filterUnits="userSpaceOnUse">\n' +
            '      <feOffset dy="3" input="SourceAlpha"/>\n' +
            '      <feGaussianBlur stdDeviation="3" result="blur"/>\n' +
            '      <feFlood flood-color="#2d5aae"/>\n' +
            '      <feComposite operator="in" in2="blur"/>\n' +
            '      <feComposite in="SourceGraphic"/>\n' +
            '    </filter>\n' +
            '  </defs>\n' +
            '  <g id="Group_1360" data-name="Group 1360" transform="translate(-954 -738)">\n' +
            '    <g transform="matrix(1, 0, 0, 1, 954, 738)" filter="url(#Ellipse_265)">\n' +
            '      <g id="Ellipse_265-2" data-name="Ellipse 265" transform="translate(9 6)" fill="#2d5aae" stroke="#346fda" stroke-width="1" opacity="0.784">\n' +
            '        <circle cx="47.5" cy="47.5" r="47.5" stroke="none"/>\n' +
            '        <circle cx="47.5" cy="47.5" r="47" fill="none"/>\n' +
            '      </g>\n' +
            '    </g>\n' +
            '    <g id="Ellipse_266" data-name="Ellipse 266" transform="translate(1006 787)" fill="#2d5aae" stroke="#346fda" stroke-width="1">\n' +
            '      <circle cx="4" cy="4" r="4" stroke="none"/>\n' +
            '      <circle cx="4" cy="4" r="3.5" fill="none"/>\n' +
            '    </g>\n' +
            '  </g>\n' +
            '</svg>'
        ];

        for (var k in obj) {
            var loc = k.split(',');
            var latlng = new google.maps.LatLng(loc[0], loc[1]);
            var dataCenterName = obj[k][0].dataCenterName;
            var totalSite = obj[k][0].totalSites;
            var dataPercent = obj[k][0].dataPercent;
            var contentString = "<div class='map-infowindow'>" +
                "<div class='lists-wrapper'><div class='lists'><h3>" + dataCenterName + "</h3></div></div>" +
                "<div class='total-sites'>" +
                "<p>" + totalSite + "</p>" +
                "<span>" + dataPercent + "</span>" +
                "</div>" +
                "</div>";

            var marker = new google.maps.Marker({
                position: latlng,
                map: map,
                IWcontent: contentString,
                icon: {
                    url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(icon),
                    scaledSize: new google.maps.Size((20 + (totalSite ? parseFloat(totalSite) : 20)), (20 + (totalSite ? parseFloat(totalSite) : 20)))
                },
            });
            bounds.extend(marker.getPosition());
            google.maps.event.addListener(marker, 'click', function (evt) {
                infowindow.setContent(this.IWcontent);
                infowindow.open(map, this);

            });
        }

        google.maps.event.addListener(map, "click", function (event) {
            infowindow.close();
        });

        map.fitBounds(bounds);
    }

        componentDidUpdate(prev) {
            if(prev.getSuperAdminSites !== this.getSuperAdminSites){
                google.maps.event.addDomListener(window, 'load', this.initialize());

            }
            else{
                google.maps.event.addDomListener(window, 'load', this.initialize());
            }
        }

    render() {
        return (
            <>
                <div id="kepler-map" className="google-maps"></div>
            </>
        )
    }
}
function mapStateToProps(state) {
    let { getDashboard,getSuperAdminSites } = state;
    return {getDashboard,getSuperAdminSites};
}
export default  connect(mapStateToProps)(GoogleMap)