import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {setDefaultSiteForm, setOrganizationID} from "./SiteForm-Action";
import createSite from "../../../ajax/actions/sites/site_post";
import {endOfURL, urlTrailWithoutLastItem} from "../../../helpers/UrlHelper";
import getSiteCreateDataCenters from "../../../ajax/actions/data-center/site_create_data_centers_get";
import generatePassword from 'generate-password';
import getWordPressLangugae from "../../../ajax/actions/word-press-language/wp_languages_get";
import getPlans from "../../../ajax/actions/hosting-plan/plan_get";

class SiteFormWorker extends React.Component
{

  componentDidMount() {
    const password = generatePassword.generate({
      length: 20,
      numbers: true,
      symbols: true,
      lowercase: true,
      uppercase: true,
    });
    if( endOfURL() === 'create' ) {
      store.dispatch(setDefaultSiteForm(password));
    }
    store.dispatch( setOrganizationID( this.props.organization_id ) );
    store.dispatch( getSiteCreateDataCenters() );
    store.dispatch( getWordPressLangugae() );
    store.dispatch(getPlans());

  }

  componentDidUpdate(prev)
  {
    let { dispatchAPI, } = this.props;

    if ( dispatchAPI ) {
      if ( !prev.dispatchAPI ) {
        store.dispatch( createSite(this.props.organization_id) );

      }
    }

    if( this.props.createSite !== prev.createSite ) {
      let { success } = this.props.createSite;
      if( success ) {
        this.props.history.push(urlTrailWithoutLastItem());
      }
    }
  }

  render()
  {
    return null
  }
}
function mapStateToProps(state) {
  let { siteForm, createSite } = state;

  let { dispatchAPI, payload, mode} = siteForm.data;

  return { siteForm, dispatchAPI,payload,mode ,createSite };
}
export default withRouter(connect( mapStateToProps )( SiteFormWorker ));