import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultDataCenterFormState = {
    payload: {
        name: ``,
        display_name: ``,
        status: ``,
        availability: ``,
        latitude: ``,
        longitude: ``
    },

    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: endOfURL(),
    message: ``
};

export const DataCenterFormState = {data: DefaultDataCenterFormState};