import {endOfURL} from '../../helpers/UrlHelper'

const DefaultLoginState = {
    payload: {
        email: ``,
        password: ``,
    },
    loginStatus: {},

    user_id: ``,
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: endOfURL(),
    message: ``

};

export const LoginFormState = {data: DefaultLoginState}

