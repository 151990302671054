import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getSite from "../../../ajax/actions/sites/site_get";
import store from "../../../Store";
import getSiteStatus from "../sites/site_status_get";
import {
    toggleCacheIgnoreFormModal,
} from "../../../pages/site/feature-block/list/FeatureBlock-Action";
import getsCacheIgnore from "./cache_ignore_gets";

function _success(success) {
    return {type: 'CACHE_IGNORE_SUCCESS', success}
}

function _error(error) {
    return {type: 'CACHE_IGNORE_ERROR', error}
}

function _processing(processing) {
    return {type: 'CACHE_IGNORE_PROCESSING', processing}
}

function ignoreCache(id) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {ignoreCacheForm,organizationDashboard} = getState();
        let payload = ignoreCacheForm.data.payload
        let org_id = organizationDashboard.data.organization_id;

        let finalPayload = {
            "path": "/"+payload.path
        }

        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${id}/tools/cache/ignore`),
            method: 'post',
            dataType: 'json',
            data: finalPayload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res.data.message)
                store.dispatch(getSiteStatus(id));
                store.dispatch(toggleCacheIgnoreFormModal());
                store.dispatch(getsCacheIgnore(id,org_id))

            }).catch(function (error) {

            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
        });
    }
}

export default ignoreCache;