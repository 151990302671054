import React from 'react';
import Select from 'react-select'
import {names, ucFirst, unslufigy} from "../../../helpers/StringHelper";

class NewSearchableSelect extends React.Component
{
    label()
    {
        let { name, label} = this.props;
        if ( label ) return (
            <label >
                { label }
            </label>
        );
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = name.split(`_id`)[0];
                return (
                    <label>
                        { _label }
                    </label>
                )
            }
        }
    }

    labelText()
    {
        let { label, name } = this.props;
        if ( label ) return label;
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
                return _label
            }
        }
    }

    render()
    {
        let {
            name, options, clearable, placeholder,
            errors, required, processing, noFormGroup, disabled, onChange
        } = this.props;

        return (

            <div className={ names(
                !noFormGroup && `form-group`,
                errors && errors.length && `has-error`,
                disabled && `item-disabled`,
                required && `required`,
                processing && `processing`) }>

                { this.label() }

                <Select name={name}
                        placeholder={placeholder}
                        isClearable={clearable}
                        onChange={onChange}
                        options={options}
                        autoComplete="nope"
                        Searchable
                />
            </div>

        )

    }
}
export default NewSearchableSelect;