import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultUserState = {
  payload:{
      first_name: '',
      last_name: '',
      // middle_name: '',
      email: ``,
      password: ``,
      // address: '',
      // extra_address_line: '',
      // post_code:'',
      city: '',
      country: '',
      country_code: '',
      contact_number: '',
      // roles: '',
      role:'',
      allowed_organizations:[],
      password_confirmation:'',
  },
     selectedRole:``,
    login_user_role:``,
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,

  message: ``,
  user_mode:``,
  action:'loader',
    mode:endOfURL(),

};

export const UserFormState = {data: DefaultUserState}

