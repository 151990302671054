import React from 'react';
import {names, ucFirst, unslufigy} from '../../../helpers/StringHelper'
import PropTypes from 'prop-types';

class TextField extends React.Component {
    placeholder() {
        let {placeholder, name, label} = this.props;
        if (placeholder) return placeholder;
        if (label) return label;
        else {
            if (name) {
                let _label = unslufigy(name);
                if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
                return _label;
            }
        }
    }

    label() {
        let {name, label} = this.props;
        if (label) return (
            <label>
                {label}
            </label>
        );
        else {
            if (name) {
                let _label = unslufigy(name);
                if (name.includes(`_id`)) _label = name.split(`_id`)[0];
                return (
                    <label>
                        {_label}
                    </label>
                )
            }
        }
    }

    render() {
        let {
            name, size, value, onChange, id, noLabel, disabled, errors, required, response_error,
        } = this.props;
        let errorsPresent = errors && errors.length;

        return (
            <>
            <div
                className={names(`form-group`, `animated fadeIn faster`, errors && errors.length && `has-error` || response_error === "true" && `has-error`, required && `required`)}>

                {!noLabel ? <>{this.label()}</> : null}

                <input type="text"
                       className="form-control"
                       size={size || `default`}
                       name={name}
                       disabled={disabled}
                       id={id || name}
                       value={value}
                       autoComplete="nope"
                       placeholder={this.placeholder()}
                       onChange={onChange}
                />
                <div className=' error-msg'>
                    {errorsPresent ? errors[0].message + '.' : null}</div>
            </div>
    </>

    )
    }
}

let {string, number, oneOfType} = PropTypes;
TextField.propTypes = {value: oneOfType([string, number])};

export default TextField;