import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken, logOut} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'FETCH_USERS_SUCCESS', success};
}

function _error(error) {
    return {type: 'FETCH_USERS_ERROR', error};
}

function _processing(processing) {
    return {type: 'FETCH_USERS_PROCESSING', processing};
}

function getUsers() {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {params} = getState().userList.data;

        const request = axios({

            url: apiBaseUrl(`admin`),
            method: "get",
            dataType: 'json',
            params,
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                // let role = res.data.data.roles[0].name;
                // if(role === 'customer') {
                //   logOut()
                // }else{
                dispatch(_success(res));
                dispatch(_processing(false));
                // }
            }).catch(function (error) {

                if (error && error.response &&
                    error.response.status === 401) {
                    logOut()
                } else {
                    handleError(error)
                    dispatch(_error(error));
                    dispatch(_processing(false));
                }
            });

        return {
            type: 'FETCH_USERS_SUCCESS',
            payload: request
        }
    }
}


export default getUsers;