import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import DisplayNameFormWorker from './DisplayNameFormWorker'
import {onDisplayNameFormChange} from "./DisplayNameForm-Action";
import TextField from "../../../../components/shared/form/DisplayNameTextField";

class displayNameForm extends React.Component {

    onChange = (e) => {
        e.preventDefault();
        store.dispatch(onDisplayNameFormChange(e.target.name, e.target.value));
    };

    render() {
        let {
             errors, display_name, site
        } = this.props;

        return (
            <>
                <DisplayNameFormWorker data={site.display_name}/>
                    <div className="display-text-field col-md-6">
                        <div className="form-group">
                            <TextField name="display_name"
                                       value={display_name}
                                       errors={errors.display_name}
                                       onChange={this.onChange}/>
                        </div>
                    </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {displayNameForm, createSiteDisplayName, getSite,getSiteStatus,siteDashboard
    } = state;
    let {errors, payload} = displayNameForm.data;
    let { site_status } = siteDashboard.data;
    let site =
        getSite &&
        getSite.success;
    let {
        display_name
    } = payload;

    return {
        displayNameForm,site, display_name, errors, createSiteDisplayName, getSite,getSiteStatus,siteDashboard,site_status
    };
}

export default connect(mapStateToProps)(displayNameForm);