import {BugFormState} from './BugForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../helpers/ErrorHelper'
import {isObject} from "../../../helpers/DataHelper";
import {endOfURL} from "../../../helpers/UrlHelper";

export default function BugFormReducer(state = BugFormState, action) {

    let {data} = {...state};
    let {
        validationErrors,
        name,
        value,
        mode,
        error,
        bug,
        tagArray_afterDelete,
        tagAdd,
        user_id,
        imageAdd,
        filteredImage
    } = action;

    switch (action.type) {

        case 'ON-BUG-FORM-CHANGE':
            if (name === "organizaiton_id" && !value) {
                data.payload[name] = value;
                data.payload['site_id'] = ''
            } else {
                data.payload[name] = value;
            }
            buildErrors();
            return {...state, data};

        case 'ON-BUG-FORM-UPDATE-CHANGE':
            data.view[name] = value;
            buildErrors();
            return {...state, data};

        case 'ON-BUG-FORM-MODE-CHANGE':
            data.mode = mode;
            buildErrors();
            return {...state, data};

        case 'SET-BUG-ERROR':
            data.message = error;
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-BUG-FORM':
            data = {
                payload: {
                    summary: ``,
                    description: ``,
                    reporter_id: user_id,
                    other_links: ``,
                    urgency: ``,
                    priority: ``,
                    assigned_to: ``,
                    organization_id: ``,
                    site_id: ``,
                    remarks: ``,
                    images: [],
                    tags: ``,
                    status: ``,
                    imageLinks: ``
                },
                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: endOfURL(),
                message: ``
            };
            buildErrors();
            return {...state, data};

        case 'SET-BUG-DATA-FOR-STATUS-UPDATE':

            data.payload = {
                status: bug && bug.status
            };
            buildErrors();
            return {...state, data};

        case 'ON-REMOVE-BUG-TAG':

            data.payload['tags'] = tagArray_afterDelete

            buildErrors();
            return {...state, data};

        case 'ON-ADD-BUG-TAG':

            data.payload['tags'] = tagAdd
            buildErrors();
            return {...state, data};

        case 'ON-ADD-IMAGE':

            data.payload['images'] = imageAdd
            buildErrors();
            return {...state, data};

        case 'ON-DELETE-IMAGE':

            data.payload['images'] = filteredImage
            buildErrors();
            return {...state, data};

        case 'SET-BUG-DATA-FOR-UPDATE':
            data.payload = {
                summary: bug.summary,
                description: bug.description,
                reporter_id: bug.reporter_id?bug.reporter_id:"",
                other_links: bug.other_links,
                urgency: bug.urgency,
                priority: bug.priority,
                assigned_to: bug.assigned_to?bug.assigned_to:"",
                organization_id: bug.organization_id ? bug.organization_id : "",
                site_id: bug.site_id ? bug.site_id : "",
                remarks: bug.remarks,
                images: [],
                imageLinks: bug.images,
                tags: bug.tags,
                status: bug && bug.status
            };
            buildErrors();
            return {...state, data};

        /* case 'SET-BUG-DATA-FOR-VIEW':

             /!*data.view = {
                 summary: bug.summary,
                 description: bug.description,
                 reporter_id: bug.reporter_id,
                 other_links: bug.other_links,
                 urgency: bug.urgency,
                 priority: bug.priority,
                 assigned_to: bug.assigned_to,
                 organization_id: bug && bug.site && bug.site.organization_id,
                 site_id: bug.site.id,
                 remarks: bug.remarks,
                 images: bug.images,
                 tags: bug.tags,
             };*!/
             data.payload = {
                 summary: bug.summary,
                 description: bug.description,
                 reporter_id: bug.reporter_id,
                 other_links: bug.other_links,
                 urgency: bug.urgency,
                 priority: bug.priority,
                 assigned_to: bug.assigned_to,
                 organization_id: bug && bug.site && bug.site.organization_id,
                 site_id: bug.site.id,
                 remarks: bug.remarks,
                 images: bug.images,
                 tags: bug.tags,
             };
             buildErrors();
             return {...state, data};*/

        case 'CANCEL-BUG-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-BUG-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'BUG-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            );
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, validationErrors, mode} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted && (mode === 'update' || mode === "create")) {
            if (field === `summary`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (field === `description`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (field === `reporter_id`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
           /* if (field === `other_links`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }*/
            if (field === `urgency`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (field === `priority`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            /*if (field === `assigned_to`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }*/
            /*if (field === `organization_id`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }*/

            /*if (field === `remarks`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }*/
            /*if (field === `images`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }*/
            if (field === `tags`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
        }

        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}
