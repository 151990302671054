import {BackupStatusState} from './Backup-status-State'

export default function backupStatusListReducer(state = BackupStatusState, action) {

    let {data} = {...state};
    let {name, value, date} = action;

    switch (action.type) {

        case 'TOGGLE-BACKUP-STATUS-LIST-FILTER':
            let toggle = true;
            if (toggle) data.params[name] = value;
            return {...state, data};

        case 'PATH-FROM-URL':
            data.params['backup_date'] = date;
            return {...state, data};

        default:
            return state
    }
}

