import React from 'react';
import BackupWorker from "./BackupWorker";
import {connect} from 'react-redux';
import store from "../../../../Store";
import swal from "sweetalert";
import TableHead from "../../../../components/shared/table/TableHead";
import Moment from "moment";
import {ucFirst, unslufigy} from "../../../../helpers/StringHelper";
import restoreBackup from "../../../../ajax/actions/backup/restore_backup_post";
import Loading from "../../../../components/shared/loaders/Loading";
import deleteBackup from "../../../../ajax/actions/backup/backup_delete";
import postBackup from "../../../../ajax/actions/backup/backup_post";
import getManualSiteBackup from "../../../../ajax/actions/backup/site_manual_backup_get";
import getSiteBackup from "../../../../ajax/actions/backup/site_backup_get";
import ResponsiveModalForm from "../../../../components/shared/modals/ResponsiveModalForm";
import ManualBackupForm from "../form/ManualBackupForm";
import {toggleBackupFormModal, toggleBackupListFilter, toggleManualBackupListFilter, setDefaultPagination} from "./Backup-Action";
import BackupPrimaryView from "./backupPrimaryView"
import PaginationButtons from "../../../../components/shared/buttons/PaginationButtons";
import {togglePaymentListFilter} from "../../../payment/list/PaymentList-Actions";

class BackupList extends React.Component {

    createBackup = (e) => {
        swal("Are you sure you want to create Manual Backup? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Create Backup",
                    value: "yes"
                }
            }
        }).then((value) => {
            if (value) this.createManualBackup()
        })
    };

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this Backup? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    restoreBackup = (id) => {
        swal("Are you sure you want to Restore this Backup? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Restore",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.restore(value)
        })
    };

    onClickDaily = () => {
        store.dispatch(getSiteBackup())
        store.dispatch(setDefaultPagination())
    }

    onClickManual = () => {
        store.dispatch(getManualSiteBackup())
        store.dispatch(setDefaultPagination())
    }

    delete(id) {
        store.dispatch(deleteBackup(id));
    }

    restore(id) {
        store.dispatch(restoreBackup(id));
    }

    createManualBackup() {
        store.dispatch(postBackup());
    }

    render() {
        let {
            backupLists,
            getSite,
            backupList,
            getSiteBackup,
            backup_modal,
            manualSiteBackups,
            getManualSiteBackup,
            siteBackups,
            params,
            fields,
            site_status,
            getSiteStatus,
            fieldsManual,
            pages,
            manualBackupPages
        } = this.props;
        let {processing, error} = getSiteBackup;
        let getSiteProcessing = getSite.processing
        let maualBackupProcessing = getManualSiteBackup.processing;
        return (
            <>
                <BackupWorker/>
                {getSite ? <>
                    <ul className="nav nav-pills">
                        <li className="nav-item">
                            <a className="nav-link active" id="pills-daily-tab" data-toggle="pill"
                               href="#pills-daily" role="tab" aria-controls="pills-daily"
                               aria-selected="true" onClick={this.onClickDaily}>Daily</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" id="pills-manual-tab" data-toggle="pill"
                               href="#pills-manual" role="tab" aria-controls="pills-manual"
                               aria-selected="false" onClick={ this.onClickManual }>Manual</a>
                        </li>

                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" id="pills-download-tab" data-toggle="pill"*/}
                        {/*       href="#pills-download" role="tab" aria-controls="pills-download"*/}
                        {/*       aria-selected="false">Download</a>*/}
                        {/*</li>*/}
                    </ul>
                    <div className="tab-content">
                        <div className="tab-pane fade show active" id="pills-daily" role="tabpanel"
                             aria-labelledby="pills-daily-tab">
                            <div className="setting-table table-responsive">
                                <table className="table table-striped">

                                    <TableHead fields={fields}
                                               params={params}
                                               noActions={true}
                                               onClick={this.toggleSort}
                                               id="backup-table-head"
                                    />

                                    <tbody>
                                    {processing ? <td colSpan={5} className="text-center"><Loading/></td> :
                                        siteBackups && siteBackups.length ? siteBackups.map((siteBackup, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        {unslufigy(ucFirst(siteBackup.type))}
                                                    </td>
                                                    <td>{Moment(siteBackup.created_at).format('YYYY-MM-DD h:mm A')}</td>
                                                    <td>{Moment(siteBackup.expiry_date).format('YYYY-MM-DD h:mm A')}</td>
                                                    <td>{siteBackup.remarks !== " " ? siteBackup.remarks : "N/A"}</td>
                                                    {/*<td className="buttons-block">*/}
                                                    {/*    <div className="dropdown ">*/}
                                                    {/*        <button*/}
                                                    {/*            // className="btn-theme btn-outline  btn-restore"*/}
                                                    {/*            className="btn-theme btn-outline blue-outline"*/}
                                                    {/*            type="button"*/}
                                                    {/*            onClick={() => this.restoreBackup(siteBackup.id)}*/}
                                                    {/*            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring' ? 'disabled' : null}>*/}
                                                    {/*            /!*{site_status === 'migrating' ?<><Loading/> Restore Backup</>:"Restore Backup"}*!/*/}
                                                    {/*            { getSiteStatus && getSiteStatus?.success?.backup_id === siteBackup.id ?<><Loading/>Restore Backup</>:"Restore Backup"}*/}
                                                    {/*        </button>*/}
                                                    {/*    </div>*/}
                                                    {/*</td>*/}
                                                    {(site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring') ?
                                                        <>
                                                            <td>
                                                                <div className="dropdown dropdown-alt text-center">
                                                                    {/*<span className="dropdown-toggle"*/}
                                                                    {/*      data-toggle="dropdown">*/}
                                                                    <span className="icon-more"/>
                                                                    {/*</span>*/}
                                                                </div>
                                                            </td>
                                                        </> :
                                                        <>

                                                            <td>
                                                                <div className="dropdown dropdown-alt text-center">
                                                <span className="dropdown-toggle"
                                                      data-toggle="dropdown">
                                                    <span className="icon-more"/>
                                                </span>
                                                                    <div className="dropdown-menu">
                                                                        <a href="#!"
                                                                           className={`link-edit`}
                                                                           type="button"
                                                                           onClick={() => this.restoreBackup(siteBackup.id)}>
                                                                            Restore Backup
                                                                        </a>
                                                                        <a href="javascript:void(0);"
                                                                           onClick={() => this.deleteConfirm(siteBackup.id)}
                                                                           className={`link-delete`}>
                                                                            {/*<a  onClick={() => this.deleteConfirm( organization.id )} className={`link-delete`}>*/}
                                                                            Delete Backup
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </>}
                                                </tr>

                                            )
                                        }) : <tr>
                                            <td colSpan={4}>No Backup Available</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                { siteBackups && siteBackups.length ? (
                                    <div className="fixed-footer backup-footer pr-60" id="inside-organization-pagination">
                                        <PaginationButtons pages={pages}
                                                           processing={processing}
                                                           fetchAction={toggleBackupListFilter}/>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        {/*<ResponsiveModalForm visible={backup_modal.mode}*/}
                        {/*                     closeModal={() => store.dispatch(toggleBackupFormModal())}>*/}
                        {/*    <ManualBackupForm/>*/}
                        {/*</ResponsiveModalForm>*/}
                        <div className="tab-pane fade" id="pills-manual" role="tabpanel"
                             aria-labelledby="pills-manual-tab">
                            <div className="padded-block">
                                <div className="tab-info-wrapper">
                                    <div className="tab-info-block">
                                        <p>Welcome to the manual backup section. A manual backup is used when you need to test something or you just want to be on the safe side. Each manual backup will be saved for 30 days.<br/>
                                            Please delete your backup if you don't need it anymore.<br/><br/>
                                            You will be able to take a maximum of 10 backups per site manually.
                                        </p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between mt-4"
                                         id="cache-row">
                                        <ResponsiveModalForm visible={backup_modal.mode}
                                                             closeModal={() => store.dispatch(toggleBackupFormModal(getSite && getSite.success && getSite.success.id))}>
                                            <ManualBackupForm/>
                                        </ResponsiveModalForm>
                                        <BackupPrimaryView/>
                                        {/*    <div className="tab-backup-button">*/}
                                        {/*        <button className="btn-theme btn-dark-blue" id="backup-btn" data-toggle="modal"*/}
                                        {/*                data-target="#manual-backup" onClick={()=> store.dispatch(toggleBackupFormModal())}*/}
                                        {/*                disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring' ? 'disabled' : null}*/}
                                        {/*        >Create Manual Backup </button>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="tab-backup-button">*/}
                                        {/*    <button className="btn-theme btn-dark-blue" id="backup-btn" data-toggle="modal"*/}
                                        {/*       data-target="#manual-backup" onClick={()=> this.createBackup()}*/}
                                        {/*            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring' ? 'disabled' : null}*/}
                                        {/*    >Create Manual Backup </button>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="setting-table table-responsive">
                                <table className="table table-striped">

                                    <TableHead fields={fieldsManual}
                                               params={params}
                                               noActions={true}
                                               onClick={this.toggleSort}
                                               id="backup-table-head"
                                    />

                                    <tbody>
                                    {maualBackupProcessing ? <td colSpan={5} className="text-center"><Loading/></td> :
                                        manualSiteBackups && manualSiteBackups.length ? manualSiteBackups.map((siteBackup, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        {siteBackup.name}
                                                    </td>
                                                    <td>
                                                        {unslufigy(ucFirst(siteBackup.type))}
                                                    </td>
                                                    <td>{Moment(siteBackup.created_at).format('YYYY-MM-DD h:mm A')}</td>
                                                    <td>{Moment(siteBackup.expiry_date).format('YYYY-MM-DD h:mm A')}</td>
                                                    <td>{siteBackup.remarks !== " " ? siteBackup.remarks : "N/A"}</td>
                                                    {/*<td className="buttons-block">*/}
                                                    {/*    <div className="dropdown ">*/}
                                                    {/*        <button*/}
                                                    {/*            // className="btn-theme btn-outline  btn-restore"*/}
                                                    {/*            className="btn-theme btn-outline blue-outline"*/}
                                                    {/*            type="button"*/}
                                                    {/*            onClick={() => this.restoreBackup(siteBackup.id)}*/}
                                                    {/*            disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring' ? 'disabled' : null}>*/}
                                                    {/*            /!*{site_status === 'migrating' ?<><Loading/> Restore Backup</>:"Restore Backup"}*!/*/}
                                                    {/*            { getSiteStatus && getSiteStatus?.success?.backup_id === siteBackup.id ?<><Loading/>Restore Backup</>:"Restore Backup"}*/}
                                                    {/*        </button>*/}
                                                    {/*    </div>*/}
                                                    {/*</td>*/}
                                                    {(site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring') ?
                                                        <>
                                                            <td>
                                                                <div className="dropdown dropdown-alt text-center">
                                                                    {/*<span className="dropdown-toggle"*/}
                                                                    {/*      data-toggle="dropdown">*/}
                                                                    <span className="icon-more"/>
                                                                    {/*</span>*/}
                                                                </div>
                                                            </td>
                                                        </> :
                                                        <>

                                                            <td>
                                                                <div className="dropdown dropdown-alt text-center">
                                                <span className="dropdown-toggle"
                                                      data-toggle="dropdown">
                                                    <span className="icon-more"/>
                                                </span>
                                                                    <div className="dropdown-menu">
                                                                        <a href="#!"
                                                                           className={`link-edit`}
                                                                           type="button"
                                                                           onClick={() => this.restoreBackup(siteBackup.id)}>
                                                                            Restore Backup
                                                                        </a>
                                                                        <a href="javascript:void(0);"
                                                                           onClick={() => this.deleteConfirm(siteBackup.id)}
                                                                           className={`link-delete`}>
                                                                            {/*<a  onClick={() => this.deleteConfirm( organization.id )} className={`link-delete`}>*/}
                                                                            Delete Backup
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </>}
                                                </tr>

                                            )
                                        }) : <tr>
                                            <td colSpan={5}>No Backup Available</td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                                { manualSiteBackups && manualSiteBackups.length ? (
                                    <div className="fixed-footer backup-footer pr-60" id="inside-organization-pagination">
                                        <PaginationButtons pages={manualBackupPages}
                                                           processing={processing}
                                                           fetchAction={toggleManualBackupListFilter}/>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                    </div>
                </> : <Loading/>
                }
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
    getSite
,
    getSiteBackup
,
    getManualSiteBackup
,
    backupList
,
    siteDashboard
,
    getSiteStatus
}

= state;
let {params, fields, backup_modal,fieldsManual} = backupList.data;
let {success} = backupList;
let {site_status} = siteDashboard.data;

let backupLists =
    success &&
    success.data &&
    success.data.data.data;

let siteBackups =
    getSiteBackup &&
    getSiteBackup.success &&
    getSiteBackup.success.data &&
    getSiteBackup.success.data.data.data;
let manualSiteBackups =
    getManualSiteBackup &&
    getManualSiteBackup.success &&
    getManualSiteBackup.success.data &&
    getManualSiteBackup.success.data.data.data;

/*Pagination for SiteBackups*/
    let current_page = getSiteBackup && getSiteBackup.success &&   getSiteBackup.success.data &&  getSiteBackup.success.data.data.current_page;
    let last_page = getSiteBackup && getSiteBackup.success &&  getSiteBackup.success.data &&  getSiteBackup.success.data.data.last_page;
    let total = getSiteBackup && getSiteBackup.success &&  getSiteBackup.success.data &&  getSiteBackup.success.data.data.total;
    let from = getSiteBackup && getSiteBackup.success &&  getSiteBackup.success.data &&  getSiteBackup.success.data.data.from;
    let pages = {current_page, last_page, total, from};

    /*Pagination for manual siteBackup*/
    let currentPage =  getManualSiteBackup &&  getManualSiteBackup.success &&    getManualSiteBackup.success.data &&   getManualSiteBackup.success.data.data.current_page;
    let lastPage =  getManualSiteBackup &&  getManualSiteBackup.success &&   getManualSiteBackup.success.data &&   getManualSiteBackup.success.data.data.last_page;
    let total_page =  getManualSiteBackup &&  getManualSiteBackup.success &&   getManualSiteBackup.success.data &&   getManualSiteBackup.success.data.data.total;
    let fr_om =  getManualSiteBackup &&  getManualSiteBackup.success &&   getManualSiteBackup.success.data &&   getManualSiteBackup.success.data.data.from;
    let manualBackupPages = {current_page : currentPage, last_page : lastPage, total : total_page, from: fr_om};

    return {
    backupLists,
    getManualSiteBackup,
    getSite,
    backup_modal,
    getSiteBackup,
    manualSiteBackups,
    params,
    fields,
    fieldsManual,
    siteBackups,
    site_status,
    siteDashboard,
    getSiteStatus,
    pages,
    manualBackupPages
};
}

export default connect(mapStateToProps)(BackupList);