import axios from 'axios'
import { apiBaseUrl } from '../../../helpers/UrlHelper'
import { loginToken } from '../../../helpers/LocalStorageHelper'
import { handleError, handleSuccess } from '../../../helpers/ErrorHelper'

function _success(success) {
  return { type: 'CREATE_DISCOUNT_SUCCESS', success }
}

function _error(error) {
  return { type: 'CREATE_DISCOUNT_ERROR', error }
}

function _processing(processing) {
  return { type: 'CREATE_DISCOUNT_PROCESSING', processing }
}

function createDiscount() {
  return (dispatch,getState) => {

    let {discountForm} = getState();
    dispatch(_success(null))
    dispatch(_error(null))
    dispatch(_processing(true))

    axios({
      url: apiBaseUrl(`admin/discount_slabs`),
      method: 'post',
      dataType: 'json',
      data: discountForm.data.payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    })
      .then(function(res) {
        dispatch(_success(res))
        dispatch(_processing(false))
        handleSuccess(res)
      }).catch(function(error) {
        dispatch(_error(error.response.data.message))
        dispatch(_processing(false))
        handleError(error)
      })
  }
}

export default createDiscount
