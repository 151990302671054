const DefaultBackupState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    per_page: 25,
    limit:10,
    page:   1,
    order:  'desc',
    sort_id: `id`,
    q: ``,
  },
  site_id:``,
  backup_modal: {
    mode: '',
  },
  fields: [
    // { field: `id`, label: `ID`, sort: true },
    // { field: `site_id`, label: `Site ID`, sort: true },
    { field: `type`, label: `Backup Type`, sort: true },
    { field: `created_at`, label: `Created At`, sort: true },
    { field: `expiry_date`, label: `Expiry Date`, sort: true },
    { field: `remarks`, label: `Description`, sort: true },
    {field: `action`, label: `Action`, sort: true},
  ],
  fieldsManual: [
    // { field: `id`, label: `ID`, sort: true },
    // { field: `site_id`, label: `Site ID`, sort: true },
    { field: `name`, label: `Backup Name`, sort: true },
    { field: `type`, label: `Backup Type`, sort: true },
    { field: `created_at`, label: `Created At`, sort: true },
    { field: `expiry_date`, label: `Expiry Date`, sort: true },
    { field: `remarks`, label: `Description`, sort: true },
    {field: `action`, label: `Action`, sort: true},
  ]
}

export const BackupState = {data:DefaultBackupState}