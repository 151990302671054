import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import {cancelPasswordResetFormDispatch, passwordResetFormServerValidationErrors} from '../../../pages/myAccount/password-reset/form/PasswordResetForm-Action'
import {scrollToError} from '../../../helpers/ScrollHelper'
import getLoggedInUser from "../user/user_loggedin_get";
import {passwordResetSuccessMessage} from '../../../pages/myAccount/MyAccount-Actions'

function _success(success) {
    return {type: 'UPDATE_USER_PASSWORD_SUCCESS', success}
}

function _error(error) {
    return {type: 'UPDATE_USER_PASSWORD_ERROR', error}
}

function _processing(processing) {
    return {type: 'UPDATE_USER_PASSWORD_PROCESSING', processing}
}

function changeUserPassword() {
    return (dispatch, getState) => {

        let {passwordResetForm} = getState()

        dispatch(_success(null))
        dispatch(_processing(true))

        axios({
            url: apiBaseUrl(`admin/change-password`),
            method: 'put',
            dataType: 'json',
            data: JSON.parse(JSON.stringify(passwordResetForm.data.payload)),
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                store.dispatch(getLoggedInUser())
                store.dispatch(cancelPasswordResetFormDispatch())

                /*in my account action and reducers*/
                store.dispatch(passwordResetSuccessMessage());


            }).catch(function (error) {
            dispatch(_error(error.response.data.message))
            dispatch(_processing(false))
            handleError(error)

            store.dispatch(cancelPasswordResetFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(passwordResetFormServerValidationErrors(error.response.data.errors))
                scrollToError()
            }
        })
    }
}

export default changeUserPassword
