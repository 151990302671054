import React from 'react'
import TextField from "../../components/shared/form/TextField";
import PasswordField from "../../components/shared/form/PasswordField";
import KeplerLogo from "../../assets/images/kepler-logo-top.svg";
import store from "../../Store";
import {onLoginFormChange, onLoginFormSubmit} from "./Login-Actions";
import LoginFormWorker from "./LoginWorker";
import {connect} from "react-redux";
import Button from "../../components/shared/buttons/Button";
import TwoFactorAuthentication from "../../components/shared/pages/2FA_VerifyCodeAuthy"
import ResponsiveModalForm from "../../components/shared/modals/ResponsiveModalForm";

class Login extends React.Component {

    onChange = (e) => {
        let {name, value} = e.target;
        store.dispatch(onLoginFormChange(name, value));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onLoginFormSubmit());
    };

    render() {
        let {payload, errors, twoFa} = this.props;
        return (
            <>
                <body className="login-pages Main-App">
                <div id="root">
                    <div className="App">
                        <section className="dashboard-login">
                            <div className="container-fluid p-0">
                                <div className="row no-gutters">
                                    <div className="col-lg-7">
                                        <div className="logo-block">
                                            <a href="/#" onClick={ev => {ev.preventDefault()}}><img src={KeplerLogo} alt=""/>
                                            </a>
                                        </div>
                                    </div>
                                   {/* <ResponsiveModalForm visible={twoFa}
                                                         closeIcon={false}
                                                         closeOnEsc={false}
                                                         closeOnOverlayClick={false}
                                                         verifyAuthyPopup={true}
                                    >
                                        <TwoFactorAuthentication/>
                                    </ResponsiveModalForm>*/}
                                    {twoFa ?
                                        <TwoFactorAuthentication/> :
                                        <div className="col-lg-5">
                                            <div className="form-block content-center">
                                                <p id="huston">Huston</p>
                                                <h2>This is Kepler Speaking</h2>
                                                <form onSubmit={this.handleSubmit}>
                                                    <div className="form-group" id="login-input">
                                                        <TextField
                                                            value={payload.email}
                                                            errors={errors.email}
                                                            icon="user"
                                                            name="email"
                                                            onChange={this.onChange}
                                                            placeholder="Your email"
                                                        />
                                                    </div>
                                                    <div className="form-group" id="login-input">
                                                        <PasswordField
                                                            value={payload.password}
                                                            errors={errors.password}
                                                            icon="key"
                                                            name="password"
                                                            onChange={this.onChange}
                                                            placeholder="Enter your password"
                                                        />
                                                    </div>
                                                    <div
                                                        className="button-wrapper inline-buttons text-center d-flex justify-content-center">

                                                        <Button
                                                            className="btn-theme btn-dark-blue btn-login"
                                                            icon="done"
                                                            type="primary"
                                                            htmlType="submit"
                                                            processing={this.props.loginUser.processing}
                                                            iconWidth={11}
                                                            iconRight={3}
                                                            text={`Login`}
                                                        >
                                                        </Button>
                                                        {/*/!*<Link to="/userAccount" >*!/*/}
                                                        {/*    <Button*/}
                                                        {/*        className="btn-theme btn-outline"*/}
                                                        {/*        icon="done"*/}
                                                        {/*        type="primary"*/}
                                                        {/*        name="Create Account"*/}
                                                        {/*        iconWidth={11}*/}
                                                        {/*        iconRight={3}*/}
                                                        {/*        text={`Create Account`}*/}
                                                        {/*        onClick={ this.onClick }*/}
                                                        {/*    >*/}
                                                        {/*    </Button>*/}
                                                        {/*/!*</Link>*!/*/}

                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </section>
                        <LoginFormWorker/>
                    </div>
                </div>
                </body>
                <section className="Download-App">
                    <div className="under-development col-md-8 col-sm-8 col-xs-8">
                        <h3>Hi <span>visitor</span>,</h3>
                        <h3>We are currently developing</h3>
                        <h3 className="spacing">Our mobile HQ platform.</h3>
                        <p>For now our platform is only optimised for smaller MacBooks and devices. For iPad & Mobile please download our internal apps to administrate the Kepler Hosting infrastructure. Please note that some settings are only available from the desktop version.</p>
                    </div>
                </section>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {loginForm, loginUser} = state;
    let {twoFa} = loginUser && loginUser.success && loginUser.success.data.data
    let {payload, errors} = loginForm.data;
    return {loginForm, loginUser, payload, errors, twoFa};
}

export default connect(mapStateToProps)(Login);