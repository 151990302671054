export function isValidDomain(domain){
    const regex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g
    return regex.test(domain);
}


export const cacheInputRestriction=(value) =>{
    let contains = false;
    var format = /[a-zA-Z0-9\-#$%&_+-\-=\[\]\\\/?]+/;
    if(format.test(value)){
        contains = true;
    } else {
        contains = false;
    }
    return contains;

}