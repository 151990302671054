import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultPublicPathForm, setSiteId
} from "./PublicPathForm-Action";
import createPublicPath from "../../../../ajax/actions/kepler-tools/public_path_post";
import {togglePublicPathFormModal} from "../list/FeatureBlock-Action";

class PublicPathFormWorker extends React.Component {

    constructor(props) {
        super(props);
        store.dispatch(setDefaultPublicPathForm())
        store.dispatch(setSiteId(this.props.match.params.id))
    }

    componentDidUpdate(prev) {
        let {dispatchAPI} = this.props;

        if (this.props.createPublicPath !== prev.createPublicPath) {
            let {success} = this.props.createPublicPath;

            if (success) {
                store.dispatch(togglePublicPathFormModal());
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(createPublicPath(this.props.match.params.id, this.props.match.params.org_id))
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {publicPathForm, createPublicPath,} = state;
    let {dispatchAPI, mode, payload} = publicPathForm.data;
    return {publicPathForm, dispatchAPI, mode, payload, createPublicPath};
}

export default withRouter(connect(mapStateToProps)(PublicPathFormWorker));