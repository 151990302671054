import axios from 'axios';
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store';
import getSiteStatus from "../sites/site_status_get";
import getSiteDomain from "./site_domain_get";

function _success(success) {
    return {type: 'DELETE_DOMAIN_SUCCESS', success}
}

function _error(error) {
    return {type: 'DELETE_DOMAIN_ERROR', error}
}

function _processing(processing) {
    return {type: 'DELETE_DOMAIN_PROCESSING', processing}
}

function deleteDomain(id) {
    return (dispatch, getState) => {

        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {domainList, organizationDashboard} = getState();
        let {site_id} = domainList.data;
        let {organization_id} = organizationDashboard.data;

        let config = {
            headers: {'Authorization': 'Bearer ' + loginToken()},
        };

        axios.delete(apiBaseUrl(`admin/organizations/${organization_id}/sites/${site_id}/domains/${id}`), config)

            .then(res => {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
                store.dispatch(getSiteStatus(site_id))
                store.dispatch(getSiteDomain())
                // store.dispatch( getSiteDomain() )
            }).catch(error => {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error)

        });
    }
}

export default deleteDomain;