import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import LoadBalancerFormWorker from './LoadBalancerFormWorker'
import {onLoadBalancerFormChange, onLoadBalancerFormSubmit} from "./LoadBalancerForm-Action";
import TextField from "../../../components/shared/form/TextField";
import Loading from "../../../components/shared/loaders/Loading";
import SelectField from "../../../components/shared/form/SelectField";
import {activeStatusFilter,versionFilter, lbType} from "../../../static-data/UserData";
import TextAreaField from "../../../components/shared/form/TextAreaField";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import {ucFirst} from "../../../helpers/StringHelper";
import Link from "react-router-dom/Link";
import {endOfURL} from "../../../helpers/UrlHelper";
import Page from "../../../components/shared/pages/Page";

class LoadBalancerForm extends React.Component {
    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    loadBalancerFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onLoadBalancerFormSubmit());
    };

    onChange = (e) => {
        if (e.target.name === "server_id") {
            e.preventDefault();
        }
        store.dispatch(onLoadBalancerFormChange(e.target.name, e.target.value,));
    };

    render() {
        let {
            serverList, name, server_id, public_ip_address, public_hardware_address, description,
            public_ip_class, public_gateway, container_name,type,
            status,version, updateLoadBalancer, createLoadBalancer, loadBalancerForm
        } = this.props;
        let {errors, mode} = loadBalancerForm.data;
        let processing = updateLoadBalancer.processing || createLoadBalancer.processing;

        return (

            <>
                <Page title={endOfURL() === "create" ?
                    "Add LoadBalancer" :
                    "Update LoadBalancer"
                } processing={processing}>
                    <div className="heading-block pr-60">
                        <div className="title-block">
                            <h2> {endOfURL() === 'create' ? 'Add new Load Balancer' : 'Update Load Balancer'}</h2>
                            <span><Link to="/load-balancer">Load Balancer</Link></span>
                            {endOfURL() === 'update' ? <span>{ucFirst(name)}</span> : null}
                            <span>{endOfURL() === 'create' ? `Add new Load Balancer` : `Update`}</span>
                        </div>
                    </div>
                    <LoadBalancerFormWorker/>
                    <div className="box-block">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="pills-add-user" role="tabpanel"
                                 aria-labelledby="pills-add-user-tab">
                                <div className="padded-block">
                                    <form onSubmit={this.loadBalancerFormSubmit}>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField name="name"
                                                               value={name}
                                                               label="Name"
                                                               errors={errors.name}
                                                               onChange={this.onChange}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                    <SelectField name="server_id"
                                                                 value={server_id}
                                                                 options={serverList}
                                                                 errors={errors.server_id}
                                                                 onChange={this.onChange}
                                                                 _value="id"
                                                                 _label="name"
                                                                 disabled={mode === "update"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>

                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                    <TextField name="public_ip_address"
                                                               value={public_ip_address}
                                                               label="Public IP Address"
                                                               errors={errors.public_ip_address}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                    <TextField name="public_ip_class"
                                                               value={public_ip_class}
                                                               label="Public IP Class"
                                                               errors={errors.public_ip_class}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}
                                                    /></div>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                    <TextField name="public_gateway"
                                                               value={public_gateway}
                                                               label="Public Gateway"
                                                               errors={errors.public_gateway}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                    <TextField name="public_hardware_address"
                                                               value={public_hardware_address}
                                                               label="Public Hardware Address"
                                                               errors={errors.public_hardware_address}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className={`form-group ${mode === "update" ? "update" : ""}`}>
                                                    <TextField name="container_name"
                                                               value={container_name}
                                                               label="Container Name"
                                                               errors={errors.container_name}
                                                               onChange={this.onChange}
                                                               disabled={mode === "update"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <SelectField name="type"
                                                                 value={type}
                                                                 options={lbType}
                                                                 label="Type"
                                                                 errors={errors.type}
                                                                 onChange={this.onChange}
                                                                 _value="value"
                                                                 _label="name"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<div className="form-row">*/}
                                        {/*    <div className="col-md-6">*/}
                                        {/*        <div className="form-group">*/}
                                        {/*    <TextField name="private_ip_address"*/}
                                        {/*               value={private_ip_address}*/}
                                        {/*               label="Private IP Address"*/}
                                        {/*               errors={errors.private_ip_address}*/}
                                        {/*               onChange={this.onChange}*/}
                                        {/*               disabled={mode === "update"}*/}
                                        {/*    />*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}

                                        {/*        <div className="col-md-6">*/}
                                        {/*            <div className="form-group">*/}
                                        {/*    <TextField name="private_ip_class"*/}
                                        {/*               value={private_ip_class}*/}
                                        {/*               label="Private IP Class"*/}
                                        {/*               errors={errors.private_ip_class}*/}
                                        {/*               onChange={this.onChange}*/}
                                        {/*               disabled={mode === "update"}*/}
                                        {/*    />*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*</div>*/}
                                        {/*<div className="form-row">*/}
                                        {/*    <div className="col-md-6">*/}
                                        {/*        <div className="form-group">*/}
                                        {/*    <TextField name="private_gateway"*/}
                                        {/*               value={private_gateway}*/}
                                        {/*               label="Private Gateway"*/}
                                        {/*               errors={errors.private_gateway}*/}
                                        {/*               onChange={this.onChange}*/}
                                        {/*               disabled={mode === "update"}*/}
                                        {/*    />*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <hr/>
                                        <TextAreaField name="description"
                                                       value={description}
                                                       label="Description"
                                                       errors={errors.description}
                                                       onChange={this.onChange}/>

                                        <hr/>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <SelectField name="status"
                                                                 value={status}
                                                                 options={activeStatusFilter}
                                                                 errors={errors.status}
                                                                 onChange={this.onChange}
                                                                 _value="value"
                                                                 _label="name"/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <SelectField name="version"
                                                                 value={version}
                                                                 options={versionFilter}
                                                                 errors={errors.version}
                                                                 onChange={this.onChange}
                                                                 _value="value"
                                                                 _label="name"/>
                                                </div>
                                            </div>
                                        </div>
                                            {/*<div className="col-md-6">*/}
                                                <div className="form-group">
                                                    <div className="button-wrapper text-right mt-20 d-flex justify-content-end">
                                                        <button type="submit"
                                                                className="btn-theme btn-dark-blue">
                                                            {processing ? <Loading/> : `Submit`}
                                                        </button>
                                                    {/*</div>*/}
                                                </div>
                                            </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>
            </>

        )
    }
}

function mapStateToProps(state) {
    let {loadBalancerForm, updateLoadBalancer, createLoadBalancer, getServerModules} = state;

    let {success} = getServerModules;

    let serverList =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data;

    let {errors, payload, mode} = loadBalancerForm.data;

    let {
        name, server_id, public_ip_address, public_hardware_address, description, container_name,
        public_ip_class, public_gateway,version,type,
        status
    } = payload;

    return {
        getServerModules, serverList, loadBalancerForm, updateLoadBalancer, createLoadBalancer, errors,
        name, server_id, public_ip_address, public_hardware_address, description, container_name,
        public_ip_class, public_gateway, mode,type,
        status,version
    };
}

export default connect(mapStateToProps)(LoadBalancerForm);