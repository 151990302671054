import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getSite from "../../../ajax/actions/sites/site_get";
import store from "../../../Store";
import getSiteStatus from "../sites/site_status_get";

function _success(success) {
    return {type: 'RESTART_PHP_SUCCESS', success}
}

function _error(error) {
    return {type: 'RESTART_PHP_ERROR', error}
}

function _processing(processing) {
    return {type: 'RESTART_PHP_PROCESSING', processing}
}

function restartPhp(id=null) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {publicPathForm, organizationDashboard} = getState();
        let site_id = id || publicPathForm?.data?.payload.site_id
        let org_id = organizationDashboard.data.organization_id;

        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/tools/restartphp`),
            method: 'post',
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                // handleSuccess("Monitoring request sent Successfully")
                handleSuccess(res.data.message)
                store.dispatch( getSiteStatus(id ) );
                store.dispatch(getSite(site_id, org_id))


            }).catch(function (error) {

            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
        });
    }
}

export default restartPhp;