import {SiteFormState} from './SiteForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../helpers/ErrorHelper'
import {endOfURL} from "../../../helpers/UrlHelper";
import {isObject} from "../../../helpers/DataHelper";
import {isValidEmail} from "../../../helpers/StringHelper";

export default function siteFormReducer(state = SiteFormState, action) {

    let {data} = {...state};
    let {validationErrors, name, value, data_center_ids, org_id, password} = action
    switch (action.type) {

        case 'ON-SITE-FORM-CHANGE':
            data.payload[name] = value;
            buildErrors();
            return {...state, data};

        case 'ON-WP-LANGUAGE-FORM-CHANGE':
            data.payload[name] = value;
            buildErrors();
            return {...state, data};

        case 'ON-SITE-FORM-CHECKED-CHANGE':
            data.mode = value
            if (value === "blankinstall") {
                data.payload.wp_flag = 0
                data.payload.site_type = `normal`
            }
            if (value === "wordpress") {
                data.payload.wp_flag = 1;
                data.payload.site_type = "normal"
            }
            buildErrors();
            return {...state, data};

        case 'ON-SITE-FORM-COMMERCE-CHECKED-CHANGE':
            data.mode = value
            if (value === "ecommerce") {
                data.payload.wp_flag = 1;
                data.payload.site_type = "ecommerce"
            }
            buildErrors();
            return {...state, data};

        case 'ON-SITE-FORM-PASSWORD-CHANGE':
            data.payload.wp_admin_password = value
            if (value === null) {
                data.payload.wp_admin_password = '';
            }
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-SITE-FORM':
            data = {
                payload: {
                    data_center_id: ``,
                    display_name: ``,
                    document_root_directory: ``,
                    wp_flag: 1,
                    wp_admin_username: ``,
                    wp_admin_password: password,
                    wp_admin_email: ``,
                    wp_title: ``,
                    wp_locale: `en_US`,
                    wp_enable_cache: 1,
                    site_type: `normal`,
                    plan_id:``
                },
                mode: "wordpress",
                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
            }
            buildErrors()
            return {...state, data}

        case 'CANCEL-SITE-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-DATA-CENTER-SELECT-SUCCESS':
            // data.payload.id = data_center_ids;
            data.data_center_id = data_center_ids;
            return {...state, data}

        case 'ON-SITE-FORM-MODE':
            data.mode = endOfURL();
            buildErrors();
            return {...state, data};

        case 'SET-ORGANIZATION-ID':
            data.payload.organization_id = +org_id;
            buildErrors();
            return {...state, data};

        case 'ON-SITE-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'SITE-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            );
            data.errorsPresent = true;
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors);
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, validationErrors} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            if (field === `plan_id`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (field === `display_name`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }

            if (field === `data_center_id`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (_('wp_flag') === 1) {
                if (field === `wp_admin_username`) {
                    if (!_(field)) newError(field, 0, `This is required field`)
                }
                if (field === `wp_admin_password`) {
                    if (!_(field)) newError(field, 0, `This is required field`)
                }
                if (field === `wp_admin_email`) {
                    if (!_(field)) newError(field, 0, `This is required field`)
                    else if (!isValidEmail(_(field))) newError(field, 0, `is invalid`);
                }
                if (field === `wp_title`) {
                    if (!_(field)) newError(field, 0, `This is required field`)
                }
                if (field === `wp_locale`) {
                    if (!_(field)) newError(field, 0, `This is required field`)
                }
            }
            if (_('ecommerce') === 1) {
                if (field === `site_type`) {
                    if (!_(field)) newError(field, 0, `This is required field`)
                }
            }

        }

        errors = mergeServerValidationErrors(errors, validationErrors);

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}