import { BugState } from './Bug-State'

export default function BugReducer(state = BugState, action) {

  let { data } = { ...state };
  let {name, value,tag} = action;

  switch (action.type) {

    case 'TOGGLE-BUG-FILTER':
      let toggle = true;
      if ( toggle ) data.params[ name ] = value;
      if (name === `sort_by`) {
        if (data.params.sort_by === value) {
          data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
        }
      }
      return { ...state, data };

    case 'PATH-FROM-BUG-URL':
      data.params['tag'] = tag;
      return {...state, data};

    case 'URGENCY-FILTER':
      data.params['urgency'] = value;
      data.params['status'] = "";
      return {...state, data};

      case 'filterAll':
      data.params['urgency'] = "";
      data.params['status'] = "";
      return {...state, data};

    case 'TOGGLE-BUG-FORM-MODAL':
      if (data.bug.mode === true) { // if open, close
        data.bug = {mode: ``,}
      } else { // if close, open
        data.bug.mode = true;
      }
      return {...state, data};

    default: return state
  }
}
