const DefaultPasswordResetFormState = {
    payload: {
        current_password: '',
        password: '',
        password_confirmation: '',
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
   };

export const PasswordResetFormState = {data: DefaultPasswordResetFormState};