import React from "react";
import store from "../../../Store";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import getBackupStatus from "../../../ajax/actions/backup-infrastructure/backup_status_get";
import { backupDateStoreFromUrlParam } from "./Backup-Status-Action";
import { isInvalidDate } from "../../../helpers/DateTimeHelper";
import { urlTrailWithOutParams } from "../../../helpers/UrlHelper";

class BackupStatusWorker extends React.Component {
  constructor(props) {
    super(props);
    const backUpDate = new URLSearchParams(this.props.location.search).get(
      "backup_date"
    );
    if (backUpDate && !isInvalidDate(backUpDate)) {
      store.dispatch(backupDateStoreFromUrlParam(backUpDate));
    } else {
      this.props.history.push(urlTrailWithOutParams);
    }
    store.dispatch(getBackupStatus());
  }

  render() {
    return null;
  }
}
function mapStateToProps(state) {
  let { getBackupStatus } = state;
  return { getBackupStatus };
}

export default withRouter(connect(mapStateToProps)(BackupStatusWorker));
