import {PasswordResetFormState} from './PasswordResetForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../../helpers/ErrorHelper'
import {endOfURL} from '../../../../helpers/UrlHelper'
import {isObject} from "../../../../helpers/DataHelper";

export default function passwordResetFormReducer(state = PasswordResetFormState, action) {

    let {data} = {...state};
    let {validationErrors, value, mode,name} = action;
    switch (action.type) {

        case 'ON-PASSWORD-RESET-FORM-CHANGE':
            data.payload[name] = value;
            buildErrors();
            return {...state, data};

        case 'ON-PASSWORD-RESET-FORM-MODE-CHANGE':
            data.mode = mode;
            buildErrors();
            return {...state, data};

        case 'SET-DEFAULT-PASSWORD-RESET-FORM':
            data = {
                payload: {
                    current_password: '',
                    password: '',
                    password_confirmation: '',
                },
                errors: [],
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: endOfURL(),
            };
            buildErrors();
            return {...state, data};

        case 'CANCEL-PASSWORD-RESET-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-PASSWORD-RESET-FORM-SUBMIT':
            data.submitted = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'PASSWORD-RESET-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                data.errors[key] = [{message: `${validationErrors[key][0]}`.slice(0, -1)}]
            );
            data.errorsPresent = true;
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, validationErrors} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            if (field === `current_password`) {
                if (!_(field)) newError(field, 0, `The current password field is required`)
            }
            if (field === `password`) {
                if (!_(field)) newError(field, 0, `The password field is required`)
            }
            if (field === `password_confirmation`) {
                if (!_(field)) newError(field, 0, `The password confirmation field is required`)
            }
        }

        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}