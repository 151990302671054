import React from 'react';
import UserWorker from "./UserWorker";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import TableHead from "../../../components/shared/table/TableHead";
import store from "../../../Store";
import {toggleUserListFilter} from "./UserList-Actions";
import deleteUser from "../../../ajax/actions/user/user_delete";
import swal from "sweetalert";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import Page from "../../../components/shared/pages/Page";
import UserListFilters from "./UserListFilters";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";

class User extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    toggleSort = (field) => {
        store.dispatch(toggleUserListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this user? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };


    delete(id) {
        store.dispatch(deleteUser(id));
    }

    render() {
        let {getUsers, users, fields, params, pages} = this.props;
        let {processing,error} = getUsers;
        if (error)
        {
            this.props.history.push('/');
        }
        return (
            <Page title="Users">
                <UserWorker/>
                < UserListFilters title="users"/>
                <div className="table-block table-responsive pr-60">

                    <table className="table table-striped  ">
                        <TableHead fields={fields}
                                   params={params}
                                   noActions={true}
                                   onClick={this.toggleSort}/>

                        <tbody>
                        {processing ? <td colSpan={4} className="text-center"><Loading/></td> :
                            users && users.length ? users.map((user, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>{(user.first_name)} {(user.middle_name)} {(user.last_name)}</td>
                                            <td>{user.email}</td>
                                            <td>{user.role}</td>
                                            <td>
                                                <div className="dropdown dropdown-alt text-center">
                                            <span className="dropdown-toggle"
                                                   data-toggle="dropdown">
                                                <span className="icon-more"/>
                                            </span>
                                                    <div className="dropdown-menu">
                                                        <Link to={`/allusers/${user.id}/update`} className={`link-edit`}>
                                                            Edit
                                                        </Link>
                                                        <a href="/#"
                                                           onClick={ev => {this.deleteConfirm(user.id);ev.preventDefault()}}
                                                           className={`link-delete`}>
                                                            Delete
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) :
                                <tr>
                                    <td colSpan={4} className="text-center">No Users Available</td>
                                </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {users && users.length ? (
                    <div className="fixed-footer pr-60">
                        <PaginationButtons pages={pages}
                                           processing={processing}
                                           fetchAction={toggleUserListFilter}/>
                    </div>
                ) : null}
            </Page>
        )
    }
}

function mapStateToProps(state) {
    let {getUsers, userList} = state;
    let {params, fields} = userList.data;
    let {success} = getUsers;

    let users =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data;

    let current_page = success && success.data && success.data.data.current_page;
    let last_page = success && success.data && success.data.data.last_page;
    let total = success && success.data && success.data.data.total;
    let pages = {current_page, last_page, total};

    return {getUsers, userList, params, fields, users, pages};
}

export default connect(mapStateToProps)(User);