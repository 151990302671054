import { GlobalActivityListState } from './GlobalActivityList-State'

export default function globalActivityListReducer(state = GlobalActivityListState, action) {

  let { data } = { ...state };
  let {name, value, site_id ,event} = action;

  switch (action.type) {

    case 'TOGGLE-GLOBAL-ACTIVITY-LIST-FILTER':
      let toggle = true;
      if ( toggle ) data.params[ name ] = value;
      if (name === `sort_by`) {
        if (data.params.sort_by === value) {
          data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
        }
      }
      return { ...state, data };

   /* case 'ON-GLOBAL-ACTIVITY-LOG-SITE-CHANGE':
      data.params.site_id = site_id;
      data.params.page = 1;
      return { ...state, data };

    case 'ON-GLOBAL-ACTIVITY-LOG-SITE-RESET':
      data.params.site_id = ``;
      return { ...state, data };*/

    case 'ON-GLOBAL-ACTIVITY-LOG-EVENT-CHANGE':
      data.params.event = event;
      data.params.page = 1;
      return { ...state, data };

    case 'ON-GLOBAL-ACTIVITY-LOG-EVENT-RESET':
      data.params.event = ``;
      data.params.page = 1;
      return { ...state, data };

    default: return state
  }
}
