import {sslState} from './SSL-State'

export default function sslListReducer(state = sslState, action) {

    let {data} = {...state};
    let {newletsEncryptDomainListId, newCustomDomainListId, isCheckedLetsEncryptDomainsList, changedDomainId,isCheckedCustomDomainsList} = action

    switch (action.type) {

        case 'TOGGLE-SSL-FORM-MODAL':
            if (data.ignore_ssl.mode === true) { // if open, close
                data.ignore_ssl = {mode: ``,}
            } else { // if close, open
                data.ignore_ssl.mode = true;
            }
            return {...state, data};

        case 'SET-LETSENCRYPT-DATA':
            data.data = newletsEncryptDomainListId;
            data.dataCustom = newCustomDomainListId;
            data.isCheckedLetsEncryptID = isCheckedLetsEncryptDomainsList
            data.isCheckedCustomID = isCheckedCustomDomainsList
            return {...state, data};

        case 'ON-CHANGE-SSL-LETS-ENCRYPT':
            data.data = changedDomainId;
            data.isCheckedLetsEncryptID = changedDomainId
            return {...state, data};

        default:
            return state
    }
}
