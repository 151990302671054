import axios from 'axios'
import {apiBaseUrl, endOfURL} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import {
    cancelBugFormDispatch,
    bugFormServerValidationErrors
} from "../../../pages/support/form/BugForm-Action";
import {toggleBugFormModal} from "../../../pages/support/list/Bug-Actions";
import getsBug from "./bugs_get";

function _success(success) {
    return {type: 'UPDATE_BUG_SUCCESS', success}
}

function _error(error) {
    return {type: 'UPDATE_BUG_ERROR', error}
}

function _processing(processing) {
    return {type: 'UPDATE_BUG_PROCESSING', processing}
}

function updateBugStatus(id) {
    return (dispatch,getState) => {
        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))
        let {bugForm}=getState();
        let {status} = bugForm.data.payload
        let payload = {
            status : status
        }

        axios({
            url: apiBaseUrl(`admin/internal-bug/update-status/${id}`),
            method: 'put',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                if(endOfURL() !== "details") {
                    store.dispatch(toggleBugFormModal());
                }
                store.dispatch(getsBug())
                store.dispatch(cancelBugFormDispatch())


            }).catch(function (error) {
            dispatch(_error(error.response.data.data))
            dispatch(_processing(false))
            handleError(error)
            store.dispatch(cancelBugFormDispatch());
            if (error.response && error.response.status === 422) {
                store.dispatch(bugFormServerValidationErrors(error.response.data.errors))
            }
        })
    }
}

export default updateBugStatus
