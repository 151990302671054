import React from 'react';
import Select from "react-select";

class CustomReactSelect extends React.Component {
    render() {
        const {
            isValid, submitted
        } = this.props

        const customStyles = {
            control: (base, state) => ({
                ...base,
                borderColor:  state.isFocused ?
                    '#ddd' :submitted && isValid.length ? 'red' : '#ddd',
                '&:hover': {
                    /* borderColor: state.isFocused ?
                         '#ddd' : submitted && isValid.length ?
                             'red' : '#ddd'*/
                    borderColor : "none"
                },
                backgroundColor: "none",
                boxShadow: "none",
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderBottom: !submitted && "solid 2px #d1d1d1",
                // background: "none",
                borderRadius: "none",

            })
        }
        return <Select styles={ customStyles } {...this.props}/>
    }
}
export default CustomReactSelect;