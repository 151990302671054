import React from 'react';
import {Helmet} from 'react-helmet';
import {ConfigApp} from "../../../Config";
// import BreadCrumb from "../../layout/breadcrumb/BreadCrumb";
// import Link from "react-router-dom/Link";

class Page extends React.Component
{

    render()
    {
        let { title, children } = this.props;
        let { Name } = ConfigApp;
        return (

            <div className="Page animated fadeIn faster">
                <div className="title-block">

                <Helmet>
                    <title>{ title } - { Name }</title>
                </Helmet>

                { children }
            </div>
            </div>
        )
    }
}
export default Page;