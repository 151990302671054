const DefaultHotfixListState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 25,
        page: 1,
        sort_order: 'desc',
        // sort_id: `id`,
        sort_by: ``,
        q: ``,
    },
    modal: {
        mode: ``
    },
    organization_id: ``,
    fields: [
        {field: `display_name`, label: `Name`, sort: true},
        // {field: `data_center_name`, label: `Data Center`, sort: true},
         {field: `status`, label: `Status`, sort: true},
        {field: `action`, label: `Action`, sort: false},
    ]
}

export const HotfixListState = {data: DefaultHotfixListState}