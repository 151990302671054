import { ServerModuleState } from './ServerModule-State'

export default function serverModuleListReducer(state = ServerModuleState, action) {

  let { data } = { ...state };
  let {name, value, data_center_id } = action;

  switch (action.type) {

    case 'TOGGLE-SERVER-MODULE-LIST-FILTER':
      data.params[ name ] = value;
      // if ( name === `sort_id` ) {
      //   if (data.params.sort_id === value) {
      //     data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
      //   }
      // }
      if (name === `sort_by`) {
        if (data.params.sort_by === value) {
          data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
        }
      }
      let toggle = true;
      if (toggle) {
        if (name === 'limit') {
          data.params.page = 1;
        }
        data.params[name] = value;
      }
      return { ...state, data };

    case 'ON-SERVER-DATA-CENTER-CHANGE':
      data.params.data_center_id = data_center_id;
      return { ...state, data };

    case 'ON-SERVER-RESET':
      data.params.data_center_id = ``;
      return { ...state, data };

    default: return state

    case 'ON-DATA-CENTER-SELECT-CHANGE':
      data.data_center_id = data_center_id;
      return { ...state, data }
  }
}
