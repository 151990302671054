import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import PasswordResetFormWorker from './PasswordResetFormWorker'
import {onPasswordResetFormChange} from "./PasswordResetForm-Action";
import Loading from "../../../../components/shared/loaders/Loading";
import PasswordField from "../../../../components/shared/form/MyAccountPasswordResetField";

class PasswordResetForm extends React.Component {

    onChange = (e) => {
        e.preventDefault();
        store.dispatch(onPasswordResetFormChange(e.target.name, e.target.value));
    };

    render() {
        let {
            errors, current_password, password, passwordReset, password_confirmation,handleCancel
        } = this.props;
        let {processing} = passwordReset

        return (
            <>
                <PasswordResetFormWorker/>
                {/*<form onSubmit={this.CouponFormSubmit}>*/}

                <div className="form-group animated fadeIn faster mb-0">
                    <div className="form-row">
                        <div className="col-lg-3 col-md-4">
                    <PasswordField required
                                   value={current_password}
                                   name="current_password"
                                   nolabel
                                   defaultValue={null}
                                   autoComplete="new-password"
                                   onChange={this.onChange}
                                   errors={errors && errors.current_password}/>
                        </div>
                        <div className="col-lg-3 col-md-4">
                    <PasswordField required
                                   value={password}
                                   name="password"
                                   nolabel
                                   defaultValue={null}
                                   autoComplete="new-password"
                                   onChange={this.onChange}
                                   errors={errors && errors.password}/>
                        </div>
                        <div className="col-lg-3 col-md-4">
                    <PasswordField required
                                   name="password_confirmation"
                                   value={password_confirmation}
                                   nolabel
                                   defaultValue={null}
                                   onChange={this.onChange}
                                   autoComplete="new-password"
                    />
                        </div>
                </div>

                    <div className="button-wrapper  inline-buttons text-right d-flex ">
                <button className="btn-theme btn-outline blue-outline" type="submit">{processing ?
                    <>Update Password
                        <Loading className="loader-position" height="15" width="15"/></> :
                    <>Update Password</>}
                </button>
                    <button className="btn-theme btn-outline blue-outline" type="reset"
                    onClick={handleCancel}>
                        Cancel
                    </button>
                    </div>
                </div>
                {/*<button className="btn-theme btn-black ml-4 btn-rounded position-relative" type="submit">
                      Activate<Loading className="loader-position" height="15" width="15"/>
                </button>*/}
                {/*</div>*/}
                {/*</form>*/}
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        passwordResetForm, passwordReset
    } = state;
    let {errors, payload} = passwordResetForm.data;
    let {
        current_password,password,password_confirmation
    } = payload;

    return {
        passwordResetForm, errors, passwordReset, current_password,password, password_confirmation
    };
}

export default connect(mapStateToProps)(PasswordResetForm);