import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store';
import getSiteStatus from "../sites/site_status_get";
import getSite from "../sites/site_get"

function _success(success) {
    return {type: 'DELETE_PLAN_SUCCESS', success};
}

function _error(error) {
    return {type: 'DELETE_PLAN_ERROR', error};
}

function _processing(processing) {
    return {type: 'DELETE_PLAN_PROCESSING', processing};
}

function deletePlan(site_id) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let { organizationDashboard} = getState();
        let {organization_id} = organizationDashboard.data;

        axios({
            url: apiBaseUrl(`admin/organizations/${organization_id}/sites/${site_id}/plans`),
            method: "delete",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res)
                store.dispatch(getSite(site_id))
                store.dispatch(getSiteStatus(site_id));
            })
            .catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError( error )
            });
    }
}


export default deletePlan;