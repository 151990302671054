import React from 'react';
import store from '../../../../Store';
import {connect} from 'react-redux';
import {setSiteIDDomain} from "./Domain-Action";
import {withRouter} from 'react-router';
import getSiteDomain from "../../../../ajax/actions/domain/site_domain_get";
import getCredentials from "../../../../ajax/actions/sftp-database/credential_get";

class DomainWorker extends React.Component {
    constructor(props) {
        super(props);
        store.dispatch(setSiteIDDomain(props.match.params.id));
        if(props.organizationDashboard.organization_id) {
            store.dispatch(getSiteDomain(props.organizationDashboard.organization_id));
            store.dispatch( getCredentials(props.organizationDashboard.organization_id ));
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {organizationDashboard, getSiteStatus} = state;
    let {organization_id} = organizationDashboard.data;
    return {organizationDashboard, organization_id, getSiteStatus};
}

export default withRouter(connect(mapStateToProps)(DomainWorker));