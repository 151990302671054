import {FeatureBlockState} from './FeatureBlock-State'

export default function featureBlockListReducer(state = FeatureBlockState, action) {

    let {data} = {...state};

    switch (action.type) {

        case 'TOGGLE-PUBLIC-PATH-FORM-MODAL':
            if (data.public_path_modal.mode === true) { // if open, close
                data.public_path_modal = {mode: ``,}
            } else { // if close, open
                data.public_path_modal.mode = true;
            }
            return {...state, data};

        case 'TOGGLE-SEARCH-AND-REPLACE-FORM-MODAL':
            if (data.modal.mode === true) { // if open, close
                data.modal = {mode: ``,}
            } else { // if close, open
                data.modal.mode = true;
            }
            return {...state, data};

        case 'TOGGLE-CACHE-IGNORE-FORM-MODAL':
            if (data.ignore_cache.mode === true) { // if open, close
                data.ignore_cache = {mode: ``,}
            } else { // if close, open
                data.ignore_cache.mode = true;
            }
            return {...state, data};

        case 'TOGGLE-PHP-VERSION-FORM-MODAL':
            if (data.php_version.mode === true) { // if open, close
                data.php_version = {mode: ``,}
            } else { // if close, open
                data.php_version.mode = true;
            }
            return {...state, data};

        case 'TOGGLE-PASSWORD-PROTECTION-FORM-MODAL':
            if (data.password_protection.mode === true) { // if open, close
                data.password_protection = {mode: ``,}
            } else { // if close, open
                data.password_protection.mode = true;
            }
            return {...state, data};
        default:
            return state
    }
}
