import React from 'react';
import Modal from 'react-responsive-modal';

class ResponsiveModalForm extends React.Component
{

    render()
    {
        const { visible, closeModal, children ,twoFactorauthenticationPopup,verifyAuthyPopup,closeIcon,closeOnEsc,closeOnOverlayClick} = this.props;
        return (

            <Modal open={ !! visible }
                   onClose={ closeModal }
                   animationDuration={200}
                   showCloseIcon={closeIcon}
                   closeOnEsc={closeOnEsc}
                   closeOnOverlayClick={closeOnOverlayClick}
                   classNames={{ overlay: `modal-overlay`, modal:verifyAuthyPopup?`modal-content verify-authy-popup`
                           :twoFactorauthenticationPopup?`modal-content enable-2fa-popup` :`modal-content share-file` }}
                   center >
                    <div className="modal-body">
                        { children }
                    </div>
            </Modal>

        );
    }
}

export default ResponsiveModalForm;