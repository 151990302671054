import React from 'react';
import LogsWorker from "./LogsWorker";
import {connect} from 'react-redux';
import Loading from "../../../../components/shared/loaders/Loading";
import {onClickError, onClickAccess} from "./Logs-Action"
import store from "../../../../Store"
import refreshSVG from '../../../../assets/images/refresh-light.svg'

class LogsList extends React.Component {

    state = {
        page: "error"
    }

    onClickError = () => {
        store.dispatch(onClickError())
        this.state.page = "error"
    }

    onClickAccess = () => {
        store.dispatch(onClickAccess())
        this.state.page = "access"
    }

    onClickRefresh = () => {
        if (this.state.page === "error") {
            store.dispatch(onClickError())
        } else if (this.state.page === "access") {
            store.dispatch(onClickAccess())
        }
    }

    render() {
        let {getLogs, allLogsList} = this.props;
        let {processing} = getLogs;
        return (
            <>
                <LogsWorker/>
                    <div className="nav-content-header">
                        <div>
                            <ul className="nav nav-pills">
                                <li className="nav-item">
                                    <a className="nav-link active" id="pills-error-tab" data-toggle="pill"
                                    href="#pills-error" role="tab" aria-controls="pills-daily"
                                    aria-selected="true" onClick={this.onClickError}>Error</a>
                                </li>

                                <li className="nav-item">
                                    {/*<a className="nav-link" id="pills-manual-tab" data-toggle="pill"*/}
                                    {/* <a className={`nav-link ${type === "access" ? 'active' : ""}`} id="pills-access-tab"
                                    data-toggle="pill"
                                    href="#pills-access" role="tab" aria-controls="pills-manual"
                                    aria-selected="false" onClick={this.onClickAccess}>Access</a> */}
                                    <a className="nav-link" id="pills-access-tab"
                                    data-toggle="pill"
                                    href="#pills-access" role="tab" aria-controls="pills-manual"
                                    aria-selected="false" onClick={this.onClickAccess}>Access</a>
                                </li>
                            </ul>
                        </div>
                        <div className="nav-content-header-refresh" onClick={this.onClickRefresh}>
                            <p>Refresh</p>
                            <img src={refreshSVG} alt="refresh"/>
                        </div>
                    </div>
                <div className="tab-content">
                    <div className="tab-pane fade show active" id="pills-error" role="tabpanel"
                         aria-labelledby="pills-error-tab">
                        <div className="setting-table table-responsive">
                            <table className="table table-striped">
                                <tbody>
                                {processing ? <td colSpan={5} className="text-center"><Loading/></td> :
                                    allLogsList && allLogsList.length ? allLogsList.map((logs, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    {logs}
                                                </td>
                                            </tr>

                                        )
                                    }) : <tr>
                                        <td colSpan={4}>No Error Logs Available</td>
                                    </tr>
                                }
                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div className="tab-pane fade" id="pills-access" role="tabpanel"
                         aria-labelledby="pills-access-tab">
                        <div className="setting-table table-responsive">
                            <table className="table table-striped">
                                <tbody>
                                {processing ? <td colSpan={5} className="text-center"><Loading/></td> :
                                    allLogsList && allLogsList.length ? allLogsList.map((accessLogs, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        {accessLogs}
                                                    </td>
                                                </tr>

                                            )
                                        }) :
                                        <td colSpan={5}>No Access Logs Available</td>

                                }
                                </tbody>
                            </table>

                        </div>
                    </div>

                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {getSite, siteDashboard, getSiteStatus, getLogs, logsList} = state;

    let allLogsList = getLogs && getLogs.success && getLogs.success.data

    let {params} = logsList.data

    return {getSite, siteDashboard, getSiteStatus, getLogs, logsList, params, allLogsList};
}

export default connect(mapStateToProps)(LogsList);