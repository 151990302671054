import store from '../../../Store';
import { toggleURLQuery } from '../../../helpers/UrlHelper'
import getAllOrganizationUsers from "../../../ajax/actions/all-organization-user/all_organizations_users_get";

export function toggleAllOrganizationUserListFilter(e, history)
{
  return function (dispatch) {
    let { name, value} = e.target;
    toggleURLQuery( name, value , history)
    dispatch({type:'TOGGLE-ALL-ORGANIZATION-USER-LIST-FILTER', name, value, history});
    if (name === 'limit') {
      store.dispatch(getAllOrganizationUsers());
      toggleURLQuery('page', '', history)
    }else{
      store.dispatch(getAllOrganizationUsers());
    }
  }
}

export function setDefaultAllOrganizationUserFiltersToURL( history )
{
  return ( dispatch, getState ) => {
    let { data } = getState().siteList;
    let { params } = data;
    Object.keys( params ).map( key => {
      if ( params[key] !== `` && params[key] !== null && params[key] !== undefined ) {
        toggleURLQuery( key, params[key], history)
      }
    })
  }
}