import React from 'react';
import { names, ucFirst,  unslufigy } from '../../../helpers/StringHelper'
import Switch from "react-switch";

class SwitchField extends React.Component
{
    placeholder()
    {
        let { placeholder, name, label } = this.props;
        if ( placeholder ) return placeholder;
        if ( label ) return label;
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = ucFirst( name.split(`_id`)[0] );
                return _label;
            }
        }
    }

    label()
    {
        let { name, label, errors } = this.props;
        let errorsPresent = errors && errors.length;
        if ( label ) return (
            <label className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>
                { label } { errorsPresent ? errors[0].message + '.' : null }
            </label>
        );
        else {
            if ( name ) {
                let _label = unslufigy( name );
                if ( name.includes(`_id`)) _label = name.split(`_id`)[0];
                return (
                    <label className={ names(`label-text`, errorsPresent && `has-error`, `animated fadeIn faster`) }>
                        { _label } { errorsPresent ? errors[0].message + '.' : null }
                    </label>
                )
            }
        }
    }

    render()
    {
        let {
            name, size, value, onChange, id, noLabel, disabled, errors, required, checked,
        } = this.props;
        return (
            <div className={ names(`form-group`, `animated fadeIn faster`, errors && errors.length && `has-error`, required && `required`) }>

                { !noLabel ? <>{ this.label() }</> : null }
                <Switch onChange={onChange}
                        name={name}
                        onColor="#86d3ff"
                        onHandleColor="#2693e6"
                        className={checked?"active":"react-switch"}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        size={size || `default`}
                        disabled={disabled}
                        id={id || name}
                        value={ value }
                        checked={checked} />
            </div>
        )
    }
}


export default SwitchField;