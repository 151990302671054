import axios from 'axios';
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {apiBaseUrl, endOfURL} from '../../../helpers/UrlHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getInvoice from "./payment_get";
import store from "../../../Store"
import getAllInvoice from "./all_invoices_get";

function _success(success) {
    return {type: 'DELETE_INVOICE_SUCCESS', success}
}

function _error(error) {
    return {type: 'DELETE_INVOICE_ERROR', error}
}

function _processing(processing) {
    return {type: 'DELETE_INVOICE_PROCESSING', processing}
}

function deleteInvoice(organization_id = null, invoice_id) {
    return (dispatch, getState) => {

        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let config = {
            headers: {'Authorization': 'Bearer ' + loginToken()},
        };

        let {organizationDashboard} = getState()
        let org_id = organization_id || organizationDashboard.data.organization_id;

        axios.delete(apiBaseUrl(`admin/organizations/${org_id}/invoices/${invoice_id}`), config)

            .then(res => {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
                store.dispatch(getInvoice(org_id))
                store.dispatch(getAllInvoice(org_id,invoice_id ))
            }).catch(error => {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error)

        });
    }
}

export default deleteInvoice;