const DefaultOrganizationBlockFormState = {
    payload: {
        organization_id: '',
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: ``,
    message: ``,
    site_id: ``,
    organization_id: ``,
};

export const OrganizationBlockFormState = {data: DefaultOrganizationBlockFormState};