import axios from 'axios';
import { apiBaseUrl } from "../../../helpers/UrlHelper";
// import { loginToken } from '../../../helpers/LocalStorageHelper'
import { handleError } from '../../../helpers/ErrorHelper'

function _success(success)
{
  return { type: 'GET_AUTHY_QR_SUCCESS', success };
}
function _error(error)
{
  return { type: 'GET_AUTHY_QR_ERROR', error };
}
function _processing(processing)
{
  return { type: 'GET_AUTHY_QR_PROCESSING', processing };
}

function getAuthyQr(token)
{
  return ( dispatch ) =>
  {
    dispatch(_processing(true));
     axios({

      url: apiBaseUrl(`admin/get-2fa-authy-qr-code`),
      method: "get",
      dataType: 'json',
      headers: { 'Authorization': 'Bearer ' +token }
    })
        .then(function (res) {
          dispatch(_success(res));
          dispatch(_processing(false));
        }).catch(function (error) {
          handleError(error);
          dispatch(_error(error));
          dispatch(_processing(false));
        });
  }
}


export default getAuthyQr;