import axios from 'axios';
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";
import getOrganization from "../organization/organization_get";
import store from "../../../Store"

function _success(success) {
    return {type: 'GET_ACTIVITY_LOGS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_ACTIVITY_LOGS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_ACTIVITY_LOGS_PROCESSING', processing};
}

function getActivityLogs(id = null) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_error(null));

        let {activityList, organizationDashboard} = getState();
        let {params} = activityList.data;
        if (!id) {
            id = id || organizationDashboard.data.organization_id;
        }
        axios({
            url: apiBaseUrl(`admin/organizations/${id}/activity_logs`),
            method: "get",
            dataType: 'json',
            params,
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`)
            }
        }).then(function (res) {
            dispatch(_processing(false));
            dispatch(_success(res));
            store.dispatch(getOrganization(id))

        }).catch(function (error) {
            handleError(error);
            dispatch(_error(error));
            dispatch(_processing(false));
        });
    }
}

export default getActivityLogs;
