import React from 'react';
import store from '../../../Store';
import {connect} from "react-redux";
import getsBug from "../../../ajax/actions/support/bugs_get";
import {withRouter} from "react-router";
import {endOfURL, urlTrailWithOutParams} from "../../../helpers/UrlHelper";
import {filter, filterAll, filterUrgency, tagStoreFromUrlParam, toggleBugFilter} from "./Bug-Actions";
import getBugs from "../../../ajax/actions/support/bugs_get";


class BugWorker extends React.Component
{
  constructor(props) {
    super(props);
    const urlTag = new URLSearchParams(this.props.location.search).get('tag');
    if (urlTag) {
      store.dispatch(tagStoreFromUrlParam(urlTag))
    } else {
      this.props.history.push(urlTrailWithOutParams)
    }
    if(endOfURL() === "active") {
      store.dispatch(filter("status","active"));
    }
    if(endOfURL() === "completed") {
      store.dispatch(filter("status","completed"));
    }
    if(endOfURL() === "urgent") {
      store.dispatch(filter("urgency","urgent"));
      store.dispatch(filter("status",""));
    }
    if(endOfURL() === "internal-support") {
      store.dispatch(filter("urgency",""));
      store.dispatch(filter("status",""));
    }
  }

  componentDidMount() {
    store.dispatch(getsBug())
  }

  componentDidUpdate( nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname){
      if(endOfURL() === "active") {
        store.dispatch(filter("status","active"));
      }
      if(endOfURL() === "completed") {
        store.dispatch(filter("status","completed"));
      }
      if(endOfURL() === "urgent") {
        store.dispatch(filterUrgency("urgent"));
        store.dispatch(filter("urgency","urgent"));

      }
      if(endOfURL() === "internal-support") {
        store.dispatch(filterAll());
        store.dispatch(filter("status",""));
      }
    }
  }

  render()
  {
      return null
  }
}

function mapStateToProps( state ) {
  let {getsBug } = state;
  return { getsBug}
}
export default withRouter(connect(mapStateToProps)(BugWorker));
