const DefaultDataCenterState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 25,
        page: 1,
        order: 'desc',
        sort_by: `name`,
        q: ``,
    },
    fields: [
        {field: `name`, label: `Name`, sort: true},
        {field: `availability`, label: `Availability`, sort: true},
        {field: `status`, label: `Status`, sort: true},
        {field: `action`, label: `Action`, sort: false},
    ]
}

export const DataCenterState = {data: DefaultDataCenterState}