import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import DomainFormWorker from './DomainFormWorker'
import {
    onDomainFormChange,
    onDomainFormSubmit,
    onDomainFormNameChange,
    onDomainFormModeChange,
    onRemoveErrorMessage
} from "./DomainForm-Action";
import TextField from "../../../../components/shared/form/TextField";
import CheckField from "../../../../components/shared/form/CheckField";
import Loading from "../../../../components/shared/loaders/Loading";
import {toggleDomainFormModal} from "../list/Domain-Action";
import MultipleValueTextInput from 'react-multivalue-text-input';
import {isValidDomain} from '../../../../helpers/ValidationHelper';

class DomainForm extends React.Component {
    state = {
        inputs: ['input-0'],
        input: [],
        data: [],
        version: ""
    };

    domainFormSubmit = (e) => {
        e.preventDefault();
        if (this.state.version === 2) {
            store.dispatch(onDomainFormModeChange("bulk"))
            store.dispatch(onDomainFormNameChange(this.state.data))
        }
        store.dispatch(onDomainFormSubmit());

    };

    onChangeName = (allItems, lb_version) => {
        store.dispatch(onRemoveErrorMessage());

        this.setState({
            data: allItems,
        });
        this.setState({
            version: lb_version,
        });
    };

    onChange = (e) => {
        store.dispatch(onDomainFormChange(e.target.name, e.target.value,));
    };

    render() {
        let {
            name, has_www, updateDomain, createDomain, domainForm,
            lb_version
        } = this.props;
        let {errors} = domainForm.data;
        let processing = updateDomain.processing || createDomain.processing;

        return (
            <>
                <h2 className="text-center mb-30">Add domain</h2>
                <p>Adding a domain to this site will make sure that visitors arriving to the new domain
                    will see the contents of this site. Note that you will have to make sure that the
                    new domain has its DNS Records set up correctly. For more information read our guide
                    on <a href="/#" onClick={ev => {
                        ev.preventDefault()
                    }}>DNS Setup.</a></p>
                <DomainFormWorker/>

                <form className="mt-30 domain-form" onSubmit={this.domainFormSubmit}>
                    {lb_version === 2 ?
                        <>
                            <p style={{color: 'red'}}> {((this.state.data).length) && domainForm?.data.message !== "" ? domainForm?.data.message : null}</p>
                            <p style={{color: 'red'}}> {!((this.state.data).length) && errors && errors.name && errors.name.length ? "This is required Field" : null}</p>
                        </>
                        :
                        <>
                            <p style={{color: 'red'}}> {domainForm?.data.message !== "" ? domainForm?.data.message : null}</p>
                        </>
                    }

                    {lb_version === 2 ?
                        <>
                                <MultipleValueTextInput
                                    onItemAdded={(item, allItems) => this.onChangeName(allItems, lb_version)}
                                    onItemDeleted={(item, allItems) => this.onChangeName(allItems, lb_version)}
                                    name="item-input"
                                    placeholder="Enter Domains; separate them with COMMA or ENTER."
                                />
                        </>
                        :
                        <>
                            <TextField name="name"
                                       value={name}
                                       label="Domain Name"
                                       errors={errors.name}
                                       onChange={this.onChange}/>

                            <CheckField name="has_www"
                                        value={has_www}
                                        item={has_www === 1 ? "has_www" : null}
                                        label="Add domain(s) with and without www"
                                        checked={has_www === 1}
                                        onChange={this.onChange}
                                        errors={errors.has_www}
                            />
                        </>
                    }

                    <div className="button-wrapper  inline-buttons text-center d-flex justify-content-center">

                        <button className="btn-theme btn-outline" type="reset"
                                onClick={() => store.dispatch(toggleDomainFormModal())}>Cancel
                        </button>
                        <div className="tab-domain-button">
                            <button type="submit" className="btn-theme btn-dark-blue">
                                {processing ? <Loading/> : `Add Domain`}
                            </button>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps(state) {

    let {
    domainForm
,
    updateDomain
,
    createDomain
,
    getServerModules
,
    getSite
}

= state;

let {success} = getServerModules;

let {lb_version} = getSite && getSite.success

let serverList =
    success &&
    success.data &&
    success.data.data;

let {errors, payload} = domainForm.data;

let {name, has_www, sss_type,} = payload;

return {
    getServerModules, serverList, domainForm, updateDomain, createDomain, errors,
    name, has_www, sss_type, getSite, lb_version
};
}

export default connect(mapStateToProps)(DomainForm);