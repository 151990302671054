const DefaultSearchAndReplaceFormState = {
    payload: {
        searchTerm: ``,
        replaceTerm: ``
    },
    replace_flag: 0,
    amount_of_terms: ``,
    search_item_name: ``,
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: ``,
    message: ``,
    site_id: ``,
    failedStatus:``,
    errorMessage:``
};

export const SearchAndReplaceFormState = {data: DefaultSearchAndReplaceFormState};