import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getSite from "../../../ajax/actions/sites/site_get";
import store from "../../../Store";
import getSiteStatus from "../sites/site_status_get";
import {cancelPasswordProtectionFormDispatch,setDefaultPasswordProtectionForm} from "../../../pages/site/passwordProtection/form/PasswordProtectionForm-Action";
import {togglePasswordProtectionFormModal} from "../../../pages/site/feature-block/list/FeatureBlock-Action";

function _success(success) {
    return {type: 'CREATE_PASSWORD-PROTECTION_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_PASSWORD-PROTECTION_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_PASSWORD-PROTECTION_PROCESSING', processing}
}

function createPasswordProtection() {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {  passwordProtectionForm,SftpList,organizationDashboard} = getState();
        let { site_id} = SftpList.data;
        let org_id = organizationDashboard.data.organization_id;
        let payload = passwordProtectionForm.data.payload

        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/tools/http_auth/credentials`),
            method: 'post',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res.data.message)
                store.dispatch(cancelPasswordProtectionFormDispatch())
                store.dispatch(getSite(site_id))
                store.dispatch(getSiteStatus(site_id))
                store.dispatch(setDefaultPasswordProtectionForm())
                store.dispatch(togglePasswordProtectionFormModal());
            }).catch(function (error) {
            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
            store.dispatch(cancelPasswordProtectionFormDispatch())
        })
    }
}

export default createPasswordProtection;
