import {LoadBalancerState} from './LoadBalancer-State'


export default function loadBalancerReducer(state = LoadBalancerState, action) {

    let {data} = {...state};
    let {name, value, data_center_ids, server_id} = action;

    switch (action.type) {

        case 'TOGGLE-LOAD-BALANCER-LIST-FILTER':
            data.params[name] = value;
            // if (name === `sort_id`) {
            //     if (data.params.sort_id === value) {
            //         data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
            //     }
            // }
            if (name === `sort_by`) {
                if (data.params.sort_by === value) {
                    data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
                }
            }
            return {...state, data};

        case 'ON-DATA-CENTER-SELECT-SUCCESS':
            data.data_center_id = data_center_ids;
            return {...state, data}

        case 'ON-DATA-CENTER-SELECT-RESET':
            data.data_center_id = ``;
            data.server_id = ``;
            return {...state, data}

        case 'ON-SERVER-SELECT-RESET':
            data.params.server_id = "";
            return {...state, data}

        case 'ON-SERVER-SELECT-SUCCESS':
            data.params.server_id = server_id;
            return {...state, data}
        default:
            return state
    }
}
