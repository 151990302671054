import React from 'react';

class NotFound extends React.Component
{
    render()
    {
        return (
            <div>
                <p>Page Not Found</p>
            </div>
        )
    }
}
export default NotFound;