import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store';
import {scrollToError} from '../../../helpers/ScrollHelper'
import getHotFix from "./hotfix_get";

function _success(success) {
    return {type: 'POST_HOTFIX_SUCCESS', success};
}

function _error(error) {
    return {type: 'POST_HOTFIX_ERROR', error};
}

function _processing(processing) {
    return {type: 'POST_HOTFIX_PROCESSING', processing};
}

function createHotfix(id) {
    return (dispatch) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        axios({
            url: apiBaseUrl(`admin/sites/hotfix/${id}`),
            method: "get",
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res));

                setTimeout(
                    () => dispatch(_processing(false))
                    ,
                    2000
                );
                setTimeout(
                    () =>store.dispatch(getHotFix())
                    ,
                    2000
                );
                setTimeout(
                    () => handleSuccess(res)
                    ,
                    2000
                );


            })
            .catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error)
                if (error.response && error.response.status === 422) {
                    // store.dispatch(hotfixFormServerValidationErrors(error.response.data.errors))
                    scrollToError();
                }
            });
    }
}


export default createHotfix;