import React from 'react';
import {connect} from 'react-redux'
import {Switch, Route, withRouter} from 'react-router-dom';
import OrganizationDetail from "./OrganizationDetail";
import OrganizationDashboardWorker from "./OrganizationDashboardWorker";
import Site from "../site/list/Site";
import Page from "../../components/shared/pages/Page";
import store from '../../Store';
import {setDashboardMenu} from "../../routes/main-route/MainRoute-Actions";

class OrganizationDashboard extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu('organization'));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    render() {
        let { match} = this.props;
        let {url} = match;

        return (
            <Page title="Payment">
                <OrganizationDashboardWorker/>
                <div className="panel-body">
                    <Switch>
                        <Route exact path={`${url}/sites`} render={() => <Site/>}/>
                        <Route exact path={`${url}/activitylog`} render={() => <Site/>}/>
                        <Route exact path={`${url}/users`} render={() => <Site/>}/>
                        <Route exact path={`${url}/payment`} render={() => <Site/>}/>
                        <Route exact path={`${url}`} component={OrganizationDetail}/>
                        {/*<Route exact path={'organization/create'} component={OrganizationForm}/>*/}
                    </Switch>
                </div>
            </Page>
        );
    }
}

function mapStateToProps(state) {
    let {userDetail} = state;
    return {userDetail};
}

export default withRouter(connect(mapStateToProps)(OrganizationDashboard));