import React from 'react';
import MigrationTrackingWorker from "./MigrationTrackingWorker";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import TableHead from "../../../components/shared/table/TableHead";
import store from "../../../Store";
import {toggleMigrationTrackingListFilter} from "./MigrationTracking-Action";
import swal from "sweetalert";
import deleteMigrationTracking from "../../../ajax/actions/migration-tracking/migration_tracking_delete";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import MigrationTrackingListFilters from "./MigrationTrackingListFilters";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import {ucFirst} from "../../../helpers/StringHelper";
import { Availability, Status } from '../../../components/shared/status/Status';

class MigrationTracking extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    toggleSort = (field) => {
        store.dispatch(toggleMigrationTrackingListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this data center? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id) {
        store.dispatch(deleteMigrationTracking(id));
    }

    render() {
        let {list, fields, params, pages, getsMigrationTracking} = this.props;
        let {processing, error} = getsMigrationTracking;
        if (error) {
            this.props.history.push('/');
        }
        return (
            <>
                <Page title="Internal Migration Tracking" processing={processing}>

                    <MigrationTrackingWorker/>
                    <MigrationTrackingListFilters title="internal-migration-tracking"/>
                    <div className="table-block table-responsive pr-60">

                        <table className="table table-striped  ">
                            <TableHead fields={fields}
                                       params={params}
                                       noActions={true}
                                       onClick={this.toggleSort}
                                       processing={processing}
                            />
                            <tbody>
                            {processing ? <td colSpan={10} className="text-center"><Loading/></td> :
                                list && list.length ? list.map((l, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                <Link to={`/internal-migration-tracking/${l.id}/update`}>{ucFirst(l.site_name)}</Link>
                                            </td>
                                            <td>
                                                {l.source_server_ip}
                                            </td><td>
                                                {l.source_lb_ip}
                                            </td><td>
                                                {l.destination_server_ip}
                                            </td><td>
                                                {l.destination_lb_ip}
                                            </td><td>
                                                {l.old_container_deleted === 1 ? "Yes":"No"}
                                            </td><td>
                                                {l.backup_deleted=== 1 ? "Yes":"No"}
                                            </td><td>
                                                {l.client_verified=== 1 ? "Yes":"No"}
                                            </td>
                                            <td>
                                                <Status status={l.status}/>
                                            </td>
                                            <td>
                                                <div className="dropdown dropdown-alt text-center">
                                                <span className="dropdown-toggle"
                                                      data-toggle="dropdown">
                                                    <span className="icon-more"/>
                                                </span>
                                                    <div className="dropdown-menu">
                                                        <Link to={`/internal-migration-tracking/${l.id}/update`}
                                                              className={`link-edit`}>
                                                            Edit
                                                        </Link>
                                                        <a href="/#"
                                                           onClick={ev => {this.deleteConfirm(l.id);ev.preventDefault()}}
                                                           className={`link-delete`}>
                                                            Delete
                                                        </a>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }) : <tr>
                                    <td colSpan="10" className="text-center">No Migration Available</td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
                    {list && list.length ? (
                        <div className="fixed-footer pr-60">
                            <PaginationButtons
                                className="pagination"
                                pages={pages}
                                processing={processing}
                                fetchAction={toggleMigrationTrackingListFilter}/>
                        </div>
                    ) : null}
                </Page>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {getsMigrationTracking, migrationTrackingList} = state;
    let {params, fields} = migrationTrackingList.data;

    let {success} = getsMigrationTracking;

    let list =
        success &&
        success.data

    let current_page = success && success.data.current_page;
    let last_page = success && success.data.last_page;
    let total = success && success.total;
    let pages = {current_page, last_page, total};

    return {getsMigrationTracking, list, migrationTrackingList, params, fields, pages};
}

export default connect(mapStateToProps)(MigrationTracking);