import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import {onBillingBlockFormChange, onBillingBlockFormSubmit} from "./BillingBlockForm-Action";
import swal from "sweetalert";
import SelectField from "../../../components/shared/form/SelectField";
import {activeStatusFilter} from "../../../static-data/UserData";
import BillingBlockFormWorker from "./BillingBlockFormWorker";
import Billing from '../../../assets/images/billing.svg'
import {withRouter} from "react-router";


class billingBlockForm extends React.Component {

    BillingBlockSubmit = (e) => {
        e.preventDefault();
        swal("Are you sure you want to update Billing?", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Yes",
                    value: e
                }
            }
        }).then((e) => {
            if (e) store.dispatch( onBillingBlockFormSubmit()  )
        })
    };

    onChange = (e) => {
        e.preventDefault();
        store.dispatch(onBillingBlockFormChange(e.target.name, e.target.value,));
    };

    render() {
        let {
            errors, site_status, payload
        } = this.props;

        return (
            <>
                <BillingBlockFormWorker/>
                <form className="mt-10" onSubmit={this.BillingBlockSubmit}>
                    <div className="padded-block">
                        <div className="d-flex justify-content-between align-items-center col-md-12 px-0" id="form-block-section">
                            <div className="d-flex text-section">
                                <img alt="logo" src={Billing}/>
                                <div>
                                    <p id="site-tools">Billing Tools</p>
                                    <h3>Billing</h3>
                                </div>
                            </div>
                            {/* <div className="col-md-6 mb-10 "> */}
                                <div className="d-flex px-0 input-and-button-section justify-content-between">
                                    <div className="px-0 input-section mr-5">
                                        <SelectField name="billable"
                                                     value={payload.billable}
                                                     options={activeStatusFilter}
                                                     errors={errors.billable}
                                                     onChange={this.onChange}
                                                     className="px-0 mb-0"
                                                     _value="value"
                                                     _label="name"
                                                     label="Disable/Activate"
                                                     />
                                    </div>
                                    <div className="button-wrapper">
                                            <button type="submit" className="btn-theme btn-outline" id="admin-org-btn"
                                                    disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring'? 'disabled' : null}
                                                    onClick={this.BillingBlockSubmit}
                                            >
                                                Update Billing
                                            </button>

                                    </div>
                                </div>

                            {/* </div> */}
                        </div>
                        {/* <div className="row">
                        <div className="button-wrapper inline-buttons text-center">
                                <button type="submit" className="btn-theme btn-black "
                                        disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring'? 'disabled' : null}
                                        onClick={this.BillingBlockSubmit}
                                >
                                    Update Billing
                                </button>

                        </div>
                        </div> */}
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {billingBlockForm,updateSiteBilling, getSite,getSiteStatus,
        siteDashboard
    } = state;
    let {errors, payload} = billingBlockForm.data;
    let { site_status,org_id } = siteDashboard.data;
    let billable = getSite && getSite.success && getSite.success.billable

    return {
        billingBlockForm,updateSiteBilling,billable, getSite, getSiteStatus, org_id, site_status, payload,errors
    };
}

export default withRouter(connect(mapStateToProps)(billingBlockForm));
