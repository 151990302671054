const DefaultExpiredBackupState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    per_page: 25,
    limit:10,
    page:   1,
    order:  'desc',
    sort_id: `id`,
    q: ``,
  },
  site_id:``,
  backup_modal: {
    mode: '',
  },
  fields: [
    { field: `id`, label: `Backup Id`, sort: false },
    { field: `site_name`, label: `Site Name`, sort: false },
    { field: `name`, label: `Backup Name`, sort: false },
    { field: `storage_type`, label: `Storage Type`, sort: false },
    { field: `type`, label: `Backup Type`, sort: false },
    { field: `snapshot_name`, label: `SnapShot Name`, sort: false },
    { field: `delete_attempt`, label: `Delete Attempt`, sort: false },
    { field: `backup_count`, label: `Backup Count`, sort: false },
    { field: `created_at`, label: `Created At`, sort: false },
    { field: `expiry_date`, label: `Expiry Date`, sort: false },
    // { field: `remarks`, label: `Description`, sort: false },
  ]
}

export const ExpiredBackupState = {data:DefaultExpiredBackupState}