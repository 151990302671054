import { MigrateState } from './Migrate-State'

export default function MigrateReducer(state = MigrateState, action) {

  let { data } = { ...state };
  let {name, value} = action;

  switch (action.type) {

    case 'TOGGLE-MIGRATE-FILTER':
      let toggle = true;
      if ( toggle ) data.params[ name ] = value;
      if (name === `sort_by`) {
        if (data.params.sort_by === value) {
          data.params.sort_order = data.params.sort_order === `desc` ? `asc` : `desc`
        }
      }
      return { ...state, data };

    case 'TOGGLE-MIGRATE-FORM-MODAL':
      if (data.migrate.mode === true) { // if open, close
        data.migrate = {mode: ``,}
      } else { // if close, open
        data.migrate.mode = true;
      }
      return {...state, data};

    default: return state
  }
}
