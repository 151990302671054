import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getSite from "../../../ajax/actions/sites/site_get";
import store from "../../../Store";

function _success(success) {
    return {type: 'ENABLE_OR_DISABLE_MONITOR_SUCCESS', success}
}

function _error(error) {
    return {type: 'ENABLE_OR_DISABLE_MONITOR_ERROR', error}
}

function _processing(processing) {
    return {type: 'ENABLE_OR_DISABLE_MONITOR_PROCESSING', processing}
}

function enableOrDisableMonitor(payload) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {SftpList, organizationDashboard} = getState();
        let { site_id} = SftpList.data;
        let org_id = organizationDashboard.data.organization_id;

        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/monitor`),
            method: 'post',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                // handleSuccess("Monitoring request sent Successfully")
                handleSuccess(res.data.message)
                store.dispatch(getSite(site_id, org_id))

            }).catch(function (error) {

            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
        })
    }
}

export default enableOrDisableMonitor;
