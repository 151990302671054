import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import PriceFormWorker from './PriceFormWorker'
import {onPriceFormChange, onPriceFormSubmit} from "./PriceForm-Action";
import TextField from "../../../components/shared/form/TextField";
import Loading from "../../../components/shared/loaders/Loading";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Link from "react-router-dom/Link";
import {endOfURL} from "../../../helpers/UrlHelper";
import Page from "../../../components/shared/pages/Page";
import SelectField from "../../../components/shared/form/SelectField";
import {selectType} from "../../../static-data/UserData";


class PriceForm extends React.Component {
    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    PriceFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onPriceFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onPriceFormChange(e.target.name, e.target.value,));
    };

    render() {
        let {
            priceForm, updatePrice, site_type, price, currency
        } = this.props;
        let {errors} = priceForm.data;
        let processing = updatePrice.processing;

        return (

            <>
                <Page title={endOfURL() === "create" ?
                    "Add Price" :
                    "Update Price"
                } processing={processing}>
                    <div className="heading-block pr-60">
                        <div className="title-block">
                            <h2> {endOfURL() === 'create' ? 'Add new Price' : 'Update Price'}</h2>
                            <span><Link to="/price">Price</Link></span>
                            {/*{endOfURL() === 'update' ? <span>{ucFirst("name")}</span> : null}*/}
                            <span>{endOfURL() === 'create' ? `Add new Price` : `Update`}</span>
                        </div>
                    </div>
                    <PriceFormWorker/>
                    <div className="box-block">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="pills-add-user" role="tabpanel"
                                 aria-labelledby="pills-add-user-tab">
                                <div className="padded-block">
                                    <form onSubmit={this.PriceFormSubmit}>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField name="price"
                                                               type="number"
                                                               value={price}
                                                               label="Price"
                                                               errors={errors.price}
                                                               onChange={this.onChange}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField name="currency"
                                                               value={currency}
                                                               label="Currency"
                                                               errors={errors.currency}
                                                               onChange={this.onChange}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <SelectField name="site_type"
                                                                 value={site_type}
                                                                 options={selectType}
                                                                 errors={errors.site_type}
                                                                 onChange={this.onChange}
                                                                 _value="value"
                                                                 _label="name"/>
                                                </div>
                                            </div>

                                        </div>
                                                <div className="button-wrapper inline-buttons text-center d-flex justify-content-end" id="form-submit-btn">
                                                    <button type="submit"
                                                            className="btn-theme btn-dark-blue">
                                                        {processing ? <Loading/> : `Submit`}
                                                    </button>

                                            </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>
            </>

        )
    }
}

function mapStateToProps(state) {
    let {priceForm, updatePrice} = state;

    let {errors, payload, mode} = priceForm.data;

    let {
        site_type, price, currency
    } = payload;

    return {
        priceForm, updatePrice, errors,currency,
        site_type, price, mode
    };
}

export default connect(mapStateToProps)(PriceForm);