import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import {cancelUsersFormDispatch, userFormServerValidationErrors} from '../../../pages/user/form/UserForm-Actions'
import {scrollToError} from '../../../helpers/ScrollHelper'
import getLoggedInUser from "./user_loggedin_get";
import getUser from "./user_get";

function _success(success) {
    return {type: 'UPDATE_USER_SUCCESS', success}
}

function _error(error) {
    return {type: 'UPDATE_USER_ERROR', error}
}

function _processing(processing) {
    return {type: 'UPDATE_USER_PROCESSING', processing}
}

function updateUser(id) {
    return (dispatch, getState) => {

        let {userForm} = getState()

        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))

        axios({
            url: apiBaseUrl(`admin/${id}`),
            method: 'put',
            dataType: 'json',
            data: JSON.parse(JSON.stringify(userForm.data.payload)),
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                store.dispatch(getUser(id))
                store.dispatch(getLoggedInUser())
                store.dispatch(cancelUsersFormDispatch())

            }).catch(function (error) {
            dispatch(_error(error.response.data.message))
            dispatch(_processing(false))
            handleError(error)

            store.dispatch(cancelUsersFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(userFormServerValidationErrors(error.response.data.errors))
                scrollToError()
            }
        })
    }
}

export default updateUser
