import React from 'react';
import {connect} from 'react-redux';
import SftpWorker from "./SftpWorker";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import store from "../../../../Store";
import credentialPost from '../../../../ajax/actions/sftp-database/credential_post';
import Loading from "../../../../components/shared/loaders/Loading";
import Tooltip from "../../../../components/shared/tooltip/Tooltip";
import getSiteStatus from "../../../../ajax/actions/sites/site_status_get";
import getCredentials from "../../../../ajax/actions/sftp-database/credential_get";
import copy from 'copy-to-clipboard';

class SftpList extends React.Component {
    state = {
        load_balancer_public_ip: false,
        sftp_host: false,
        sftp_username: false,
        sftp_password: false,
        sftp_port: false,
        db_name: false,
        db_username: false,
        db_password: false,
        php_myadmin_url: false,
        ssh_terminal_command: false,
        data_center_name: false,
        copy_all_sftp_info: false,
        copy_all_db_info: false,

    };

    componentDidUpdate(prev) {
        this.state.load_balancer_public_ip = false
        this.state.ssh_terminal_command = false
        this.state.sftp_host = false
        this.state.sftp_username = false
        this.state.sftp_password = false
        this.state.sftp_port = false
        this.state.db_name = false
        this.state.db_username = false
        this.state.db_password = false
        this.state.php_myadmin_url = false
        this.state.ssh_terminal_command = false
        this.state.data_center_name = false
        this.state.copy_all_sftp_info = false
        this.state.copy_all_db_info = false

        if (this.props.createCredentials !== prev.createCredentials) {
            let {credentials, createCredentials} = this.props;
            let {success} = createCredentials;
            if (success) {
                credentials && store.dispatch(getSiteStatus(credentials.site_id))
                this.setState({processing_sftp_password: false, processing_db_password: false})
            }
        }

        if (this.props.getSiteStatus !== prev.getSiteStatus) {
            let {getSiteStatus} = this.props;
            let data = getSiteStatus?.success?.notifications
            data && data.length && data.map((i, keys) => {
                if (i.event_name === "change_site_ssh_password" ||
                    i.event_name === "change_site_db_password") {
                    store.dispatch(getCredentials());
                }
            })
            if (getSiteStatus?.success?.status === "updating") {
                return null
            }
        }
    }

    generateDatabasePassword = () => {
        const payload = {
            "target": "db"
        }
        store.dispatch(credentialPost(payload));
    }

    generateSftpPassword = () => {
        const payload = {
            "target": "ssh"

        }
        store.dispatch(credentialPost(payload));

    }

    handleSftpCopy = (e,sftpAllInfoCopy) => {
        e.preventDefault();
        this.setState({copy_all_sftp_info: true})
        copy(sftpAllInfoCopy, {
            format : "text/plain"
        });
    }

    handleDbCopy = (e,dbAllInfoCopy) => {
        e.preventDefault();
        this.setState({copy_all_db_info: true})
        copy(dbAllInfoCopy, {
            format : "text/plain"
        });
    }

    render() {
        let {credentials, site_status, getCredentials, getSiteStatus, getSite, dataCenterName} = this.props;
        let {processing} = getCredentials.processing;

        let sftpAllInfoCopy = ""
        let dbAllInfoCopy = ""

        if (credentials) {
            sftpAllInfoCopy =
                "=== SFTP Details ====" +
                "\nSFTP HOST : " + credentials.sftp_host +
                "\nSFTP Username : " + credentials.sftp_username +
                "\nSFTP Password : " + credentials.sftp_password +
                "\nSFTP Port : " + credentials.sftp_port


            dbAllInfoCopy =
                "=== Database Details ====" +
                "\nphpMyAdmin URL : " + credentials.php_myadmin_url +
                "\nDatabase Name : " + credentials.db_name +
                "\nDatabase Username : " + credentials.db_username +
                "\nDatabase Password : " + credentials.db_password
        }


        return (
            <>
                <SftpWorker/>
                {processing ? <div className="text-center"><Loading/></div> :

                    <>
                        {credentials &&
                            <div className="padded-block">
                                <div className="row align-items-end">
                                    <div className="col-xl-3 col-lg-6 col-sm-6">
                                        <div className="content-label">Site IP Address</div>

                                        <div className="content-info content-info-wrapper">
                                            <span>  {getSite?.success?.load_balancer_public_ip}</span>

                                            <div className="text-center mt-2 action-btn-copy">

                                                <CopyToClipboard
                                                    text={getSite?.success?.load_balancer_public_ip}
                                                    onCopy={() => this.setState({load_balancer_public_ip: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.load_balancer_public_ip ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.load_balancer_public_ip ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.load_balancer_public_ip ? null : 'Copy'}/>
                                                            :
                                                            this.state.load_balancer_public_ip ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.load_balancer_public_ip ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.load_balancer_public_ip ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-3 col-sm-6">
                                        <div className="content-label">Data Center</div>

                                        <div className="content-info content-info-wrapper">
                                            <span>  {getSite?.success?.data_center_name}</span>

                                            <div className="text-center mt-2 action-btn-copy">

                                                <CopyToClipboard
                                                    text={getSite?.success?.data_center_name}
                                                    onCopy={() => this.setState({data_center_name: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.data_center_name ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.data_center_name ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.data_center_name ? null : 'Copy'}/>
                                                            :
                                                            this.state.data_center_name ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.data_center_name ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.data_center_name ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-6">
                                        {/*<CopyToClipboard text={sftpAllInfoCopy} format="text/html"*/}
                                        {/*                 onCopy={() => this.setState({copy_all_sftp_info: true})}>*/}
                                            <div className="btn-text-wrapper">
                                                <button
                                                    onClick={(e)=>this.handleSftpCopy(e,sftpAllInfoCopy)}
                                                    className={this.state.copy_all_sftp_info ? "btn-theme btn-text all-copied" : "btn-theme btn-text"}>
                                                    {!this.state.copy_all_sftp_info ?
                                                        "Copy All SFTP Info"
                                                        :
                                                        this.state.copy_all_sftp_info ?
                                                            <div>
                                                                Copied
                                                            </div>
                                                            : null}

                                                </button>
                                            </div>
                                        {/*</CopyToClipboard>*/}
                                    </div>
                                </div>
                                <div className="row">

                                    {/* <div className="col-xl-3 col-lg-3 col-sm-6">
                                <div className="content-label">Data Center </div>

                                <div className="content-info content-info-wrapper">
                                    <span>  {getSite?.success?.data_center_name}</span>

                                    <div className="text-center mt-2 action-btn-copy">

                                        <CopyToClipboard
                                            text={getSite?.success?.data_center_name}
                                            onCopy={() => this.setState({data_center_name: true})}>
                                            <button className="btn-sm" >
                                                {!this.state.data_center_name  ?
                                                    <Tooltip title={<span
                                                        className={`${this.state.data_center_name ? `text-danger` : null} icon-compare`}/>}
                                                             tooltip={this.state.data_center_name ? null : 'Copy'}/>
                                                    :
                                                    this.state.data_center_name ?
                                                        <div>
                                                            <Tooltip title={<span
                                                                className={`${this.state.data_center_name ? `text-danger` : null} icon-check `}/>}
                                                                     tooltip={this.state.data_center_name ? 'Copied' : null}/></div>
                                                        : null}
                                            </button>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div> */}

                                    <div className="col-xl-3 col-lg-6 col-sm-6">
                                        <div className="content-label">SFTP Host</div>

                                        <div className="content-info content-info-wrapper">
                                            <span>  {credentials.sftp_host}</span>

                                            <div className="text-center mt-2 action-btn-copy">

                                                <CopyToClipboard
                                                    text={credentials.sftp_host}
                                                    onCopy={() => this.setState({sftp_host: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.sftp_host ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.sftp_host ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.sftp_host ? null : 'Copy'}/>
                                                            :
                                                            this.state.sftp_host ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.sftp_host ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.sftp_host ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-6 col-sm-6">
                                        <div className="content-label">SFTP Username</div>
                                        <div className="content-info content-info-wrapper">
                                            <span>{credentials.sftp_username}</span>
                                            <div className="text-center mt-2 action-btn-copy">
                                                <CopyToClipboard
                                                    text={credentials.sftp_username}
                                                    onCopy={() => this.setState({sftp_username: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.sftp_username ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.sftp_username ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.sftp_username ? null : 'Copy'}/>
                                                            :
                                                            this.state.sftp_username ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.sftp_username ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.sftp_username ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-6 col-sm-6">
                                        <div className="content-label">SFTP Password</div>
                                        <div className="content-info content-info-wrapper">
                                            <span>{credentials.sftp_password}</span>
                                            <div className="text-center mt-2 action-btn-copy">
                                                <CopyToClipboard text={credentials.sftp_password}
                                                                 onCopy={() => this.setState({sftp_password: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.sftp_password ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.sftp_password ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.sftp_password ? null : 'Copy'}/>
                                                            :
                                                            this.state.sftp_password ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.sftp_password ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.sftp_password ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-6 col-sm-6">
                                        <div className="content-label">SFTP Port</div>
                                        <div className="content-info content-info-wrapper">
                                            <span>{credentials.sftp_port}</span>
                                            <div className="text-center mt-2 action-btn-copy">
                                                <CopyToClipboard text={credentials.sftp_port}
                                                                 onCopy={() => this.setState({sftp_port: true})}>
                                                    <button className="btn-sm">


                                                        {!this.state.sftp_port ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.sftp_port ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.sftp_port ? null : 'Copy'}/>
                                                            :
                                                            this.state.sftp_port ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.sftp_port ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.sftp_port ? 'Copied' : null}/>
                                                                </div>
                                                                : null}

                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-12 col-sm-12">
                                        <div className="content-label">SSH Terminal command</div>
                                        <div className="content-info content-info-wrapper">
                                        <span>
                                        ssh
                                        -p {credentials.sftp_port} {credentials.sftp_username}@{credentials.sftp_host}</span>
                                            <div className="text-center mt-2 action-btn-copy">

                                                <CopyToClipboard
                                                    text={"ssh -p" + " " + credentials.sftp_port + " " + credentials.sftp_username + "@" + credentials.sftp_host}
                                                    onCopy={() => this.setState({ssh_terminal_command: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.ssh_terminal_command ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.ssh_terminal_command ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.ssh_terminal_command ? null : 'Copy'}/>
                                                            :
                                                            this.state.ssh_terminal_command ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.ssh_terminal_command ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.ssh_terminal_command ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-12 d-flex justify-content-end align-items-center">
                                        <div className="btn-wrapper">
                                            <button className={`btn-theme btn-outline blue-outline`}
                                                    disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring' ? 'disabled' : null}
                                                    onClick={this.generateSftpPassword}>
                                                {getSiteStatus?.success?.event === "change_site_ssh_password" ? <>
                                                    <Loading/>Generate
                                                    New SFTP Password</> : 'Generate New SFTP Password'}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-35 send-to-developer align-items-end">
                                    <div className="col-xl-3 col-lg-12">
                                        <div className="content-label">Database Name</div>
                                        <div className="content-info content-info-wrapper">
                                            <span>{credentials.db_name}</span>
                                            <div className="text-center mt-2 action-btn-copy">
                                                <CopyToClipboard text={credentials.db_name}
                                                                 onCopy={() => this.setState({db_name: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.db_name ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.db_name ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.db_name ? null : 'Copy'}/>
                                                            :
                                                            this.state.db_name ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.db_name ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.db_name ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="content-label">Database Username</div>
                                        <div className="content-info content-info-wrapper">
                                            <span>{credentials.db_username}</span>
                                            <div className="text-center mt-2 action-btn-copy">
                                                <CopyToClipboard text={credentials.db_username}
                                                                 onCopy={() => this.setState({db_username: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.db_username ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.db_username ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.db_username ? null : 'Copy'}/>
                                                            :
                                                            this.state.db_username ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.db_username ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.db_username ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-6">
                                        <div className="content-label">Database Password</div>
                                        <div className="content-info content-info-wrapper">
                                            <span> {credentials.db_password}</span>
                                            <div className="text-center mt-2 action-btn-copy">
                                                <CopyToClipboard text={credentials.db_password}
                                                                 onCopy={() => this.setState({db_password: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.db_password ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.db_password ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.db_password ? null : 'Copy'}/>
                                                            :
                                                            this.state.db_password ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.db_password ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.db_password ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6 col-sm-6">
                                        {/* Copy all sftp info */}
                                        {/*<CopyToClipboard text={dbAllInfoCopy}*/}
                                        {/*                 onCopy={() => this.setState({copy_all_db_info: true})}>*/}
                                            <div className="btn-text-wrapper">
                                                <button
                                                    onClick={(e)=>this.handleDbCopy(e,dbAllInfoCopy)}
                                                    className={this.state.copy_all_db_info ? "btn-theme btn-text all-copied" : "btn-theme btn-text"}>
                                                    {!this.state.copy_all_db_info ?
                                                        "Copy All DB Info"
                                                        :
                                                        this.state.copy_all_db_info ?
                                                            "Copied"
                                                            : null}

                                                </button>
                                            </div>
                                        {/*</CopyToClipboard>*/}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-6 col-lg-12">
                                        <div className="content-label">phpMyAdmin URL</div>
                                        <div className="content-info content-info-wrapper">
                                            <a className="admin-url" href={`https://${credentials.php_myadmin_url}`}
                                               target="_blank">
                                                {credentials.php_myadmin_url}
                                            </a>
                                            <div className="text-center mt-2 action-btn-copy">
                                                <CopyToClipboard text={credentials.php_myadmin_url}
                                                                 onCopy={() => this.setState({php_myadmin_url: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.php_myadmin_url ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.php_myadmin_url ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.php_myadmin_url ? null : 'Copy'}/>
                                                            :
                                                            this.state.php_myadmin_url ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.php_myadmin_url ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.php_myadmin_url ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="col-xl-6 col-lg-12 col-md-12 btn-wrapper inline-buttons d-flex justify-content-between align-items-center flex-wrap wrap-1440">

                                        {site_status !== `pending` && (
                                            <div>
                                                <a href={`https://${credentials.php_myadmin_url}`} target="_blank"
                                                   className="open-admin">Open phpMyAdmin <span
                                                    className="icon-share"/></a>
                                            </div>
                                        )}

                                        <button className="btn-theme btn-outline blue-outline"
                                                data-toggle="modal" data-target="#new-database"
                                                disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring' ? 'disabled' : null}
                                                onClick={this.generateDatabasePassword}>
                                            {getSiteStatus?.success?.event === "change_site_db_password" ? <><Loading/>Generate
                                                New Database Password</> : 'Generate New Database Password'}</button>
                                    </div>
                                </div>
                                {/* <div className="send-to-developer d-flex">
                            <p className="col-md-8">With Kepler - Send you can easily send the correct details to you developer. Click on Share details and enter your developers email. They will get full access to both you server and WP install</p>
                            <button className="btn-theme btn-outline blue-outline">Send to Developer</button>
                        </div> */}
                            </div>
                        }
                    </>
                }
            </>
        )
    }

}

function mapStateToProps(state) {
    let {
        getSite
        ,
        getCredentials
        ,
        SftpList
        ,
        siteDashboard
        ,
        createCredentials
        ,
        getSiteStatus
    }

        = state;

    let {params, fields} = SftpList.data;
    let {success} = SftpList;
    let {site_status} = siteDashboard.data;
    let SftpLists =
        success &&
        success.data &&
        success.data.data.data;
    let dataCenterName = getSite && getSite.success && getSite.success.data_center_name

    let credentials =
        getCredentials &&
        getCredentials.success;

    return {
        SftpLists,
        getSite,
        getCredentials,
        params,
        fields,
        credentials,
        siteDashboard,
        site_status,
        createCredentials,
        getSiteStatus,
        dataCenterName
    };
}

export default connect(mapStateToProps)(SftpList);