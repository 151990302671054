import React from 'react';
import store from '../../Store';
import getHotFixs from "../../ajax/actions/hotfix/hotfix_get";

class HotfixWorker extends React.Component {


    componentDidMount() {
        store.dispatch(getHotFixs());
    }

    render() {
        return null
    }
}


export default HotfixWorker;