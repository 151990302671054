export function migrationTrackingFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'MIGRATION-TRACKING-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onMigrationTrackingFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-MIGRATION-TRACKING-FORM-SUBMIT'})
    }
}

export function onMigrationTrackingFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-MIGRATION-TRACKING-FORM-CHANGE', name, value});
    }
}

export function onMigrationTrackingFormModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-MIGRATION-TRACKING-FORM-MODE-CHANGE', mode});
    }
}

export function cancelMigrationTrackingFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-MIGRATION-TRACKING-FORM-DISPATCH'});
    }
}

export function setDefaultMigrationTrackingForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-MIGRATION-TRACKING-FORM'});
    }
}

export function setMigrationTrackingDataForUpdate() {
    return function (dispatch, getState) {
        let {getMigrationTracking} = getState();
        let migrationDetail = getMigrationTracking && getMigrationTracking.success && getMigrationTracking.success.data&& getMigrationTracking.success.data.data;
        dispatch({type: 'SET-MIGRATION-TRACKING-DATA-FOR-UPDATE', migrationDetail});
    }
}