import React from 'react'
import TextField from '../../../components/shared/form/TextField'
import PasswordField from '../../../components/shared/form/PasswordField'
import store from '../../../Store';
import {connect} from 'react-redux'
import {
    onUserFormSubmit,
    onUserFormChange, onUserRoleFormChange,
    selectCountry, onUserOrganizationAssign
} from './UserForm-Actions'
import UserFormWorker from "./UserFormWorker";
import {endOfURL} from "../../../helpers/UrlHelper";
import Page from "../../../components/shared/pages/Page";
import Loading from "../../../components/shared/loaders/Loading";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import {Link} from "react-router-dom";
import {ucFirst} from "../../../helpers/StringHelper";
import getOrganizations from "../../../ajax/actions/organization/organizations_get";
import NewSearchableSelect from "../../../components/shared/form/NewSearchableSelect";
import SelectField from "../../../components/shared/form/SelectField";
import OrganizationSideMenu from '../../organization/OrganizationSideMenu';
import CustomReactSelect from "../../../components/shared/form/CustomReactSelectWithErrorHandle";


class UserForm extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu('organization'));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    onChange = (e) => {
        store.dispatch(onUserFormChange(e));
    }

    handleRole = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.slug;

            if (selectedOption.slug === 'organization-admin') {
                store.dispatch(getOrganizations());
            }
            if (value) {
                store.dispatch(onUserRoleFormChange(value));
            }
        }
        store.dispatch(onUserRoleFormChange(value));
    }

    handleCountry = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.target.value;
        }
        store.dispatch(selectCountry(value));
    }

    handleOrganizationChange = (selectedOption) => {
        let value = [];
        if (selectedOption) {
            selectedOption.map(s => {
                let data = {"id": s.value, "name": s.label}
                if (s.value) {
                    value.push(data)
                }
            })
        }
        store.dispatch(onUserOrganizationAssign(value));
    }


    render() {
        let {
            first_name, middle_name, last_name, email, password, createUser, address, extra_address_line,
            post_code, city, country_id, errors, match, getCustomerRole, role,countriesList, org_id, submitted
        } = this.props;
        let allRoles = getCustomerRole?.success

        let clearable = true;
        let roleList = [];
        allRoles && allRoles.map(role => {
            roleList.push({value: role.id, label: role.name, slug: role.slug})
        });

        let countryList = [];
        countriesList && countriesList.map(c => {
            countryList.push({value: c.name, label: c.name, id: c.id})
        })

        let {processing} = createUser.processing;

        return (
            <>
                <Page title={endOfURL() === "create" ?
                    "Add User" :
                    "Update User"
                } processing={processing}>

                    <div className="page-content">
                                    
                    <div className="organizations-sidebar">
                        <OrganizationSideMenu />
                    </div>
                    <div className="content-section">
                    <div className="heading-block pr-60">
                        <div className="title-block">
                            <h2>{endOfURL() === 'create' ? `Add new User` : `Update User`} </h2>
                            <span><Link to={`/organizations/${org_id}/users`}>Users</Link></span>
                            {endOfURL() === 'update' ?
                                <span>{ucFirst(first_name)} {ucFirst(middle_name)} {ucFirst(last_name)}</span> : null}
                            <span>{endOfURL() === 'create' ? `Add new User` : `Update`}</span>
                        </div>
                    </div>

                    <UserFormWorker organization_id={match.params.org_id}/>
                    <div className="box-block">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="pills-add-user" role="tabpanel"
                                 aria-labelledby="pills-add-user-tab">
                                <div className="padded-block">
                                    {/*<div className="form-block content-center">*/}
                                    <form onSubmit={e => store.dispatch(onUserFormSubmit(e))}>
                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField required
                                                               value={first_name}
                                                               name="first_name"
                                                               icon="user"
                                                               onChange={this.onChange}
                                                               errors={errors && errors.first_name}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField
                                                        value={middle_name}
                                                        name="middle_name"
                                                        icon="user"
                                                        onChange={this.onChange}
                                                        errors={errors && errors.middle_name}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField required
                                                               value={last_name}
                                                               name="last_name"
                                                               icon="user"
                                                               onChange={this.onChange}
                                                               errors={errors && errors.last_name}/>
                                                </div>
                                            </div>
                                        </div>

                                        <hr/>

                                        <div className="form-row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <TextField required
                                                               value={address}
                                                               name="address"
                                                               onChange={this.onChange}
                                                               errors={errors && errors.address}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <TextField required
                                                               value={extra_address_line}
                                                               name="extra_address_line"
                                                               onChange={this.onChange}
                                                               errors={errors && errors.extra_address_line}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField required
                                                               value={post_code}
                                                               name="post_code"
                                                               icon="user"
                                                               label="Postal Code"
                                                               onChange={this.onChange}
                                                               errors={errors && errors.post_code}/>
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField required
                                                               value={city}
                                                               name="city"
                                                               icon="user"
                                                               onChange={this.onChange}
                                                               errors={errors && errors.city}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    {/*<NewSearchableSelect*/}
                                                    {/*    name="country"*/}
                                                    {/*    isClearable={clearable}*/}
                                                    {/*    onChange={this.handleCountry}*/}
                                                    {/*    placeholder={endOfURL() === 'update' ? country : "Select..."}*/}
                                                    {/*    options={countryList}*/}
                                                    {/*    errors={errors && errors.country}*/}
                                                    {/*    Searchable*/}
                                                    {/*/>*/}
                                                    <SelectField name="country_id"
                                                                 options={countryList}
                                                                 value={country_id}
                                                                 errors={errors && errors.country_id}
                                                                 onChange={this.handleCountry}
                                                                 _value="id"
                                                                 _label="value"/>
                                                </div>
                                            </div>
                                        </div>

                                        <hr/>

                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <TextField required
                                                               value={email}
                                                               name="email"
                                                               type="email"
                                                               icon="mail"
                                                               autoComplete="nope"
                                                               errors={errors && errors.email}
                                                               onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    {/*<label>Select Role</label>*/}
                                                    {/*<NewSearchableSelect name="role"
                                                                         placeholder={role || "Select..."}
                                                                         isClearable={clearable}
                                                                         onChange={this.handleRole}
                                                                         options={roleList}
                                                                         label="Select Role"
                                                                         autoComplete="nope"
                                                                         errors={errors && errors.role}
                                                                         Searchable
                                                    />*/}
                                                    <label className="mt-1">Select Role</label>
                                                    <CustomReactSelect name="role"
                                                                       placeholder={"Select Role..."}
                                                                       value={ roleList.filter(option => option.slug === role) ||roleList.value}
                                                                       isClearable={clearable}
                                                                       onChange={this.handleRole}
                                                                       options={roleList}
                                                                       label="Select Role"
                                                                       autoComplete="nope"
                                                                       isValid={errors && errors.role}
                                                                       submitted={submitted}
                                                                       Searchable
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <PasswordField required
                                                                   value={password}
                                                                   name="password"
                                                                   icon="lock"
                                                                   defaultValue={null}
                                                                   autoComplete="new-password"
                                                                   onChange={this.onChange}
                                                                   errors={errors && errors.password}/>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <PasswordField required
                                                                   name="password_confirmation"
                                                                   icon="lock"
                                                                   defaultValue={null}
                                                                   onChange={this.onChange}
                                                                   autoComplete="new-password"
                                                                   errors={errors && errors.password}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="button-wrapper text-right mt-20 d-flex justify-content-end">
                                            <button type="submit"
                                                    className="btn-theme btn-dark-blue">
                                                {
                                                    processing ? <Loading/> : `Submit`
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="tab-pane fade" id="pills-edit-user" role="tabpanel"
                                     aria-labelledby="pills-edit-user-tab">
                                </div>

                            </div>
                        </div>

                    </div>
                    </div>
                </div>
                </Page>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {organizationUserForm, getOrganizationUser, createUser, updateUser, getCountries, getRoles, getOrganizations, getCustomerRole, getOrganization} = state;
    let {payload, mode, submitted} = organizationUserForm.data;
    let countriesList = getCountries?.success?.data.data

    let {success} = getRoles;

    let allRoles =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data;

    let allOrganizations =
        getOrganizations &&
        getOrganizations.success &&
        getOrganizations.success.data &&
        getOrganizations.success.data.data &&
        getOrganizations.success.data.data.data;

    let {errors, errorsPresent} = organizationUserForm.data;

    let org_id = getOrganization.success?.id

    let {
        first_name, middle_name, last_name, email, password, address, extra_address_line,
        post_code, city, country_id, role, allowed_organizations
    } = payload;

    return {
        errors, errorsPresent, allRoles, first_name, getCustomerRole,
        middle_name, last_name, email, password, createUser, updateUser, getOrganizationUser, mode,
        organizationUserForm, address, extra_address_line, post_code, city, country_id, role, getRoles,
        getOrganizations, allOrganizations, allowed_organizations, countriesList, org_id, submitted
    };
}

export default connect(mapStateToProps)(UserForm);
