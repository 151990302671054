import store from '../../../Store';
import { toggleURLQuery } from '../../../helpers/UrlHelper'
import getExpiredBackup from "../../../ajax/actions/backup-infrastructure/expired_backup_get";

export function toggleExpiredBackupListFilter(e, history)
{
  return function (dispatch) {
    let { name, value } = e.target;
    let toggle = true;
    if ( toggle ) toggleURLQuery( name, value , history)
    dispatch({type:'TOGGLE-EXPIRED-BACKUP-LIST-FILTER', name, value});
    store.dispatch(getExpiredBackup());
  }
}