import React from 'react';
import {unslufigy} from "../../../helpers/StringHelper";
import {connect} from "react-redux";
import store from "../../../Store";
import {
    togglePriceListFilter,
} from "./Price-Action";
import {Link} from "react-router-dom";
import Button from "../../../components/shared/buttons/Button";

class PriceHeader extends React.Component {
    toggleFilter = (e) => {
        store.dispatch(togglePriceListFilter(e, this.props.history));
    }

    render() {
        let {title} = this.props;
        return (
            <div className="heading-block pr-60">
                <div className="title-block Page animated fadeIn faster">
                    <h2>{unslufigy(title)}</h2>
                </div>
                <div className="button-wrapper">
                    <Link to={`/price/create`}>
                        <Button className="btn-theme btn-dark-blue"
                                title={`Add ${unslufigy(title)}`}
                                text={`Add ${unslufigy(title)}`}/>
                    </Link>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    let {priceList, getPrices} = state;
    let {params} = priceList.data;

    return {getPrices, params};
}

export default connect(mapStateToProps)(PriceHeader);