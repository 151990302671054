import axios from 'axios';
import {apiBaseUrl, endOfURL} from '../../../helpers/UrlHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
    return {type: 'GET_DATA_CENTERS_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_DATA_CENTERS_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_DATA_CENTERS_PROCESSING', processing};
}

function getDataCenters() {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_error(null));
        let {params} = getState().dataCenterList.data;
        if (endOfURL() === "data-center") {
            axios({
                url: apiBaseUrl(`admin/datacenters`),
                method: "get",
                dataType: 'json',
                params,
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`)
                }
            }).then(function (res) {
                dispatch(_processing(false));
                dispatch(_success(res));

            }).catch(function (error) {
                handleError(error);
                dispatch(_error(error));
                dispatch(_processing(false));
            });
        } else {
            axios({
                url: apiBaseUrl(`admin/datacenters`),
                method: "get",
                dataType: 'json',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem(`Bearer`)
                }
            }).then(function (res) {
                dispatch(_processing(false));
                dispatch(_success(res));

            }).catch(function (error) {
                handleError(error);
                dispatch(_error(error));
                dispatch(_processing(false));
            });
        }
    }
}

export default getDataCenters;
