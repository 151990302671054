const DefaultPublicPathFormState = {
    payload: {
        public_path: ``,
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: ``,
    message: ``,
    site_id: ``,
};

export const PublicPathFormState = {data: DefaultPublicPathFormState};