import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {
    setDefaultDomainForm,
    setDomainDataForUpdate
} from "./DomainForm-Action";
import createDomain from "../../../../ajax/actions/domain/domain_post";
import createBulkDomain from "../../../../ajax/actions/domain/domain_bulk_post";
import {toggleDomainFormModal} from "../list/Domain-Action";
import getSiteDomain from "../../../../ajax/actions/domain/site_domain_get";

class DomainFormWorker extends React.Component {

    constructor(props) {
        super(props);

        store.dispatch(setDefaultDomainForm())
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode,payload} = this.props;

        if (this.props.getDomain !== prev.getDomain) {
            let {success} = this.props.getDomain;
            if (success) {
                store.dispatch(setDomainDataForUpdate());
            }
        }

        if (this.props.createDomain !== prev.createDomain) {
            let {success} = this.props.createDomain;

            if (success) {
                store.dispatch(getSiteDomain())
                store.dispatch(toggleDomainFormModal());
            }
        }

        if (this.props.createBulkDomain !== prev.createBulkDomain) {
            let {success} = this.props.createBulkDomain;

            if (success) {
                store.dispatch(getSiteDomain())
                store.dispatch(toggleDomainFormModal());
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                if (mode === "bulk" && payload.name.length) {
                    // console.log(` <|  |> `,"bikash" )
                    store.dispatch(createBulkDomain())
                } else {
                    store.dispatch(createDomain())
                }
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {domainForm, getDomain, createDomain, createBulkDomain} = state;
    let {dispatchAPI, mode, payload} = domainForm.data;
    return {domainForm, dispatchAPI, getDomain, mode, payload, createDomain, createBulkDomain};
}

export default withRouter(connect(mapStateToProps)(DomainFormWorker));