import React from 'react';
import store from '../../../../Store';
import {setSftpID} from "./Sftp-Action";
import {withRouter} from "react-router";
import {connect} from "react-redux";

class SftpWorker extends React.Component {

  constructor(props) {
    super(props);
    store.dispatch(setSftpID(props.match.params.id))
  }

  render() {
    return null
  }
}
  function mapStateToProps(state) {
  let { organizationDashboard } = state;
  let { organization_id } = organizationDashboard.data;
  return { organizationDashboard, organization_id };
}

export default withRouter(connect(mapStateToProps)(SftpWorker));