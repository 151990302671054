import React from 'react'
import {connect} from 'react-redux';
import ActivityWorker from './GlobalActivityWorker';
import {toggleGlobalActivityListFilter} from './GlobalActivityList-Actions'
import ActivityHeading from "./GlobalActivityHeading";
import store from '../../../Store';
import TableHead from "../../../components/shared/table/TableHead";
import Page from "../../../components/shared/pages/Page";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import {fullDate} from "../../../helpers/DateTimeHelper";
import Loading from "../../../components/shared/loaders/Loading";
import {ActivityLogStatus} from '../../../components/shared/status/Status';
import getGlobalActivityLogs from "../../../ajax/actions/global-activity-log/global_activity_logs_get";
import getGlobalActivity from "../../../ajax/reducers/gloal-activity-log/global-activity-logs-get";

class GlobalActivity extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    toggleSort = (field) => {
        store.dispatch(toggleGlobalActivityListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    render() {
        let { list, pages, params, fields, modal,getGlobalActivity} = this.props;

        let {processing, error} = getGlobalActivity;
        if (error) {
            this.props.history.push('/');
        }

        return (
            <Page title="Activity Log">
                <div className="page-content">
                    <div className="content-section">
                        <ActivityHeading organization_id={this.props.match.params.id}/>
                        <div className="table-block table-responsive pr-60">
                            <table className="table table-striped mb-60 table-with-action-dropdown">
                                <TableHead fields={fields}
                                           params={params}
                                           noActions={true}
                                           onClick={this.toggleSort}/>
                                <tbody>
                                {processing ? <td colSpan={5} className="text-center"><Loading/></td> :

                                    list && list.length ? list.map((l, key) => {
                                            return (
                                                <tr key={key}>
                                                    <td>{fullDate(l.created_at)}</td>
                                                    <td>
                                                        {
                                                            l.user_name && true ?
                                                                l.user_name : "Kepler Hosting"
                                                        }
                                                    </td>
                                                    <td>{l.site_name || "-"}</td>
                                                    {l.description ?
                                                        <td className="action-td">
                                                            <div className="collapse-link">
                                                                {l.payment ?
                                                                    <>   {l.event} {"("}{l.payment.amount} {l.payment.currency}{")"}
                                                                    </> :
                                                                    l.event}
                                                            </div>
                                                            <div className="collapse-content">
                                                                {l.description}
                                                            </div>
                                                        </td> :
                                                        <td>{l.payment ?
                                                            <>   {l.event} {"("}{l.payment.amount} {l.payment.currency}{")"}
                                                            </> :
                                                            l.event}
                                                        </td>
                                                    }

                                                    {/*{l.payment ?*/}
                                                    {/*<td>{l.event} {"("}{l.payment.amount} {l.payment.currency}{")"}</td>:*/}
                                                    {/*<td>{l.event}</td>}*/}


                                                    <td className="last-item">
                                                        <ActivityLogStatus status={l.status}/>
                                                        {/*<i className={`icon-${l.status === "failed" ? "close-circle" : 'checkmark'} text-${l.status === "failed" ? "danger" : l.status === "pending" ? "primary" : "success"}`}/>*/}

                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                        <tr>
                                            <td colSpan="5" className="text-center">No Activity Logs
                                                Available
                                            </td>
                                        </tr>
                                }
                                    </tbody>
                                    </table>
                                    </div>
                                {list && list.length ? (
                                    <div className="fixed-footer pr-60" id="inside-organization-pagination">
                                    <PaginationButtons pages={pages}
                                    processing={processing}
                                    fetchAction={toggleGlobalActivityListFilter}/>
                                    </div>
                                    ) : null}
                                    </div>

                                    </div>
                                    <ActivityWorker/>
                                    </Page>
                                    )
                                }
}

function mapStateToProps(state) {
    let { globalActivityList, getGlobalActivity} = state;

    let {params, fields, modal} = globalActivityList.data;

    let {success} = getGlobalActivity;

    let list =
        success &&
        success.data &&
        success.data.data.data;

    let current_page = success && success.data && success.data.data.current_page;
    let last_page = success && success.data && success.data.data.last_page;
    let total = success && success.data && success.data.data.total;
    let from = success && success.data && success.data.data.from;
    let pages = {current_page, last_page, total, from};

    return {getGlobalActivity, list, globalActivityList, pages, params, fields, modal};
}

export default connect(mapStateToProps)(GlobalActivity);