import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken, logOut} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'GET_BUG_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_BUG_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_BUG_PROCESSING', processing};
}

function getBug(id) {
    return (dispatch) => {
        dispatch(_processing(true));
        dispatch(_error(null));

        const request = axios({

            url: apiBaseUrl(`admin/internal-bug/${id}`),
            method: "get",
            dataType: 'json',
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                dispatch(_success(res.data));
                dispatch(_processing(false));

            }).catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                handleError(error)

            });

        return {
            type: 'GET_BUG_SUCCESS',
            payload: request
        }
    }
}


export default getBug;