import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleSuccess} from '../../../helpers/ErrorHelper'
import {cancelBugFormDispatch, bugFormServerValidationErrors, setDefaultBugForm}
    from '../../../pages/support/form/BugForm-Action'
import store from '../../../Store';
import {scrollToError} from '../../../helpers/ScrollHelper'

function _success(success) {
    return {type: 'POST_BUG_SUCCESS', success};
}

function _error(error) {
    return {type: 'POST_BUG_ERROR', error};
}

function _processing(processing) {
    return {type: 'POST_BUG_PROCESSING', processing};
}

function createBug() {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {bugForm} = getState();
        let {payload} = bugForm.data
        if (!(payload.tags).length) {
            delete payload.tags
        }
        delete payload.status
        delete payload.imageLinks
        let data = (payload);
        const formData = new FormData();
        Object.keys(data).map(item => {
            if (item !== `images` && item !== 'tags') {
                formData.append(item, data[item])
            } else if (item === "images") {
                (data.images).map(i => {
                    formData.append(`images[]`, i)
                })
            } else if ((data.tags).length && item === "tags") {
                (data.tags).map(i => {
                    formData.append(`tags[]`, i)
                })
            }

        })

        axios({
            url: apiBaseUrl(`admin/internal-bug`),
            method: "post",
            data: formData,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
                'Content-Type': 'multipart/form-data',
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res)
                store.dispatch(cancelBugFormDispatch())
                store.dispatch(setDefaultBugForm())
            })
            .catch(function (error) {
                dispatch(_error(error));
                dispatch(_processing(false));
                // store.dispatch(bugError(error.response.data.message))
                store.dispatch(cancelBugFormDispatch())

                if (error.response && error.response.status === 422) {
                    store.dispatch(bugFormServerValidationErrors(error.response.data.errors))
                    scrollToError();
                }
            });
    }
}


export default createBug;