import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handle422error, handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import { scrollToError } from '../../../helpers/ScrollHelper'
import store from "../../../Store";
import {
  cancelSslCertificateFormDispatch, onSslCertificateFormModeChange,
  setDefaultSslCertificateForm,
  SSLCertificateFormServerValidationErrors
} from '../../../pages/site/ssl/sslCertificate/form/SSLForm-Action'
import getSslCertificates from "./ssl_get";
import {toggleSSLFormModal} from "../../../pages/site/ssl/list/SSL-Action";


function _success(success)
{
  return { type: 'PUT_SSL_CERTIFICATES_SUCCESS', success };
}
function _error(error)
{
  return { type: 'PUT_SSL_CERTIFICATES_ERROR', error };
}
function _processing(processing)
{
  return { type: 'PUT_SSL_CERTIFICATES_PROCESSING', processing };
}

function updateSsl(site_id,id)
{
  return ( dispatch, getState ) =>
  {
    dispatch(_processing(true));
    dispatch(_success(null));
    dispatch(_error(null));
    let {SSLForm,organizationDashboard} = getState();
    let {payload} = SSLForm.data
    payload.key = SSLForm &&  SSLForm.data.payload.key_content;
    delete payload.key_content;
    let org_id = organizationDashboard.data.organization_id;

    axios({
      url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/certificates/${id}`),
      method: "put",
      dataType: 'json',
      data: SSLForm.data.payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    })
      .then(function(res){
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess( res )
        store.dispatch(getSslCertificates(site_id));
        store.dispatch(setDefaultSslCertificateForm());
        store.dispatch(toggleSSLFormModal())
      })
      .catch(function(error){
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError( error )
        store.dispatch(cancelSslCertificateFormDispatch())

        if(error.response && error.response.status === 422 ) {
          handle422error(error.response.data.errors)
          // validationError(error.response.data.message,error.response.status)
          store.dispatch(SSLCertificateFormServerValidationErrors(error.response.data.errors))
          scrollToError();
        }
      });

  }
}


export default updateSsl;