import React from 'react';
import store from '../../../../Store';
import {connect} from 'react-redux';
import {withRouter} from "react-router";
import getRedirects from "../../../../ajax/actions/redirects/redirects_get";
import getSiteDomainNoPagination from "../../../../ajax/actions/domain/site_domain_nopagination_get";

class RedirectsWorker extends React.Component {
    componentDidMount() {
        store.dispatch(getRedirects(this.props.match.params.id));
        store.dispatch(getSiteDomainNoPagination(this.props.match.params.id,this.props.organizationDashboard.organization_id));
    }

    componentDidUpdate(prev) {
        if (this.props.getRedirects !== prev.getRedirects) {
            let {success} = this.props.getRedirects;
            if (success) {
                store.dispatch(getRedirects(this.props.match.params.id));
            }
        }
    }
    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getSite, getSites,organizationDashboard} = state;
    let { organization_id } = organizationDashboard.data;
    return {getSite, getSites,organizationDashboard,organization_id};
}

export default withRouter(connect(mapStateToProps)(RedirectsWorker));