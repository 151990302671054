import {fullDateStringNoTime} from "../../../helpers/DateTimeHelper";

const DefaultBackupStatusState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    per_page: 25,
    limit:10,
    page:   1,
    order:  'desc',
    sort_id: `id`,
    q: ``,
    backup_date: fullDateStringNoTime(new Date()),
  },
  site_id:``,
  backup_modal: {
    mode: '',
  },
  fields: [
    { field: `total_sites`, label: `Total Sites`, sort: false },
    { field: `total_backup_today`, label: `Total Backup Today`, sort: false },
    { field: `total_full_backups`, label: `Total Full Backups`, sort: false },
    { field: `total_partial_backups`, label: `Total Partial Backups`, sort: false },
    { field: `total_zfs_based_sites`, label: `Total zfs Based Sites`, sort: false },
    { field: `total_normal_based_sites`, label: `Total Normal Based Sites`, sort: false },
  ],
  table_fields: [
    { field: `id`, label: `Site Id`, sort: false },
    { field: `organization_id`, label: `Organization Id`, sort: false },
    { field: `name`, label: `Site Name`, sort: false },
    { field: `backup_attempts`, label: `Backup Attempts`, sort: false },
    { field: `container_name`, label: `Container Name`, sort: false },
  ]
}

export const BackupStatusState = {data:DefaultBackupStatusState}