import React from 'react';
import Active from '../../../assets/images/active.svg';
import Inactive from '../../../assets/images/inactive.svg';
import Pending from '../../../assets/images/pending.svg';
import {ucFirst} from "../../../helpers/StringHelper";

export function Availability(props)
{
    let { availability } = props;
    return (
        <>
            {
                availability === 0 ? 
                <div className="available">
                        <p>Not Available</p>
                        <img alt="status" src={Inactive}/>
                </div>

                : 

                <div className="available">
                        <p>Available</p>
                        <img alt="status" src={Active}/>
                </div>
            }
        </>
    )
}

export function Paid(props)
{
    let { status } = props;
    return (
        <>
            {
                status === "pending" ? 
                <div className="paid">
                        <p>Pending</p>
                        <img alt="status" src={Pending}/>
                </div>

                :  status === "paid" ?

                <div className="paid">
                        <p>Paid</p>
                        <img alt="status" src={Active}/>
                </div>

                : 
                
                <div className="paid">
                        <p>Not paid</p>
                        <img alt="status" src={Inactive}/>
                </div>
            }
        </>
    )
}

export function ActivityLogStatus(props)
{
    let { status } = props;
    return (
        <>
            {
                status === "failed" || status === "cancelled" || status === "rejected" || status === "inactive" ?
                <div className="activity-status">
                        <p>{ucFirst(status)}</p>
                        <img alt="status" src={Inactive}/>
                </div>
                :
                    status === "pending" ?
                        <div className="activity-status">
                            <p>Pending</p>
                            <img alt="status" src={Pending}/>
                        </div>
                        :
                        status === "migrating" ?
                            <div className="activity-status">
                                <p>Migrating</p>
                                <img alt="status" src={Pending}/>
                            </div>
                            :
                            status === "completed" ?
                                <div className="activity-status">
                                    <p>Completed</p>
                                    <img alt="status" src={Active}/>
                                </div>
                                :
                                status === "active" ?
                                    <div className="activity-status">
                                        <p>Active</p>
                                        <img alt="status" src={Active}/>
                                    </div> :
                                status === "reassigned" ?
                                    <div className="activity-status">
                                        <p>Reassigned</p>
                                        <img alt="status" src={Pending}/>
                                    </div>
                                :
                <div className="activity-status">
                        <p>Success</p>
                        <img alt="status" src={Active}/>
                </div>
            }
        </>
    )
}

export function Status(props)
{
    let { status } = props;
    return (
        <>
            {
                status === 0 ? 
                <div className="status">
                        <p>Inactive</p>
                        <img alt="status" src={Inactive}/>
                </div>

                : 

                <div className="status">
                        <p>Active</p>
                        <img alt="status" src={Active}/>
                </div>
            }
        </>
    )
}
export default Status;