import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultServerModuleFormState = {
  payload:{
    name:``,
    data_center_id:'',
    description:``,
    public_ip_address:``,
    network_interface:'',
    c_core:'',
    status:``,
    storage_type:``,
    max_sites:``
  },

  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,
  mode:endOfURL(),
  message: ``
};

export const ServerModuleFormState = { data: DefaultServerModuleFormState };