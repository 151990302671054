const DefaultDomainState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit:25,
    per_page: 25,
    page:   1,
    order:  'desc',
    sort_id: `id`,
    q: ``,
  },
  site_id:``,
  domain_id:``,
  modal:{
    mode:``,
  },
  fields: [
    { field: `id`, label: `ID`, sort: true },
    { field: `site_id`, label: `Site ID`, sort: true },
    { field: `name`, label: `Name`, sort: true },
    { field: `has_www`, label: `Has www`, sort: true },
    { field: `ssl_type`, label: `SSL Type`, sort: true },
    { field: `ssl_flag`, label: `SSL Flag`, sort: true },
    { field: `is_primary`, label: `Is Primary`, sort: true },
  ]
}

export const DomainState = {data:DefaultDomainState}