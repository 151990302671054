import React from 'react';
import {names, ucFirst, unslufigy} from '../../../helpers/StringHelper'
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Tooltip from "../tooltip/Tooltip";
import {onSiteFormPasswordChange} from "../../../pages/site/form/SiteForm-Action"
import store from "../../../Store"
import {generatePasswords} from "../../../helpers/passwordGenerateHelper"
import Reset from '../../../assets/images/redo-alt-light.svg'

class WordPressPasswordField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            copied: false,
        };

    }
    
    onPasswordChange = (e) => {
        e.preventDefault();
        store.dispatch(onSiteFormPasswordChange(e.target.value));
    }

    generatePwd() {
        this.setState({
            copied: false
        });
        let password = generatePasswords()
        store.dispatch(onSiteFormPasswordChange(password));
    }

    placeholder() {
        let {placeholder, name, label} = this.props;
        if (placeholder) return placeholder;
        if (label) return label;
        else {
            if (name) {
                let _label = unslufigy(name);
                if (name.includes(`_id`)) _label = ucFirst(name.split(`_id`)[0]);
                return _label;
            }
        }
    }

    label() {
        let {name, label} = this.props;
        if (label) return (
            <label>
                {label}
            </label>
        );
        else {
            if (name) {
                let _label = unslufigy(name);
                if (name.includes(`_id`)) _label = name.split(`_id`)[0];
                return (
                    <label>
                        {_label}
                    </label>
                )
            }
        }
    }

    render() {
        let {
            name, size, value, onChange, id, noLabel, disabled, errors, required,
        } = this.props;

        return (
            <>
                <div className={names(`form-group`, errors && errors.length && `has-error`, required && `required`)}>

                    {!noLabel ? <>{this.label()}</> : null}

                    <div className="wp-password-input-wrapper">
                        <input type="text"
                               className="form-control"
                               size={size || `default`}
                               name={name}
                               disabled={disabled}
                               id={id || name}
                               value={value}
                               placeholder={this.placeholder()}
                               onChange={onChange}
                        />

                        <a className="password-reset" href="/#" onClick={ev => {ev.preventDefault();}}>
                            <button
                                className="bg-transparent  border-0"
                                type="button"
                                onClick={() => {
                                    this.generatePwd()
                                }}
                            ><span> <img id="reset" src={Reset} alt=""/></span></button>
                        </a>
                        <a className="password-copy text-center action-btn-copy" href="/#" onClick={ev => {ev.preventDefault();}}>
                            <CopyToClipboard
                                text={value}
                                onCopy={() => this.setState({copied: true})}>
                                <button className="bg-transparent border-0" type="reset">
                                    {!this.state.copied ?
                                        <Tooltip title={<span
                                            className={`${this.state.copied ? `text-danger` : null} icon-compare`}/>}
                                                 tooltip={this.state.copied ? null : 'Copy'}/>
                                        :
                                        this.state.copied ?
                                            <div style={{"color": "blue"}}>
                                                <Tooltip title={<span
                                                    className={`icon-check `}/>}
                                                         tooltip={this.state.copied ? 'Copied' : null}/>
                                            </div>
                                            // <div style={{"color": "blue"}}>
                                            //     <span className="icon-checkmark"/>
                                            // </div>
                                            : null}
                                </button>
                            </CopyToClipboard></a>
                    </div>
                </div>
            </>

        )
    }
}

let {string, number, oneOfType} = PropTypes;
WordPressPasswordField.propTypes = {value: oneOfType([string, number])};

export default WordPressPasswordField;