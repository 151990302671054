import store from '../../../Store';
import {toggleURLQuery} from "../../../helpers/UrlHelper";
import getDiscounts from "../../../ajax/actions/discount-slab/discount_gets"

export function toggleDiscountListFilter(e, history) {
    return function (dispatch) {
        let {name, value} = e.target;
        toggleURLQuery(name, value, history)
        dispatch({type: 'TOGGLE-DISCOUNT-LIST-FILTER', name, value, history});

        if (name === 'limit') {
            store.dispatch(getDiscounts());
            toggleURLQuery('page', '', history)
        } else {
            store.dispatch(getDiscounts());
        }
    }
}

export function setDefaultDiscountFiltersToURL(history) {
    return (dispatch, getState) => {
        let {discountList} = getState()
        let {data} = discountList
        let {params} = data;
        Object.keys(params).map(key => {
            if (params[key] !== `` && params[key] !== null && params[key] !== undefined) {
                toggleURLQuery(key, params[key], history)
            }
        })
    }
}