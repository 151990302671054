import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultBugState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    order:  'desc',
    sort_by: ``,
    q: ``,
    tag:``,
    urgency:'urgent',
    status: ""
  },
  bug: {
    mode:''
  },

  fields: [
    // { field: `created_at`, label: `Date`, sort: true },
    { field: `description`, label: `Description`, sort: false },
    { field: `display_name`, label: `Site Name`, sort: false },
    { field: `name`, label: `Organization Name`, sort: false },
    { field: `site_name`, label: `Tag`, sort: false },//display_name
    { field: `status`, label: `Status`, sort: true },
    { field: `urgency`, label: `Urgent`, sort: true },
    { field: `priority`, label: `Priority`, sort: true },
    { field: `event`, label: `Action`, sort: false },

  ]
}

export const BugState = {data:DefaultBugState}