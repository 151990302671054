import React from 'react';
import store from '../../../Store';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {unslufigy} from "../../../helpers/StringHelper";
import {onDateFilter, toggleBackupStatusListFilter} from "./Backup-Status-Action";
import DatePicker from "react-datepicker";
import {fullDateStringNoTime} from "../../../helpers/DateTimeHelper";
import Reset from "../../../assets/images/redo-alt-light.svg";

class BackupStatusListFilters extends React.Component {

    toggleFilter = (e) => {
        store.dispatch(toggleBackupStatusListFilter(e, this.props.history));
    }
    onChangeDate = (name, date) => {
        store.dispatch(onDateFilter(name, fullDateStringNoTime(date), this.props.history));
    };

    render() {
        let {title, params} = this.props;
        let {backup_date} = params;
        const startDate = new Date();
        let changedDate = backup_date && new Date(backup_date)
        return (
            <div className="heading-block pr-60 d-flex">
                <div className="title-block">
                    <h2>{unslufigy(title)}</h2>
                </div>
                <div className="date-picker d-flex" >
                    {/*<label>Choose Date</label><br/>*/}
                    <DatePicker className="form-control" dateFormat="yyyy-MM-dd"
                                selected={ changedDate ?changedDate :startDate}
                                onChange={date => this.onChangeDate("backup_date", date)}/>

                    <button
                        className="bg-transparent  border-0 pt-3"
                        type="button"
                        onClick={() => {
                            this.onChangeDate("backup_date", startDate)
                        }}
                    ><span> <img id="reset" src={Reset} alt=""/></span></button>
                </div>
              {/*  <div className="search-bar">
                    <input type="text"
                           className="form-control"
                           name="q"
                           placeholder={`Search ${unslufigy(title)}`}
                           value={params.q}
                           onChange={this.toggleFilter}
                    />
                    <span className="icon-search"/>
                </div>*/}
               {/* <div className="button-wrapper">
                    <Link to={`/${title}/create`}>
                        <Button className="btn-theme btn-dark-blue"
                                title={`Add ${unslufigy(title)}`}
                                text={`Add ${unslufigy(title)}`}/>
                    </Link>
                </div>*/}
            </div>

        )
    }
}

function mapStateToProps(state) {
    let {getBackupStatus, backupStatusList} = state;
    let {success} = getBackupStatus;

    let list =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data;


    let {params} = backupStatusList.data;


    return {list, getBackupStatus, params};
}

export default withRouter(connect(mapStateToProps)(BackupStatusListFilters));