const DefaultLoadBalancerState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 25,
        page: 1,
        // sort_id: `id`,
        sort_order: `desc`,
        sort_by: `name`,
        q: ``,
        server_id: '',
    },
    fields: [
        {field: `name`, label: `Name`, sort: true},
        {field: `server_name`, label: `Server Name`, sort: true},
        {field: `status`, label: `Status`, sort: true},
        {field: `action`, label: `Action`, sort: false},
    ]
}

export const LoadBalancerState = {data: DefaultLoadBalancerState}