import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError} from "../../../helpers/ErrorHelper";

function _success(success) {
    return {type: 'GET_DISCOUNT_SUCCESS', success};
}

function _error(error) {
    return {type: 'GET_DISCOUNT_ERROR', error};
}

function _processing(processing) {
    return {type: 'GET_DISCOUNT_PROCESSING', processing};
}

function getDiscountSlab(id) {
    return (dispatch) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        axios({
            url: apiBaseUrl(`admin/discount_slabs/${id}`),
            method: "get",
            dataType: 'json',
            headers: {'Authorization': 'Bearer ' + loginToken()}
        })
            .then(function (res) {
                dispatch(_success(res.data.data));
                dispatch(_processing(false));
            }).catch(function (error) {
            handleError(error);
            dispatch(_error(error));
            dispatch(_processing(false));
        });


    }
}


export default getDiscountSlab;