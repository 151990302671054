import React from 'react';
import store from "../../../../Store";
import {togglePasswordProtectionFormModal} from "./FeatureBlock-Action";
import {connect} from "react-redux";
import Loading from "../../../../components/shared/loaders/Loading";
// import cacheSvg from "../../../../assets/images/cache.svg";
import passwordSvg from "../../../../assets/images/authpass.svg";
import disablePasswordProtection from "../../../../ajax/actions/kepler-tools/disable_password_protection_post"
import {withRouter} from "react-router";

class PasswordProtectionPrimaryView extends React.Component {
    disableAuthPath(){
        const payload = {
            "paths": []
        }
        // console.log(` <|  |> `,this.props )
        store.dispatch(disablePasswordProtection(payload,this.props.match.params.id));
    }
    render() {
        let {event, site_status, httpAuthPaths} = this.props
        return (
            <div className="col-xl-12 col-sm-12 feature-col">
                <div className="single-feature">
                    <div className="feature-information">
                        <img src={passwordSvg} alt="feature svg" className="feature-img"/>
                        <div className="feature-name-section">
                            <p className="site-tools-heading">Site Tools</p>
                            <h3>Password Protection</h3>
                        </div>
                    </div>
                    <p className="site-tools-description">The Kepler Hosting Password Protection Tool - This tool will add an HTTP Authentication to your website. This means that only people with the correct credentials will be able to access your site. A small login box will show before displaying your site. This tool is good to use if your site is under development.</p>
                    <div className="btn-wrapper">
                        {httpAuthPaths && httpAuthPaths.length === 0 ?
                            <button className="btn-theme btn-outline blue-outline" data-toggle="modal"
                                    data-target="#password-protection"
                                    onClick={() => store.dispatch(togglePasswordProtectionFormModal())}
                                    disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                            >
                                {event === "update_auth_paths" ? <>Enable <Loading/></> : "Enable"}
                            </button> :
                            <button className="btn-theme btn-outline blue-outline"
                                    type="button"
                                    onClick={() =>this.disableAuthPath()}
                                    disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                            >
                                {event === "update_auth_paths" ? <>Disable <Loading/></> : "Disable"}
                            </button>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let {getSite, getSiteStatus, siteDashboard} = state;
    let {site_status} = siteDashboard.data;
    let httpAuthPaths = getSite && getSite.success && getSite.success.httpAuthPaths

    let event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event

    return {event, getSite, getSiteStatus, site_status,httpAuthPaths };

}

export default withRouter(connect(mapStateToProps)(PasswordProtectionPrimaryView));
