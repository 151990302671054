import React from 'react';
import store from '../../Store';
import {toggleHotfixListFilter} from "./HotfixList-Actions";

class HotfixListFilters extends React.Component {
    componentDidMount() {
    }

    toggleFilter = (e) => {
        store.dispatch(toggleHotfixListFilter(e, this.props.history));
    }

    render() {
        return (
            <div className="heading-block pr-60">
                <div className="title-block">
                    <h2>Hotfix</h2>
                </div>
            </div>
        )
    }
}

export default HotfixListFilters;