const DefaultPaymentListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    order:  'desc',
    // sort_id: `id`,
    sort_by: `id`,
    q: ``,
  },

  fields: [
    { field: `created_at`, label: `Date`, sort: true },
    { field: `invoice_for`, label: `Description`, sort: true },
    { field: `grand_total`, label: `Amount`, sort: false },
    { field: `status`, label: `View/Download`, sort: true },
  ]
}

export const PaymentListState = {data:DefaultPaymentListState}