import React from 'react';
import store from "../../../../Store";
import {togglePhpVersionFormModal} from "./FeatureBlock-Action";
import {connect} from "react-redux";
import Loading from "../../../../components/shared/loaders/Loading";
import phpSvg from "../../../../assets/images/phpicon.svg";

class PhpVersionPrimaryView extends React.Component {
    render() {
        let {event, site_status} = this.props
        return (
            <div className="col-xl-12 col-sm-12 feature-col">
                <div className="single-feature">
                    <div className="feature-information">
                        <img src={phpSvg} alt="feature svg" className="feature-img"/>
                        <div className="feature-name-section">
                            <p className="site-tools-heading">Site Tools</p>
                            <h3>PHP Version</h3>
                        </div>
                    </div>
                    <p className="site-tools-description">With Kepler Hosting you can easily change the PHP version of your site. Click "change" and select the correct version for your site.</p>
                    <div className="btn-wrapper">
                        <button className="btn-theme btn-outline blue-outline" data-toggle="modal"
                                data-target="#public-path"
                                onClick={() => store.dispatch(togglePhpVersionFormModal())}
                                disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'restoring' ? 'disabled' : null}
                        >
                            {event === "change_php_version" ? <>Change <Loading/></> : "Change"}

                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let {getSite, getSiteStatus, siteDashboard} = state;
    let {site_status} = siteDashboard.data;

    let event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event

    return {event, getSite, getSiteStatus, site_status};

}

export default connect(mapStateToProps)(PhpVersionPrimaryView);
