import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import {setMigrationDataForUpdate, setMigrationDataForView} from "../../../pages/migration/form/MigrationForm-Action";

function _success(success) {
    return {type: 'GET_MIGRATION_SUCCESS', success}
}

function _error(error) {
    return {type: 'GET_MIGRATION_ERROR', error}
}

function _processing(processing) {
    return {type: 'GET_MIGRATION_PROCESSING', processing}
}

function getMigration(id) {
    return (dispatch) => {
        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))

        axios({
            url: apiBaseUrl(`admin/manual-migration/${id}`),
            method: 'get',
            dataType: 'json',
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                store.dispatch(setMigrationDataForUpdate());
                store.dispatch(setMigrationDataForView());

            }).catch(function (error) {
            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
        })
    }
}

export default getMigration
