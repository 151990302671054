import {MyAccountState} from './MyAccount-State'

export default function myAccountListReducer(state = MyAccountState, action) {

    let {data} = {...state};

    switch (action.type) {
        case 'ON-PASSWORD-RESET-CREATE':
            data.passwordResetSuccessMessage = "Password Changed Successfully";
            return {...state, data};

        case 'DEFAULT-PASSWORD-RESET-MESSAGE':
            data.passwordResetSuccessMessage = "";
            return {...state, data};

        case 'TOGGLE-TWO-FACTOR-AUTHENTICATION-FORM-MODAL':
            if (data.twoFactorAuthentication.mode === true) { // if open, close
                data.twoFactorAuthentication = {mode: ``,}
            } else { // if close, open
                data.twoFactorAuthentication.mode = true;
            }
            return {...state, data};

        default:
            return state
    }
}