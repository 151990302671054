import store from '../../../Store';
import {toggleURLQuery} from '../../../helpers/UrlHelper'
import getsMigrationTracking from "../../../ajax/actions/migration-tracking/migration_tracking_gets";

export function toggleMigrationTrackingListFilter(e, history) {
    return function (dispatch) {
        let {name, value} = e.target;
        toggleURLQuery(name, value, history)
        dispatch({type: 'TOGGLE-MIGRATION-TRACKING-LIST-FILTER', name, value, history});

        if (name === 'limit') {
            store.dispatch(getsMigrationTracking());
            toggleURLQuery('page', '', history)
        } else {
            store.dispatch(getsMigrationTracking());
        }
    }
}

export function setDefaultMigrationTrackingFiltersToURL(history) {
    return (dispatch, getState) => {
        let {data} = getState().migrationTrackingList;
        let {params} = data;
        Object.keys(params).map(key => {
            if (params[key] !== `` && params[key] !== null && params[key] !== undefined) {
                toggleURLQuery(key, params[key], history)
            }
        })
    }
}