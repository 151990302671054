import React from 'react';
import store from '../../../Store';
import getRoles from "../../../ajax/actions/role/roles_get";

class RoleWorker extends React.Component
{
  componentDidMount()
  {
    store.dispatch(getRoles());
  }

  render()
  {
      return null
  }
}

export default RoleWorker;