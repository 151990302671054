import React from "react";
import { Route, Switch } from "react-router-dom";
import Site from "../pages/site/list/Site";
import Organizations from "../pages/organization/list/Organization";
import NotFound from "../components/shared/pages/NotFound";
import OrganizationForm from "../pages/organization/form/OrganizationForm";
import SiteForm from "../pages/site/form/SiteForm";
import OrganizationDashboard from "../pages/organization/OrganizationDashboard";
import ActivityLog from "../pages/activity-log/list/Activity";
import NewSiteDashboard from "../pages/site/NewSiteDashboard";
import MyAccount from "../pages/myAccount/MyAccount";

class OrganizationAdminRoutes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Organizations} />
        <Route exact path="/myaccount" component={MyAccount} />
        <Route exact path="/organizations" component={Organizations} />
        <Route
          exact
          path="/organizations/:id([0-9]{0,9})/update"
          component={OrganizationForm}
        />
        <Route
          exact
          path="/organizations/:id([0-9]{0,9})"
          component={OrganizationDashboard}
        />
        <Route
          exact
          path="/organizations/:id([0-9]{0,9})/sites"
          component={Site}
        />
        <Route
          exact
          path="/organizations/:id([0-9]{0,9})/sites/create"
          component={SiteForm}
        />
        <Route
          exact
          path="/organizations/:org_id([0-9]{0,9})/sites/:id([0-9]{0,9})/:site"
          component={NewSiteDashboard}
        />
        <Route
          exact
          path="/organizations/:org_id([0-9]{0,9})/activitylog"
          component={ActivityLog}
        />
        <Route
          exact
          path="/organizations/create"
          component={OrganizationForm}
        />
        <Route component={NotFound} />
      </Switch>
    );
  }
}

export default OrganizationAdminRoutes;
