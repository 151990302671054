import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess, handleErrorMessageWithOutStatusCode} from '../../../helpers/ErrorHelper'
import {scrollToError} from '../../../helpers/ScrollHelper'
import store from "../../../Store";
import {
    cancelRedirectsFormDispatch,
    RedirectsFormServerValidationErrors,
    setDefaultRedirectsForm
} from "../../../pages/site/redirects/form/RedirectsForm-Action";
import {toggleRedirectsFormModal} from "../../../pages/site/redirects/list/Redirects-Action";
import getRedirects from "./redirects_get";
import getSiteStatus from "../sites/site_status_get";


function _success(success) {
    return {type: 'PUT_REDIRECT_SUCCESS', success};
}

function _error(error) {
    return {type: 'PUT_REDIRECT_ERROR', error};
}

function _processing(processing) {
    return {type: 'PUT_REDIRECT_PROCESSING', processing};
}

function updateRedirect(site_id, id) {
    return (dispatch, getState) => {
        /*dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));*/
        let {redirectForm,getRedirect} = getState()
        let {payload} = redirectForm.data
        //uncomment if you want to pass domain
        /*if(payload.all_domains){
         delete payload.domain_id
        }*/
        delete payload.domain_id
        delete payload.all_domains

        let {organizationDashboard} = getState();
        let org_id = organizationDashboard.data.organization_id;

        /*Donot update if the value is not changed*/
        let {regex, destination, redirect_code} = getRedirect?.success?.data.data
        // const destination = getRedirect?.success?.data.data.destination
        let isPayloadSame = (regex === payload.regex) && (destination === payload.destination) && (redirect_code === payload.redirect_code)

        if (isPayloadSame) {
            store.dispatch(cancelRedirectsFormDispatch())
            let error = {"message": "Please change data to update"}
            handleErrorMessageWithOutStatusCode(error)
        } else {
            dispatch(_processing(true));
            dispatch(_success(null));
            dispatch(_error(null));
            axios({
                url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/redirect_rule/${id}`),
                method: "put",
                dataType: 'json',
                data: payload,
                headers: {
                    'Authorization': 'Bearer ' + loginToken()
                }
            })
                .then(function (res) {
                    dispatch(_success(res));
                    dispatch(_processing(false));
                    handleSuccess(res)
                    store.dispatch(getSiteStatus(site_id))
                    store.dispatch(getRedirects(site_id));
                    store.dispatch(setDefaultRedirectsForm());
                    store.dispatch(toggleRedirectsFormModal());

                })
                .catch(function (error) {
                    dispatch(_error(error.response.data.message));
                    dispatch(_processing(false));
                    handleError(error.response.data)
                    // console.log(` <|  |> `,error.response.data.message )
                    store.dispatch(cancelRedirectsFormDispatch())
                    if (error.response && error.response.status === 422) {
                        // validationError(error.response.data.message,error.response.status)
                        store.dispatch(RedirectsFormServerValidationErrors(error.response.data.errors))
                        scrollToError();
                    }
                });
        }
    }
}


export default updateRedirect;