import axios from 'axios';
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import store from '../../../Store';
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getOrganizationStatus from "../organization/organization_status_get";

function _success(success) {
    return {type: 'DELETE_SITE_SUCCESS', success}
}

function _error(error) {
    return {type: 'DELETE_SITE_ERROR', error}
}

function _processing(processing) {
    return {type: 'DELETE_SITE_PROCESSING', processing}
}

function deleteSite(id,organization_id=null) {
    return (dispatch, getState) => {

        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));
        let {siteList} = getState();

        let org_id = organization_id || siteList.data.organization_id;

        let config = {
            headers: {'Authorization': 'Bearer ' + loginToken()},
        };


        axios.delete(apiBaseUrl(`admin/organizations/${org_id}/sites/${id}`), config)

            .then(res => {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
                store.dispatch(getOrganizationStatus(org_id))
            }).catch(error => {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error)

        });
    }
}

export default deleteSite;