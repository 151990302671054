import store from '../../../Store';
import getActivityLogs from "../../../ajax/actions/activity-log/activity_logs_get";
import {toggleURLQuery} from "../../../helpers/UrlHelper";


export function toggleActivityListFilter(e, history,)
{
  return function (dispatch ) {
    let { name, value  } = e.target;
    toggleURLQuery(name, value, history)
    dispatch({type:'TOGGLE-ACTIVITY-LIST-FILTER', name, value});
    store.dispatch(getActivityLogs());
  }
}

export function onActivityLogSiteChange(site_id) {
  return function (dispatch) {
    dispatch({type:'ON-ACTIVITY-LOG-SITE-CHANGE', site_id});
    store.dispatch( getActivityLogs( ) );
  }
}

export function onActivityLogSiteReset() {
  return function (dispatch) {
    dispatch({type:'ON-ACTIVITY-LOG-SITE-RESET'});
    store.dispatch( getActivityLogs( ) );
  }
}

export function onActivityLogEventChange(event) {
  return function (dispatch) {
    dispatch({type:'ON-ACTIVITY-LOG-EVENT-CHANGE', event});
    store.dispatch( getActivityLogs( ) );
  }
}

export function onActivityLogEventReset() {
  return function (dispatch) {
    dispatch({type:'ON-ACTIVITY-LOG-EVENT-RESET'});
    store.dispatch( getActivityLogs( ) );
  }
}