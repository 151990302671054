import React from 'react';
import {withRouter} from 'react-router';
import store from '../../Store';
import {connect} from 'react-redux';
import {setDefaultPasswordResetMessage} from "./MyAccount-Actions"
import getCountries from "../../ajax/actions/country/countries_get";
import {setDefaultEnable2Fa} from "../two-factor-authentication/Enable2Fa-Action";

class MyAccountWorker extends React.Component {

    componentDidMount() {
        store.dispatch(setDefaultPasswordResetMessage())
        store.dispatch(getCountries())
        store.dispatch(setDefaultEnable2Fa());
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getLoggedInUser} = state;
    return {getLoggedInUser}
}

export default withRouter(connect(mapStateToProps)(MyAccountWorker));