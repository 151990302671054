import axios from "axios";
import { apiBaseUrl } from "../../../helpers/UrlHelper";
import { loginToken } from "../../../helpers/LocalStorageHelper";
import { handleError, handleSuccess } from "../../../helpers/ErrorHelper";
import store from "../../../Store";
import { scrollToError } from "../../../helpers/ScrollHelper";
import {
  cancelDataCenterFormDispatch,
  dataCenterFormServerValidationErrors,
} from "../../../pages/data-center/form/DataCenterForm-Action";

function _success(success) {
  return { type: "CREATE_DATA_CENTER_SUCCESS", success };
}

function _error(error) {
  return { type: "CREATE_DATA_CENTER_ERROR", error };
}

function _processing(processing) {
  return { type: "CREATE_DATA_CENTER_PROCESSING", processing };
}

function createDataCenter() {
  return (dispatch, getState) => {
    let { dataCenterForm } = getState();

    let { latitude, longitude } = dataCenterForm?.data?.payload;
    if (!latitude && !longitude) {
      delete dataCenterForm?.data?.payload?.latitude;
      delete dataCenterForm?.data?.payload?.longitude;
    }
    dispatch(_success(null));
    dispatch(_error(null));
    dispatch(_processing(true));

    axios({
      url: apiBaseUrl(`admin/datacenters`),
      method: "post",
      dataType: "json",
      data: dataCenterForm.data.payload,
      headers: {
        Authorization: "Bearer " + loginToken(),
      },
    })
      .then(function (res) {
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess("Data center created Successfully");
        store.dispatch(cancelDataCenterFormDispatch());
      })
      .catch(function (error) {
        dispatch(_error(error.response.data.message));
        dispatch(_processing(false));
        handleError(error);

        store.dispatch(cancelDataCenterFormDispatch());
        if (error.response && error.response.status === 422) {
          store.dispatch(
            dataCenterFormServerValidationErrors(error.response.data.errors)
          );
          scrollToError();
        }
      });
  };
}

export default createDataCenter;
