export function roleFormServerValidationErrors( validationErrors ) {
  return function (dispatch) {
    dispatch({type:'ROLE-FORM-SERVER-VALIDATION-ERRORS', validationErrors });
  }
}

export function onRoleFormSubmit( ) {
  return function (dispatch) {
    dispatch({type:'ON-ROLE-FORM-SUBMIT' })
  }
}

export function onRoleFormChange( name, value )
{
  return function ( dispatch )
  {
    dispatch({ type:'ON-ROLE-FORM-CHANGE', name, value} );
  }
}

export function onRoleFormModeChange( mode )
{
  return function ( dispatch )
  {
    dispatch({ type:'ON-ROLE-FORM-MODE-CHANGE', mode} );
  }
}

export function cancelRoleFormDispatch() {
  return function (dispatch) {
    dispatch({ type:'CANCEL-ROLE-FORM-DISPATCH' });
  }
}

export function setDefaultRoleForm() {
  return function (dispatch){
    dispatch({type:'SET-DEFAULT-ROLE-FORM'});
  }
}

export function setRoleDataForUpdate()
{
  return function (dispatch, getState) {
    let { getRole } = getState();
    let role = getRole && getRole.success && getRole.success.data && getRole.success.data.data;
    dispatch({type:'SET-ROLE-DATA-FOR-UPDATE',  roles: JSON.parse(JSON.stringify( role )) });
  }
}

export function listPermissionForAssign() {
  return function (dispatch, getState) {
    let { getPermissions } = getState();
    let permissions = getPermissions.success &&
        getPermissions.success.data && getPermissions.success.data.data;

    if( permissions ) {
      dispatch({type:'PERMISSIONS-LIST-TO-ASSIGN',  permissions });
    }
  }
}

export function onRolePermissionsFormChange(name, value) {

  return function (dispatch){
    dispatch({type:'ON-ROLE-PERMISSIONS-FORM-CHANGE', name, value});
  }

}

export function onRolePermissionsFormSwitch(id) {
  return function (dispatch) {
    dispatch({type: 'ON-ROLE-PERMISSIONS-FORM-SWITCH', id});
  }
}
export function onPartialFullItemChange( permissions, mode ) {
    return function (dispatch) {
      dispatch({type: 'ON-PARTIAL-FULL-ITEM-CHANGE', permissions, mode });
    }
}
export function onRolePermissionstestFormSwitch(id) {
  return function (dispatch){
    dispatch({type:'ON-ROLE-PERMISSIONS-FORM-SWITCH', id});
  }
}