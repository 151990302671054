import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import {scrollToError} from '../../../helpers/ScrollHelper'
import {
    cancelOrganizationFormDispatch,
    organizationFormServerValidationErrors,
} from "../../../pages/organization/form/OrganizationForm-Action";

function _success(success) {
    return {type: 'UPDATE_ORGANIZATION_SUCCESS', success}
}

function _error(error) {
    return {type: 'UPDATE_ORGANIZATION_ERROR', error}
}

function _processing(processing) {
    return {type: 'UPDATE_ORGANIZATION_PROCESSING', processing}
}

function updateOrganization(id) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {organizationForm} = getState();

        axios({
            url: apiBaseUrl(`admin/organizations/${id}`),
            method: "post",
            dataType: 'json',
            data: organizationForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken(),
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res)
                store.dispatch(cancelOrganizationFormDispatch())
            }).catch(function (error) {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error)
            store.dispatch(cancelOrganizationFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(organizationFormServerValidationErrors(error.response.data.errors))
                scrollToError();
            }
        });
    }
}


export default updateOrganization;
