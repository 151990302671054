import React from 'react';
import CouponWorker from "./CouponWorker";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import TableHead from "../../../components/shared/table/CouponTableHead";
import store from "../../../Store";
import {toggleCouponListFilter} from "./Coupon-Action";
import swal from "sweetalert";
import deleteCoupon from "../../../ajax/actions/coupon/coupon_delete";
import CouponHeader from "./CouponHeader";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import {fullDate} from "../../../helpers/DateTimeHelper";
import {withRouter} from "react-router";

class Coupon extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }


    toggleSort = (field) => {
        store.dispatch(toggleCouponListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this coupon? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id) {
        store.dispatch(deleteCoupon(id));
    }

    render() {
        let {fields, params, list, getCoupons, getCurrency, values} = this.props;
        let {processing, error} = getCoupons || getCurrency;
        if (error) {
            this.props.history.push('/');
        }

        let titles = getCurrency.success

        return (
            <Page title="Coupon" processing={processing}>

                <CouponWorker/>

                <CouponHeader title="coupon"/>

                <div className="table-block  pr-60">

                    <table className="table table-striped  ">
                            <TableHead fields={fields}
                                        params={params}
                                        values={values}
                                        noActions={true}
                                        onClick={this.toggleSort}/>
                        <tbody>
                        {processing ? <td colSpan="4" className="text-center"><Loading/></td> :
                            list && list.length ? list.map((l, key) => {
                                return (
                                    <tr key={key}>
                                        <td>
                                            {l.name}
                                        </td>
                                        <td>
                                            {fullDate(l.effective_from)}
                                        </td>
                                        <td>
                                            {fullDate(l.effective_to)}
                                        </td>
                                        <td>
                                            {l.total_usage_amount}
                                        </td>
                                        {titles?.map((title, i) => (
                                            <td key={title}>
                                                {l.value[title]} {title}
                                            </td>
                                        ))}
                                        <td>
                                            <div className="dropdown dropdown-alt text-center">
                                                    <span className="dropdown-toggle"
                                                          data-toggle="dropdown">
                                                        <span className="icon-more"/>
                                                    </span>
                                                <div className="dropdown-menu">
                                                    <Link to={`/coupon/${l.id}/update`}>
                                                        <span className=" dropdown-item">Edit</span>
                                                    </Link>
                                                    <Link to={`/coupon/${l.id}/apply`}>
                                                        <span className=" dropdown-item">Apply Coupon</span>
                                                    </Link>
                                                    <a href="/#"
                                                       onClick={ev => {this.deleteConfirm(l.id);ev.preventDefault()}}
                                                       className={`link-delete`}>
                                                        Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : <tr>
                                <td colSpan="7" className="text-center">No Coupon Available</td>
                            </tr>
                        }
                        </tbody>

                    </table>
                </div>

            </Page>
        )
    }
}

function mapStateToProps(state) {
    let {couponList, getCoupons, getCurrency} = state;
    let {params, fields} = couponList.data;
    let {success} = getCoupons;

    let list =
        success &&
        success.data

    let values = getCurrency && getCurrency.success

    let current_page = success && success.current_page;
    let last_page = success && success.last_page;
    let total = success && success.total;
    let pages = {current_page, last_page, total};

    return {params, fields, pages, getCoupons, list, getCurrency, values};
}

export default withRouter(connect(mapStateToProps)(Coupon));