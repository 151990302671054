import React from 'react';
import store from "../../../../Store";
import {toggleCacheIgnoreFormModal} from "./Cache-Action";
import {connect} from "react-redux";
import Loading from "../../../../components/shared/loaders/Loading";
import {onIgnoreCacheFormModeChange,setDefaultIgnoreCacheForm} from "../../ignoreCache/form/IgnoreCacheForm-Action";
import {withRouter} from "react-router";

class CacheIgnorePrimaryView extends React.Component {

    onAddNewRule = () =>{
        // store.dispatch(onIgnoreCacheFormModeChange("create"));
        store.dispatch(setDefaultIgnoreCacheForm());
        store.dispatch(toggleCacheIgnoreFormModal());
    }

    render() {
        let {event,site_status,cache} = this.props

        return (

            <div className="feature-col" id="ignore-cache-feature">
                <div className="single-feature mt-4">
                    <div className="feature-information">
                        <div className="feature-name-section">
                            <h3>Add Cache Ignore Rules</h3>
                            {/*<p>Here you are able to add cache ignore rules for your website.</p>*/}
                            <p>Welcome to the Cache ignore section. Here you will be able to add URLs that you would like our cache tool to ignore. This is good to use if you have a WooCommerce website.</p>
                            {/*<p>If you have any question please contact our support or read our knowledge guide on our website by <a href="/#" onClick={ev => {ev.preventDefault()}}>clicking here</a></p>*/}
                            <p>If you have any questions and need help. Please contact our support.</p>
                        </div>
                    </div>
                    <div className="btn-wrapper">
                        <button className={cache ? "btn-theme btn-outline blue-outline" : "btn-theme btn-outline-disable"} data-toggle="modal"
                                data-target="#public-path"
                                onClick={() =>this.onAddNewRule()}
                                // disabled={cache === 0}
                            disabled={!cache || site_status === 'pending' || site_status === 'updating' ||  site_status === 'restoring'? 'disabled' : null}
                        >
                            { event === "add_ignore_cache" ? <>Adding New Rule <Loading/></> : "Add New Rule"}
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let {getSite, getSiteStatus, siteDashboard} = state;
    let {site_status} = siteDashboard.data;
    let cache = getSite && getSite.success && getSite.success.site_meta && getSite.success.site_meta.enable_cache

    let event = getSiteStatus && getSiteStatus.success && getSiteStatus.success.event

    return {event, getSite, getSiteStatus, site_status,cache};

}

export default withRouter(connect(mapStateToProps)(CacheIgnorePrimaryView));