import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'GETS_MIGRATION_SUCCESS', success}
}

function _error(error) {
    return {type: 'GETS_MIGRATION_ERROR', error}
}

function _processing(processing) {
    return {type: 'GETS_MIGRATION_PROCESSING', processing}
}

function getsMigration() {
    return (dispatch,getState) => {
        // dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))
        let {migrationList} = getState();
        let {params} = migrationList.data;

        axios({
            url: apiBaseUrl(`admin/manual-migration`),
            method: 'get',
            dataType: 'json',
            params,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res.data.data))
                dispatch(_processing(false))

            }).catch(function (error) {
            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
        })
    }
}

export default getsMigration
