export function onUserFormChange(e) {
    let {name, value} = e.target;
    return function (dispatch) {
        dispatch({type: 'ON-USER-FORM-CHANGE', name, value});
    }
}

export function selectCountry(value) {
    let name = "country"
    return function (dispatch) {
        dispatch({type: 'ON-USER-FORM-CHANGE', name, value});
    }
}

export function selectUserPhoneCode(value) {
    let name = "country_code"
    return function (dispatch) {
        dispatch({type: 'ON-USER-FORM-CHANGE', name, value});
    }
}

export function onUserRoleFormChange(value) {
    let name = "role";
    return function (dispatch) {
        dispatch({type: 'ON-USER-FORM-CHANGE', name, value});
    }
}

export function onUserOrganizationAssign(value) {
    return function (dispatch) {
        dispatch({type: 'ON-USER-ORGANIZATION-ASSIGN', value});
    }
}

export function onModeChange(mode) {
    return function (dispatch) {
        dispatch({type: 'ON-MODE-CHANGE', mode});
    }
}


export function onUserFormSubmit(e) {
    e && e.preventDefault();
    return function (dispatch) {
        dispatch({type: 'ON-USER-FORM-SUBMIT'});
    }
}

export function userFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'USER-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function setDefaultUserData() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-USER-DATA',});
    }
}

export function cancelUsersFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-USER-FORM-DISPATCH'});
    }
}


export function setAdminDataForProfile() {
    return function (dispatch, getState) {
            let {getLoggedInUser} = getState();
            let user = getLoggedInUser?.success?.data?.data;
            dispatch({type: 'SET-SPECIFIC-USER-PROFILE', user});
    }
}

export function setLoggedinUserRole() {
    return function (dispatch, getState) {
        let {getLoggedInUser} = getState();
        let login_user_role = getLoggedInUser?.success?.data?.data?.role?.slug
        dispatch({type: 'SET-PROFILE-LOGIN-USER-ROLE', login_user_role});
    }
}