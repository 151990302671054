import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultUserState = {
  payload:{
      first_name: '',
      last_name: '',
      middle_name: '',
      email: ``,
      password: ``,
      address: '',
      extra_address_line: '',
      post_code:'',
      city: '',
      country_id: '',
      // role:'customer-role',
      role:'',
      password_confirmation:'',
      on_sales:true,
  },
    organization_id:``,
     selectedRole:``,
  errors: [],
  errorsPresent: false,
  submitted: false,
  dispatchAPI: false,

  message: ``,
  user_mode:``,
  action:'loader',
    mode:endOfURL(),

};

export const UserFormState = {data: DefaultUserState}

