import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import {scrollToError} from '../../../helpers/ScrollHelper'
import {
    cancelServerModuleFormDispatch,
    serverModuleFormServerValidationErrors
} from "../../../pages/server-module/form/ServerModuleForm-Action";
import store from '../../../Store';

function _success(success) {
    return {type: 'CREATE_SERVER_MODULE_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_SERVER_MODULE_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_SERVER_MODULE_PROCESSING', processing}
}

function createServerModule() {
    return (dispatch, getState) => {

        let {serverModuleForm} = getState();

        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))

        axios({
            url: apiBaseUrl(`admin/servers`),
            method: 'post',
            dataType: 'json',
            data: serverModuleForm.data.payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                store.dispatch(cancelServerModuleFormDispatch())

            }).catch(function (error) {
            dispatch(_error(error.response.data.message))
            dispatch(_processing(false))
            handleError(error)

            store.dispatch(cancelServerModuleFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(serverModuleFormServerValidationErrors(error.response.data.errors))
                scrollToError()
            }
        })
    }
}

export default createServerModule
