import React from 'react';
import store from '../../../Store';
import getDataCenters from "../../../ajax/actions/data-center/data_centers_get";
import getLoadBalancers from "../../../ajax/actions/load-balancer/load_balancers_get";

class LoadBalancerWorker extends React.Component {

    componentDidMount() {
        store.dispatch(getLoadBalancers());
        store.dispatch(getDataCenters());
    }

    render() {
        return null
    }
}

export default LoadBalancerWorker;