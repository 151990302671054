import React from 'react';
import Button from "../../../components/shared/buttons/Button";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import store from "../../../Store";
import {toggleBugFilter} from "./Bug-Actions";

class BugHeading extends React.Component {

    toggleFilter = (e) => {
        store.dispatch(toggleBugFilter(e, this.props.history))
    }

        render() {

        let{params}=this.props
        return (
            <div className="heading-block pr-60">
                <div className="title-block">
                    <h2>Internal Support</h2>
                </div>

                <div className="search-bar">
                    <input type="text"
                           className="form-control"
                           name="tag"
                           placeholder={`Search Tags`}
                           value={params.tag}
                           onChange={this.toggleFilter}
                    />
                    <span className="icon-search"/>
                </div>

                <div className="button-wrapper">
                    <Link to={`/bugs/create`}>
                        <Button className="btn-theme btn-dark-blue"
                                title={`Add Bug`}
                                text={`Add Bug`}
                        // onClick = {this.onBugCreate}
                        />
                    </Link>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    let {bugList} = state;
    let {params} = bugList.data;
    return {
        bugList, params
    };
}

export default withRouter(connect(mapStateToProps)(BugHeading));
