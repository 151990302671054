import React from 'react';
import {connect} from 'react-redux';
import Link from "react-router-dom/Link";
import SiteActionCell from "./SiteActionCell";
import {withRouter} from 'react-router';


class SiteTableCell extends React.Component {

    onclick(organization_id , site_id)
    {

        this.props.history.push(`/organizations/${organization_id}/sites/${site_id}/launchpad/admin`)
    }

    render() {
        let { list, site_status } = this.props;
        return (
         list?.length ? list.map((l, key) => {
             return (
                 site_status  && site_status.length && site_status.map((a,key) => {
                     return(
                         a.id === l.id ?
                         <tr key={key}>
                             {/*<td>{l.id}</td>*/}
                             <td className={l.status === "deleting" || l.status==="creating"?"deactive":"onclick"} onClick={() => (l.created === true && a.status === "updating") || a.status === "idle" ?this.onclick( l.organization_id , l.id ):"#"}>
                                 {(l.created === true && a.status === "updating") || a.status === "idle" ?
                                     <Link to={`/organizations/${l.organization_id}/sites/${l.id}/launchpad/admin`}>
                                         {l.display_name}
                                     </Link> : l.status === "deleting" || l.status==="creating" ? l.display_name:l.display_name}
                             </td>
                             <td className={l.status === "deleting" || l.status==="creating"?"deactive":"onclick"}
                                 onClick={() =>  (l.created === true && a.status === "updating") || a.status === "idle"
                                     ?this.onclick( l.organization_id , l.id ):"#"}>
                                 {l.data_center_name}</td>



                             <td><Link to={`/organizations/${l.organization_id}/sites`}>{l.organization_name}</Link></td>

                             <td className={l.status === "deleting" || l.status==="creating"?"deactive":"onclick"}
                                 onClick={() =>  (l.created === true && a.status === "updating") || a.status === "idle"
                                     ?this.onclick( l.organization_id , l.id ):"#"}>
                                 {l.plan?l.plan.description:"-"}</td>

                             <td className="text-center">

                                 <SiteActionCell
                                     server_id={l.server_id}
                                     site_id={l.id}
                                     status={l.status}
                                     org_id={l.organization_id}
                                 />
                             </td>
                         </tr> :
                             null
                     )
                 }
                 ))
             })
             : <tr>
                 <td colSpan={4} className="text-center">No Sites Available</td>
             </tr>
        )
    }
}

function mapStateToProps(state) {

    let {   getSuperAdminSites , getAdminSiteStatus } = state;

    let {success} = getSuperAdminSites;

    let site_status =
        getAdminSiteStatus?.success;

    let list =
        success?.data.data.data;

    return { list , getAdminSiteStatus , site_status };
}
export default withRouter(connect(mapStateToProps)(SiteTableCell));
