import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import PublicPathFormWorker from './PublicPathFormWorker'
import {onPublicPathFormChange, onPublicPathFormSubmit} from "./PublicPathForm-Action";
import TextField from "../../../../components/shared/form/TextField";
import Loading from "../../../../components/shared/loaders/Loading";
import {togglePublicPathFormModal} from "../list/FeatureBlock-Action";

class publicPathForm extends React.Component {
    publicPathFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onPublicPathFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onPublicPathFormChange(e.target.name, e.target.value,));
    };

    render() {
        let {
            public_path, errors, getSite, publicPathForm
        } = this.props;
        let {processing} = getSite.processing

        return (
            <>
                <PublicPathFormWorker/>
                <form className="mt-30" onSubmit={this.publicPathFormSubmit}>

                    <TextField name="public_path"
                               className="form-control"
                               type="text"
                               value={public_path}
                               label="Public Path"
                               errors={errors.name}
                               response_error={publicPathForm?.data?.payload.public_path === "" ? "true" : "false"}
                               onChange={this.onChange}/>

                    <div className="button-wrapper inline-buttons text-center d-flex justify-content-center">
                        <button className="btn-theme btn-outline" type="reset"
                                onClick={() => store.dispatch(togglePublicPathFormModal())}>Cancel
                        </button>
                        <button type="submit" className="btn-theme radius-btn-blue">
                            {processing ? <Loading/> : `Submit`}
                        </button>
                    </div>
                </form>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {publicPathForm, createPublicPath, getSite} = state;
    let {errors, payload} = publicPathForm.data;

    let {
        public_path
    } = payload;

    return {
        publicPathForm, public_path, errors, createPublicPath, getSite
    };
}

export default connect(mapStateToProps)(publicPathForm);