import axios from 'axios';
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getBug from "./bug_get";
import store from "../../../Store"

function _success(success) {
    return {type: 'DELETE_BUG_IMAGE_SUCCESS', success}
}

function _error(error) {
    return {type: 'DELETE_BUG_IMAGE_ERROR', error}
}

function _processing(processing) {
    return {type: 'DELETE_BUG_IMAGE_PROCESSING', processing}
}

function deleteBugImage(internal_bug_id,id) {
    return (dispatch) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let config = {
            headers: {'Authorization': 'Bearer ' + loginToken()},
        };

        axios.delete(apiBaseUrl(`admin/internal-bug/${internal_bug_id}/image/${id}`), config)

            .then(res => {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
                store.dispatch(getBug(internal_bug_id))
            }).catch(error => {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error)

        });
    }
}

export default deleteBugImage;