import React from "react";
import { connect } from "react-redux";
import store from "../../../Store";
import { setDashboardMenu } from "../../../routes/main-route/MainRoute-Actions";
import Page from "../../../components/shared/pages/Page";
import BackupStatusListFilters from "./BackupStatusListFilters";
import BackupStatusWorker from "./BackupStatusWorker";
import { toggleBackupStatusListFilter } from "./Backup-Status-Action";
import { unslufigy } from "../../../helpers/StringHelper";
import TableHead from "../../../components/shared/table/TableHead";
import Loading from "../../../components/shared/loaders/Loading";

class BackupStatus extends React.Component {
  componentDidMount() {
    document.querySelector("body").classList.add("organization-dashboard");
    store.dispatch(setDashboardMenu(""));
  }

  componentWillUnmount() {
    document.querySelector("body").classList.remove("organization-dashboard");
  }

  toggleSort = (field) => {
    store.dispatch(
      toggleBackupStatusListFilter({
        target: { name: `sort_by`, value: field },
      })
    );
  };

  render() {
    let { list, fields, params, pages, getBackupStatus, table_fields } =
      this.props;
    let { processing, error } = getBackupStatus;
    if (error) {
      this.props.history.push("/");
    }
    let sites_with_no_backups = list && list.sites_with_no_backups;
    const titles = list && Object.keys(list);

    return (
      <>
        <Page title="Backup Status" processing={processing}>
          <BackupStatusWorker />
          <BackupStatusListFilters title="backup-status" />
          <div className="statistics-content">
            <div className="statistics-data-block">
              <div className="statistics-rowing">
                {processing ? (
                  <td colSpan={5} className="text-center">
                    <Loading />
                  </td>
                ) : (
                  titles?.map((title, index) => {
                    return (
                      <>
                        {title !== "sites_with_no_backups" && (
                          <div id="statistics-row" key={index}>
                            <div className="single-site-data" key={index}>
                              <div className="data-title" key={index}>
                                {/*{
                                                                title === "total_sites" ?
                                                                    <h2>Total Sites</h2>
                                                                    : title === "total_backups_today" ?
                                                                        <h2>Total Backups Today</h2>
                                                                        : title === "total_full_backups" ?
                                                                            <h2>Total Full Backups</h2>
                                                                            : title === "total_partial_backups" ?
                                                                                <h2>Total Partial Backups</h2>
                                                                                : title === "total_zfs_based_sites" ?
                                                                                    <h2>Total zfs Based Sites</h2>
                                                                                    :
                                                                                    title === "total_normal_based_sites" ?
                                                                                        <h2>Total Normal Based Sites</h2>
                                                                                        : title === "total_normal_backups" ?
                                                                                            <h2>Total Normal Backups</h2>
                                                                                            : title === "total_manual_backups" ?
                                                                                            <h2>Total Manual Backups</h2>
                                                                                            : ""
                                                            }*/}
                                {title ? <h2>{unslufigy(title)}</h2> : ""}
                              </div>
                              {title && (
                                <div className="site-output">
                                  <div className="data-number">
                                    <p>{list[title]}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })
                )}
              </div>
            </div>
          </div>

          {/*sites without backup listing*/}
          {list && (
            <>
              <div className="heading-block pr-60 backup-status-header mb-5">
                <div className="title-block">
                  <h2>Sites With No Backups</h2>
                </div>
              </div>

              <div className="table-block table-responsive pr-60">
                <table className="table table-striped backup-status-table">
                  <TableHead
                    fields={table_fields}
                    params={params}
                    noActions={true}
                    onClick={this.toggleSort}
                    processing={processing}
                  />
                  <tbody>
                    {processing ? (
                      <td colSpan={5} className="text-center">
                        <Loading />
                      </td>
                    ) : sites_with_no_backups &&
                      sites_with_no_backups.length ? (
                      sites_with_no_backups.map((l, key) => {
                        return (
                          <tr key={key}>
                            <td>{l.id}</td>
                            <td>{l.organization_id}</td>
                            <td>{l.name}</td>
                            <td>{l.backup_attempts}</td>
                            <td>{l.container_name}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="5" className="text-center">
                          No Sites Backups
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </Page>
      </>
    );
  }
}

function mapStateToProps(state) {
  let { getBackupStatus, backupStatusList } = state;

  let { params, fields, table_fields } = backupStatusList.data;

  let { success } = getBackupStatus;

  let list = success;
  return {
    getBackupStatus,
    list,
    backupStatusList,
    params,
    fields,
    table_fields,
  };
}

export default connect(mapStateToProps)(BackupStatus);
