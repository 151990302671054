import axios from 'axios'
import { apiBaseUrl } from '../../../helpers/UrlHelper'
import { loginToken } from '../../../helpers/LocalStorageHelper'
import { handleError, handleSuccess } from '../../../helpers/ErrorHelper'
import {
  cancelCouponFormDispatch,
  currencyError
} from "../../../pages/coupon/form/CouponForm-Action";
import store from "../../../Store";
import {scrollToError} from "../../../helpers/ScrollHelper";

function _success(success) {
  return { type: 'CREATE_COUPON_SUCCESS', success }
}

function _error(error) {
  return { type: 'CREATE_COUPON_ERROR', error }
}

function _processing(processing) {
  return { type: 'CREATE_COUPON_PROCESSING', processing }
}

function createCoupon() {
  return (dispatch,getState) => {

    let {couponForm} = getState();
    dispatch(_success(null))
    dispatch(_error(null))
    dispatch(_processing(true))
    let {payload} = couponForm.data
    delete payload.organization_id
    delete payload.customer_id

    axios({
      url: apiBaseUrl(`admin/coupons`),
      method: 'post',
      dataType: 'json',
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    })
        .then(function(res) {
          dispatch(_success(res))
          dispatch(_processing(false))
          handleSuccess(res)
          store.dispatch(cancelCouponFormDispatch())

        }).catch(function(error) {
      dispatch(_error(error.response.data.message))
      dispatch(_processing(false))
      handleError(error)
      store.dispatch(cancelCouponFormDispatch())
      if (error.response && error.response.status === 422) {
        // console.log(` <| error.response.data |> `,error.response.data )
        // store.dispatch(couponFormServerValidationErrors(error.response.data))
        store.dispatch(currencyError(error.response.data))
        scrollToError()
      }

    })
  }
}

export default createCoupon
