import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {clone} from '../../../helpers/DataHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from '../../../Store'
import {
    cancelUsersFormDispatch,
    setDefaultUserData,
    userFormServerValidationErrors
} from '../../../pages/user/form/UserForm-Actions'
import {scrollToError} from '../../../helpers/ScrollHelper'

function _success(success) {
    return {type: 'CREATE_USER_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_USER_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_USER_PROCESSING', processing}
}

function createUser() {
    return (dispatch, getState) => {
        let userForm = clone(getState().userForm)

        dispatch(_success(null))
        dispatch(_error(null))
        dispatch(_processing(true))

        axios({
            url: apiBaseUrl(`admin`),
            method: 'post',
            dataType: 'json',
            data: JSON.parse(JSON.stringify(userForm.data.payload)),
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                handleSuccess(res)
                store.dispatch(setDefaultUserData())
                store.dispatch(cancelUsersFormDispatch())

            }).catch(function (error) {
            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)

            store.dispatch(cancelUsersFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(userFormServerValidationErrors(error.response.data.errors))
                scrollToError()
            }
        })

    }
}

export default createUser
