import React from 'react';
import {connect} from 'react-redux';
import store from "../../Store";
import Page from "../../components/shared/pages/Page";
import {setDashboardMenu} from "../../routes/main-route/MainRoute-Actions";
import PasswordResetForm from "./password-reset/form/PasswordResetForm";
import {onPasswordResetFormSubmit} from "./password-reset/form/PasswordResetForm-Action";
import {setDefaultPasswordResetMessage, toggleTwoFactorAuthenticationFormModal} from "./MyAccount-Actions";
import ResponsiveModalForm from "../../components/shared/modals/ResponsiveModalForm";
import Enable2Fa from "../two-factor-authentication/Enable2Fa";
import MyAccountWorker from "./MyAccountWorker";
import {Link} from "react-router-dom";
import Gravatar from "react-gravatar";
import enableTwoFactorAuthentication from "../../ajax/actions/2fa-authentication/enable_2fa_post";
import Loading from "../../components/shared/loaders/Loading";
import ProfileForm from "./myProfile/form/ProfileForm";


class myAccount extends React.Component {

    constructor () {
        super()
        this.state = {
            isOpen: false,
            passwordReset: true,
            accountEdit: true,
            activate: false,
        }
        this.openModal = this.openModal.bind(this)
        this.setCloseModel = this.setCloseModel.bind(this)

    }
    openModal () {
        this.setState({
            isOpen: true
        });
    }

    setCloseModel(){
        this.setState({isOpen:false});
    }

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    passwordResetFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onPasswordResetFormSubmit());
    };

    directActivate =(e)=>{
        e.preventDefault();
        store.dispatch(enableTwoFactorAuthentication())
    }

    onClickPasswordReset = () => {
        this.setState({passwordReset: false})
        this.setState({activate: true})
        store.dispatch(setDefaultPasswordResetMessage())
    }

    onClickAccountEdit = () => {
        this.setState({accountEdit: false})
    }

    handleCancel = () => {
        this.setState({passwordReset: true})
        this.setState({activate: false})
    }

    onActivateTwoFactorAuthentication=()=> {
        store.dispatch(toggleTwoFactorAuthenticationFormModal())
    }

    render() {
        let {passwordResetSuccessMessage, twoFa, user, contactNumber , countryCode, enable2fa} = this.props

        let {processing} = enable2fa

        return (
            <Page title="My Account">
                <div className="heading-block pr-60">
                    <div className="title-block">
                        <h2>My Account</h2>
                    </div>
                </div>

                <div className="myaccount-block">
{/*
                    <div className="myaccount-avatar">
                    <a className="account-gravatar">
                        <Gravatar email={user.email}/>
                    </a>
                        <span>{user.first_name} {user.last_name}</span>
                        <span>{user.email}</span>
                    </div>
*/}

                    <div className="col-md-12 m-auto">

                        <div className="custom-password-block" id="cache-row">
                            <div className="myaccount-col" id="myaccount-feature">
                                <div className={!this.state.accountEdit ?"single-feature flex-wrap":"single-feature"}>
                                    <div className={!this.state.accountEdit ?"feature-information": "feature-information"}>
                                        <div className="feature-name-section">
                                            <h3>Account Info</h3>
                                        </div>
                                        </div>
                                    <div className="btn-wrapper">
                                    <button className="btn-theme btn-outline blue-outline "
                                            onClick={this.onClickAccountEdit}>
                                        Edit
                                    </button>
                                    </div>
                                        {this.state.accountEdit === false &&
                                        <ProfileForm/>
                                        }

                                    </div>
                                </div>
                            </div>

                        <div className="custom-password-block" id="cache-row">
                            <div className="myaccount-col" id="myaccount-feature">
                                <div className={!this.state.passwordReset && this.state.activate && !passwordResetSuccessMessage ?"single-feature d-block":"single-feature"}>
                                    <div className={!this.state.passwordReset && this.state.activate && !passwordResetSuccessMessage?"feature-information w-100 mb-4": "feature-information"}>
                                        <div className="feature-name-section">
                                            <h3>Password</h3>
                                        </div>

                                    </div>
                                    {/*<div className="button-block d-flex align-items-center">*/}
                                    {!passwordResetSuccessMessage ?
                                        <div className="password-reset-form">
                                            <form _lpchecked="1" onSubmit={this.passwordResetFormSubmit}>
                                                <div className="button-block">
                                                    {this.state.activate === true && this.state.passwordReset === false &&
                                                    <>
                                                        <PasswordResetForm handleCancel={this.handleCancel}/>
                                                    </>
                                                    }


                                                </div>
                                            </form>
                                        </div>
                                        :
                                        <>
                                        <span className="password-reset-success-message">
                                                      <h3> {passwordResetSuccessMessage ? passwordResetSuccessMessage : null}</h3>
                                                   </span>
                                            {/*{this.state.passwordReset === true &&*/}
                                            <button className="btn-theme btn-outline blue-outline"
                                                    onClick={this.onClickPasswordReset}>
                                                Reset Password
                                            </button>
                                            {/*}*/}
                                        </>
                                    }


                                    {this.state.passwordReset === true &&
                                    <button className="btn-theme btn-outline blue-outline"
                                            onClick={this.onClickPasswordReset}>
                                        Reset Password
                                    </button>
                                    }

                                    {/*<div className="btn-wrapper">
                                        <button className="btn-theme btn-outline blue-outline">
                                            Reset Password
                                        </button>
                                    </div>*/}
                                </div>
                            </div>
                        </div>

                        <ResponsiveModalForm
                            visible={this.state.isOpen}
                            closeModal={()=>this.setCloseModel()}
                            twoFactorauthenticationPopup={true}
                        >
                            <Enable2Fa/>
                        </ResponsiveModalForm>


                        <div className="d-flex align-items-center justify-content-between" id="cache-row">
                            <div className="myaccount-col" id="myaccount-feature">
                                <div className="single-feature">
                                    <div className="feature-information">
                                        <div className="feature-name-section">
                                            <h3>Two Factor Authentication</h3>
                                            <p>Two-factor authentication is an enhanced security measure.
                                                Once enabled you'll be required to give two types of identification when
                                                you
                                                log into KeplerHosting. Google Authenticator and Authy are
                                                supported.</p>
                                        </div>
                                    </div>
                                    <div className="btn-wrapper">
                                        {!twoFa && (!contactNumber || !countryCode)?
                                            <button className="btn-theme btn-outline blue-outline"
                                                    onClick={this.openModal.bind()}>
                                                Activate
                                            </button> :
                                            !twoFa && contactNumber && countryCode?
                                            <button className="btn-theme btn-outline blue-outline" type="reset"
                                                    onClick={(e)=>this.directActivate(e)}>
                                                {processing?<>Activate<Loading/></>:"Activate"}
                                            </button>
                                            :
                                            <button className="btn-theme btn-outline-disable" disabled>
                                                Disable
                                            </button>
                                        }
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <MyAccountWorker/>
            </Page>
        )
    }
}

function mapStateToProps(state) {
    let {route, getUsers, getLoggedInUser, myAccountList, enable2fa} = state;

    let {twoFa, contactNumber , countryCode} = getLoggedInUser
        && getLoggedInUser.success
        && getLoggedInUser.success.data
        && getLoggedInUser.success.data.data

    let {user} =route.data

    let {passwordResetSuccessMessage, twoFactorAuthentication} = myAccountList.data

    return {getUsers, getLoggedInUser, passwordResetSuccessMessage, twoFa,  contactNumber , countryCode, twoFactorAuthentication, user, enable2fa};
}

export default connect(mapStateToProps)(myAccount);