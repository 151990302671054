import React from 'react';
import {connect} from "react-redux";
import {CopyToClipboard} from "react-copy-to-clipboard";
import Tooltip from "../../../../components/shared/tooltip/Tooltip";
import {toggleDomainFormModal} from "./Domain-Action";
import store from "../../../../Store";

// import Badge from "../../../../components/shared/badges/Badge";

class PrimaryDomainView extends React.Component {
    state = {
        copy: false,
        copyData: false,
        copyIp: false
    }

    componentDidUpdate(prev) {
        if (prev.copy !== this.state.copy) {
            this.state.copy = false
        }
        if (prev.copyData !== this.state.copyData) {
            this.state.copyData = false
        }
        if (prev.copyIp !== this.state.copyIp) {
            this.state.copyIp = false
        }
    }

    render() {
        let {domainList, getSite, site_status} = this.props;
        return (

            domainList && domainList.length ?
                domainList.map((d, key) => {
                    if (d.is_primary === 1) {
                        return (
                            <div className="padded-block" key={key}>
                                <div className="row">
                                    <div className="col-xl-12 col-lg-12 mb-5">
                                        {/* <p>Your primary domain is the way we refer to this site and should usually
                                        be set to the main domain you would like your visitors to arrive
                                        through. The DNS records for the primary domain must be set up correctly
                                        for it to work.
                                    </p>*/}
                                        <p>Welcome to the Kepler Domain manager.</p>
                                        <p className="mt-2">Start by adding your first domain. In Kepler Hosting you will
                                            be able to add your domain before pointing your DNS. Once you
                                            have added all your domains please make sure to change the DNS
                                            settings in your DNS manager. Please use the "IP address for DNS"
                                            If you have any questions please feel free
                                            to contact our support and we will help you.</p>
                                        <p className="mt-2">Once your domain is pointed you will need to generate an SSL certificate.</p>
                                        <p className="mt-2">You can do this by clicking on the SSL tab</p>
                                    </div>
                                </div>

                                <div className="row d-flex flex-wrap">

                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                                        <div className="content-label">Primary Domain</div>
                                        <div className="content-info content-info-wrapper">
                                            <span> {d.name} </span>

                                            <div className="text-center mt-2 action-btn-copy">

                                                <CopyToClipboard
                                                    text={d.name}
                                                    onCopy={() => this.setState({copy: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.copy ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.copy ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.copy ? null : 'Copy'}/>
                                                            :
                                                            this.state.copy ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.copy ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.copy ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                                        <div className="content-label">Location / data center</div>

                                        <div className="content-info content-info-wrapper">
                                            <span>  {getSite?.success?.data_center_name}</span>

                                            <div className="text-center mt-2 action-btn-copy">

                                                <CopyToClipboard
                                                    text={getSite?.success?.data_center_name}
                                                    onCopy={() => this.setState({copyData: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.copyData ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.copyData ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.copyData ? null : 'Copy'}/>
                                                            :
                                                            this.state.copyData ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.copyData ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.copyData ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6">
                                        <div className="content-label">IP address for DNS</div>
                                        <div className="content-info content-info-wrapper">
                                            <span> {getSite?.success?.load_balancer_public_ip} </span>

                                            <div className="text-center mt-2 action-btn-copy">

                                                <CopyToClipboard
                                                    text={getSite?.success?.load_balancer_public_ip}
                                                    onCopy={() => this.setState({copyIp: true})}>
                                                    <button className="btn-sm">
                                                        {!this.state.copyIp ?
                                                            <Tooltip title={<span
                                                                className={`${this.state.copyIp ? `text-danger` : null} icon-compare`}/>}
                                                                     tooltip={this.state.copyIp ? null : 'Copy'}/>
                                                            :
                                                            this.state.copyIp ?
                                                                <div>
                                                                    <Tooltip title={<span
                                                                        className={`${this.state.copyIp ? `text-danger` : null} icon-check `}/>}
                                                                             tooltip={this.state.copyIp ? 'Copied' : null}/>
                                                                </div>
                                                                : null}
                                                    </button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="d-flex align-items-center justify-content-end col-xl-3 col-lg-6 col-md-6 col-sm-6 flex-wrap">
                                        <button className="btn-theme btn-outline blue-outline" id="add-domain-btn"
                                                data-toggle="modal"
                                                disabled={site_status === `pending` || site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring'}
                                                data-target="#add-domain"
                                                onClick={() => store.dispatch(toggleDomainFormModal())}>Add Domain
                                        </button>
                                    </div>

                                </div>


                            </div>)
                    }
                }) : null
        )
    }
}

function mapStateToProps(state) {
    let {getSiteDomain, getSite, siteDashboard} = state;
    let {site_status} = siteDashboard.data;
    let {success} = getSiteDomain;
    let domainList = success &&
        success.data &&
        success.data.data &&
        success.data.data.data;
    return {getSiteDomain, domainList, getSite, site_status};
}

export default connect(mapStateToProps)(PrimaryDomainView);