import React from 'react';
import store from '../../../../Store';
import {setSiteIDBackup} from "./Backup-Action";
import getSiteBackup from "../../../../ajax/actions/backup/site_backup_get";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import getSiteStatus from "../../../../ajax/actions/sites/site_status_get";
import getManualSiteBackup from "../../../../ajax/actions/backup/site_manual_backup_get";

class BackupWorker extends React.Component {

  constructor(props) {
    super(props);
    store.dispatch(setSiteIDBackup(props.match.params.id))
    // if(props.organizationDashboard.data.organization_id) {
    //   store.dispatch(getSiteBackup(props.organizationDashboard.organization_id));
    //   store.dispatch(getManualSiteBackup(props.organizationDashboard.organization_id));
    //   store.dispatch(getSiteStatus(props.match.params.id, props.organizationDashboard.organization_id));
    // }
  }

  render() {
    return null
  }
}
  function mapStateToProps(state) {
  let { organizationDashboard ,getSiteStatus, getManualSiteBackup, getSiteBackup } = state;
  let { organization_id } = organizationDashboard.data;
  return { organizationDashboard, organization_id , getSiteStatus, getManualSiteBackup, getSiteBackup};
}

export default withRouter(connect(mapStateToProps)(BackupWorker));