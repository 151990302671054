import React from 'react'
import loader from '../../../assets/images/loader.svg'
import whiteLoader from '../../../assets/images/loader-white.svg'

export default class Loading extends React.Component
{
    render()
    {
        let {
           color, height, width,
            xs, sm, md, lg, xl,className
        } = this.props;

        let setHeight = height || xs ? 7 : sm ? 15 : md ? 40 : lg ? 70 : xl ? 190 : 20;
        let setWidth  = width  || xs ? 7 : sm ? 15 : md ? 40 : lg ? 70 : xl ? 190 : 20;

        return (

            <>
                <img src={ color === `white` ? whiteLoader : loader }
                     alt="loader"
                     className={className}
                     height={ setHeight }
                     width={ setWidth }
                />
            </>
        );
    }
}