import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import { loginToken } from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import getSslCertificates from "./ssl_get";
import getSite from '../../actions/sites/site_get'
import getSiteStatus from "../sites/site_status_get";


function _success(success)
{
  return { type: 'POST_CUSTOM_SSL_CERTIFICATES_SUCCESS', success };
}
function _error(error)
{
  return { type: 'POST_CUSTOM_SSL_CERTIFICATES_ERROR', error };
}
function _processing(processing)
{
  return { type: 'POST_CUSTOM_SSL_CERTIFICATES_PROCESSING', processing };
}

function createCustomSslCertificate(id,payload)
{
  return ( dispatch, getState ) =>
  {
    dispatch(_processing(true));
    dispatch(_success(null));
    dispatch(_error(null));
    let {organizationDashboard} = getState();
    let org_id = organizationDashboard.data.organization_id;

    axios({
      url: apiBaseUrl(`admin/organizations/${org_id}/sites/${id}/ssl`),
      method: "post",
      dataType: 'json',
      data: payload,
      headers: {
        'Authorization': 'Bearer ' + loginToken()
      }
    })
      .then(function(res){
        dispatch(_success(res));
        dispatch(_processing(false));
        handleSuccess( res )
        store.dispatch(getSiteStatus(id))
        store.dispatch(getSslCertificates(id));
        store.dispatch(getSite(id));
      })
      .catch(function(error){
        dispatch(_error(error));
        dispatch(_processing(false));
        handleError( error )
      });

  }
}


export default createCustomSslCertificate;