import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError} from '../../../helpers/ErrorHelper'

function _success(success) {
    return {type: 'GETS_MIGRATION_TRACKING_SUCCESS', success}
}

function _error(error) {
    return {type: 'GETS_MIGRATION_TRACKING_ERROR', error}
}

function _processing(processing) {
    return {type: 'GETS_MIGRATION_TRACKING_PROCESSING', processing}
}

function getsMigrationTracking() {
    return (dispatch,getState) => {
        dispatch(_error(null))
        dispatch(_processing(true))
        let {migrationTrackingList} = getState();
        let {params} = migrationTrackingList.data;

        axios({
            url: apiBaseUrl(`admin/internal-migration-tracking`),
            method: 'get',
            dataType: 'json',
            params,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res.data.data))
                dispatch(_processing(false))

            }).catch(function (error) {
            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
        })
    }
}

export default getsMigrationTracking
