import React from 'react';
import { Route, Switch } from 'react-router-dom'
import Login from '../pages/login/Login'
import RedirectTo from "../pages/RedirectTo";
import {urlTrail} from "../helpers/UrlHelper";

class LoginRoutes extends React.Component
{
    render()
    {
        return (

            <Switch>
                <Route exact path="/" component={ Login }/>
                <Route  path="/login" component={ Login }/>
                {/*<Route  path="/user/create" component={ UserForm }/>*/}
                <Route path="*" render={() => <RedirectTo path={ urlTrail() } />} />
            </Switch>
        )
    }
}
export default LoginRoutes;