export default function getSiteBackupReducer
    (state = { error:null, success:null, processing:false }, action) {

    switch (action.type)
    {
        case 'GET_SITE_BACKUP_SUCCESS':
            return {...state, ...{success: action.success }};

        case 'GET_SITE_BACKUP_ERROR':
            return {...state, ...{ error: action.error }};

        case 'GET_SITE_BACKUP_PROCESSING':
            return {...state, ...{ processing: action.processing }};

        default: return state;
    }
}