import React from 'react';
import store from '../../../../Store';
import {connect} from 'react-redux';
import getSite from "../../../../ajax/actions/sites/site_get";
import {withRouter} from "react-router";


class FeatureBlockWorker extends React.Component {
    componentDidMount() {
        if(this.props.organizationDashboard.organization_id) {
            store.dispatch(getSite(this.props.match.params.id, this.props.organizationDashboard.organization_id));
        }
    }


    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {organizationDashboard, getSite, getSites} = state;
    let {organization_id} = organizationDashboard.data;
    return {organizationDashboard, organization_id, getSite, getSites};
}

export default withRouter(connect(mapStateToProps)(FeatureBlockWorker));