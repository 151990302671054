const DefaultGlobalActivityListState ={
  payload:{
    initialLoading: true,
    flashMessage: null,
    error:"",
    success:"",
  },
  params: {
    limit: 25,
    page:   1,
    order:  'desc',
    sort_by: `site_id`,
    q: ``,
    site_id:``,
    event:``
  },

  fields: [
    { field: `created_at`, label: `Date`, sort: true },
    { field: `user_name`, label: `User`, sort: false },
    { field: `site_id`, label: `Site`, sort: true },//display_name
    { field: `event`, label: `Action`, sort: true },
    { field: `status`, label: `Status`, sort: true },
  ]
}

export const GlobalActivityListState = {data:DefaultGlobalActivityListState}