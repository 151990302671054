import React from 'react';
import LoadBalancerWorker from "./LoadBalancerWorker";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import TableHead from "../../../components/shared/table/TableHead";
import store from "../../../Store";
import {toggleLoadBalancerListFilter} from "./LoadBalancer-Action";
import swal from "sweetalert";
import deleteLoadBalancer from "../../../ajax/actions/load-balancer/load_balancer_delete";
import PaginationButtons from "../../../components/shared/buttons/PaginationButtons";
import LoadBalancerHeader from "./loadBalancerHeader";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Loading from "../../../components/shared/loaders/Loading";
import Page from "../../../components/shared/pages/Page";
import Status from '../../../components/shared/status/Status';

class LoadBalancer extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }


    toggleSort = (field) => {
        store.dispatch(toggleLoadBalancerListFilter({
            // target: {name: `sort_id`, value: field}
            target: {name: `sort_by`, value: field}
        }))
    };

    deleteConfirm = (id) => {
        swal("Are you sure you want to delete this load balancer? ", {
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Delete",
                    value: id
                }
            }
        }).then((value) => {
            if (value) this.delete(value)
        })
    };

    delete(id) {
        store.dispatch(deleteLoadBalancer(id));
    }

    render() {
        let {fields, params, pages, allLoadBalancer, getLoadBalancers} = this.props;
        let {processing, error} = getLoadBalancers;
        if (error) {
            this.props.history.push('/');
        }
        return (
            <Page title="Load Balancer" processing={processing}>

                <LoadBalancerWorker/>

                <LoadBalancerHeader title="load-balancer"/>

                <div className="table-block  pr-60">

                    <table className="table table-striped  ">

                        <TableHead fields={fields}
                                   params={params}
                                   noActions={true}
                                   onClick={this.toggleSort}/>

                        <tbody>
                        {processing ? <td colSpan="4" className="text-center"><Loading/></td> :
                            allLoadBalancer && allLoadBalancer.length ? allLoadBalancer.map((l, key) => {
                                return (
                                    <tr key={key}>
                                        <td>
                                            <Link to={`/load-balancer/${l.id}/update`}>
                                            {l.name}
                                            </Link>
                                        </td>
                                        <td>
                                            <Link to="/server">
                                                {(l.server_name)}
                                            </Link>
                                        </td>
                                        <td>
                                            <Status status={l.status}/>
                                        </td>
                                        <td>
                                            <div className="dropdown dropdown-alt text-center">
                                                    <span className="dropdown-toggle"
                                                          data-toggle="dropdown">
                                                        <span className="icon-more"/>
                                                    </span>
                                                <div className="dropdown-menu">
                                                    <Link to={`/load-balancer/${l.id}/update`}>
                                                        <span className=" dropdown-item">Edit</span>
                                                    </Link>
                                                    <a href="/#"
                                                       onClick={ev => {this.deleteConfirm(l.id);ev.preventDefault()}}
                                                       className={`link-delete`}>
                                                        Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) : <tr>
                                <td colSpan="7" className="text-center">Please Select the Data Center & server</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </div>
                {allLoadBalancer && allLoadBalancer.length ? (
                    <div className="fixed-footer pr-60">
                        <PaginationButtons pages={pages}
                                           processing={processing}
                                           fetchAction={toggleLoadBalancerListFilter}/>
                    </div>
                ) : null}

            </Page>
        )
    }
}

function mapStateToProps(state) {
    let {loadBalancerList, getDataCenters, getLoadBalancers} = state;
    let {params, fields} = loadBalancerList.data;

    let allLoadBalancer =
        getLoadBalancers &&
        getLoadBalancers.success &&
        getLoadBalancers.success.data &&
        getLoadBalancers.success.data.data &&
        getLoadBalancers.success.data.data.data

    let current_page = getLoadBalancers && getLoadBalancers.success && getLoadBalancers.success.data &&
        getLoadBalancers.success.data.data && getLoadBalancers.success.data.data.current_page;
    let last_page = getLoadBalancers && getLoadBalancers.success && getLoadBalancers.success.data &&
        getLoadBalancers.success.data.data && getLoadBalancers.success.data.data.last_page;
    let total = getLoadBalancers && getLoadBalancers.success && getLoadBalancers.success.data &&
        getLoadBalancers.success.data.data && getLoadBalancers.success.data.data.total;
    let from = getLoadBalancers && getLoadBalancers.success && getLoadBalancers.success.data &&
        getLoadBalancers.success.data.data && getLoadBalancers.success.data.data.from;

    let pages = {current_page, last_page, total, from};

    return {allLoadBalancer, loadBalancerList, params, fields, pages, getDataCenters, getLoadBalancers};
}

export default connect(mapStateToProps)(LoadBalancer);