import React from 'react';
import store from '../../Store'
import {connect} from 'react-redux';
import {accessToken, loggedInUser} from "../../helpers/LocalStorageHelper";
import loginUser from "../../ajax/actions/user/user_login_post";
import "../../routes/AdminRoutes";
import {
    cancelLoginFormDispatch,
    loginFormServerValidationErrors,
} from "./Login-Actions";
import {setUser} from "../../routes/main-route/MainRoute-Actions";
import getLoggedInUser from "../../ajax/actions/user/user_loggedin_get";
import getAuthyQr from "../../ajax/actions/2fa-authentication/authy_qr_code_get";

class LoginWorker extends React.Component {

    componentDidUpdate(prev) {
        let {dispatchAPI} = this.props;

        if (this.props.loginUser !== prev.loginUser) {
            let {success, error} = this.props.loginUser;
            // console.log(` <| success |> `,success && success.data.data.twoFa )
            // if(success){
            //     store.dispatch(getAuthyQr(success.data.data.token));
            // }
           /* if(success && success.data.data && success.data.data.access_token){
                accessToken(success.data.data.access_token)
            }*/


         /*   if (success && !success.data.data.twoFa && success.status !== 401) {
                loggedInUser(success.data.data.token);
                // store.dispatch(getUser(success.data.data.user.id));
                store.dispatch(getLoggedInUser(success.data.data))
                window.location.href = '/';
            }*/

               if (success && !success.data.data.twoFa && success.status !== 401) {
               loggedInUser(success.data.data.bearer_token);
               // store.dispatch(getUser(success.data.data.user.id));
               store.dispatch(getLoggedInUser())
               window.location.href = '/';
           }


           /* if (success) {
                loggedInUser(success.data.data.token);
                // store.dispatch(getUser(success.data.data.user.id));
                store.dispatch(getLoggedInUser(success.data.data))
                window.location.href = '/';
            }*/

            if (error) {
                store.dispatch(cancelLoginFormDispatch());
                if (error.response && error.response.status === 422) {
                    store.dispatch(loginFormServerValidationErrors(error.response.data && error.response.data.errors))
                }
            }
        }

        if (this.props.getLoggedInUser !== prev.getLoggedInUser) {
            let {success} = this.props.getLoggedInUser;
            if (success) {
                store.dispatch(setUser(success))
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(loginUser());
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {loginForm, loginUser, getLoggedInUser} = state;
    let {dispatchAPI, redirectTo} = loginForm.data;
    return {loginForm, dispatchAPI, loginUser, redirectTo, getLoggedInUser};
}

export default connect(mapStateToProps)(LoginWorker);