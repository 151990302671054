import React from 'react';
import {withRouter} from 'react-router';
import store from '../../Store';
import {connect} from 'react-redux';
import getOrganization from "../../ajax/actions/organization/organization_get";
import {setOrganizationDashboardData,} from "./OrganizationDashboard-Action";
import {setOrganizationSiteUrl} from "../../routes/main-route/MainRoute-Actions";

class OrganizationDashboardWorker extends React.Component {
    componentDidMount() {
        store.dispatch(getOrganization(this.props.match.params.id));
        store.dispatch(setOrganizationSiteUrl(this.props.match.url));
    }

    componentDidUpdate(prev) {
        if (this.props.getOrganization !== prev.getOrganization) {
            let {success} = this.props.getOrganization;
            if (success) {
                store.dispatch(setOrganizationDashboardData());
            }
        }
    }

    render() {

        return null
    }
}

function mapStateToProps(state) {
    let {getOrganization, getOrganizationStatus} = state;
    return {getOrganization, getOrganizationStatus}
}

export default withRouter(connect(mapStateToProps)(OrganizationDashboardWorker));