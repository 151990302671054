import React from 'react';
import store from '../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import updateDataCenter from "../../../ajax/actions/data-center/data_center_put";
import {
    onDataCenterFormModeChange,
    setDefaultDataCenterForm,
    setDataCenterDataForUpdate
} from "./DataCenterForm-Action";
import createDataCenter from "../../../ajax/actions/data-center/data_center_post";
import getDataCenter from "../../../ajax/actions/data-center/data_center_get";
import {endOfURL, urlTrailWithoutLastItem} from "../../../helpers/UrlHelper";

class DataCenterFormWorker extends React.Component {

    constructor(props) {
        super(props);
        if (props.match.params.id) {
            store.dispatch(getDataCenter(props.match.params.id));
            store.dispatch(onDataCenterFormModeChange('update'));
        }

        if (endOfURL() === 'create') {
            store.dispatch(setDefaultDataCenterForm())
        }
    }

    componentDidUpdate(prev) {
        let {dispatchAPI, mode,} = this.props;
        if (this.props.getDataCenter !== prev.getDataCenter) {
            let {success, error} = this.props.getDataCenter;
            if (success) {
                store.dispatch(setDataCenterDataForUpdate());
            }

            if (error) {
                //
            }
        }

        if (this.props.createDataCenter !== prev.createDataCenter) {
            let {success} = this.props.createDataCenter;
            if (success) {
                this.props.history.push(urlTrailWithoutLastItem());
            }
        }

        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                if (mode === 'create') {
                    store.dispatch(createDataCenter())
                } else {
                    store.dispatch(updateDataCenter(this.props.match.params.id))
                }
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {dataCenterForm, getDataCenter, createDataCenter,} = state;
    let {dispatchAPI, mode, payload} = dataCenterForm.data;
    return {dataCenterForm, dispatchAPI, getDataCenter, mode, payload, createDataCenter};
}

export default withRouter(connect(mapStateToProps)(DataCenterFormWorker));