export default function getBugsReducer
    (state = { error:'', success:'', processing:false}, action)
{
    switch (action.type) {
        case 'GET_BUGS_SUCCESS':
            return {...state, ...{success: action.success}};

        case 'GET_BUGS_ERROR':
            return {...state, ...{error: action.error}};

        case 'GET_BUGS_PROCESSING':
            return {...state, ...{processing: action.processing}};

        default: return state;
    }
}