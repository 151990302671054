const DefaultBillingBlockFormState = {
    payload: {
        billable: ''
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: ``,
    message: ``,
    site_id: ``,
    organization_id: ``,
};

export const BillingBlockFormState = {data: DefaultBillingBlockFormState};