export function planFormServerValidationErrors(validationErrors) {
    return function (dispatch) {
        dispatch({type: 'PLAN-FORM-SERVER-VALIDATION-ERRORS', validationErrors});
    }
}

export function onPlanFormSubmit() {
    return function (dispatch) {
        dispatch({type: 'ON-PLAN-FORM-SUBMIT'})
    }
}

export function onPlanFormChange(name, value) {
    return function (dispatch) {
        dispatch({type: 'ON-PLAN-FORM-CHANGE', name, value});
    }
}

export function cancelPlanFormDispatch() {
    return function (dispatch) {
        dispatch({type: 'CANCEL-PLAN-FORM-DISPATCH'});
    }
}

export function setDefaultPlanForm() {
    return function (dispatch) {
        dispatch({type: 'SET-DEFAULT-PLAN-FORM'});
    }
}

export function setSiteId(site_id) {
    return function (dispatch) {
         dispatch({type: 'SET-SITE-ID', site_id});
    }
}

export function planError(error) {
    return function (dispatch) {
        dispatch({type: 'SET-PLAN-ERROR', error});
    }
}