import axios from 'axios'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import getSite from "../../../ajax/actions/sites/site_get";
import store from "../../../Store";
import {
    cancelPublicPathFormDispatch, publicPathFormServerValidationErrors,
    setDefaultPublicPathForm
} from "../../../pages/site/feature-block/form/PublicPathForm-Action";
import {togglePublicPathFormModal} from "../../../pages/site/feature-block/list/FeatureBlock-Action";
import getSiteStatus from "../sites/site_status_get";
import {scrollToError} from "../../../helpers/ScrollHelper";

function _success(success) {
    return {type: 'CREATE_PUBLIC_PATH_SUCCESS', success}
}

function _error(error) {
    return {type: 'CREATE_PUBLIC_PATH_ERROR', error}
}

function _processing(processing) {
    return {type: 'CREATE_PUBLIC_PATH_PROCESSING', processing}
}

function createPublicPath(id=null) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {publicPathForm, organizationDashboard} = getState();
        let payload = publicPathForm.data.payload
        let site_id = id || publicPathForm?.data?.payload.site_id
        let org_id = organizationDashboard.data.organization_id;

        axios({
            url: apiBaseUrl(`admin/organizations/${org_id}/sites/${site_id}/public_path`),
            method: 'post',
            dataType: 'json',
            data: payload,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res))
                dispatch(_processing(false))
                // handleSuccess("Monitoring request sent Successfully")
                handleSuccess(res.data.message)
                store.dispatch( cancelPublicPathFormDispatch( ))
                store.dispatch( setDefaultPublicPathForm() )
                store.dispatch(togglePublicPathFormModal());
                store.dispatch( getSiteStatus(id ) );
                store.dispatch(getSite(site_id, org_id))


            }).catch(function (error) {

            dispatch(_error(error))
            dispatch(_processing(false))
            handleError(error)
            store.dispatch( cancelPublicPathFormDispatch( ))
            if(error.response && error.response.status === 422 ) {
                store.dispatch( publicPathFormServerValidationErrors( error.response.data.errors ))
                scrollToError();
            }
        });
    }
}

export default createPublicPath;