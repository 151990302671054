import {CouponFormState} from './CouponForm-State'
import {errorsList, errorsPresent, mergeServerValidationErrors} from '../../../helpers/ErrorHelper'
import {endOfURL} from '../../../helpers/UrlHelper'
import {isObject} from "../../../helpers/DataHelper";
import {fullDateStringNoTime} from "../../../helpers/DateTimeHelper";

export default function couponFormReducer(state = CouponFormState, action) {

    let {data} = {...state};
    let {validationErrors, name, value, coupon, mode, couponDetails, price, currencyServerError} = action;
    switch (action.type) {

        case 'ON-COUPON-FORM-CHANGE':
            data.payload[name] = value;
            data.currencyError = ''
            data.submittedStatus = false
            // data.currencyError=''
            buildErrors();
            return {...state, data};

        case 'ON-COUPON-APPLY-FORM-CHANGE':
            data.payload[name] = value;
            // data.currencyError=''
            buildErrors();
            return {...state, data};

        case 'ON-COUPON-FORM-DATE-CHANGE':
            data.payload[name] = value;
            // data.currencyError=''
            buildErrors();
            return {...state, data};

        case 'ON-COUPON-CURRENCY-ERROR':
            data.currencyError = currencyServerError;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-COUPON-CURRENCY-CHANGE':
            data.payload['value'] = value
            data.currencyError = ''
            data.submittedStatus = false
            buildErrors();
            return {...state, data};

        case 'onInitialUpdateCurrencyChange':
            data.payload.value[name] = value
            // data.currencyError=''
            buildErrors();
            return {...state, data};

        case 'ON-COUPON-FORM-MODE-CHANGE':
            data.mode = mode;
            buildErrors();
            return {...state, data};

        case 'SET-COUPON-DATA-FOR-UPDATE':
            data.payload = {
                name: coupon.name,
                type: coupon.type,
                effective_from: coupon.effective_from,
                effective_to: coupon.effective_to,
                total_usage_amount: coupon.total_usage_amount,
                status: coupon.status,
                value: coupon.value,
                customer_id: coupon.customer_id || ``,
                organization_id: coupon.organization_id || ``,
            };
            data.currencyError = ''
            data.submittedStatus = false
            buildErrors();
            return {...state, data};

        // case 'SET-COUPON-DATA-FOR-APPLY':
        //     data.payload = {
        //         name: couponDetails.name,
        //         type: couponDetails.type,
        //         effective_from: couponDetails.effective_from,
        //         effective_to: couponDetails.effective_to,
        //         total_usage_amount: couponDetails.total_usage_amount,
        //         status: couponDetails.status,
        //         value: couponDetails.value,
        //
        //     };
        //     buildErrors();
        //     return {...state, data};

        case 'SET-DEFAULT-COUPON-FORM':
            data = {
                payload: {
                    name: ``,
                    type: 'flat',
                    effective_from: fullDateStringNoTime(new Date()),
                    effective_to: fullDateStringNoTime(new Date()),
                    total_usage_amount: '',
                    status: '',
                    value: ''
                },
                errors: [],
                currencyError: '',
                errorsPresent: false,
                submitted: false,
                dispatchAPI: false,
                mode: endOfURL(),
                message: ``
            };
            buildErrors();
            return {...state, data};

        case 'CANCEL-COUPON-FORM-DISPATCH':
            data.dispatchAPI = false;
            data.submitted = false;
            buildErrors();
            return {...state, data};

        case 'ON-COUPON-FORM-SUBMIT':
            data.submitted = true;
            data.submittedStatus = true;
            buildErrors();
            if (!data.errorsPresent) data.dispatchAPI = true;
            return {...state, data};

        case 'COUPON-FORM-SERVER-VALIDATION-ERRORS':
            validationErrors && isObject(validationErrors) &&
            Object.keys(validationErrors).map(key =>
                // console.log(` <| validationErrors |> `, validationErrors)
                data.errors[key] = [{message: ` - ${validationErrors[key][0]}`.slice(0, -1)}]
            );
            data.dispatchAPI = false;
            return {...state, data};

        default:
            return state
    }

    function buildErrors() {
        data.errors = errorsList(errors)
        data.errorsPresent = errorsPresent(errors)
    }

    function errors(field) {
        let {payload, submitted, validationErrors,mode} = data;
        let errors = {};
        Object.keys(payload).map(key => errors[key] = []);

        if (submitted) {
            if (field === `name`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (field === `effective_from`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (field === `effective_to`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (field === `status`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if (field === `total_usage_amount`) {
                if (!_(field)) newError(field, 0, `This is required field`)
            }
            if(mode === "apply"){
                if (field === `organization_id`) {
                    if (!_(field)) newError(field, 0, `This is required field`)
                }
                if (field === `customer_id`) {
                    if (!_(field)) newError(field, 0, `This is required field`)
                }
            }

            // {currencyList && currencyList.map(l => {
            //     if (field === payload.value[l]) {
            //         if (!_(field)) newError(field, 0, `This is required field`)
            //     }
            // })
            // }
        }

        errors = mergeServerValidationErrors(errors, validationErrors)

        return field ? errors[field] : errors

        function newError(field, step, message) {
            errors && errors[field].push({step, message})
        }

        function _(field) {
            return data.payload[field]
        }
    }
}