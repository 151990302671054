import store from '../../../../Store';
import { toggleURLQuery } from '../../../../helpers/UrlHelper'
// import getErrorLogs from "../../../../ajax/actions/logs/errorLogs_get";
import getLogs from "../../../../ajax/actions/logs/Logs_get";

export function toggleLogsListFilter(e, history)
{
  return function (dispatch) {
    let { name, value } = e.target;
    let toggle = true;
    if ( toggle ) toggleURLQuery( name, value , history)
    dispatch({type:'TOGGLE-LOGS-LIST-FILTER', name, value});
    // store.dispatch(getErrorLogs());
    // store.dispatch(getLogs(this.props.match.params.org_id));
    store.dispatch(getLogs());
  }
}

export function setSiteIDLogs( site_id )
{
  return function (dispatch) {
    dispatch({type:'SET-SITEID-LOGS', site_id});
  }
}

export function onClickError() {
  return function (dispatch) {
    dispatch({type: 'SET-LOGS-ERRORCLICK'});
    store.dispatch(getLogs());
  }
}

export function onClickAccess() {
  return function (dispatch) {
    dispatch({type: 'SET-LOGS-ACCESSCLICK'});
    store.dispatch(getLogs());
  }
}