const DefaultSitesListState = {
    payload: {
        initialLoading: true,
        flashMessage: null,
        error: "",
        success: "",
    },
    params: {
        limit: 25,
        page: 1,
        sort_order: 'desc',
        // billable:'',
        // sort_id: `id`,
        sort_by: `sites_count`,
        q: ``,
    },
    billable:'',
    fields: [
        {field: `sites_count`, label: `Number of sites`, sort: true},
        {field: `name`, label: `Name`, sort: true},
        {field: `billing_name`, label: `Client`, sort: false},
        {field: `city`, label: `Location`, sort: true},
        {field: `billable`, label: `Billing`, sort: true},
        {field: `action`, label: `Action`, sort: false},

    ]
}

export const OrganizationListState = {data: DefaultSitesListState}