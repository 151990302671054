import React from 'react';
import Button from "../../components/shared/buttons/Button";
import {Link, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import Loading from "../../components/shared/loaders/Loading";
import DisplayNameForm from "./change-display-name/form/DisplayNameForm";
import store from "../../Store";
import changeDisplayName from "../../ajax/actions/sites/site_displayname_post"
import {endOfURL} from "../../helpers/UrlHelper";

class SiteDashboardHeader extends React.Component {

    state = {
        edit: false,
        cancel: false,
        update: false,
    }
    updateDisplayName=(e)=>{
        store.dispatch( changeDisplayName(this.props.match.params.id ) );
        this.state.edit= false;
    }
    render() {
        let {
            organization_id, site, organization_name, site_status, match,
            site_message, processing,planMode
        } = this.props;

        let mode = match && match.params && match.params.mode;
        return (
            <>
                {
                    (site_status === 'updating' || site_status === 'migrating' || site_status === 'restoring') && (
                        <div className="div-site-status sticky-top">
                            {site_message} <Loading color="white"/>
                        </div>
                    )
                }
                <div className="heading-block pr-60 mb-0 mt-30">
                    <div className="title-block" id="site-dashboard-heading">
                        {site && site.name ?
                            <>

                                {mode !== 'admin' ?
                                    <>
                                        <span><Link to="/organizations">Organizations</Link></span>
                                        <span><Link
                                            to={`/organizations/${organization_id}/sites`}>{organization_name}</Link></span>

                                        {/*<span>{organization_name}</span>*/}
                                        <span> <Link to={`/organizations/${organization_id}/sites`}>Sites</Link></span>
                                        <span>{site && site.display_name}</span></> :
                                    <>
                                        <span><Link to="/sites">Sites</Link></span>
                                        <span>{site && site.display_name}</span>
                                    </>
                                }
                                <div className="display-name" id={this.state.edit === true && "buttons-wrap"}>{this.state.edit === false ?
                                <>  {
                                    planMode !== "update" &&  processing ?
                                        <div className="mr-5"><Loading /></div>
                                        :
                                        <h2>
                                            {site && site.display_name}
                                        </h2>
                                }
                                </>:
                                <DisplayNameForm data={site && site.display_name}/>
                                }
                                    {this.state.edit === false ?
                                <a href="/#" onClick={ev => {this.setState({edit: true});ev.preventDefault()}}>
                                    {
                                        planMode !== "update" &&  processing ? "" :
                                        <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                                    }

                                </a>
                                        :<>
                                            <div className="d-flex">
                                                <button className="btn-theme btn-outline 1 mr-2" id="update-site-name-btn" onClick={this.updateDisplayName}>Update</button>
                                                <button className="btn-theme btn-outline 1" id="update-site-name-btn" onClick={() => this.setState({edit: false})}>Cancel</button>
                                            </div>
                                        </>
                                }
                                </div>
                            </> :  <div className="heading-block pr-60"> <div className="title-block"> <Loading/></div></div>
                        }
                    </div>
                    <div className="button-wrapper" id="add-site">
                        <Link to={`/organizations/${organization_id}/sites/create`}>
                            <Button className="btn-theme btn-dark-blue"
                                    title={`Add Site`}
                                    text={`Add Site`}/>
                        </Link>
                    </div>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {getSite, organizationDashboard, siteDashboard, planList} = state;
    let {organization_name, organization_id,} = organizationDashboard.data;
    let site =
        getSite &&
        getSite.success;

    let {
        site_status, site_notification,
        site_message
    } = siteDashboard.data;
    let {mode:planMode } =  planList?.data

    return {
        getSite,
        site,
        organizationDashboard,
        organization_name,
        organization_id,
        siteDashboard,
        site_status,
        site_notification,
        site_message,
        planMode
    };
}

export default withRouter(connect(mapStateToProps)(SiteDashboardHeader));