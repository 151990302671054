import {endOfURL} from "../../../helpers/UrlHelper";

const DefaultBugFormState = {
    payload: {
        summary: ``,
        description:``,
        reporter_id: ``,
        other_links:``,
        urgency:``,
        priority:``,
        assigned_to:``,
        organization_id: ``,
        site_id: ``,
        remarks:``,
        images:[],
        tags: [],
        status:``,
        imageLinks:[]
    },
    errors: [],
    errorsPresent: false,
    submitted: false,
    dispatchAPI: false,
    mode: endOfURL(),
    message: ``,
    site_id: ``,
};

export const BugFormState = {data: DefaultBugFormState};