import React from 'react';
import {connect} from 'react-redux'
import store from '../../../../Store';
import SearchReplaceFormWorker from './SearchAndReplaceFormWorker'
import {
    onReplaceFlagChange,
    onSearchAndReplaceFormChange,
    onSearchAndReplaceFormSubmit
} from "./SearchAndReplaceForm-Action";
import TextField from "../../../../components/shared/form/TextField";
import Loading from "../../../../components/shared/loaders/Loading";
import {toggleSearchAndReplaceFormModal} from "../../feature-block/list/FeatureBlock-Action";
import CheckField from "../../../../components/shared/form/CheckField";
import Inactive from "../../../../assets/images/inactive.svg";

class searchAndReplaceForm extends React.Component {
    searchAndReplaceFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onSearchAndReplaceFormSubmit());
    };

    onChange = (e) => {
        store.dispatch(onSearchAndReplaceFormChange(e.target.name, e.target.value,));
    };
    onReplaceFlagChange = (e) => {
        store.dispatch(onReplaceFlagChange(e.target.name));
    };


    render() {
        let {
            errors,
            getSite,
            searchTerm,
            replaceTerm,
            site_search_status_event,
            site_search_status_message,
            amount_of_terms,
            replace_flag,
            search_item_name,
            site_status,
            failedStatus,
            errorMessage
        } = this.props;
        let {processing} = getSite.processing

        return (
            <>
                <SearchReplaceFormWorker/>
                <h3 className="search-header text-left mb-30">Search and Replace</h3>
                <div className="search-replace-line">
                    <p>  {site_search_status_event === "search_replace_term" || site_search_status_event === "replace_searched_term"|| site_search_status_event === "backup_for_replace_term" ? <>
                        <div className="div-site-status-inside-form sticky-top">
                            <Loading color="white"/>{site_search_status_message} </div>
                    </> : null}</p>
                    <p className="thick-text"> The Kepler Hosting Search & Replace tool - After you migrate a website to Kepler or if you want to change your domain. The search and replace tool is used to search for your old domain and replace it with your new domain. Please remember to clear your cache if the cache is activated.</p>
                    <form className="mt-30 domain-form" onSubmit={this.searchAndReplaceFormSubmit}>

                        <TextField name="searchTerm"
                                   className="form-control"
                                   type="text"
                                   value={searchTerm}
                                   label="Search"
                                   errors={errors.searchTerm}
                                   onChange={this.onChange}/>

                        <CheckField name="replace_flag"
                                    value={replace_flag}
                                    label="Replace"
                                    checked={replace_flag === 1}
                                    onChange={this.onReplaceFlagChange}
                        />
                        {replace_flag && replace_flag === 1 ?
                            <TextField name="replaceTerm"
                                       className="form-control"
                                       type="text"
                                       value={replaceTerm}
                                       label="Replace with"
                                       errors={errors.replaceTerm}
                                       onChange={this.onChange}/>
                            : null}

                        {amount_of_terms ?
                            <div className="table-block table-responsive mt-20">
                                <table className="table table-striped">
                                    <tbody>
                                    <tr>
                                        <td>
                                            <a className={`icon-check`} href="/#" onClick={ev => {ev.preventDefault();}}/> {amount_of_terms} results found for
                                            "{search_item_name}"
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            : null}

                        {failedStatus && errorMessage ?
                            <p className="bg-danger">
                                <img alt="status" src={Inactive}/> {failedStatus}-
                                {errorMessage}</p>
                            // <p>
                            // <div className="div-site-status-inside-form sticky-top">
                            //     <a className={`icon-close-circle`}/> {failedStatus}-
                            //     {errorMessage}
                            // </div></p>
                            : null}
                        <div className="button-wrapper inline-buttons text-center d-flex justify-content-center">
                            <button className="btn-theme btn-outline" type="reset"
                                    onClick={() => store.dispatch(toggleSearchAndReplaceFormModal())}>Cancel
                            </button>
                            <button type="submit" className="btn-theme radius-btn-blue"
                                    disabled={site_status === 'pending' || site_status === 'updating' || site_status === 'migrating'||  site_status === 'restoring'? 'disabled' : null}>
                                {processing ? <Loading/> : `Submit`}
                            </button>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {searchAndReplaceForm, createPublicPath, getSite, getSiteStatus, siteDashboard} = state;
    let {site_status} = siteDashboard.data;

    let site_search_status_event =
        getSiteStatus &&
        getSiteStatus.success &&
        getSiteStatus.success.event
    let site_search_status_message =
        getSiteStatus &&
        getSiteStatus.success &&
        getSiteStatus.success.message

    let {errors, payload} = searchAndReplaceForm.data;
    let replace_flag = searchAndReplaceForm && searchAndReplaceForm.data.replace_flag
    let amount_of_terms = searchAndReplaceForm && searchAndReplaceForm.data.amount_of_terms
    let search_item_name = searchAndReplaceForm && searchAndReplaceForm.data.search_item_name
    let failedStatus = searchAndReplaceForm && searchAndReplaceForm.data.failedStatus
    let errorMessage = searchAndReplaceForm && searchAndReplaceForm.data.errorMessage

    let {
        searchTerm, replaceTerm
    } = payload;

    return {
        searchAndReplaceForm,
        searchTerm,
        replaceTerm,
        errors,
        createPublicPath,
        getSite,
        getSiteStatus,
        site_search_status_event,
        site_search_status_message,
        amount_of_terms,
        replace_flag,
        search_item_name,
        site_status,
        failedStatus,
        errorMessage
    };
}

export default connect(mapStateToProps)(searchAndReplaceForm);