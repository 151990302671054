import React from 'react'
import TextField from '../../../../components/shared/form/TextFieldProfile'
import PasswordField from '../../../../components/shared/form/PasswordField'
import store from '../../../../Store';
import {connect} from 'react-redux'
import {
    onUserFormSubmit,
    onUserFormChange, onUserRoleFormChange,
    selectCountry, onUserOrganizationAssign, selectUserPhoneCode
} from './ProfileForm-Actions'
import UserFormWorker from "./ProfileFormWorker";
import Select from "react-select";
import Page from "../../../../components/shared/pages/Page";
import Loading from "../../../../components/shared/loaders/Loading";
import {setDashboardMenu} from "../../../../routes/main-route/MainRoute-Actions";
import {ucFirst, unslufigy} from "../../../../helpers/StringHelper";
import {countryListAllIsoData} from "../../../../static-data/CountryList"
import getOrganizatonsWithoutPagination
    from "../../../../ajax/actions/organization/organizations_without_pagination_get";
import CustomReactSelect from "../../../../components/shared/form/CustomReactSelectWithErrorHandle";
import Link from "react-router-dom/Link";
import {endOfURL} from "../../../../helpers/UrlHelper";
import Gravatar from "react-gravatar";

class ProfileForm extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    onChange = (e) => {
        store.dispatch(onUserFormChange(e));
    }

    handleCountry = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.value;
        }
        store.dispatch(selectCountry(value));
    }

    handleCountryCode = (selectedOption) => {
        let value = "";
        if (selectedOption) {
            value = selectedOption.value;
        }
        store.dispatch(selectUserPhoneCode(value));
    }

    handleOrganizationChange = (selectedOption) => {
        let value = [];
        if (selectedOption) {
            selectedOption.map(s => {
                let data = {"id": s.value, "name": s.label}
                if (s.value) {
                    value.push(data)
                }
            })
        }
        store.dispatch(onUserOrganizationAssign(value));
    }

    render() {
        let {
            first_name,
            last_name,
            email,
            password,
            country_code,
            countries,
            contact_number,
            login_user_role,
            city,
            country,
            errors,
            allRoles,
            role,
            allOrganizations,
            allowed_organizations,
            submitted,
            mode,
            loggedInUserUpdate
        } = this.props;

        let clearable = true;
        let roleList = [];
        allRoles && allRoles.map(role => {
            roleList.push({value: role.id, label: ucFirst(role.name), slug: role.slug})
        });

        let countryList = [];
        countryListAllIsoData.map(c => {
            countryList.push({value: c.name, label: c.name})
        })

        let orgList = [];
        allOrganizations && allOrganizations.map(c => {
            orgList.push({value: c.id, label: c.name})
        });

        let allowed_org_list = [];
        allowed_organizations && allowed_organizations.map(c => {
            allowed_org_list.push({value: c.id, label: c.name})
        });
        let countryCodeList = [];
        countries && countries.map(c => {
            countryCodeList.push({value: "+" + c.phone_code, label: c.name + " " + "(+" + c.phone_code + ")", id: c.id})
        })

        let {processing} = loggedInUserUpdate;
        let countryCodeError = errors && errors.country_code && (errors.country_code).length && errors.country_code[0].message
        let countryError = errors && errors.country && (errors.country).length && errors.country[0].message

        return (
            <>
                <UserFormWorker/>
                <div className="custom-profile-update">
                    <div className="feature-col">
                        <div className="single-feature">
                            <div className="feature-information">
                                <div className="feature-avatar-section">
                                    <a className="profile-avatar">
                                        <Gravatar email={email}/></a>
                                </div>
                            </div>
                            <div className="box-block">
                                <div className="tab-content">
                                            <form onSubmit={e => store.dispatch(onUserFormSubmit(e))}>
                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <TextField required
                                                                       value={first_name}
                                                                       name="first_name"
                                                                       icon="user"
                                                                       onChange={this.onChange}
                                                                       errors={errors && errors.first_name}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <TextField required
                                                                       value={last_name}
                                                                       name="last_name"
                                                                       icon="user"
                                                                       onChange={this.onChange}
                                                                       errors={errors && errors.last_name}/>
                                                        </div>
                                                    </div>
                                                </div>




                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label>Country</label>
                                                            <CustomReactSelect
                                                                name="country"
                                                                isClearable={clearable}
                                                                onChange={this.handleCountry}
                                                                value={countryList.filter(option => option.value === country) || countryList.value}
                                                                placeholder="Select Country..."
                                                                options={countryList}
                                                                isValid={errors && errors.country}
                                                                submitted={submitted}
                                                                Searchable
                                                            />
                                                            <div className=' error-msg'>
                                                                {countryError !== 0 && countryError}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">

                                                        <div className="form-group">
                                                            <label>Country Code</label>
                                                            <CustomReactSelect
                                                                name="country_code"
                                                                isClearable={clearable}
                                                                value={countryCodeList.filter(option => option.value === country_code) || countryCodeList.value}
                                                                placeholder="Select Country Code..."
                                                                options={countryCodeList}
                                                                onChange={this.handleCountryCode}
                                                                isValid={errors && errors.country_code}
                                                                submitted={submitted}
                                                            />
                                                            <div className=' error-msg'>
                                                                {countryCodeError !== 0 && countryCodeError}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*</div>*/}

                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <TextField required
                                                                       value={city}
                                                                       name="city"
                                                                       icon="user"
                                                                       onChange={this.onChange}
                                                                       errors={errors && errors.city}/>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <TextField required
                                                                       value={contact_number}
                                                                       name="contact_number"
                                                                       onChange={this.onChange}
                                                                       errors={errors && errors.contact_number}/>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="form-row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <TextField required
                                                                       value={email}
                                                                       name="email"
                                                                       type="email"
                                                                       icon="mail"
                                                                       autoComplete="nope"
                                                                       errors={errors && errors.email}
                                                                       onChange={this.onChange}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <TextField required
                                                                       value={ucFirst(unslufigy(role))}
                                                                       name="role"
                                                                       disabled
                                                            />
                                                        </div>
                                                    </div>


                                                    {
                                                        role !== 'super-admin' ?
                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label>Select Organization</label>
                                                                    <Select
                                                                        name="allowed_organizations"
                                                                        value={allowed_org_list}
                                                                        isMulti
                                                                        isClearable={clearable}
                                                                        onChange={this.handleOrganizationChange}
                                                                        options={orgList}
                                                                        Searchable
                                                                        // disabled
                                                                    />
                                                                </div>
                                                            </div> :

                                                            null
                                                    }
                                                </div>

                                                <div className="button-wrapper text-left mt-20 d-flex ">
                                                    <button type="submit"
                                                            className="btn-theme btn-outline blue-outline ">
                                                        {
                                                            processing ? <>Update <Loading/></> : `Update`
                                                        }
                                                    </button>
                                                </div>
                                            </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    let {
        profileForm,
        updateUser,
        getRoles,
        getOrganizations,
        getLoggedInUser,
        getOrganizatonsWithoutPagination,
        getCountries,
        loggedInUserUpdate,
    } = state;

    let {payload, mode, submitted} = profileForm.data;

    let login_user_role =
        getLoggedInUser?.success?.data?.data?.role?.slug

    let countries = getCountries && getCountries.success && getCountries.success.data.data


    let {success} = getRoles;

    let allRoles =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data;

    let allOrganizations =
        getOrganizatonsWithoutPagination &&
        getOrganizatonsWithoutPagination.success &&
        getOrganizatonsWithoutPagination.success.data &&
        getOrganizatonsWithoutPagination.success.data.data

    let {errors, errorsPresent} = profileForm.data;

    let {
        first_name, middle_name, last_name, email, password, address, extra_address_line,
        post_code, city, country, role, allowed_organizations, country_code, contact_number
    } = payload;

    return {
        errors, errorsPresent, allRoles, first_name, countries, submitted, getLoggedInUser, loggedInUserUpdate,
        middle_name, last_name, email, password, updateUser, mode, contact_number,
        profileForm, address, extra_address_line, post_code, city, country, role, getRoles, country_code,
        getOrganizations, allOrganizations, allowed_organizations, login_user_role, getOrganizatonsWithoutPagination
    };
}

export default connect(mapStateToProps)(ProfileForm);
