import axios from 'axios';
import {apiBaseUrl} from "../../../helpers/UrlHelper";
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import {clone} from '../../../helpers/DataHelper'
import store from '../../../Store'
import {scrollToError} from '../../../helpers/ScrollHelper'
import {cancelRoleFormDispatch, roleFormServerValidationErrors} from "../../../pages/role/form/RoleForm-Action";

function _success(success) {
    return {type: 'PUT_ROLE_SUCCESS', success};
}

function _error(error) {
    return {type: 'PUT_ROLE_ERROR', error};
}

function _processing(processing) {
    return {type: 'PUT_ROLE_PROCESSING', processing};
}

function updateRole(id) {
    return (dispatch, getState) => {
        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let {roleForm} = getState();
        let data = clone(roleForm.data.payload);

        axios({
            url: apiBaseUrl(`admin/roles/${id}`),
            method: "put",
            dataType: 'json',
            data,
            headers: {
                'Authorization': 'Bearer ' + loginToken()
            }
        })
            .then(function (res) {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res)
                store.dispatch(cancelRoleFormDispatch())


            }).catch(function (error) {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error)
            store.dispatch(cancelRoleFormDispatch())
            if (error.response && error.response.status === 422) {
                store.dispatch(roleFormServerValidationErrors(error.response.data.errors))
                scrollToError();
            }

        });
    }
}


export default updateRole;