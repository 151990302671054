import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class SidebarMainMenu extends React.Component {
  render() {
    let { role, url, startOfURL, home } = this.props;

    // let organization_terary = startOfURL === "users" || startOfURL === "organizations" || startOfURL === "sites" || (startOfURL === "activitylog" && url === "organizations") || (url === "profile" && startOfURL === "user") || home === true
    let organization_terary =
      startOfURL === "users" ||
      startOfURL === "organizations" ||
      startOfURL === "sites" ||
      startOfURL === "migration-details" ||
      startOfURL === "migration" ||
      startOfURL === "myaccount" ||
      startOfURL === "new-client" ||
      (startOfURL === "activitylog" && url !== "infrastructure") ||
      (url === "profile" && startOfURL === "user") ||
      home === true;

    let infrastructure_ternary =
      url === "internal-migration-tracking" ||
      url === "data-center" ||
      url === "server" ||
      url === "load-balancer" ||
      (url === "update" && startOfURL === "data-center") ||
      (url === "create" && startOfURL === "data-center") ||
      (url === "update" && startOfURL === "internal-migration-tracking") ||
      startOfURL === "server" ||
      startOfURL === "load-balancer" ||
      (startOfURL === "activitylog" && url === "infrastructure") ||
      startOfURL === "backup-status" ||
      startOfURL === "expired-backup";

    let users_ternary =
      url === "roles" ||
      url === "allusers" ||
      url === "role" ||
      (url === "update" && startOfURL === "allusers") ||
      startOfURL === "role" ||
      (startOfURL === "allusers" && url === "create");

    let billing_ternary =
      url === "discounts" ||
      url === "price" ||
      url === "coupon" ||
      (url === "update" && startOfURL === "price") ||
      (url === "create" && startOfURL === "price") ||
      startOfURL === "discounts" ||
      startOfURL === "coupon" ||
      url === "upcloud" ||
      url === "renew";

    let support =
      url === "internal-support" ||
      (url === "create" && startOfURL === "bugs") ||
      (url === "update" && startOfURL === "bugs") ||
      (url === "details" && startOfURL === "bugs") ||
      (url === "active" && startOfURL === "internal-support") ||
      (url === "completed" && startOfURL === "internal-support") ||
      (url === "urgent" && startOfURL === "internal-support");

    return (
      <>
        <ul className="main-menu">
          {role === "organization-admin" ? (
            <>
              <li className="data-link">
                <Link
                  className={
                    startOfURL === "organizations" || home ? "active" : ""
                  }
                  to="/organizations"
                >
                  Organizations
                </Link>
              </li>
            </>
          ) : organization_terary ? (
            <>
              <li className={"data-link"}>
                <Link
                  className={startOfURL === "organizations" ? "active" : ""}
                  to="/organizations"
                >
                  Organizations
                </Link>
              </li>
              <li className="data-link">
                <Link
                  className={startOfURL === "sites" ? "active" : ""}
                  to="/sites"
                >
                  Sites
                </Link>
              </li>
              <li className="data-link">
                <Link
                  className={startOfURL === "migration" ? "active" : ""}
                  to="/migration"
                >
                  Migration
                </Link>
              </li>
              <li className="data-link">
                <Link
                  className={startOfURL === "users" ? "active" : ""}
                  to="/users"
                >
                  Users
                </Link>
              </li>
              {/*<li className="data-link"><Link className={startOfURL === "new-client" ? "active" : ""}
                                                                to="/new-client">New Client</Link></li>*/}
              {/*<li className="data-link" id="activity-log"><Link className={url === "organizations" && startOfURL === "activitylog" ? "active" : ""} to="/activitylog/organizations">Activity Log</Link></li>*/}
              <li className="data-link" id="activity-log">
                <Link
                  className={startOfURL === "activitylog" ? "active" : ""}
                  to="/activitylog"
                >
                  Activity Log
                </Link>
              </li>
            </>
          ) : infrastructure_ternary ? (
            <>
              <li className="data-link">
                <Link
                  className={startOfURL === "data-center" ? "active" : ""}
                  to="/data-center"
                >
                  Data Center
                </Link>
              </li>
              <li className="data-link">
                <Link
                  className={startOfURL === "server" ? "active" : ""}
                  to="/server"
                >
                  Server
                </Link>
              </li>
              <li className="load-link">
                <Link
                  className={startOfURL === "load-balancer" ? "active" : ""}
                  to="/load-balancer"
                >
                  Load Balancer
                </Link>
              </li>
              <li className="load-link">
                <Link
                  className={
                    startOfURL === "internal-migration-tracking" ? "active" : ""
                  }
                  to="/internal-migration-tracking"
                >
                  Internal Migration
                </Link>
              </li>
              <li className="load-link">
                <Link
                  className={startOfURL === "backup-status" ? "active" : ""}
                  to="/backup-status"
                >
                  Backup
                </Link>
              </li>
              <li className="load-link">
                <Link
                  className={startOfURL === "expired-backup" ? "active" : ""}
                  to="/expired-backup"
                >
                  Expired
                </Link>
              </li>
              <li className="data-link" id="activity-log">
                <Link
                  className={url === "infrastructure" ? "active" : ""}
                  to="/activitylog/infrastructure"
                >
                  Activity Log
                </Link>
              </li>
            </>
          ) : users_ternary ? (
            <>
              <li className="user-link">
                <Link
                  className={startOfURL === "allusers" ? "active" : ""}
                  to="/allusers"
                >
                  Users
                </Link>
              </li>
              <li className="roles-link">
                <Link
                  className={
                    startOfURL === "roles" || startOfURL === "role"
                      ? "active"
                      : ""
                  }
                  to="/roles"
                >
                  Roles
                </Link>
              </li>
            </>
          ) : billing_ternary ? (
            <>
              <li className="roles-link">
                <Link
                  className={startOfURL === "price" ? "active" : ""}
                  to="/price"
                >
                  Price
                </Link>
              </li>
              <li className="roles-link">
                <Link
                  className={startOfURL === "discounts" ? "active" : ""}
                  to="/discounts"
                >
                  Discounts
                </Link>
              </li>
              <li className="roles-link" id="coupon">
                <Link
                  className={startOfURL === "coupon" ? "active" : ""}
                  to="/coupon"
                >
                  Coupon
                </Link>
              </li>

              {/* <li className="roles-link"><Link className={startOfURL === "upcloud" ? "active" : ""} to="/upcloud">Upcloud</Link></li> */}
            </>
          ) : support ? (
            <>
              <li className="user-link">
                <Link
                  className={
                    startOfURL === "internal-support" &&
                    url !== "urgent" &&
                    url !== "active" &&
                    url !== "completed"
                      ? "active"
                      : ""
                  }
                  to="/internal-support"
                >
                  All
                </Link>
              </li>{" "}
              <li className="user-link">
                <Link
                  className={url === "urgent" ? "active" : ""}
                  to="/internal-support/urgent"
                >
                  Urgent
                </Link>
              </li>
              <li className="user-link">
                <Link
                  className={url === "active" ? "active" : ""}
                  to="/internal-support/active"
                >
                  Active
                </Link>
              </li>
              <li className="user-link">
                <Link
                  className={url === "completed" ? "active" : ""}
                  to="/internal-support/completed"
                >
                  Completed
                </Link>
              </li>
            </>
          ) : (
            ""
          )}
        </ul>
      </>
    );
  }
}

function mapStateToProps(state) {
  let { route } = state;

  let role = route && route.data.user && route.data.user.role.slug;
  return { route, role };
}

export default connect(mapStateToProps)(SidebarMainMenu);
