import React from 'react';
import {withRouter} from 'react-router';
import store from '../../Store';
import {connect} from 'react-redux';
import getsite from "../../ajax/actions/sites/site_get";
import {setSiteDashboardData, setSiteStatus, setSiteDashboardOrganizationID} from "./SiteDashboard-Action";
import { setOrganizationSiteUrl} from "../../routes/main-route/MainRoute-Actions";
import getOrganization from "../../ajax/actions/organization/organization_get";
import {setOrganizationDashboardData} from "../organization/OrganizationDashboard-Action";
import getSiteStatus from "../../ajax/actions/sites/site_status_get";
import Alert from "react-s-alert";
// import { ConfigNotificationSuccessAlert} from "../../Config";
import getSiteDomain from "../../ajax/actions/domain/site_domain_get";
import getCredentials from "../../ajax/actions/sftp-database/credential_get";
import {setSftpID} from "./sftp-database/list/Sftp-Action";
import {setSiteIDDomain} from "./domain/list/Domain-Action";
import {
     setSiteId,setOrganizationId
} from "./organization-block/form/OrganizationBlockForm-Action";
import {
    setSitesId
} from "./internal-migration-block/form/InternalMigrationForm-Action";
import getLogs from "../../ajax/actions/logs/Logs_get";
import getSiteBackup from "../../ajax/actions/backup/site_backup_get";

class SiteDashboardWorker extends React.Component {
    componentDidMount() {
        store.dispatch( getOrganization(this.props.match.params.org_id ));
        store.dispatch(setSftpID(this.props.match.params.id));
        store.dispatch(setSiteIDDomain(this.props.match.params.id));
        store.dispatch(setSiteId(this.props.match.params.id));
        store.dispatch(setSitesId(this.props.match.params.id));
        store.dispatch(setOrganizationId(this.props.match.params.org_id));
        // store.dispatch(getOrganizationsBlock());
        store.dispatch( setOrganizationSiteUrl(this.props.match.url ) );
        store.dispatch( setSiteDashboardOrganizationID(this.props.match.params.org_id ) );
        store.dispatch( getSiteDomain(  this.props.match.params.org_id ));
        store.dispatch( getSiteStatus( this.props.match.params.id , this.props.match.params.org_id ) );
        store.dispatch( getsite(this.props.match.params.id , this.props.match.params.org_id ));
        store.dispatch( getCredentials(this.props.organization_id ));
        store.dispatch(getSiteBackup(this.props.match.params.id, this.props.match.params.org_id ));
        // store.dispatch(getManualSiteBackup(this.props.match.params.id, this.props.match.params.org_id ));
        store.dispatch(getLogs(this.props.match.params.id, this.props.match.params.org_id));
        this.periodicFetch()
    }

    componentDidUpdate(prev) {

        if( this.props.getOrganization !== prev.getOrganization ) {
            let { success } = this.props.getOrganization;
            if( success ) {
                store.dispatch( setOrganizationDashboardData() );
                store.dispatch( getsite(this.props.match.params.id , this.props.match.params.org_id ));
                store.dispatch( getSiteStatus( this.props.match.params.id , this.props.match.params.org_id ) );
                // store.dispatch( getSiteDomain(this.props.match.params.org_id ));
                store.dispatch( setSiteDashboardOrganizationID(this.props.match.params.org_id ) );
            }
        }
        if( this.props.getSite !== prev.getSite ) {
            let { success, error } = this.props.getSite;
            if( success ) {
                store.dispatch( setSiteDashboardData() );
            }

            if( error ) {
                if( error.response.status === 403 ) {
                    this.props.history.push(`/organizations/${this.props.match.params.org_id}/sites`)
                }
            }
        }

        if( this.props.getSiteStatus !== prev.getSiteStatus ) {
            let { success } = this.props.getSiteStatus;
            if( success ) {
                store.dispatch( setSiteStatus() );
                if( success && success.notification && success.notification.status === "failed" ) {
                    let i = 0;
                    if( i === 0 ) {
                        Alert.error(`Error - ${ success && success.notification && success.notification.message }` );
                    }
                }
            }
        }
        if (this.props.organization_id !== prev.organization_id) {
            store.dispatch(getCredentials(this.props.match.params.org_id));
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    periodicFetch()
    {
        this.timer = setInterval(() => !this.props.getSiteStatus.processing && store.dispatch( getSiteStatus( this.props.match.params.id , this.props.match.params.org_id )), 10000)
    }



    render() {
        return null
    }
}

function mapStateToProps( state ) {
    let { getSite, getOrganization, getSiteStatus , getCredentials, getOrganizationsBlock} = state;
    return { getSite, getOrganization, getSiteStatus , getCredentials , getOrganizationsBlock}
}

export default withRouter(connect(mapStateToProps)(SiteDashboardWorker));