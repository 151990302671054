import React from 'react';
import store from '../../../../Store'
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import passwordReset from "../../../../ajax/actions/user-admin-account/user_change_password_put";
import {setDefaultPasswordResetForm} from "./PasswordResetForm-Action";


class PasswordResetFormWorker extends React.Component {

    componentDidMount() {
        store.dispatch(setDefaultPasswordResetForm());
    }

    componentDidUpdate(prev) {
        let {dispatchAPI} = this.props;
        if (dispatchAPI) {
            if (!prev.dispatchAPI) {
                store.dispatch(passwordReset(this.props.match.params.id))
            }
        }
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {passwordResetForm, passwordReset,} = state;
    let {dispatchAPI, mode, payload} = passwordResetForm.data;
    return {passwordResetForm, dispatchAPI, mode, payload, passwordReset};
}

export default withRouter(connect(mapStateToProps)(PasswordResetFormWorker));