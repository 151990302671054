import React from 'react';
import Button from "../../../components/shared/buttons/Button";
import {Link} from 'react-router-dom';
import {unslufigy} from "../../../helpers/StringHelper";
import {connect} from "react-redux";
import Select from "react-select";
import store from "../../../Store";
import {
    onDataCenterFormChange,
    toggleLoadBalancerListFilter,
    onServerFormReset,
    onDataCenterFormReset
} from "./LoadBalancer-Action";
import {onServerFormChange} from "./LoadBalancer-Action";

class LoadBalancerHeader extends React.Component {
    toggleFilter = (e) => {
        store.dispatch(toggleLoadBalancerListFilter(e, this.props.history));
    }

    handleDataCenter = (selectedOption) => {
        if (selectedOption) {
            store.dispatch(onDataCenterFormChange(selectedOption.value));
        } else {
            store.dispatch(onDataCenterFormReset());
        }
    }

    handleSelectServer = (selectedOptions) => {
        if (selectedOptions) {
            store.dispatch(onServerFormChange(selectedOptions.value, selectedOptions.id));
        } else {
            store.dispatch(onServerFormReset());
        }
    }

    render() {
        let {title, dataCenters, dataCenterServer} = this.props;
        let clearable = true;

        let dataCenterList = [];
        dataCenters && dataCenters.map(dataCenter => {
            dataCenterList.push({value: dataCenter.id, label: dataCenter.name})
        });

        let serverList = [];
        dataCenterServer && dataCenterServer.map(server => {
            serverList.push({value: server.id, label: server.name, id: server.data_center_id})
        });

        return (

            <div className="heading-block pr-60">
                <div className="title-block Page animated fadeIn faster">
                    <h2>{unslufigy(title)}</h2>
                </div>

                <div className="search-bar">
                    <input type="text"
                           className="form-control"
                           name="q"
                           placeholder={`Search ${unslufigy(title)}`}
                           ref="searchStringInput"
                           onChange={this.toggleFilter}
                    />
                    <span className="icon-search"/>
                </div>

                <div className="select-block">
                    <Select
                        name="DataCenter"
                        isClearable={clearable}
                        value={dataCenterList.id}
                        placeholder="Select DataCenter..."
                        options={dataCenterList}
                        onChange={this.handleDataCenter}
                    />

                </div>
                <div className="select-block">
                    <Select
                        name="Server"
                        isClearable={clearable}
                        placeholder="Select Server..."
                        value={serverList.id}
                        options={serverList}
                        onChange={this.handleSelectServer}
                    />
                </div>

                <div className="button-wrapper">
                    <Link to={`/${title}/create`}>
                        <Button className="btn-theme btn-dark-blue"
                                title={`Add ${unslufigy(title)}`}
                                text={`Add ${unslufigy(title)}`}/>
                    </Link>
                </div>
            </div>

        )
    }
}

function mapStateToProps(state) {
    let {getDataCenters, getDataCenterServerModule, loadBalancerList} = state;
    let {params} = loadBalancerList.data;
    let dataCenters =
        getDataCenters &&
        getDataCenters.success &&
        getDataCenters.success.data &&
        getDataCenters.success.data.data &&
        getDataCenters.success.data.data.data;

    let dataCenterServer =
        getDataCenterServerModule &&
        getDataCenterServerModule.success &&
        getDataCenterServerModule.success.data &&
        getDataCenterServerModule.success.data.data &&
        getDataCenterServerModule.success.data.data.data

    return {getDataCenters, dataCenters, getDataCenterServerModule, dataCenterServer, params};
}

export default connect(mapStateToProps)(LoadBalancerHeader);