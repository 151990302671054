import React from 'react';
import {connect} from 'react-redux'
import store from '../../../Store';
import ServerModuleFormWorker from './ServerModuleFormWorker'
import {onServerModuleFormChange, onServerModuleFormSubmit} from "./ServerModuleForm-Action";
import TextField from "../../../components/shared/form/TextField";
import Loading from "../../../components/shared/loaders/Loading";
import SelectField from "../../../components/shared/form/SelectField";
import {activeStatusFilter,storageType} from "../../../static-data/UserData";
import TextAreaField from "../../../components/shared/form/TextAreaField";
import {setDashboardMenu} from "../../../routes/main-route/MainRoute-Actions";
import Link from "react-router-dom/Link";
import {endOfURL} from "../../../helpers/UrlHelper";
import {ucFirst} from "../../../helpers/StringHelper";
import Page from "../../../components/shared/pages/Page";

class ServerModuleForm extends React.Component {

    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(  setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    serverModuleFormSubmit = (e) => {
        e.preventDefault();
        store.dispatch(onServerModuleFormSubmit());
    };

    onChange = (e) => {
        // e.preventDefault();
        store.dispatch(onServerModuleFormChange(e.target.name, e.target.value,));
    };

    render() {
        let {
            name, data_center_id, public_ip_address, description,c_core, network_interface,
            status, updateServerModule, createServerModule, serverModuleForm, dataCenterList,storage_type,max_sites
        } = this.props;
        let {errors, mode} = serverModuleForm.data;

        let processing = updateServerModule.processing || createServerModule.processing;

        return (

            <>
                <Page title={endOfURL()=== "create" ?
                    "Add Server" :
                    "Update Server"
                } processing={processing}>
                <div className="heading-block pr-60">
                    <div className="title-block">
                        <h2>{ endOfURL() === 'create' ? `Add new Server` : `Update Server` } </h2>
                        <span><Link to="/server">Server</Link></span>
                        { endOfURL() === 'update' ? <span>{ucFirst(name)}</span> : null}
                        <span>{ endOfURL() === 'create' ? `Add new Server` : `Update` }</span>
                </div>
                </div>
                <ServerModuleFormWorker/>
                    <div className="box-block">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="pills-add-user" role="tabpanel"
                                 aria-labelledby="pills-add-user-tab">
                                <div className="padded-block">
                    <form onSubmit={this.serverModuleFormSubmit}>
                        <div className="form-row">
                            <div className="col-md-6">
                                <div className="form-group">
                            <TextField name="name"
                                       value={name}
                                       label="Name"
                                       errors={errors.name}
                                       onChange={this.onChange}/>
                                </div>
                            </div>

                        <div className="col-md-6">
                                <div className="form-group">
                            <SelectField name="data_center_id"
                                         value={data_center_id}
                                         options={dataCenterList}
                                         errors={errors.data_center_id}
                                         onChange={this.onChange}
                                         _value="id"
                                         label="Data Center"
                                // disabled={mode === "update"}
                            />
                                </div>
                        </div>
                        </div>
                        <hr/>
                        {/*<div className="form-row">*/}
                        {/*<div className="col-md-6">*/}
                        {/*    <div className="form-group">*/}
                        {/*        <TextField name="fqdn"*/}
                        {/*        value={fqdn}*/}
                        {/*        errors={errors.fqdn}*/}
                        {/*        onChange={this.onChange}*/}
                        {/*        disabled={mode === "update"}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*</div>*/}

                        <div className="form-row">
                            <div className="col-md-6">
                                <div className="form-group">
                            <TextField name="public_ip_address"
                                       value={public_ip_address}
                                       label="Public IP Address"
                                       errors={errors.public_ip_address}
                                       onChange={this.onChange}
                                       disabled={mode === "update"}
                            />
                                </div>
                            </div>
                        </div>

<hr/>
                        <div className="form-row">
                            <div className="col-md-6">
                                <div className="form-group">
                            <TextField name="network_interface"
                                       value={network_interface}
                                       label="Network Interface"
                                       errors={errors.network_interface}
                                       onChange={this.onChange}
                                       disabled={mode === "update"}
                            />
                        </div>
                            </div>


                            <div className="col-md-6">
                                <div className="form-group">
                            <TextField name="c_core"
                                       value={c_core}
                                       errors={errors.c_core}
                                       onChange={this.onChange}
                                       disabled={mode === "update"}
                            />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <SelectField name="storage_type"
                                                 value={storage_type}
                                                 options={storageType}
                                                 errors={errors.storage_type}
                                                 onChange={this.onChange}
                                                 _value="value"
                                                 _label="name"/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group">
                                    <TextField name="max_sites"
                                               value={max_sites}
                                               errors={errors.max_sites}
                                               onChange={this.onChange}
                                    />
                                </div>
                            </div>
                        </div>
<hr/>
                            {/*    <div className="col-md-6">*/}
                            {/*        <div className="form-group">*/}
                            {/*<TextField name="private_gateway"*/}
                            {/*           value={private_gateway}*/}
                            {/*           label="Private Gateway"*/}
                            {/*           errors={errors.private_gateway}*/}
                            {/*           onChange={this.onChange}*/}
                            {/*           disabled={mode === "update"}*/}
                            {/*/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                        {/*</div>*/}
                        <div className="form-row">
                            <div className="col-md-12">
                                <div className="form-group">
                            <TextAreaField name="description"
                                           value={description}
                                           label="Detailed Description"
                                           errors={errors.description}
                                           onChange={this.onChange}/>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className="form-row">
                        <div className="col-md-6">
                            <div className="form-group">
                            <SelectField name="status"
                                         value={status}
                                         options={activeStatusFilter}
                                         errors={errors.status}
                                         onChange={this.onChange}
                                         _value="value"
                                         _label="name"/>
                            </div>
                        </div>


                            <div className="col-md-6">
                                <div className="form-group">
                        <div className="button-wrapper text-right mt-20 d-flex justify-content-end">
                            <button type="submit"
                                    className="btn-theme btn-dark-blue">
                                {processing ? <Loading/> : `Submit`}
                            </button>
                        </div>
                                </div>
                            </div>
                        </div>
                    </form>
                        </div>
                    </div>

                </div>
            </div>
                </Page>
            </>

        )
    }
}

function mapStateToProps(state) {
    let {serverModuleForm, updateServerModule, createServerModule, getDataCenters } = state;

    let {errors, payload, mode,} = serverModuleForm.data;

    let {success} = getDataCenters;
    let dataCenterList =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data;

    let {
        name, data_center_id, public_ip_address, description,c_core, network_interface,

        status,storage_type,max_sites
    } = payload;

    return { getDataCenters, dataCenterList,
        serverModuleForm, updateServerModule, createServerModule, errors,
        name, data_center_id, public_ip_address, description,
        c_core, network_interface,storage_type,max_sites,
        status, mode
    };
}

export default connect(mapStateToProps)(ServerModuleForm);