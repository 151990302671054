import React from 'react';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import Loading from "../../../components/shared/loaders/Loading";
import {ucFirst} from "../../../helpers/StringHelper";
import {fromRecord, inRecord} from "../../../helpers/DataHelper";

class SiteActionCell extends React.Component {

    render() {
        let { site_id, org_id, site_status } = this.props;
        let s_status = '';
            if (inRecord(site_status, 'id', '==', site_id)) {
                s_status = fromRecord(site_status, 'id', '==', site_id).status;
            }

        return (
<>
    {
        s_status === `updating` || s_status === `idle` ?
            <div className="dropdown dropdown-alt text-center">
                    <span className="dropdown-toggle"
                          data-toggle="dropdown">
                        <span className="icon-more"/>
                    </span>
                <div className="dropdown-menu">

                    <Link to={`/organizations/${org_id}/sites/${site_id}/launchpad/admin`} className={`link-edit`}>
                        Edit
                    </Link>
                    {/*<a href="javascript:void(0);" onClick={() => this.deleteConfirm(site_id)} className={`link-delete`}>*/}
                    {/*    Delete*/}
                    {/*</a>*/}
                </div>
            </div>
                        :

                        <div className="text-center position-absolute">
                            <Loading/> {ucFirst(s_status)}</div>
    }
 </>
        )
    }
}

function mapStateToProps(state) {
    let {   getSuperAdminSites, getAdminSiteStatus  } = state;

    let {success} = getSuperAdminSites;

    let site_status =
        getAdminSiteStatus?.success;

    let list =
        success &&
        success.data &&
        success.data.data &&
        success.data.data.data;

    return {getSuperAdminSites, list,  getAdminSiteStatus, site_status  };
}
export default connect(mapStateToProps)(SiteActionCell);
