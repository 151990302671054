import axios from 'axios';
import {loginToken} from '../../../helpers/LocalStorageHelper'
import {apiBaseUrl} from '../../../helpers/UrlHelper'
import {handleError, handleSuccess} from '../../../helpers/ErrorHelper'
import store from "../../../Store";
import getOrganizations from "./organizations_get";

function _success(success) {
    return {type: 'DELETE_ORGANIZATION_SUCCESS', success}
}

function _error(error) {
    return {type: 'DELETE_ORGANIZATION_ERROR', error}
}

function _processing(processing) {
    return {type: 'DELETE_ORGANIZATION_PROCESSING', processing}
}

function deleteOrganization(id) {
    return (dispatch) => {

        dispatch(_processing(true));
        dispatch(_success(null));
        dispatch(_error(null));

        let config = {
            headers: {'Authorization': 'Bearer ' + loginToken()},
        };

        axios.delete(apiBaseUrl(`admin/organizations/${id}`), config)

            .then(res => {
                dispatch(_success(res));
                dispatch(_processing(false));
                handleSuccess(res);
                store.dispatch(getOrganizations());

            }).catch(error => {
            dispatch(_error(error));
            dispatch(_processing(false));
            handleError(error)

        });
    }
}

export default deleteOrganization;