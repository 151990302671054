import React from 'react';
import {withRouter} from 'react-router';
import store from '../../Store';
import {connect} from 'react-redux';
import {setDefaultEnable2Fa} from "./Enable2Fa-Action";
import getLoggedInUser from "../../ajax/actions/user/user_loggedin_get";

class MyAccountWorker extends React.Component {

    componentDidMount() {
        store.dispatch(getLoggedInUser());
        store.dispatch(setDefaultEnable2Fa());
    }

    render() {
        return null
    }
}

function mapStateToProps(state) {
    let {getLoggedInUser} = state;
    return {getLoggedInUser}
}

export default withRouter(connect(mapStateToProps)(MyAccountWorker));