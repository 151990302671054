import React from 'react'
import {connect} from 'react-redux';
import HotfixWorker from './HotfixWorker';
import {toggleHotfixListFilter} from './HotfixList-Actions'
import store from '../../Store';
import TableHead from "../../components/shared/table/TableHead";
import PaginationButtons from "../../components/shared/buttons/PaginationButtons";
import Page from "../../components/shared/pages/Page";
import HotfixListFilters from "./HotfixListFilters";
import {setDashboardMenu} from "../../routes/main-route/MainRoute-Actions";
import createHotfix from "../../ajax/actions/hotfix/hotfix_post";
import Loading from "../../components/shared/loaders/Loading";

class Hotfix extends React.Component {

    state={
        loadingSiteId:``
    }
    
    componentDidMount() {
        document.querySelector('body').classList.add('organization-dashboard');
        store.dispatch(setDashboardMenu(''));
    }

    componentWillUnmount() {
        document.querySelector('body').classList.remove('organization-dashboard');
    }

    toggleSort = (field) => {
        store.dispatch(toggleHotfixListFilter({
            target: {name: `sort_by`, value: field}
        }))
    };

    onclick(id) {
        store.dispatch(createHotfix(id))
        this.setState({loadingSiteId:id})
    }

    render() {
        let {pages, getHotfixs, params, fields, list,createHotfix} = this.props;
        let {processing, error} = getHotfixs;
        let createHotfixProcessing = createHotfix.processing;
        if (error) {
            this.props.history.push('/');

        }
        return (
            <Page title="Hotfix" processing={processing}>
                <HotfixListFilters title="Hotfix"/>

                <div className="table-block table-responsive pr-60 table-list-block">
                    <table className="table table-striped ">
                        <TableHead fields={fields}
                                   params={params}
                                   noActions={true}
                                   onClick={this.toggleSort}
                                   processing={processing}
                        />

                        <tbody>
                        {processing ? <td colSpan={5} className="text-center"><Loading/></td> :
                        list?.length ? list.map((item, i) => {
                            return(
                                <tr key={i}>
                                    <td>
                                        {item.display_name}
                                    </td>
                                    <td>Pending</td>
                                    {/*<td>{list.data_center_name}</td>*/}
                                    <td className="table-view-block" key={i}>
                                        <div className="button-wrapper text-right">

                                            <button type="reset" className="btn-theme btn-dark-blue"
                                                    onClick={() => this.onclick(item.id)}
                                            >
                                                {createHotfixProcessing && this.state.loadingSiteId === item.id? <>Hotfix <Loading/> </>:"Hotfix"}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )})
                        : <tr>
                                <td colSpan={5} className="text-center">No Pending Sites</td>
                            </tr>
                       }
                        </tbody>
                    </table>
                </div>
                {list && list.length ? (
                    <>

                    <div className="fixed-footer pr-60">
                        <div className="footer-text-block">
                        </div>
                        <PaginationButtons pages={pages}
                                           processing={processing}
                                           fetchAction={toggleHotfixListFilter}/>
                    </div>
                    </>
                ) : null}
                <HotfixWorker/>
            </Page>
        )
    }
}

function mapStateToProps(state) {
    let {getHotfixs,hotfixList,createHotfix} = state;

    let {params, fields} = hotfixList.data;

    let list =
        getHotfixs &&
        getHotfixs.success

    let current_page = getHotfixs.success && getHotfixs.success.data && getHotfixs.success.data.data.current_page;
    let last_page = getHotfixs.success && getHotfixs.success.data && getHotfixs.success.data.data.last_page;
    let total = getHotfixs.success && getHotfixs.success.data && getHotfixs.success.data.data.total;
    let pages = {current_page, last_page, total};

    return { params, fields, getHotfixs, pages, list,createHotfix};
}

export default connect(mapStateToProps)(Hotfix);