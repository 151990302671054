import React from 'react';
import {Link} from "react-router-dom";
import store from "../../Store";
import {endOfURL, secondToLastItemInURL} from "../../helpers/UrlHelper";
import {setOrganizationSiteUrl} from "../../routes/main-route/MainRoute-Actions";
import {connect} from "react-redux";
import {withRouter} from "react-router";

class OrganizationSideMenu extends React.Component {
    constructor(props) {
        super(props);
        if (props) {
            store.dispatch(setOrganizationSiteUrl(endOfURL()));
        }
    }

    state = {
        activeLabel: ``,
        width: window.innerWidth,
    };

    render() {
        let {org_id, role, processing} = this.props;
        
        return (

            <>
                    <div className="org-menu">
                        <ul>
                            <li className={endOfURL() === "sites" || secondToLastItemInURL() === "sites" || endOfURL() === "adminorg" || endOfURL() === "admin" || endOfURL() === "launchpad" || endOfURL() === "domain" || endOfURL() === "redirects"  || endOfURL() === "backup" || endOfURL() === "featureblock" || endOfURL() === "cache" ||  endOfURL() === "logs"  ||  endOfURL() === "plan" ||  endOfURL() === "ssl" ? "active" : ""} id={processing && "disabled-org-link"}>
                                <Link to={`/organizations/${org_id}/sites`} id={processing && "disabled-org-link"}>
                                    Org Sites
                                </Link>
                            </li>

                            <li className={endOfURL() === "activitylog" ? "active" : ""} id={processing && "disabled-org-link"}>
                                <Link to={`/organizations/${org_id}/activitylog`} id={processing && "disabled-org-link"}>
                                    Activity Log
                                </Link>
                            </li>
                            {role === 'super-admin' ? (
                            <li className={endOfURL() === "users" || endOfURL() === "update" || (secondToLastItemInURL() === "users" && endOfURL() === "create")  ? "active" : ""} id={processing && "disabled-org-link"}>
                                <Link to={`/organizations/${org_id}/users`} id={processing && "disabled-org-link"}>
                                    Users
                                </Link>
                            </li>
                                ):null}

                            {/* <li className={endOfURL() === "billing" || endOfURL() === "fortnox-client" ? "active" : ""} id={processing && "disabled-org-link"}>
                                <Link to={`/organizations/${org_id}/billing`} id={processing && "disabled-org-link"}>
                                    Billing
                                </Link>
                            </li> */}

                           {/* <li className={endOfURL() === "fortnox-client" ? "active" : ""} id={processing && "disabled-org-link"}>
                                <Link to={`/organizations/${org_id}/fortnox-client`} id={processing && "disabled-org-link"}>
                                    Fortnox
                                </Link>
                            </li>*/}

                            {role === 'super-admin' ? (
                                <li className={endOfURL() === "payments" ? "active" : ""} id="payments-link">
                                <Link to={`/organizations/${org_id}/payments`} id={processing && "disabled-org-link"}>
                                    Payments
                                </Link>
                            </li>
                            ): null}
                        </ul>
                    </div>
            </>

        )
    }
}


function mapStateToProps(state) {
    let {getSite, organizationDashboard, route} = state;
    let {success} = getSite;
    let role = route && route.data.user && route.data.user.role.slug;
    let site_id =
        success &&
        success.id
    let org_id = organizationDashboard.data.organization_id;

    return {getSite, site_id, organizationDashboard, org_id, route, role};
}

export default withRouter(connect(mapStateToProps)(OrganizationSideMenu));